 <div *ngIf="!isApprovedReported">
     <div class="col-12 mr-0 pr-0 mb-2">
         <div>
             <h6 class="font-weight-normal text-left mb-2">{{headerTitle}}</h6>
         </div>
         <div *ngIf="!isFromPreview && !isBuilt && statusId != enumCampaignStatus.Sent_to_API_Build" class="pr-0 mr-0 row">
             <div class="mr-0">
                 <button (click)="approveReport(true); $event.preventDefault()" pButton label="APPROVE" class="p-button-primary approve-button">
                 </button>
             </div>
             <div class="text-right">
                 <button (click)="approveReport(false);$event.preventDefault()" pButton label="REPORT" class="p-button-primary report-button">
                 </button>
             </div>
         </div>
         <div *ngIf="isFromPreview && !isBuilt && statusId != enumCampaignStatus.Sent_to_API_Build" class="pr-0  mr-0 row">
            <div class="col-12 mr-0 mb-2" style="text-align: center;">
                 <button (click)="approveReport(true);$event.preventDefault()" pButton label="APPROVE" class="approve-button p-button-primary">
                 </button>
             </div>
             <div class="col-12" style="text-align: center;">
                 <button (click)="approveReport(false);$event.preventDefault()" pButton label="REPORT" class="p-button-primary report-button">
                 </button>
             </div>
         </div>

     </div>
     <div *ngIf="!creativeAsset.isValid && !isBuilt && statusId != enumCampaignStatus.Sent_to_API_Build">
         <label *ngIf="isFromPreview" class="required">Comment</label>
         <textarea style="min-width: 100%;" rows="5" pInputTextarea placeholder="" [(ngModel)]="creativeAsset.validationMessage"></textarea>
     </div>

 </div>
 <div *ngIf="isApprovedReported">
     <div class="col-12 mr-0 pr-0 mb-3">
         <div>
             <h6 class="font-weight-normal text-left mb-2">{{headerTitle}}</h6>
         </div>
         <div *ngIf="!isFromPreview" class="col-12 p-0 mt-3 mr-0 row">
             <div *ngIf="creativeAsset.isValid" class="col-7 m-0 ">
                 <i class="fa-lg fal fa-check-circle"> Approved</i>
             </div>
             <div *ngIf="!creativeAsset.isValid" class="col-7 m-0">
                 <i class="fa-lg fal fa-times-circle"> Reported</i>
             </div>
             <div *ngIf="!isBuilt && statusId != enumCampaignStatus.Sent_to_API_Build" class="col-5 mr-0 pr-0 text-right">
                 <i title="Edit" (click)="isApprovedReported=false;approveReport(null)" class="fa-lg fal fa-pencil"></i>
             </div>
         </div>
         <div *ngIf="isFromPreview" class="col-12 p-0 mt-3 mr-0 row">
             <div *ngIf="creativeAsset.isValid" class="col-9 m-0 ">
                 <i class="fa-lg fal fa-check-circle"> Approved</i>
             </div>
             <div *ngIf="!creativeAsset.isValid" class="col-9 m-0">
                 <i class="fa-lg fal fa-times-circle"> Reported</i>
             </div>
             <div *ngIf="!isBuilt && statusId != enumCampaignStatus.Sent_to_API_Build" class="col-3 mr-0 pr-0 text-right">
                 <i title="Edit" (click)="isApprovedReported=false;approveReport(null)" class="fa-lg fal fa-pencil"></i>
             </div>
         </div>

     </div>
     <div *ngIf="!isBuilt && statusId != enumCampaignStatus.Sent_to_API_Build" class="col-12">
         <textarea style="min-width: 100%;" rows="5" readonly="true" pInputTextarea placeholder="" [(ngModel)]="creativeAsset.validationMessage"></textarea>
     </div>

 </div>