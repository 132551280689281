<div fullPageLoader>
    <div progressBar (onProgressBarChange)="onProgressBarChange($event)">
        <div class="text-center mb-3 progressBar">Loading...</div>
        <p-progressBar [value]="progressBarValue" [showValue]="false" class="bg-primary"></p-progressBar>
        <span class="progress-bar-value">{{progressBarValue}}%</span>
    </div>
</div>
<div class="page-loader-bg"></div>
<router-outlet></router-outlet>
<p-toast></p-toast>
