//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IEnumDataAttribute } from './interfaces';

export enum eEnvironment {
	NotSet = 0,
	Local = 1,
	Development = 2,
	QA = 3,
	Staging = 4,
	Live = 5,
	Migration = 6,
	QAJourney = 7,
	DevelopmentYellowstone = 8,
	QAYellowstone = 9
}
export enum eProviderType {
	AzureAD = 1
}
export enum eFilterDefiniton {
	Equals = 1,
	Contains = 2
}
export enum eChannel {
	NorSet = 0,
	Single = 1,
	Multi = 2
}
export enum eAnnualMarketingSpend {
	NotSet = 0,
	Less_10K = 1,
	More_10K = 2
}
export enum eApprovalStatus {
	APPROVAL_STATUS_UNSPECIFIED = 1,
	APPROVAL_STATUS_PENDING_NOT_SERVABLE = 2,
	APPROVAL_STATUS_PENDING_SERVABLE = 3,
	APPROVAL_STATUS_APPROVED_SERVABLE = 4,
	APPROVAL_STATUS_REJECTED_NOT_SERVABLE = 5
}
export enum eAssetRole {
	ASSET_ROLE_UNSPECIFIED = 1,
	ASSET_ROLE_MAIN = 2,
	ASSET_ROLE_BACKUP = 3,
	ASSET_ROLE_POLITE_LOAD = 4,
	ASSET_ROLE_HEADLINE = 5,
	ASSET_ROLE_LONG_HEADLINE = 6,
	ASSET_ROLE_BODY = 7,
	ASSET_ROLE_LONG_BODY = 8,
	ASSET_ROLE_CAPTION_URL = 9,
	ASSET_ROLE_CALL_TO_ACTION = 10,
	ASSET_ROLE_ADVERTISER_NAME = 11,
	ASSET_ROLE_PRICE = 12,
	ASSET_ROLE_ANDROID_APP_ID = 13,
	ASSET_ROLE_IOS_APP_ID = 14,
	ASSET_ROLE_RATING = 15,
	ASSET_ROLE_COVER_IMAGE = 16
}
export enum eAssetType {
	NotSet = 1
}
export enum eCampaignGoal {
	Notset = 0,
	Branding_And_Awareness = 1,
	Direct_Response = 2,
	Quick_Promotion = 3
}
export enum eCampaignGoalType {
	CAMPAIGN_GOAL_TYPE_UNSPECIFIED = 1,
	CAMPAIGN_GOAL_TYPE_APP_INSTALL = 2,
	CAMPAIGN_GOAL_TYPE_BRAND_AWARENESS = 3,
	CAMPAIGN_GOAL_TYPE_OFFLINE_ACTION = 4,
	CAMPAIGN_GOAL_TYPE_ONLINE_ACTION = 5
}
export enum eCreativeAttribute {
	CREATIVE_ATTRIBUTE_UNSPECIFIED = 1,
	CREATIVE_ATTRIBUTE_VAST = 2,
	CREATIVE_ATTRIBUTE_VPAID_LINEAR = 3,
	CREATIVE_ATTRIBUTE_VPAID_NON_LINEAR = 4
}
export enum eDigitalPercentageSpend {
	NotSet = 0,
	Percentage_1 = 1,
	Percentage_10 = 2
}
export enum eEntityStatus {
	Active = 1,
	Inactive = 2,
	Transfered = 3,
	Deleted = 99
}
export enum eExchange {
	EXCHANGE_UNSPECIFIED = 1,
	EXCHANGE_GOOGLE_AD_MANAGER = 2,
	EXCHANGE_APPNEXUS = 3,
	EXCHANGE_BRIGHTROLL = 4,
	EXCHANGE_ADFORM = 5,
	EXCHANGE_ADMETA = 6,
	EXCHANGE_ADMIXER = 7,
	EXCHANGE_ADSMOGO = 8,
	EXCHANGE_ADSWIZZ = 9,
	EXCHANGE_BIDSWITCH = 10,
	EXCHANGE_BRIGHTROLL_DISPLAY = 11,
	EXCHANGE_CADREON = 12,
	EXCHANGE_DAILYMOTION = 13,
	EXCHANGE_FIVE = 14,
	EXCHANGE_FLUCT = 15,
	EXCHANGE_FREEWHEEL = 16,
	EXCHANGE_GENIEE = 17,
	EXCHANGE_GUMGUM = 18,
	EXCHANGE_IMOBILE = 19,
	EXCHANGE_IBILLBOARD = 20,
	EXCHANGE_IMPROVE_DIGITAL = 21,
	EXCHANGE_INDEX = 22,
	EXCHANGE_KARGO = 23,
	EXCHANGE_MICROAD = 24,
	EXCHANGE_MOPUB = 25,
	EXCHANGE_NEND = 26,
	EXCHANGE_ONE_BY_AOL_DISPLAY = 27,
	EXCHANGE_ONE_BY_AOL_MOBILE = 28,
	EXCHANGE_ONE_BY_AOL_VIDEO = 29,
	EXCHANGE_OOYALA = 30,
	EXCHANGE_OPENX = 31,
	EXCHANGE_PERMODO = 32,
	EXCHANGE_PLATFORMONE = 33,
	EXCHANGE_PLATFORMID = 34,
	EXCHANGE_PUBMATIC = 35,
	EXCHANGE_PULSEPOINT = 36,
	EXCHANGE_REVENUEMAX = 37,
	EXCHANGE_RUBICON = 38,
	EXCHANGE_SMARTCLIP = 39,
	EXCHANGE_SMARTRTB = 40,
	EXCHANGE_SMARTSTREAMTV = 41,
	EXCHANGE_SOVRN = 42,
	EXCHANGE_SPOTXCHANGE = 43,
	EXCHANGE_STROER = 44,
	EXCHANGE_TEADSTV = 45,
	EXCHANGE_TELARIA = 46,
	EXCHANGE_TVN = 47,
	EXCHANGE_UNITED = 48,
	EXCHANGE_YIELDLAB = 49,
	EXCHANGE_YIELDMO = 50,
	EXCHANGE_TRIPLELIFT = 51,
	EXCHANGE_TABOOLA = 52,
	EXCHANGE_INMOBI = 53,
	EXCHANGE_SMAATO = 54,
	EXCHANGE_AJA = 55,
	EXCHANGE_NEXSTAR_DIGITAL = 56,
	EXCHANGE_WAZE = 57
}
export enum eExitEventType {
	EXIT_EVENT_TYPE_UNSPECIFIED = 1,
	EXIT_EVENT_TYPE_DEFAULT = 2,
	EXIT_EVENT_TYPE_BACKUP = 3
}
export enum eExpandingDirection {
	EXPANDING_DIRECTION_UNSPECIFIED = 1,
	EXPANDING_DIRECTION_NONE = 2,
	EXPANDING_DIRECTION_UP = 3,
	EXPANDING_DIRECTION_DOWN = 4,
	EXPANDING_DIRECTION_LEFT = 5,
	EXPANDING_DIRECTION_RIGHT = 6,
	EXPANDING_DIRECTION_UP_AND_LEFT = 7,
	EXPANDING_DIRECTION_UP_AND_RIGHT = 8,
	EXPANDING_DIRECTION_DOWN_AND_LEFT = 9,
	EXPANDING_DIRECTION_DOWN_AND_RIGHT = 10,
	EXPANDING_DIRECTION_UP_OR_DOWN = 11,
	EXPANDING_DIRECTION_LEFT_OR_RIGHT = 12,
	EXPANDING_DIRECTION_ANY_DIAGONAL = 13
}
export enum eHostingSource {

}
export enum eObaIconPosition {
	OBA_ICON_POSITION_UNSPECIFIED = 1,
	OBA_ICON_POSITION_UPPER_RIGHT = 2,
	OBA_ICON_POSITION_UPPER_LEFT = 3,
	OBA_ICON_POSITION_LOWER_RIGHT = 4,
	OBA_ICON_POSITION_LOWER_LEFT = 5
}
export enum eReviewStatus {
	REVIEW_STATUS_UNSPECIFIED = 1,
	REVIEW_STATUS_APPROVED = 2,
	REVIEW_STATUS_REJECTED = 3,
	REVIEW_STATUS_PENDING = 4
}
export enum eTeam {
	NotSet = 0,
	HubTeam = 1,
	TeslaMogoTeam = 2
}
export enum eThirdPartUrlType {
	THIRD_PARTY_URL_TYPE_UNSPECIFIED = 1,
	THIRD_PARTY_URL_TYPE_IMPRESSION = 2,
	THIRD_PARTY_URL_TYPE_CLICK_TRACKING = 3,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_START = 4,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_FIRST_QUARTILE = 5,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_MIDPOINT = 6,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_THIRD_QUARTILE = 7,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_COMPLETE = 8,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_MUTE = 9,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_PAUSE = 10,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_REWIND = 11,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_FULLSCREEN = 12,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_STOP = 13,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_CUSTOM = 14,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_SKIP = 15,
	THIRD_PARTY_URL_TYPE_AUDIO_VIDEO_PROGRESS = 16
}
export enum eUniversalAdRegistry {
	UNIVERSAL_AD_REGISTRY_UNSPECIFIED = 1,
	UNIVERSAL_AD_REGISTRY_OTHER = 2,
	UNIVERSAL_AD_REGISTRY_AD_ = 3,
	UNIVERSAL_AD_REGISTRY_DV360 = 4,
	UNIVERSAL_AD_REGISTRY_CM = 5
}
export enum eAccountType {
	NotSet = 0,
	FAN365 = 1,
	HS = 2,
	HMP = 3,
	OAS = 4,
	Social = 5,
	Content = 7
}
export enum eCampaignStatus {
	Notset = 0,
	Assets_Pending = 1,
	Assets_Uploaded = 2,
	Assets_Approved = 3,
	Payment_Pending = 4,
	Payment_Completed = 5,
	Campaign_Draft = 6,
	Campaign_Ended = 7,
	Campaign_Live = 8,
	Build_Failed = 9,
	Archived = 10,
	Sent_to_API_Build = 11,
	Assets_Declined = 12,
	Build_Success = 13,
	Campaign_Paused = 14,
	API_Build_Started = 15,
	Live_365_Update = 16,
	Activated_outside_of_Hub = 17,
	Campaign_Expired = 18,
	Creative_Swaps = 19,
	Build_Bulk_Edit_Success = 20,
	Build_Bulk_Edit_Failed = 21,
	Build_Impression_Flight_Success = 22,
	Build_Impression_Flight_Failed = 23,
	Creative_Swap_Success = 24,
	Creative_Swap_Failed = 25,
	Live365_Impression_Update = 26
}
export enum eCreativeType {
	StaticImage = 1,
	StaticImageCopy = 2,
	Video = 4,
	VideoCopy = 8,
	StaticImageVideoCopy = 16,
	Entitlement = 32
}
export enum eChannelType {
	NotSet = 0,
	Social = 1,
	Display = 2,
	Other = 3,
	Social_campaign = 4
}
export enum eStoriesType {
	Stories = 1,
	Reels = 2
}
export enum eIndustry {
	NotSet = 0,
	ARTS = 1,
	FINANCIAL_SERVICES = 2,
	PUBLIC_MEDIA = 3,
	eCommerce = 4,
	HIGHER_EDUCATION = 5,
	ATHLETICS = 6
}
export enum eUserAccountRole {
	NotSet = 0,
	Lead = 1,
	Member = 2,
	Associate = 3,
	Clien_Solution = 4,
	Media_Activation = 5
}
export enum eUserTeamRole {
	NotSet = 0,
	Associate = 1,
	Clien_Solution = 2,
	Media_Activation = 3
}
export enum eUserRole {
	NotSet = 0,
	Client = 1,
	Admin = 2,
	Associate = 3,
	Client_Solution = 4,
	Media_Activation = 5,
	LIMGC_AdOps_Admin = 6,
	LIMGCSocialAdOps = 7
}
export enum eCreditCardType {
	NotSet = 0,
	Visa = 1,
	MasterCard = 2,
	Discover = 3,
	AmericanExpress = 4
}
export enum eEmailStatusType {
	NotSet = 0,
	NotSent = 1,
	Sent = 2,
	Failed = 3,
	DisabledByUser = 4,
	FaultyUserEmailConfiguration = 5,
	FaultyUserEmail = 6,
	Processed = 7,
	Dropped = 8,
	Deffered = 9,
	Delivered = 10,
	Bounced = 11
}
export enum eEmailType {
	NotSet = 0
}
export enum eValidationType {
	NotSet = 0,
	Create = 1,
	Update = 2,
	Delete = 3
}
export enum eFileExtension {
	jpg = 0,
	png = 1,
	gif = 2,
	zip = 3,
	HTML5 = 4,
	mp4 = 5,
	mp3 = 6,
	wav = 7,
	ogg = 8,
	doc = 9,
	dox = 10,
	xsl = 11,
	xslx = 12,
	csv = 13,
	jpeg = 14,
	pdf = 15
}
export enum eFileType {
	Not_Set = 0,
	FacebookStaticNewsFeed = 1,
	DisplayBanner = 2,
	FacebookNewsFeedStaticImage = 3,
	FacebookNewsFeedDynamicVideo = 4,
	NativeVideo = 5,
	FacebookNewsFeedDynamicImage = 6,
	InStreamVideo = 7,
	LeadGeneration = 8,
	LeadGenerationStatic = 9,
	NativeImage = 10,
	SpotifyAudio = 11,
	NativeVideoStatic = 12,
	SpotifyAudioStatic = 13,
	SpotifyVideo = 14,
	SpotifyVideoStatic = 15,
	InstantExperienceStatic = 16,
	InstantExperience = 17,
	StoriesStatis = 18,
	Stories = 19,
	CRM = 20,
	Report = 21,
	OASDisplay = 25,
	OASVideo = 26,
	SocialCampaign = 27,
	ContentCampaign = 28,
	LinkedinImage = 29,
	LinkedinVideo = 30,
	LinkedinCarousel = 31,
	TikTokInFeedAds = 32
}
export enum eVideoType {
	Not_set = 0,
	In_stream = 1,
	CTV = 2
}
export enum eLinkType {
	Not_Set = 0,
	GCMLink = 1,
	GoogleSheetLink = 2
}
export enum eCallToAction {
	Not_Set = 0,
	Contact_Us = 1,
	Download = 2,
	Get_Quote = 3,
	Get_Showtimes = 4,
	Learn_More = 5,
	Listen_Now = 6,
	No_Button = 7,
	Request_Time = 8,
	Save = 9,
	See_Menu = 10,
	Shop_Now = 11,
	Sign_Up = 12,
	Subscribe = 13,
	Watch_More = 14,
	Get_promotions = 15,
	Apply_now = 16,
	Book_now = 17,
	Donate_now = 18,
	Get_offer = 19,
	Order_now = 20,
	Get_Access = 21,
	Get_updates = 22
}
export enum eRegion {
	Not_set = 0,
	East_South = 1,
	Central = 2,
	West = 3,
	East = 4,
	South = 5,
	North = 6
}
export enum eApiBuildStatus {
	Notset = 0,
	DV360 = 1,
	TTD = 2
}
export enum eInventoryTypeOAS {
	NotSet = 0,
	Entitlement = 1,
	Geo_Demo_Targeted = 2,
	Page_Takeover = 3,
	Ros_Display_Campaign = 4,
	Ros_Video_Campaign = 5,
	Page_Takeover_Premium = 6,
	Trade_Deal = 7,
	Share_Of_Voice = 8
}
export enum eInventoryTypeSocial {
	NotSet = 0,
	Digital_Game = 1,
	Content = 2,
	Social_Lead_Generation = 3,
	Social_Lead_Generation_Premium = 4,
	Paid_support = 5,
	Snap_AR = 6,
	Flexible_Campaign = 7
}
export enum eCreativeTheme {
	Not_Set = 0,
	Athletics_Branded_Not_Sport_Specific = 1,
	Football = 2,
	Basketball = 3,
	Hockey = 4,
	Volleyball = 5,
	Other = 6
}
export enum eContentType {
	Not_Set = 0,
	Traffic_Driver = 1,
	Straight_Content = 2,
	Other = 3
}
export enum eGameMechanic {
	Not_Set = 0,
	Scratch_Off = 1,
	Spin_to_Win = 2,
	Puzzle = 3,
	Skill_Game = 4,
	Quiz = 5,
	Slot_Machine = 6,
	Pick_Poll = 7,
	Other = 8
}
export enum eCTALinkedinImage {
	Not_Set = 0,
	Learn_More_Or_Read_More = 100,
	Download = 101,
	Register_Sign_Up_Or_Subscribe = 102,
	Complete_The_Form = 103
}
export enum eCTALinkedinVideo {
	Not_Set = 0,
	Apply = 200,
	Download = 201,
	Learn_More = 202,
	Sign_Up = 203,
	Subscribe = 204,
	Register = 205,
	Join = 206,
	Attend = 207
}
export enum eCTATikTok {
	Not_Set = 0,
	Apply_Now = 300,
	Book_Now = 301,
	Contact_Us = 302,
	Download = 303,
	Experience_Now = 304,
	Get_Quote = 305,
	Get_Showtimes = 306,
	Get_Tickets_Now = 307,
	Install_Now = 308,
	Interested = 309,
	Learn_More = 310,
	Listen_Now = 311,
	Order_Now = 312,
	Play_Game = 313,
	Pre_Order_Now = 314,
	Read_More = 315,
	Shop_Now = 316,
	Sign_Up = 317,
	Subscribe = 318,
	View_Now = 319,
	Visit_Store = 320,
	Watch_Now = 321
}
export enum eStandardCampaginChannel {
	NotSet = 0,
	Facebook_Static_News_Feed = 1,
	Facebook_Dynamic_News_Feed = 2,
	Facebook_Dynamic_News_Feed_Video = 3,
	Facebook_Dynamic_News_Feed_Carousel = 4,
	Standard_Display_Banner = 5,
	Premium_Display_Banner = 6,
	Abandoned_Cart_Display_Banner = 7,
	Post_Purchase_Upsell_Display_Banner = 8,
	Spotify_Video = 9,
	Spotify_Audio = 10,
	In_Stream_Video = 11,
	Google_AdWords = 12,
	Native_Image = 13,
	Native_Video = 14,
	Facebook_Static_News_Feed_Video = 15,
	Lead_Generation = 16,
	Instant_Experience = 17,
	Stories = 18,
	ConnectedTv = 19,
	OAS_Display = 20,
	OAS_Video = 21,
	Paid_support = 22,
	Content = 23,
	Snap_AR = 24,
	Social_Lead_Generation = 25,
	Social_Lead_Generation_Premium = 26,
	Digital_Game = 27,
	Flexible_Campaign = 28,
	LinkedIn_News_Feed_Image = 29,
	LinkedIn_News_Feed_Video = 30,
	LinkedIn_Dynamic_News_Feed_Carousel = 31,
	TikTok_In_Feed_Ads = 32,
	YouTube_Video = 33
}

export const eStandardCampaginChannelName = new Map<number, IEnumDataAttribute>([
[eStandardCampaginChannel.NotSet, {}],
[eStandardCampaginChannel.Facebook_Static_News_Feed, {userTypeId:1,name:"Facebook Static News Feed",channelId:1}],
[eStandardCampaginChannel.Facebook_Dynamic_News_Feed, {userTypeId:0,name:"Facebook Dynamic News Feed",channelId:1}],
[eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video, {userTypeId:0,name:"Facebook Dynamic News Feed Video",channelId:1}],
[eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel, {userTypeId:0,name:"Facebook Dynamic News Feed Carousel",channelId:1}],
[eStandardCampaginChannel.Standard_Display_Banner, {userTypeId:0,name:"Standard Display Banner",channelId:2}],
[eStandardCampaginChannel.Premium_Display_Banner, {userTypeId:0,name:"Premium Display Banner",channelId:2}],
[eStandardCampaginChannel.Abandoned_Cart_Display_Banner, {userTypeId:0,name:"Abandoned Cart Display Banner",channelId:2}],
[eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner, {userTypeId:0,name:"Post Purchase Upsell Display Banner",channelId:2}],
[eStandardCampaginChannel.Spotify_Video, {userTypeId:0,name:"Spotify Video",channelId:3}],
[eStandardCampaginChannel.Spotify_Audio, {userTypeId:0,name:"Spotify Audio",channelId:3}],
[eStandardCampaginChannel.In_Stream_Video, {userTypeId:0,name:"In Stream Video",channelId:3}],
[eStandardCampaginChannel.Google_AdWords, {userTypeId:0,name:"Google AdWords",channelId:3}],
[eStandardCampaginChannel.Native_Image, {userTypeId:0,name:"Native Image",channelId:3}],
[eStandardCampaginChannel.Native_Video, {userTypeId:0,name:"Native Video",channelId:3}],
[eStandardCampaginChannel.Facebook_Static_News_Feed_Video, {userTypeId:0,name:"Facebook Static News Feed Video",channelId:1}],
[eStandardCampaginChannel.Lead_Generation, {userTypeId:0,name:"Lead Generation",channelId:1}],
[eStandardCampaginChannel.Instant_Experience, {userTypeId:0,name:"Instant Experience",channelId:1}],
[eStandardCampaginChannel.Stories, {userTypeId:0,name:"Stories",channelId:1}],
[eStandardCampaginChannel.ConnectedTv, {userTypeId:0,name:"Connected TV",channelId:3}],
[eStandardCampaginChannel.OAS_Display, {userTypeId:0,name:"OAS Display",channelId:2}],
[eStandardCampaginChannel.OAS_Video, {userTypeId:0,name:"OAS Video",channelId:2}],
[eStandardCampaginChannel.Paid_support, {userTypeId:0,name:"Paid Support",channelId:4}],
[eStandardCampaginChannel.Content, {userTypeId:0,name:"Content",channelId:4}],
[eStandardCampaginChannel.Snap_AR, {userTypeId:0,name:"Snap AR Lens",channelId:4}],
[eStandardCampaginChannel.Social_Lead_Generation, {userTypeId:0,name:"Lead Generation (Sweepstakes)",channelId:4}],
[eStandardCampaginChannel.Social_Lead_Generation_Premium, {userTypeId:0,name:"Premium Lead Generation (Sweepstakes with Additional Engagement Element)",channelId:4}],
[eStandardCampaginChannel.Digital_Game, {userTypeId:0,name:"Digital Game",channelId:4}],
[eStandardCampaginChannel.Flexible_Campaign, {userTypeId:0,name:"Flexible campaign",channelId:4}],
[eStandardCampaginChannel.LinkedIn_News_Feed_Image, {userTypeId:0,name:"LinkedIn News Feed Image",channelId:1}],
[eStandardCampaginChannel.LinkedIn_News_Feed_Video, {userTypeId:0,name:"LinkedIn News Feed Video",channelId:1}],
[eStandardCampaginChannel.LinkedIn_Dynamic_News_Feed_Carousel, {userTypeId:0,name:"LinkedIn Dynamic News Feed Carousel",channelId:1}],
[eStandardCampaginChannel.TikTok_In_Feed_Ads, {userTypeId:0,name:"TikTok In Feed Ads",channelId:1}],
[eStandardCampaginChannel.YouTube_Video, {userTypeId:0,name:"YouTube Video",channelId:3}]]);

export enum eTimeUnit {
	NotSet = 0,
	TIME_UNIT_UNSPECIFIED = 1,
	TIME_UNIT_LIFETIME = 2,
	TIME_UNIT_MONTHS = 3,
	TIME_UNIT_WEEKS = 4,
	TIME_UNIT_DAYS = 5,
	TIME_UNIT_HOURS = 6,
	TIME_UNIT_MINUTES = 7
}

export const eTimeUnitName = new Map<number, IEnumDataAttribute>([
[eTimeUnit.NotSet, {userTypeId:0,name:"Not Set",channelId:0}],
[eTimeUnit.TIME_UNIT_UNSPECIFIED, {userTypeId:0,name:"Not specified",channelId:0}],
[eTimeUnit.TIME_UNIT_LIFETIME, {userTypeId:0,name:"Lifetime",channelId:0}],
[eTimeUnit.TIME_UNIT_MONTHS, {userTypeId:0,name:"Month(s)",channelId:0}],
[eTimeUnit.TIME_UNIT_WEEKS, {userTypeId:0,name:"Week(s)",channelId:0}],
[eTimeUnit.TIME_UNIT_DAYS, {userTypeId:0,name:"Day(s)",channelId:0}],
[eTimeUnit.TIME_UNIT_HOURS, {userTypeId:0,name:"Hour(s)",channelId:0}],
[eTimeUnit.TIME_UNIT_MINUTES, {userTypeId:0,name:"Minute(s)",channelId:0}]]);

export enum eAgeFrom {
	None = 0,
	From18 = 1,
	From25 = 2,
	From35 = 3,
	From45 = 4,
	From55 = 5,
	From65d = 6
}

export const eAgeFromName = new Map<number, IEnumDataAttribute>([
[eAgeFrom.None, {userTypeId:0,name:"None",channelId:0}],
[eAgeFrom.From18, {userTypeId:0,name:"18",channelId:0}],
[eAgeFrom.From25, {userTypeId:0,name:"25",channelId:0}],
[eAgeFrom.From35, {userTypeId:0,name:"35",channelId:0}],
[eAgeFrom.From45, {userTypeId:0,name:"45",channelId:0}],
[eAgeFrom.From55, {userTypeId:0,name:"55",channelId:0}],
[eAgeFrom.From65d, {userTypeId:0,name:"65",channelId:0}]]);

export enum eAgeTo {
	None = 0,
	To25 = 1,
	To35 = 2,
	To45 = 3,
	To55 = 4,
	To65 = 5,
	Plus = 6
}

export const eAgeToName = new Map<number, IEnumDataAttribute>([
[eAgeTo.None, {userTypeId:0,name:"None",channelId:0}],
[eAgeTo.To25, {userTypeId:0,name:"25",channelId:0}],
[eAgeTo.To35, {userTypeId:0,name:"35",channelId:0}],
[eAgeTo.To45, {userTypeId:0,name:"45",channelId:0}],
[eAgeTo.To55, {userTypeId:0,name:"55",channelId:0}],
[eAgeTo.To65, {userTypeId:0,name:"65",channelId:0}],
[eAgeTo.Plus, {userTypeId:0,name:"+",channelId:0}]]);

export enum eSweepstakesCategory {
	NotSet = 0,
	Countdown_Calendar = 1,
	Trivia = 2,
	Bracket = 3,
	Personality_Quiz = 4,
	Photo_Contest = 5,
	Vote_to_Win = 6,
	Enter_to_Win = 7,
	Nomination_Based = 8,
	Memory_Matching_Game = 9,
	Ecard_Fill_In_The_Blank = 10,
	Other = 11
}

export const eSweepstakesCategoryName = new Map<number, IEnumDataAttribute>([
[eSweepstakesCategory.NotSet, {userTypeId:0,name:"Not Set",channelId:0}],
[eSweepstakesCategory.Countdown_Calendar, {userTypeId:0,name:"Countdown Calendar",channelId:0}],
[eSweepstakesCategory.Trivia, {userTypeId:0,name:"Trivia",channelId:0}],
[eSweepstakesCategory.Bracket, {userTypeId:0,name:"Bracket",channelId:0}],
[eSweepstakesCategory.Personality_Quiz, {userTypeId:0,name:"Personality Quiz",channelId:0}],
[eSweepstakesCategory.Photo_Contest, {userTypeId:0,name:"Photo Contest",channelId:0}],
[eSweepstakesCategory.Vote_to_Win, {userTypeId:0,name:"Vote-to-Win",channelId:0}],
[eSweepstakesCategory.Enter_to_Win, {userTypeId:0,name:"Enter-to-Win",channelId:0}],
[eSweepstakesCategory.Nomination_Based, {userTypeId:0,name:"Nomination-Based",channelId:0}],
[eSweepstakesCategory.Memory_Matching_Game, {userTypeId:0,name:"Memory Matching Game",channelId:0}],
[eSweepstakesCategory.Ecard_Fill_In_The_Blank, {userTypeId:0,name:"Ecard/Fill-In-The-Blank",channelId:0}],
[eSweepstakesCategory.Other, {userTypeId:0,name:"Other",channelId:0}]]);

export enum eGender {
	None = 0,
	Male = 1,
	Female = 2
}

export const eGenderName = new Map<number, IEnumDataAttribute>([
[eGender.None, {}],
[eGender.Male, {userTypeId:0,name:"Male",channelId:0}],
[eGender.Female, {userTypeId:0,name:"Female",channelId:0}]]);

export enum eIncomeFrom {
	None = 0,
	Lower50 = 1,
	From41To50 = 2,
	From31To40 = 3,
	From21To30 = 4,
	From11To20 = 5,
	Top10 = 6
}

export const eIncomeFromName = new Map<number, IEnumDataAttribute>([
[eIncomeFrom.None, {userTypeId:0,name:"None",channelId:0}],
[eIncomeFrom.Lower50, {userTypeId:0,name:"Lower 50%",channelId:0}],
[eIncomeFrom.From41To50, {userTypeId:0,name:"41% ~ 50%",channelId:0}],
[eIncomeFrom.From31To40, {userTypeId:0,name:"31% ~ 40%",channelId:0}],
[eIncomeFrom.From21To30, {userTypeId:0,name:"21% ~ 30%",channelId:0}],
[eIncomeFrom.From11To20, {userTypeId:0,name:"11% ~ 20%",channelId:0}],
[eIncomeFrom.Top10, {userTypeId:0,name:"Top 10%",channelId:0}]]);

export enum eIncomeTo {
	None = 0,
	Lower50 = 1,
	From41To50 = 2,
	From31To40 = 3,
	From21To30 = 4,
	From11To20 = 5,
	Top10 = 6
}

export const eIncomeToName = new Map<number, IEnumDataAttribute>([
[eIncomeTo.None, {userTypeId:0,name:"None",channelId:0}],
[eIncomeTo.Lower50, {userTypeId:0,name:"Lower 50%",channelId:0}],
[eIncomeTo.From41To50, {userTypeId:0,name:"41% ~ 50%",channelId:0}],
[eIncomeTo.From31To40, {userTypeId:0,name:"31% ~ 40%",channelId:0}],
[eIncomeTo.From21To30, {userTypeId:0,name:"21% ~ 30%",channelId:0}],
[eIncomeTo.From11To20, {userTypeId:0,name:"11% ~ 20%",channelId:0}],
[eIncomeTo.Top10, {userTypeId:0,name:"Top 10%",channelId:0}]]);

export enum eParentalStatus {
	None = 0,
	Parent = 1,
	NotAParent = 2
}

export const eParentalStatusName = new Map<number, IEnumDataAttribute>([
[eParentalStatus.None, {}],
[eParentalStatus.Parent, {userTypeId:0,name:"Parent",channelId:0}],
[eParentalStatus.NotAParent, {userTypeId:0,name:"Not a Parent",channelId:0}]]);

export enum eHMPCampaignPackages {
	NotSet = 0,
	Grow = 1,
	Expand = 2,
	Amplify = 3,
	Thrive = 4,
	Display = 5,
	Social = 6,
	Multi_channel = 7,
	Amplify_Flex = 8
}

export const eHMPCampaignPackagesName = new Map<number, IEnumDataAttribute>([
[eHMPCampaignPackages.NotSet, {}],
[eHMPCampaignPackages.Grow, {userTypeId:0,name:"Grow",channelId:0}],
[eHMPCampaignPackages.Expand, {userTypeId:0,name:"Expand",channelId:0}],
[eHMPCampaignPackages.Amplify, {userTypeId:0,name:"Amplify",channelId:0}],
[eHMPCampaignPackages.Thrive, {userTypeId:0,name:"Thrive",channelId:0}],
[eHMPCampaignPackages.Display, {userTypeId:0,name:"Display",channelId:0}],
[eHMPCampaignPackages.Social, {userTypeId:0,name:"Social",channelId:0}],
[eHMPCampaignPackages.Multi_channel, {userTypeId:0,name:"Multi-channel",channelId:0}],
[eHMPCampaignPackages.Amplify_Flex, {userTypeId:0,name:"Amplify Flex",channelId:0}]]);

export enum ePerformanceGoalTypeDV360 {
	NotSet = 0,
	PERFORMANCE_GOAL_TYPE_CPM = 1,
	PERFORMANCE_GOAL_TYPE_CPC = 2,
	PERFORMANCE_GOAL_TYPE_CPA = 3,
	PERFORMANCE_GOAL_TYPE_CTR = 4,
	PERFORMANCE_GOAL_TYPE_VIEWABILITY = 5,
	PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE = 6
}

export const ePerformanceGoalTypeDV360Name = new Map<number, IEnumDataAttribute>([
[ePerformanceGoalTypeDV360.NotSet, {userTypeId:0,name:"Not Set",channelId:0}],
[ePerformanceGoalTypeDV360.PERFORMANCE_GOAL_TYPE_CPM, {userTypeId:2,name:"Cost per thousand impressions (CPM)",channelId:0}],
[ePerformanceGoalTypeDV360.PERFORMANCE_GOAL_TYPE_CPC, {userTypeId:2,name:"Cost per click (CPC)",channelId:0}],
[ePerformanceGoalTypeDV360.PERFORMANCE_GOAL_TYPE_CPA, {userTypeId:2,name:"Cost per action (CPA)",channelId:0}],
[ePerformanceGoalTypeDV360.PERFORMANCE_GOAL_TYPE_CTR, {userTypeId:1,name:"Impression click though rate (CTR)",channelId:0}],
[ePerformanceGoalTypeDV360.PERFORMANCE_GOAL_TYPE_VIEWABILITY, {userTypeId:1,name:"Viewability %",channelId:0}],
[ePerformanceGoalTypeDV360.PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE, {userTypeId:1,name:"Completion Rate",channelId:0}]]);

export enum ePerformanceGoalTypeTTD {
	NotSet = 0,
	CTRInPercent = 1,
	CPCInAdvertiserCurrency = 2,
	CPAInAdvertiserCurrency = 3,
	VCRInPercent = 4,
	ViewabilityInPercent = 5
}

export const ePerformanceGoalTypeTTDName = new Map<number, IEnumDataAttribute>([
[ePerformanceGoalTypeTTD.NotSet, {userTypeId:0,name:"Not Set",channelId:0}],
[ePerformanceGoalTypeTTD.CTRInPercent, {userTypeId:1,name:"CTR - Click-Through Rate",channelId:0}],
[ePerformanceGoalTypeTTD.CPCInAdvertiserCurrency, {userTypeId:2,name:"CPC - Cost Per Click",channelId:0}],
[ePerformanceGoalTypeTTD.CPAInAdvertiserCurrency, {userTypeId:2,name:"CPA - Cost Per Acquisition",channelId:0}],
[ePerformanceGoalTypeTTD.VCRInPercent, {userTypeId:1,name:"VCR - Viewable Completion Rate",channelId:0}],
[ePerformanceGoalTypeTTD.ViewabilityInPercent, {userTypeId:1,name:"Viewability ",channelId:0}]]);

export enum eTimeUnitTTD {
	NotSet = 0,
	TTD_MINUTES = 1,
	TTD_HOURS = 60,
	TTD_DAYS = 1440,
	TTD_WEEKS = 10800,
	TTD_LIFETIME = 2147483647
}

export const eTimeUnitTTDName = new Map<number, IEnumDataAttribute>([
[eTimeUnitTTD.NotSet, {userTypeId:0,name:"Not Set",channelId:0}],
[eTimeUnitTTD.TTD_MINUTES, {userTypeId:0,name:"Minute(s)",channelId:0}],
[eTimeUnitTTD.TTD_HOURS, {userTypeId:0,name:"Hour(s)",channelId:0}],
[eTimeUnitTTD.TTD_DAYS, {userTypeId:0,name:"Day(s)",channelId:0}],
[eTimeUnitTTD.TTD_WEEKS, {userTypeId:0,name:"Week(s)",channelId:0}],
[eTimeUnitTTD.TTD_LIFETIME, {userTypeId:0,name:"Lifetime",channelId:0}]]);

export enum eBiddingStrategyPerformanceGoalType {
	NotSet = 0,
	BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CPA = 1,
	BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CPC = 2,
	BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_AV_VIEWED = 3
}

export const eBiddingStrategyPerformanceGoalTypeName = new Map<number, IEnumDataAttribute>([
[eBiddingStrategyPerformanceGoalType.NotSet, {userTypeId:0,name:"Not Set",channelId:0}],
[eBiddingStrategyPerformanceGoalType.BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CPA, {userTypeId:0,name:"Maximize conversions",channelId:0}],
[eBiddingStrategyPerformanceGoalType.BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CPC, {userTypeId:0,name:"Maximize clicks",channelId:0}],
[eBiddingStrategyPerformanceGoalType.BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_AV_VIEWED, {userTypeId:0,name:"Maximize viewable impressions",channelId:0}]]);

export enum eCRMType {
	NotSet = 0,
	Target = 1,
	Suppression = 2,
	Evergreen = 3
}

export const eCRMTypeName = new Map<number, IEnumDataAttribute>([
[eCRMType.NotSet, {userTypeId:0,name:"Not Set",channelId:0}],
[eCRMType.Target, {userTypeId:0,name:"Target - One Campaign Use",channelId:0}],
[eCRMType.Suppression, {userTypeId:0,name:"Suppression",channelId:0}],
[eCRMType.Evergreen, {userTypeId:0,name:"Evergreen - Multi-Campaign Use",channelId:0}]]);

export enum eCodeLanguage {
	NotSet = 0,
	CSharp = 1,
	Php = 2,
	JS = 3,
	ActionScript = 4,
	ColdFusion = 5,
	Bash = 6,
	Delphi = 7,
	Java = 8,
	Perl = 9,
	Python = 10,
	Ruby = 11,
	VisualBasic = 12,
	SQL = 13
}
export enum eSensorType {
	NotSet = 1,
	CriticalError = 2,
	DegradedFunctionality = 4,
	Operational = 16
}
export enum eMessageType {
	NotSet = 0,
	Error = 2,
	Warning = 4,
	Message = 8,
	Success = 16,
	Unknown = 32,
	NotApplicable = 64,
	NoResponse = 128,
	Email = 256
}
export enum eValueType {
	Text = 0,
	TextFormated = 1,
	HTML = 10,
	XML = 12,
	URL = 15,
	JSON = 20,
	Code = 30,
	CSV = 40,
	Date = 100,
	Time = 101,
	DateTime = 102,
	Duration = 105,
	Number = 110
}
export enum eFileDirectoryAccess {
	NotSet = 0,
	LastWriteTime = 1,
	LastAccessTime = 2,
	CreationTime = 3
}
