<div id="uploadPage" *ngIf="isLoaded" class="row mx-3 mt-3">
    <div class="col-xl-2 col-lg-3 col-12 card-wrapper" *ngIf="showCards && !reviewPage" [@componentFadeIn]>
        <card-item-details #cardItemDetails [editButtonShown]="reviewPage || isCampaignActivatedOutsideOfHub" [isCampaignActivatedOutsideOfHub]="isCampaignActivatedOutsideOfHub" (onActivatedOutsideOfHub)="isCampaignActivatedOutsideOfHub = $event" (onDateChange)="isStartDateChanged=$event.isStartDateChanged; isEndDateChanged=$event.isEndDateChanged" [isNoteValid]="isNoteValid" [isVisible]="!isVisible"></card-item-details>
    </div>
    <div [ngClass]="!reviewPage?'col-xl-10 col-lg-9 col-12 pl-lg-0 pl-3': 'col-xl-12 col-12'">
        <div class="">
            <p-breadcrumb  *ngIf="(currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient)
            && this.currentUser.userRole != enumUserRole.LIMGCSocialAdOps && this.currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin" [model]="breadcrumbItemsCampaignUpload"></p-breadcrumb>

            <div id="topPage" *ngIf="reviewPage" class="col-md-11 mb-4" style="background-color: var(--background-color-primary);">
                <h4 style="color:white">Please review</h4>
                <span style="color:white">Before submitting for approval, please check if all of the assets you uploaded are correct.</span>
            </div>
            
            <div *ngIf="!!baseCampaign"  class="col-md-11 mb-3 pb-3 pr-3 pl-3">
                <span *ngIf="reviewPage && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS)">
                    <span class="text-secondary"><strong>INFORMATION SUMMARY</strong></span>
                    <div class="ml-5 pl-3" style=" border:0; border-left: 1px; border-style: solid; border-color: gray;">
                        <div *ngIf="baseCampaign.campaignType == enumAccountType.FAN365" class="mb-4 mt-3 pt-3">
                            <div class="row col-10">
                                <label><strong>You will deliver {{baseCampaign.impressions | number}} {{baseCampaign.impressions > 1 ? 'impressions' : 'impression'}} for {{baseCampaign.seasonJson.name}} {{baseCampaign.seasonJson.season}} sports season</strong></label>
                            </div>
                            <div class="row col-10">
                                <!-- <label><strong>Targeting {{baseCampaign.account.dV360Audience}}, {{baseCampaign.account.facebookAudience}}, {{baseCampaign.account.ttdAudience}} audience
                                    </strong></label> -->
                                <label><strong>Targeting {{baseCampaign.account.name}} audience
                                    </strong></label>
                            </div>
                            <div *ngIf="checkisDemographicVisible()" class="row col-10">
                                <label><strong>Targeting demographic
                                        <div class="ml-2" *ngIf="demographicsValues">
                                            <div class="campaign-card-content" *ngIf="demographicsValues[1]!=''"><span style="font-weight: bold;">Age: </span><span>{{ demographicsValues[1] }}</span></div>
                                            <div class="campaign-card-content" *ngIf="demographicsValues[0]!=''"><span style="font-weight: bold;">Gender: </span><span>{{ demographicsValues[0] }}</span></div>
                                            <div class="campaign-card-content" *ngIf="demographicsValues[2]!=''"><span style="font-weight: bold;">Household Income: </span><span>{{ demographicsValues[2] }}</span></div>
                                        </div>
                                    </strong></label>
                            </div>
                            <div class="row col-10">
                                <label><strong>Targeting geo
                                        <div class="ml-2" *ngIf="geographicsValues">
                                            <div class="campaign-card-content" *ngIf="geographicsValues[2]!=''"><span style="font-weight: bold;">State: </span><span>{{ geographicsValues[2] }}</span></div>
                                            <div class="campaign-card-content" *ngIf="geographicsValues[1]!=''"><span style="font-weight: bold;">DMA: </span><span>{{ geographicsValues[1] }}</span></div>
                                            <div class="campaign-card-content" *ngIf="geographicsValues[0]!=''"><span style="font-weight: bold;">City: </span><span>{{ geographicsValues[0] }}</span></div>
                                            <div class="campaign-card-content" *ngIf="geographicsValues[3]!=''"><span style="font-weight: bold;">ZIP: </span><span>{{ geographicsValues[3] }}</span></div>
                                        </div>
                                    </strong></label>
                            </div>
                            <div *ngIf="checkisAdditionalTargetingVisible()" class="row col-10">
                                <label><strong>You {{baseCampaign.isAdditionalTargetingSegmentsEnabled ? 'have': 'have not'}} requested additional segments</strong></label>
                            </div>
                            <div *ngIf="checkIsSiteVisitTrackingVisible()" class="row col-10">
                                <label><strong>You {{baseCampaign.isSiteVisitTrackingEnabled ? 'have': 'have not'}} requested site visit tracking</strong></label>
                            </div>
                            <div *ngIf="checkIsConversionTrackingVisible()" class="row col-10">
                                <label><strong>You {{baseCampaign.isConversionTrackingEnabled ? 'have': 'have not'}} requested conversion tracking</strong></label>
                            </div>
                        </div>
                        <div *ngIf="baseCampaign.campaignType == enumAccountType.OAS" class="mb-4 mt-3 pt-3">
                            <div  class="row col-10" *ngIf="!this.isPageTakeOver && !this.isPageTakeOverPremium">
                                <label><strong>You will deliver {{baseCampaign.impressions | number}} {{baseCampaign.impressions > 1 ? 'impressions' : 'impression'}} by {{baseCampaign.endDate| date: 'MM/dd/yyyy'}}.</strong></label>
                            </div>
                            <div  class="row col-10" *ngIf="this.isPageTakeOverPremium">
                                <label><strong>Your page takeover campaign will deliver between {{baseCampaign.startDate| date: 'MM/dd/yyyy'}} and {{baseCampaign.endDate| date: 'MM/dd/yyyy'}}.</strong></label>
                            </div>
                            <div  class="row col-10" *ngIf="this.isPageTakeOver">
                                <label><strong>Your page takeover campaign will deliver on {{baseCampaign.startDate| date: 'MM/dd/yyyy'}}.</strong></label>
                            </div>
                        </div>
                    </div>
                </span>

                <p-accordion *ngIf="isVisible" class="card card-body mb-2" [activeIndex]="0">
                    <p-accordionTab>
                        <p-header>
                            <span>{{baseCampaign.campaignType != enumAccountType.Content ? 'CAMPAIGN DATES' : 'LAUNCH DATE'}}</span>
                        </p-header>
                        <campaign-dates-accordion #initCalendar [disabled]="editModeCampaignDates() || reviewPage || (currentUser.permissions.isClient && currentUser.userRole != enumUserRole.LIMGCSocialAdOps && currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin && baseCampaign.campaignStatusId != enumCampaignStatus.Assets_Pending)" [baseCampaign]="baseCampaign" (onStartDateChange)="campaignDatesSaved($event)" (onEndDateChange)="campaignDatesSaved($event)"></campaign-dates-accordion>
                    </p-accordionTab>
                </p-accordion>

                <p-accordion class="card card-body mb-2" *ngIf="isVisible && baseCampaign.account!=null && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS) && canEditDemographics ">
                    <p-accordionTab>
                        <p-header>
                            <span>DEMOGRAPHIC TARGETING</span>
                            <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.demographicsInfo' class="fal fa-info-circle info float-right mt-1"></span>
                        </p-header>
                        <campaign-demographic-accordion [disabled]="editModeCampaignDemographic() || reviewPage" [baseCampaign]="baseCampaign" (onSave)="demographicChanges($event)"></campaign-demographic-accordion>
                    </p-accordionTab>
                </p-accordion>

                <p-accordion class="card card-body mb-2" *ngIf="isVisible && baseCampaign.account!=null && (baseCampaign.campaignType == enumAccountType.FAN365 || (baseCampaign.campaignType == enumAccountType.OAS && (baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != enumInventoryTypeOAS.Share_Of_Voice || !(currentUser.permissions.isClient && currentUser.userRole != enumUserRole.LIMGCSocialAdOps && currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin)))) && canEditGeographics">
                    <p-accordionTab>
                        <p-header>
                            <span>GEOGRAPHIC TARGETING</span>
                            <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.geographicsInfo' class="fal fa-info-circle info float-right mt-1"></span>
                        </p-header>
                        <campaign-geographic-accordion [disabled]="editModeCampaignGeographic() || reviewPage" [baseCampaign]="baseCampaign" (onSave)="geographicChanges($event)"></campaign-geographic-accordion>
                    </p-accordionTab>
                </p-accordion>

                <p-accordion class="card card-body mb-2"  *ngIf="isVisible && baseCampaign.account!=null && baseCampaign.campaignType == enumAccountType.FAN365 && (canEditSiteVisitTracking || canEditConversionTracking || canEditInterestTargeting)">
                    <p-accordionTab>
                        <p-header>
                            <span>ADDITIONAL SETTINGS</span>
                            <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.additionalSettingsInfo' class="fal fa-info-circle info float-right mt-1"></span>
                        </p-header>
                        <additional-settings-accordion [disabled]="editButtonShown || reviewPage" (onSave)="additionalSettingsChanges($event)" [baseCampaign]="baseCampaign" ></additional-settings-accordion>
                    </p-accordionTab>
                </p-accordion>

                <p-accordion class="card card-body mb-2" *ngIf="isVisible && (baseCampaign.campaignType == enumAccountType.Social 
                || baseCampaign.campaignType == enumAccountType.Content || baseCampaign.campaignType == enumAccountType.FAN365) 
                && !(baseCampaign.campaignType == enumAccountType.Social && baseCampaign.campaignInventoryItems[0]?.inventoryItemContent != null && baseCampaign.account.isLFStudios)">
                    <p-accordionTab>
                        <p-header>
                            <span>IMPRESSIONS</span>
                        </p-header>
                        <inventory-add-on-accordion #inventoryAddOns [baseCampaign]="baseCampaign" [disabled]="reviewPage" (onSave)="addOnImpressionsChanges($event)"></inventory-add-on-accordion>
                    </p-accordionTab>
                </p-accordion>

                <p-accordion class="card card-body mb-2" *ngIf="(isPageTakeOver || isPageTakeOverPremium) && isVisible">
                    <p-accordionTab>
                        <p-header>
                            <span>OAS SITE</span>
                        </p-header>
                        <key-value-pair-accordion [baseCampaign]="baseCampaign" (onSave)="keyValuePairChanges($event)" [disabled]="reviewPage"></key-value-pair-accordion>
                    </p-accordionTab>
                </p-accordion>

                <p-accordion class="card card-body mb-2" *ngIf="!!baseCampaign.campaignImpressionFlights && this.baseCampaign.campaignImpressionFlights.length > 0 && isVisible">
                    <p-accordionTab>
                        <p-header>
                            <span>IMPRESSIONS</span> 
                            <div *ngIf="baseCampaign.creatives.length > baseCampaign.campaignImpressionFlights.length && baseCampaign.campaignImpressionFlights[0].alignCreativeFlights" class="invalid-message-box" style="display: inline-block !important; padding: 0.3em; margin-left: 3em;">
                                <span class="pl-2 font-weight-bold">There is more Cretive flights then impressions! Please review.</span>
                            </div>
                            
                            <div class="p-2 bd-highlight text-right edit-button-accordion">
                                <button type="button" *ngIf="!reviewPage" pButton class="p-button-primary" label="Edit" (click)="$event.stopPropagation(); openImpressionFlightDialog()"></button>
                            </div>


                        </p-header>
                        <div *ngFor="let impression of baseCampaign.campaignImpressionFlights; let i = index">
                            <div class="row mb-2 ml-1">
                                <div class="p-0 col-md-3 mt-2 font-weight-bold">
                                    Impression Flight {{i + 1}}
                                </div>

                                <div class="col-md-3">
                                    {{ impression.impressionStartDate | date : constants.DEFAULT_FORMAT_DATE_US }} - {{ impression.impressionEndDate | date : constants.DEFAULT_FORMAT_DATE_US }}
                                </div>

                                <div class="p-0 col-md-3 mt-2 font-weight-bold">
                                    {{ impression.impression | number: '1.0-2'}}
                                </div>
                            </div>
                            
                        </div>
                    </p-accordionTab>
                </p-accordion>

                <p-accordion *ngIf="baseCampaign.campaignType == enumAccountType.OAS" class="card card-body mb-2" >
                    <p-accordionTab>
                        <p-header>
                            <span>AD UNIT TYPE</span>
                        </p-header>
                        <ad-unit-accordion [baseCampaign]="baseCampaign" [disabled]="reviewPage" (onSave)="adUnitChanges($event)"></ad-unit-accordion>
                    </p-accordionTab>
                </p-accordion>

               
            <div *ngFor="let number of numberOfFlights">
                <p-accordion class="card card-body mb-2" *ngIf="!(!!socialContentCreationCampaign && socialContentCreationCampaign.length>0) && !(!!socialLinkCampaign && socialLinkCampaign.length>0)
                    && !(!!socialFlexibleCampaign && socialFlexibleCampaign.length>0) && baseCampaign.campaignType != enumAccountType.Content" [activeIndex]="0">
                    <p-accordionTab>
                        <p-header>
                            <span>FLIGHT {{number}}</span>
                        </p-header>
                        <i *ngIf="canEditFlight(number) && maxCreativeGroup > 1 && !(currentUser.permissions.isClient && currentUser.userRole != enumUserRole.LIMGCSocialAdOps && currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin)" (click)="openEditFlightDialog(number)" class="edit-flight-icon fa fa-pencil" pTooltip='Edit flight'></i>
                        <i *ngIf="canEditFlight(number) && number > 1" (click)="openDeleteFlightDialog(number)" class="remove-flight-icon fa fa-trash-alt delete" pTooltip='Remove flight'></i>
                                <!-- DISPLAY -->
                                <div *ngIf="(!!standardDisplayCreative && standardDisplayCreative.length>0) || (!!premiumDisplayCreative && premiumDisplayCreative.length>0) ||
                                    (!!cartDisplayBannerCreative && cartDisplayBannerCreative.length>0) || (!!ppuDisplayBannerCreative && ppuDisplayBannerCreative.length>0)
                                    || (!!oasCreative && oasCreative.length>0)">
                                <span class="text-secondary"><strong>DISPLAY</strong></span>

                                <div *ngFor="let item of standardDisplayCreative; let i = index">
                                <display *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" (uploadValidationMessage)="uploadWarning($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="baseCampaign" [creative]="item"></display>
                                </div>

                                <div *ngFor="let item of premiumDisplayCreative; let i = index">
                                <display-premium  *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" (uploadValidationMessage)="uploadWarning($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></display-premium>
                                </div>

                                <div *ngFor="let item of cartDisplayBannerCreative; let i = index">
                                <display *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></display>
                                </div>

                                <div *ngFor="let item of ppuDisplayBannerCreative; let i = index">
                                <display *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></display>
                                </div>

                                <!-- OAS Campaigns -->
                                <div *ngFor="let item of oasCreative; let i = index">
                                    <oas *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' (onCreativetDelete)="removeCreative($event)" [landingPage]='baseCampaign.landingPageJson.url' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item" [index]="i"></oas>
                                </div>

                                </div>

                                <!-- FACEBOOK -->
                                <div *ngIf="(!!facebookStaticCreative && facebookStaticCreative.length>0) || (!!facebookDynamicCreative && facebookDynamicCreative.length>0) ||
                                (!!facebookDynamicVideoCreative && facebookDynamicVideoCreative.length>0) || (!!facebookStaticVideoCreative && facebookStaticVideoCreative.length>0)
                                || (!!facebookDynamicCarouselCreative && facebookDynamicCarouselCreative.length>0) || (!!stories && stories.length>0)
                                || (!!leadGeneration && leadGeneration.length>0) || (!!instantExperience && instantExperience.length>0)">
                                <span class="text-secondary"><strong>FACEBOOK AND INSTAGRAM</strong></span>

                                <div *ngFor="let item of facebookStaticCreative; let i = index">
                                <facebook-static *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" (uploadValidationMessage)="uploadWarning($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></facebook-static>
                                </div>

                                <div *ngFor="let item of facebookDynamicCreative; let i = index">
                                <facebook-static *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item" dynamic="booleanTrue"></facebook-static>
                                </div>

                                <div *ngFor="let item of facebookStaticVideoCreative; let i = index">
                                <facebook-static-video *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></facebook-static-video>
                                </div>

                                <div *ngFor="let item of facebookDynamicVideoCreative; let i = index">
                                <facebook-static-video *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item" dynamic="booleanTrue"></facebook-static-video>
                                </div>

                                <div *ngFor="let item of facebookDynamicCarouselCreative; let i = index">
                                <facebook-carousel *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></facebook-carousel>
                                </div>

                                <div *ngFor="let item of stories; let i = index">
                                <stories *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></stories>
                                </div>

                                <div *ngFor="let item of leadGeneration; let i = index">
                                <lead-generation *ngIf="item.creativeGroup == number" [landingPage]='baseCampaign.landingPageJson.url' (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></lead-generation>
                                </div>

                                <div *ngFor="let item of instantExperience; let i = index">
                                <instant-experience *ngIf="item.creativeGroup == number" [landingPage]='baseCampaign.landingPageJson.url' (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></instant-experience>
                                </div>

                                </div>

                                <!-- OTHER -->
                                <div *ngIf="(!!inStreamVideoVideoCreative && inStreamVideoVideoCreative.length>0) || (!!nativeImageCreative && nativeImageCreative.length>0) 
                                    || (!!nativeVideoCreative && nativeVideoCreative.length>0) || (!!spotifyVideoCreative && spotifyVideoCreative.length>0)
                                    || (!!spotifyAudioCreative && spotifyAudioCreative.length>0) || (!!googleAdWorkCreative && googleAdWorkCreative.length>0) 
                                    || (!!connectedTvCreative && connectedTvCreative.length>0)">

                                <div *ngFor="let item of inStreamVideoVideoCreative; let i = index">
                                <in-stream-video *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></in-stream-video>
                                </div>

                                <div *ngFor="let item of connectedTvCreative; let i = index">
                                <connected-tv *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [landingPage]='baseCampaign.landingPageJson.url' [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></connected-tv>
                                </div>

                                <div *ngFor="let item of nativeImageCreative; let i = index">
                                <native-image *ngIf="item.creativeGroup == number" [landingPage]='baseCampaign.landingPageJson.url' (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></native-image>
                                </div>

                                <div *ngFor="let item of nativeVideoCreative; let i = index">
                                <native-video *ngIf="item.creativeGroup == number" [landingPage]='baseCampaign.landingPageJson.url' (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></native-video>
                                </div>

                                <div *ngFor="let item of spotifyVideoCreative; let i = index">
                                <spotify-video *ngIf="item.creativeGroup == number" [landingPage]='baseCampaign.landingPageJson.url' (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></spotify-video>
                                </div>

                                <div *ngFor="let item of spotifyAudioCreative; let i = index">
                                <spotify-audio *ngIf="item.creativeGroup == number" [landingPage]='baseCampaign.landingPageJson.url' (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></spotify-audio>
                                </div>

                                <div *ngFor="let item of googleAdWorkCreative; let i = index">
                                <google-adWords *ngIf="item.creativeGroup == number" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></google-adWords>
                                </div>

                                </div>
                    </p-accordionTab>
                    </p-accordion>
              
            </div>

                <div *ngIf="!reviewPage && baseCampaign.campaignStatusId != enumCampaignStatus.Campaign_Expired">
                        <!-- Creative Flight Add button -->
                        <button type="button" *ngIf="(baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS) && baseCampaign.creatives[baseCampaign.creatives.length-1].creativeGroup < 15  && !!baseCampaign.startDate && !!baseCampaign.endDate" pButton (click)="addCreativeFlight()" class="flat-btn mb-2" label="+ Add Creative Flight"></button>
                        <!-- Impression Flight Add button -->
                        <button *ngIf="baseCampaign.campaignType == enumAccountType.OAS && this.baseCampaign.campaignImpressionFlights?.length <= 0 && !!baseCampaign.startDate && !!baseCampaign.endDate" type="button" pButton [attr.title]="!isImpressionDisabled? null : (impressionToolTip != null ? impressionToolTip : null)" [disabled]="checkIsImpressionDisabled()" (click)="addImpressionFlight()" class="flat-btn ml-2 mb-2" label="+ Add Impression Flight"></button>
                </div>
               
                <!-- Social campaigns -->
                <div class="card card-body mb-2" *ngIf="(!!socialContentCreationCampaign && socialContentCreationCampaign.length>0) || (!!socialLinkCampaign && socialLinkCampaign.length>0)
                                || (!!socialFlexibleCampaign && socialFlexibleCampaign.length>0)">

                    <div>
                        <social-general (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="this.baseCampaign.creatives[0]"></social-general>
                    </div>

                    <div class="ml-5" *ngIf="!!socialFlexibleCampaign && socialFlexibleCampaign.length>0">
                        <div class="row align-items-center mb-2 ml-2">
                            <div class="col-lg-3 col-12">
                                <label><strong>Type of Campaign</strong></label>
                            </div>
                            <div class="col-lg-9 col-12">
                                <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" [disabled]="reviewPage " autoWidth="false" (onChange)="setCreativeType()" [(ngModel)]="this.baseCampaign.creatives[0].creativeTypeId" [options]="creativeTypeDD"></p-dropdown>
                                <!-- <label *ngIf="reviewPage" class="mr-0 pr-0">{{campaignTypeLabel}}</label> -->
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!!socialLinkCampaign && socialLinkCampaign.length>0">
                        <social-link-campaign (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="this.baseCampaign.creatives[0]"></social-link-campaign>
                    </div>

                    <div *ngIf="!!socialCataboom && socialCataboom.length>0">
                        <social-cataboom (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="this.baseCampaign.creatives[0]"></social-cataboom>
                    </div>

                    <div *ngIf="!!socialContentCreationCampaign && socialContentCreationCampaign.length>0">
                        <social-content-creation (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="this.baseCampaign.creatives[0]"></social-content-creation>
                    </div>
                </div>

                <!-- Content campaigns -->
                <div *ngIf="baseCampaign.campaignType == enumAccountType.Content" class="card card-body mb-2" >
                    <div>
                        <content-component (openPreviewEvent)="openPreview($event)" [campaign]="this.baseCampaign" [creative]="this.baseCampaign.creatives[0]" [reviewPage]='reviewPage'></content-component>
                    </div>
                </div>
                

                <!-- NOTES -->
                <div *ngIf="checkForCreatives()" class="card card-body">
                    <span>
                        <div *ngIf="!!baseCampaign.pssBroadcastContractLine?.notes" class="mb4">
                            <span class="mb-0 ml-3 text-secondary"><strong>L365 Notes</strong></span>
                            <p class="ml-4">{{baseCampaign.pssBroadcastContractLine?.notes}}</p>
                        </div>
                        <div>
                            <span class="mb-0 ml-3 text-secondary"><strong>Add notes or comments</strong></span>
                        </div>
                        <div class="mb-0 ml-3" *ngIf="!isNoteValid" style="color: red;">
                            {{constants.dateInNoteValidation}}
                        </div>
                        <div class="ml-5 pl-3 mt-3" [ngStyle]="isNoteValid ? {'border':'0', 'border-left': '1px', 'border-style': 'solid', 'border-color':'gray'} :  {'border':'0', 'border-left': '1px', 'border-style': 'solid', 'border-color':'red'}">
                            <div class="col-12">
                                <textarea type="text" [(ngModel)]="note" class="form-control" style="width: 100%; height: 6em;" (focusout)="validateNotes()" placeholder="Start Typing..."></textarea>
                            </div>
                        </div>
                    </span>
                    <!-- <div *ngIf="this.baseCampaign.isLearfieldCampaign && this.currentUser.permissions.isClient && this.currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin && this.currentUser.userRole != enumUserRole.LIMGCSocialAdOps">
                        <span class="mb-0 ml-3 text-secondary"><strong>Please reach out to your Activation Strategy team for any campaign inquiries prior to submission</strong></span>
                    </div> -->
                    <rc-galleria *ngIf='isPreviewOpen' [isBuilt]="previewedCreativeIsBuilt" [statusId]="baseCampaign.statusId" [isApproveProcess]="isApprove" (previewDialogDisplay)="showHideDialog($event)" [isVisible]='isPreviewOpen' [medias]="creativeAssetsForPreview" [activeIndex]="0" [active]="currentIndex"></rc-galleria>

                    <div *ngIf="baseCampaign.campaignStatusId != enumCampaignStatus.Activated_outside_of_Hub || (baseCampaign.campaignStatusId == enumCampaignStatus.Activated_outside_of_Hub && !isCampaignActivatedOutsideOfHub)" class="d-flex flex-row-reverse flex-wrap bd-highlight mt-3 mb-3">
                        <div class="p-2 bd-highlight"><button type="button" pButton (click)="next()" *ngIf="!reviewPage && (currentUser.isClient || baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Pending || baseCampaign.statusId == enumCampaignStatus.Assets_Pending)" [attr.title]="!isLandingUrlOk? 'Missing Landing Page URL for uploaded asset!' : (tooltipDisabled != null ? tooltipDisabled : null)" [disabled]="flightsDatesInvalid() || (baseCampaign.campaignType == enumAccountType.FAN365 ? checkIsDisabled() : checkIsDisabled() || !isLandingUrlOk) "  class="p-button-primary" label="Review Upload"></button></div>
                        <div class="p-2 bd-highlight"><button type="button" pButton (click)="confirmSave()" [attr.title]="!isLandingUrlOk? 'Missing Landing Page URL for uploaded asset!' : (tooltipDisabled != null ? tooltipDisabled : null)" [disabled]="flightsDatesInvalid() || impressionFlightsDatesInvalid() || ((!this.currentUser.permissions.isClient || this.currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == enumUserRole.LIMGCSocialAdOps) ? false : baseCampaign.campaignType == enumAccountType.FAN365 ? checkIsDisabled() : checkIsDisabled() || !isLandingUrlOk )" class="flat-btn" [label]="reviewPage? 'Submit':'Save & exit to Home'"> </button></div>
                        <div class="p-2 bd-highlight"><button type="button" pButton (click)="back()" *ngIf="reviewPage" class="flat-btn" label="Back"></button></div>
                        <div class="p-2 bd-highlight"><button type="button" pButton (click)="cancel()" class="flat-btn" label="Cancel"></button></div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

</div>


<creative-swaps-dialog #creativeSwaps (onRemoveSave)="removeCampaignDatesSaved($event)" (onNewSave)="newCreativeFlightDatesSaved($event)" (onEditSave)="editCreativeFlightsDates($event)"></creative-swaps-dialog>
<impression-flights-dialog #impressionFlights [baseCampaign]="baseCampaign" (onSave)="saveImpressionFlights($event)"></impression-flights-dialog>
<p-confirmDialog header="Campaign Transfered" icon="pi pi-exclamation-triangle" key="transferedCampaign" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>
<p-confirmDialog header="Short Campaign Warning" icon="pi pi-exclamation-triangle" key="shortCampaign" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>
<p-confirmDialog header="Confirm Save Campaign" icon="pi pi-exclamation-triangle" key="saveFan365Campaign" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>
<p-confirmDialog header="Upload Warning" icon="pi pi-exclamation-triangle" key="uploadWarning" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>

