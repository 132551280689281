<p-pickList 
styleClass="picklist" 
[sourceHeader]="titleSource" 
[targetHeader]="titleTarget" 
[responsive]="true" 
[showSourceControls]="false" 
[showTargetControls]="false" 
[source]="sourceList" 
[target]="targetList" 
filterBy="label"
[sourceFilterPlaceholder]="sourcePlaceholder"
[targetFilterPlaceholder]="targetPlaceholder"
>
    <ng-template let-data pTemplate="item">
        <div class="p-helper-clearfix">
            <div>{{data.label}}</div>
        </div>
    </ng-template>
</p-pickList>