<component-loader-spinner [isLoading]="loading"></component-loader-spinner>

    <div class="alert alert-warning text-center" *ngIf="!baseCampaign.account.isCampaignCreation">
        Geographic targeting must comply with IP usage guidelines set by Learfield.
    </div>
    <div *ngIf="!!errorMessage" class="alert alert-danger">
        <b>{{ errorMessage}}</b>
    </div>

    <p-tabView [ngClass]="loading == true? 'content-disabled':''">
        <p-tabPanel header="Select Geo">
            <div class="mb-2 mt-2 row align-items-center" *ngIf="this.baseCampaign.campaignType == enumAccountType.FAN365 || this.baseCampaign.campaignType == enumAccountType.OAS">
    
                <div class="col-md-2">
                    <p-checkbox [(ngModel)]="isStateChecked" [disabled]="disabled" (onChange)="disableOther($event)" binary="true" inputId="none"></p-checkbox>
                </div>
                <div class="col-md-10 font-weight-bold">
                    Targeting the state of {{states[0]?.label}}
                </div>
            </div>
    
            <div class="mb-2 row align-items-center">
                <div class="col-md-2 font-weight-bold">
                    DMA
                </div>
                <div class="col-md-10">
                    <p-dropdown [placeholder]="'Choose'" (onChange)="detectChanges()" autoWidth="false" filter="true" [style]="{'width':'100%'}"  *ngIf="this.baseCampaign.account.isCampaignCreation" [(ngModel)]="selectedDmas" [disabled]="isStateChecked || disabled" [options]="dmas" [virtualScroll]="true" [itemSize]="31"></p-dropdown>

                    <p-multiSelect *ngIf="this.baseCampaign.campaignType == enumAccountType.FAN365 || this.baseCampaign.campaignType == enumAccountType.OAS" [placeholder]="'Choose'" [options]="dmas" [disabled]='isStateChecked || disabled' [(ngModel)]="selectedDmas"  (onChange)="detectChanges()" [virtualScroll]="yes" itemSize="30" selectedItemsLabel="{0} items selected"></p-multiSelect>
                </div>
            </div>
    
            <div class="mb-2 row align-items-center">
                <div class="col-md-2 font-weight-bold">
                    City
                </div>
                <div class="col-md-10">
                    <p-multiSelect [placeholder]="'Choose'" [options]="cities" [disabled]='isStateChecked || disabled' (onChange)="detectChanges()" [(ngModel)]="selectedCities" [virtualScroll]="yes" itemSize="30" selectedItemsLabel="{0} items selected"></p-multiSelect>
                </div>
            </div>

            <div class="mb-2 row align-items-center" *ngIf="this.baseCampaign.account.isCampaignCreation">
                <div class="col-md-2 font-weight-bold">
                    States
                </div>
                <div class="col-md-10">
                    <p-dropdown [placeholder]="'Choose'"  autoWidth="false" filter="true" (onChange)="detectChanges()" [style]="{'width':'100%'}" [(ngModel)]="selectedStates" [disabled]="isStateChecked || disabled" [options]="states" [virtualScroll]="true" [itemSize]="31"></p-dropdown>
                </div>
            </div>
    
            <div class="mb-2 row align-items-center" *ngIf="customZip && (this.baseCampaign.campaignType == enumAccountType.FAN365 || this.baseCampaign.campaignType == enumAccountType.OAS)">
                <div class="col-md-2 font-weight-bold">
                    Zip Code
                </div>
                <div class="col-md-10">
                    <p-multiSelect [options]="zipCodes" [disabled]='isStateChecked || disabled' [(ngModel)]="selectedZipCodes" [virtualScroll]="yes" itemSize="30" (onChange)="detectChanges()" selectedItemsLabel="{0} items selected"></p-multiSelect>
                </div>
            </div>
        </p-tabPanel>
        
        <p-tabPanel header="Bulk Upload Zips" *ngIf="(this.baseCampaign.campaignType == enumAccountType.FAN365 || this.baseCampaign.campaignType == enumAccountType.OAS) && customZip" [disabled]="!customZip">
            <div class="mb-2 mt-2">
                <div class="mb-1 font-weight-bold">Type or paste ZIP codes into the text box bellow (comma delimited)</div>
                <textarea rows="3" [(ngModel)]="pastedZipcodes" (ngModelChange)="onTextareaChanged($event)" [disabled]="disabled"></textarea>
            </div>
    
            <div>
                <div class="mb-1 font-weight-bold">Or</div>
                <rc-filedrop [files]="files" [allowedExtensions]="allowedFileExtensions" (onFilesDropped)="parseFile($event)" [disabled]="disabled"></rc-filedrop>
            </div>
        </p-tabPanel>
    
    </p-tabView>
