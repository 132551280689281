<div *ngIf="showPackage">

    <div class="px-3">
        <h3 class="display-4 pt-3">Select Package <small>
                <i class=" float-right fal fa-info-circle"></i>
            </small>
        </h3>
    </div>

    <div class="media text-muted px-3">
        <p class="media-body pb-3 mb-0 small lh-125 border-gray">
            Direct Response: Manage consumer sales, leads, and other activity
        </p>
    </div>
</div>
<div>
    <div class="card-group mb-3 mx-1 text-center">
        <div *ngFor="let item of packages" class="col d-flex px-0">
            <div class="card mb-4 mx-2 w-100" [ngClass]="item.isRecomended == true? 'recommanded':''">
                <div class="card-header pt-3">
                    <h4 class="my-0 pt-3 display-4 text-uppercase">{{item.name}}</h4>

                </div>

                <!-- {{recommendedPackage.name}} -->

                <!-- <h4 class="my-0 font-weight-normal">Grow</h4> -->
                <div class="card-body h-100 d-flex flex-column my-flex-container-column">

                    <div class="my-flex-item">
                        <h4 *ngIf="item.name !== 'Thrive'" class="card-title pricing-card-title font-weight-bold">${{item.price}} <small class="font-weight-normal">/<label *ngIf="campaignGoalType == 3">day</label> <label *ngIf="campaignGoalType != 3">week</label></small></h4>
                        <h4 *ngIf="item.name == 'Thrive'" class="card-title pricing-card-title"><strong>Sky's the limit</strong></h4>
                    </div>

                    <!-- Commented due to USER STORY - 20898, Finn asked for it in comments
                    <div class="my-flex-item">
                        <small *ngIf="item.name == 'Grow' || item.name === 'Amplify Flex'" class="text-left text-muted">
                            No Discounts
                        </small>
                        <small *ngIf="item.name == 'Expand'" class="text-left text-muted">
                            10% Weekly Discount
                        </small>
                        <small *ngIf="item.name == 'Amplify'" class="text-left text-muted">
                            15% Weekly Discount
                        </small>
                        <small class="text-white" *ngIf="item.name === 'Thrive'"> .</small>
                    </div> -->

                    <div class="my-flex-item h-100">

                        <ul class="list-unstyled h-100 d-flex flex-column justify-content-between text-left mt-3">
                            <li class="mb-5 mx-3 text-center" style="flex-basis: 70%;">{{item.description}}</li>

                            <li style="flex-basis: 40%;">
                                <div *ngIf="item.name === 'Grow' || item.name ==='Display'" class="pt-4">

                                    <button *ngIf="item.name === 'Grow' || item.name === 'Display'" (click)="onShowPackage(item.name + ' Display')" class="package-btn btn btn-default btn-block mb-4" type="button">
                                        <span class="pull-left">Select Display</span>
                                        <!-- <i class=" pl-2 fad fa-long-arrow-alt-right"></i> -->
                                    </button>
                                    <button *ngIf="item.name === 'Grow'" (click)="onShowPackage(item.name + ' Social')" class="package-btn btn btn-default btn-block mb-4" type="button">
                                        <span class="pull-left">Select Social</span>
                                        <!-- <i class=" pl-2 fad fa-long-arrow-alt-right"></i> -->
                                    </button>
                                </div>
                                <div class="pt-4" *ngIf="item.name === 'Expand' || item.name === 'Social'">
                                    <button (click)="onShowPackage(item.name)" class="package-btn btn btn-default btn-block mb-4" type="button">
                                        <span class="pull-left">Select</span>
                                        <!-- <i class=" pl-2 fad fa-long-arrow-alt-right"></i> -->
                                    </button>
                                </div>
                                <div class="pt-4" *ngIf="item.name === 'Amplify' || item.name === 'Multi channel'">
                                    <!-- <div class=" text-center mb-3">RECOMENDED</div> -->
                                    <button (click)="onShowPackage(item.name)" class="package-btn btn btn-default btn-block mb-4" type="button">
                                        <span class="pull-left">Select</span>
                                        <!-- <i class=" pl-2 fad fa-long-arrow-alt-right"></i> -->
                                    </button>
                                </div>
                                <div class="pt-4 mb-4" *ngIf="item.name === 'Amplify Flex'">
                                    <button (click)="onShowPackage(item.name.replace(' ', '_'))" class="package-btn btn btn-default btn-block" type="button">
                                        <span class="pull-left">Select</span>
                                        <!-- <i class=" pl-2 fad fa-long-arrow-alt-right"></i> -->
                                    </button>
                                </div>
                                <div class="pt-4 mb-4" *ngIf="item.name === 'Thrive'">
                                    <button (click)="onShowPackage(item.name)" class="package-btn btn btn-default btn-block" type="button">
                                        <span class="pull-left">Select</span>
                                        <!-- <i class=" pl-2 fad fa-long-arrow-alt-right"></i> -->
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="accordion mx-2" id="accordionExample">
        <div class="card">
            <div id="collapseOne" aria-expanded="true" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body border-0">
                    <table class="table table-borderless compare-package">
                        <thead>
                            <tr class="row">
                                <div class="col-4">

                                    <th scope="col" class="compare-package-header">
                                        <h3 class="text-secondary">
                                            Compare our packages
                                        </h3>

                                        <span>
                                            Check out what package can bring you
                                        </span>
                                    </th>
                                </div>
                                <div class="col">

                                    <th *ngIf="campaignGoalType !== 3 && !!recommendedPackage" scope="col" class="px-4" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Grow'}">Grow</th>
                                    <th *ngIf="campaignGoalType === 3 && !!recommendedPackage" scope="col" class="px-4" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Display'}">Display</th>

                                </div>
                                <div class="col">

                                    <th *ngIf="campaignGoalType !== 3 && !!recommendedPackage" scope="col" class="px-4" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Expand'}">Expand</th>
                                    <th *ngIf="campaignGoalType === 3 && !!recommendedPackage" scope="col" class="px-4" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Social'}">Social</th>

                                </div>
                                <div class="col">

                                    <th *ngIf="campaignGoalType !== 3 && !!recommendedPackage" scope="col" class="px-4 recommanded-package" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Amplify'}">Amplify</th>
                                    <th *ngIf="campaignGoalType === 3 && !!recommendedPackage" scope="col" class="px-4" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Multi channel'}">Multi-channel</th>

                                </div>
                                <div class="col" *ngIf="campaignGoalType !== 3 && !!recommendedPackage">

                                    <th scope="col" class="px-4" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Amplify Flex'}">Amplify Flex</th>

                                </div>
                                <div class="col">

                                    <!-- <th scope="col"></th> -->
                                    <th scope="col" *ngIf="!!recommendedPackage" class="px-4" [ngClass]="{'recommanded-package': recommendedPackage.name == 'Thrive'}">Thrive</th>
                                </div>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="row" *ngFor="let item of header">
                                <td class="col-4">

                                    {{item.name}} <small>

                                        <i title="{{item.title}}" class="pl-1 fal fa-info-circle"></i>
                                    </small>

                                </td>
                                <td class="col">

                                    <span *ngIf="item.grow !== true && item.grow !== false" class="px-4">{{item.grow}} </span>
                                    <span class="ml-2" *ngIf="item.grow === true" class="px-4"> <i class="fad fa-check-circle"></i> </span>
                                    <span class="col-md-2 mr-3 text-muted" *ngIf="item.grow === false"> <small> - </small> </span>
                                </td>
                                <td class="col">

                                    <span *ngIf="item.expand !== true && item.expand !== false" class="px-4">{{item.expand}} </span>
                                    <span class="ml-2" *ngIf="item.expand === true" class="px-4"> <i class="fad fa-check-circle"></i> </span>
                                    <span class="col-md-2 mr-3 text-muted px-4" *ngIf="item.expand === false"> <small> - </small> </span>

                                </td>
                                <td class="col">

                                    <span *ngIf="item.amplify !== true && item.amplify !== false" class="px-4">{{item.amplify}} </span>
                                    <span class="ml-2" *ngIf="item.amplify === true" class="px-4"> <i class="fad fa-check-circle"></i> </span>
                                    <span class="col-md-2 mr-3 text-muted px-4" *ngIf="item.amplify === false"> <small>
                                            -
                                        </small> </span>
                                </td>

                                <td class="col" *ngIf="campaignGoalType !== 3">

                                    <span *ngIf="item.amplifyFlex !== true && item.amplifyFlex !== false" class="px-4">{{item.amplify}} </span>
                                    <span class="ml-2" *ngIf="item.amplifyFlex === true" class="px-4"> <i class="fad fa-check-circle"></i> </span>
                                    <span class="col-md-2 mr-3 text-muted px-4" *ngIf="item.amplifyFlex === false"> <small>
                                            -
                                        </small> </span>
                                </td>

                                <td class="col">


                                    <span *ngIf="item.thrive !== true && item.thrive !== false" class="px-4">{{item.thrive}} </span>
                                    <span class="ml-2" *ngIf="item.thrive === true" class="px-4"> <i class="fad fa-check-circle"></i> </span>
                                    <span class="ml-3 text-muted px-4" *ngIf="item.thrive === false"> <small>
                                            -
                                        </small> </span>
                                </td>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>