<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Facebook Dynamic News Feed Carousel</strong></span>
</div>
<div class="pl-3" [ngClass]="this.currentUser.permissions.isLearfieldClient ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign || (creative.name && this.campaign.account.isHubStandardCampaign)" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="creative.flightStartDate && creative.flightEndDate"> | {{(this.creative.flightStartDate) | date : constants.DEFAULT_FORMAT_DATE_US}} - {{(this.creative.flightEndDate) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
        </div>
    </div>
    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div class="mb-4 mt-3 pt-3">
        <!-- <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Headline</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage">{{creative.detailJson.headline}}</label>

                        <div *ngIf="!reviewPage" class="input-group">
                            <input class="form-control" type="text" [maxlength]=40 [(ngModel)]="creative.detailJson.headline" [title]="creative.detailJson.headline" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, maxNumberOfFlights)">
                            <label class="input-group-append px-2 d-flex align-items-center justify-content-center">{{40-creative.detailJson.headline.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>{{campaign.campaignType == enumAccountType.HS ? 'Primary (Body) Text' : 'Body Text'}}</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage">{{creative.detailJson.bodyText[0]}}</label>
                        <div *ngIf="!reviewPage" class="input-group">
                            <input class="form-control" type="text" [maxlength]=90 [(ngModel)]="creative.detailJson.bodyText[0]" [title]="creative.detailJson.bodyText[0]" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append px-2 d-flex align-items-center justify-content-center">{{90-creative.detailJson.bodyText[0].length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let item of creative.creativeAssets; let i = index">
        <div class="mt-2">
            <b>CARD {{i+1}}</b>
        </div>
        <div class="mb-4 mt-3 pt-3">

            <div class="row align-items-center">
                <div class="col-lg-2 col-12">
                    <label><strong>Headline</strong></label>
                </div>
                <div class="col-lg-10 col-12">
                    <div class="row">
                        <div class="col-12">
                            <label *ngIf="reviewPage">{{item.detailJson.headline}}</label>
                            <div class="input-group" *ngIf="!reviewPage ">
                                <input class="form-control" type="text" [maxlength]=40 [(ngModel)]="item.detailJson.headline" [title]="item.detailJson.headline" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                                <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{40-item.detailJson.headline.length}} CHARACTERS LEFT</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-2 col-12">
                    <label><strong>{{campaign.campaignType == enumAccountType.HS ? 'Description (Link Description)' : 'Link Description'}}</strong></label>
                </div>
                <div class="col-lg-10 col-12">
                    <div class="row">
                        <div class="col-12">
                            <label *ngIf="reviewPage">{{item.detailJson.linkDescription}}</label>
                            <div class="input-group" *ngIf="!reviewPage ">
                                <input class="form-control" type="text" [maxlength]=20 [(ngModel)]="item.detailJson.linkDescription" [title]="item.detailJson.linkDescription" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                                <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{20-item.detailJson.linkDescription.length}} CHARACTERS LEFT</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row align-items-center mb-2">
                <div class="col-lg-2 col-12">
                    <label><strong>Landing Page URL</strong></label>
                </div>
                <div class="col-lg-10 col-12">
                    <input class="form-control" *ngIf="!reviewPage " type="text" [(ngModel)]="item.detailJson.landingPageUrl" [title]="isLandingUrlOk == false ? titleWrongUrl : item.detailJson.landingPageUrl" style="overflow: hidden; text-overflow: ellipsis;" [ngClass]="this.isLandingUrlOk == false ? 'border-wrong' : ''" (focusout)="validationLandingPageMessage(i)" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                    <span *ngIf="!reviewPage" tooltipPosition="top" [pTooltip]='constants.landingPageInfo' tooltipStyleClass="tooltipStyle" class="fal fa-info-circle info"></span>
                    <label *ngIf="reviewPage" class="mr-0 pr-0">{{item.detailJson.landingPageUrl}}</label>
                </div>
            </div>

            <!-- <div class="row align-items-center mb-2">
                <div class="col-lg-2 col-12">
                    <label><strong>Facebook Page URL</strong></label>
                </div>
                <div class="col-lg-10 col-12">
                    <input class="form-control" *ngIf="!reviewPage " type="text" [(ngModel)]="item.detailJson.facebookPageUrl" [title]="item.detailJson.facebookPageUrl" style="overflow: hidden; text-overflow: ellipsis;">
                    <label *ngIf="reviewPage" class="mr-0 pr-0">{{item.detailJson.facebookPageUrl}}</label>
                </div>
            </div> -->

            <div class="row align-items-center mb-2">
                <div class="col-lg-2 col-12">
                    <label><strong>Call to Action</strong></label>
                </div>
                <div class="col-lg-10 col-12">
                    <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" (onChange)="setCreativeCallToAction($event)" *ngIf="!reviewPage " autoWidth="false" [(ngModel)]="item.callToAction" [options]="callToActionDD" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)"></p-dropdown>
                    <label *ngIf="reviewPage" class="mr-0 pr-0">{{callToActionLabel}}</label>
                </div>
            </div>

        </div>

        <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
            <file-upload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='item.isValid!=null' [isApprove]='isApprove' filetypes="jpg, jpeg" size=1048576 [footer]='booleanFalse' width=1080 height=1080 [header]="booleanTrue" [campaign]="this.campaign" [creative]="this.creative" [asset]="i"></file-upload>

        </div>

    </div>
    <div *ngIf="!reviewPage && creative.creativeAssets.length<10 && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" class="p-2 bd-highlight"><button type="button" pButton (click)="addCard()" class="flat-btn" label="+ Add card"></button></div>
</div>