import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
    selector: 'mogo-instructions-page',
    templateUrl: './instructions.page.html',
})
export class InstructionsPage implements OnInit, OnDestroy {

    ngUnsubscribe: Subject<void> = new Subject();

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}