<div *ngIf="!!formGroup" class='main-content'>
    <div class="d-flex flex-column page-container">
        <form [formGroup]="formGroup">
            <div class="row mb-3">
                <div class="col-12 display-4">
                    Add New {{isAddingClient ? 'Mogo Account' : 'Employee'}} Information
                    <div *ngIf="!!isFromAccount || !!isFromTeam" class="sub-heading">
                        Create the first user associated with above {{header}}
                    </div>
                    <div *ngIf="!isFromAccount && !isFromTeam" class="sub-heading">
                        Create a new user
                    </div>
                </div>
            </div>
            <div class="row" [ngClass]="!isFromTeam ? 'border-bottom' : ''">
                <div class="col-lg-6 col-12">
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                First Name
                            </label>
                        </div>
                        <div class="col-8">
                            <input pInputText maxlength="50" formControlName="firstName" class="w-100" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Last Name
                            </label>
                        </div>
                        <div class="col-8">
                            <input pInputText maxlength="50" formControlName="lastName" class="w-100" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Email
                            </label>
                        </div>
                        <div class="col-8">
                            <input type="email" (change)="onChange($any)" maxlength="50" pInputText formControlName="email" class="w-100" />
                        </div>
                        <div class="offset-4 col-8" style="color: red;" *ngIf="equalEmail">
                            This email adress is already taken, please enter the new one.
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                Phone
                            </label>
                        </div>
                        <div class="col-8">
                            <input pInputText maxlength="50" formControlName="phone" class="w-100" />
                        </div>

                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Username
                            </label>
                        </div>
                        <div class="col-8">
                            <input (change)="onUsernameChange()" pInputText pattern="^[a-zA-Z0-9\-._@+]+$" maxlength="50" formControlName="username" class="w-100" />
                        </div>
                        <div class="offset-4 col-8" style="color: red;" *ngIf="equalUserName">
                            This username is already taken, please enter the new one.
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Password
                            </label>
                        </div>

                        <div class="col-7 pr-0">
                            <input (keyup)="onPasswordChange()" #password [type]="passwordFieldTextType ? 'text' : 'password'" maxlength="50" pInputText formControlName="password" class="w-100" />
                        </div>
                        <span class="input-group-text pointer" (click)="passwordFieldTextType = !passwordFieldTextType">
                            <i class="fa" [ngClass]="{'fa-eye-slash': !passwordFieldTextType,'fa-eye': passwordFieldTextType}"></i>
                        </span>
                        <!-- <div *ngIf="formGroup.controls['password'].errors.required">
                            Password is required.
                        </div> -->
                        <div class="offset-4 col-8" style="color: red;" *ngIf="!!formGroup.controls['password'].errors && formGroup.controls['password'].errors.pattern && formGroup.controls['password'].touched">
                            Password must contain at least one uppercase character, one special character, one digit character and be 8 or more characters length.
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Confirm Password
                            </label>
                        </div>

                        <div class="col-7 pr-0">
                            <input (keyup)="onPasswordChange()" [type]="confirmPasswordFieldTextType ? 'text' : 'password'" maxlength="50" pInputText formControlName="confirmPassword" class="w-100" />                            
                        </div>
                        <span class="input-group-text pointer" (click)="confirmPasswordFieldTextType = !confirmPasswordFieldTextType">
                            <i class="fa" [ngClass]="{'fa-eye-slash': !confirmPasswordFieldTextType,'fa-eye': confirmPasswordFieldTextType}"></i>
                        </span>
                        <div class="offset-4 col-8" style="color: red;" *ngIf="formGroup.controls['confirmPassword'].value != formGroup.controls['password'].value">
                            Passwords must match.
                        </div>
                    </div>
                    <span *ngIf="isAddingClient; else teamUser">
                        <div class="row mb-3">
                            <div class="col-4">
                                <label>
                                    Send User Notifications
                                </label>
                            </div>
                            <div class="col-8">
                                <p-checkbox formControlName="sendUserNotification" binary="true" label='Send the new User an email about Account'></p-checkbox>
                            </div>
                        </div>
                        <div *ngIf="(!user || !isFromAccount) && (showRolesDropdowns || isFromAccount != true)" class="row mb-3">
                            <div class="col-4">
                                <label class="required">
                                    Account
                                </label>
                            </div>
                            <div class="col-8">
                                <p-dropdown *ngIf="!!accountsSource && !!accountsSource.length; else accountName" autoWidth="false" [filter]=true [style]="{'width':'100%'}" formControlName="account" [options]="accountsSource"></p-dropdown>
                                <ng-template #accountName>{{user.userAccounts[0].accountCreate.name}}</ng-template>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="showRolesDropdowns || isFromAccount != true" > 
                            <div class="col-4">
                                <label class="required">
                                    Account Role
                                </label>
                            </div>

                            <div class="col-8">
                                <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="accountRole" [options]="userRoles"></p-dropdown>
                            </div>
                        </div>
                    </span>
                    <ng-template #teamUser>
                        <div class="row mb-3">
                            <label class="col-4 required">
                                Role
                            </label>
                            <div class="col-8">
                                <p-dropdown *ngIf="userRoles && userRoles.length > 0" autoWidth="false" [style]="{'width':'100%'}" formControlName="accountRole" [options]="userRoles"></p-dropdown>
                            </div>
                        </div>
                    </ng-template>
                </div>

            </div>
        </form>
    </div>
    <!-- <p-accordion *ngIf="!isAddingClient" expandIcon=" pi pi-fw pi-chevron-right" collapseIcon="pi pi-fw pi-chevron-down" [multiple]="true">
        <p-accordionTab class="d-flex flex-column page-container" header="Assign User to a Team">
            <div class="col-12 p-0 my-2">
                <pick-list titleSource="Active Teams" titleTarget="Assigned Teams" [sourceList]="teams" [targetList]="userTeams"></pick-list>
            </div>
        </p-accordionTab>
    </p-accordion> -->
    <div class="row mx-3 mt-4 mb-3" *ngIf="!isAddingClient && !isFromTeam">
        <div class="col-12">
            <div class="display-4">
                Assign User to a Team
                <div class="sub-heading">
                    Pick a team to assign to the employee
                </div>
            </div>
        </div>
    </div>
    <div class="row mx-3" *ngIf="!isAddingClient && !isFromTeam">
        <div class="col-12">
            <pick-list titleSource="Active Teams" titleTarget="Assigned Teams" [sourceList]="teams" [targetList]="userTeams"></pick-list>
        </div>
    </div>
</div>

<div *ngIf="!isFromTeam && !isFromAccount && !!formGroup" class="row mx-3 mt-3 mb-5">
    <div class="col-12 d-flex flex-wrap justify-content-end">
        <button (click)="onCancel()" class="btn btn-light flat-btn mr-3">
            CANCEL
        </button>

        <button [disabled]='formGroup.invalid || equalEmail || equalUserName || !passwordMatch' pButton (click)="onSave()" label="SAVE & EXIT" class="p-button-primary"></button>

        <!-- <button [disabled]='formGroup.invalid' class="p-button-primary" pButton label="SAVE & NEXT"></button> -->
    </div>
</div>