<div class="modal-wrapper">
    <form>
        <div class="row modal-internal-content">
            <div class="col-6 offset-3">
                <p-card>
                    <div class="row my-2 px-5">
                        <div class="col-12">
                            <div class="d-flex flex-wrap justify-content-between align-items-center py-3 my-3 upload-heading">
                                <div class="text">Upload CRM</div>
                                <div (click)="handleCloseDialog()" class="close-icon">
                                    <i class="fa fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div (onFileDropped)="selectFile($event)" *ngIf="!!crmDto" class="row my-2 px-5">
                        <div class="col-12 text-center">
                            <div (onFileDropped)="selectFile($event)" class="upload-file-wrapper mb-2" id="first">
                                <div>
                                    <img *ngIf="urls[0]" alt="" [ngClass]="this.borders[0] ? 'border border-2' : ''" style="width: 100%; height: 100%; display: block;" />

                                    <div (onFileDropped)="selectFile($event)" class="py-5" *ngIf="(!isUploading && !isUploaded)">
                                        <div>
                                            Drag and drop .xls or .csv files
                                        </div>

                                        <div>
                                            or
                                            <a  accept=".xlsx,.csv,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" *ngIf="!urls[0]" (click)="uploader1.click()" id="upload_link" class="link-color-secondary browse-btn">
                                                browse
                                            </a>
                                        </div>
                                    </div>

                                    <div class="p-5" *ngIf="(isUploading && !isUploaded)">
                                        <div class="text-center text-success mb-3">Uploading...</div>
                                        <p-progressBar [value]="uploadProgress" [showValue]="false" class="bg-primary"></p-progressBar>
                                    </div>

                                    <div class="px-5 pt-3 pb-5" *ngIf="isUploaded">
                                        <div class="alert alert-success text-left uploaded-alert">
                                            File was uploaded successfully.
                                        </div>


                                        <div class="d-flex file-container align-items-center justify-content-between">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <i class="far fa-file-spreadsheet"></i>
                                                </div>

                                                <div class="ml-3 file-name">
                                                    {{fileName}}
                                                </div>
                                            </div>

                                            <div class="justify-content-end">
                                                <button pButton class="p-button-primary" label="Replace" (click)="handleReplace($event)"></button>
                                            </div>
                                        </div>
                                    </div>

                                    <input accept=".xlsx,.csv,.xls" id="upload" type="file" #uploader1 (change)="selectFile($event.target.files, 1, 160, 600)" hidden />
                                </div>

                                <div *ngIf="!isUploading && isUploaded"></div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2 px-5">
                        <div class="col-3">
                            <span >Campaign</span>
                        </div>
                        <div class="col-9">
                            <p-dropdown [options]="campaigns" name="campaign" [(ngModel)]="crmDto.campaignId" [ngModelOptions]="{standalone: true}"></p-dropdown>
                        </div>
                    </div>

                    <div class="row my-2 px-5">
                        <div class="col-3">
                            <span class="required">CRM Types</span>
                        </div>
                        <div class="col-9">
                            <p-dropdown [options]="crmTypes" [(ngModel)]="crmDto.crmType" [ngModelOptions]="{standalone: true}"></p-dropdown>
                        </div>
                    </div>

                    <div class="row my-2 px-5">
                        <div class="col-3">
                            Comments
                        </div>

                        <div class="col-9">
                            <input [(ngModel)]="crmDto.comment" maxlength="100" [ngModelOptions]="{standalone: true}" type="text" class="w-100" pInputText name="" id="" />
                        </div>
                    </div>

                    <div class="row my-3 px-5">
                        <div class="col-12">
                            <a href="#" (click)="onDownload()" class="link">Click here to check out the best practices.</a>
                        </div>
                    </div>

                    <p-footer>
                        <div class="row">
                            <div class="col-12 px-5 mb-3">
                                <div class="d-flex flex-wrap justify-content-end px-3">
                                    <button pButton label="Cancel" class="flat-btn text-uppercase mr-3" (click)="handleCloseDialog()"></button>

                                    <span *ngIf="!!crmDto.crmType  && isUploaded">
                                        <button pButton label="Save & Exit" class="p-button-primary text-uppercase mr-3 py-1" (click)="handleSaveandExit($event)"></button>

                                        <button pButton label="Save & Add New CRM" class="p-button-secondary text-uppercase mr-3 py-1" (click)="handleSaveandNext($event)"></button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </p-footer>
                </p-card>
            </div>
        </div>
    </form>
</div>