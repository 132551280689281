<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Native Video</strong></span>
</div>
<div class="pl-3" [ngClass]="this.currentUser.permissions.isLearfieldClient ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign || (creative.name && this.campaign.account.isHubStandardCampaign)" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="creative.flightStartDate && creative.flightEndDate"> | {{(this.creative.flight ) | date : constants.DEFAULT_FORMAT_DATE_US}} - {{(this.creative.flightEndDate ) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
        </div>
    </div>

    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div class="mb-4 mt-3 pt-3">

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Company Name</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.companyName}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=25 [(ngModel)]="creative.detailJson.companyName" [title]="creative.detailJson.companyName" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{25-creative.detailJson.companyName.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Short Title</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.shortTitle}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=25 [(ngModel)]="creative.detailJson.shortTitle" [title]="creative.detailJson.shortTitle" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{25-creative.detailJson.shortTitle.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Long Title</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.longTitle}}</label>
                        <div *ngIf="!reviewPage" class="input-group">
                            <input class="form-control" type="text" [maxlength]=50 [(ngModel)]="creative.detailJson.longTitle" [title]="creative.detailJson.longTitle" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{50-creative.detailJson.longTitle.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Short Description</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.shortDescription}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=90 [(ngModel)]="creative.detailJson.shortDescription" [title]="creative.detailJson.shortDescription" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{90-creative.detailJson.shortDescription.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2">
                <label><strong>Long Description</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.longDescription}}</label>

                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=140 [(ngModel)]="creative.detailJson.longDescription" [title]="creative.detailJson.longDescription" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{140-creative.detailJson.longDescription.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Call to Action</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.callToAction}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=15 [(ngModel)]="creative.detailJson.callToAction" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{15-creative.detailJson.callToAction.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Landing page URL</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input *ngIf="!reviewPage" [disabled]="!!landingPage || !functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" type="text" class="form-control" [(ngModel)]="!!landingPage ? landingPage : creative.detailJson.landingPageUrl" [title]="isLandingUrlOk == false ? titleWrongUrl : !!landingPage ? landingPage : creative.detailJson.landingPageUrl" style="overflow: hidden; text-overflow: ellipsis;" [ngClass]="this.isLandingUrlOk == false ? 'border-wrong' : ''" (focusout)="validationLandingPageMessage()">
                <span *ngIf="!reviewPage" tooltipPosition="top" [pTooltip]='constants.landingPageInfo' class="fal fa-info-circle info"></span>
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.landingPageUrl}}</label>
            </div>
        </div>
    </div>

    <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
        <file-upload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[0].isValid!=null' [isApprove]='isApprove' filetypes="mp4" size=1073741824 [footer]='booleanFalse' [campaign]="this.campaign" [creative]="this.creative" [asset]="0"></file-upload>
        <file-upload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[1].isValid!=null' [isApprove]='isApprove' filetypes="jpg, jpeg" size=1048576 [footer]='booleanFalse' width=128 height=128 [header]="booleanTrue" [campaign]="this.campaign" [creative]="this.creative" [asset]="1"></file-upload>
    </div>

</div>