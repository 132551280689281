import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sign-up-page',
    templateUrl: './sign-up.page.html',
    styleUrls: ['./sign-up.page.scss']
})

export class SignupPageComponent implements OnInit {
    
    constructor() { }

    ngOnInit() { }
}