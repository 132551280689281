import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { SelectItem, MessageService } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eVideoType, eCampaignStatus, eAccountType } from '@Workspace/_generated/enums';
import { Router} from '@angular/router';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto} from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { LookUps, Constants, Functions } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CreativeSwapsDialog } from '../../../../../shared-core/components/creative-swaps-dialog/creative-swaps-dialog';

@Component({
    selector: 'connected-tv',
    templateUrl: './connected-tv.component.html',
    styleUrls: ['./connected-tv.component.scss'],
})
export class ConnectedTvComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
        private dateTime: DatePipe,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() landingPage: string;
    @ViewChild('creativeSwaps') creativeSwaps: CreativeSwapsDialog;
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    @Output() onCreativetDelete: EventEmitter<any> = new EventEmitter();
    @Output() onDateChange: EventEmitter<any> = new EventEmitter();
    videoTypes: SelectItem[] = [];
    videoType: eVideoType[] = [];
    isPreviewOpen: boolean;
    currentIndex: number;
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isUploadOk: boolean = true;
    constants = Constants;
    campaignDates = '';
    enumCampaignStatus = eCampaignStatus;
    currentUser = this.authService.userSettings;
    isLandingUrlOk: boolean = true;
    titleWrongUrl: string = 'Please enter a valid Url.';
    functions = Functions;
    maxNumberOfFlights: number = 1;
    enumAccountType = eAccountType;

    ngOnInit() {
        this.initLists();

        this.maxNumberOfFlights =  Math.max(...this.campaign.creatives.map(x => x.creativeGroup));
        //this.campaign.creatives.sort((x, y) => { return y.creativeGroup - x.creativeGroup })[0].creativeGroup;
        
        this.creative.creativeAssets.forEach(x => {
            if (!!x.file && !!x.file.urlWithToken)
                this.creativeAssetsForPreview.push(x);
        });
        
        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            });
    }

    initLists() {
        this.videoTypes = LookUps.getVideoTypeLu();
        this.videoTypes.unshift({ value: 0, label: "-" });
    }


    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    save() {
    }

    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }

    delete(){
        this.creativeSwaps.openDialog(
            this.campaign, this.creative.creativeGroup, 'Remove');
    }

    edit(){
        if(this.creativeSwaps){

            this.creativeSwaps.openDialog(
                this.campaign, this.creative.creativeGroup, 'Edit');
        }
    }

    editCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged) {
            this.creative.flightStartDate = event.startDate;
        }
        if (event.isEndDateChanged) {
            this.creative.flightEndDate = event.endDate;
        }

        this.creative.creativeName = event.creativeName;

        this.campaignDates = `${this.formatDate(
            (this.campaign.startDate)
        )} - ${this.formatDate(this.campaign.endDate)}`;
    }

    removeCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged || event.isEndDateChanged) {
            this.onCreativetDelete.emit({ baseCampaign: event.baseCampaign, creativeGroup: this.creative.creativeGroup });
        }
    }

    formatDate(dateProp: any) {
        var date = new Date(dateProp);
        return `${this.dateTime.transform(dateProp, this.constants.DEFAULT_FORMAT_DATE_US)}`;
    }
    
    validationLandingPageMessage() {
        let textToMacth = !!this.landingPage ? this.landingPage : this.creative.detailJson.landingPageUrl;
        // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?%#[\]@!\$&'\(\)\*\+,;=.]+$/;
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        var found = textToMacth.match(regex);
        this.isLandingUrlOk = true;
        if (found == null) {
            this.isLandingUrlOk = false;
        }
    }
}
