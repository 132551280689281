import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slider } from './route-animations';
import { AuthService } from '@Workspace/auth';
import { IUserCreateDto, IUserPasswordResetDto, IUserPropertiesDto } from '@Workspace/_generated/interfaces';
import { AfterViewInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'Workspace-root',
    templateUrl: './mogo.component.html',
    styleUrls: ['./mogo.component.scss'],
    animations: [
        slider
    ]
})
export class MogoComponent implements OnInit, AfterViewInit {
    constructor(
        public authService: AuthService,
        private cd: ChangeDetectorRef
    ) { }
    currentUser: IUserPropertiesDto;
    ngOnInit() {
        this.currentUser = this.authService.userSettings;

    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
