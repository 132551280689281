  <!-- <app-seleton-loader *ngIf="!loaded" cWidthPc="100" cHeightPx="400"></app-seleton-loader> -->

  <rc-grid #usersTable [options]="options" tableStyleClass="prime-table-font-size" class="common-grid-datatable" [paginator]="team ==null && account==null">
      <ng-template pTemplate="header" let-item>
          <tr>
              <th *ngFor="let column of options.columns" [pSortableColumn]="column.value" [ngStyle]="{ width: column.width + '%' }" [ngClass]="getTextAlignClass(column.align)" class="p-3">
                  <span *ngIf="column.label != 'CheckBox'">
                      {{ column.label }}
                      <p-sortIcon *ngIf="column.label !='Account' && column.label !='Action' && column.label !=''" [field]="column.value"></p-sortIcon>

                  </span>
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-cols let-rowIndex="rowIndex" class="p-table-scrollable-wrapper errorTooltip">
          <tr tooltipPosition="top">
              <td class="p-3">{{ rowData.fullName }}</td>
              <td *ngIf="
                    isClient(rowData) &&
                    !!rowData.userAccounts[0] &&
                    rowData.userAccounts[0].accountTypeId !=
                        enums.enumAccountType.FAN365
                " class="p-3">
                  {{
                    rowData.userAccounts[0].roleId
                        | enumString: enums.enumAccountUeserRole
                }}
              </td>
              <td *ngIf="
                    !!rowData.userAccounts[0] &&
                    rowData.userAccounts[0].accountTypeId ==
                        enums.enumAccountType.FAN365 &&
                    !account &&
                    isClient(rowData)
                " class="p-3"></td>
              <td *ngIf="!isClient(rowData)" class="p-3">
                  {{ rowData.roleId | enumString: enums.enumUserRole }}
              </td>
              <td *ngIf="isAccountColumnVisible" class="p-3">
                  <span *ngIf="isAccountColumnVisible && !!rowData.userAccounts[0]">{{ rowData.userAccounts[0].accountName }}</span>
              </td>

              <td class="p-3 text-right">
                  <i *ngIf="
                        (isClient(rowData) &&
                            !!rowData.userAccounts[0] &&
                            rowData.userAccounts[0].accountTypeId !=
                                enums.enumAccountType.FAN365) ||
                        (!isClient(rowData) &&
                            currentUser.permissions.canManageAccountUsers &&
                            !currentUser.permissions.isClient)
                    " (click)="openEditDialog(rowData)" class="fa fa-edit pr-1">
                  </i>
                  <i *ngIf="
                        (isClient(rowData) &&
                            rowData.userAccounts[0].accountTypeId !=
                                enums.enumAccountType.FAN365) ||
                        (!isClient(rowData) &&
                            currentUser.permissions.canManageAccountUsers &&
                            !currentUser.permissions.isClient)
                    " (click)="remove(rowData)" class="fa fa-times"></i>
              </td>
          </tr>
      </ng-template>
  </rc-grid>

  <user-edit-dialog [displayDialog]="isEditDialogDisplayed" *ngIf="!!selectedUser && isEditDialogOpen" (userDialogDisplay)="showHideDialog($event)" [user]="selectedUser"></user-edit-dialog>

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="userDelete" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>
