import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { SelectItem } from 'primeng/api';
import { eInventoryTypeOAS } from '@Workspace/_generated/enums';


@Component({
    selector: 'ad-unit-accordion',
    templateUrl: './ad-unit-accordion.component.html',
    styleUrls: ['./ad-unit-accordion.component.scss']
})
export class AdUnitAccordion implements OnInit {

    constructor(
        public campaignController: CampaignController
    ) { }

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() disabled: boolean = false;

    public validationMessage: string = "There is a significant decrease in scale if App Only is selected.";
    public messageVisible: boolean = false;
    public adUnitSelectOptions: SelectItem[];

    ngOnInit() {
        if(this.baseCampaign.account.appAdUnitId){
            this.adUnitSelectOptions = [
                
                {label: "OAS",value: "OAS"},
                {label: "OAS, App",value: "OAS, App"},
                {label: "App",value: "App"}
            ];
            this.baseCampaign.adUnitTypeSelected = this.baseCampaign.adUnitTypeSelected != null ? this.baseCampaign.adUnitTypeSelected :
                (this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium ? "OAS" : "OAS, App");
        }
        else
        {
            this.adUnitSelectOptions = [
                {label: "OAS",value: "OAS"}
            ];
            this.baseCampaign.adUnitTypeSelected = this.baseCampaign.adUnitTypeSelected != null ? this.baseCampaign.adUnitTypeSelected :"OAS";
        }
    }

    onAdUnitChange(event){
        if(event.value == "App"){
            this.messageVisible = true;
        }
        else{
            this.messageVisible = false;
        }
        const dialogResult = {
            dialogType: 'adUnit',
            adUnitTypeSelectedId: this.baseCampaign.adUnitTypeSelected,
        };
        this.onSave.emit(dialogResult);
    }
}