<p-dialog [modal]="true" [(visible)]="isVisible" appendTo="body">
    <p-header>{{action == 'edit' ? 'Edit' : action == 'remove' ? 'Remove' : 'Add'}} Page</p-header>

    <div *ngIf="isVisible && (action == 'edit' || action == 'add')" style="height: 400px">
        <div *ngIf="!!errorMessage" class="alert alert-danger">
            <b>{{ errorMessage }}</b>
        </div>

        <div class="row mb-2 align-items-center">
            <div class="col-md-3">
                Page Title<span class="text-danger font-weight-bold">*</span>
            </div>
            <div class="col-md-9">
                <input class="w-100" type="text" pInputText [(ngModel)]="instructionPage.title" />
            </div>
        </div>
        <div style="height: 300px;">
            <quill-editor [(ngModel)]="editorContent"></quill-editor>
                <!-- <div #editor [ngxSummernote]="editorCfg" [(ngModel)]="instructionPage.markupContent">
                </div> -->
        </div>

    </div>

    <div *ngIf="isVisible && action == 'remove'">
        <div>
            <label>
                Are you sure that you want to remove this page?
            </label>
        </div>
    </div>

    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="btn btn-light flat-btn text-uppercase" label="{{action == 'edit' || action == 'add' ? 'Cancel' : 'No'}}"></button>
            <button (click)="save()" pButton [disabled]="!instructionPage || instructionPage.title == null || instructionPage.title == ''"  class="p-button-primary text-uppercase" label="{{action == 'edit' || action == 'add' ? 'Save' : 'Yes'}}"></button>
        </div>
    </p-footer>
</p-dialog>