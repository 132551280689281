import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { IDemographicsDto, IEnumDataAttribute, IInstructionPageDto, IAgeRange } from '@Workspace/_generated/interfaces';
import { eGender, eParentalStatus, eIncomeToName, eIncomeFromName, eIncomeFrom, eIncomeTo, eMessageType, eGenderName, eParentalStatusName, eAgeFrom, eAgeTo, eAgeToName, eAgeFromName } from '@Workspace/_generated/enums';
import { RcCheckboxComponent } from '../rc-checkbox/rc-checkbox.component';
import { LookUps } from '@Workspace/common';
import { SelectItem } from 'primeng/api';
import { NotificationService } from '@Workspace/services';
import { ICheckBoxInfo } from '@Workspace/interfaces';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'demographic-dialog',
    templateUrl: './demographic.dialog.html',
    styleUrls: ['./demographic.dialog.scss']
})
export class DemographicDialog implements OnInit {

    constructor(private notificationService: NotificationService,
        public authService: AuthService

    ) { }


    @Output('onSave') onSave: EventEmitter<IDemographicsDto> = new EventEmitter<IDemographicsDto>();

    isVisible: boolean = false;
    isValid: boolean = false;
    currentCard: number;
    errorMessage: string;

    demographics: IDemographicsDto;

    genders: ICheckBoxInfo[];
    agesFrom: ICheckBoxInfo[];
    agesTo: ICheckBoxInfo[];

    parentalStatuses: ICheckBoxInfo[];
    incomes: ICheckBoxInfo[];
    enumAgesFrom;
    enumAgeTo;
    minimumAge;
    rangeValues: number[] = [15, 75];
    rangeValuesIncome: number[] = [15, 65];
    previousRangeValues: number[] = [];
    previousIncomeRangeValues: number[] = [];

    //stepValue: number = 7;
    itemsFrom: any[] = [];
    itemsTo: any[] = [];

    itemsFromIncome: any[] = [];
    itemsToIncome: any[] = [];

    isNone: boolean = false;
    isNoneIncome: boolean = false;

    ngOnInit() {
        this.itemsFrom = [{
            label: 0,
            value: 0
        },
        {
            label: 15, // 18
            value: 1
        },
        {
            label: 25,
            value: 2
        },
        {
            label: 35,
            value: 3
        },
        {
            label: 45,
            value: 4
        },
        {
            label: 55,
            value: 5
        },
        {
            label: 65,
            value: 6
        },
        {
            label: 75, // 65+
            value: 7
        }
        ];
        this.itemsTo = [{
            label: 0,
            value: 0
        },
        {
            label: 25,
            value: 1
        },
        {
            label: 35,
            value: 2
        },
        {
            label: 45,
            value: 3
        }
            ,
        {
            label: 55,
            value: 4
        },
        {
            label: 65,
            value: 5
        },
        {
            label: 75, // 65+
            value: 6
        }
        ];

        this.itemsFromIncome = [{
            label: 0,
            value: 0
        },
        {
            label: 15, // 18
            value: 1
        },
        {
            label: 25,
            value: 2
        },
        {
            label: 35,
            value: 3
        },
        {
            label: 45,
            value: 4
        },
        {
            label: 55,
            value: 5
        },
        {
            label: 65,
            value: 6
        }
        ];

        this.itemsToIncome = [{
            label: 0,
            value: 0
        },
        {
            label: 15, // 18
            value: 1
        },
        {
            label: 25,
            value: 2
        },
        {
            label: 35,
            value: 3
        },
        {
            label: 45,
            value: 4
        },
        {
            label: 55,
            value: 5
        },
        {
            label: 65,
            value: 6
        }];

    }

    openDialog(preselected: IDemographicsDto = null) {
        this.resetData();
        this.enumAgesFrom = this.agesFrom.map(x => ({ value: x.value, label: x.label }));
        this.enumAgeTo = this.agesTo.map(x => ({ value: x.value, label: x.label }));
        if (!!preselected) {
            this.demographics = JSON.parse(JSON.stringify(preselected));
            this.genders.forEach(x => x.checked = (preselected.genderList & x.value) > 0);
            //this.incomes.forEach(x => x.checked = (preselected.incomeList & x.value) > 0);
            //this.parentalStatuses.forEach(x => x.checked = (preselected.parentalStatusList & x.value) > 0);
            this.minimumAge = this.demographics.ageRange.ageFrom ?? 0;
        }
        if (this.demographics.ageRange.ageFrom == eAgeFrom.None && this.demographics.ageRange.ageTo == eAgeTo.None) {
            this.isNone = true;
        } else {
            this.rangeValues = []
            let ageFrom = this.itemsFrom.find(x => x.value == this.demographics.ageRange.ageFrom).label;
            let ageTo = this.itemsTo.find(x => x.value == this.demographics.ageRange.ageTo).label;
            this.rangeValues.push(ageFrom); //  Check it again !! :)
            this.rangeValues.push(ageTo);

        }

        if (this.demographics.incomeRange.from == eIncomeFrom.None && this.demographics.incomeRange.to == eIncomeTo.None) {
            this.isNoneIncome = true;
        } else {
            this.rangeValuesIncome = [];
            let from = this.itemsFromIncome.find(x => x.value == this.demographics.incomeRange.from).label;
            let to = this.itemsToIncome.find(x => x.value == this.demographics.incomeRange.to).label;
            this.rangeValuesIncome.push(from); //  Check it again !! :)
            this.rangeValuesIncome.push(to);

        }
        this.isVisible = true;
        this.currentCard = 1;
    }

    openCard(cardNumber: number) {
        this.currentCard = cardNumber;
    }

    handleChange(event: any) {

        this.previousRangeValues = this.rangeValues;
    };

    handleIncomeChange(event: any) {

        this.previousIncomeRangeValues = this.rangeValuesIncome;
    };

    onChange(event: any) {
        if (event.values[1] < 25) {
            event.values[1] = 25
        }
        if (event.values[0] > 65) {
            event.values[0] = 65
        }
        if (event.values[0] == event.values[1]) {
            event.values[0] = this.previousRangeValues[0];
            event.values[1] = this.previousRangeValues[1]
            this.rangeValues[0] = this.previousRangeValues[0];
            this.rangeValues[1] = this.previousRangeValues[1]
        }

        this.demographics.ageRange.ageFrom = this.itemsFrom.find(x => x.label == event.values[0]).value;
        this.demographics.ageRange.ageTo = this.itemsTo.find(x => x.label == event.values[1]).value
        this.validate();

    }

    onChangeIncome(event: any) {
        // if (event.values[1] < 25) {
        //     event.values[1] = 25
        // }
        // if (event.values[0] > 65) {
        //     event.values[0] = 65
        // }
        // if (event.values[0] == event.values[1]) {
        //     event.values[0] = this.previousIncomeRangeValues[0];
        //     event.values[1] = this.previousIncomeRangeValues[1]
        //     this.rangeValuesIncome[0] = this.previousIncomeRangeValues[0];
        //     this.rangeValuesIncome[1] = this.previousIncomeRangeValues[1]
        // }

        this.demographics.incomeRange.from = this.itemsFromIncome.find(x => x.label == event.values[0]).value;
        this.demographics.incomeRange.to = this.itemsToIncome.find(x => x.label == event.values[1]).value
        this.validate();

    }

    OnUnknownChanged(event: any) {
        if (event.checked) {
            this.demographics.ageRange.ageFrom = eAgeFrom.None;
            this.demographics.ageRange.ageTo = eAgeTo.None;
        }
        else {
            this.demographics.ageRange.ageFrom = eAgeFrom.From18;
            this.demographics.ageRange.ageTo = eAgeTo.Plus;
        }
        this.validate();

    }

    OnRangeChanged(event: any) {
        if (event.checked) {

            this.demographics.incomeRange.from = eIncomeFrom.None;
            this.demographics.incomeRange.to = eIncomeTo.None;
        } else {
            this.demographics.incomeRange.from = eIncomeFrom.Lower50;
            this.demographics.incomeRange.to = eIncomeTo.Top10;
        }
        this.validate();


    }

    save() {
        this.validate();

        if (!this.isValid) {
            return;
        }
        this.isVisible = false;

        const result = JSON.parse(JSON.stringify(this.demographics));

        this.onSave.emit(result);
    }

    cancel() {
        this.isVisible = false;
        this.resetData();
    }

    // gender
    onGenderOptionClick(gender: eGender, e: any) {
        let IsFan365User = this.authService.userSettings.accounts.find(x => x.id == this.authService.userSettings.accountId) ? this.authService.userSettings.accounts.find(x => x.id == this.authService.userSettings.accountId).isLearfieldCampaign : false
        // if (IsFan365User) {
        //     this.genders.forEach(x => x.checked = false);
        //     this.genders.find(x => x.value == gender).checked = e;
        //     this.demographics.genderList = gender
        //     this.validate();

        // }
        // else {

        this.demographics.genderList = this.orXor(this.demographics.genderList, gender, e.checked);
        this.validate();
        // }
    }

    // age
    onAgeFromOptionClick(age: number, e: any) {
        this.demographics.ageRange.ageFrom = age;
        if (!!age) {
            this.agesTo.forEach(x => {
                x.disabled = x.value < age;
            })
        }
        this.validate();
    }
    onAgeToOptionClick(age: number, e: any) {
        this.demographics.ageRange.ageTo = age;
        if (!!age) {
            this.agesFrom.forEach(x => {
                x.disabled = x.value > age;

            })
        }
        this.validate();
    }


    // onAgeFromOptionClick(age: number, e: any){
    //     this.demographics.ageRange.ageFrom=age;
    // }

    // onAgeToOptionClick(age: number, e: any){
    //     // this.demographics.ageRange.ageTo = age;
    //     this.validate();
    // }

    onAgeUnknownOptionClick(e: any) {
        this.demographics.ageUnknown = e.checked;
        this.validate();
    }

    // parental
    onParentOptionClick(parentalStatus: eParentalStatus, e: any) {
        this.demographics.parentalStatusList = this.orXor(this.demographics.parentalStatusList, parentalStatus, e.checked);
        this.validate();
    }

    onParentUnknownOptionClick(e: any) {
        this.demographics.parentalStatusUnknown = e.checked;
        this.validate();
    }

    // income
    // onIncomeOptionClick(income: eIncome, e: any) {
    //     this.demographics.incomeRange.to = income;
    //     if (!!income) {
    //         this.incomes.forEach(x => {
    //             x.disabled = x.value > income;

    //         })
    //     }
    //     this.validate();
    // }

    onIncomeUnknownOptionClick(e: any) {
        this.demographics.incomeUnknown = e.checked;
        this.validate();
    }

    // misc
    private orXor(data: any, shift: any, isOr: boolean) {
        if (isOr) {
            data = data | shift;
        } else {
            data = data ^ shift;
        }

        return data;
    }

    private validate() {
        this.isValid = true;
        const genderSpecified = this.demographics.genderList !== eGender.None;
        if (this.demographics.ageRange.ageTo !== eAgeTo.None && this.demographics.ageRange.ageFrom == eAgeFrom.None)
            this.demographics.ageRange.ageFrom = eAgeFrom.From18;
        const ageSpecified = this.demographics.ageRange.ageFrom !== eAgeFrom.None && this.demographics.ageRange.ageTo !== eAgeTo.None; // || this.demographics.ageUnknown;
        // const parentalSpecified = this.demographics.parentalStatusList !== eParentalStatus.None || this.demographics.parentalStatusUnknown;
        const incomeSpecified = this.demographics.incomeRange.from !== eIncomeFrom.None && this.demographics.incomeRange.to !== eIncomeTo.None; // || this.demographics.incomeUnknown;
        if (!this.isNone && this.demographics.ageRange.ageFrom == eAgeFrom.None && this.demographics.ageRange.ageTo == eAgeTo.None) {
            this.demographics.ageRange.ageFrom = eAgeFrom.From18;
            this.demographics.ageRange.ageTo = eAgeTo.Plus
        }
        if (!this.isNoneIncome && this.demographics.incomeRange.from == eIncomeFrom.None && this.demographics.incomeRange.to == eIncomeTo.None) {
            this.demographics.incomeRange.from = eIncomeFrom.Lower50;
            this.demographics.incomeRange.to = eIncomeTo.Top10;
        }
        // const groups = [genderSpecified, ageSpecified, parentalSpecified, incomeSpecified];
        const groups = [genderSpecified, ageSpecified, incomeSpecified];
        const specified = groups.filter(x => x === true).length;
        const errorMessage = `Selecting more than one type of demographic targeting can significantly reduce impression scale.
         This configuration is not supported through HUB. Please reach out to the Fan365 Activation team for support on this campaign.`;


        this.isValid = specified > 1 ? false : true;
        this.errorMessage = this.isValid ? null : errorMessage;
    }

    private createInitialDemographicsObject(): IDemographicsDto {
        return {
            genderList: eGender.None,
            ageRange: { ageFrom: eAgeFrom.None, ageTo: eAgeTo.None } as IAgeRange,
            parentalStatusList: eParentalStatus.None,
            incomeRange: { from: eIncomeFrom.None, to: eIncomeTo.None },
            ageUnknown: false,
            parentalStatusUnknown: false,
            incomeUnknown: true
        } as IDemographicsDto;
    }

    private enumMapToArray(map: Map<number, IEnumDataAttribute>): ICheckBoxInfo[] {
        let result: ICheckBoxInfo[] = [];
        map.forEach(((enumDataAttr, key) => result.push({ label: enumDataAttr.name, value: key, checked: false, disabled: false })));

        return result;
    }

    private resetData() {
        this.currentCard = 1;
        this.isValid = true;
        this.demographics = this.createInitialDemographicsObject();

        const excludeNoneFlag = ([k, _]) => k !== 0;

        const genderMap = new Map<number, IEnumDataAttribute>([...eGenderName].filter(excludeNoneFlag));
        const ageFromMap = new Map<number, IEnumDataAttribute>([...eAgeFromName]);
        const ageToMap = new Map<number, IEnumDataAttribute>([...eAgeToName]);

        //const parentalStatusMap = new Map<number, IEnumDataAttribute>([...eParentalStatusName].filter(excludeNoneFlag));
        //const incomeMap = new Map<number, IEnumDataAttribute>([...eIncomeName].filter(excludeNoneFlag));
        this.genders = this.enumMapToArray(genderMap);
        this.agesFrom = this.enumMapToArray(ageFromMap);
        this.agesTo = this.enumMapToArray(ageToMap);

        //this.parentalStatuses = this.enumMapToArray(parentalStatusMap);
        //this.incomes = this.enumMapToArray(incomeMap);
    }



    clickEvent(event) {
        this.minimumAge = event;
        if (this.demographics.ageRange.ageTo.valueOf() < this.demographics.ageRange.ageFrom.valueOf())
            this.demographics.ageRange.ageTo = this.demographics.ageRange.ageFrom.valueOf();

        this.validate();



    }


}