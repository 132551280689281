<!-- <div  class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Post Details</strong></span>
</div> -->


<div class="pl-3" class="left-border ml-5">

    <div class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">Additional Details</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>Link to Post</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage " type="text" [(ngModel)]="creative.detailSocialCampaignJson.linkToPost" [title]="creative.detailSocialCampaignJson.linkToPost" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.linkToPost}}</label>
        </div>
    </div>

    <!-- <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
        <file-upload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[0].isValid!=null' [isApprove]='isApprove' filetypes="jpg, jpeg, png, gif" size=1048576 [footer]='booleanFalse' [header]="booleanTrue" [headerTitle]="'Sponsor logo (required)'" [campaign]="this.campaign" [creative]="this.creative" [asset]="0"></file-upload>
    </div> -->

</div>