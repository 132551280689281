import { Component, Input, TemplateRef, ContentChild } from "@angular/core";

@Component({
    selector: "app-page-internal-header",
    templateUrl: "./page-internal-header.component.html",
    styleUrls: ["./page-internal-header.component.scss"]
})

export class PageInternalHeader {
    
    @ContentChild("templateLeft")
    // @Input()
    templateLeft: TemplateRef<any>;

    @ContentChild("templateRight")
    // @Input()
    templateRight: TemplateRef<any>;

    @Input()
    isGrid: boolean;
}