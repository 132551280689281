import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// bootstrap
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import * as directives from '@Workspace/directives';
import * as pipes from '@Workspace/pipes';
import * as primeng from 'primeng/api';
import * as table from 'primeng/table';
import * as toast from 'primeng/toast';
import { PaginatorComponent } from './components/grid/paginator/paginator.component';
import { GridComponent } from './components/grid/rc-grid.component';
import { ScrollableViewComponent } from './components/grid/scrollable-view/scrollable-view.component';
import { SortIconComponent } from './components/grid/sort-icon/sort-icon.component';
import { GridBodyComponent } from './components/grid/table-body/table-body.component';
import { ButtonExpandableComponent } from './components/button-expandable/button-expandable.component';
import {
    AccountController, TargetingController
} from '@Workspace/_generated/services';
import { SharedModule } from '../shared/shared.module';
import { PickListComponent } from './components/pick-list/pick-list.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { CampaignCostsTableComponent } from './components/campaign-costs-table/campaign-costs-table.component';
import { DemographicDialog } from './components/demographic-dialog/demographic.dialog';
import { RcCheckboxComponent } from './components/rc-checkbox/rc-checkbox.component';
import { GeographicDialog } from './components/geographic-dialog/geographic.dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { CardItemComponent } from './components/card-item/card-item.component';
import { FileDropComponent } from './components/rc-filedrop/rc-filedrop.component';
import { FileUpload } from './components/file-upload/file-upload.component';
import { SkeletonLoaderModule } from './components/seleton-loader/skeleton-loader.module';
import { AdditionalSettingsDialog } from './components/additional-settings-dialog/additional-settings-dialog.component';
import { UnlockFeaturesDialog } from './components/unlock-features-dialog/unlock-features-dialog.component';
import { RcGalleriaComponent } from './components/galleria/galleria.component';
import { FileApproveComponent } from './components/file-upload/file-approve/file-approve.component';
import { AdvertiserDialog } from './components/advertiser-dialog/advertiser-dialog.component';
import { PaymentStatusDialog } from './components/payment-status-dialog/payment-status-dialog';
import { CampaignDatesDialog } from './components/campaign-dates-dialog/campaign-dates-dialog.component';
import { KeyValuePairDialog } from './components/key-value-pair-dialog/key-value-pair-dialog.component';
import { CreativeSwapsDialog } from './components/creative-swaps-dialog/creative-swaps-dialog';
import { InventoryAddOnDialog } from './components/inventory-add-on-dialog/inventory-add-on-dialog.component';
import { CampaignDatesAccordion } from './components/campaign-dates-accordion/campaign-dates-accordion.component';
import { CampaignGeographicAccordion } from './components/campaign-geographic-accordion/campaign-geographic-accordion.component';
import { CampaignDemographicAccordion } from './components/campaign-demographic-accordion/campaign-demographic-accordion.component';
import { ComponentLoaderSpinnerComponent } from './components/component-loader-spinner/component-loader-spinner.component';
import { AdditionalSettingsAccordion } from './components/additional-settings-accordion/additional-settings-accordion.component';
import { InventoryAddOnAccordion } from './components/invertory-add-on-accordion/inventory-add-on-accordion.component';
import { KeyValuePairAccordion } from './components/key-value-pair-accordion/key-value-pair-accordion.component';
import { ImpressionFlightsDialog } from './components/impression-flights-dialog/impression-flights-dialog';
import { AdUnitAccordion } from './components/ad-unit-accordion.component/ad-unit-accordion.component';
import {SliderModule} from 'primeng/slider';
import {DropdownModule} from 'primeng/dropdown';
import {PaginatorModule} from 'primeng/paginator';
import {ButtonModule} from 'primeng/button';
import {EditorModule} from 'primeng/editor';
import {FieldsetModule} from 'primeng/fieldset';
import {SplitButtonModule} from 'primeng/splitbutton';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {PanelModule} from 'primeng/panel';
import {MenuModule} from 'primeng/menu';
import {TabViewModule} from 'primeng/tabview';
import {ProgressBarModule} from 'primeng/progressbar';
import {MessagesModule} from 'primeng/messages';
import {CardModule} from 'primeng/card';
import {ChipsModule} from 'primeng/chips';
import {ListboxModule} from 'primeng/listbox';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SpinnerModule} from 'primeng/spinner';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DragDropModule} from 'primeng/dragdrop';
import {AccordionModule} from 'primeng/accordion';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {PickListModule} from 'primeng/picklist';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {MultiSelectModule} from 'primeng/multiselect';
import {TooltipModule} from 'primeng/tooltip';
import {GalleriaModule} from 'primeng/galleria';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {SlideMenuModule} from 'primeng/slidemenu';
import {StepsModule} from 'primeng/steps';
import { InventoryItemsDialog } from './components/inventory-items-dialog/inventory-items-dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
    declarations: [
        //Components
        // TableComponent,
        PaginatorComponent,
        ScrollableViewComponent,
        SortIconComponent,
        GridBodyComponent,
        GridComponent,
        ButtonExpandableComponent,
        PickListComponent,
        InfoCardComponent,
        CampaignCostsTableComponent,
        RcCheckboxComponent,
        FileDropComponent,
        FileUpload,
        RcGalleriaComponent,
        FileApproveComponent,
        AdvertiserDialog,
        PaymentStatusDialog,
        CampaignDatesDialog,
        KeyValuePairDialog,
        CreativeSwapsDialog,
        ImpressionFlightsDialog,
        InventoryAddOnDialog,
        InventoryItemsDialog,
        // Pipes
        pipes.ElementSanitizerPipe,
        pipes.EnumStringPipe,
        pipes.CurrencyInputPipe,
        pipes.DateAgoPipe,
        pipes.CreditCardMaskPipe,
        pipes.DateToUserTimeZonePipe,
        // pipes.FileSizePipe,
        // pipes.FileTypePipe,
        // pipes.DurationPipe,
        directives.TruncateTextDirective,

        //Campaign Accordions
        CampaignDatesAccordion,
        CampaignGeographicAccordion,
        CampaignDemographicAccordion,
        AdditionalSettingsAccordion,
        InventoryAddOnAccordion,
        KeyValuePairAccordion,
        AdUnitAccordion,

        //Spinner
        ComponentLoaderSpinnerComponent,

        //Directives
        // directives.CheckmarkDirective,
        // directives.WidthSetDirective,
        // directives.AutoFocusDirective,
        // directives.FileDropDirective,
        // directives.ValidationDirective,

        //Dialogs
        // PresentationCreationDialog,
        // CustomDropdownComponent,
        // CardHeaderComponent,
        // ImmutableInputComponent
        DemographicDialog,
        GeographicDialog,
        AdditionalSettingsDialog,
        UnlockFeaturesDialog,
        CardItemComponent,
        UnlockFeaturesDialog,

    ],
    imports: [
        // toast.ToastModule,
        // table.TableModule,
        ReactiveFormsModule,
        FormsModule,
        // InfiniteScrollModule,
        CommonModule,
        RouterModule,
        // RcTreeModule,
        // RcGalleriaModule,
        // RcDynamicDialogModule,
        DynamicDialogModule,
        SharedModule,
        // NgbModule,
        SkeletonLoaderModule,
        //Primeng
        SliderModule,
        DropdownModule,
        PaginatorModule,
        ButtonModule,
        EditorModule,
        FieldsetModule,
        SplitButtonModule,
        DialogModule,
        InputTextModule,
        PanelModule,
        MenuModule,
        TabViewModule,
        ProgressBarModule,
        MessagesModule,
        CardModule,
        ChipsModule,
        ListboxModule,
        ProgressSpinnerModule,
        SpinnerModule,
        ColorPickerModule,
        ConfirmDialogModule,
        DragDropModule,
        AccordionModule,
        ToggleButtonModule,
        CalendarModule,
        CheckboxModule,
        TabMenuModule,
        AutoCompleteModule,
        PickListModule,
        RadioButtonModule,
        //primeng.GrowlModule,
        FileUploadModule,
        InputMaskModule,
        RadioButtonModule,
        ScrollPanelModule,
        InputNumberModule,
        InputSwitchModule,
        MultiSelectModule,
        TooltipModule,
        GalleriaModule,
        BreadcrumbModule,
        TieredMenuModule,
        SlideMenuModule,
        FontAwesomeModule
    ],
    providers: [
        AccountController,
        TargetingController,
        DialogService,
        primeng.ConfirmationService,
        // primeng.MessageService,
    ],
    exports: [
        //Components
        // TableComponent,
        PaginatorComponent,
        ScrollableViewComponent,
        SortIconComponent,
        GridBodyComponent,
        GridComponent,
        ButtonExpandableComponent,
        PickListComponent,
        InfoCardComponent,
        CampaignCostsTableComponent,
        RcCheckboxComponent,
        FileDropComponent,
        FileUpload,
        RcGalleriaComponent,
        FileApproveComponent,
        AdvertiserDialog,
        PaymentStatusDialog,
        CampaignDatesDialog,
        KeyValuePairDialog,
        CreativeSwapsDialog,
        ImpressionFlightsDialog,
        InventoryAddOnDialog,
        InventoryItemsDialog,
        
        //Campaign Accordions
        CampaignDatesAccordion,
        CampaignGeographicAccordion,
        CampaignDemographicAccordion,
        AdditionalSettingsAccordion,
        InventoryAddOnAccordion,
        KeyValuePairAccordion,
        AdUnitAccordion,
        
        //Spinner
        ComponentLoaderSpinnerComponent,

        //Pipes
        pipes.ElementSanitizerPipe,
        pipes.EnumStringPipe,
        pipes.CurrencyInputPipe,
        pipes.DateAgoPipe,
        pipes.CreditCardMaskPipe,
        pipes.DateToUserTimeZonePipe,
        // pipes.FileTypePipe,
        // pipes.DurationPipe,

        //Directives
        // directives.AutoFocusDirective,
        // directives.ValidationDirective,
        directives.TruncateTextDirective,

        //Dialogs
        DemographicDialog,
        GeographicDialog,
        AdditionalSettingsDialog,
        UnlockFeaturesDialog,
        // Modules
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        DynamicDialogModule,
        CardItemComponent,
        //Primeng
        SliderModule,
        FieldsetModule,
        DropdownModule,
        PaginatorModule,
        ColorPickerModule,
        ButtonModule,
        CheckboxModule,
        SliderModule,
        SpinnerModule,
        ColorPickerModule,
        ConfirmDialogModule,
        DragDropModule,
        AccordionModule,
        CalendarModule,
        TabMenuModule,
        AutoCompleteModule,
        ToggleButtonModule,

        // Modules
        toast.ToastModule,
        // table.TableModule,
        ProgressBarModule,
        MessagesModule,
        CardModule,
        ChipsModule,
        ListboxModule,
        SplitButtonModule,
        DialogModule,
        InputTextModule,
        InputSwitchModule,
        MultiSelectModule,
        RadioButtonModule,

        PanelModule,
        MenuModule,
        TabViewModule,
        ProgressSpinnerModule,
        GalleriaModule,
        // primeng.GrowlModule,
        FileUploadModule,
        InputMaskModule,
        ScrollPanelModule,
        StepsModule,
        InputNumberModule,
        TooltipModule,
        BreadcrumbModule,
        TieredMenuModule,
        SlideMenuModule

        // SharedModule
    ]
})
export class SharedCoreModule { }
