<div #container [ngClass]="{
        'p-splitbutton p-buttonset p-component': true,
        'p-disabled': disabled
    }" [ngStyle]="style" [class]="styleClass">
    <button type="button" pButton [title]="label" [ngClass]="_isBaseTheme ? 'button-base' : (isUserButton ? 'button-transparent-logo' :'button-transparent' )" [iconPos]="iconPos" [icon]="icon" [label]="label"  (click)="onDropdownButtonClick($event)" [disabled]="disabled" class="text-truncate"></button>
    <!-- cornerStyleClass depracated, komponenta se ne koristi
        [cornerStyleClass]="dir === 'rtl' ? 'p-corner-left' : 'p-corner-right'" 
    -->
    <div #overlay [ngClass]="
            'p-menu p-menu-dynamic p-component p-component-content p-corner-all p-helper-clearfix p-shadow'
        " *ngIf="overlayVisible" [ngStyle]="menuStyle" [class]="menuStyleClass" [@overlayAnimation]="{
            value: 'visible',
            params: {
                showTransitionParams: showTransitionOptions,
                hideTransitionParams: hideTransitionOptions
            }
        }" (@overlayAnimation.start)="onOverlayAnimationStart($event)">
        <ul class="p-menu-list p-reset">
            <ng-template ngFor let-item [ngForOf]="model">
                <li class="p-menuitem p-component p-corner-all" role="menuitem" *ngIf="item.visible !== false">
                    <a *ngIf="!item.routerLink" [attr.href]="item.url" class="p-menuitem-link p-corner-all" [attr.target]="item.target" [ngClass]="{ 'p-disabled': item.disabled }" (click)="itemClick($event, item)">
                        <span [ngClass]="'p-menuitem-icon'" [class]="item.icon" *ngIf="item.icon"></span>
                        <span class="p-menuitem-text">{{ item.label }}</span>
                        <span class="p-menuitem-text">

                <li *ngIf="!!item.items && item.items.length >1" class="">
                    <p-dropdown [(ngModel)]="item.id" [options]="item.items" (onChange)="onChangeSelection($event)">
                    </p-dropdown>
                </li>
                <li *ngIf="!!item.items && item.items.length ==1" class="">
                    {{item.items[0].label}}
                </li>
                </span>
                </a>
                <a *ngIf="item.routerLink" [routerLink]="item.routerLink" [queryParams]="item.queryParams" class="p-menuitem-link p-corner-all" [attr.target]="item.target" [ngClass]="{ 'p-disabled': item.disabled }" (click)="itemClick($event, item)">
                    <span [ngClass]="'p-menuitem-icon'" [class]="item.icon" *ngIf="item.icon"></span>
                    <span class="p-menuitem-text">{{ item.label }}</span>
                </a>
                </li>
            </ng-template>
        </ul>
    </div>
</div>