<div>
    <!-- <div class="card card-body mb-3 mx-3">
        <div *ngIf="campaignService.getIsGrid() | async" class="row align-items-center">
            <div class="col-4 display-3">Accounts & Users</div>
            <div class="col-8 d-flex justify-content-end">
                <button *hasPermission="[constants.CanManageAccountUsers]" (click)="navigateUser()" pButton label="ADD NEW USER" class="p-button-primary mr-3">
                </button>

                <button *ngIf="!currentUser.permissions.isClient" (click)="navigateAccount()" pButton label="ADD NEW ACCOUNT" class="p-button-secondary">
                </button>
            </div>
        </div>
    </div> -->

    <app-page-internal-header *ngIf="!currentUser.permissions.isLearfieldClient">
        <ng-template #templateLeft>
            <div class="display-3">Accounts & Users</div>
        </ng-template>

        <ng-template #templateRight>

            <button *ngIf="!currentUser.permissions.isClient" (click)="navigateAccount()" pButton label="ADD NEW ACCOUNT" class="p-button-secondary mr-3">
            </button>

            <button *hasPermission="[constants.CanManageAccountUsers]" (click)="navigateUser()" pButton label="ADD NEW USER" class="p-button-primary">
            </button>

        </ng-template>
    </app-page-internal-header>

    <div class="card card-body mx-3">
        <div *ngIf="!currentUser.permissions.isLearfieldClient" class="row mb-3">
            <div *ngIf="checkRoute()" class="col-6">
                <p-dropdown autoWidth="false" [(ngModel)]="gridId" class="col-4 p-0" (onChange)="onChangeGrid($event)" [options]="grids">
                </p-dropdown>
            </div>
            <div class="col-6" *ngIf="checkRoute()">
                <div class="p-inputgroup float-right">
                    <input type="text" pInputText [(ngModel)]="searchParameter" placeholder="Search" (keyup.enter)="composeFilter()" style="border-right: 0;" />
                    <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="composeFilter()"></button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <!-- <account-list *ngIf="gridId==1"></account-list>
                <user-list *ngIf="gridId==2"></user-list> -->

                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>