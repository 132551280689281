import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@Workspace/auth';
import * as guards from '@Workspace/guards';
import * as services from '@Workspace/services';
import { AuthenticationHttpInterceptor } from 'libs/interceptors/authentication-http.interceptor';
import { AuthAdSevice } from 'libs/services/auth/auth.ad.service';
import { AuthTokenService } from 'libs/services/auth/auth.token.service';
import { BroadcastService } from 'libs/services/auth/msad/broadcast.service';
import { MsalWrapperService } from 'libs/services/auth/msad/msal-wrapper.service';
import { storeFreeze } from 'ngrx-store-freeze';
import { ConfirmationService, MessageService } from 'primeng/api';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppInitializer } from './app.initializer';
import { AppComponent } from './modules/app.component';
import { MogoModule } from './modules/mogo/mogo.module';
import { CartItemService } from './modules/shared-core/components/card-item/card-item.service';
import { SharedCoreModule } from './modules/shared-core/shared-core.module';
import { SharedModule } from './modules/shared/shared.module';
import { DatePipe } from '@angular/common';
import { ToastModule } from 'primeng/toast';

// import { SettingsService, StorageService } from '@Workspace/services';
// import { PermissionService } from '@Workspace/services';

@NgModule({
    declarations: [AppComponent],
    imports: [
        //Modules
        AppRoutingModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        MogoModule,
        HttpClientModule,
        StoreModule.forRoot(
            {},
            { metaReducers: !environment.production ? [storeFreeze] : [] }
        ),
        EffectsModule.forRoot([]),
        TranslateModule.forRoot(),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot(),
        SharedCoreModule,
        ToastModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializer: AppInitializer) => {
                return () => appInitializer.initializeAsync();
            },
            deps: [AppInitializer],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationHttpInterceptor,
            multi: true
        },

        //Services
        guards.LoggedInGuard,
        guards.AuthGuard,
        services.SettingsService,
        services.StorageService,
        services.PageLoaderService,
        services.ProgressBarService,
        AppInitializer,
        AuthTokenService,
        BroadcastService,
        MsalWrapperService,
        AuthAdSevice,
        AuthService,
        ConfirmationService,
        CartItemService,
        DatePipe,
        MessageService
        //services.PermissionService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
