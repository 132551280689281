import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem, ConfirmationService, MessageService } from 'primeng/api';
import { componentFadeIn } from '../../../route-animations';
import { IGridOptions } from '@Workspace/interfaces';
import { Functions, Constants } from '@Workspace/common';
import {
    AccountController, TeamController
} from '@Workspace/_generated/services';
import { IAccountDto, IUserDto, IUserAccountDto, IUserCreateDto, ITeamDto } from '@Workspace/_generated/interfaces';
import { eUserRole, eUserAccountRole, eEntityStatus, eAccountType } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { Router } from '@angular/router';
import { SettingsService } from '@Workspace/services';
import { CampaignService } from '../../../services/campaign.service';
import { takeUntil } from 'rxjs/operators';
import { GridComponent } from '../../../../shared-core/components/grid/rc-grid.component';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['user-list.component.scss'],
    animations: [componentFadeIn]
})
export class UserListComponent implements OnInit, OnDestroy {

    constructor(
        public accountController: AccountController,
        public teamController: TeamController,
        public authService: AuthService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private settingsService: SettingsService,
        private campaignService: CampaignService,
        public messageService: MessageService,

    ) {

    }

    @Input() account: IAccountDto;
    @Input() team: ITeamDto;

    loaded: boolean = false;
    options: IGridOptions;
    columns: any[] = [];
    users: IUserCreateDto[] = [];
    selectedUser: IUserCreateDto;
    isEditDialogOpen: boolean;
    isEditDialogDisplayed: boolean;
    isAccountColumnVisible: boolean;
    currentUser = this.authService.userSettings;
    private searchParameter: string;
    @Output() onRemove: EventEmitter<object> = new EventEmitter<object>();
    @ViewChild('usersTable') usersTable: GridComponent;

    public enums: any = {
        enumUserRole: eUserRole,
        enumAccountUeserRole: eUserAccountRole,
        enumAccountType: eAccountType
    }
    ngUnsubscribe = new Subject();


    ngOnInit() {
        this.campaignService.getRefresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {

            this.campaignService.getUserSearchPareameter().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {                
                this.composeGridColumns(x);
                this.searchParameter = x;
            });
        });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    composeGridColumns(searchParameter?: string) {
        if (this.usersTable) {  
            this.usersTable.resetSkipTake();
        }
        this.isAccountColumnVisible = this.router.url.indexOf("accounts/users") > -1;

        this.options = {} as IGridOptions;

        this.options.columns = [
            {
                label: 'Full Name',
                value: 'fullName',
                width: '35'
            },
            {
                label: !!this.account && this.account.isLearfieldCampaign ? "" : "Role",
                value: 'roleId',
                width: !!this.account && this.account.isLearfieldCampaign ? '' : (this.isAccountColumnVisible ? '25' : '33.3'),
            },
            {
                label: "Account",
                value: '',
                width: '25'
            },
            {
                label: 'Action',
                value: '',
                align: 'right'
            }
        ];

        if (!this.isAccountColumnVisible) {
            this.options.columns.splice(2, 1);
        }

        this.options.isOData = true;
        this.options.isSimpleView = false;
        this.options.sortField = 'FullName';

        this.composeFilter(searchParameter);

        Functions.composeGridOptions(
            this.options,
            'FullName',
            this.settingsService.createApiUrl('Account/GetUsers'),
            false
        );

    }

    composeFilter(searchParameter?: string) {
        if (!!this.account || !!this.currentUser.accountId) {
            let accountId = !!this.account ? this.account.id : this.currentUser.accountId
            this.options.filter = `UserAccounts/any(c: c/AccountId eq ${accountId}) and RoleId eq Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Client}'`
        }
        else if (!!this.team) {
            this.options.filter = `UserTeams/any(c: c/TeamId eq ${this.team.id})`
        }
        else if (this.router.url.indexOf('accounts') > -1) {
            this.options.filter = `RoleId eq Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Client}'`
        } else {
            this.options.filter = `RoleId ne Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Client}'`
        }

        if (!!searchParameter) {
            const searchTerm = searchParameter.replace(
                /\'/g,
                "''"
            );
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') + ` (contains(FullName, '${searchTerm}'))`

        }

        this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') + ` EntityStatus eq Learfield.Mogo_Fan365.Domain.Enums.eEntityStatus'${eEntityStatus.Active} '`

        let options = this.options;
        this.options = Object.assign({}, options);
    }

    getTextAlignClass(textAlign: string): string {
        let textAlignClass: string = "text-left";
        switch (textAlign) {
            case 'left':
                textAlignClass = "text-left";
                break;
            case 'center':
                textAlignClass = "text-center";
                break;
            case 'right':
                textAlignClass = "text-right";
                break;
        }
        return textAlignClass;
    }
    isClient(data) {
        if (!!data.userAccounts.length && !!data.userAccounts[0].roleId && data.roleId == eUserRole.Client)
            return true;
        else
            return false;
    }

    openEditDialog(event) {
        this.selectedUser = event;
        this.showHideDialog(true);

    }

    showHideDialog(event: boolean) {
        this.isEditDialogDisplayed = event;
        setTimeout(() => {
            this.isEditDialogOpen = event;
        }, 0);
    }

    onChangeGrid(event) {
        if (event.value == 1)
            this.router.navigate(['/mogo/accounts']);
        else
            this.router.navigate(['/mogo/accounts/users']);
    }

    remove(user: IUserCreateDto) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'userDelete',
            message:
                !!this.team ? 'This action will remove this user from the Team. Are you sure you want to proceed?' : ((!!this.account || this.isAccountColumnVisible) ? 'This action will delete this client from HUB. Are you sure you want to proceed?' : 'This action will delete this user from HUB. Are you sure you want to proceed?'),
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                if (this.team == null) {
                    user.entityStatus = eEntityStatus.Deleted;
                    user.userAccounts.forEach(x => x.entityStatus = eEntityStatus.Deleted);
                    user.userTeams.forEach(x => x.entityStatus = eEntityStatus.Deleted);
                } else
                    user.userTeams.find(x => x.userId == user.id).entityStatus = eEntityStatus.Deleted;

                this.accountController.CreateUser(user).subscribe(x => {
                    this.campaignService.setRefresh(true)
                    this.messageService.add({ severity: 'success', detail: 'User removed successfully' + (!!this.team ? ' from Team' : '') });

                    this.composeGridColumns(this.searchParameter);
                    this.onRemove.emit();
                });
            }
        });

    }

    navigateAccount() {
        return ('/accounts/' + Constants.GuidEmpty);
    }
}
