import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Subject} from 'rxjs';
import { AuthService } from '@Workspace/auth';
import { eAccountType, eCampaignStatus, eEntityStatus, eInventoryTypeOAS, eStandardCampaginChannel, eUserRole } from '@Workspace/_generated/enums';
import { Functions } from '@Workspace/common';
import * as moment from 'moment';

@Component({
    selector: 'campaign-dates-accordion',
    templateUrl: './campaign-dates-accordion.component.html',
    styleUrls: ['./campaign-dates-accordion.component.scss']
})
export class CampaignDatesAccordion implements OnInit {

    isVisible: boolean = false;
    currentUser = this.authService.userSettings;
    defaultValues: any;
    editableFields: boolean[] = [];
    minStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    maxStartDate: Date;
    isStartDateChanged: boolean;
    isEndDateChanged: boolean;
    startDate: Date = new Date;
    endDate: Date = new Date;
    //baseCampaign: IBaseCampaignDto;
    disableEndDate: boolean = false;
    validationMessage:string = '';
    isOnlyFlight: boolean = true;
    firstFlightEndDate: Date;
    lastFlightStartDate: Date;
    minCampaignLength: number;
    maxCampaignLength: number;
    minLaunchDate: Date;
    maxLaunchDate: Date;
    launchDate: Date;
    fiscalYearStartDate: Date;
    fiscalYearEndDate: Date;

    enumAccountType = eAccountType;
    enumInventoryTypeOAS = eInventoryTypeOAS;

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    @Output('onStartDateChange') onStartDateChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output('onEndDateChange') onEndDateChangeEmitter: EventEmitter<any> = new EventEmitter<any>();

    @Input() baseCampaign: IBaseCampaignDto;
    @Input() disabled: boolean = false;

    constructor(
        public campaignController: CampaignController,
        private authService: AuthService,
    ) { }

    private ngUnsubscribe = new Subject();
    

    ngOnInit() {
        if(this.baseCampaign.campaignType != eAccountType.Content){
            this.minCampaignLength=this.baseCampaign.campaignInventoryItems[0].inventoryItem != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItem.minimumCampaignLength :
                (this.baseCampaign.campaignInventoryItems[0].inventoryItemNew != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItemNew.minimumCampaignLength : 1);
            this.maxCampaignLength=this.baseCampaign.campaignInventoryItems[0].inventoryItem != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItem.maximumCampaignLength :
                (this.baseCampaign.campaignInventoryItems[0].inventoryItemNew != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItemNew.maximumCampaignLength : 1);
            
            this.initPage(
                    this.baseCampaign.startDate != null ? (this.baseCampaign.startDate.toString()) : null,
                    this.baseCampaign.endDate != null ? (this.baseCampaign.endDate.toString()) : null,
        
                    this.baseCampaign.campaignInventoryItems[0].inventoryItem != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItem.startDate : this.baseCampaign.seasonMapping.startDate,
                    this.baseCampaign.campaignInventoryItems[0].inventoryItem != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItem.endDate : this.baseCampaign.seasonMapping.endDate,
                    this.baseCampaign
                );
        }
        else{
            this.initPageContent();
        }
    }


    initPage(startDate: any, endDate: any, minStartDate: Date, maxEndDate: Date, baseCampaign: IBaseCampaignDto) {
        this.defaultValues = { ...arguments };
        this.startDate = !!startDate? new Date(startDate) : null;
        this.endDate = !!endDate?  new Date(endDate): null;
        this.baseCampaign = baseCampaign;
        this.isOnlyFlight=true;
        this.baseCampaign.creatives.sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        if(this.baseCampaign.creatives.some(x => x.entityStatus != eEntityStatus.Deleted && x.creativeGroup > 1 )){
            this.isOnlyFlight=false;
        }

        if (baseCampaign.campaignType == eAccountType.OAS && baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice){
            this.campaignController.GetAllSeasonMapping().subscribe(data => {
                var seasons = data.filter(x => x.seasonId == baseCampaign.seasonMapping.seasonId);
                seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());

                this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
                this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;

                minStartDate = seasons[0].startDate;
                maxEndDate = seasons[3].endDate;

                if (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                {
                    let minOasStartDate = new Date();
                    if(baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium)
                        minOasStartDate = this.addBusinessDays(minOasStartDate, 5);
                        //minOasStartDate.setDate(minOasStartDate.getDate() + 5);

                    else
                        //minOasStartDate.setDate(minOasStartDate.getDate() + 3);
                        minOasStartDate = this.addBusinessDays(minOasStartDate, 3);
                    minOasStartDate.setHours(0, 0, 0, 0);
                    if ( this.startDate < minOasStartDate && this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending 
                        && (this.baseCampaign.statusId != eCampaignStatus.Campaign_Live && this.baseCampaign.statusId != eCampaignStatus.Campaign_Paused && this.baseCampaign.statusId != eCampaignStatus.Build_Success)) {
                        this.startDate = null;
                        this.onStartDateChangeEmitter.emit({isStartDateChanged: true, startDate: this.startDate , isEndDateChanged: true, endDate: this.endDate });
                    }
                    if (new Date(minStartDate) < minOasStartDate ) {
                        minStartDate = minOasStartDate;
                    }

                    if(!this.isOnlyFlight){
                        this.firstFlightEndDate = new Date(this.baseCampaign.creatives[0].flightEndDate);
                        this.lastFlightStartDate = new Date(this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].flightStartDate);
                        
                        this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1); 
                        this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1); 
                        // if(baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice){
                        //     this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1); 
                        //     this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1); 
                        // }
                        // else{
                        //     this.maxStartDate = new Date(maxEndDate);
                        //     this.maxStartDate.setDate(this.maxStartDate.getDate() - this.minCampaignLength);
                        //     this.minEndDate = new Date(minStartDate);
                        //     this.minEndDate.setDate(this.minEndDate.getDate() + this.minCampaignLength);
                        //     if(Functions.isDateBefore(this.firstFlightEndDate, this.maxStartDate)){
                        //         this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1);
                        //     }
                        //     if(Functions.isDateBefore(this.minEndDate,this.lastFlightStartDate)){
                        //         this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1);
                        //     }
                        // }
                    }
                    else{
                        if(baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice)
                        {
                            this.maxStartDate = new Date(maxEndDate);
                            this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
                            this.minEndDate = new Date(this.minStartDate);
                            this.minEndDate.setDate(this.minEndDate.getDate() + 1);
                        }
                        else{
                            this.maxStartDate = new Date(maxEndDate);
                            this.maxStartDate.setDate(this.maxStartDate.getDate() - this.minCampaignLength);
                            this.minEndDate = new Date(minStartDate);
                            this.minEndDate.setDate(this.minEndDate.getDate() + this.minCampaignLength);
                        }
                    }

                    this.minStartDate = new Date(minStartDate);
                    this.maxEndDate = new Date(maxEndDate);

                    if(baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.pageTakeover || baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.premiumPageTakeover)
                        this.disableEndDate = true;
                }
                else{
                    if(!this.isOnlyFlight){
                        this.firstFlightEndDate = new Date(this.baseCampaign.creatives[0].flightEndDate);
                        this.lastFlightStartDate = new Date(this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].flightStartDate);

                        this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1); 
                        this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1); 
                        // if(baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice){
                        //     this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1); 
                        //     this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1); 
                        // }
                        // else{
                        //     this.maxStartDate = new Date(maxEndDate);
                        //     this.maxStartDate.setDate(this.maxStartDate.getDate() - this.minCampaignLength);
                        //     this.minEndDate = new Date(minStartDate);
                        //     this.minEndDate.setDate(this.minEndDate.getDate() + this.minCampaignLength);

                        //     if(Functions.isDateBefore(this.firstFlightEndDate, this.maxStartDate)){
                        //         this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1);
                        //     }
                        //     if(Functions.isDateBefore(this.minEndDate,this.lastFlightStartDate)){
                        //         this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1);
                        //     }
                        // }
                    }
                    else{
                        this.maxStartDate = new Date(maxEndDate);
                        this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
                        this.minEndDate = new Date(minStartDate);
                        this.minEndDate.setDate(this.minEndDate.getDate() + 1);
                        // if(baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice){
                        //     this.maxStartDate = new Date(maxEndDate);
                        //     this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
                        //     this.minEndDate = new Date(minStartDate);
                        //     this.minEndDate.setDate(this.minEndDate.getDate() + 1);
                        // }
                        // else{
                        //     this.maxStartDate = new Date(maxEndDate);
                        //     this.maxStartDate.setDate(this.maxStartDate.getDate() - this.minCampaignLength);
                        //     this.minEndDate = new Date(minStartDate);
                        //     this.minEndDate.setDate(this.minEndDate.getDate() + this.minCampaignLength);
                        // }
                    }
                    this.minStartDate = new Date(minStartDate);
                    this.maxEndDate = new Date(maxEndDate);
                    
                    if(baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.pageTakeover || baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.premiumPageTakeover)
                        this.disableEndDate = true;
                }
                
            });
        }
        else if (baseCampaign.campaignType == eAccountType.Social){
            this.campaignController.GetAllSeasonMapping().subscribe(data => {
                var seasons = data.filter(x => x.seasonId == baseCampaign.seasonMapping.seasonId);
                seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());

                this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
                this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;

                minStartDate = seasons[0].startDate;
                maxEndDate = seasons[3].endDate;

                if (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                {
                    let nextDateFromToday = new Date();
                    nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);
                    let datePlus14 = new Date();
                    datePlus14.setDate(datePlus14.getDate() + 14);
                    let datePlus7 = new Date();
                    datePlus7.setDate(datePlus7.getDate() + 7);
                    var minDateFromToday = nextDateFromToday;
                    if(this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Content || this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Snap_AR)
                        minDateFromToday = datePlus7;
                    else if(this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation 
                        || this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation_Premium
                        || this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Digital_Game)
                        minDateFromToday = datePlus14;

                    minDateFromToday.setHours(0, 0, 0, 0);
                    if ( this.startDate < minDateFromToday && this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending 
                        && (this.baseCampaign.statusId != eCampaignStatus.Campaign_Live && this.baseCampaign.statusId != eCampaignStatus.Campaign_Paused && this.baseCampaign.statusId != eCampaignStatus.Build_Success)) {
                        this.startDate = null;
                        this.onStartDateChangeEmitter.emit({isStartDateChanged: true, startDate: this.startDate , isEndDateChanged: true, endDate: this.endDate });
                    }
                    if (new Date(minStartDate) < minDateFromToday) {
                        minStartDate = minDateFromToday;
                    }

                    this.minStartDate = new Date(minStartDate);
                    this.minEndDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);

                    this.minEndDate.setDate(this.minStartDate.getDate() + this.minCampaignLength);
                    this.maxEndDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxEndDate.getDate() - this.minCampaignLength);
                }
                else{
                    this.minStartDate = new Date(minStartDate);
                    this.minEndDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);
                    this.minEndDate.setDate(this.minStartDate.getDate() + 1);
                    this.maxEndDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxEndDate.getDate() - 1);

                }
            });
        }
        else if (baseCampaign.campaignType == eAccountType.FAN365)
        {
            if(this.currentUser.userRole != eUserRole.Admin){
                this.campaignController.GetAllSeasonMapping().subscribe(data => {
                    var seasons = data.filter(x => x.seasonId == baseCampaign.seasonMapping.seasonId);
                    seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());
    
                    this.fiscalYearStartDate = new Date(seasons[0].startDate);
                    this.fiscalYearEndDate = new Date(seasons[3].endDate);
    
                    this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
                    this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;
    
                    minStartDate = this.fiscalYearStartDate;
                    maxEndDate = this.fiscalYearEndDate;
                    
                    if (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                    {
                        // minStartDate = new Date(this.baseCampaign.seasonMapping.startDate);
                        // maxEndDate = new Date(this.baseCampaign.seasonMapping.endDate);
                        // if(this.baseCampaign.seasonMapping.category != "Spring")
                        //     maxEndDate.setDate(maxEndDate.getDate() + 30);
                        let twoDaysFromToday = new Date();
                        twoDaysFromToday.setDate(twoDaysFromToday.getDate() + 2);
                        twoDaysFromToday.setHours(0, 0, 0, 0);
                        if ( this.startDate < twoDaysFromToday && (this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Uploaded || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined) 
                            && (this.baseCampaign.statusId != eCampaignStatus.Campaign_Live && this.baseCampaign.statusId != eCampaignStatus.Campaign_Paused && this.baseCampaign.statusId != eCampaignStatus.Build_Success)) {
                            this.startDate = null;
                            this.endDate = null;
                            this.onStartDateChangeEmitter.emit({isStartDateChanged: true, startDate: this.startDate , isEndDateChanged: true, endDate: this.endDate });
                        }
                        if (new Date(minStartDate) < twoDaysFromToday ) {
                            minStartDate = twoDaysFromToday;
                        }
    
                        this.minStartDate = new Date(minStartDate);
                        this.maxEndDate = new Date(maxEndDate);
                        
                        let campaignLengthValidator = new Date(this.maxEndDate);
                        campaignLengthValidator.setDate(campaignLengthValidator.getDate() - this.minCampaignLength);
                       
                        if(!this.isOnlyFlight){
                            
                            if(campaignLengthValidator < this.minStartDate){
                                this.minCampaignLength -= this.minStartDate.getDate() - (this.maxEndDate.getDate() - this.minCampaignLength);
                                
                                this.maxStartDate = new Date(maxEndDate);
                                this.maxStartDate.setDate(this.maxStartDate.getDate() - this.minCampaignLength);
                                this.minEndDate = new Date(this.minStartDate);
                                this.minEndDate.setDate(this.minEndDate.getDate() + this.minCampaignLength);}
                            else{
                                this.firstFlightEndDate = new Date(this.baseCampaign.creatives[0].flightEndDate);
                                this.lastFlightStartDate = new Date(this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].flightStartDate);
    
                                let firstFlightStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1); 
                                let lastflightEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1); 
                                
                                let maxStartDateHelper = new Date(maxEndDate);
                                maxStartDateHelper.setDate(maxStartDateHelper.getDate() - this.minCampaignLength);
                                let minEndDateHelper = new Date(this.minStartDate);
                                minEndDateHelper.setDate(minEndDateHelper.getDate() + this.minCampaignLength);
    
                                this.maxStartDate = maxStartDateHelper < firstFlightStartDate ? maxStartDateHelper : firstFlightStartDate;
                                this.minEndDate = minEndDateHelper > lastflightEndDate ? minEndDateHelper : lastflightEndDate;
                            }
                        }
                        else{
                            this.maxStartDate = new Date(maxEndDate);
                            if(campaignLengthValidator < this.minStartDate){
                                this.minCampaignLength -= this.minStartDate.getDate() - (this.maxEndDate.getDate() - this.minCampaignLength);
                            }
                            this.maxStartDate.setDate(this.maxStartDate.getDate() - this.minCampaignLength);
                            this.minEndDate = new Date(this.minStartDate);
                            this.minEndDate.setDate(this.minEndDate.getDate() + this.minCampaignLength);
                        }
                    }
                    else 
                    {
                        // minStartDate = seasons[0].startDate;
                        // maxEndDate = seasons[3].endDate;
    
                        if(!this.isOnlyFlight){
                            this.firstFlightEndDate = new Date(this.baseCampaign.creatives[0].flightEndDate);
                            this.lastFlightStartDate = new Date(this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].flightStartDate);
    
                            this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1); 
                            this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1); 
                        }
                        else{
                            this.maxStartDate = new Date(maxEndDate);
                            this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
                            this.minEndDate = new Date(minStartDate);
                            this.minEndDate.setDate(this.minEndDate.getDate() + 1);
                        }
                        this.minStartDate = new Date(minStartDate);
                        this.maxEndDate = new Date(maxEndDate);
                    }
                    
                    });
            }
        }
        else
        {
            this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
            this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;

            minStartDate = new Date(this.baseCampaign.seasonMapping.startDate);
            maxEndDate = new Date(this.baseCampaign.seasonMapping.endDate);

            let nextDateFromToday = new Date();
                    nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);
            if (new Date(minStartDate) < nextDateFromToday) {
                minStartDate = nextDateFromToday;
            }
    
            this.minStartDate = new Date(minStartDate);
            this.maxStartDate = new Date(maxEndDate);
            this.maxStartDate.setDate(maxEndDate.getDate() - 30);
            this.minEndDate = new Date();
            this.minEndDate.setDate(this.minStartDate.getDate() + 30);
            this.maxEndDate = new Date(maxEndDate);
        }

        this.isVisible = true;

    }

    initPageContent(){
        this.campaignController.GetAllSeasonMapping().subscribe(data => {
            var seasons = data.filter(x => x.seasonId == this.baseCampaign.seasonMapping.seasonId);
            seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());

            this.launchDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
            this.minLaunchDate = new Date(seasons[0].startDate);
            this.maxLaunchDate = new Date(seasons[3].endDate);

            if (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin){
                let nextDateFromToday = new Date();
                nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);

                if(this.launchDate < nextDateFromToday){
                    this.launchDate = null;
                    this.baseCampaign.startDate = null;
                    this.baseCampaign.endDate = null;
                }

                if (this.minLaunchDate < nextDateFromToday) {
                    this.minLaunchDate = nextDateFromToday;
                    this.minLaunchDate.setDate(1);
                }
            }
        });
    }

    resetDefault() {
        this.startDate = this.defaultValues[0];
        this.endDate = this.defaultValues[1];
    }

    save() {
        const dialogResult = {
            startDate: this.startDate,
            endDate: this.endDate,
            isStartDateChanged: this.isStartDateChanged,
            isEndDateChanged: this.isEndDateChanged

        };

        this.onSave.emit(dialogResult);
        this.isVisible = false;
    }

    cancel() {
        this.isVisible = false;
    }


    onStartDateChange(startDate: Date) {
        if (!!startDate) {
            if(!(this.currentUser.userRole == eUserRole.Admin && this.baseCampaign.campaignType == eAccountType.FAN365)){
                if(this.isOnlyFlight){
                    this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                    if(this.baseCampaign.campaignType == eAccountType.FAN365 && (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin)){
                        this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + this.minCampaignLength);
                        if(startDate.getTime()+this.maxCampaignLength*24*60*60*1000 < this.fiscalYearEndDate.getTime())
                            this.maxEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + this.maxCampaignLength);                      
                        else{
                            this.maxEndDate = this.fiscalYearEndDate; 
                            // if(this.baseCampaign.seasonMapping.category!="Spring")
                            //     this.maxEndDate.setDate(this.maxEndDate.getDate() +30);
                        }
                    }
                    else{
                        this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
                        // if(this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice)
                        //     this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
                        // else
                        //     this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + this.minCampaignLength);
                    }
                }
                // else if(this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Share_Of_Voice){
                //     this.lastFlightStartDate = new Date(this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].flightStartDate);
                //     if(Functions.isDateBefore(this.minEndDate,this.lastFlightStartDate)){
                //         this.minEndDate = new Date(this.lastFlightStartDate.getFullYear(), this.lastFlightStartDate.getMonth(), this.lastFlightStartDate.getDate() + 1);
                //     }
                //     else{
                //         this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + this.minCampaignLength);
                //     }
                        
                // }
    
                if (this.endDate > this.maxEndDate || this.endDate < this.minEndDate) {
                    this.endDate = null;
                    this.isEndDateChanged = true;
                }
                this.isStartDateChanged = true;
                this.validationMessage= '';
    
                if(this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.pageTakeover)
                {
                    // from ticket
                    //Days live for every campaign should be a single day not a range of days. For example, if a campaign is to run on 9/4 the range should be 9/4 to 9/4.
                    this.endDate = new Date(startDate);
                    if(this.endDate <= this.maxEndDate){
                        this.isEndDateChanged = true;
                        this.validationMessage= '';
                    }
                    else{
                        this.validationMessage= 'Campaign end date is higher than season end date.';
                    }
                }
    
                else if(this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.premiumPageTakeover)
                {
                    // from ticket
                    //Days live for every campaign should be a 3 days for Premium Page Takeover campaigns
                    this.endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 2);
    
                    if(this.endDate <= this.maxEndDate){
                        this.isEndDateChanged = true;
                        this.validationMessage= '';
                    }
                    else{
                        this.validationMessage= 'Campaign end date is higher than season end date.';
                    }
                }
            }
            else
                this.isStartDateChanged = true;
        }

        if (startDate == null || startDate > this.endDate) {
            this.validationMessage= 'Campaign dates are wrong.';
            this.endDate = null;
        }   

        this.onStartDateChangeEmitter.emit({isStartDateChanged: this.isStartDateChanged, startDate: startDate , isEndDateChanged: this.isEndDateChanged, endDate: this.endDate });
    }

    onEndDateChange(endDate: Date) {

        if (!!endDate) {
            if(!(this.currentUser.userRole == eUserRole.Admin && this.baseCampaign.campaignType == eAccountType.FAN365)){
                if(this.isOnlyFlight){
                    this.maxStartDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                    if(this.baseCampaign.campaignType == eAccountType.FAN365 && (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin)){
                        this.maxStartDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - this.minCampaignLength);
                        if(endDate.getTime()-this.maxCampaignLength*24*60*60*1000 > this.fiscalYearStartDate.getTime())
                            this.minStartDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - this.maxCampaignLength);
                        else if(new Date() >= this.fiscalYearStartDate){
                            this.minStartDate = new Date();
                            this.minStartDate.setDate(this.minStartDate.getDate() + 2);
                            this.minStartDate.setHours(0, 0, 0, 0);
                        }
                        else
                            this.minStartDate = new Date(this.baseCampaign.seasonMapping.startDate);
                    }
                    else{
                        this.maxStartDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 1);
                        // if(this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice)
                        //     this.maxStartDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 1);
                        // else
                        //     this.maxStartDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - this.minCampaignLength);
                    }
                }
                // else if(this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Share_Of_Voice){
                //     this.firstFlightEndDate = new Date(this.baseCampaign.creatives[0].flightEndDate);
                //     if(Functions.isDateBefore(this.maxStartDate, this.firstFlightEndDate)){
                //         this.maxStartDate = new Date(this.firstFlightEndDate.getFullYear(), this.firstFlightEndDate.getMonth(), this.firstFlightEndDate.getDate() - 1);
                //     }
                //     else{
                //         this.maxStartDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - this.minCampaignLength);
                //     }
                // }
                    
                this.isEndDateChanged = true;
                this.validationMessage = '';
    
                if (this.startDate > this.maxStartDate) {
                    this.startDate = this.maxStartDate;
                    this.isStartDateChanged = true;
                }
                else if(this.startDate<this.minStartDate){
                    this.startDate = null;
                    this.isStartDateChanged = true;
                }
            }
            else
                this.isEndDateChanged = true;
        }

        if (this.startDate > this.maxStartDate || this.startDate > this.endDate) {
            this.startDate = null;
            this.isStartDateChanged = true;
            this.validationMessage= 'Campaign dates are wrong.';
        }
        else{
            this.validationMessage = '';
        }

        this.onEndDateChangeEmitter.emit({isStartDateChanged: this.isStartDateChanged, startDate: this.startDate ,isEndDateChanged: this.isEndDateChanged, endDate: endDate });
    }

    onLaunchDateChange(launchDate: Date){
        this.baseCampaign.startDate = launchDate;
        this.baseCampaign.endDate = new Date(launchDate.getFullYear(), launchDate.getMonth() + 1, 0);
        Functions.dateTimeZoneConversion(this.baseCampaign.startDate);
        Functions.dateTimeZoneConversion(this.baseCampaign.endDate);
    }

    addBusinessDays(d, n) {
        d = new Date(d.getTime());
        var day = d.getDay();
        d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
        return new Date(d);
    }
}