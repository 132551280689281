<div [ngClass]="!!status? 'card card-body m-3': ''">

    <div class="row mb-3">
        <div class="col-12">
            <div class="row align-items-center" *ngIf="!!status">
                <div class="col-6 d-flex align-items-center label-text">{{status.label }} </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-5 offset-7">
                            <div class="p-inputgroup w-100">
                                <input class="w-100" type="text" pInputText [(ngModel)]="searchParameter" placeholder="Search" (keyup.enter)="composeFilter()" style="border-right: 0;" />
                                <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="composeFilter()"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <div [@componentFadeIn]>
                
                <rc-grid *ngIf="this.currentUser.permissions.isLearfieldClient && !selectedSponsor" (onBeforeDataLoad)="setParameters($event)" [options]="options" [paginator]="true" >
                    
                    <ng-template pTemplate="header" let-item>
                        <tr>
                            <th *ngFor="let column of options?.columns" [pSortableColumn]="column.value" [ngStyle]="{'width':column.width}" class="p-3">
                                {{column.label}}
                                <p-sortIcon [field]="column.value" ></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        
                        <tr [ngClass]="checkValue(rowData) ? 'selectedRow' : ''">
                            <td>
                                <a class="d-block" href="{{navigateToSponsor(rowData)}}" truncateText (click)="setSponsor(rowData.sponsor, rowData.dealId, $event)">
                                    <span>
                                        {{ rowData.sponsor }}
                                    </span>
                                </a>

                            </td>
                            <td>
                                <a class="d-block gridLinkColor" href="{{navigateToSponsor(rowData)}}" truncateText (click)="setSponsor(rowData.sponsor, rowData.dealId, $event)">
                                    <span>
                                        {{ rowData.dealId }}
                                    </span>
                                </a>

                            </td>
                            <td>{{ rowData.seasonYear }}</td>
                            <td>{{ rowData.lastModified |  dateAgo }} ({{ (rowData.lastModified | dateToUserTimeZone) | date : constants.DEFAULT_FORMAT_DATE_US }})</td>
                            <td>{{ rowData.seasonImpression | number : '1.0-2' }}</td>
                            <td>{{ rowData.revenue | currencyInput }}</td>
                            <td>{{ rowData.pendingCampaigns }}</td>
                            </tr>
                        
                    </ng-template>
                </rc-grid>

                
                <rc-grid *ngIf="!this.currentUser.permissions.isLearfieldClient || !!selectedSponsor" (onAfterDataLoad)="onAfterDataLoadList.emit($event); showGrid= !!$event.totalCount; checkData($event)" [options]="options" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <!-- <th [ngStyle]="{'width':'3%'}" *ngIf='showCheckboxes && gridId == 1 && !this.currentUser.permissions.isLearfieldClient'> 
                                <p-checkbox *ngIf="!this.currentUser.permissions.isLearfieldClient && showCheckboxes" (onChange)="checkAllRows($event)" [ngModel]='selectedCampaigns.length > 0' binary="true" ></p-checkbox>
                            </th> -->
                            <th *ngFor="let column of options?.columns" [pSortableColumn]="column.value" [ngStyle]="{'width':column.width}" [ngClass]=getTextAlignClass(column.align)>
                                {{ column.label }}
                                <p-sortIcon *ngIf="column.label!='Report' && column.label!='Action' && column.label!='Live' && column.label!=''" [field]="column.value"></p-sortIcon>
                            </th>
                        </tr>
                        <component-loader-spinner [isLoading]="showGrid"></component-loader-spinner>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>

                        <tr *ngIf='gridId == 1 || gridId == 4 || gridId == 5 || gridId == 6|| gridId == 7' [ngClass]="checkValue(rowData) ? 'selectedRow' : ''" >
                            <!-- <td *ngIf="!this.currentUser.permissions.isLearfieldClient && gridId == 1 && showCheckboxes">
                                <p-checkbox (onChange)="checkRow(rowData, $event)" [ngModel]='checkValue(rowData)' binary="true" ></p-checkbox>
                            </td> -->
                            <td>
                                <fa-icon *ngIf="!!rowData.pssBroadcastContractLine?.notes" [pTooltip]="rowData.pssBroadcastContractLine?.notes" [icon]="faClipboard" class="faClipboard"></fa-icon>
                            </td>
                            <td>
                                <a class="d-block" href="{{rowData.entityStatus != 3 ? updateCampaignService(rowData) : null}}" truncateText [pTooltip]="rowData.name" tooltipPosition="top" (onEllipsisAdded)="handleEllipseAdded($event, rowData.id)" [tooltipDisabled]="!nameEllipsesState[rowData.id]">
                                    <span class="name" (click)="rowData.entityStatus == 3 ? openTransferedCampaignModal($event, rowData.id) : null">
                                        {{ rowData.name }}
                                    </span>
                                </a>

                            </td>
                            <td *ngIf="!currentAccount">{{ rowData.account.name }}</td>
                            <td *ngIf="currentUser.permissions.isClient && gridId == 6">{{ rowData.campaignType == enumAccountType.FAN365 ? "Fan365" : (rowData.campaignType == enumAccountType.OAS ? "OAS" : (rowData.campaignType == enumAccountType.Social ? "Social" : (rowData.campaignType == enumAccountType.Content ? "Content" : ""))) }}</td>
                            <td>{{ rowData.dealName }}</td>
                            <td>{{ rowData.modifiedOn |  dateAgo }} ({{ (rowData.modifiedOn | dateToUserTimeZone) | date : constants.DEFAULT_FORMAT_DATE_US }})</td>
                            <td *ngIf="gridId != 6 && !(currentUser.permissions.isClient && currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin && currentUser.userRole != enumUserRole.LIMGCSocialAdOps)">{{ rowData.createdOn |  dateAgo }} ({{ (rowData.createdOn | dateToUserTimeZone) | date : constants.DEFAULT_FORMAT_DATE_US }})</td>
                            <td class="text-right">{{ rowData.impressions !=null ? (rowData.impressions | number) :0 }} {{ !!rowData.creatives[0] ? ((rowData.creatives[0].name == "Page_Takeover" || rowData.creatives[0].name == "Page_Takeover_Premium") ? (rowData.impressions > 1 ? 'days' : 'day' ) : (rowData.creatives[0].name == "Share_Of_Voice" ? '%' : '')):''}}</td>
                            <!--impressions -->
                            <!-- <td class="text-right" [pTooltip]="getInventoryItemTooltip(rowData)" tooltipPosition="top">{{ rowData.inventoryItemCount !=null ? (rowData.inventoryItemCount | number) : 0 }}</td> -->
                            <td class="text-right" [pTooltip]="getInventoryItemTooltip(rowData)" tooltipPosition="top">{{ rowData.quantity !=null ? (rowData.quantity | number) : (rowData.inventoryItemCount !=null ? (rowData.inventoryItemCount | number) : 0) }}</td> 
                            
                            <td *ngIf="!currentAccount">{{ rowData.campaignType == enumAccountType.Social ? "Social" : (rowData.campaignType == enumAccountType.OAS ? "Display" : (rowData.campaignType == enumAccountType.Content ? "Content" : getCampaignChannels(rowData)))}}</td>

                            <td *ngIf="!currentAccount">{{ (!!rowData.regionId && rowData.regionId !=enumCampaignRegion.Not_set) ? (rowData.regionId | enumString: enumCampaignRegion) : '-' }}</td>

                            <!-- only statusId -->
                            <td *ngIf="currentUser.permissions.isClient || ((rowData.campaignStatusId == enumCampaignStatus.Campaign_Ended || rowData.campaignStatusId == enumCampaignStatus.Campaign_Expired) && rowData.statusId != enumCampaignStatus.Live_365_Update) || 
                                        (!currentUser.permissions.isClient && rowData.statusId == null)">
                                {{ rowData.campaignType != enumAccountType.Content ? (rowData.campaignStatusId |  enumString: enumCampaignStatus) : (rowData.campaignStatusId == enumCampaignStatus.Assets_Pending ? (rowData.campaignStatusId |  enumString: enumCampaignStatus).replace("Assets Pending","Form Pending") : (rowData.campaignStatusId == enumCampaignStatus.Assets_Uploaded ? (rowData.campaignStatusId |  enumString: enumCampaignStatus).replace("Assets Uploaded","Form Submitted") : (rowData.campaignStatusId |  enumString: enumCampaignStatus)))}}</td>
                            <!-- statusId(campaignStatusId) -->
                            <td *ngIf="!currentUser.permissions.isClient && !!rowData.statusId && rowData.campaignStatusId != enumCampaignStatus.Campaign_Live && rowData.campaignStatusId != enumCampaignStatus.Campaign_Paused && rowData.campaignStatusId != enumCampaignStatus.Campaign_Ended && rowData.campaignStatusId != enumCampaignStatus.Campaign_Expired">
                                <span class="d-block" truncateText [pTooltip]="(rowData.statusId | enumString: enumCampaignStatus) + ' (' + ( rowData.campaignStatusId |  enumString: enumCampaignStatus ) + ')' " tooltipPosition="top" (onEllipsisAdded)="handleEllipseAdded($event, rowData.id)" [tooltipDisabled]="!nameEllipsesState[rowData.id]">
                                    {{ rowData.statusId |  enumString: enumCampaignStatus }} ({{ rowData.campaignStatusId |  enumString: enumCampaignStatus }})</span></td>
                            <!-- campaignStatusId(statusId) -->
                            <td *ngIf="!currentUser.permissions.isClient && !!rowData.statusId && (rowData.campaignStatusId == enumCampaignStatus.Campaign_Live || rowData.campaignStatusId == enumCampaignStatus.Campaign_Paused || ((rowData.campaignStatusId == enumCampaignStatus.Campaign_Ended || rowData.campaignStatusId == enumCampaignStatus.Campaign_Expired) && rowData.statusId == enumCampaignStatus.Live_365_Update))">
                                <span class="d-block" truncateText [pTooltip]="(rowData.campaignStatusId | enumString: enumCampaignStatus) + ' (' + ( rowData.statusId |  enumString: enumCampaignStatus ) + ')' " tooltipPosition="top" (onEllipsisAdded)="handleEllipseAdded($event, rowData.id)" [tooltipDisabled]="!nameEllipsesState[rowData.id]">
                                    {{ rowData.campaignStatusId |  enumString: enumCampaignStatus }} ({{ rowData.statusId |  enumString: enumCampaignStatus }})</span></td>
      
                            <td class="text-center"><a class="fa fa-file-pdf fa-lg" href="{{navigateToReport(rowData)}}"></a></td>
                            
                            <!-- <td class="text-right" *ngIf="currentUser.permissions.isClient">
                                <i pTooltip="Unlock features" (click)="editCampaign(rowData)" class="fa fa-unlock fa-lg"> </i>
                            </td> -->
                            <!-- <td class="text-right" *ngIf="!currentUser.permissions.isClient">
                                <i *ngIf="rowData.campaignStatusId == enumCampaignStatus.Campaign_Draft" (click)="openEditDialog(rowData)" class="fa fa-edit fa-lg pr-3"> </i>
                                <i *ngIf="rowData.campaignStatusId == enumCampaignStatus.Campaign_Draft" (click)="remove(rowData)" class="fa fa-trash fa-lg"></i>
                                
                                <i pTooltip="Send to API Build (DV360)" tooltipPosition="left" 
                                
                                *ngIf="!rowData.account.automaticApiBuild && (rowData.campaignStatusId == enumCampaignStatus.Assets_Approved || rowData.statusId == enumCampaignStatus.Assets_Approved) 
                                && rowData.statusId != enumCampaignStatus.Sent_to_API_Build && rowData.statusId != enumCampaignStatus.Build_Success 
                                && rowData.failedCount < 5 && currentUser.userRole == enumUserRole.Media_Activation"
                                
                                
                                (click)="sendToApiBuild(rowData, 'DV360')"><img style="height: 1.5em; margin-bottom: 1px;" src="../../../../../assets/DV360.svg " /></i>
                                <br *ngIf="!rowData.account.automaticApiBuild && (rowData.campaignStatusId == enumCampaignStatus.Assets_Approved || rowData.statusId == enumCampaignStatus.Assets_Approved) && rowData.statusId != enumCampaignStatus.Sent_to_API_Build && rowData.statusId != enumCampaignStatus.Build_Success && rowData.failedCount < 5 && currentUser.userRole == enumUserRole.Media_Activation">
                                
                                <i pTooltip="Send to API Build (TTD)" tooltipPosition="left" *ngIf="!rowData.account.automaticApiBuild 
                                && (rowData.campaignStatusId == enumCampaignStatus.Assets_Approved || rowData.statusId == enumCampaignStatus.Assets_Approved) 
                                && rowData.statusId != enumCampaignStatus.Sent_to_API_Build && rowData.statusId != enumCampaignStatus.Build_Success && rowData.failedCount < 5
                                && currentUser.userRole == enumUserRole.Media_Activation" (click)="sendToApiBuild(rowData, 'TTD')"><img style="height: 1.5em; margin-bottom: 1px;" src="../../../../../assets/TTD.svg " /></i>
                                
                                <br *ngIf="!rowData.account.automaticApiBuild && (rowData.campaignStatusId == enumCampaignStatus.Assets_Approved || rowData.statusId == enumCampaignStatus.Assets_Approved) && 
                                rowData.statusId != enumCampaignStatus.Sent_to_API_Build && rowData.statusId != enumCampaignStatus.Build_Success && rowData.failedCount < 5 
                                && currentUser.userRole == enumUserRole.Media_Activation"> -->

                                <!-- <i pTooltip="Go Live" tooltipPosition="top" *ngIf="(rowData.statusId == enumCampaignStatus.Sent_to_API_Build || rowData.statusId == enumCampaignStatus.Build_Success) && currentUser.userRole == enumUserRole.Media_Activation" (click)="changeStatus(rowData)" class="fa fa-paper-plane fa-lg"></i> -->
                                <!-- <p-inputSwitch [pTooltip]="rowData.campaignStatusId == enumCampaignStatus.Campaign_Live ? 'Pause Campaign': 'Go Live'" tooltipPosition="left" *ngIf="(rowData.statusId == enumCampaignStatus.Sent_to_API_Build || rowData.statusId == enumCampaignStatus.Build_Success || rowData.statusId == enumCampaignStatus.Build_Failed || rowData.campaignStatusId == enumCampaignStatus.Campaign_Live || rowData.campaignStatusId == enumCampaignStatus.Campaign_Paused || rowData.campaignStatusId == enumCampaignStatus.Live_365_Update || rowData.StatusId == enumCampaignStatus.Live_365_Update) && currentUser.userRole == enumUserRole.Media_Activation" (onChange)="changeStatus(rowData)" [ngModel]="(rowData.campaignStatusId == enumCampaignStatus.Campaign_Live)"></p-inputSwitch> -->
                            <!-- </td> -->
                                
                            <td class="text-right" *ngIf="!currentUser.permissions.isClient">
                                <div class="center">
                                    <p-inputSwitch [pTooltip]="rowData.campaignStatusId == enumCampaignStatus.Campaign_Live ? 'Pause Campaign': 'Go Live'" tooltipPosition="left" 
                                    *ngIf="(rowData.statusId == enumCampaignStatus.Sent_to_API_Build || rowData.statusId == enumCampaignStatus.Build_Success 
                                    || rowData.statusId == enumCampaignStatus.Build_Failed || rowData.campaignStatusId == enumCampaignStatus.Campaign_Live 
                                    || rowData.campaignStatusId == enumCampaignStatus.Campaign_Paused || rowData.campaignStatusId == enumCampaignStatus.Live_365_Update 
                                    || rowData.StatusId == enumCampaignStatus.Live_365_Update) && (currentUser.userRole == enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin)"
                                    (onChange)="changeStatus(rowData)" [ngModel]="(rowData.campaignStatusId == enumCampaignStatus.Campaign_Live)">
                                    </p-inputSwitch>
<!--                                     
                                    <br *ngIf="!rowData.account.automaticApiBuild 
                                            && (rowData.campaignStatusId == this.enumCampaignStatus.Assets_Approved || rowData.statusId == this.enumCampaignStatus.Assets_Approved) 
                                            && rowData.statusId != this.enumCampaignStatus.Sent_to_API_Build && rowData.statusId != this.enumCampaignStatus.Build_Success && rowData.failedCount < 5
                                            && (this.currentUser.userRole == this.enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin)">
                                <i pTooltip="Send to API Build (DV360)" tooltipPosition="left" 
                                    *ngIf="!rowData.account.automaticApiBuild && (rowData.campaignStatusId == this.enumCampaignStatus.Assets_Approved || rowData.statusId == this.enumCampaignStatus.Assets_Approved) 
                                        && rowData.statusId != this.enumCampaignStatus.Sent_to_API_Build && rowData.statusId != this.enumCampaignStatus.Build_Success 
                                        && rowData.failedCount < 5 && (this.currentUser.userRole == this.enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin)"
                                    (click)="sendToApiBuild(rowData, 'DV360')"><img style="height: 1.5em; margin-bottom: 1px;" src="../../../../../assets/DV360.svg " /></i>
                                    <br *ngIf="!rowData.account.automaticApiBuild 
                                            && (rowData.campaignStatusId == this.enumCampaignStatus.Assets_Approved || rowData.statusId == this.enumCampaignStatus.Assets_Approved) 
                                            && rowData.statusId != this.enumCampaignStatus.Sent_to_API_Build && rowData.statusId != this.enumCampaignStatus.Build_Success && rowData.failedCount < 5
                                            && (this.currentUser.userRole == this.enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin)">
                                <i pTooltip="Send to API Build (TTD)" tooltipPosition="left" 
                                    *ngIf="!rowData.account.automaticApiBuild 
                                        && (rowData.campaignStatusId == this.enumCampaignStatus.Assets_Approved || rowData.statusId == this.enumCampaignStatus.Assets_Approved) 
                                        && rowData.statusId != this.enumCampaignStatus.Sent_to_API_Build && rowData.statusId != this.enumCampaignStatus.Build_Success && rowData.failedCount < 5
                                        && (this.currentUser.userRole == this.enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin)"
                                    (click)="sendToApiBuild(rowData, 'TTD')"><img style="height: 1.5em; margin-bottom: 1px;" src="../../../../../assets/TTD.svg " /></i>
                                
                                    <br *ngIf="rowData.campaignStatusId == this.enumCampaignStatus.Campaign_Draft">
                                <i pTooltip="Edit" tooltipPosition="top" *ngIf="rowData.campaignStatusId == this.enumCampaignStatus.Campaign_Draft" (click)="this.openEditDialog(rowData)" class="fa fa-edit fa-lg" style="color:black"></i>
                                    
                                    <br *ngIf="rowData.campaignStatusId == this.enumCampaignStatus.Campaign_Draft">
                                <i pTooltip="Remove" tooltipPosition="top" *ngIf="rowData.campaignStatusId == this.enumCampaignStatus.Campaign_Draft" (click)="this.remove(rowData)" class="fa fa-trash fa-lg" style="color:black"></i>
                                
                                    <--<br *ngIf="">
                                <--<i pTooltip="Go Live" tooltipPosition="top" *ngIf="(rowData.statusId == this.enumCampaignStatus.Sent_to_API_Build || rowData.statusId == this.enumCampaignStatus.Build_Success) && this.currentUser.userRole == this.enumUserRole.Media_Activation" (click)="this.changeStatus(rowData)" class="fa fa-paper-plane"></i> 
                                
                                    <br *ngIf="(rowData.isOASCampaign || rowData.isLearfieldCampaign) && (rowData.campaignStatusId == this.enumCampaignStatus.Live_365_Update 
                                            || rowData.statusId == this.enumCampaignStatus.Live_365_Update) && rowData.entityStatus == enumEntityStatus.Active
                                            && (!this.currentUser.permissions.isClient || this.currentUser.userRole == this.enumUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == this.enumUserRole.LIMGCSocialAdOps)">
                                <i pTooltip="Transfer Data" tooltipPosition="top" *ngIf="(rowData.isOASCampaign || rowData.isLearfieldCampaign) && (rowData.campaignStatusId == this.enumCampaignStatus.Live_365_Update 
                                    || rowData.statusId == this.enumCampaignStatus.Live_365_Update) && rowData.entityStatus == enumEntityStatus.Active
                                    && (!this.currentUser.permissions.isClient || this.currentUser.userRole == this.enumUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == this.enumUserRole.LIMGCSocialAdOps)" (click)="this.transferData(rowData)" class="fa fa-retweet fa-lg" style="color:black"></i>
                                 -->
                                    <p-splitButton [model]="getMenuItemsForItem(rowData)[0]" *ngIf="getMenuItemsForItem(rowData)[1]" label=" ">
                                        <ng-template pTemplate="dropdownicon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16"> 
                                                <path fill-rule="evenodd" 
                                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/> 
                                            </svg>
                                        </ng-template>
                                    </p-splitButton>
                                </div>
                            </td>
                            
                        </tr>
                            <tr *ngIf="gridId == 2 || gridId == 3">
                                <td>
                                    <a class="name" href="{{updateCampaignService(rowData)}}" truncateText [pTooltip]="rowData.name" tooltipPosition="top" (onEllipsisAdded)="handleEllipseAdded($event, rowData.id)" [tooltipDisabled]="!nameEllipsesState[rowData.id]">
                                        {{ rowData.name }}
                                    </a>
                                </td>
                                <td *ngIf="!currentAccount">{{ rowData.account.name }}</td>
                                <td>{{rowData.modifiedOn | dateAgo }} ({{ (rowData.modifiedOn | dateToUserTimeZone) | date : constants.DEFAULT_FORMAT_DATE_US }})</td>
                                <td *ngIf="gridId==3" class="text-right">{{ rowData.totalAmount !=null ? (rowData.totalAmount | currencyInput) :0 }}</td>
    
                                <td *ngIf="currentUser.permissions.isClient || rowData.campaignStatusId == enumCampaignStatus.Campaign_Ended || rowData.campaignStatusId == enumCampaignStatus.Campaign_Expired || (!currentUser.permissions.isClient && (rowData.statusId == null || (!!rowData.statusId && (rowData.campaignStatusId == enumCampaignStatus.Campaign_Live || rowData.campaignStatusId == enumCampaignStatus.Campaign_Paused ))))">{{ rowData.campaignStatusId |  enumString: enumCampaignStatus }}</td>
    
                                <td *ngIf="(!currentUser.permissions.isClient && !!rowData.statusId && rowData.campaignStatusId != enumCampaignStatus.Campaign_Live && rowData.campaignStatusId != enumCampaignStatus.Campaign_Paused && rowData.campaignStatusId != enumCampaignStatus.Campaign_Ended  && rowData.campaignStatusId != enumCampaignStatus.Campaign_Expired)">
                                    <span class="d-block" truncateText [pTooltip]="(rowData.statusId | enumString: enumCampaignStatus) + ' (' + ( rowData.campaignStatusId |  enumString: enumCampaignStatus ) + ')' " tooltipPosition="top" (onEllipsisAdded)="handleEllipseAdded($event, rowData.id)" [tooltipDisabled]="!nameEllipsesState[rowData.id]">
                                        {{ rowData.statusId |  enumString: enumCampaignStatus }} ({{ rowData.campaignStatusId |  enumString: enumCampaignStatus }})</span></td>
                                <td class="text-center"><a class="fa fa-file-pdf fa-lg" href="{{navigateToReport(rowData)}}" (click)="navigateToReport(rowData)"></a></td>
    
                                <td class="text-right" *ngIf="!currentUser.permissions.isClient || (currentUser.permissions.isClient && currentUser.permissions.isHMPClient )">
                                    <div class="center">
                                        <a pTooltip="Edit campaign" tooltipPosition="top" *ngIf="(currentUser.permissions.canCreateCampaignEmployee || currentUser.permissions.canCreateCampaignClient) && ((rowData.campaignStatusId == enumCampaignStatus.Campaign_Draft && rowData.statusId == null) || (gridId==2  && (currentUser.userRole == enumUserRole.Client_Solution || currentUser.userRole == enumUserRole.Admin) && rowData.statusId == null || (!!rowData.statusId && gridId == 2 && (rowData.campaignStatusId == enumCampaignStatus.Campaign_Paused || rowData.campaignStatusId == enumCampaignStatus.Campaign_Live))))" href="{{openEditDialog(rowData, true)}}" (click)="openEditDialog(rowData)" class="fa fa-edit fa-lg"> </a>
                                        
                                        <i pTooltip="Delete campaign" tooltipPosition="top" *ngIf="(rowData.campaignStatusId == enumCampaignStatus.Campaign_Draft || rowData.campaignStatusId == enumCampaignStatus.Assets_Pending) && rowData.statusId == null && (currentUser.userRole == enumUserRole.Client_Solution || currentUser.userRole == enumUserRole.Admin)" (click)="remove(rowData)" class="fa fa-trash fa-lg pl-2"></i>
        
                                        <i pTooltip="Trafficking grid" tooltipPosition="top" *ngIf="rowData.campaignStatusId != enumCampaignStatus.Assets_Pending && rowData.campaignStatusId != enumCampaignStatus.Campaign_Draft && (currentUser.userRole == enumUserRole.Client_Solution || currentUser.userRole == enumUserRole.Associate || currentUser.userRole == enumUserRole.Admin)" (click)="initTraffickingGrid(rowData)" class="fa fa-paper-plane fa-lg"></i>
        
                                        <i pTooltip="Send to API Build" tooltipPosition="top" *ngIf="!rowData.account.automaticApiBuild && (rowData.campaignStatusId == enumCampaignStatus.Assets_Approved || rowData.statusId == enumCampaignStatus.Assets_Approved || rowData.campaignStatusId == enumCampaignStatus.Payment_Completed) && ((rowData.statusId != enumCampaignStatus.Sent_to_API_Build && rowData.statusId != enumCampaignStatus.Build_Success && rowData.statusId != enumCampaignStatus.Build_Failed) || rowData.account.typeId == enumAccountType.HS) && (currentUser.userRole == enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin) && gridId !=2" (click)="sendToApiBuild(rowData, 'DV360')" class="fa fa-paper-plane fa-lg"></i><br>
        
                                        <p-inputSwitch [pTooltip]="rowData.campaignStatusId == enumCampaignStatus.Campaign_Live ? 'Pause Campaign': 'Go Live'" tooltipPosition="top" *ngIf="(rowData.statusId == enumCampaignStatus.Sent_to_API_Build || rowData.statusId == enumCampaignStatus.Build_Success || rowData.statusId == enumCampaignStatus.Build_Failed || rowData.campaignStatusId == enumCampaignStatus.Campaign_Live || rowData.campaignStatusId == enumCampaignStatus.Campaign_Paused || (rowData.account.isHubStandardCampaign && rowData.campaignStatusId == enumCampaignStatus.Assets_Approved)) && (currentUser.userRole == enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin || currentUser.userRole == enumUserRole.Client_Solution)" (onChange)="changeStatus(rowData)" [ngModel]="(rowData.campaignStatusId == enumCampaignStatus.Campaign_Live)"></p-inputSwitch>
                                    </div>        
                                </td>
                                <!-- <td class="text-right" *ngIf="!currentUser.permissions.isClient && rowData.account.isHubStandardCampaign">
                                    <p-inputSwitch [pTooltip]="rowData.campaignStatusId == enumCampaignStatus.Campaign_Live ? 'Pause Campaign': 'Go Live'" tooltipPosition="top" *ngIf="(rowData.statusId == enumCampaignStatus.Sent_to_API_Build || rowData.statusId == enumCampaignStatus.Build_Success || rowData.statusId == enumCampaignStatus.Build_Failed || rowData.campaignStatusId == enumCampaignStatus.Campaign_Live || rowData.campaignStatusId == enumCampaignStatus.Campaign_Paused) && (currentUser.userRole == enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin)" (onChange)="changeStatus(rowData)" [ngModel]="(rowData.campaignStatusId == enumCampaignStatus.Campaign_Live)"></p-inputSwitch>
                                </td> -->
                            </tr>
                        
                    </ng-template>

                </rc-grid>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!status || status.value == 1">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="campaignDelete" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>

    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="changeStatus" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>

    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="pauseLiveCampaign" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>

    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="initTrafficking" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>

    <p-confirmDialog header="Campaign Transfered" icon="pi pi-exclamation-triangle" key="transferedCampaign" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>

</div>

<!-- <p-dialog [showHeader]="true" header="Unlock features" [modal]="true" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
    
    <div class="mb-2 row align-items-center"> 
        <div class="col-md-8 font-weight-bold">
            Unlock date range, site tracking, and conversion tracking
        </div>
        <div class="col-md-4">
            <p-inputSwitch [(ngModel)]="unlock"></p-inputSwitch>
        </div>
    </div>
    
    <p class="pt-3">Someone from our team will contact you shortly.</p>
    <p-footer>
        <button type="button" (click)="onClose()" pButton class="p-button-primary" label="Sure, thank you"></button>
    </p-footer>
</p-dialog> -->

<campaign-transfer-data-dialog #campaignTransferDataDialog (updateGrid)="updateGrid()"></campaign-transfer-data-dialog>
