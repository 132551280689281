<img class="privacy-policy-image" src="assets/privacy-policy.png" (load)="imageLoaded = true" alt="Privacy policy">
<div class="privacy-policy-content" *ngIf="imageLoaded">
    <p>Mogo Marketing + Media, Inc., the parent company of MogoSME and MogoARTS Marketing, referred to as “MogoSME”, is committed to protecting consumer privacy and respecting your right to choose as it relates to advertising and the data used to help serve ads relevant to consumers. MogoSME helps advertisers connect with consumers who are interested or who could benefit from the advertisers’ products. See below for our complete Privacy Policy or to opt out of receiving relevant ads served by us.</p>
    <div class="small-spacing">
        <p>The following are Mogo’s promises to consumers:</p>
        <p>● We use non-personalized data to serve relevant ads to consumers</p>
        <p>● We use cookies to help match that non-personalized data to consumers around the Internet</p>
        <p>● We collect non-personalized data about users (which may include information like: sites visited, ads seen, search keywords used, ads clicked, shopping information).</p>
        <p>● We do NOT use personally identifiable information to choose what ads to serve to users</p>
        <p>● We do NOT target users who we know are under the age of 13 unless there is explicit parental consent and opt-in</p>
        <p>● We do NOT use any individual level data about health conditions, or specific financial account information to serve behaviorally targeted ads unless a user has explicitly opted in to such ads</p>
        <p>● We do NOT serve ads based on an individual’s sexual orientation or any individual’s visits to sexually explicit websites</p>
        <p>● We do NOT target users in the European Union or users within the European Economic Area.</p>
    </div>
    <p>MogoSME is highly committed to following the OBA Principles and as a member of the Digital Advertising Alliance and Network Advertising Initiative: NAI is in compliance with the industry’s Self-Regulatory Code and GDPR compliance standards. Click here if you would like further information regarding these principles.</p>
    <p>Please contact hello@mogosme.com for any questions about MogoSME privacy, security, and data practices. Please see below for our complete Privacy Policy.</p>
    <p>If you would prefer not to have MogoSME serve you relevant ads, you may opt-out here.</p>
    <p>Effective: January 7, 2015, updated July 22, 2021</p>
    <p>MogoSME (“we”, “us”, or “our”) is an online advertising and technology platform headquartered in the United States. We provide this privacy policy to inform you about how our technology platform (“Technology”) processes information on behalf of online advertisers, website owners, data providers and other businesses that use our services (our “Clients”). This policy also outlines our privacy practices for our corporate website, which is located on our website, mogosme.com.</p>
    <p>PRIVACY PRACTICES FOR MOGOSME</p>
    <p>Information MogoSME Does and Does Not Collect</p>
    <div class="small-spacing">
        <p>Information we collect in-directly</p>
        <p>If you visit our website, we collect non-personally identifiable information including, but not limited to:</p>
        <p>● Your unique IP address</p>
        <p>● Date and time of ad requests</p>
        <p>● Website pages you have viewed</p>
        <p>● Browser type you are using (Firefox, Chrome, Explorer, etc.)</p>
        <p>● Referring URL (of the website that directed you to the website you are viewing)</p>
        <p>● Internet Service Provider</p>
        <p>● Your computer’s operating system (Windows, Mac, etc.)</p>
        <p>● Non-PII from Clients or other third parties</p>
    </div>
    <div class="small-spacing">
        <p>The following that we do NOT collect:</p>
        <p>● We do not collect personal information, as defined in the Children’s Online Privacy Protection Act (“COPPA”), from users we have actual knowledge are under the age of 13 or from sites directed to children under the age of 13 for OBA, nor do we engage in OBA directed to children we have actual knowledge are under the age of 13 except as compliant with the COPPA.</p>
        <p>● MogoSME also does not collect or use financial account numbers, Social Security numbers, pharmaceutical prescriptions, or medical records about a specific individual for Online Behavioral Advertising.</p>
    </div>
    <div class="small-spacing">
        <p>Information we collect directly from you:</p>
        <p>We receive Personal Data directly from you when you provide us with such Personal Data via forms, direct contact or other , including without limitation the following:</p>
        <p>● First and last name</p>
        <p>● Email address</p>
        <p>● Location information (limited to city, state, zip code, country, and time zone)</p>
        <p>● Employment history</p>
        <p>● Links to your social media accounts</p>
        <p>● Any other information you choose to share publicly on the Services, for example, anything you post to a public part of your Profile or an online classroom on the Services</p>
    </div>
    <div class="small-spacing">
        <p>How We Use Your Information</p>
        <p>MogoSME uses non-personally identifiable information to target and serve relevant advertising to consumers and to perform attribution analysis or other analytics regarding advertising audiences. MogoSME does NOT use any personally identifiable information to serve advertisements to consumers, and we do not sell any of your personally identifiable information. We do not actively target and collect data on members of the European Union or users within the European Economic Area.</p>
    </div>
    <div class="small-spacing">
        <p>Cookies and Web Beacons</p>
        <p>In order to help make the online advertisements more relevant to users, MogoSME uses the standard “cookies” feature of major browser applications that allows us to store a small piece of data on users’ computers about users’ visits to certain websites. We also use cookies to understand users’ browsing and buying activities so that we can measure the success of online advertising campaigns we run.</p>
    </div>
    <p>Cookies are small text files that web servers typically send to a user’s computer when they visit a website. Cookies are stored as text files on a user’s hard drive, and can be accessed by web servers when the user visits a website or views an advertisement. Cookies are used by companies to collect and send information about a user’s website visit – for example, number of visits, average time spent, pages viewed, navigation history through the website, and other statistics. This information helps companies improve a user’s online experience in many ways: for example, by allowing companies to monitor website performance, making the website easier to use, measuring the effectiveness of their promotional placements, and tailoring their website (including the ads and offers they receive) to better match a user’s interests and preferences.The cookies placed by MogoSME cannot be used to access any other data on a user’s hard drive, or to personally identify them. Users who prefer not to accept cookies can set their Internet browser to notify them when they receive a cookie or to prevent cookies from being placed on their hard drive.</p>
    <p>The MogoSME cookie is set to expire after one year, this expiration is a sliding window, meaning it resets itself after any interaction occurs.</p>
    <p>MogoSME also uses web beacons (also known as 1×1 gifs), along with cookies, to determine if a user has visited a particular website. These beacons aid in providing more relevant advertising to the user. They also help the success of an advertising campaign. They do not provide any personally identifiable information.</p>
    <div class="small-spacing">
        <p>Opt-Out From Our Cookies</p>
        <p>If you’d like to opt-out from having Mogo collect your non personally identifiable information, please click on the opt out button on this page. When you opt out, we will place an opt-out cookie on your computer. The opt-out cookie tells us not to collect your non-personally identifiable information to tailor our online advertising campaigns. Please note that if you delete, block or otherwise restrict cookies, or if you use a different computer or Internet browser, you may need to renew your opt-out choices. Please also note that if you choose to disable this function, your online experience may be diminished and some features may not work as they were intended.</p>
    </div>
    <p>The online advertising industry also provides websites from which you may opt-out of receiving targeted ads from our data and advertising partners. You can access these, and also learn more about targeted advertising and consumer choice and privacy, at aboutads.info and at networkadvertising.org/choices/.</p>
    <div class="small-spacing">
        <p>Information Sharing</p>
        <p>MogoSME may share aggregated, non-personal information with Clients in connection with reporting and accounting needs as well as with other unaffiliated third parties for various purposes such as statistical or educational analysis. In some instances we may share Client contact information in an offline process that includes personally identifiable information—like name, email address and company—with select service providers and partners of MogoSME who provide relevant services for our Clients. None of this personally identifiable information is tied to you the user or any cookied user in Mogo.</p>
    </div>
    <p>We may disclose your information to third parties when we reasonably believe we are obligated to do so by law, and in order to investigate, prevent, or take action regarding suspected or actual prohibited activities, including but not limited to, fraud and situations involving potential threats to the physical safety of any person.</p>
    <p>Also, in the event MogoSME is merged into another company, or another company acquires all or substantially all of MogoSME’s assets, including, without limitation, in the event of bankruptcy, all data compiled or owned by MogoSME may be among the items transferred in that transaction. In such event, all the data will continue to be governed by this policy.</p>
    <div class="small-spacing">
        <p>Data Storage and Security</p>
        <p>We retain the non-Personally Identifiable Information collected for up to 180 days from the last time we see a user. After 180 days the data is stored in aggregate for up to three years. In instances where local laws are more stringent, we adhere to the local laws where we are doing business.</p>
    </div>
    <p>The security of your information is important to us. MogoSME has implemented reasonable security measures to prevent the loss, misuse, and alteration of the information that MogoSME collects, but we make no assurances about our ability to prevent such loss, misuse, to you or to any third party arising out of any such loss, misuse, or alteration.</p>
    <div class="small-spacing">
        <p>Changes To Our Policy</p>
        <p>We may revise this policy at any time. Any changes and/or updates to the policy will be updated directly on this page. We encourage you to visit this page periodically to review our current privacy policy so that you will always know what information we gather and how we may use that information. In the event we make any material changes to this policy we will advise our Clients of such changes in advance.</p>
    </div>
    <div class="small-spacing">
        <p>Contacting Us</p>
        <p>Should you have any questions in regards to this policy or MogoSME’s privacy practices, please contact our representatives at the following email and they will be more than happy to clarify and assist you in any way possible: hello@mogosme.com, or by mail at:</p>
    </div>
    <div class="small-spacing">
        <p>Mogo Marketing & Media, Inc.</p>
        <p>900 Larkspur Landing Circle</p>
        <p>Suite #900-125</p>
        <p>Larkspur, CA 94939</p>
    </div>
    <p>PRIVACY PRACTICES FOR OUR WEBSITE</p>
    <div class="small-spacing">
        <p>Information We Collect On Our Website</p>
        <p>On the Careers or Contact sections of the Website, we may collect personally identifiable information. The information that MogoSME records is only used to reply to job inquiries or contacts and is later on erased with no further use. MogoSME does not match any of this data with the cookies-based data MogoSME collects.</p>
    </div>
    <p>We may also collect non- personally identifiable information from visitors to this Website for a variety of reasons including to streamline website usability.</p>
    <div class="small-spacing">
        <p>Third Party Websites</p>
        <p>The MogoSME Website may contain links to and advertisements for websites operated by third parties whose privacy practices may differ from ours. While we endeavor to associate only with reputable entities, MogoSME is not responsible for the privacy practices of these other websites. We encourage you to check the privacy policies of all websites that you visit.</p>
    </div>
    <div class="small-spacing">
        <p>Updating / Deleting your PII</p>
        <p>If you’d like us to update, correct, delete, or deactivate any PII that you have provided to us on this Website, please re-submit your contact information on the Contact page or send your request to us at hello@mogosme.com and we will process your request within a reasonable period of time after receipt.</p>
    </div>
    <div class="small-spacing">
        <p>Acceptance of this Policy</p>
        <p>Your use of our Site, including any dispute concerning privacy, is subject to this Privacy Policy and the. BY USING OUR SITE, YOU ARE ACCEPTING THE PRACTICES SET OUT IN THIS PRIVACY POLICY. We reserve the right to modify this Privacy Policy at any time by posting the changes on this page. Please check the revision date at the bottom of this page to determine if the policy has been modified since you last reviewed it. Your continued use of any portion of our Site following posting of the updated Privacy Policy will constitute your acceptance of the changes. EFFECTIVE DATE. This privacy policy was last revised on July 22, 2021.</p>
    </div>

</div>