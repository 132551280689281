
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageService, SelectItem } from 'primeng/api';
import { componentFadeIn } from '../../../route-animations';
import { Constants, LookUps } from '@Workspace/common';
import {
    AccountController, TargetingController, TeamController
} from '@Workspace/_generated/services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { eAccountType, eIndustry, eEntityStatus } from '@Workspace/_generated/enums';
import { IUserCreateDto, IAccountDto } from '@Workspace/_generated/interfaces';
import { Router } from '@angular/router';
import { UserDetailComponent } from '../../user/user-detail/user-detail.component';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../services/campaign.service';
import { PageLoaderService } from '@Workspace/services';

@Component({
    selector: 'account-detail',
    templateUrl: './account-detail.component.html',
    styleUrls: ['./account-detail.component.scss'],
    animations: [componentFadeIn]
})
export class AccountDetailComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        public accountController: AccountController,
        private cdr: ChangeDetectorRef,
        protected fb: UntypedFormBuilder,
        private authService: AuthService,
        private router: Router,
        private teamController: TeamController,
        private campaignService: CampaignService,
        public messageService : MessageService,
        private targetingController: TargetingController,
        private pageLoader: PageLoaderService
    ) {

    }

    @ViewChild(UserDetailComponent) userDetailComponent: UserDetailComponent;
    formGroup: UntypedFormGroup;
    formGroupUser: UntypedFormGroup;
    newUser: IUserCreateDto;
    newAccount: IAccountDto;
    companyTypes: SelectItem[] = [];
    industries: SelectItem[] = [];
    notificationGroups: SelectItem[] = [];
    passwordPattern: string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

    ngUnsubscribe: Subject<void> = new Subject();
    companies: any;
    equalCompaniesName: boolean = false;

    currentUser = this.authService.userSettings;
    eAccountType = eAccountType;
    loading: boolean = false;
    dmas: SelectItem[];
    states: SelectItem[];
    cities: SelectItem[];


    async ngOnInit(): Promise<void> {
        this.getGeographicData();
        this.formInit();
        this.getAccounts();
        await this.initLists();
        this.createNewUser();
        this.userDetailComponent.initDataFromAccount(this.formGroupUser,"Account",this.newUser,true);
    }

    selectedCompanyType(event) {
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    formInit(): void {
        this.formGroup = this.fb.group({
            companyName: ["", Validators.required],
            companyType: ["", Validators.required],
            industry: [""],
            notificationGroup: [""],
            GCMAdvertiserId: [""],
            TTDAdvertiserId: [""],
            DV360AdvertiserId: [""],
            facebookAdAccountId: [],
            facebookPageId: [],
            instagramId: [],
            apiBuild: [],
            cities: [],
            dma: [""],
            state: [""]
        });
        this.formGroupUser = this.fb.group({
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            email: ["", [Validators.email, Validators.required]],
            phone: [""],
            username: ["", Validators.required],
            password: ["", [Validators.required, Validators.pattern(this.passwordPattern)]],
            confirmPassword: ["", Validators.required],
            sendUserNotification: [false],
            accountRole: ["", Validators.required],
            account: [""],
            team: [""]
        });
    }

    getGeographicData() {
        this.loading = true;
        this.targetingController.GetGeographicDialogData(Constants.GuidEmpty, false, '')
            .subscribe(
                (result) => {
                    const stateMap = ({ id, name }) => ({ label: name, value: id });
                    const dmaMap = ({ dmaName, dmaCode }) => ({ label: dmaName, value: dmaCode });
                    const cityMap = ({ id, name, stateAbbreviation }) => ({ label: `${name}, ${stateAbbreviation}`, value: id });

                    const states = result.states.map(stateMap);
                    this.states = states;
                    this.states.unshift({ label: "-", value: null });

                    const dmas = result.dmas.map(dmaMap);
                    this.dmas = this.mapUnique(dmas);
                    this.dmas.unshift({ label: "-", value: null });

                    const cities = result.cities.map(cityMap);
                    this.cities = this.mapUnique(cities);

                    this.loading = false;
                    this.pageLoader.hidePageLoader()
                },
                (error) => { this.pageLoader.hidePageLoader() });
    }

    private mapUnique(items: SelectItem[]): SelectItem[] {
        let result: SelectItem[] = [];
        let map = new Map();

        items.forEach(x => {
            if (!map.has(x.label)) {
                map.set(x.label, true);
                result.push({ label: x.label, value: x.value });
            }
        });

        return result;
    }

    onCompanyNameChange() {
        let company = this.formGroup.get('companyName').value

        if (this.companies.find(x => x.toLowerCase() == company.toLowerCase()))
            this.equalCompaniesName = true;
        else
            this.equalCompaniesName = false;
    }

    async initLists() {
        let orderBy = `?$orderby=Name asc`;
        let teams = await this.teamController.GetTeams(orderBy).toPromise();
        this.notificationGroups = teams.data.map(t => { return { label: t.name, value: t.id } });
        this.companyTypes = LookUps.getAccountTypesLu();
        this.industries = LookUps.getIndustryLu();
        this.companyTypes.unshift({ value: 0, label: "-" });
        this.industries.unshift({ value: 0, label: "-" });
        this.notificationGroups.unshift({ value: 0, label: "-" });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    createNewUser() {
        this.newUser = {} as IUserCreateDto;
    }

    getAccountValues() {
        this.newAccount = {} as IAccountDto;
        this.newAccount.name = this.formGroup.value.companyName;
        this.newAccount.industry = !!this.formGroup.value.industry ? this.formGroup.value.industry : eIndustry.NotSet;
        this.newAccount.typeId = this.formGroup.value.companyType;

        this.newAccount.notificationTeamId = this.formGroup.value.notificationGroup == 0 ? null : this.formGroup.value.notificationGroup;
        this.newAccount.advertiserId = this.formGroup.value.GCMAdvertiserId;
        this.newAccount.ttdAdvertiserId = this.formGroup.value.TTDAdvertiserId;
        this.newAccount.dV360AdvertiserId = this.formGroup.value.DV360AdvertiserId;
        this.newAccount.facebookAdAccountId = this.formGroup.value.facebookAdAccountId;
        this.newAccount.facebookPageId = this.formGroup.value.facebookPageId;
        this.newAccount.instagramId = this.formGroup.value.instagramId;
        this.newAccount.email = this.newUser.email;
        this.newAccount.accountUsersDto = [];
        this.newAccount.entityStatus = eEntityStatus.Active;

        if (this.newAccount.typeId == eAccountType.HMP || this.currentUser.permissions.isAdmin) {
            this.newAccount.geographicTargetDMA = this.formGroup.value.dma;
            this.newAccount.geographicTargetState = this.formGroup.value.state;
            if(this.formGroup.value.cities == null || this.formGroup.value.cities.length==0){
                this.newAccount.geographicTargetCity =null;
            }
            else{
                this.newAccount.geographicTargetCity = JSON.stringify(this.formGroup.value.cities).replace('[','').replace(']','');
            }
        }
    }

    getAccounts() {
        this.companies = [];
        this.accountController.GetAllAccounts('').subscribe(x => {
            x.data.forEach(y => {
                this.companies.push(y.name);
            });
        });
    }
    saveChanges() {
        this.pageLoader.showPageLoader();
        this.newUser = this.userDetailComponent.getUserValues();
        this.getAccountValues();
        this.newUser.userAccounts[0].accountCreate = this.newAccount;

        this.accountController.CreateUser(this.newUser).subscribe(async a => {
            await this.authService.reloadUserSettingsAsync();
            this.campaignService.setIsGrid(true);
            this.pageLoader.hidePageLoader();
            this.router.navigate(["mogo/accounts"]);
        },
        error => {
            for (const property in error.error.Errors) {
                this.pageLoader.hidePageLoader();
                error.error.Errors[property].forEach(element => {
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: element });
                });
            }
        }
        );
    }

    onCancel() {
        this.campaignService.setIsGrid(true);
        this.router.navigate(["mogo/accounts"]);
    }

}