import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignDetailPageComponent } from './pages/campaign/campaign-detail-page';
import { MogoComponent } from './mogo.component';
import { CampaignUploadPage } from './pages/campaign-uploads/campaign-upload-page';
import { AccountPage } from './pages/account/account.page';
import { AccountDetailComponent } from './components/account/account-detail/account-detail.component';
import { StepsNavigationPageComponent } from './pages/steps-navigation/steps-navigation.page';
import { SuCampaignDetailComponent } from './components/standard-users/su-campaign/su-campaign-detail-create/su-campaign-detail-create.component';
import { CampaignPaymentComponent } from './components/campaign/campaign-payment/campaign-payment.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { EmployeePage } from './pages/employee/employee.page';
import { TeamDetailComponent } from './components/team/team-detail/team-detail.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { AccountListComponent } from './components/account/account-list/account-list.component';
import { UploadNewCrmPage } from "./pages/upload-new-crm/upload-new-crm.page";
import { TeamListComponent } from './components/team/team-list/team-list.component';
import { UserPicklistComponent } from './components/user/user-picklist/user-picklist.component';
import { SuCampaignReviewComponent } from './components/standard-users/su-campaign/su-campaign-review/su-campaign-review.component';
import { CampaignReviewComponent } from './components/campaign/campaign-review/campaign-review.component';
import { CampaignReviewPage } from './pages/campaign-review/campaign-review-page';
import { LoggedInGuard, AuthGuard, PendingChangesGuard } from '@Workspace/guards';
import { InstructionsPage } from './pages/instructions/instructions.page';
import { CampaignPreviewPage } from './pages/campaign/campaign-preview.page';
import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { CampaignUploadPageFan365 } from './pages/campaign-uploads-fan365/campaign-upload-page-fan365';
import { CampaignReportAssetsComponent } from './components/campaign/campaign-report/campaign-report-assets/campaign-report-assets.component';
import { CampaignReportManageComponent } from './components/campaign/campaign-report/campaign-report-manage/campaign-report-manage.component';
import { CampaignReportDetailComponent } from './components/campaign/campaign-report/campaign-report-detail/campaign-report-detail.component';
import { CampaignReportPage } from './pages/campaign/campaign-report/campaign-report.page';
import { HelpDocumentsPage } from './pages/documents/help-documents.page';
import { CampaignPaymentPage } from './pages/campaign/campaign-payment/campaign-payment-page';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { HelpDocumentsComponent } from './components/documents/help-documents.component';
import { CampaignSuccessCardPage } from './pages/campaign/campaign-success-card/campaign-success-card.page';
import { CampaignThrivePackageComponent } from './components/campaign/campaign-thrive-package/campaign-thrive-package.component';
import { CampaignCalendar } from './components/campaign-calendar-page/campaign-calendar-page';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ServiceAgreementComponent } from './components/service-agreement/service-agreement.component';

const routes: Routes = [
    {
        path: '',
        component: MogoComponent,
        children: [
            {
                path: '',
                component: CampaignPreviewPage,
                pathMatch: 'full'
            },
            {
                path: 'fan365/:sponsor',
                component: CampaignPreviewPage,
                pathMatch: 'full'
            },
            {
                path: "campaign/:slug",
                canActivate: [AuthGuard],
                component: StepsNavigationPageComponent,
                data: { animation: 'isRight' }
            },
            {
                path: 'campaign/:slug/payment',
                // canActivate: [AuthGuard],
                component: CampaignPaymentPage
            },
            {
                path: 'campaign/:slug/campaign-upload',
                component: CampaignUploadPage,
                canDeactivate: [PendingChangesGuard],
                data: { animation: 'isRight' }

            },
            {
                path: 'campaign/:slug/reporting',
                component: CampaignReportPage,
                data: { animation: 'isRight' }

            },
            {
                path: 'campaign/:slug/thrive',
                component: CampaignThrivePackageComponent,
                data: { animation: 'isRight' }

            },

            {
                path: 'su-campaign/:slug',
                canActivate: [AuthGuard],
                component: SuCampaignDetailComponent,
                data: { animation: 'isRight' }

            },
            {
                path: 'su-campaign-review/:slug',
                component: SuCampaignReviewComponent,
                data: { animation: 'isRight' }

            },
            {
                path: 'campaign/:slug/campaign-success-card',
                component: CampaignSuccessCardPage,
                data: { animation: 'isRight' }

            },
            {
                path: "resources",
                component: InstructionsPage,
                data: { animation: 'isRight' },
                children: [
                    {
                        path: "creative-specs",
                        component: InstructionsComponent,
                        data: { animation: 'isRight' }
                    },
                    {
                        path: "documents",
                        component: HelpDocumentsComponent,
                        data: { animation: 'isRight' }
                    }
                ]
            },
            {
                path: "privacy-policy",
                component: PrivacyPolicyComponent,
                data: { animation: 'isRight' }
            },
            {
                path: "service-agreement",
                component: ServiceAgreementComponent,
                data: { animation: 'isRight' }
            },
            {
                path: "documents",
                component: HelpDocumentsPage,
                data: { animation: 'isRight' }
            },
            {
                path: 'accounts',
                component: AccountPage,
                canActivate: [AuthGuard],
                //  data: { animation: 'isRight' },
                children: [
                    {
                        path: "",
                        component: AccountListComponent,
                        canActivate: [AuthGuard],
                        data: { animation: 'isRight' }
                    },
                    {
                        path: "users",
                        canActivate: [AuthGuard],
                        component: UserListComponent,
                        data: { animation: 'isRight' }
                    },
                    {
                        path: ":slug",
                        canActivate: [AuthGuard],
                        component: AccountDetailComponent,
                        data: { animation: 'isRight' }
                    },
                    {
                        path: "users/:slug",
                        canActivate: [AuthGuard],
                        component: UserDetailComponent,
                        data: { animation: 'isRight' }
                    },

                ]
            },
            {
                path: 'teams',
                component: EmployeePage,
                canActivate: [AuthGuard],
                //  data: { animation: 'isRight' },
                children: [
                    {
                        path: "",
                        component: TeamListComponent,
                        canActivate: [AuthGuard],

                        data: { animation: 'isRight' }
                    },
                    {
                        path: "users",
                        component: UserListComponent,
                        canActivate: [AuthGuard],

                        data: { animation: 'isRight' }
                    },
                    {
                        path: ":slug",
                        component: TeamDetailComponent,
                        canActivate: [AuthGuard],

                        data: { animation: 'isRight' }
                    },

                    {
                        path: "users/:slug",
                        component: UserDetailComponent,
                        canActivate: [AuthGuard],

                        data: { animation: 'isRight' }
                    },

                ]
            },
            {
                path: 'campaign-calendar',
                component: CampaignCalendar,
            },
            {
                path: 'reporting',
                component: EmployeePage,
                canActivate: [AuthGuard],
                //  data: { animation: 'isRight' },
                children: [
                    {
                        path: "",
                        component: TeamListComponent,
                        data: { animation: 'isRight' }
                    },

                ]
            },
            {
                path: "upload-new-crm",
                component: UploadNewCrmPage
            },
            {
                path: 'campaign/:slug/campaign-review',
                component: CampaignReviewPage,
                data: { animation: 'isRight' }

            },
            {
                path: 'campaign/:slug/campaign-upload-fan365',
                component: CampaignUploadPageFan365,
                canDeactivate: [PendingChangesGuard],
                data: { animation: 'isRight' }

            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MogoRoutingModule { }
