import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, Input } from '@angular/core';
import { eCampaignGoal, eHMPCampaignPackages, eStandardCampaginChannel, eCampaignStatus, eChannelType, eAccountType, eEntityStatus} from '@Workspace/_generated/enums';
import { MessageService, SelectItem } from 'primeng/api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IPackageDto, IBaseCampaignDto, IAddOnDto, ICampaignAddOnDto, IAccountDto, ICreativeDto, ICreativeAssetDto, IDetailJson } from '@Workspace/_generated/interfaces';
import { LookUps, Constants, Functions } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { CampaignController } from '@Workspace/_generated/services';
import { Subject } from 'rxjs';
import { CampaignPackagePreviewComponent } from '../campaign-package-preview/campaign-package-preview.component';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { EnumStringPipe } from '@Workspace/pipes';
import { CartItemService } from '../../../../shared-core/components/card-item/card-item.service';
import { CampaignService } from '../../../services/campaign.service';
import { CampaignThrivePackageComponent } from '../campaign-thrive-package/campaign-thrive-package.component';
import { PageLoaderService } from '@Workspace/services';
import * as _ from 'lodash';

@Component({
    selector: 'campaign-detail-create',
    templateUrl: './campaign-detail.component.html',
    styleUrls: ['./campaign-detail.component.scss']
})
export class CampaignDetailCreateComponent implements OnInit, OnDestroy {
    constructor(
        protected fb: UntypedFormBuilder,
        public authService: AuthService,
        public campaignController: CampaignController,
        private messageService: MessageService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private cardItemService: CartItemService,
        public campaignService: CampaignService,
        private pageLoaderService: PageLoaderService
    ) { }
    @ViewChild(CampaignPackagePreviewComponent) campaignPackagePreviewComponent: CampaignPackagePreviewComponent;
    @ViewChild(CampaignThrivePackageComponent) campaignThrivePackageComponent: CampaignThrivePackageComponent;
    packageItem: string = "";
    amount: number;
    totalAmount: number = 0;
    currentUser = this.authService.userSettings;
    currentAccount: IAccountDto;
    isAdditionalPackageAdded: boolean = false;
    IsAddOnSelected: boolean = false;
    IsFacebookAddOnSelected: boolean = false;
    IsAbandonedCartAddOnSelected: boolean = false;
    isPpuAddOnSelected: boolean = false;
    isInStreamVideoAddOnSelected: boolean = false;
    isStaticNativeAddOnSelected: boolean = false;
    isFacebookDynamicVideoAddOnSelected: boolean = false;
    isFacebookDynamicStaticAddOnSelected: boolean = false;
    isFacebookCarouselAddOnSelected: boolean = false;

    campaignGoalItem: eCampaignGoal[] = [];
    campaignGoals: SelectItem[] = [];
    selectedCampaignGoal: number = 0;
    formGroup: UntypedFormGroup;
    campaignNameLabel: string = "";
    campaignGoalLabel: string = "";
    campaignGoalStartDateLabel: Date = new Date();
    campaignGoalEndDateLabel: Date = new Date();
    isDisplayAddOnVisible: boolean = false;
    isFacebookAddOnVisible: boolean = false;
    isAbandonedCartAddOnVisible: boolean = false;
    isPpuAddOnVisible: boolean = false;
    isInStreamVideoAddOnVisible: boolean = false;
    isStaticNativeAddOnVisible: boolean = false;
    isFacebookDynamicVideoAddOnVisible: boolean = false;
    isFacebookDynamicStaticAddOnVisible: boolean = false;
    isFacebookCarouselAddOnVisible: boolean = false;

    minStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    constants = Constants;
    _eCampaignGoal = eCampaignGoal;
    campaignDto: IBaseCampaignDto = {} as IBaseCampaignDto;
    activeIndex: number = 0;
    campaignAddOn: ICampaignAddOnDto[] = [];
    addOn: IAddOnDto[] = {} as IAddOnDto[];
    ngUnsubscribe: Subject<void> = new Subject();
    difference_In_Days: number;
    selectedPackage: IPackageDto = {} as IPackageDto;
    startDate: Date = new Date();
    campaignId: string;
    isRequiredFieldEmpty: boolean = false;
    creativesBackup: ICreativeDto[] = [];
    test: boolean = true;
    @Output() onStepTwo: EventEmitter<any> = new EventEmitter();
    @Output() campaignGoalId: EventEmitter<any> = new EventEmitter();
    @Output() campaign: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() showPackage: boolean;
    premiumBanners: any[] = ['970x250', '970x90', '320x100'];
    standardBanners: string[] = ['160x600', '300x250', '728x90', '320x50', '300x600'];
    unit: string;
    amplifyAddOn: IAddOnDto[] = {} as IAddOnDto[]
    numberOfCreativeFlightsAmplifyFlex: number = 1;
    numberOfFlightsHelper: number = 1;
    minCpm: number = 9;
    maxCpm: number = 2;
    maxNumberOfFlights: number = 1;
    isGrowSocialEvent: boolean = false;
    isGrowSocialCampaign: boolean = false;

    ngOnInit() {
        this.campaignAddOn = !!this.baseCampaign.campaignAddOns && !!this.baseCampaign.campaignAddOns.length ? this.baseCampaign.campaignAddOns : [];
        let campaigngoalId = this.baseCampaign.campaignGoalId ? this.baseCampaign.campaignGoalId : eCampaignGoal.Direct_Response;
        this.selectedCampaignGoalType(campaigngoalId, true);
        this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.campaignId = params.slug;
        });
        this.campaignService.getRefresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.formInit();
            this.initLists();
            let newDate = new Date();
            let dateOnly = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
            var workingDays = Functions.addBusinessDays(dateOnly, 5);
            this.startDate = workingDays;
            let currentUser = this.authService.userSettings;
            if (!!currentUser.accounts && !!currentUser.accountId)
                this.currentAccount = currentUser.accounts.find(x => x.id == currentUser.accountId);
            else
                this.currentAccount = currentUser.accounts[0];

            this.formGroup.value.campaignGoal = this.campaignGoals.find(x => x.value == eCampaignGoal.Direct_Response).value;
        });

        if (!!this.baseCampaign && !!this.baseCampaign.creatives) {
            this.campaignDto = this.baseCampaign;
            this.campaign.emit(this.campaignDto);
            this.creativesBackup = this.campaignDto.creatives;
            this.formGroup.value.campaignName = this.campaignDto.name;
            this.formGroup.value.campaignGoalId = this.campaignDto.campaignGoalId;
            this.packageItem = this.campaignDto.package.name;
            let startDate = new Date(this.campaignDto.startDate);
            let endDate = new Date(this.campaignDto.endDate);
            this.difference_In_Days = ((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
                Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / 86400000)+1;
            this.campaignGoalLabel = this.campaignGoals.find(x => x.value == this.campaignDto.campaignGoalId).label;
            this.campaignDto.endDate = !!this.baseCampaign.endDate ? new Date(this.baseCampaign.endDate.toString()) : null;
            this.campaignDto.startDate = !!this.baseCampaign.startDate ? new Date(this.baseCampaign.startDate.toString()) : null;
            this.selectedCampaignGoal = this.campaignDto.campaignGoalId;
            this.selectedPackage = this.campaignDto.package;
            this.maxNumberOfFlights = _.orderBy(this.campaignDto.creatives, 'creativeGroup', 'asc')[this.campaignDto.creatives.length-1].creativeGroup;
            this.selectedCampaignGoalType(this.campaignDto.campaignGoalId, false);
            if (this.campaignDto.package.name == "Grow") {
                this.campaignDto.package.facebookDynamicNewsFeedVideo = false;
                this.campaignDto.package.premiumDisplayBanners = false;
                this.baseCampaign.creatives.forEach(cr => {
                    if (cr.channelId == 2)
                        this.campaignDto.package.premiumDisplayBanners = true;
                    else if (cr.channelId == 1)
                        this.campaignDto.package.facebookDynamicNewsFeedVideo = true;
                });
            }
            this.onSelectedPackageItemAddOn(this.campaignDto.package);
            this.totalAmount = this.campaignDto.amount;
            this.amount = this.campaignDto.amount - this.campaignDto.totalDisplayAddOnAmount - this.campaignDto.totalFacebookAddOnAmount - this.campaignDto.totalOtherAddOnAmount - this.campaignDto.totalCreativeFlightsAddOnAmount;
            

            this.IsAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Premium Display Banners");              
            this.IsFacebookAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Facebook Video News Feed");
            this.IsAbandonedCartAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Abandoned Cart Display Banners");
            this.isPpuAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Post Purchase Display Banners");
            this.isInStreamVideoAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Display In-Stream Video");
            this.isStaticNativeAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Static Native");
            this.isFacebookDynamicVideoAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Facebook Dynamic News Feed Video");
            this.isFacebookDynamicStaticAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Facebook Dynamic News Feed");
            this.isFacebookCarouselAddOnSelected = this.campaignDto.campaignAddOns.some(x => x.addOn!=null && x.addOn.name == "Facebook Dynamic News Feed Carousel");       

            if (!!this.addOn[0])
                this.addOn[0].price = this.campaignDto.totalDisplayAddOnAmount;
            if (!!this.addOn[1])
                this.addOn[1].price = this.campaignDto.totalFacebookAddOnAmount;
                
            this.numberOfCreativeFlightsAmplifyFlex = _.orderBy(this.campaignDto.creatives, 'creativeGroup', 'asc')[this.campaignDto.creatives.length-1].creativeGroup;
            if(this.campaignDto.package.name!='Amplify Flex'){
                this.numberOfFlightsHelper = this.numberOfCreativeFlightsAmplifyFlex;
            }
            this.patchFormValue();
        }

        else if (this.campaignId != Constants.GuidEmpty) {
            this.pageLoaderService.showPageLoader();
            let filter = `?$filter=Id eq ${this.campaignId}`;
            this.campaignController.GetAllCampaigns(filter).subscribe(async data => {
                if (data.totalCount > 0) {
                    this.campaignDto = data.data[0];
                    this.creativesBackup = this.campaignDto.creatives;
                    if(this.campaignDto.package.name=="Grow"){
                        if(this.creativesBackup.some(x => x.channelId==eChannelType.Social))
                            this.campaignDto.package.premiumDisplayBanners = false;
                        else
                            this.campaignDto.package.facebookDynamicNewsFeedVideo = false;
                    }
                    this.campaign.emit(this.campaignDto);
                    this.formGroup.value.campaignName = this.campaignDto.name;
                    this.formGroup.value.campaignGoalId = this.campaignDto.campaignGoalId;
                    this.packageItem = !!this.campaignDto.package ? this.campaignDto.package.name : '';
                    let startDate = new Date(this.campaignDto.startDate);
                    let endDate = new Date(this.campaignDto.endDate);
                    this.difference_In_Days = ((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
                        Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / 86400000)+1;
                    this.amount = this.campaignDto.package.price * (this.difference_In_Days / 7);
                    this.campaignAddOn = !!this.campaignDto.campaignAddOns && !!this.campaignDto.campaignAddOns.length ? this.campaignDto.campaignAddOns : [];
                    let campaigngoalId = this.campaignDto.campaignGoalId ? this.campaignDto.campaignGoalId : eCampaignGoal.Direct_Response;
                    this.selectedCampaignGoalType(campaigngoalId, true);
                    this.campaignGoalLabel = this.campaignGoals.find(x => x.value == this.campaignDto.campaignGoalId).label;
                    this.campaignDto.endDate = new Date(this.campaignDto.endDate.toString());
                    this.campaignDto.startDate = new Date(this.campaignDto.startDate.toString());
                    this.numberOfCreativeFlightsAmplifyFlex = _.orderBy(this.campaignDto.creatives, 'creativeGroup', 'asc')[this.campaignDto.creatives.length-1].creativeGroup;
                    this.patchFormValue();
                }
                this.pageLoaderService.hidePageLoader();
            },
            error => {
                this.pageLoaderService.hidePageLoader();
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
            });
        }
    }
    
    onAddAddional(event: any) {
        this.isAdditionalPackageAdded = JSON.parse(event.currentTarget.ariaExpanded);
    }

    changePremiumAddOn(event: string){
        this.IsAddOnSelected = !this.IsAddOnSelected;

        if (this.campaignDto.package.name != 'Amplify' && this.campaignDto.package.name != 'Amplify Flex') {
            if(this.IsAddOnSelected){
                if (this.addOn.find(x => x.id == event).description == "3"){
                    this.totalAmount = this.totalAmount + ((this.addOn.find(x => x.id == event).price * this.difference_In_Days));
                    this.campaignDto.totalDisplayAddOnAmount+=((this.addOn.find(x => x.id == event).price * this.difference_In_Days));
                }
                else{
                    this.totalAmount = this.totalAmount + ((this.addOn.find(x => x.id == event).price * this.difference_In_Days) / 7);
                    this.campaignDto.totalDisplayAddOnAmount+=((this.addOn.find(x => x.id == event).price * this.difference_In_Days) / 7);
                }
            }
            else{
                if (this.addOn.find(x => x.id == event).description == "3"){
                    this.totalAmount = this.totalAmount - ((this.addOn.find(x => x.id == event).price * this.difference_In_Days));
                    this.campaignDto.totalDisplayAddOnAmount-=((this.addOn.find(x => x.id == event).price * this.difference_In_Days));
                }
                else{
                    this.totalAmount = this.totalAmount - ((this.addOn.find(x => x.id == event).price * this.difference_In_Days) / 7);
                    this.campaignDto.totalDisplayAddOnAmount-=((this.addOn.find(x => x.id == event).price * this.difference_In_Days) / 7);
                }
            }
        }
    }
    
    handleChange(event: any) {
        if (this.IsAddOnSelected) {
            let isDisplayPremiumExist: boolean = this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId==eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Standard_Display_Banner);
            let isAbandonedPremiumExist: boolean = this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId==eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner);
            let isPpuPremiumExist: boolean = this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId==eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner);
            for (var y = 0; y < this.campaignDto.creatives.length; y++) {
                if ((this.campaignDto.creatives[y].creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner && !isDisplayPremiumExist)
                || (this.campaignDto.creatives[y].creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner && !isAbandonedPremiumExist)
                || (this.campaignDto.creatives[y].creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner && !isPpuPremiumExist)) {
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.channelId = eChannelType.Display;
                    creativesDto.quantity = 1;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeGroup = this.campaignDto.creatives[y].creativeGroup;
                    creativesDto.creativeTypeId = eStandardCampaginChannel.Premium_Display_Banner;
                    creativesDto.parentCreativeTypeId = this.campaignDto.creatives[y].creativeTypeId;
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Premium_Display_Banner, eStandardCampaginChannel)
                    for (var x = 0; x < this.premiumBanners.length; x++) {
                        let height = this.premiumBanners[x].split('x')[1];
                        let width = this.premiumBanners[x].split('x')[0];
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = Number(width);
                        creativeAssetDto.height = Number(height);

                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }
            }

            if(this.campaignAddOn.filter(x => x.addOn.name == "Premium Display Banners").length == 0){
                let addOn = {} as ICampaignAddOnDto
                addOn.addOnId = event;
                addOn.campaignId = Constants.GuidEmpty;
                addOn.id = Constants.GuidEmpty;
    
                let campaignAddOns = this.addOn.find(x => x.id == event);
                this.campaignAddOn.push(addOn);
                this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
            }
        }
        else {
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Premium_Display_Banner)
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            
            
        }
    }

    fbHandleChange(event: string) {
        this.IsFacebookAddOnSelected = !this.IsFacebookAddOnSelected;
        if (this.IsFacebookAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed_Video)){
                for(let i=1;i<=(!this.maxNumberOfFlights? 1 : this.maxNumberOfFlights);i++){
                let creativesDto = {} as ICreativeDto;
                creativesDto.creativeAssets = [];
                creativesDto.channelId = eChannelType.Social;
                creativesDto.quantity = 1;
                creativesDto.creativeGroup=i;
                creativesDto.isBuilt = false;
                creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Static_News_Feed_Video
                creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed_Video, eStandardCampaginChannel)
    
                let creativeAssetDto = {} as ICreativeAssetDto;
                creativeAssetDto.id = Constants.GuidEmpty;
                creativeAssetDto.width = 0;
                creativeAssetDto.height = 0;
                creativesDto.creativeAssets.push(creativeAssetDto);
                creativesDto.entityStatus = eEntityStatus.Active;
                this.campaignDto.creatives.push(creativesDto);
                }
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Facebook Video News Feed").length == 0){
                    let addOnId = {} as ICampaignAddOnDto
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event)
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
            if (this.campaignDto.package.name != 'Amplify' && this.campaignDto.package.name != 'Amplify Flex') {
                this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
                this.campaignDto.totalFacebookAddOnAmount+= (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            }
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Facebook_Static_News_Feed_Video);
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalFacebookAddOnAmount -= (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
        }
    }

    abandonedCartAddOnChange(event: string){
        this.IsAbandonedCartAddOnSelected = !this.IsAbandonedCartAddOnSelected;
        if(this.IsAbandonedCartAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner)){
                let creativesDto = {} as ICreativeDto;
                creativesDto.creativeAssets = [];
                creativesDto.channelId = eChannelType.Display;
                creativesDto.quantity = 1;
                creativesDto.creativeGroup = 1;
                creativesDto.isBuilt = false;
                creativesDto.creativeTypeId = eStandardCampaginChannel.Abandoned_Cart_Display_Banner
                creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Abandoned_Cart_Display_Banner, eStandardCampaginChannel)
    
                if (this.campaignService.isDisplayBanner(creativesDto.creativeTypeId)) {
                    for (var element = 0; element < this.standardBanners.length; element++) {
                        let height = this.standardBanners[element].split('x')[1]
                        let width = this.standardBanners[element].split('x')[0]
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = Number(width)
                        creativeAssetDto.height = Number(height)
                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                } 
    
                creativesDto.entityStatus = eEntityStatus.Active;
                this.campaignDto.creatives.push(creativesDto);
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Abandoned Cart Display Banners").length == 0){
                    let addOnId = {} as ICampaignAddOnDto
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event)
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
                
            if (this.campaignDto.package.name != 'Amplify') {
                this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
                this.campaignDto.totalDisplayAddOnAmount+=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
                
            }
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Abandoned_Cart_Display_Banner && !(x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner));
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalDisplayAddOnAmount-=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
    }

    ppuAddOnChange(event: string){
        this.isPpuAddOnSelected = !this.isPpuAddOnSelected;
        if(this.isPpuAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner)){
                let creativesDto = {} as ICreativeDto;
                creativesDto.creativeAssets = [];
                creativesDto.channelId = eChannelType.Display;
                creativesDto.quantity = 1;
                creativesDto.creativeGroup = 1;
                creativesDto.isBuilt = false;
                creativesDto.creativeTypeId = eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner
                creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner, eStandardCampaginChannel)
    
                if (this.campaignService.isDisplayBanner(creativesDto.creativeTypeId)) {
                    for (var element = 0; element < this.standardBanners.length; element++) {
                        let height = this.standardBanners[element].split('x')[1]
                        let width = this.standardBanners[element].split('x')[0]
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = Number(width)
                        creativeAssetDto.height = Number(height)
                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                } 
    
                creativesDto.entityStatus = eEntityStatus.Active;
                this.campaignDto.creatives.push(creativesDto);
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Post Purchase Display Banners").length == 0){
                    let addOnId = {} as ICampaignAddOnDto
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event)
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
            if (this.campaignDto.package.name != 'Amplify') {
                this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
                this.campaignDto.totalDisplayAddOnAmount+=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
                
            }
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner && !(x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner));
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalDisplayAddOnAmount-=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
    }

    inStreamVideoAddOnChange(event: string){
        this.isInStreamVideoAddOnSelected = !this.isInStreamVideoAddOnSelected;
        if(this.isInStreamVideoAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.In_Stream_Video)){
                for(let i=1;i<=(!this.maxNumberOfFlights? 1 : this.maxNumberOfFlights);i++){
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.channelId = eChannelType.Other;
                    creativesDto.quantity = 1;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeGroup = i;
                    creativesDto.creativeTypeId = eStandardCampaginChannel.In_Stream_Video
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.In_Stream_Video, eStandardCampaginChannel)
        
                    creativesDto = this.createNewDetailJson(creativesDto);
        
                    let creativeAssetDto = {} as ICreativeAssetDto;
                    creativeAssetDto.id = Constants.GuidEmpty;
                    creativeAssetDto.width = 0;
                    creativeAssetDto.height = 0;
                    creativesDto.creativeAssets.push(creativeAssetDto);
        
                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Display In-Stream Video").length == 0){
                    let addOnId = {} as ICampaignAddOnDto
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event)
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
            this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalOtherAddOnAmount+=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.In_Stream_Video);
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalOtherAddOnAmount -= (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
        }
    }

    staticNativeVideoAddOnChange(event: string){
        this.isStaticNativeAddOnSelected = !this.isStaticNativeAddOnSelected;
        if(this.isStaticNativeAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.Native_Image)){
                for(let i=1;i<=(!this.maxNumberOfFlights? 1 : this.maxNumberOfFlights);i++){
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.channelId = eChannelType.Other;
                    creativesDto.quantity = 1;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeGroup = i;
        
                    creativesDto = this.createNewDetailJson(creativesDto);
        
                    creativesDto.creativeTypeId = eStandardCampaginChannel.Native_Image;
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Native_Image, eStandardCampaginChannel)
        
                    for (var j = 0; j < 2; j++) {
        
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = 0;
                        creativeAssetDto.height = 0;
                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Static Native").length == 0){
                    let addOnId = {} as ICampaignAddOnDto
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event)
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
            this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalOtherAddOnAmount+=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Native_Image);
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalOtherAddOnAmount -= (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
    }

    facebookDynamicVideoAddOnChange(event: string){
        this.isFacebookDynamicVideoAddOnSelected = !this.isFacebookDynamicVideoAddOnSelected;
        if(this.isFacebookDynamicVideoAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video)){
                for(let i=1;i<=(!this.maxNumberOfFlights? 1 : this.maxNumberOfFlights);i++){
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.channelId = eChannelType.Social;
                    creativesDto.quantity = 1;
                    creativesDto.creativeGroup = i;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video, eStandardCampaginChannel)
        
                    let creativeAssetDto = {} as ICreativeAssetDto;
                    creativeAssetDto.id = Constants.GuidEmpty;
                    creativeAssetDto.width = 0;
                    creativeAssetDto.height = 0;
                    creativesDto.creativeAssets.push(creativeAssetDto);
                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Facebook Dynamic News Feed Video").length == 0){
                    let addOnId = {} as ICampaignAddOnDto
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event)
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
            this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalFacebookAddOnAmount+=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video);
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalFacebookAddOnAmount-=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
    }

    facebookDynamicStaticAddOnChange(event: string){
        this.isFacebookDynamicStaticAddOnSelected = !this.isFacebookDynamicStaticAddOnSelected;
        if(this.isFacebookDynamicStaticAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed)){
                for(let i=1;i<=(!this.maxNumberOfFlights? 1 : this.maxNumberOfFlights);i++){
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.channelId = eChannelType.Social;
                    creativesDto.quantity = 1;
                    creativesDto.creativeGroup = i;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Dynamic_News_Feed;
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Dynamic_News_Feed, eStandardCampaginChannel)
        
                    let creativeAssetDto = {} as ICreativeAssetDto;
                    creativeAssetDto.id = Constants.GuidEmpty;
                    creativeAssetDto.width = 0;
                    creativeAssetDto.height = 0;
                    creativesDto.creativeAssets.push(creativeAssetDto);
                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }  
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Facebook Dynamic News Feed").length == 0){
                    let addOnId = {} as ICampaignAddOnDto;
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event);
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
            this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalFacebookAddOnAmount+=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Facebook_Dynamic_News_Feed);
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalFacebookAddOnAmount-=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
        }
    }

    facebookCarouselAddOnChange(event: string){
        this.isFacebookCarouselAddOnSelected = !this.isFacebookCarouselAddOnSelected;
        if(this.isFacebookCarouselAddOnSelected) {
            if(!this.campaignDto.creatives.some(x=>x.creativeGroup==1 && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel)){
                    for(let i=1;i<=(!this.maxNumberOfFlights? 1 : this.maxNumberOfFlights);i++){
                        let creativesDto = {} as ICreativeDto;
                        creativesDto.creativeAssets = [];
                        creativesDto.channelId = eChannelType.Social;
                        creativesDto.quantity = 1;
                        creativesDto.isBuilt = false;
                        creativesDto.creativeGroup = i;
                        creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel;
                        creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel, eStandardCampaginChannel)
            
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = 0;
                        creativeAssetDto.height = 0;
                        creativesDto.creativeAssets.push(creativeAssetDto);
                        creativesDto.entityStatus = eEntityStatus.Active;
                        this.campaignDto.creatives.push(creativesDto);
                    }
            }
                if(this.campaignAddOn.filter(x => x.addOn.name == "Facebook Dynamic News Feed Carousel").length == 0){
                    let addOnId = {} as ICampaignAddOnDto;
                    addOnId.addOnId = event;
                    addOnId.campaignId = Constants.GuidEmpty;
                    addOnId.id = Constants.GuidEmpty;
                    let campaignAddOns = this.addOn.find(x => x.id == event);
                    this.campaignAddOn.push(addOnId);
                    this.campaignAddOn.find(element => element.addOnId == event).addOn = campaignAddOns;
                }
            this.totalAmount = this.totalAmount + (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalFacebookAddOnAmount+=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
        else {
            this.campaignAddOn = this.campaignAddOn.filter(x => x.addOnId !== event);
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel);
            this.totalAmount = this.totalAmount - (this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            this.campaignDto.totalFacebookAddOnAmount-=(this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? (this.addOn.find(x => x.id == event).price * this.difference_In_Days) : ((this.addOn.find(x => x.id == event).price / 7) * this.difference_In_Days));
            
        }
    }

    selectedCampaignGoalType(event: any, isChangedGoalType: boolean = false) {
        this.campaignController.GetCampaignAddOns().subscribe(x => {
            this.addOn = x;
            this.selectedCampaignGoal = !!event.value ? event.value : event;
            if (this.selectedCampaignGoal == eCampaignGoal.Quick_Promotion) {
                this.addOn = this.addOn.filter(x => x.description == "3");
                this.amplifyAddOn = this.addOn;
            }
            else {
                this.addOn = this.addOn.filter(x => x.description != "3");
                this.amplifyAddOn = this.addOn;
            }

            if (!!this.campaignPackagePreviewComponent)
                this.campaignPackagePreviewComponent.filterPackages(this.selectedCampaignGoal);

            this.campaignGoalId.emit(this.selectedCampaignGoal);

            if (!!this.campaignDto.startDate && isChangedGoalType) {
                this.onChangeStartDate(this.campaignDto.startDate, false);
            }
        });
    }

    onShowPackage(event: any) {

        if (this.packageItem == 'Thrive') {
            this.showPackage = event;
        }
        else {
            if (this.formGroup.value.campaignName == '' || this.formGroup.value.campaignName.trim() == '') {
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign Name field should not be empty.' });
            }
            else if (this.campaignDto.startDate == null || this.campaignDto.endDate == null) {
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign Dates should not be empty.' });
            }
            else {
                this.showPackage = event;
                this.onStepTwo.emit(this.activeIndex = 0);
            }
        }     
    }

    showAddOns(packageDto: IPackageDto) {
    }

    onSelectedPackageItem(event: IBaseCampaignDto) {
        if (this.selectedCampaignGoal == 0) {
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign Goal Type field should be selected.' });
        }

        else if (!!this.campaignDto && !!this.campaignDto.packageId && !!event.package.id && this.campaignDto.packageId == event.package.id && (this.campaignDto.package.name!="Grow" || this.isGrowSocialCampaign == this.isGrowSocialEvent)){
                this.campaignDto.creatives = this.creativesBackup;
                this.IsAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner);
                this.IsFacebookAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed_Video);
                this.IsAbandonedCartAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner);
                this.isPpuAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner);
                this.isInStreamVideoAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.In_Stream_Video);
                this.isStaticNativeAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Native_Image);
                this.isFacebookDynamicVideoAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video);
                this.isFacebookDynamicStaticAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed);
                this.isFacebookCarouselAddOnSelected = this.campaignDto.creatives.some(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel);

                    if (this.campaignDto.package.name != "Amplify Flex")
                        this.campaignDto.totalCreativeFlightsAddOnAmount =  (this.numberOfCreativeFlightsAmplifyFlex-1 > 0 ? 1 : 0) * (this.campaignDto.package.name == "Grow" ? 250 : 500) * (this.difference_In_Days / 7);
                
                    this.numberOfFlightsHelper = this.numberOfCreativeFlightsAmplifyFlex;

                    this.campaignDto.totalFacebookAddOnAmount=0;
                    this.campaignDto.totalDisplayAddOnAmount=0;
                    this.campaignDto.totalOtherAddOnAmount=0;

                    if(this.isInStreamVideoAddOnSelected){
                        this.isInStreamVideoAddOnSelected=!this.isInStreamVideoAddOnSelected;
                        this.inStreamVideoAddOnChange(this.addOn[4].id);
                    }
                    if(this.isStaticNativeAddOnSelected){
                        this.isStaticNativeAddOnSelected=!this.isStaticNativeAddOnSelected;
                        this.staticNativeVideoAddOnChange(this.addOn[5].id);
                    }
                    if(this.isFacebookDynamicVideoAddOnSelected){
                        this.isFacebookDynamicVideoAddOnSelected=!this.isFacebookDynamicVideoAddOnSelected;
                        this.facebookDynamicVideoAddOnChange(this.addOn[6].id);
                    }
                    if(this.isFacebookDynamicStaticAddOnSelected){
                        this.isFacebookDynamicStaticAddOnSelected=!this.isFacebookDynamicStaticAddOnSelected;
                        this.facebookDynamicStaticAddOnChange(this.addOn[7].id);
                    }
                    if(this.isFacebookCarouselAddOnSelected){
                        this.isFacebookCarouselAddOnSelected=!this.isFacebookCarouselAddOnSelected;
                        this.facebookCarouselAddOnChange(this.addOn[8].id);
                    }

                    if(this.campaignDto.package.name == 'Amplify Flex')
                    {
                        this.campaignDto.totalCreativeFlightsAddOnAmount = 0;
                        this.numberOfFlightsHelper = 1;
                    }
                    else
                    {   
                        if(this.IsAbandonedCartAddOnSelected){
                            this.IsAbandonedCartAddOnSelected=!this.IsAbandonedCartAddOnSelected;
                            this.abandonedCartAddOnChange(this.addOn[2].id);
                        }
                        if(this.isPpuAddOnSelected){
                            this.isPpuAddOnSelected=!this.isPpuAddOnSelected;
                            this.ppuAddOnChange(this.addOn[3].id);
                        }
                        if(this.IsAddOnSelected && this.campaignDto.package.name != 'Amplify'){
                            this.handleChange(this.addOn[0].id);
                            this.IsAddOnSelected = !this.IsAddOnSelected;
                            this.changePremiumAddOn(this.addOn[0].id);
                        }
                        
                        if(this.campaignDto.package.name == 'Expand' || this.campaignDto.package.name == 'Social' || this.campaignDto.package.name == 'Grow' || this.campaignDto.package.name == 'Multi channel')
                        {
                            if(this.IsFacebookAddOnSelected){
                                this.IsFacebookAddOnSelected=!this.IsFacebookAddOnSelected;
                                this.fbHandleChange(this.addOn[1].id);
                            }
                            
                        }
                    }
                    this.totalAmount = this.amount + this.campaignDto.totalDisplayAddOnAmount + this.campaignDto.totalFacebookAddOnAmount + this.campaignDto.totalOtherAddOnAmount + this.campaignDto.totalCreativeFlightsAddOnAmount;
            this.selectedPackage = event.package;
            this.packageItem = !!event.package ? event.package.name : '';
            this.campaignGoalLabel = this.campaignGoals.find(x => x.value == this.selectedCampaignGoal).label;
            this.campaignNameLabel = this.formGroup.value.campaignName;
            this.campaignDto.campaignGoalId = this.campaignGoals.find(x => x.value == this.selectedCampaignGoal).value;
            this.campaignDto.name = this.formGroup.value.campaignName;
            // this.isFacebookAddOnVisible = event.package.facebookDynamicNewsFeedVideo;
            // this.isDisplayAddOnVisible = event.package.premiumDisplayBanners;
            // this.isAbandonedCartAddOnVisible = event.package.abandonedCartDisplayCreatives;
            if (!this.showPackage && this.campaignDto.packageId == event.package.id)
                this.onStepTwo.emit(this.activeIndex = 1);

        }

        else {
            this.numberOfCreativeFlightsAmplifyFlex=1;
            this.numberOfFlightsHelper=1;
            this.campaignDto.totalCreativeFlightsAddOnAmount=0;

            this.campaignAddOn = [];
            this.IsAddOnSelected = false;
            this.IsFacebookAddOnSelected = false;
            this.campaignDto.totalDisplayAddOnAmount = 0;
            this.campaignDto.totalFacebookAddOnAmount = 0;
            this.campaignDto.totalOtherAddOnAmount = 0;
            this.totalAmount = this.amount;
            this.campaignDto.creatives = event.creatives;
            this.packageItem = !!event.package ? event.package.name : '';
            this.selectedPackage = event.package;
            this.campaignDto.package = event.package;
            this.campaignGoalLabel = this.campaignGoals.find(x => x.value == this.selectedCampaignGoal).label;
            this.campaignNameLabel = this.formGroup.value.campaignName;
            this.campaignDto.campaignGoalId = this.campaignGoals.find(x => x.value == this.selectedCampaignGoal).value;
            this.campaignDto.name = this.formGroup.value.campaignName;
            this.isFacebookAddOnVisible = !!event.package ? event.package.facebookDynamicNewsFeedVideo : false;
            this.isDisplayAddOnVisible = !!event.package ? event.package.premiumDisplayBanners : false;
            
            if (!this.showPackage)
                this.onStepTwo.emit(this.activeIndex = 1);
        }
         this.maxNumberOfFlights = _.orderBy(this.campaignDto.creatives, 'creativeGroup', 'asc')[this.campaignDto.creatives.length-1]?.creativeGroup;
    }

    onSelectedPackageItemAddOn(event: IPackageDto) {
        if (!!this.campaignDto.endDate && !!this.campaignDto.startDate) {
            this.isGrowSocialEvent = event.facebookDynamicNewsFeedVideo;
            if(!!this.campaignDto.id && !!this.campaignDto.package && this.campaignDto.package.name == "Grow")
                this.isGrowSocialCampaign = this.campaignDto.package.facebookDynamicNewsFeedVideo;
            this.campaignDto.package = event;
            this.difference_In_Days = ((Date.UTC(this.campaignDto.endDate.getUTCFullYear(), this.campaignDto.endDate.getMonth(), this.campaignDto.endDate.getDate()) -
                 Date.UTC(this.campaignDto.startDate.getUTCFullYear(), this.campaignDto.startDate.getMonth(), this.campaignDto.startDate.getDate())) / 86400000)+1;

            if ((this.difference_In_Days / 7) > 1)
                this.unit = "weeks";

            else if ((this.difference_In_Days / 7) <= 1) 
                this.unit = "week";

            this.IsAddOnSelected = false;
            this.IsFacebookAddOnSelected = false;
            this.IsAbandonedCartAddOnSelected = false;
            this.isPpuAddOnSelected = false;
            this.isInStreamVideoAddOnSelected = false;
            this.isStaticNativeAddOnSelected = false;
            this.isFacebookDynamicVideoAddOnSelected = false;
            this.isFacebookDynamicStaticAddOnSelected = false;
            this.isFacebookCarouselAddOnSelected = false;

            this.isFacebookAddOnVisible = event.facebookDynamicNewsFeedVideo;
            this.isDisplayAddOnVisible = event.premiumDisplayBanners;
            this.isAbandonedCartAddOnVisible = event.abandonedCartDisplayCreatives;

            if(event.name == new EnumStringPipe().transform(eHMPCampaignPackages.Expand, eHMPCampaignPackages) || event.name == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify, eHMPCampaignPackages) || event.name == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify_Flex, eHMPCampaignPackages)){
                this.isPpuAddOnVisible = true;
                this.isAbandonedCartAddOnVisible = true;
                this.isInStreamVideoAddOnVisible = true;
                this.isStaticNativeAddOnVisible = true;
                this.isFacebookDynamicVideoAddOnVisible = true;
                this.isFacebookDynamicStaticAddOnVisible = true;
                this.isFacebookCarouselAddOnVisible = true;
            }

            //Grow Social
            if(event.name == new EnumStringPipe().transform(eHMPCampaignPackages.Grow, eHMPCampaignPackages) && this.isGrowSocialEvent){
                this.isPpuAddOnVisible = false;
                this.isAbandonedCartAddOnVisible = false;
                this.isInStreamVideoAddOnVisible = false;
                this.isStaticNativeAddOnVisible = false;
                this.isFacebookDynamicVideoAddOnVisible = true;
                this.isFacebookDynamicStaticAddOnVisible = true;
                this.isFacebookCarouselAddOnVisible = true;
            }

            //Grow Display
            if(event.name == new EnumStringPipe().transform(eHMPCampaignPackages.Grow, eHMPCampaignPackages) && !this.isGrowSocialEvent){
                this.isPpuAddOnVisible = true;
                this.isAbandonedCartAddOnVisible = true;
                this.isInStreamVideoAddOnVisible = true;
                this.isStaticNativeAddOnVisible = true;
                this.isFacebookDynamicVideoAddOnVisible = false;
                this.isFacebookDynamicStaticAddOnVisible = false;
                this.isFacebookCarouselAddOnVisible = false;
            }


            this.formGroup.value.campaignGoal = event.campaignGoalId;
            this.amount = this.formGroup.value.campaignGoal == this._eCampaignGoal.Quick_Promotion ? ((Number(event.price)) * this.difference_In_Days) : ((Number(event.price)) * this.difference_In_Days) / 7;
            this.totalAmount = this.amount;
            if ((event.name == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify, eHMPCampaignPackages) || event.name == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify_Flex, eHMPCampaignPackages)) && this.addOn.length > 0) {
                this.campaignAddOn = [];
                this.addOn.forEach(x => {
                    // if (x.name == "Premium Display Banners") {
                    //     this.handleChange(x.id); } else 
                    if (x.name == "Facebook Video News Feed") {
                        this.fbHandleChange(x.id);
                    }
                });
            }
        }
    }

    onChangeStartDate(selectedDate: Date, isFromDatePicker: boolean = true) {
        if (selectedDate > this.campaignDto.endDate) {
            this.campaignDto.endDate = null;
        }
        let daysToAddMin = this.formGroup.value.campaignGoal == eCampaignGoal.Quick_Promotion ? 2 : 20;
        let daysToAddMax = this.formGroup.value.campaignGoal == eCampaignGoal.Quick_Promotion ? 13 : 83;
        if (isFromDatePicker) {
            this.campaignDto.startDate = selectedDate;
            Functions.dateTimeZoneConversion(this.campaignDto.startDate);
        }
        this.minEndDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + daysToAddMin);
        this.maxEndDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + daysToAddMax);

        if (this.campaignDto.endDate < this.minEndDate || this.campaignDto.endDate > this.maxEndDate)
            this.campaignDto.endDate = null;
    }

    onChangeEndDate(selectedDate: Date) {
        this.campaignDto.endDate = selectedDate;
        Functions.dateTimeZoneConversion(this.campaignDto.endDate); 
    }

    formInit(): void {
        this.formGroup = this.fb.group({
            campaignName: ["", Validators.required],
            campaignGoal: [2, Validators.required],
            campaignStartDate: ["", Validators.required],
            campaignEndDate: ["", Validators.required],
            advertiserId: [""],
            ttdadvertiserId: [""],
            dv360advertiserId: [""],
            facebookAdAccountId: [""],
            facebookPageId: [""],
            instagramId: [""]
        });
    }

    patchFormValue() {
        this.formGroup.patchValue({
            advertiserId: this.currentAccount.advertiserId,
            ttdadvertiserId: this.currentAccount.ttdAdvertiserId,
            dv360advertiserId: this.currentAccount.dV360AdvertiserId,
            campaignGoal: this.campaignDto.campaignGoalId,
            campaignName: this.campaignDto.name,
            facebookAdAccountId: this.campaignDto.facebookAdAccount,
            facebookPageId: this.campaignDto.facebookPageId,
            instagramId: this.campaignDto.instagramId
        });
    }
    initLists() {
        this.campaignGoals = LookUps.getCampaignGoalLu();
        this.campaignGoals.unshift({ value: 0, label: "-" });
    }

    onContinue(next: boolean) {
        if(this.campaignDto.package.name == 'Amplify' || this.campaignDto.package.name == 'Amplify Flex'){
            this.IsAddOnSelected = true;
            this.handleChange(this.addOn[0].id);
        }
        else
            this.handleChange(this.addOn[0].id);
        
        this.campaignDto.campaignAddOns = this.campaignAddOn;

        this.campaignDto.package = this.selectedPackage;
        this.campaignDto.packageId = this.selectedPackage.id;
        this.campaignDto.amount = this.totalAmount;
        this.campaignDto.buildFee = this.campaignDto.amount * 12 / 100;
        this.campaignDto.totalAmount = this.campaignDto.amount + this.campaignDto.buildFee;
        this.campaignDto.advertiserId = !!this.currentAccount ? this.currentAccount.advertiserId : '';
        this.campaignDto.ttdAdvertiserId = !!this.currentAccount ? this.currentAccount.ttdAdvertiserId : '';
        this.campaignDto.dV360AdvertiserId = !!this.currentAccount ? this.currentAccount.dV360AdvertiserId : '';
        if (!this.campaignDto.accountId) {
            this.campaignDto.accountId = this.currentUser.accountId;
            this.campaignDto.accountName = !!this.currentAccount ? this.currentAccount.name : '';
            this.campaignDto.accountTypeId = 'HMP';
            this.campaignDto.account = this.currentAccount;
        }
        this.campaignDto.campaignStatusId = eCampaignStatus.Campaign_Draft;
        this.campaignDto.isEdit = true;
        this.campaignDto.campaignType = eAccountType.HMP;
        this.cardItemService.updatedDataSelection(this.campaignDto);
        Functions.dateTimeZoneConversion(this.campaignDto.startDate);
        Functions.dateTimeZoneConversion(this.campaignDto.endDate);
        this.addCreativeFlighstHandle(this.numberOfCreativeFlightsAmplifyFlex);

        if (next) {
            this.onStepTwo.emit({ activeIndex: this.activeIndex = 2, campaign: this.campaignDto, numberOfCreativeFlightsAmplifyFlex: this.numberOfCreativeFlightsAmplifyFlex });
        }
        else {
            this.pageLoaderService.showPageLoader();
            this.campaignController.SaveCampaign(this.campaignDto).subscribe(x => {
                var gridId = 3; // HMP users
                this.campaignService.setGridIdId(gridId);
                this.pageLoaderService.hidePageLoader();
                this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' });
                setTimeout(() => {
                    this.router.navigate(['/mogo']);
                    this.cardItemService.updatedDataSelection(null);
                }, 1000);
                
            },
            error => {
                this.pageLoaderService.hidePageLoader();
                for (const property in error.error.Errors) {
                    error.error.Errors[property].forEach(e => {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: e });
                    });
                }
            });
        }
    }

    addCreativeFlighstHandle(number: number){
        let maxNumberOfFlights = _.orderBy(this.campaignDto.creatives, 'creativeGroup', 'asc')[this.campaignDto.creatives.length-1].creativeGroup;
        this.campaignDto.creatives.forEach(cr => {
            cr.flightEndDate = null;
            cr.flightStartDate = null;
        });
        
        if(number > maxNumberOfFlights){
            for (let index = 0; index < number - maxNumberOfFlights; index++) {

                if(this.selectedPackage.name == new EnumStringPipe().transform(eHMPCampaignPackages.Grow, eHMPCampaignPackages) && this.selectedPackage.facebookDynamicNewsFeedVideo == true){
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.bannerDimensions = [""];
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed, eStandardCampaginChannel)
                    creativesDto.channelId = eChannelType.Social;
                    creativesDto.quantity = 1;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Static_News_Feed;
                    creativesDto.creativeGroup = maxNumberOfFlights + index + 1;
                    for (var element = 0; element < 2; element++) {
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = 0;
                        creativeAssetDto.height = 0;
                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                    
                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }
        
                else if(this.selectedPackage.name == new EnumStringPipe().transform(eHMPCampaignPackages.Grow, eHMPCampaignPackages) && !this.selectedPackage.facebookDynamicNewsFeedVideo == true){
                    let creativesDtoDisplay = {} as ICreativeDto;
                    creativesDtoDisplay.creativeAssets = [];
                    creativesDtoDisplay.bannerDimensions = [""];
                    creativesDtoDisplay.name = new EnumStringPipe().transform(eStandardCampaginChannel.Standard_Display_Banner, eStandardCampaginChannel)
                    creativesDtoDisplay.channelId = eChannelType.Display;
                    creativesDtoDisplay.quantity = 1;
                    creativesDtoDisplay.isBuilt = false;
                    creativesDtoDisplay.creativeTypeId = eStandardCampaginChannel.Standard_Display_Banner;
                    creativesDtoDisplay.creativeGroup = maxNumberOfFlights + index + 1;
        
                    if (this.campaignService.isDisplayBanner(creativesDtoDisplay.creativeTypeId)) {
                        for (var element = 0; element < this.standardBanners.length; element++) {
                            let height = this.standardBanners[element].split('x')[1]
                            let width = this.standardBanners[element].split('x')[0]
                            let creativeAssetDtoDisplay = {} as ICreativeAssetDto;
                            creativeAssetDtoDisplay.id = Constants.GuidEmpty;
                            creativeAssetDtoDisplay.width = Number(width)
                            creativeAssetDtoDisplay.height = Number(height)
                            creativesDtoDisplay.creativeAssets.push(creativeAssetDtoDisplay);
                        }
                    } 
        
                    creativesDtoDisplay.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDtoDisplay);

                }
        
                else {
                    let facebook_static_news_feed = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Facebook_Static_News_Feed, eChannelType.Social);
                    
                    for (var i = 0; i < 2; i++) {
                        let creativeAssetDto = this.addCreativeAsset();
                        facebook_static_news_feed.creativeAssets.push(creativeAssetDto);
                    }
                    facebook_static_news_feed.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(facebook_static_news_feed);
        
                    let standard_display_banner = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Standard_Display_Banner, eChannelType.Display);
        
                    if (this.campaignService.isDisplayBanner(standard_display_banner.creativeTypeId)) {
                        for (var element = 0; element < this.standardBanners.length; element++) {
                            let height = this.standardBanners[element].split('x')[1]
                            let width = this.standardBanners[element].split('x')[0]
                            let creativeAssetDtoDisplay = {} as ICreativeAssetDto;
                            creativeAssetDtoDisplay.id = Constants.GuidEmpty;
                            creativeAssetDtoDisplay.width = Number(width)
                            creativeAssetDtoDisplay.height = Number(height)
                            standard_display_banner.creativeAssets.push(creativeAssetDtoDisplay);
                        }
                    } 
                    standard_display_banner.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(standard_display_banner);
                }

                if(this.campaignDto.creatives.find(x => x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner)) {
                    let creative = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Premium_Display_Banner, eChannelType.Display);
                    for(let i=0; i< this.premiumBanners.length; i++){
                        let height = this.premiumBanners[i].split('x')[1];
                        let width = this.premiumBanners[i].split('x')[0];
                        let creativeAssetDtoDisplay = {} as ICreativeAssetDto;
                        creativeAssetDtoDisplay.id = Constants.GuidEmpty;
                        creativeAssetDtoDisplay.width = Number(width)
                        creativeAssetDtoDisplay.height = Number(height)
                        creative.creativeAssets.push(creativeAssetDtoDisplay);
                    }
                    if(this.campaignDto.account.isCampaignCreation)
                        creative.parentCreativeTypeId=eStandardCampaginChannel.Standard_Display_Banner;
                    creative.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creative);
                }

                if(this.campaignDto.creatives.find(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed_Video)) {
                    let creative = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Facebook_Static_News_Feed_Video, eChannelType.Social);

                    let creativeAssetDto = this.addCreativeAsset();
                    creative.creativeAssets.push(creativeAssetDto);

                    creative.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creative);
                }

                if(this.campaignDto.creatives.find(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed)) {
                    let creative = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Facebook_Dynamic_News_Feed, eChannelType.Social);

                    let creativeAssetDto = this.addCreativeAsset();
                    creative.creativeAssets.push(creativeAssetDto);
                    creative.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creative);
                }

                if(this.campaignDto.creatives.find(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video)) {
                    let creative = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video, eChannelType.Social);

                    let creativeAssetDto = this.addCreativeAsset();
                    creative.creativeAssets.push(creativeAssetDto);
                    creative.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creative);
                }

                if(this.campaignDto.creatives.find(x => x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel)) {
                    let creative = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel, eChannelType.Social);

                    let creativeAssetDto = this.addCreativeAsset();
                    creative.creativeAssets.push(creativeAssetDto);
                    creative.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creative);
                }

                if(this.campaignDto.creatives.find(x => x.creativeTypeId == eStandardCampaginChannel.Native_Image)) {
                    let creative = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.Native_Image, eChannelType.Other);

                    creative = this.createNewDetailJson(creative);

                    for (var i = 0; i < 2; i++) {
                        let creativeAssetDto = this.addCreativeAsset();
                        creative.creativeAssets.push(creativeAssetDto);
                    }
                    creative.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creative);
                } 

                if(this.campaignDto.creatives.find(x => x.creativeTypeId == eStandardCampaginChannel.In_Stream_Video)) {
                    let creative = this.addNewCreative(maxNumberOfFlights,index, eStandardCampaginChannel.In_Stream_Video, eChannelType.Other);

                    creative = this.createNewDetailJson(creative);
                    
                    let creativeAssetDto = this.addCreativeAsset();
                    creative.creativeAssets.push(creativeAssetDto);
                    creative.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creative);
                }
            
            }
        }

        else{
            this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeGroup <= number);
        }
    }

    CreateCreativesAndAssets(addOn, creativeGroup: number) {
        if (addOn.length > 0)
            addOn.forEach(element => {
                if (element.name == "Premium Display Banners") {
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.bannerDimensions = [""];
                    creativesDto.channelId = eChannelType.Display;
                    creativesDto.quantity = 1;
                    creativesDto.isBuilt = false;
                    creativesDto.parentCreativeTypeId = eStandardCampaginChannel.Standard_Display_Banner;
                    creativesDto.creativeGroup = creativeGroup;
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Premium_Display_Banner, eStandardCampaginChannel);
                    creativesDto.creativeTypeId = eStandardCampaginChannel.Premium_Display_Banner;

                    for (var x = 0; x < this.premiumBanners.length; x++) {
                        let height = this.premiumBanners[x].split('x')[1];
                        let width = this.premiumBanners[x].split('x')[0];
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = Number(width);
                        creativeAssetDto.height = Number(height);

                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }

                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }
                else if (element.name == "Facebook Video News Feed") {
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.bannerDimensions = [""];
                    creativesDto.channelId = eChannelType.Social
                    creativesDto.quantity = 1;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeGroup = creativeGroup;
                    creativesDto.parentCreativeTypeId = eStandardCampaginChannel.Facebook_Static_News_Feed;
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed_Video, eStandardCampaginChannel)
                    creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Static_News_Feed_Video;
                    let creativeAssetDto = {} as ICreativeAssetDto;
                    creativeAssetDto.id = Constants.GuidEmpty;
                    creativeAssetDto.width = 0;
                    creativeAssetDto.height = 0;
                    creativesDto.creativeAssets.push(creativeAssetDto);
                    creativesDto.entityStatus = eEntityStatus.Active;
                    this.campaignDto.creatives.push(creativesDto);
                }
            });
    }

    handleNumberOfFlights(event: any){
        if(this.campaignDto.package.name != 'Amplify Flex')
        {
            if(event==2 && this.numberOfFlightsHelper == 1)
            {
                this.totalAmount += (this.campaignDto.package.name == "Grow" ? 250 : 500) * (this.difference_In_Days  / 7);
                this.campaignDto.totalCreativeFlightsAddOnAmount = (this.campaignDto.package.name == "Grow" ? 250 : 500) * (this.difference_In_Days  / 7);
                this.numberOfFlightsHelper = this.numberOfCreativeFlightsAmplifyFlex;
            }
            else if(event==1 && this.numberOfFlightsHelper > 1 && this.campaignDto.totalCreativeFlightsAddOnAmount > 0)
            {
                this.totalAmount -= this.campaignDto.totalCreativeFlightsAddOnAmount;
                this.campaignDto.totalCreativeFlightsAddOnAmount = 0;
                this.numberOfFlightsHelper = this.numberOfCreativeFlightsAmplifyFlex;
            }
        }

        this.campaignDto.creatives = this.campaignDto.creatives.filter(x => x.creativeGroup <= this.numberOfCreativeFlightsAmplifyFlex);
    }

    addNewCreative(maxNumberOfFlights: number, index: number, campaignChannel: eStandardCampaginChannel, channelId: eChannelType ){
        let creativesDto = {} as ICreativeDto;
        creativesDto.creativeAssets = [];
        creativesDto.bannerDimensions = [""];
        creativesDto.name = new EnumStringPipe().transform(campaignChannel, eStandardCampaginChannel)
        creativesDto.channelId = channelId;
        creativesDto.creativeTypeId = campaignChannel;
        creativesDto.isBuilt = false;
        creativesDto.quantity = 1;
        creativesDto.creativeGroup = maxNumberOfFlights + index + 1;
        
        return creativesDto;
    }

    createNewDetailJson(creativesDto: ICreativeDto){
        creativesDto.detailJson = {} as IDetailJson;
        creativesDto.detailJson.headline = '';
        creativesDto.detailJson.bodyText = [];
        creativesDto.detailJson.bodyText.push('');
        creativesDto.detailJson.linkDescription = '';
        creativesDto.detailJson.landingPageUrl = '';
        creativesDto.detailJson.facebookPageUrl = '';
        creativesDto.detailJson.campaignTitle = '';
        creativesDto.detailJson.advertiser = '';
        creativesDto.detailJson.companyName = '';
        creativesDto.detailJson.shortTitle = '';
        creativesDto.detailJson.longTitle = '';
        creativesDto.detailJson.shortDescription = '';
        creativesDto.detailJson.longDescription = '';
        creativesDto.detailJson.callToAction = '';
        creativesDto.detailJson.headline2 = '';
        creativesDto.detailJson.description = '';
        creativesDto.detailJson.path1 = '';
        creativesDto.detailJson.path2 = '';
        creativesDto.detailJson.displayUrl = '';
        creativesDto.detailJson.adGroup = '';
        creativesDto.detailJson.postText = '';
        creativesDto.detailJson.privacyPolicy = '';
        creativesDto.detailJson.customDisclaimer = '';
        creativesDto.detailJson.copyOption1 = [];
        creativesDto.detailJson.copyOption1.push('');
        creativesDto.detailJson.copyOption2 = '';
        creativesDto.detailJson.buttonText = '';
        creativesDto.detailJson.destinationUrl = '';

        return creativesDto;
    }

    addCreativeAsset(){
        let creativeAssetDto = {} as ICreativeAssetDto;
        creativeAssetDto.id = Constants.GuidEmpty;
        creativeAssetDto.width = 0;
        creativeAssetDto.height = 0;
        return creativeAssetDto;
    }

    cancel() {
        this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Now, you will be redirected to Home Page.' });
        setTimeout(
            () => {
                var gridId = 3; // HMP users
                this.campaignService.setGridIdId(gridId);
                this.router.navigate(['mogo']),
                    1000
            }
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
