import { OnInit, OnDestroy, Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@Workspace/auth';
import { Constants } from '@Workspace/common';
import { PageLoaderService } from '@Workspace/services';
import { ICampaignExternalIdsDto} from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs/internal/operators/take';
@Component({
    selector: 'campaign-report-external-ids',
    templateUrl: './campaign-report-external-ids.component.html',
    styleUrls: ['./campaign-report-external-ids.component.scss']
})
export class CampaignReportExternalIdsComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        private authService: AuthService,
        protected fb: UntypedFormBuilder,
        private pageLoader: PageLoaderService,
        private messageService: MessageService
    ) {}

    @Input() isOASCampaign: boolean;
    @Input() isLearfieldCampaign: boolean;
    @Input() baseCampaignId: string;
    @Input() dealNumber: string;
    @Input() procedureCampaignId: string;
    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    externalIds: ICampaignExternalIdsDto = {} as ICampaignExternalIdsDto;

    GCM_Campaign_ID: number;
    GAM_Campaign_ID: number;
    DV360_Campaign_ID: number;
    TTD_Campaign_ID: string;

    GCM_Campaign_ID_External: number;
    GAM_Order_ID_External: number;
    GAM_Line_Item_ID_External: string;
    DV360_Campaign_ID_External: number;
    TTD_Campaign_ID_External: string;

    overrideDV360: boolean = false;
    overrideGCM: boolean = false;
    overrideTTD: boolean = false;
    overrideGAMLineItem: boolean = false;
    overrideGAMOrder: boolean = false;

    dealNumberExternal: string = null;
    idExternal: string = null;

    ngOnInit() {
        this.GCM_Campaign_ID = this.procedureCampaignId ? JSON.parse(this.procedureCampaignId)["GCM_Campaign_ID"]: null;
        this.GAM_Campaign_ID = this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["GAM"] ? parseInt(JSON.parse(this.procedureCampaignId)["GAM"]["campaign_id"]) : null;
        this.DV360_Campaign_ID = this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["DV360"] && JSON.parse(this.procedureCampaignId)["DV360"]["insertion_order_id"]
            ? parseInt(JSON.parse(this.procedureCampaignId)["DV360"]["insertion_order_id"]) : null;
        this.TTD_Campaign_ID = this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["TTD"] && JSON.parse(this.procedureCampaignId)["TTD"]["campaign_id"] 
            ? JSON.parse(this.procedureCampaignId)["TTD"]["campaign_id"] : null;

        this.campaignController
            .GetCampaignExternalIds(this.baseCampaignId)
            .pipe(take(1))
            .subscribe(
                result => {
                    if(result != null){
                        this.idExternal = result.id;
                        this.dealNumberExternal = result.dealNumber;
                        this.GCM_Campaign_ID_External = result.gcmCampaignId;
                        this.DV360_Campaign_ID_External = result.dV360CampaignId;
                        this.TTD_Campaign_ID_External = result.ttdCampaignId;
                        this.GAM_Line_Item_ID_External = result.gamLineItemId;
                        this.GAM_Order_ID_External = result.gamOrderId;

                        if(!!this.GCM_Campaign_ID_External)
                            this.overrideGCM = true;
                        if(!!this.DV360_Campaign_ID_External)
                            this.overrideDV360 = true;
                        if(!!this.TTD_Campaign_ID_External)
                            this.overrideTTD = true;
                        if(!!this.GAM_Line_Item_ID_External)
                            this.overrideGAMLineItem = true;
                        if(!!this.GAM_Order_ID_External)
                            this.overrideGAMOrder = true;
                    }
                    else{
                        this.idExternal = Constants.GuidEmpty;
                        this.dealNumberExternal = this.dealNumber;
                    }
                },
                error => {
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong with loading External IDs.' });
                }
            );
    }

    ngOnDestroy(): void {}

    save() {
        this.pageLoader.showPageLoader();

        this.externalIds.id = this.idExternal;
        this.externalIds.baseCampaignId = this.baseCampaignId;
        this.externalIds.dealNumber = this.dealNumberExternal;
        this.externalIds.dV360CampaignId = this.DV360_Campaign_ID_External;
        this.externalIds.ttdCampaignId = this.TTD_Campaign_ID_External;
        this.externalIds.gcmCampaignId = this.GCM_Campaign_ID_External;
        this.externalIds.gamLineItemId = this.GAM_Line_Item_ID_External;
        this.externalIds.gamOrderId = this.GAM_Order_ID_External;

        const dialogResult = {
            gcmCampaignId : this.externalIds.gcmCampaignId,
            gamCampaignId : this.externalIds.gamOrderId,
            dV360CampaignId : this.externalIds.dV360CampaignId,
            ttdCampaignId : this.externalIds.ttdCampaignId,
        };

        this.campaignController
            .SetCampaignExternalIds(this.externalIds)
            .pipe(take(1))
            .subscribe(
                result => {
                    this.pageLoader.hidePageLoader();
                    this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'External IDs are saved.' });
                    this.onSave.emit(dialogResult);
                },
                error => {
                    this.pageLoader.hidePageLoader();
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong with saving External IDs.' });
                }
            );
    }

    changeStatus(event, externalId: string){
        if(event.checked == false){
            if(externalId == 'dv360')
                this.DV360_Campaign_ID_External = null;
            else if(externalId == 'ttd')
                this.TTD_Campaign_ID_External = null;
            else if(externalId == 'gcm')
                this.GCM_Campaign_ID_External = null;
            else if(externalId == 'gam_line_item')
                this.GAM_Line_Item_ID_External = null;
            else if(externalId == 'gam_order')
                this.GAM_Order_ID_External = null;
        }
    }
}
