    <div class="row mb-3" *ngIf="baseCampaign.campaignInventoryItems[0].inventoryItemOAS.inventoryType == enumInventoryTypeOAS.Page_Takeover">
        <div class="col-2">
            <label class="required">
                Page Template
            </label>
        </div>

        <div class="col-3">
            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" [(ngModel)]="keyValuePairPageTemplateId" [options]="keyValuePairsFriendlyValuePageTemplate" (onChange)="save()" [disabled]="disabled"></p-dropdown>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-2">
            <label class="required">
                Section
            </label>
        </div>

        <div class="col-3">
            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" [(ngModel)]="keyValuePairSectionId" [options]="keyValuePairsFriendlyValueSection" (onChange)="save()" [disabled]="disabled"></p-dropdown>
        </div>
    </div>
