<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>{{header}}</p-header>


    <!-- <ng-template> -->
    <div *ngIf="campaignType == enumAccountType.FAN365 || campaignType == enumAccountType.OAS || header!='Advertiser'" class="mb-2 mt-2 row align-items-center">
        <div class="col-md-5 font-weight-bold">
            {{header =='Advertiser'? (campaignType == enumAccountType.FAN365 || campaignType == enumAccountType.OAS ? 'GCM Advertiser ID' : 'Advertiser ID' ): 'Landing Page Url'}}
        </div>
        <div class="col-md-6">
            <input *ngIf="header!='Advertiser'" type="text" [(ngModel)]="landingPageUrl" (ngModelChange)="validateLandingPage()">
            <input *ngIf="header =='Advertiser'" type="number"  (ngModelChange)="onChangeGCMAdvertiserId()" [(ngModel)]="advertiserId">
        </div>
    </div>
    <span *ngIf="campaignType != enumAccountType.FAN365 && campaignType != enumAccountType.OAS && campaignType != enumAccountType.Social && header =='Advertiser'">
        <div class="mb-2 row align-items-center">
            <div class="col-md-5 font-weight-bold">
                Advertiser Name
            </div>
            <div class="col-md-6">
                <input type="text" [(ngModel)]="advertiserName">
            </div>
        </div>
    </span>
    <div *ngIf="header =='Advertiser' && campaignType == enumAccountType.FAN365" class="mb-2 mt-2 row align-items-center">
        <div class="col-md-5 font-weight-bold">
            TTD Advertiser ID
        </div>
        <div class="col-md-6">
            <input type="text" (ngModelChange)="onChangeTTDAdvertiserId()" [(ngModel)]="ttdadvertiserId">
        </div>
    </div>
    <div *ngIf="header =='Advertiser' && campaignType == enumAccountType.FAN365" class="mb-2 mt-2 row align-items-center">
        <div class="col-md-5 font-weight-bold">
            DV360 Advertiser ID
        </div>
        <div class="col-md-6">
            <input type="text" (ngModelChange)="onChangeDV360AdvertiserId()" [(ngModel)]="dv360advertiserId">
        </div>
    </div>
    <div *ngIf="header =='Advertiser' && campaignType == enumAccountType.OAS" class="mb-2 mt-2 row align-items-center">
        <div class="col-md-5 font-weight-bold">
            GAM Advertiser ID
        </div>
        <div class="col-md-6">
            <input type="text" (ngModelChange)="onChangeGAMAdvertiserId()" [(ngModel)]="gamadvertiserId">
        </div>
    </div>
    <div *ngIf="!canSave" class=" col-md-12 mb-2 mt-2 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Please enter a valid landing page.</span>
    </div>

    <div *ngIf="header =='Advertiser'" class="alert alert-warning text-center" style="min-height: 3em;">
        By changing Advertisers you are changing them only for this campaign.
    </div>


    <!-- </ng-template> -->


    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" pButton class="px-3 p-button-primary" [disabled]='!canSave' label="Save"></button>
        </div>
    </p-footer>
</p-dialog>