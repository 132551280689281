import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MenuItem, SelectItem, TreeNode, MessageService } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eStandardCampaginChannel, eChannel, eChannelType, eFileType, eUserRole } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FileUpload } from '../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { componentFadeIn } from '../../../route-animations';
import { Subscription } from 'rxjs/internal/Subscription';
import { FileUploadService } from '../../../services/file-upload.service';
import { Functions } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'campaign-review',
    templateUrl: './campaign-review.component.html',
    styleUrls: ['./campaign-review.component.scss'],
    animations: [componentFadeIn]
})
export class CampaignReviewComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,

    ) { }

    @Output() onStepTwo: EventEmitter<any> = new EventEmitter();
    @Input() isApprove: boolean;

    creativeAssetsDto: ICreativeAssetDto = {} as ICreativeAssetDto;
    urls = [];
    borders = [];
    test: any = 'png,jpg,jpeg';
    campaignId: string;

    border: boolean = false;
    border1: boolean = false;
    issues: number = 0;
    approved: number = 0;
    baseCampaign: IBaseCampaignDto;
    showCards = false;
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;

    ngUnsubscribe = new Subject();

    displayShow: boolean = false;
    facebookShow: boolean = false;

    facebookHeadline: number = 0;

    activeIndex: number = 0;
    creativesSelected: string;
    inventoryItems: string[];
    userType: any;

    subscription: Subscription;
    browserRefresh = false;

    facebookStaticCreative: ICreativeDto[];
    facebookDynamicCreative: ICreativeDto[];
    facebookDynamicVideoCreative: ICreativeDto[];
    facebookDynamicCarouselCreative: ICreativeDto[];
    standardDisplayCreative: ICreativeDto[];
    premiumDisplayCreative: ICreativeDto[];
    cartDisplayBannerCreative: ICreativeDto[];
    ppuDisplayBannerCreative: ICreativeDto[];
    spotifyVideoCreative: ICreativeDto[];
    spotifyAudioCreative: ICreativeDto[];
    inStreamVideoVideoCreative: ICreativeDto[];
    googleAdWorkCreative: ICreativeDto[];
    nativeVideoCreative: ICreativeDto[];
    nativeImageCreative: ICreativeDto[];

    currentUser = this.authService.userSettings;

    isShowDialog: boolean = false;
    displayPicture: number = 0;


    ngOnInit() {
        this.activatedRoute.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                this.campaignId = params.slug;
            });

        let filter = `?$filter=Id eq ${this.campaignId}`;
        this.campaignController.GetAllCampaigns(filter).subscribe(data => {
            if (data.totalCount > 0) {
                this.baseCampaign = data.data[0];
                // this.cardItemService.updatedDataSelection(this.baseCampaign);
                this.showCards = true;

                this.isApprove = this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Uploaded && (this.currentUser.userRole == eUserRole.Client_Solution || this.currentUser.userRole == eUserRole.Associate);

                if (this.baseCampaign.creatives.length > 0) {

                    this.campaignController.GetFilesFromAzure(this.baseCampaign)
                        .subscribe(
                            result => {
                                this.baseCampaign = result;

                                this.standardDisplayCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner);
                                this.premiumDisplayCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner);
                                this.cartDisplayBannerCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner);
                                this.ppuDisplayBannerCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner);

                                this.facebookStaticCreative = this.baseCampaign.creatives != null ? this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) : null;
                                this.facebookDynamicCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed);
                                this.facebookDynamicVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video);
                                this.facebookDynamicCarouselCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel);

                                this.spotifyVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Spotify_Video);
                                this.spotifyAudioCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Spotify_Audio);
                                this.inStreamVideoVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.In_Stream_Video);
                                this.googleAdWorkCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Google_AdWords);
                                this.nativeVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Native_Video);
                                this.nativeImageCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Native_Image);
                            }


                        );
                }

            }
        },
        error => {
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
        });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        // window.removeEventListener('beforeunload', this.eventFunction);
    }

    onkeyup(headline, linkDescription, landingPage, bodyText, facebookPage) {
        this.facebookHeadline =
            (headline ? 1 : 0) +
            (linkDescription ? 1 : 0) +
            (landingPage ? 1 : 0) +
            (bodyText ? 1 : 0) +
            (facebookPage ? 1 : 0);
    }

    save() {
        this.baseCampaign.campaignStatusId = eCampaignStatus.Campaign_Draft;

        this.campaignController
            .SaveCampaign(this.baseCampaign)
            .subscribe(data => {
            });

        const blobToFile = (blob: Blob, fileName: string): File => {
            let file: any = blob;
            file.lastModifiedDate = Date.now();
            file.name = fileName;

            return <File>file;
        }

        //this.fileUploadService.UploadCreativeAssets()
        let payload = { uploads: [] } as IUploadPayloadModelDto;
        this.baseCampaign.creatives.forEach(creative => {
            creative.creativeAssets.forEach(asset => {
                asset.isFromReviewPage = true;
                let blob = new Blob([asset.fileWrapper]);
                let file = blobToFile(blob, asset.fileName);
                // asset.isValid = true;
                let upload = { detailsJson: JSON.stringify(asset), campaignId: this.baseCampaign.id, fileTypeId: creative.creativeTypeId, fileExtension: asset.fileType, fileName: asset.id, pathName: `${this.baseCampaign.id}/${creative.id}`, file: file } as IUpload;
                payload.uploads.push(upload);
            });
        });

        this.fileUploadService.UploadCreativeAssets(payload, true).subscribe(x => {
            // SaveCampaign(this.campaignDto).subscribe(x => {
            this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Now, you will be redirected to Home Page.' });

            setTimeout(
                () => this.router.navigate(['mogo']),
                3500
            );

        });

    }

    editEvent() { }

    myDialog() {
        this.isShowDialog = true;

    }

    nextPicture() {
        if (this.displayPicture < this.urls.length - 1)
            this.displayPicture = this.displayPicture + 1;

    }

    previuosPicture() {
        if (this.displayPicture > 0)
            this.displayPicture = this.displayPicture - 1;

    }
}
