<p-dialog [modal]="true" [(visible)]="isVisible" (onHide)="cancel()">
    <p-header *ngIf="step != 3" >Transfer Data</p-header>
    <p-header *ngIf="step == 3" >Delete Data</p-header>

    <div *ngIf="step == 1">

        <div class="text-muted" *ngIf="isGridEmpty" style="text-align: center; padding-top: 5em; padding-bottom: 5em;">
            <strong>There aren't any campaigns in assets pending that can take in campaign data for the deal number selected.</strong>
        </div>

        <div class="row" *ngIf="!isGridEmpty">
            <h2 class="col-6 text-muted mb-3 mt-2">Select Campaign to Transfer Data</h2>
            <div class="col-6">
                <div class="row mb-3">
                    <div class="col-6 offset-6">
                        <div class="p-inputgroup w-100">
                            <input class="w-100" type="text" pInputText [(ngModel)]="searchParameter" placeholder="Search" (keyup.enter)="search()" style="border-right: 0;" />
                            <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="search()"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <rc-grid [options]="options" [paginator]="true" [isCurentPageInputEnabled]="true" (onBeforeDataLoad)="setParameters($event)" [rowsPerPageOptions]="[10]"> 
                    <ng-template pTemplate="header" let-item>
                        <tr>
                            <th *ngFor="let column of options?.columns" [pSortableColumn]="column.value"
                                [ngStyle]="{'width':column.width}" class="p-3">
                                {{column.label}}
                                <p-sortIcon *ngIf="column.value != 'radioButton'" [field]="column.value"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        <tr  [ngClass]="checkValue(rowData) ? 'selectedRow' : ''">
                            <td>
                                <input class="p-radiobutton" [value]="rowData" [(ngModel)]="selectedCampaign" type="radio">
                            </td>

                            <td>
                                <a class="d-block" (click)="updateCampaignService(rowData)" truncateText [pTooltip]="rowData.name" tooltipPosition="top">
                                    <span>
                                        {{ rowData.name }}
                                    </span>
                                </a>

                            </td>

                            <td>
                                <span>{{ rowData.dealId }}</span>
                            </td>

                            <td>{{ rowData.impressions !=null ? (rowData.impressions | number) :0 }}</td>
                            
                            <td>{{ rowData.campaignType == enumAccountType.Social ? "Social" : getCampaignChannels(rowData)}}</td>
                        </tr>

                    </ng-template>
                </rc-grid>
            </div>
        </div>
    </div>

    <div *ngIf="step == 2">
        <div class="display-3 mb-4 mt-4">
            Are you sure you want to transfer data to this campaign?
        </div>

        <p class="mb-4"><strong>{{ selectedCampaign.name }} </strong> ({{ selectedCampaign.dealId }}) {{ selectedCampaign.impressions !=null ? (selectedCampaign.impressions | number) : 0 }} | {{ selectedCampaign.campaignType == enumAccountType.Social ? "Social" : (selectedCampaign.campaignType == enumAccountType.Content ? "Content" : getCampaignChannels(selectedCampaign)) }}</p>
    
    </div>

    <div *ngIf="step == 3">
        <div class="display-3 mb-4 mt-4">
            Do you want to delete original campaign?
        </div>

        <p class="mb-4"><strong>{{ selectedCampaign.name }} </strong> ({{ selectedCampaign.dealId }}) {{ selectedCampaign.impressions !=null ? (selectedCampaign.impressions | number) : 0 }} | {{ selectedCampaign.campaignType == enumAccountType.Social ? "Social" : (selectedCampaign.campaignType == enumAccountType.Content ? "Content" : getCampaignChannels(selectedCampaign)) }}</p>
    
    </div>


    <p-footer  *ngIf="!isGridEmpty">
        <div class="mb-3 d-flex justify-content-end" *ngIf="step == 1" >
            <button (click)="cancel()" pButton class="px-3 flat-btn pull-left " label="Cancel"></button>
            <button pButton (click)="save()" [disabled]="!selectedCampaign" class="px-3 flat-btn pull-left p-button-primary" label="Save"></button>
        </div>

        <div class="mb-3 d-flex justify-content-end" *ngIf="step == 2" >
            <button (click)="transferData(false)" pButton class="px-3 flat-btn pull-left " label="No"></button>
            <button pButton (click)="transferData(true)" class="px-3 flat-btn pull-left p-button-primary" label="Yes"></button>
        </div>

        <div class="mb-3 d-flex justify-content-end" *ngIf="step == 3" >
            <button (click)="deleteData(false)" pButton class="px-3 flat-btn pull-left " label="No"></button>
            <button pButton (click)="deleteData(true)" class="px-3 flat-btn pull-left p-button-primary" label="Yes"></button>
        </div>

    </p-footer>

    <p-footer *ngIf="isGridEmpty">
        <div class="mb-3 d-flex justify-content-end">
            <button (click)="cancel()" pButton class="px-3 flat-btn pull-left " label="Close"></button>
        </div>
    </p-footer>

</p-dialog>