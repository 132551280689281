import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard, AuthGuard } from '@Workspace/guards';
import { SignInPageComponent } from './modules/mogo/pages/sign-in/sign-in.page';
import { SignupPageComponent } from './modules/mogo/pages/sign-up/sign-up.page';


const routes: Routes = [

    {
        path: '',
        redirectTo: 'mogo',
        pathMatch: 'full'
    },
    {
        path: 'sign-in',
        component: SignInPageComponent
    },
    {
        path: 'reset-password',
        component: SignInPageComponent,
        children: [
            {
                path: ':guid',
                component: SignInPageComponent
            }
        ]
    },
    {
        path: 'recover-password',
        component: SignInPageComponent
    },
    {
        path: 'sign-up',
        component: SignupPageComponent
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../app/modules/dashboard/dashboard.module').then(_ => _.DashboardModule)
    },
    {
        path: 'mogo',
        loadChildren: () => import('../app/modules/mogo/mogo.module').then(_ => _.MogoModule),
        canLoad: [LoggedInGuard],
        canActivateChild: [LoggedInGuard]
    },
    {
        path: 'fan365',
        loadChildren: () => import('../app/modules/mogo/mogo.module').then(_ => _.MogoModule),
        // canLoad: [LoggedInGuard],
        // canActivateChild: [LoggedInGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
