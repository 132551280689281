<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>{{header}}</p-header>
    
    <div class="row mb dialog-content">
        <div class="col-4">
            <label>
                Select contact: 
            </label>
        </div>

        <div class="col-8">
            <p-dropdown [options]="usersOptions" [(ngModel)]="selectedUser" (onChange)="onChange()" autoWidth="false" [style]="{'width':'100%'}" scrollHeight="100px"></p-dropdown>
        </div>
        <div *ngIf="!!contactInfo && email == null" class="col-md-12 d-flex align-items-center" style="min-height: 3em;">
            <span class="col-md-12 invalid-message-box px-2 py-2">{{contactInfo}}</span>
        </div>
    </div>

    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>  
</p-dialog>