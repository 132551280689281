import { CampaignController } from '@Workspace/_generated/services';
import { Constants } from '@Workspace/common';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'service-agreement',
    templateUrl: './service-agreement.component.html',
    styleUrls: ['./service-agreement.component.scss']
})
export class ServiceAgreementComponent implements OnInit {


    constructor(private campaignController: CampaignController) {}

    ngOnInit(): void {
    }

    private byteArrayToFileBlob(base64: string) {
        let binaryString = window.atob(base64);
        const length = binaryString.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return new Blob([bytes], { type: 'application/pdf' });
    }

    private downloadFile(blob: Blob, fileName: string) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
    }

    openAgreementDoc() {
        this.campaignController.GetCampaignFile("", Constants.GuidEmpty)
            .subscribe(
                result => {
                    if (!!result) {
                        let file = this.byteArrayToFileBlob(result as any);
                        this.downloadFile(file, 'MogoSME HUB Marketing Plans Services Agreement_rev 8.28.23.pdf');
                    }
                });
    }
}