<div class="mt-3">

    <div class="card card-body mx-3 mt-3">
        <div class="row align-items-center">
            <div class="col-6">
                <div class="display-3">Calendar</div>
            </div>

            <div class="col-6 d-flex flex-wrap justify-content-end align-items-center">
                <p-dropdown autoWidth="false" [style]="{'width':'100%'}" class="pr-3"
                    [options]="keyValuePairsFriendlyValuePageTemplate" [(ngModel)]="keyValuePairPageTemplate"
                    (onChange)="filter()"></p-dropdown>
                <p-dropdown autoWidth="false" [style]="{'width':'100%'}" [(ngModel)]="keyValuePairSection" class="pr-3"
                    [options]="keyValuePairsFriendlyValueSection" (onChange)="filter()">
                </p-dropdown>

                <p-dropdown placeholder="Select OAS" [options]="keyValuesPairsOAS" [(ngModel)]="OAS" filter="true" (onChange)="filter()">
                </p-dropdown>

            </div>
        </div>
    </div>
    <!-- header -->
    <div class="row mx-3">
        <div class="card card-body noBorder col-4">
            <full-calendar *ngIf="takeOutCalendarData" [options]="calendarOptions"></full-calendar>
        </div>
        <div class="card card-body noBorder col-8">
            <div class="row mb-3">
                <div class="col-6 selectedDateText">
                    <strong>
                        {{ selectedDate }}
                    </strong>
                </div>
                <div class="col-6" style="text-align: right;">
                    <span class="text-white nav-btn eventCount">
                        {{ selectDateEvents?.length }}
                    </span>
                </div>
            </div>



            <div *ngIf="selectDateEvents?.length">
                <div *ngFor="let event of selectDateEvents; let i = index">
                    <div class="row campaignEventRow" [ngClass]="isEven(i+1) ? '' : 'highLightColumn'">
                        <div class="col-6">
                            <strong>
                                {{event.campaignName}}
                            </strong>
                        </div>
                        <div class="col-3">{{event.oas}}</div>
                        <div class="col-3">{{event.pageTamplate +'|'+ event.section}}</div>
                    </div>
                </div>
            </div>

            <div *ngIf="selectDateEvents?.length == 0" class="text-muted" style="text-align: center; padding-top: 5em;">
                <strong>There is no records for current day!</strong>
            </div>


        </div>
    </div>
</div>