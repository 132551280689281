import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IBaseCampaignDto, IThrivePackageEmailDetailDto } from '@Workspace/_generated/interfaces';
import { CampaignService } from '../../../services/campaign.service';
import { CartItemService } from '../../../../shared-core/components/card-item/card-item.service';
import { CampaignController } from '@Workspace/_generated/services';
import { AuthService } from '@Workspace/auth';
import { Constants } from 'libs/common/Constants';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'campaign-thrive-package',
    templateUrl: './campaign-thrive-package.component.html',
    styleUrls: ['./campaign-thrive-package.component.scss']



})
export class CampaignThrivePackageComponent {
    constructor(private router: Router, public activatedRoute: ActivatedRoute,
        private cardItemService: CartItemService,
        public campaignController: CampaignController,
        public authService: AuthService,
        private datePipe: DatePipe,
        public messageService: MessageService,

    ) { }

    budget: any;
    startDate: Date = new Date;
    campaignId: any;
    ngUnsubscribe = new Subject();
    @Input() campaignDto: IBaseCampaignDto;
    thrivePackageEmailDetailDto: IThrivePackageEmailDetailDto = {} as IThrivePackageEmailDetailDto;
    isThriveSelected: boolean = false;
    displayBasic: boolean = false;

    ngOnInit() {
        this.authService.userSettings.email;

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {


                if (!!data) {

                    this.campaignDto = data;
                }

            })
        this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.campaignId = params.slug;


        });
    }
    handleCloseDialog() {

        this.isThriveSelected = true;
        this.cardItemService.updatedDataSelection(this.campaignDto)

        this.router.navigate(["campaign/" + this.campaignId]);

    }
    sendData() {

        if (!!this.startDate && !!this.budget) {

            this.thrivePackageEmailDetailDto.senderEmail = this.authService.userSettings.email;
            this.thrivePackageEmailDetailDto.senderName = this.authService.userSettings.fullName;
            this.thrivePackageEmailDetailDto.startDate = this.datePipe.transform(new Date(this.startDate), "MM/dd/yyyy");
            this.thrivePackageEmailDetailDto.budget = this.budget;
            this.thrivePackageEmailDetailDto.id = Constants.GuidEmpty;
            this.campaignController.SendEmailThrivePackage(this.thrivePackageEmailDetailDto).subscribe(x => x)
            this.showBasicDialog();
        }
        else {

            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Please populate all necessary fields.' });

        }

    }
    onStartDateChanged(startDate: Date) {
        // if(!!startDate)
        //this.startDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));


    }
    onClose() {
        this.displayBasic = false;
        this.router.navigate(['mogo']);

    }
    showBasicDialog() {
        this.displayBasic = true;
    }
}