import { Component, ViewChild, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { IBaseCampaignDto, ICreativeDto } from '@Workspace/_generated/interfaces';
import { Subject } from 'rxjs';
import { Constants, Functions } from '@Workspace/common';
import { eAccountType, eCampaignStatus, eEntityStatus, eStandardCampaginChannel, eUserRole } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';

interface ICreativeDates
{
	id: string;
	flightStartDate: Date;
    flightEndDate: Date;
    startDateMin: Date;
    startDateMax: Date;
    endDateMin: Date;
    endDateMax: Date;
    creativeGroup: number;
    isBuilt: boolean;
}
@Component({
    selector: 'creative-swaps-dialog',
    templateUrl: './creative-swaps-dialog.html',
    styleUrls: ['./creative-swaps-dialog.scss']
})
export class CreativeSwapsDialog implements OnInit {

    constants = Constants;
    functions = Functions;
    eCampaignStatus = eCampaignStatus;
    isVisible: boolean = false;
    isStartDateChanged: boolean;
    startDate: Date;
    endDate: Date;
    minStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    isEndDateChanged: boolean;
    disableEndDate: boolean = false;
    baseCampaign: IBaseCampaignDto;
    editCreatives: ICreativeDates[] = [];
    validationMessage: string = '';
    newCreativeName: string;
    creative: ICreativeDto = {} as ICreativeDto;
    prevCreative: ICreativeDto = {} as ICreativeDto;
    nextCreative: ICreativeDto = {} as ICreativeDto;
    action: string = '';
    creativeNumber: number;
    maxEndDateFirst: Date;
    minEndDateSecond: Date;
    creativeGroup: number;

    maxCreativeGroup: number = 1;
    baseCampaignOriginal: IBaseCampaignDto;
    numberOfCreativesPerFlight: number;

    prevFlightStartDate: Date;
    prevFlightEndDate: Date;
    nextFlightStartDate: Date;
    nextFlightEndDate: Date;
    currentUser = this.authService.userSettings;
    private ngUnsubscribe = new Subject();
    enumAccountType = eAccountType;

    @Output('onEditSave') onEditSave: EventEmitter<any> = new EventEmitter<any>();
    @Output('onRemoveSave') onRemoveSave: EventEmitter<any> = new EventEmitter<any>();
    @Output('onNewSave') onNewSave: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public campaignController: CampaignController,
        private authService: AuthService
    ) { }

    ngOnInit() {
    }

    openDialog(baseCampaign: IBaseCampaignDto, creativeGroup: number, action: string) {
        baseCampaign.creatives = baseCampaign.creatives.sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.baseCampaign = baseCampaign;
        this.baseCampaignOriginal = JSON.parse(JSON.stringify(baseCampaign));
        this.creativeGroup = creativeGroup;
        let creatives = this.baseCampaign.creatives.filter(cr => cr.entityStatus == eEntityStatus.Active);
        this.creative = creatives.filter(cr => cr.creativeGroup == creativeGroup)[0];
        this.maxCreativeGroup=Math.max(...creatives.map(x => x.creativeGroup));        

        this.action = action;
        this.startDate = !!this.creative.flightStartDate ? new Date(this.creative.flightStartDate) : null;
        this.endDate = !!this.creative.flightEndDate ? new Date(this.creative.flightEndDate) : null;

        this.numberOfCreativesPerFlight = creatives.filter(x=>x.creativeGroup==this.maxCreativeGroup).length;
        this.newCreativeName = !!this.creative && !!this.creative.creativeName ? this.creative.creativeName : (
            (creativeGroup == 2 ? '2nd' : creativeGroup  + 'th') + ' - End Season Banner Creative Set');

        this.creativeNumber = this.baseCampaign.creatives.indexOf(this.creative);

        if (action == 'Edit') {
            this.minStartDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate) :
                (!!this.baseCampaign.startDate ? new Date(this.baseCampaign.startDate) : null);
            this.maxEndDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate) :
                (!!this.baseCampaign.endDate ? new Date(this.baseCampaign.endDate) : null);
            
            this.validateEditFlightsCalendar(this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active));
        }
        else if (action == 'New') {
            this.minStartDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate ? new Date(new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate).getFullYear(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate).getMonth(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate).getDate() + 1) :
                (!!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate ? new Date(new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate).getFullYear(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate).getMonth(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate).getDate() + 2) :
                    (!!this.baseCampaign.startDate ? new Date(new Date(this.baseCampaign.startDate).getFullYear(), new Date(this.baseCampaign.startDate).getMonth(), new Date(this.baseCampaign.startDate).getDate() + 2) : null));
            
            this.maxEndDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate) :
                (!!this.baseCampaign.endDate ? new Date(new Date(this.baseCampaign.endDate).getFullYear(), new Date(this.baseCampaign.endDate).getMonth(), new Date(this.baseCampaign.endDate).getDate() - 1) : null);
        }
        else if (action == 'Remove') {
            if (!!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0]) 
            {
                creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate && new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate) >= new Date(this.baseCampaign.startDate) ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate) : (!!this.baseCampaign.startDate ? new Date(this.baseCampaign.startDate) : null);
                creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate) : null;
            }
            if (!!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0]) 
            {
                creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate) : null;
                creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightEndDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate && new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate) < new Date(this.baseCampaign.endDate) ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightEndDate) : (!!this.baseCampaign.endDate ? new Date(this.baseCampaign.endDate) : null);
            }

            this.minStartDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightEndDate ? new Date(new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightEndDate).getFullYear(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightEndDate).getMonth(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightEndDate).getDate() + 1) :
                (!!creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightStartDate ? new Date(new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightStartDate).getFullYear(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightStartDate).getMonth(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-2)[0].flightStartDate).getDate() + 2) :
                    (!!this.baseCampaign.startDate ? new Date(new Date(this.baseCampaign.startDate).getFullYear(), new Date(this.baseCampaign.startDate).getMonth(), new Date(this.baseCampaign.startDate).getDate() + 2) : null));

            this.minEndDateSecond = !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate ? new Date(new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate).getFullYear(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate).getMonth(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightEndDate).getDate() + 1) :
                (!!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate ? new Date(new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate).getFullYear(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate).getMonth(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup-1)[0].flightStartDate).getDate() + 2) :
                    (!!this.baseCampaign.startDate ? new Date(new Date(this.baseCampaign.startDate).getFullYear(), new Date(this.baseCampaign.startDate).getMonth(), new Date(this.baseCampaign.startDate).getDate()) : null));

            this.maxEndDateFirst = !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate ?
                new Date(new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate).getFullYear(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate).getMonth(), new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+1)[0].flightStartDate).getDate() - 1) :
                (!!this.baseCampaign.endDate ? new Date(this.baseCampaign.endDate) : null);

            this.maxEndDate = !!creatives.filter(_ => _.creativeGroup == creativeGroup+2)[0] && !!creatives.filter(_ => _.creativeGroup == creativeGroup+2)[0].flightStartDate ? new Date(creatives.filter(_ => _.creativeGroup == creativeGroup+2)[0].flightStartDate) :
                (!!this.baseCampaign.endDate ? new Date(this.baseCampaign.endDate) : null);
        }

        
        this.prevCreative = this.baseCampaign.creatives.filter(cr => cr.creativeGroup == creativeGroup-1 && cr.entityStatus == eEntityStatus.Active)[0];
        this.nextCreative = this.baseCampaign.creatives.filter(cr => cr.creativeGroup == creativeGroup+1 && cr.entityStatus == eEntityStatus.Active)[0];

        if(!!this.prevCreative){
            this.prevFlightStartDate = new Date(this.prevCreative.creativeGroup > 1 ? this.prevCreative.flightStartDate : this.baseCampaign.startDate);
            this.prevFlightEndDate = !!this.prevCreative.flightEndDate ? new Date(this.prevCreative.flightEndDate) : new Date(this.baseCampaign.endDate);
        }
        if(!!this.nextCreative){
            this.nextFlightStartDate = new Date(this.nextCreative.flightStartDate);
            this.nextFlightEndDate = new Date(this.nextCreative.creativeGroup < this.maxCreativeGroup ? this.nextCreative.flightEndDate :this.baseCampaign.endDate);
        }

        if (baseCampaign.campaignType == eAccountType.OAS) {
            if (!this.minStartDate) {
                this.campaignController.GetAllSeasonMapping().subscribe(data => {
                    var seasons = data.filter(x => x.seasonId == baseCampaign.seasonMapping.seasonId);
                    seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());

                    this.minStartDate = new Date(seasons[0].startDate);
                    this.maxEndDate = new Date(seasons[3].endDate);

                    let nextDateFromToday = new Date();
                        nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);
                    if (this.minStartDate < nextDateFromToday) {
                        this.minStartDate = nextDateFromToday;
                    }

                    if (!this.maxEndDate && this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.pageTakeover) {
                        let campaignStartDate = !!this.baseCampaign.startDate ? new Date(this.baseCampaign.startDate) :
                            (!!creatives[0].flightStartDate ? new Date(creatives[0].flightStartDate) : this.minStartDate);
                        this.maxEndDate = new Date(campaignStartDate.getFullYear(), campaignStartDate.getMonth(), campaignStartDate.getDate() + this.baseCampaign.quantity);
                    }
                    this.minEndDate = new Date(this.minStartDate.getFullYear(), this.minStartDate.getMonth(), this.minStartDate.getDate() + 1);
                });
            }

            else {
                let nextDateFromToday = new Date();
                    nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);
                if (this.minStartDate < nextDateFromToday) {
                    this.minStartDate = nextDateFromToday;
                }

                if (!this.maxEndDate && this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.pageTakeover) {
                    let campaignStartDate = !!this.baseCampaign.startDate ? new Date(this.baseCampaign.startDate) :
                        (!creatives[0].flightStartDate ? new Date(creatives[0].flightStartDate) : this.minStartDate);
                    this.maxEndDate = new Date(campaignStartDate.getFullYear(), campaignStartDate.getMonth(), campaignStartDate.getDate() + this.baseCampaign.quantity);
                }
                this.minEndDate = new Date(this.minStartDate.getFullYear(), this.minStartDate.getMonth(), this.minStartDate.getDate() + 1);
            }
        }

        else {
            let fiveDaysFromToday = new Date();
                fiveDaysFromToday.setDate(fiveDaysFromToday.getDate() + 5);
            if (new Date(this.minStartDate) < fiveDaysFromToday) {
                this.minStartDate = fiveDaysFromToday;
            }
            this.minEndDate = new Date(this.minStartDate.getFullYear(), this.minStartDate.getMonth(), this.minStartDate.getDate() + 1);
        }

        this.isVisible = true;
        this.isEndDateChanged = false;
        this.isStartDateChanged = false;

    }

    save() {
        if(this.action != 'Edit'){
            if(!!this.prevCreative){
                this.baseCampaign.creatives.filter(x=>x.creativeGroup == this.prevCreative.creativeGroup).forEach(y => {
                    y.flightStartDate = this.prevFlightStartDate;
                    y.flightEndDate = !!this.nextCreative ? this.prevFlightEndDate : null;
                    
                    Functions.dateTimeZoneConversion(this.prevCreative.flightEndDate);
                    Functions.dateTimeZoneConversion(this.prevCreative.flightStartDate);
                });
            }
            if(!!this.nextCreative){
                this.baseCampaign.creatives.filter(x=>x.creativeGroup == this.nextCreative.creativeGroup).forEach(y => {
                    y.flightStartDate = this.nextFlightStartDate;
                    y.flightEndDate = this.maxCreativeGroup != this.nextCreative.creativeGroup ? this.nextFlightEndDate : null;
                    
                    Functions.dateTimeZoneConversion(this.nextCreative.flightStartDate);
                    Functions.dateTimeZoneConversion(this.nextCreative.flightEndDate);
                });
            }
        }

        if(this.action=='Remove'){
            if(this.maxCreativeGroup<=2){
                this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active).forEach(x=>{
                    x.flightStartDate=null;
                    x.flightEndDate=null;
                });
            this.isStartDateChanged = true;
            this.isEndDateChanged = true;
            }
            else if(this.maxCreativeGroup == this.creativeGroup){
                this.isStartDateChanged = true;
                this.isEndDateChanged = true;
            }
            else{
                this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active).forEach(x=>{
                    if(x.creativeGroup>this.creativeGroup && x.creativeName==x.creativeGroup+"th - End Season Banner Creative Set"){
                        if(x.creativeGroup-1==2){
                            x.creativeName=(x.creativeGroup-1) +"nd - End Season Banner Creative Set";
                        }
                        else{
                            x.creativeName=(x.creativeGroup-1) +"th - End Season Banner Creative Set";
                        }
                    }
                });
            }
            let deletedCreatives = this.baseCampaign.creatives.filter(x=>x.creativeGroup == this.creativeGroup);
            deletedCreatives.forEach(x=>{
                this.deleteUpload(x);
            });
        }
        else if(this.action == 'Edit'){
            this.editCreatives.forEach(x=>{
                let creatives = this.baseCampaign.creatives.filter(y=>y.entityStatus == eEntityStatus.Active && y.creativeGroup == x.creativeGroup);
                creatives.forEach(creative=>{
                    creative.flightStartDate = x.flightStartDate;
                    Functions.dateTimeZoneConversion(creative.flightStartDate);
                    if(x.creativeGroup != this.maxCreativeGroup){
                        creative.flightEndDate = x.flightEndDate;
                        Functions.dateTimeZoneConversion(creative.flightEndDate);
                    }
                });
            });
        }

        const dialogResult = {
            startDate: new Date(this.startDate),
            endDate: !!this.endDate ? new Date(this.endDate) : null,
            isStartDateChanged: this.isStartDateChanged,
            isEndDateChanged: this.isEndDateChanged,
            creativeName: this.newCreativeName,
            creative: this.creative,
            baseCampaign: this.baseCampaign,
            creativeGroup: this.creativeGroup,

        };
        if (this.action == 'Edit')
            this.onEditSave.emit(dialogResult);
        else if (this.action == 'New')
            this.onNewSave.emit(dialogResult);
        else if (this.action == 'Remove')
            this.onRemoveSave.emit(dialogResult);
        this.action = '';
        this.isVisible = false;
    }

    cancel() {
        if(this.action != 'Edit'){
            this.baseCampaign.creatives = this.baseCampaignOriginal.creatives;
            this.prevFlightEndDate = null;
            this.prevFlightStartDate = null;
            this.nextFlightEndDate = null;
            this.nextFlightStartDate = null;
            this.isStartDateChanged = false;
            this.isEndDateChanged = false;
            if (this.action != 'Remove'){
                while(this.numberOfCreativesPerFlight>0){
                    this.baseCampaign.creatives.pop();
                    this.numberOfCreativesPerFlight--;
                }
            }
        }
        this.isVisible = false;
    }

    onStartDateChange(startDate: Date) {
        if (!!startDate) {
            this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);

            this.isStartDateChanged = true;
            this.validationMessage = '';

            if (this.baseCampaign.campaignType == eAccountType.OAS &&
                this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.pageTakeover) {
                this.maxEndDate = !!this.baseCampaign.endDate ? new Date(this.baseCampaign.endDate) :
                    (!!this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active)[0].flightStartDate ? new Date(this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active)[0].flightStartDate.getFullYear(),
                        this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active)[0].flightStartDate.getMonth(), this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active)[0].flightStartDate.getDate() + this.baseCampaign.quantity)
                        : new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + this.baseCampaign.quantity));
                new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + this.baseCampaign.quantity);
            }
            Functions.dateTimeZoneConversion(startDate);
        }

        if (startDate == null || (startDate > this.endDate && this.action != 'New')) {
            this.validationMessage = 'Campaign dates are wrong.';
            this.endDate = null;
        }
    }

    onEndDateChange(endDate: Date) {
        if (!!endDate) {
            this.isEndDateChanged = true;
            this.validationMessage = '';
            Functions.dateTimeZoneConversion(endDate);
            this.endDate = endDate;
        }
        if (this.startDate > endDate) {
            this.startDate = null;
        }
    }
    editOnStartDateChange(startDate: Date, id: string, creativeGroup: number){
        let changedCreative: ICreativeDates = this.editCreatives.filter(x => x.id == id && x.creativeGroup == creativeGroup)[0];
        let prevCreativeGroup: number = changedCreative.creativeGroup - 1;
        if(changedCreative.creativeGroup != 1){
            this.editCreatives[prevCreativeGroup - 1].flightEndDate = new Date(startDate.getFullYear(),startDate.getMonth(), startDate.getDate() - 1);
        }
        this.validateEditFlightsCalendar(this.editCreatives);
    }
    editOnEndDateChange(endDate: Date, id: string, creativeGroup: number){
        let changedCreative: ICreativeDates = this.editCreatives.filter(x => x.id == id && x.creativeGroup == creativeGroup)[0];
        let nextCreativeGroup: number = changedCreative.creativeGroup + 1;
        if(changedCreative.creativeGroup < this.maxCreativeGroup){
            this.editCreatives[nextCreativeGroup - 1].flightStartDate = new Date(endDate.getFullYear(),endDate.getMonth(), endDate.getDate() + 1);
        }
        this.validateEditFlightsCalendar(this.editCreatives);
    }
    checkIsDisable() {
        if (this.action == 'New' && !!this.startDate && !!!this.validationMessage)
            return false;
        else if (this.action == 'Remove' && (((!!this.isStartDateChanged || !!this.isEndDateChanged) && !!!this.validationMessage) || this.maxCreativeGroup == this.creativeGroup))
            return false;
        else if(this.action == 'Edit'){
            let creatives: ICreativeDto[] = this.baseCampaign.creatives.filter(x=> x.entityStatus==eEntityStatus.Active);
            let canEdit: boolean = false;
            
            creatives.forEach(x=>{
                if(Functions.checkCanChangeFlight(this.baseCampaign.campaignStatusId, this.baseCampaign.statusId, this.baseCampaign.campaignType, x.isBuilt)){
                    canEdit = true;
                    return;
                }
            });
            return !canEdit || this.checkIsEditDisable() || (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin);
        }
        else
            return true;
    }
    checkIsEditDisable(){
        let disabled: boolean = false;
        this.editCreatives.forEach(x=>{
            if(!Functions.isDateBefore(x.flightStartDate,x.flightEndDate) || (x.creativeGroup != this.maxCreativeGroup && (!Functions.isDateBefore(x.flightEndDate, this.editCreatives[x.creativeGroup].flightStartDate) || !Functions.isOneDayDifference(x.flightEndDate, this.editCreatives[x.creativeGroup].flightStartDate)))
            || !Functions.isDateBetween(x.flightStartDate,new Date(this.baseCampaign.startDate), new Date(this.baseCampaign.endDate), false) || !Functions.isDateBetween(x.flightEndDate, new Date(this.baseCampaign.startDate), new Date(this.baseCampaign.endDate), true)){
                disabled = true;
                return;
            }
        });
        return disabled;
    }
    onRemoveEndDateChange(endDate: Date) {
        if (!!endDate) {
            this.isEndDateChanged = true;
            this.validationMessage = '';
            // if (!!this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active)[this.creativeNumber - 1] && !!this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active)[this.creativeNumber + 1])
                //this.baseCampaign.creatives[this.creativeNumber - 1].flightEndDate = new Date(endDate);
            if (!!this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active && x.creativeGroup == this.creativeNumber + 1)){
                // this.baseCampaign.creatives[this.creativeNumber + 1].flightStartDate = new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate() + 1);
                this.nextFlightStartDate = new Date(this.prevFlightEndDate.getFullYear(),this.prevFlightEndDate.getMonth(), this.prevFlightEndDate.getDate() + 1);
            }
        }
    }
    onRemoveStartDateChange(startDate: Date) {
        if (!!startDate) {
            this.isStartDateChanged = true;
            this.validationMessage = '';
            if (!!this.baseCampaign.creatives.filter(x=>x.entityStatus==eEntityStatus.Active && x.creativeGroup == this.creativeNumber - 1)){
                this.prevFlightEndDate = new Date(this.nextFlightStartDate.getFullYear(),this.nextFlightStartDate.getMonth(), this.nextFlightStartDate.getDate() - 1);
                //this.baseCampaign.creatives[this.creativeNumber - 1].flightEndDate = new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), new Date(startDate).getDate() - 1);
            }
            // if (!!this.baseCampaign.creatives[this.creativeNumber + 1])
            //     //this.baseCampaign.creatives[this.creativeNumber + 1].flightStartDate = new Date(startDate);

        }
    }
    validateEditFlightsCalendar(list: any){
        this.editCreatives = [];
        let creativeNumber: number = 0;
        list.sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        list.forEach(x=>{
            if(creativeNumber != x.creativeGroup){
                creativeNumber = x.creativeGroup;
                let element: ICreativeDates = {
                    id: x.id,
                    flightStartDate: x.flightStartDate != null ? new Date(x.flightStartDate) : new Date(this.baseCampaign.startDate),
                    flightEndDate: x.flightEndDate != null ? new Date(x.flightEndDate) : new Date(this.baseCampaign.endDate),
                    startDateMin: x.creativeGroup === 1
                    ? (!Functions.isTodayOrBefore(new Date(this.baseCampaign.startDate))
                        ? new Date(this.baseCampaign.startDate)
                        : new Date(new Date().setDate(new Date().getDate() + 1)))
                    : (!Functions.isTodayOrBefore(new Date(list.find(y => y.creativeGroup == x.creativeGroup - 1).flightStartDate))
                        ? new Date(new Date(list.find(y => y.creativeGroup == x.creativeGroup - 1).flightStartDate).setDate(new Date(list.find(y => y.creativeGroup == x.creativeGroup - 1).flightStartDate).getDate() + 2))
                        : new Date(new Date().setDate(new Date().getDate() + 1))),
                    startDateMax: x.creativeGroup != this.maxCreativeGroup
                    ? new Date(new Date(x.flightEndDate).setDate(new Date(x.flightEndDate).getDate() - 1))
                        : new Date(new Date(this.baseCampaign.endDate).setDate(new Date(this.baseCampaign.endDate).getDate() - 1)),
                    endDateMin: !Functions.isTodayOrBefore(new Date(x.flightStartDate))
                    ? new Date(new Date(x.flightStartDate).setDate(new Date(x.flightStartDate).getDate() + 1))
                        : new Date(new Date().setDate(new Date().getDate() + 1)),
                    endDateMax: x.creativeGroup != this.maxCreativeGroup
                    ? list.find(y => y.creativeGroup == x.creativeGroup + 1).flightEndDate != null 
                        ? new Date(new Date(list.find(y => y.creativeGroup == x.creativeGroup + 1).flightEndDate).setDate(new Date(list.find(y => y.creativeGroup == x.creativeGroup + 1).flightEndDate).getDate() - 2))
                        : new Date(new Date(this.baseCampaign.endDate).setDate(new Date(this.baseCampaign.endDate).getDate() - 2))
                    : new Date(new Date(this.baseCampaign.endDate)),
                    creativeGroup: x.creativeGroup,
                    isBuilt: x.isBuilt
                };
                this.editCreatives.push(element);
            }
        });
    }

    deleteUpload(creative: ICreativeDto) {
        creative.creativeAssets.forEach(x=>{
            x.fileName = null;
            x.fileSize = null;
            x.fileType = null;
            if(!((this.baseCampaign.account.isHubStandardCampaign || this.baseCampaign.account.isCampaignCreation) 
            && (creative.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner || creative.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner 
                || creative.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner || creative.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner))){
                    x.height = 0;
                    x.width = 0;
            }
            x.fileWrapper = null;
            x.isChanged = true;
            x.isUploadValid = null;
            x.imageUrl = null;
            x.isValid = null;
            x.isDeleted = true;
            x.fileAdditional = null;
            x.fileWrapperAdditional = null;
            x.file = null;
            x.isChanged = true;
            x.isLandingPageExist = null;
        });
    }
}