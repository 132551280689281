import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { componentFadeIn } from '../../../route-animations';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../services/campaign.service';
import { SettingsService, PageLoaderService } from '@Workspace/services';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { IGridOptions } from '@Workspace/interfaces';
import { Functions, Constants } from '@Workspace/common';
import { eCRMType, eEntityStatus } from '@Workspace/_generated/enums';
import { ICRMDto } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { FileUploadService } from '../../../services/file-upload.service';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'crm-list',
    templateUrl: './crm-list.component.html',
    styleUrls: ['./crm-list.component.scss'],

    animations: [componentFadeIn]
})
export class CRMListComponent implements OnInit, OnDestroy {

    constructor(
        public authService: AuthService,
        private confirmationService: ConfirmationService,
        private settingsService: SettingsService,
        private campaignService: CampaignService,
        private campaignController: CampaignController,
        public fileuploadService: FileUploadService,
        public pageLoader: PageLoaderService
    ) {

    }

    enumCrmType = eCRMType;
    options: IGridOptions;
    constants = Constants;

    ngUnsubscribe: Subject<void> = new Subject();

    ngOnInit(): void {
        this.campaignService.getRefresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            this.composeGridColumns();
        });


    }

    composeGridColumns(searchParameter?: string) {
        this.options = {} as IGridOptions;

        this.options.columns = [
            {
                label: 'Campaign Name',
                value: 'campaignName'
            },
            {
                label: 'CRM Type',
                value: 'crmType'
            },
            {
                label: 'Client Name',
                value: 'campaignAdvertiserName'
            },
            {
                label: 'Comments',
                value: 'comment'
            },

            {
                label: 'Submitted',
                value: 'ModifiedOn',
            },
            {
                label: '',
                value: '',
                align: 'right'
            }

        ];

        if (!!searchParameter) {
            const searchTerm = searchParameter.replace(
                /\'/g,
                "''"
            );
            this.options.filter = `(contains(CampaignName, '${searchTerm}') or contains(Comment, '${searchTerm}')
                                    or contains(CampaignAdvertiserName, '${searchTerm}'))`;
        }

        let options = this.options;
        this.options = Object.assign({}, options);

        Functions.composeGridOptions(
            this.options,
            'ModifiedOn',
            this.settingsService.createApiUrl('Campaign/GetCRM'),
            false
        );
    }

    getTextAlignClass(textAlign: string): string {
        let textAlignClass: string = "text-left";
        switch (textAlign) {
            case 'left':
                textAlignClass = "text-left";
                break;
            case 'center':
                textAlignClass = "text-center";
                break;
            case 'right':
                textAlignClass = "text-right";
                break;
        }

        return textAlignClass;
    }

    // openDialog(item: ICRMDto) {

    // }

    remove(item: ICRMDto) {

        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'CRMDelete',
            message:
                'Are you sure you want to delete CRM?',
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                item.entityStatus = eEntityStatus.Deleted;
                this.campaignController.CreateCRM(item).subscribe(x => {
                    this.composeGridColumns();

                })
            }
        });
    }

    download(item: ICRMDto) {
        this.pageLoader.showPageLoader();

        var x = this.fileuploadService.GetCMRFile(item.id, "").pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            let file = new Blob([x as any], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(file, item.file.fileName);
            this.pageLoader.hidePageLoader();


        });


    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}