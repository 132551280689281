import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Constants } from '@Workspace/common';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Subject } from 'rxjs';

@Component({
    selector: 'additional-settings-accordion',
    templateUrl: './additional-settings-accordion.component.html',
    styleUrls: ['./additional-settings-accordion.component.scss']
})
export class AdditionalSettingsAccordion implements OnInit {

    constants = Constants;
    isSiteVisitTrackingEnabled: boolean = false;
    isConversionTrackingEnabled: boolean;
    isAdditionalTargetingSegmentsEnabled: boolean;
    defaultValues: any;
    editableFields: boolean[] = [];
    isActivation: boolean;
    isActivatedOutsideOfHub: boolean = false;
    canEditInterestTargeting: boolean;
    canEditConversionTracking: boolean;
    canEditSiteVisitTracking: boolean;

    constructor() { }

    ngOnInit() {
        this.canEditSiteVisitTracking = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.siteVisitTracking : (x.inventoryItemNew != null ? x.inventoryItemNew.siteVisitTracking : false)) || this.baseCampaign.isSiteVisitTrackingUnlocked;
        this.canEditConversionTracking = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.conversionTracking : (x.inventoryItemNew != null ? x.inventoryItemNew.conversionTracking : false)) || this.baseCampaign.isConversionTrackingUnlocked;
        this.canEditInterestTargeting = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.interestTargeting : (x.inventoryItemNew != null ? x.inventoryItemNew.interestTargeting : false)) || this.baseCampaign.isInterestSegmentsUnlocked;
        
        this.initPage(
            this.baseCampaign.isSiteVisitTrackingEnabled,
            this.baseCampaign.isConversionTrackingEnabled,
            this.baseCampaign.isAdditionalTargetingSegmentsEnabled,
            [this.canEditSiteVisitTracking, this.canEditConversionTracking, this.canEditInterestTargeting]
            )
    }

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() disabled: boolean = false;

    private ngUnsubscribe = new Subject();

    initPage(isSiteVisitTrackingEnabled: boolean, isConversionTrackingEnabled: boolean, isAdditionalTargetingSegmentsEnabled: boolean, editable: boolean[], isActivation?: boolean, isActivatedOutsideOfHub?: boolean) {
        this.isActivation = isActivation;
        if (!isActivation) {
            this.defaultValues = { ...arguments };
            this.isSiteVisitTrackingEnabled = arguments[0];
            this.isConversionTrackingEnabled = arguments[1];
            this.isAdditionalTargetingSegmentsEnabled = arguments[2];
            this.editableFields = arguments[3];

        } else {
            this.isActivatedOutsideOfHub = isActivatedOutsideOfHub;

        }

    }

    resetDefault() {
        this.isSiteVisitTrackingEnabled = this.defaultValues[0];
        this.isConversionTrackingEnabled = this.defaultValues[1];
        this.isAdditionalTargetingSegmentsEnabled = this.defaultValues[2];
    }

    save() {
        const dialogResult = {
            isSiteVisitTrackingEnabled: this.isSiteVisitTrackingEnabled,
            isConversionTrackingEnabled: this.isConversionTrackingEnabled,
            isAdditionalTargetingSegmentsEnabled: this.isAdditionalTargetingSegmentsEnabled,
            isActivatedOutsideOfHub: this.isActivatedOutsideOfHub
        };


        this.onSave.emit(dialogResult);
    }

}