<p-dialog [(visible)]="isVisible" [modal]="true">
    <p-header>Preview</p-header>

    <div *ngIf="!!mediaInfo">

        <div>Name: <i>{{ mediaInfo.title }}</i></div>

        <div *ngIf="IsImage()">
            <img [src]="mediaInfo.url" alt="">
        </div>

        <div *ngIf="IsAudio()">
            <audio controls>
                <source [src]="mediaInfo.url" type="audio/mpeg">
            </audio>
        </div>

        <div *ngIf="IsVideo()">
            <video controls>
                <source [src]="mediaInfo.url" type="video/mp4">
            </video>
        </div>
    </div>

    <p-footer>
        <div class="pt-3">
            <button (click)="close()" pButton class="text-uppercase ml-3" label="Close"></button>
        </div>
    </p-footer>
</p-dialog>