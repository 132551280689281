<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>{{this.creativeName}}</strong></span>
</div>
<div class="pl-3" [ngClass]="this.campaign.account.isLearfieldCampaign ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div *ngIf="this.campaign.campaignType == enumAccountType.FAN365 || this.campaign.campaignType == enumAccountType.OAS" class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{"Flight " + creative.creativeGroup}}</label>
            <label class="my-0">{{" | " + (creative.creativeName ? creative.creativeName : creative.name)}}</label>
            <label class="my-0" *ngIf="creative.flightStartDate || creative.flightEndDate"> | {{(!!creative.flightStartDate ? creative.flightStartDate : campaign.startDate) | date : constants.DEFAULT_FORMAT_DATE_US}} - {{(!!creative.flightEndDate? creative.flightEndDate : campaign.endDate) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
            <span *ngIf="currentUser.permissions.isLearfieldClient" tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='creative.name == "Premium Display Banner" ? constants.premiumDisplayBannerInfo : constants.standardDisplayBannerInfo' class="fal fa-info-circle info"></span>
        </div>
        <div *ngIf="campaign.account.isCampaignCreation" class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{creative.creativeName ? creative.creativeName : creative.name}}</label>
            <label class="my-0" *ngIf="creative.flightStartDate || creative.flightEndDate"> 
                | {{(accordionName=="abandonedCart" || accordionName=="postPurchaseUpsell") ? 
                    (campaign.startDate | date : constants.DEFAULT_FORMAT_DATE_US) : 
                        (!!creative.flightStartDate ? creative.flightStartDate : 
                            (isOnlyFlight ? campaign.startDate : null)) | date : constants.DEFAULT_FORMAT_DATE_US }} - 
                {{(accordionName=="abandonedCart" || accordionName=="postPurchaseUpsell") ? 
                    (campaign.endDate | date : constants.DEFAULT_FORMAT_DATE_US) : 
                        (!!creative.flightEndDate? creative.flightEndDate : 
                        (isOnlyFlight ? campaign.endDate : null)) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
        </div>
        <div *ngIf="this.campaign.campaignType == enumAccountType.FAN365 || this.campaign.campaignType == enumAccountType.OAS" class="col-lg-6 col-12 px-2 d-flex flex-wrap justify-content-lg-end justify-content-start">
            <label class="my-0 float-right"> Accepted banner sizes:</label>
            <label class="my-0 float-right"> {{functions.returnAcceptedBannerSizes(campaign.campaignType, campaign.creatives, creative, fileUpload, acceptedBannerSizes)}} </label>
            <!-- <i *ngIf="creative.creativeGroup > 1 && !functions.ifFileExist(creative) && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, maxNumberOfFlights)"  (click)="delete()" class=" my-0 pl-3 fa fa-trash-alt delete" pTooltip='Remove flight'></i> -->
        </div>
    </div>
    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div *ngIf="checkIsFileUploaded()" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Missing creative image.</span>
    </div>
    <div *ngIf="!isLandingUrlOk" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Missing Landing URL page for uploaded asset.</span>
    </div>
    <div class="mb-3 d-flex flex-wrap">
        <div class="mb-4" [ngClass]="isApprove? 'col-lg-6 col-12':'col-lg-4 col-12'" *ngFor="let item of creative.creativeAssets; let i = index">
            <file-upload (openPreviewEvent)="openPreview($event)" (uploadValidationMessage)="uploadWarning($event)" [reviewPage]='reviewPage' [headerTitle]="'Premium Banner' + ' ' + (i+1)" [isApprove]='isApprove' [isApprovedReported]='item.isValid!=null' [footer]='booleanTrue' filetypes="png, jpg, jpeg, gif" size=307200 [campaign]="campaign" [creative]="creative" [asset]="i" type="image" [disableLandingPage]='!!landingPage' [landingPage]='landingPage'></file-upload>

        </div>
    </div>
</div>

<creative-swaps-dialog #creativeSwaps (onEditSave)="editCampaignDatesSaved($event)" (onRemoveSave)="removeCampaignDatesSaved($event)"></creative-swaps-dialog>