import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { IBaseCampaignDto, ICreativeAssetDto, ICreativeDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';
import { Constants, Functions, LookUps } from '@Workspace/common';
import { CartItemService } from 'apps/core/src/app/modules/shared-core/components/card-item/card-item.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'youtube-video',
    templateUrl: './youtube-video.component.html',
    styleUrls: ['./youtube-video.component.scss']
})
export class YouTubeVideoComponent implements OnInit, OnDestroy {
    constructor(
        private cardItemService: CartItemService,
        private authService: AuthService,
    ) { }

    @Input() reviewPage: boolean = false;
    @Input() creative: ICreativeDto;
    @Input() campaign: IBaseCampaignDto;
    @Input() isApprove: boolean = false;

    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();

    ngUnsubscribe = new Subject();
    functions = Functions;
    constants = Constants;
    currentUser = this.authService.userSettings;

    isUploadOk: boolean = true;
    maxNumberOfFlights: number = 1;
    isChannelUrlOk: boolean = true;
    isYouTubeToVideoUrlOk: boolean = true;
    isClientYoutubeEmailOk: boolean = true;

    ngOnInit() {
        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            }
        );
    }

    removeAsset(asset: ICreativeAssetDto) {
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights))
        {
            let index = this.creative.creativeAssets.indexOf(asset);
            this.creative.creativeAssets.splice(index, 1);
        }
    }

    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    validationUrlMessage(fieldType: string) {
        let textToMacth = fieldType == 'clientChannelUrl' ? this.creative.detailJson.clientYoutubeChannelUrl : this.creative.detailJson.youTubeUrlToVideo;
        // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?%#[\]@!\$&'\(\)\*\+,;=.]+$/;
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        var found = textToMacth.match(regex);
        if(fieldType == 'clientChannelUrl'){
            this.isChannelUrlOk = true;
            if (found == null) {
                this.isChannelUrlOk = false;
            }
        }
        else{
            this.isYouTubeToVideoUrlOk = true;
            if (found == null) {
                this.isYouTubeToVideoUrlOk = false;
            }
        }
    }

    validationClientYoutubeEmailMessage(){
        let textToMacth = this.creative.detailJson.clientYoutubeEmail;
        var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        var found = textToMacth.match(regex);
        this.isClientYoutubeEmailOk = true;
        if (found == null) {
            this.isClientYoutubeEmailOk = false;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
