<div class="ml-3">

    <div class="row mb-3">
        <div class="col-12">
            <div class="display-4">
                Assign User To the {{resource}}
            </div>
        </div>
    </div>

    <div class="d-flex flex-column page-container">
        <div>
            <!-- <div *ngIf="!isFromTeam" class="col-12 header-component pb-0">Assign User To the {{resource}}</div> -->

            <pick-list titleSource="Active Users" titleTarget="Assigned Users" [sourceList]="sourceUsers" [targetList]="targetUsers"></pick-list>
        </div>
    </div>
</div>