import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';
import { IUserPropertiesDto, IAccountDto } from '@Workspace/_generated/interfaces';
import { SelectItem } from 'primeng/api';
import { AuthService } from '@Workspace/auth';
import { CampaignController, AccountController } from '@Workspace/_generated/services';
import { eUserRole } from '@Workspace/_generated/enums';
import { CampaignService } from '../../services/campaign.service';
import { take, takeUntil } from 'rxjs/operators';
import { IKeyValuesPairOASDto } from '@Workspace/_generated/interfaces';
import { PageLoaderService } from '@Workspace/services';
import * as moment from 'moment';

@Component({
    selector: 'campaign-calendar-page',
    templateUrl: './campaign-calendar-page.html',
    styleUrls: ['./campaign-calendar-page.scss']
})
export class CampaignCalendar implements OnInit {
    constructor(
        private authService: AuthService,
        private campaignService: CampaignService,
        public accountController: AccountController,
        public campaignController: CampaignController,
        private pageLoaderService: PageLoaderService
    ) { }

    ngUnsubscribe = new Subject();
    currentUser: IUserPropertiesDto;
    
    enumUserRole = eUserRole;

    currentAccount: IAccountDto[];
    gridId: number;

    selectedDate: string = '';
    calendarEvents: any[];
    selectDateEvents: any;
    takeOutCalendarData: any;
    initTakeOutCalendarData: any[];
    calendarOptions: any;

    keyValuePairPageTemplate: any;
    keyValuePairSection: any;

    keyValuePairsFriendlyValueSection: SelectItem[] = [];
    keyValuePairsFriendlyValuePageTemplate: SelectItem[] = [];
    keyValuePairsFriendlyValue: SelectItem[] = [];
    keyValuesPairsOAS: SelectItem[] = [];
    filteredOAS: any[] = [];
    OAS: any;


    ngOnInit() {

        this.pageLoaderService.showPageLoader();
        this.currentUser = this.authService.userSettings;
        if (!!this.currentUser.accounts && this.currentUser.userRole == 1)
            //this.currentAccount = this.currentUser.accounts.find(x => !!this.currentUser.accountId ? x.id == this.currentUser.accountId : false);
            this.currentAccount = this.currentUser.accounts;
        if (!!this.currentAccount && this.currentAccount[0].isCampaignCreation)
            this.gridId = 3;
        else if (!!this.currentAccount && this.currentAccount[0].isHubStandardCampaign )
            this.gridId = 2;
        else if ( !!this.currentAccount && this.currentAccount[0].isLearfieldCampaign )
            this.gridId = 6;
        else if (!!this.currentAccount && this.currentAccount[0].isOASCampaign)
            this.gridId = 6;
        else if ( !!this.currentAccount && this.currentAccount[0].isSocialCampaign )
            this.gridId = 6;
        else if ( !!this.currentAccount && this.currentAccount[0].isContentCampaign )
            this.gridId = 6;
        else if (this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin) this.gridId = 6;
        else if (this.currentUser.userRole == eUserRole.LIMGCSocialAdOps) this.gridId = 6;
        else {
            this.campaignService.getGridIdId().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (!!x)
                    this.gridId = x;
                else
                    this.gridId = (!this.currentUser.accountId && (this.currentUser.permissions.isEmployee || this.currentUser.permissions.isAdmin))
                        || (!!this.currentAccount && this.currentAccount[0].isLearfieldCampaign) ? 1 :
                        ((!this.currentUser.accountId && (this.currentUser.permissions.isEmployee || this.currentUser.permissions.isAdmin))
                            || (!!this.currentAccount && this.currentAccount[0].isOASCampaign) ? 4 :
                            ((!this.currentUser.accountId && (this.currentUser.permissions.isEmployee || this.currentUser.permissions.isAdmin))
                                || (!!this.currentAccount && this.currentAccount[0].isSocialCampaign) ? 5 : 
                             (!this.currentUser.accountId && (this.currentUser.permissions.isEmployee || this.currentUser.permissions.isAdmin))
                                || (!!this.currentAccount && this.currentAccount[0].isContentCampaign) ? 7 :  
                             (this.currentUser.permissions.isHSClient ? 2 : 3)));
                ;
            });
        }

        this.campaignController
            .GetTakeOutCalendarData()
            .pipe(take(1))
            .subscribe(data => {
                this.takeOutCalendarData = data.data;
                this.initTakeOutCalendarData = data.data;

                var counts = data.data.reduce((p, c) => {
                    var startDate = c.startDate.split("T")[0];
                    if (!p.hasOwnProperty(startDate)) {
                      p[startDate] = 0;
                    }
                    p[startDate]++;
                    return p;
                  }, {});
                  
                  this.calendarEvents = Object.keys(counts).map(k => {
                    return {date: k, title: counts[k]}; });

                    this.calendarOptions = {
                        initialDate: moment(new Date()).format('YYYY-MM-DD').toString(),
                        headerToolbar: {
                            left: null,
                            center: 'prev,title,next',
                            right: null
                        },
                        dateClick: this.handleDateClick.bind(this),
                        eventClick: this.handleEventClick.bind(this),
                        
                        events: this.calendarEvents,
                        editable: true,
                        selectable: true,
                        selectMirror: true,
                        dayMaxEvents: true
                    };

                this.handleOnLoadEvent(moment(new Date()).format('YYYY-MM-DD').toString());
                this.pageLoaderService.hidePageLoader();
            });
            
        this.initDropdowns();
    }

    handleDateClick(arg) {
        this.selectedDate = moment(arg.date).format('dddd').toString() + ',' + moment(arg.date).format('DD/MM/YYYY').toString();
        this.selectDateEvents = this.takeOutCalendarData.filter(x => x.startDate.split("T")[0] == arg.dateStr);
    }

    handleEventClick(date){   
        this.selectedDate = moment(date.event.start).format('dddd').toString() + ',' + moment(date.event.start).format('DD/MM/YYYY').toString();
        var dateString = moment(new Date(date.event.start)).format('YYYY-MM-DD').toString();
        this.selectDateEvents = this.takeOutCalendarData.filter(x => x.startDate.split("T")[0] == dateString);
    }

    handleOnLoadEvent(date){
        this.selectedDate = moment(date).format('dddd').toString() + ',' + moment(date).format('DD/MM/YYYY').toString();
        var dateString = moment(new Date(date)).format('YYYY-MM-DD').toString();
        this.selectDateEvents = this.takeOutCalendarData.filter(x => x.startDate.split("T")[0] == dateString);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initDropdowns() {
        this.campaignController.GetKeyValuesPairsOAS().subscribe(data => {
            this.keyValuePairsFriendlyValueSection = data.filter(x => x.definition == "Sport Section").map(x => { return { value: x.friendlyValues, label: x.friendlyValues } });
            this.keyValuePairsFriendlyValuePageTemplate = data.filter(x => x.definition == "Page Template").map(x => { return { value: x.friendlyValues, label: x.friendlyValues } });
            this.keyValuePairsFriendlyValueSection.unshift({ value: null, label: 'Page Section' });
            this.keyValuePairsFriendlyValuePageTemplate.unshift({ value: null, label: 'Page Template' });
        });

        this.accountController.GetAllAccounts('').subscribe(x => {
            x.data.forEach(y => {
               this.keyValuesPairsOAS.push({value: y.oas, label: y.oas})
            })

            this.keyValuesPairsOAS.unshift({ label: "-", value: null });
        })
    }

    isEven(n) {
        return n % 2 == 0;
      }

      filter(){

        if(this.keyValuePairPageTemplate != null){
            this.takeOutCalendarData = this.initTakeOutCalendarData.filter(x => x.pageTamplate == this.keyValuePairPageTemplate);
        }

        else{
            this.takeOutCalendarData = this.initTakeOutCalendarData;
        }

        if (this.keyValuePairSection != null){
            this.takeOutCalendarData = this.takeOutCalendarData.filter(x => x.section == this.keyValuePairSection);
        }

        if(this.OAS != null){
            this.takeOutCalendarData = this.takeOutCalendarData.filter(x => x.oas == this.OAS);
        }

        this.recalculateCalendarEvents(this.takeOutCalendarData);
        this.handleOnLoadEvent(moment(new Date(this.selectedDate.split(',')[1])).format('DD/MM/YYYY').toString());
    }


    recalculateCalendarEvents(data){
        this.calendarEvents = [...[]];
        
        var counts = data.reduce((p, c) => {
            var startDate = c.startDate.split("T")[0];
            if (!p.hasOwnProperty(startDate)) {
                p[startDate] = 0;
            }
            p[startDate]++;
            return p;
            }, {});
            
            this.calendarEvents = Object.keys(counts).map(k => {
            return {date: k, title: counts[k]}; });

        this.calendarOptions.events = this.calendarEvents;
    }
}