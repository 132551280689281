<p-dialog [modal]="true" *ngIf="!!baseCampaign" [(visible)]="isVisible">
    <p-header>Impressions</p-header>

    <!-- <ng-template> -->

    <div class="row mb-3">
        <div class="col-4">
            <label class="required">
                Impressions
            </label>
        </div>

        <div class="col-lg-9 col-12">
            <input class="form-control" [disabled]="true" type="text" [(ngModel)]="totalImpresions">
        </div>
    </div>


    <div *ngIf="!!addOns && !!addOns.length" class="row mb-3">
        <div class="col-5">
            <label class="required">
                Add-on impressions
            </label>
        </div>

        <div class="col-8 mb-3">
            <p-multiSelect [options]="addOns" [(ngModel)]="selectedAddOns" (onChange)="onSelectAdOn()" itemSize="30" selectedItemsLabel="{0} items selected"></p-multiSelect>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" [disabled]="true" type="text" [(ngModel)]="addOnImpressions">
        </div>
    </div>

    <!-- </ng-template> -->


    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>