import { Component, ViewChild, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TargetingController } from '@Workspace/_generated/services';
import { IGoogleGeoTargetDto, IGoogleDmaDto, IGeographicTargetingJson, IGeographicDialogDataDto, ISelectItem, IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, subscribeOn, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PageLoaderService, NotificationService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';
import { eAccountType, eInventoryTypeOAS, eUserRole } from '@Workspace/_generated/enums';

@Component({
    selector: 'campaign-geographic-accordion',
    templateUrl: './campaign-geographic-accordion.component.html',
    styleUrls: ['./campaign-geographic-accordion.component.scss']
})
export class CampaignGeographicAccordion implements OnInit {
    constructor(
        private targetingController: TargetingController,
        private pageLoader: PageLoaderService,
        private authService: AuthService,
        private notificationService: NotificationService
    ) { }

    
    @Output('onSave') onSave: EventEmitter<IGeographicTargetingJson> = new EventEmitter<IGeographicTargetingJson>();
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() disabled: boolean = false;
    
    canEditGeographicsZipCodes: boolean = false;
    yes: boolean = true;
    isVisible: boolean = false;
    errorMessage: string;
    currentCard: number = 1;

    allowedFileExtensions = ['.txt', '.csv'];

    states: SelectItem[];
    cities: SelectItem[];
    dmas: SelectItem[];
    zipCodes: SelectItem[];

    selectedStates: number[] = [];
    selectedCities: number[] = [];
    selectedDmas: number[] = [];
    selectedZipCodes: number[] = [];
    files: File[];

    loading: boolean = false;

    fileZipcodes: string;
    zipCodesFile: File;
    campaign: IBaseCampaignDto;
    pastedZipcodes: string = '';
    customZip: boolean = false;
    isStateChecked: boolean = false;
    defaultPreselected: IGeographicTargetingJson;
    private pastedZipodesChanged$: Subject<string> = new BehaviorSubject<string>(null);
    private ngUnsubscribe = new Subject();
    currentUser = this.authService.userSettings;

    enumAccountType = eAccountType;
    
    ngOnInit() {
        this.pastedZipodesChanged$
            .pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe(result => {
                if (result != null) {
                    this.validateZipcodes(result, 'Incorrect format of pasted zip codes');
                }
            });

            this.canEditGeographicsZipCodes = (!!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.customZip : (x.inventoryItemNew != null ? x.inventoryItemNew.customZip : false))) 
                                            || this.baseCampaign.isGeographicTargetingZipCodesUnlocked == true;

            this.initPage(
                this.baseCampaign.geographicTargetingJson,
                this.baseCampaign.defaultGeographicTargetingJson,
                this.canEditGeographicsZipCodes,
                this.baseCampaign
            );
    }

    initPage(preselected: IGeographicTargetingJson = null, defaultPreselected: IGeographicTargetingJson = null, customZip: boolean, campaign: IBaseCampaignDto) {
        this.isVisible = true;
        this.customZip = customZip;
        this.campaign = campaign;


        this.defaultPreselected = defaultPreselected;


        this.dumpData();
        this.getData(preselected, defaultPreselected);

    }

    openCard(cardNumber: number) {
        this.currentCard = cardNumber;
    }

    getData(preselected: IGeographicTargetingJson = null, defaultPreselected: IGeographicTargetingJson = null) {
        this.loading = true;
        this.targetingController.GetGeographicDialogData(this.campaign.id, this.customZip, this.campaign.account.stateAbbreviation === null ? '' : this.campaign.account.stateAbbreviation)
            .subscribe(
                (result) => {
                    const stateMap = ({ id, name }) => ({ label: name, value: id });
                    const dmaMap = ({ dmaName, dmaCode }) => ({ label: dmaName, value: dmaCode });
                    const cityMap = ({ id, name, stateAbbreviation }) => ({ label: `${name}, ${stateAbbreviation}`, value: id });
                    const zipcodeMap = ({ zipCode, zipCodeId }) => ({ label: `${zipCode}`, value: zipCodeId });

                    const states = result.states.map(stateMap);
                    this.states = states;

                    const dmas = result.dmas.map(dmaMap);
                    this.dmas = this.mapUnique(dmas);

                    const cities = result.cities.map(cityMap);
                    this.cities = this.mapUnique(cities);

                    const zipcodes = result.zipcodes.filter(x => x.zipCodeId !== null).map(zipcodeMap);
                    this.zipCodes = zipcodes;

                    if(this.currentUser.permissions.isClient && this.currentUser.userRole!=eUserRole.LIMGCSocialAdOps && this.currentUser.userRole!=eUserRole.LIMGC_AdOps_Admin
                         && this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignInventoryItems[0].inventoryItemOASId != null && (this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.inventoryType == eInventoryTypeOAS.Ros_Display_Campaign || this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.inventoryType == eInventoryTypeOAS.Ros_Video_Campaign)
                         && (!!preselected && (preselected.dmaCodes.length==0 && preselected.cities.length==0))){
                        this.isStateChecked=true;
                        this.disableOther(true);
                    }
                    else if(this.baseCampaign.campaignType == eAccountType.FAN365 && (!!preselected && (preselected.dmaCodes.length==0 && preselected.cities.length==0))){
                       this.isStateChecked=true;
                       this.disableOther(true);
                   }
                    else if (!!preselected && (!!preselected.dmaCodes.length || !!preselected.cities.length || !!preselected.states.length || !!preselected.zipCodes.length || (!!preselected.customZipCodes && !!preselected.customZipCodes.length))) {
                        this.preselectDropdowns(preselected);
                    }
                    else
                        this.preselectDropdowns(defaultPreselected);
                    this.loading = false;
                    this.pageLoader.hidePageLoader()

                },
                (error) => { this.pageLoader.hidePageLoader() });
    }

    disableOther(event) {

        if (event) {
            this.selectedDmas = [];
            this.selectedCities = [];

            this.selectedZipCodes = [];

            this.pastedZipcodes = '';
            this.fileZipcodes = '';

        }

        this.detectChanges();
    }


    private preselectDropdowns(preselected: IGeographicTargetingJson) {
        let stateIds :any;
        let dmaCodes :any;
        let cityIds = [];
        let zipCodeIds = [];

        if (!!preselected?.states && !!preselected?.states.length && !this.baseCampaign.account.isCampaignCreation)
            this.isStateChecked = true;

        if(this.baseCampaign.account.isCampaignCreation){
            if (!!preselected?.dmaCodes){
                dmaCodes = preselected.dmaCodes.map(x => x.id)[0];
            }
            else if (this.baseCampaign.account.geographicTargetDMA){
              dmaCodes = parseInt(this.baseCampaign.account.geographicTargetDMA);
            }
            else if(!preselected?.dmaCodes && !this.baseCampaign.account.geographicTargetDMA){
                dmaCodes = [];
            }

            if (!!preselected?.cities){
               cityIds = preselected.cities.map(x => Number(x.id));
            }

            else if(this.baseCampaign.account.geographicTargetCity){
                cityIds = this.baseCampaign.account.geographicTargetCity.split(',').map(function (b, i) {return Number(b)});
            }
            else if(!preselected?.cities && !this.baseCampaign.account.geographicTargetCity){
                cityIds = [];
            }

            if (!!preselected?.states){
                stateIds = preselected.states.map(x => x.id)[0];
            }

            else if(this.baseCampaign.account.geographicTargetState){
                stateIds = parseInt(this.baseCampaign.account.geographicTargetState);
            }
            else if(!preselected?.states && !this.baseCampaign.account.geographicTargetState){
                stateIds = [];
            }
        }

        else{
            if (!!preselected?.dmaCodes)
                dmaCodes = preselected.dmaCodes.map(x => x.id);

            if (!!preselected?.cities)
                cityIds = preselected.cities.map(x => x.id);

        }
        
        if (!!preselected?.zipCodes)
            zipCodeIds = preselected.zipCodes.map(x => x.id);



        this.selectedStates = stateIds;
        this.selectedDmas = dmaCodes;
        this.selectedCities = cityIds;
        this.selectedZipCodes = zipCodeIds;

        if (!!preselected?.customZipCodes) {
            let pastedZipcodes = '';

            preselected.customZipCodes.forEach(x => pastedZipcodes += `${x}, `);
            this.pastedZipcodes = pastedZipcodes.substring(0, pastedZipcodes.length - 2);
        }


    }

    // file handling

    parseFile(files: FileList) {
        if (!!files[0]) {
            this.pageLoader.showPageLoader();
            this.notificationService.info('Processing file...');
            const file = Array.from(files)[0] as Blob;
            const reader = new FileReader();

            reader.readAsText(file);
            reader.onloadend = () => {
                this.pageLoader.hidePageLoader();
                const result = reader.result as string;
                this.fileZipcodes = result;
                this.validateZipcodes(result, 'Incorrect zip code format found in file');
                this.detectChanges();
            }
        }
    }

    // validations

    private validateZipcodes(inputContent: string, errorMsg: string): boolean {
        let valid = true;
        const isEmpty = inputContent === null || inputContent === undefined || inputContent.length === 0;

        if (isEmpty) {
            return valid;
        }

        // const usaZipFormat = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        const zipcodes = inputContent.split(',');
        const isDigit = /^\d+$/;

        zipcodes.forEach(x => {
            const code = x.trim();
            const validCode = isDigit.test(code) && code.length === 5;

            valid = valid && validCode;
        });

        this.errorMessage = valid ? null : errorMsg;


        return valid;
    }

    private splitZipcodes(inputContent: string): string[] {
        let splittedZips: string[] = [];
        const isEmpty = inputContent === null || inputContent === undefined || inputContent.length === 0;

        if (!isEmpty) {
            splittedZips = inputContent.split(',');
        }

        return splittedZips;
    }

    // misc

    onTextareaChanged(zipcodes: string) {
        this.pastedZipodesChanged$.next(zipcodes);
        this.detectChanges();
    }



    detectChanges() {
        let valid = true;
        valid = this.validateZipcodes(this.pastedZipcodes, 'Incorrect format of pasted zip codes');

        if (!valid) {
            return;
        }

        valid = this.validateZipcodes(this.fileZipcodes, 'Incorrect zip code format found in file');
        if (!valid) {
            return;
        }

        const pastedZips = this.splitZipcodes(this.pastedZipcodes);
        const filedZips = this.splitZipcodes(this.fileZipcodes);
        const customZipcodes = Array.from(new Set([...pastedZips, ...filedZips]));

        const itemMap = ({ label, value }) => ({ id: value, name: label } as ISelectItem)
        let selectedStates = [];
        let selectedCities = [];
        let selectedDmas = [];
        let zips = [];
        let selectedZips = [];

        if (!!this.selectedStates && !this.selectedStates.length && !this.selectedStates && !!this.selectedCities && !this.selectedCities.length && !this.selectedCities 
            && !!this.selectedDmas && !this.selectedDmas.length && !this.selectedDmas && !!this.selectedZipCodes && !this.selectedZipCodes.length && !this.selectedZipCodes
            && !!customZipcodes && !customZipcodes.length && !customZipcodes) {
            this.preselectDropdowns(this.defaultPreselected);

        }

        if (this.isStateChecked) {
            selectedStates = this.states.map(itemMap);
        } else {
            if(this.baseCampaign.account.isCampaignCreation){
                selectedCities = this.cities.filter(x => this.selectedCities.includes(x.value)).map(itemMap);
                selectedStates = this.states.filter(x => x.value == this.selectedStates).map(itemMap);
                selectedDmas = this.dmas.filter(x => x.value == this.selectedDmas).map(itemMap);
            }
            else{
                selectedCities = this.cities.filter(x => this.selectedCities.includes(x.value)).map(itemMap);
                selectedDmas = this.dmas.filter(x => this.selectedDmas.includes(x.value)).map(itemMap);    
            }

            zips = this.zipCodes.filter(x => this.selectedZipCodes.includes(x.value)).map(itemMap);
            selectedZips = this.mapUniqueISelectItem(zips);
        }



        const result = {
            states: selectedStates,
            cities: selectedCities,
            dmaCodes: selectedDmas,
            zipCodes: selectedZips,
            customZipCodes: customZipcodes
        } as IGeographicTargetingJson;


        this.onSave.emit(result);
        //this.dumpData();
        //this.isVisible = false;
    }

    cancel() {
        this.dumpData();
        this.isVisible = false;
    }

    private mapUnique(items: SelectItem[]): SelectItem[] {
        let result: SelectItem[] = [];
        let map = new Map();

        items.forEach(x => {
            if (!map.has(x.label)) {
                map.set(x.label, true);
                result.push({ label: x.label, value: x.value });
            }
        });

        return result;
    }

    private mapUniqueISelectItem(items: ISelectItem[]): ISelectItem[] {
        let result: ISelectItem[] = [];
        let map = new Map();

        items.forEach(x => {
            if (!map.has(x.id)) {
                map.set(x.id, true);
                result.push({ id: x.id, name: x.name });
            }
        });

        return result;
    }


    private dumpData() {
        this.errorMessage = '';
        this.files = [];
        this.states = [];
        this.cities = [];
        this.dmas = [];
        this.zipCodes = [];
        this.selectedStates = [];
        this.selectedCities = [];
        this.selectedDmas = [];
        this.selectedZipCodes = [];
        this.fileZipcodes = '';
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}