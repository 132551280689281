<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>{{dynamic?'Dynamic':'Static'}} News Feed</strong></span>
</div>
<div class="pl-3" [ngClass]="this.currentUser.permissions.isLearfieldClient ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign || (creative.name && this.campaign.account.isHubStandardCampaign)" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="creative.flightStartDate && creative.flightEndDate"> | {{(this.creative.flightStartDate) | date : constants.DEFAULT_FORMAT_DATE_US}} - {{(this.creative.flightEndDate) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
        </div>
        <div *ngIf="canDeleteCreative" class="d-flex float-right align-items-center justify-content-center deleteAsset delete-button">
            <i (click)="deleteCreative()" class="fa fa-trash-alt"></i>
        </div>
    </div>

    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div class="mb-4 mt-3 pt-3">


        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Headline</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage">{{creative.detailJson.headline}}</label>

                        <div *ngIf="!reviewPage  " class="input-group">
                            <input class="form-control" type="text" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" [maxlength]=25 [(ngModel)]="creative.detailJson.headline" [title]="creative.detailJson.headline" style="overflow: hidden; text-overflow: ellipsis;">
                            <label class="input-group-append px-2 d-flex align-items-center justify-content-center">{{25-creative.detailJson.headline.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" *ngFor="let item of creative.detailJson.bodyText; let i = index; trackBy:indexTracker">
            <div class="col-lg-2 col-12">
                <label><strong>{{campaign.campaignType == enumAccountType.HS ? 'Primary (Body) Text' : 'Body Text'}} {{ dynamic? i+1: ''}}</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage">{{creative.detailJson.bodyText[i]}}</label>

                        <div *ngIf="!reviewPage" class="input-group mb-2">
                            <input class="form-control" type="text" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" [maxlength]=90 [(ngModel)]="creative.detailJson.bodyText[i]" [title]="creative.detailJson.bodyText[i]" style="overflow: hidden; text-overflow: ellipsis;">
                            <div class="input-group-append align-items-center px-2" [ngClass]="i>2 && this.currentUser.permissions.isClient?'clickDiv':''">
                                <label class=" m-auto" [ngClass]="i>2 && this.currentUser.permissions.isClient?'charLen':''">{{90-creative.detailJson.bodyText[i].length}} CHARACTERS LEFT</label>
                                <label *ngIf="i>2 && this.currentUser.permissions.isClient" class="charLen m-auto"> | <i class="fas fa-trash-alt"></i> </label>
                                <label *ngIf="i>2 && this.currentUser.permissions.isClient && this.currentUser.userRole !=enumUserRole.LIMGC_AdOps_Admin && this.currentUser.userRole !=enumUserRole.LIMGCSocialAdOps && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" (click)="removeBodyText(i)" class="delete m-auto"><i class="fas fa-trash-alt"></i></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!reviewPage && dynamic && creative.detailJson.bodyText.length<25 && this.currentUser.permissions.isClient && this.currentUser.userRole !=enumUserRole.LIMGC_AdOps_Admin && this.currentUser.userRole !=enumUserRole.LIMGCSocialAdOps && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" class="row offset-md-2 mb-2">
            <div class="col-sm-push-2 bd-highlight"><button type="button" pButton (click)="addBodyText()" class="flat-btn" label="{{campaign.campaignType == enumAccountType.HS ? '+ Add Primary (Body) Text' : '+ Add Body Text'}}"></button></div>
            <div class="d-flex align-items-center justify-content-center px-2">You can add up to 25</div>
        </div>


        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>{{campaign.campaignType == enumAccountType.HS ? 'Description (Link Description)' : 'Link Description'}}</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage">{{creative.detailJson.linkDescription}}</label>
                        <div class="input-group" *ngIf="!reviewPage ">
                            <input class="form-control" type="text" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" [maxlength]=90 [(ngModel)]="creative.detailJson.linkDescription" [title]="creative.detailJson.linkDescription" style="overflow: hidden; text-overflow: ellipsis;">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{90-creative.detailJson.linkDescription.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Landing Page URL</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input [disabled]='!!landingPage || !functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)' class="form-control" *ngIf="!reviewPage " type="text"  [(ngModel)]="!!landingPage?landingPage: creative.detailJson.landingPageUrl" [title]="isLandingUrlOk == false ? titleWrongUrl : !!landingPage?landingPage:creative.detailJson.landingPageUrl" style="overflow: hidden; text-overflow: ellipsis;" [ngClass]="this.isLandingUrlOk == false ? 'border-wrong' : ''" (focusout)="validationLandingPageMessage()">
                <span *ngIf="!reviewPage" tooltipPosition="top" [pTooltip]='constants.landingPageInfo' tooltipStyleClass="tooltipStyle" class="fal fa-info-circle info"></span>
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.landingPageUrl}}</label>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Call to Action</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" (onChange)="setCreativeCallToAction($event)" *ngIf="!reviewPage " autoWidth="false" [(ngModel)]="creative.callToAction" [options]="callToActionDD" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)"></p-dropdown>
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{callToActionLabel}}</label>
            </div>
        </div>
    </div>

    <div class="mb-3 d-flex flex-wrap">
        <div class="mb-4" [ngClass]="isApprove? 'col-lg-6 col-12':'col-lg-4 col-12'" *ngFor="let item of creative.creativeAssets; let i = index">
            <file-upload #fileUpload (openPreviewEvent)="openPreview($event)" (onAssetDelete)="removeAsset($event)" (uploadValidationMessage)="uploadWarning($event)" [reviewPage]='reviewPage' [isApprovedReported]='item.isValid!=null' [isApprove]='isApprove' filetypes="{{this.campaign.account.isLearfieldCampaign ?'mp4, jpg, jpeg':'jpg, jpeg'}}" size="{{this.campaign.account.isLearfieldCampaign?'4294967296':'1048576'}}" [footer]='booleanFalse' [canDelete]="dynamic && i > 0?booleanTrue:booleanFalse" [campaign]="this.campaign" [creative]="this.creative" [asset]="i"></file-upload>
            <!-- filetypes="jpg, jpeg" size=1048576 -->
        </div>

        <div *ngIf="!reviewPage && dynamic && this.currentUser.permissions.isClient && this.currentUser.userRole !=enumUserRole.LIMGC_AdOps_Admin && this.currentUser.userRole !=enumUserRole.LIMGCSocialAdOps && creative.creativeAssets.length<4 && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" class="col-4" style="cursor: pointer;">
            <div class="upload-wrapper border-ok text-center mt-2 d-flex flex-column align-items-center justify-content-center" style="height: 200px;" (click)="addCreativeAsset()">
                <i class="d-flex align-items-center justify-content-center fa fa-fw fa-plus"></i>
                <div>Add up to {{4 - creative.creativeAssets.length}} more {{creative.creativeAssets.length == 3 ? 'image' : 'images'}}</div>
            </div>
        </div>

    </div>
</div>