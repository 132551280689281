<div class="card card-body m-3" *ngIf="(campaignService.getIsGrid() | async) || isGrid">
    <div class="row align-items-center">
        <div class="display-3 col-6">Teams & Users</div>
        <div class="col-6 d-flex flex-wrap justify-content-end">
            <button (click)="navigateTeam()" pButton label="ADD NEW TEAM" class="p-button-secondary mr-3"></button>
            <button (click)="navigateUser()" pButton label="ADD NEW USER" class="p-button-primary "></button>

        </div>
    </div>
</div>
<div class="card card-body mx-3">
    <div class="row mb-3">
        <div *ngIf="checkRoute()" class="col-6">
            <p-dropdown autoWidth="false" [(ngModel)]="gridId" class="col-4 p-0" (onChange)="onChangeGrid($event)" [options]="grids">
            </p-dropdown>
        </div>
        <div *ngIf="checkRoute()" class="col-6">
            <div class="p-inputgroup float-right">
                <input type="text" pInputText [(ngModel)]="searchParameter" placeholder="Search" (keyup.enter)="composeFilter()" style="border-right: 0;" />
                <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="composeFilter()"></button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<!-- <div class="card card-body mx-3">
    <div class="row">
        <div *ngIf="isGrid" class="col-12 dropdown-wrapper">
            <p-dropdown autoWidth="false" [(ngModel)]="gridId" (onChange)="onChangeGrid($event)" [options]="grids">
            </p-dropdown>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-5 offset-7">
                    <div class="p-inputgroup w-100">
                        <input class="w-100" type="text" pInputText [(ngModel)]="searchParameter" placeholder="Search" (keyup.enter)="composeFilter()" />
                        <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="composeFilter()"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div> -->