import { OnInit, OnDestroy, Component, ViewChild } from '@angular/core';
import { AuthService } from '@Workspace/auth';
import { CampaignController } from '@Workspace/_generated/services';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '../../../services/campaign.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { PageLoaderService } from '@Workspace/services';
import { eAccountType, eUserRole } from '@Workspace/_generated/enums';
import { CampaignReportTableComponent } from '../../../components/campaign/campaign-report/campaign-report-table/campaign-report-table.component';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'campaign-report-page',
    templateUrl: './campaign-report.page.html',
    // styleUrls: ['./campaign-detail.component.scss'],

})
export class CampaignReportPage implements OnInit {
    constructor(public authService: AuthService,
        private campaignController: CampaignController,
        private activatedRoute: ActivatedRoute,
        private campaignService: CampaignService,
        private messageService: MessageService,
        private pageLoder: PageLoaderService
    ) { }

    ngUnsubscribe = new Subject();
    campaignId: string;
    baseCampaign: IBaseCampaignDto;
    currentUser = this.authService.userSettings;
    file: any;
    enumUserRole = eUserRole;
    enumAccountType = eAccountType;
    @ViewChild(CampaignReportTableComponent) campaignReportTable: CampaignReportTableComponent;
    
    ngOnInit() {
        this.campaignService.getRefresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            this.activatedRoute.params
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(params => {
                    this.campaignId = params.slug;
                });
            this.pageLoder.showPageLoader();
            let filter = `?$filter=Id eq ${this.campaignId}`;
            this.campaignController.GetAllCampaigns(filter).subscribe(data => {
                if (data.totalCount > 0) {
                    this.baseCampaign = data.data[0];
                    if (!!this.baseCampaign.reportFile) {
                        this.pageLoder.showPageLoader();
                        this.campaignController.GetCampaignFile(encodeURIComponent(this.baseCampaign.reportFile.fileName), this.baseCampaign.id)
                            .subscribe(
                                result => {
                                    if (!!result) {
                                        this.file = this.byteArrayToFileBlob(result as any);
                                    }
                                    this.pageLoder.hidePageLoader();
                                });
                    }
                }
                this.pageLoder.hidePageLoader();
            },
            error => {
                this.pageLoder.hidePageLoader();
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
            });
        });
    }

    private byteArrayToFileBlob(base64: string) {
        let binaryString = window.atob(base64);
        const length = binaryString.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return new Blob([bytes], { type: 'application/pdf' });
    }
    idsSaved(event: any){
        if(this.baseCampaign.campaignType == eAccountType.FAN365)
        {
            // if((!this.baseCampaign.campaignJSON || !JSON.parse(this.baseCampaign.campaignJSON)["DV360"]["insertion_order_id"]
            //         || !JSON.parse(this.baseCampaign.campaignJSON)["TTD"]["campaign_id"]) && !!event.dV360CampaignId && !!event.ttdCampaignId)
            // {
            this.campaignReportTable.getCampaignReport(event.dV360CampaignId, event.ttdCampaignId, 0, 10, 'GCM', true);
            // }
        }

        else if(this.baseCampaign.campaignType == eAccountType.OAS)
        {
            // if((!this.baseCampaign.campaignJSON || !JSON.parse(this.baseCampaign.campaignJSON)["GAM"]["campaign_id"]) && !!event.gamCampaignId)
            // {
            this.campaignReportTable.getCampaignReport(event.gamCampaignId, null, 0, 10, 'GAM', true);
            // }
        }
    }
}