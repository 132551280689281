import { DatePipe } from '@angular/common';
import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { AuthService } from '@Workspace/auth';
import { Constants } from '@Workspace/common';
import { IGridOptions, IPagingResult } from '@Workspace/interfaces';
import { PageLoaderService } from '@Workspace/services';
import { eUserRole } from '@Workspace/_generated/enums';
import { ICampaignReportDto, IUserPropertiesDto } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { MessageService } from 'primeng/api';
import { ObjectUtils } from 'primeng/utils';
import { Subject } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil } from 'rxjs/operators';
import { CampaignService } from '../../../../services/campaign.service';

@Component({
    selector: 'campaign-report-table',
    templateUrl: './campaign-report-table.component.html',
    styleUrls: ['./campaign-report-table.component.scss']
})
export class CampaignReportTableComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        private campaignService: CampaignService,
        private pageLoaderService: PageLoaderService,
        private authService: AuthService,
        private dateTime: DatePipe,
        private pageLoader: PageLoaderService,
        private messageService: MessageService
    ) {}

    @Input() title: string;
    @Input() baseCampaignName: string;
    @Input() procedureCampaignId: string;
    @Input() baseCampaignId: string;
    @Input() isReportVisible: boolean;
    
    options: IGridOptions;
    ngUnsubscribe: Subject<void> = new Subject();
    totalCount: any;
    totalImpressions: number;
    totalClicks: number;
    totalCTR: number;
    totalStartDate: Date;
    totalEndDate: Date; 
    enumUserRole = eUserRole;
    currentUser: IUserPropertiesDto = this.authService.userSettings;
    constants = Constants;
    GCM_Campaign_ID: number;
    GAM_Campaign_ID: number;
    DV360_Campaign_ID: number;
    TTD_Campaign_ID: string;
    campaignReportPagingResult = {} as IPagingResult;
    skipParam:number;
    takeParam:number;
    reportExist:boolean = false;
    campaignReportDto = {} as ICampaignReportDto;

    async ngOnInit() {

        this.campaignService
            .getRefresh()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(x => {
                this.composeGridColumns();
            });

        try{
            const result = await this.campaignController.GetCampaignExternalIds(this.baseCampaignId).pipe(take(1)).toPromise();

            this.GCM_Campaign_ID = this.procedureCampaignId ? JSON.parse(this.procedureCampaignId)["GCM_Campaign_ID"]: null;
            this.GAM_Campaign_ID = this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["GAM"] ? parseInt(JSON.parse(this.procedureCampaignId)["GAM"]["campaign_id"]) : null;

            this.DV360_Campaign_ID = this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["DV360"] && JSON.parse(this.procedureCampaignId)["DV360"]["insertion_order_id"]
                                    ? parseInt(JSON.parse(this.procedureCampaignId)["DV360"]["insertion_order_id"]) : null;

            this.TTD_Campaign_ID = this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["TTD"] && JSON.parse(this.procedureCampaignId)["TTD"]["campaign_id"] 
                                    ? JSON.parse(this.procedureCampaignId)["TTD"]["campaign_id"] : null;

            if(result != null){
                this.GCM_Campaign_ID = result.gcmCampaignId != null ? result.gcmCampaignId : this.GCM_Campaign_ID;
                this.GAM_Campaign_ID = result.gamOrderId != null ? result.gamOrderId : this.GAM_Campaign_ID;
                this.DV360_Campaign_ID = result.dV360CampaignId != null ? result.dV360CampaignId : this.DV360_Campaign_ID;
                this.TTD_Campaign_ID = result.ttdCampaignId != null ? result.ttdCampaignId : this.TTD_Campaign_ID;
            }
            
            switch (this.title) {
                case 'Fan365': //GCM
                // if(this.GCM_Campaign_ID)
                //     this.getCampaignReport(this.GCM_Campaign_ID, null, 0, 10, 'GCM');
                if(this.DV360_Campaign_ID || this.TTD_Campaign_ID){
                    this.getCampaignReport(this.DV360_Campaign_ID, this.TTD_Campaign_ID, 0, 10, 'GCM');
                }
                break;
            
                case 'OAS': //GAM
                if(this.GAM_Campaign_ID){
                    this.getCampaignReport(this.GAM_Campaign_ID, null, 0, 10, 'GAM');
                }
                break;
            }
        }
        catch(error){
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong with loading External IDs.' });
        }
    }

    getCampaignReport(procedureId, procedureIdTTD,  skipParam, takeParam, type, isFromExternalIds = false){
        if(isFromExternalIds)
        {
            this.GCM_Campaign_ID = type == 'GCM' && procedureId != null 
                ? procedureId : this.procedureCampaignId && type == 'GCM'
                    ? JSON.parse(this.procedureCampaignId)["GCM_Campaign_ID"]: null;;
            this.DV360_Campaign_ID = type == 'GCM' && procedureId != null 
                ? procedureId : type == 'GCM' && this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["DV360"] && JSON.parse(this.procedureCampaignId)["DV360"]["insertion_order_id"] 
                    ? parseInt(JSON.parse(this.procedureCampaignId)["DV360"]["insertion_order_id"]) : null;
            this.TTD_Campaign_ID = type == 'GCM' && procedureIdTTD != null 
                ? procedureIdTTD : type == 'GCM' && this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["TTD"] && JSON.parse(this.procedureCampaignId)["TTD"]["campaign_id"] 
                    ? JSON.parse(this.procedureCampaignId)["TTD"]["campaign_id"] : null;;
            this.GAM_Campaign_ID = type == 'GAM' && procedureId != null 
                ? procedureId : type == 'GAM' && this.procedureCampaignId && JSON.parse(this.procedureCampaignId)["GAM"] 
                    ? parseInt(JSON.parse(this.procedureCampaignId)["GAM"]["campaign_id"]) : null;
        }

        this.campaignReportDto.dv360CampaignId = this.DV360_Campaign_ID;
        this.campaignReportDto.ttdCampaignId = this.TTD_Campaign_ID;
        this.campaignReportDto.gamCampaignId = this.GAM_Campaign_ID;
        this.campaignReportDto.type = type;
        this.campaignReportDto.skip = skipParam;
        this.campaignReportDto.take = takeParam;

        if((type == 'GCM' && (!!this.campaignReportDto.dv360CampaignId || !!this.campaignReportDto.ttdCampaignId)) || (type == 'GAM' && !!this.campaignReportDto.gamCampaignId)){
            this.pageLoaderService.showPageLoader();
            this.campaignController
                .GetCampaignReport(this.campaignReportDto)
                .pipe(take(1))
                .subscribe(
                    result => {
                        if(result.data){
                            this.campaignReportPagingResult = {
                                data: result.data,
                                skip: 0,
                                take: result.data.length,
                                totalCount: result.totalCount
                            };
                            this.totalCount = skipParam == 0 ? result.totalCount : this.totalCount;
                            this.totalCTR = skipParam == 0 ? result.data[0].ctr : this.totalCTR;
                            this.totalImpressions = skipParam == 0 ? result.data[0].impressions : this.totalImpressions;
                            this.totalClicks = skipParam == 0 ? result.data[0].clicks : this.totalClicks;
                            this.totalStartDate = skipParam == 0 ? result.data[0].date : this.totalStartDate;
                            this.totalEndDate = skipParam == 0 ? result.data[0].endDate : this.totalEndDate;
                            this.skipParam = skipParam;
                            this.takeParam = 10;
                            this.reportExist = true;
                        }
                        else {
                            this.reportExist = false;
                        }
                        this.pageLoaderService.hidePageLoader();
                    },
                    error => {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
                        this.pageLoaderService.hidePageLoader();
                        this.reportExist = false;
                    }
                );
        }
    }

    ngOnDestroy(): void {}
    composeGridColumns(searchParameter?: string) {
        this.options = {} as IGridOptions;
        this.options.columns = [
            {
                label: 'Date',
                value: 'date',
                width: '25%'
            },
            {
                label: 'Impressions',
                value: 'impressions',
                width: '25%'
            },
            {
                label: 'Clicks',
                value: 'clicks',
                width: '25%'
            },
            {
                label: 'CTR',
                value: 'ctr',
                width: '25%'
            }            
        ];
    }

    exportToCSV(){
        this.pageLoaderService.showPageLoader();
        switch (this.title) {
            case 'Fan365': //GCM
                //this.exportToCSVSpecificCampaign(this.GCM_Campaign_ID, null, 'GCM');
                this.exportToCSVSpecificCampaign(this.DV360_Campaign_ID, this.TTD_Campaign_ID, 'GCM');
                break;
            case 'OAS': //GAM
                this.exportToCSVSpecificCampaign(this.GAM_Campaign_ID, null, 'GAM');
                break;
        }
    }

    exportToCSVSpecificCampaign(campaignId, TTDCampaignId, type){
        if(type == "GCM"){
            this.campaignReportDto.dv360CampaignId = campaignId;
            this.campaignReportDto.ttdCampaignId = TTDCampaignId;
        }
        else if(type == "GAM")
            this.campaignReportDto.gamCampaignId = campaignId;
        this.campaignReportDto.type = type;
        this.campaignReportDto.skip = 0;
        this.campaignReportDto.take = this.totalCount;

        this.campaignController
            .GetCampaignReport(this.campaignReportDto)
            .pipe(take(1))
            .subscribe(
                result => {
                    if(result.data){
                        let csv = '\ufeff';
                        for (let i = 0; i < this.options.columns.length; i++) {
                            let column = this.options.columns[i];
                            if (column.label) {
                                csv += '"' + column.label + '"';
                                if (i < this.options.columns.length - 1) {
                                    csv += ',';
                                }
                            }
                        }
                        result.data.forEach(record => {
                            csv += '\n';
                            for (let i = 0; i < this.options.columns.length; i++) {
                                let column = this.options.columns[i];
                                if (column.value) {
                                    let cellData;
                                    if(column.value == 'date' && record.id == Constants.GuidEmpty)
                                        cellData = 'Total (' + this.formatDate(record.date) + ' - ' + this.formatDate(record.endDate) + ')';

                                        
                                    else if(column.value == 'ctr')
                                        cellData = record.ctr + '%';
                                    else 
                                        cellData = ObjectUtils.resolveFieldData(
                                            record,
                                            column.value
                                        );

                                    if (cellData != null) {
                                        cellData = String(cellData).replace(/"/g, '""' );
                                        cellData = String(cellData).replace(/T00:00:00/g,'');
                                    } 
                                    else cellData = '';

                                    csv += '"' + cellData + '"';
                                    if (i < this.options.columns.length - 1) {
                                        csv += ',';
                                    }
                                }
                            }
                        });
    
                        let blob = new Blob([csv], {
                            type: 'text/csv;charset=utf-8;'
                        });
    
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        if (link.download !== undefined) {
                            link.setAttribute('href', URL.createObjectURL(blob));
                            link.setAttribute(
                                'download',
                                this.title + '_' + this.baseCampaignName + '.csv'
                            );
                            link.click();
                        } else {
                            csv = 'data:text/csv;charset=utf-8,' + csv;
                            window.open(encodeURI(csv));
                        }
                        document.body.removeChild(link);
                    }
                    this.pageLoaderService.hidePageLoader();
                },
                error => {
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
                    this.pageLoaderService.hidePageLoader();
                }
            );
    }

    formatDate(dateProp: any) {
        var date = new Date(dateProp);
        return `${this.dateTime.transform(dateProp, this.constants.DEFAULT_FORMAT_DATE_US)}`;
    }

    saveIsReportVisible(){
        this.pageLoader.showPageLoader();
        this.campaignController
            .SetReportVisibility(this.baseCampaignId, this.isReportVisible)
            .pipe(take(1))
            .subscribe(
                result => {
                    this.messageService.add({ severity: 'success', detail: this.isReportVisible ? 'Report visibility set to visible' : 'Report visibility set to not visible' });
                    this.pageLoader.hidePageLoader();
                },
                error => {
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong, visibility not saved.' });
                    this.pageLoader.hidePageLoader();
                }
            );
    }
}