<div #scrollHeader class="p-table-scrollable-header p-component-header">
    <div #scrollHeaderBox class="p-table-scrollable-header-box">
        <table class="p-table-scrollable-header-table" [ngClass]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
            <ng-container *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
            <thead class="p-table-thead">
                <ng-container *ngTemplateOutlet="frozen ? dt.frozenHeaderTemplate||dt.headerTemplate : dt.headerTemplate; context {$implicit: columns}"></ng-container>
            </thead>
            <tbody class="p-table-tbody">
                <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="dt.frozenValue" [ngForTrackBy]="dt.rowTrackBy">
                    <ng-container *ngTemplateOutlet="dt.frozenRowsTemplate; context: {$implicit: rowData, rowIndex: rowIndex, columns: columns}"></ng-container>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>
<div #scrollBody class="p-table-scrollable-body">
    <table #scrollTable [ngClass]="{'p-table-scrollable-body-table': true, 'p-table-virtual-table': dt.virtualScroll}" [class]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
        <ng-container *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
        <tbody class="p-table-tbody" [GridBody]="columns" [GridBodyTemplate]="frozen ? dt.frozenBodyTemplate||dt.bodyTemplate : dt.bodyTemplate"></tbody>
    </table>
    <div #virtualScroller class="p-table-virtual-scroller" *ngIf="dt.virtualScroll"></div>
</div>
<div #scrollFooter *ngIf="dt.footerTemplate" class="p-table-scrollable-footer p-component-header">
    <div #scrollFooterBox class="p-table-scrollable-footer-box">
        <table class="p-table-scrollable-footer-table" [ngClass]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
            <ng-container *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
            <tfoot class="p-table-tfoot">
                <ng-container *ngTemplateOutlet="frozen ? dt.frozenFooterTemplate||dt.footerTemplate : dt.footerTemplate; context {$implicit: columns}"></ng-container>
            </tfoot>
        </table>
    </div>
</div>
