import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'unlock-features-dialog',
    templateUrl: './unlock-features-dialog.component.html',
    styleUrls: ['./unlock-features-dialog.component.scss']
})
export class UnlockFeaturesDialog implements OnInit {

    isVisible: boolean = false;
    isSiteVisitTrackingUnlocked: boolean = false;
    isConversionTrackingUnlocked: boolean = false;
    isDataRangeUnlocked: boolean = true;
    isInterestSegmentsUnlocked: boolean = false;
    isDemographicTargetingUnlocked: boolean = false;
    isGeographicTargetingUnlocked: boolean = false;
    isGeographicTargetingZipCodesUnlocked: boolean = false;
    isLearfieldCampaign: boolean = false;
    defaultValues: any;

    constructor() { }

    ngOnInit() {

    }

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    private ngUnsubscribe = new Subject();

    openDialog(isSiteVisitTrackingUnlocked: boolean, isConversionTrackingUnlocked: boolean, isDataRangeUnlocked: boolean,
        isInterestSegmentsUnlocked: boolean, isDemographicTargetingUnlocked: boolean, isGeographicTargetingUnlocked: boolean, isGeographicTargetingZipCodesUnlocked: boolean, isLearfieldCampaign: boolean) {
        this.defaultValues = { ...arguments };
        this.isSiteVisitTrackingUnlocked = arguments[0];
        this.isConversionTrackingUnlocked = arguments[1];
        this.isDataRangeUnlocked = arguments[2];
        this.isInterestSegmentsUnlocked = arguments[3];
        this.isDemographicTargetingUnlocked = arguments[4];
        this.isGeographicTargetingUnlocked = arguments[5];
        this.isGeographicTargetingZipCodesUnlocked = arguments[6];
        this.isLearfieldCampaign = arguments[7]
        this.isVisible = true;

    }

    save() {
        const dialogResult = {
            isSiteVisitTrackingUnlocked: this.isSiteVisitTrackingUnlocked,
            isConversionTrackingUnlocked: this.isConversionTrackingUnlocked,
            isDataRangeUnlocked: this.isDataRangeUnlocked,
            isInterestSegmentsUnlocked: this.isInterestSegmentsUnlocked,
            isDemographicTargetingUnlocked: this.isDemographicTargetingUnlocked,
            isGeographicTargetingUnlocked: this.isGeographicTargetingUnlocked,
            isGeographicTargetingZipCodesUnlocked: this.isGeographicTargetingZipCodesUnlocked
        };


        this.onSave.emit(dialogResult);
        this.isVisible = false;
    }

    cancel() {
        this.isVisible = false;
    }


}
