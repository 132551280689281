import { Component, ViewChild, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MultiSelectItem } from 'primeng/multiselect';
import { ISelectItem, IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Subject, BehaviorSubject } from 'rxjs';
import { CampaignController } from '@Workspace/_generated/services';
import { debounceTime, subscribeOn, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'inventory-add-on-dialog',
    templateUrl: './inventory-add-on-dialog.component.html',
    styleUrls: ['./inventory-add-on-dialog.component.scss']
})
export class InventoryAddOnDialog implements OnInit, OnDestroy {

    baseCampaign: IBaseCampaignDto;
    pssLines: any[];
    isVisible: boolean = false;
    addOns: SelectItem[];
    selectedAddOns: SelectItem[] = [];
    totalImpresions: string;
    addOnImpressions: string;

    private ngUnsubscribe = new Subject();

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public campaignController: CampaignController,
        private decimalPipe: DecimalPipe
    ) { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {

    }

    openDialog(baseCampaign: IBaseCampaignDto) {
        this.isVisible = true;
        this.baseCampaign = baseCampaign;
        this.totalImpresions = this.decimalPipe.transform(this.baseCampaign.impressions);
        this.addOnImpressions = this.decimalPipe.transform(this.baseCampaign.adOnImpressions);

        this.selectedAddOns = !!baseCampaign.pssAdOnsJson ? baseCampaign.pssAdOnsJson.map(x => {
            return x.id;
        }) : [];

        this.campaignController.GetPSSByDealNumberQuery(this.baseCampaign.id, this.baseCampaign.dealId).subscribe(x => {
            this.pssLines = x;
            this.addOns = this.pssLines.map(x => {
                let adOn = {} as SelectItem;
                adOn.value = x.kore_ContractLineId;
                adOn.label = x.inventoryItem;

                return adOn;
            })
        });
    }

    onSelectAdOn() {
        this.baseCampaign.impressions -= this.baseCampaign.adOnImpressions;
        this.baseCampaign.totalHardCost = this.baseCampaign.hardCost;
        this.baseCampaign.totalInventoryItemPrice = this.baseCampaign.inventoryItemPrice;
        this.baseCampaign.adOnImpressions = 0;
        this.selectedAddOns.forEach(x => {
            let line = this.pssLines.find(l => l.kore_ContractLineId == x);
            this.baseCampaign.adOnImpressions += (line.inventoryDuration * line.quantity * line.events);
            this.baseCampaign.totalHardCost = (this.baseCampaign.hardCost ? this.baseCampaign.hardCost : 0) +  line.hardCost;
            this.baseCampaign.totalInventoryItemPrice = (this.baseCampaign.inventoryItemPrice? this.baseCampaign.inventoryItemPrice : 0) + line.inventoryItemPrice;
        });
        this.addOnImpressions = this.decimalPipe.transform(this.baseCampaign.adOnImpressions);
        this.baseCampaign.impressions += this.baseCampaign.adOnImpressions;
        this.totalImpresions = this.decimalPipe.transform(this.baseCampaign.impressions);
    }

    save() {
        const dialogResult = {
            dialogType: 'keyValuePair',
            selectedAddOns: this.addOns.filter(x => this.selectedAddOns.some(si => si == x.value)).map(x => {
                let adOn = {} as ISelectItem;
                adOn.id = x.value;
                adOn.name = x.label;

                return adOn;

            })
        };

        this.onSave.emit(dialogResult);
        this.isVisible = false;
    }

    cancel() {
        this.isVisible = false;
    }

}