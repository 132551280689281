<div *ngIf='!!campaign' class="row pt-3 mb-3">
    <div class="col-12">
        <div class="card-body bg-primary">
            <h6 class="display-4 text-white">
                Please review campaign
            </h6>
            <div class="media text-white">
                <p class="media-body mb-0 small lh-125 border-gray">
                    Before sending the campaign to the client please have one last look.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="card card-body pt-0">
            <h6 class="display-2 my-4 text-muted font-weight-bold">Company Information </h6>

            <div class="row">

                <label class="col-4 required text-dark font-weight-bold">
                    Company
                </label>
                <label class="col-7">{{campaign.account.name}}</label>

            </div>
            <div class="row pt-3">

                <label class="col-4 required text-dark font-weight-bold">
                    Contact Name
                </label>
                <label class="col-7"> {{campaign.contact.fullName}}</label>

            </div>

            <h6 class="display-2 text-muted font-weight-bold my-4">Campaign Information </h6>

            <div class="row">

                <label class="col-4 font-weight-bold">
                    Campaign Name
                </label>
                <label class="col-8"> {{campaign.name}}</label>

            </div>
            <div class="row mt-3">
                <label class="col-4 required font-weight-bold">
                    Campaign Dates
                </label>

                <label class="col-8"> {{startDate | date : constants.DEFAULT_FORMAT_DATE_US}} - {{endDate | date : constants.DEFAULT_FORMAT_DATE_US}}</label>

            </div>
            <div class="row mt-3">

                <label class="col-4 required font-weight-bold">
                    Campaign Due Date
                </label>

                <label class="col-8"> {{dueDate |  date : constants.DEFAULT_FORMAT_DATE_US}}</label>
            </div>
        </div>
    </div>
    <div class="col-6">
        <p-scrollPanel [style]="{width: '100%', height: '450px'}">
            <div class="card card-body pt-0">

                <h6 class="display-2 my-4 text-muted font-weight-bold">Channels & Creatives</h6>

                <div class="row">
                    <div class="col-4">
                        <label class="font-weight-bold required">
                            Channels
                        </label>
                    </div>

                    <div class="col-8">
                        <label>
                            {{channel}}
                        </label>
                    </div>

                </div>
                <div class="row">
                    <div class="col-4">
                        <label class="font-weight-bold required">
                            Creatives
                        </label>
                    </div>

                    <div class="col-8">
                        <div class="row">
                            <div class="col-12 d-flex flex-column" *ngFor="let item of channelGroup.creativeDto | keyvalue">

                                <div>
                                    <label class="text-secondary font-weight-bold">
                                        {{item.value[0].quantity}} x {{item.value[0].displayName}}
                                    </label>
                                </div>
                                <div *ngFor="let data of item.value">

                                    <div>
                                        <label>
                                            Creative Set Name: {{data.name}}
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            Flight Dates: {{data.flightStartLocalDate | date :constants.DEFAULT_FORMAT_DATE_US}} - {{data.flightEndLocalDate | date : constants.DEFAULT_FORMAT_DATE_US}}
                                        </label>
                                    </div>
                                </div>

                                <div>
                                    <label *ngIf="item.value[0].isDisplay">
                                        Banner Dimensions: {{item.value[0].bannerDimensions}}
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </p-scrollPanel>
    </div>
</div>

<div class="row mb-5 mt-3">

    <div class="col-6">
        <button type="button" (click)="onCancel()" pButton class="btn btn-light flat-btn font-weight-bold" label="CANCEL">
        </button>
    </div>

    <div class="col-6 mb-3 d-flex flex-wrap flex-row">
        <div class="mr-auto p-2">
            <button type="button" (click)="onPreviousStep()" class="btn btn-light flat-btn font-weight-bold" pButton label="GO BACK">
            </button>
        </div>

        <div class="p-2">
            <button type="button" [disabled]="isSaved" (click)="save(true)" pButton class="btn btn-light flat-btn font-weight-bold" label="SAVE & EXIT TO HOME">
            </button>
        </div>

        <div class="p-2">
            <button type="button" [disabled]="isSaved" (click)="sendToClient(true)" pButton class="p-button-primary font-weight-bold" label="SEND TO CLIENT">
            </button>
        </div>
    </div>
</div>