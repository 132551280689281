<div class="card card-body">
    <div class="row mb-3">
        <div class="col-12">
            <h6 class="display-4">Channel Options</h6>
            <p class="sub-heading">
                Select from various channel options
            </p>
        </div>
    </div>
    <div *ngIf="!!campaign" class="row">
        <div class="col-lg-4 col-12">
            <ul class="list-group">
                <li class="list-group-item text-uppercase text-primary">
                    Social
                </li>
                <div *ngFor="let item of socialCreatives">
                    <li class="list-group-item">
                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                            <div>
                                <label>
                                    {{item.label}} </label>
                            </div>

                            <div>
                                <p-inputNumber [size]="1" [min]="0" [(ngModel)]="item.quantity" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" decrementButtonClass="bg-white" incrementButtonClass="bg-white" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                                </p-inputNumber>
                            </div>
                        </div>
                    </li>
                </div>

            </ul>
        </div>

        <div *ngIf="!!creativeDto" class="col-lg-4 col-12 mt-3 mt-lg-0">
            <ul class="list-group">
                <li class="list-group-item text-uppercase text-primary">
                    Display Banners
                </li>

                <div *ngFor="let item of displayCreatives; let i= index">
                    <li class="list-group-item">
                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                            <div>
                                <label>
                                    {{item.label}} </label>
                            </div>

                            <div>
                                <p-inputNumber [size]="1" [min]="0" *ngIf="!!creativeDto[item.value]" [(ngModel)]="item.quantity" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" decrementButtonClass="bg-white" incrementButtonClass="bg-white" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                                </p-inputNumber>
                            </div>
                        </div>
                    </li>
                </div>
            </ul>
        </div>

        <div class="col-lg-4 col-12 mt-3 mt-lg-0">
            <ul class="list-group">
                <li class="list-group-item text-uppercase text-primary">
                    Others
                </li>
                <div *ngFor="let item of otherCreatives">
                    <li class="list-group-item">
                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                            <div>
                                <label>
                                    {{item.label}} </label>
                            </div>

                            <div>
                                <p-inputNumber [size]="1" [min]="0" [(ngModel)]="item.quantity" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" decrementButtonClass="bg-white" incrementButtonClass="bg-white" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                                </p-inputNumber>
                            </div>
                        </div>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</div>
<div class="mt-2 footer-btns">
    <div class="d-flex flex-row-reverse bd-highlight">
        <div class="p-2 bd-highlight">
            <button [disabled]="!isNextButtonEnabled()" pButton type="button" class="p-button-primary" (click)="OnNextStep()" label="NEXT"></button>
        </div>
        <!-- <div class="p-2 bd-highlight">
            <button type="button" class="btn btn-light flat-btn">
                SAVE & EXIT TO HOME
            </button>
        </div> -->
        <div class="p-2 bd-highlight">
            <button type="button" (click)="Cancel()" class="btn btn-light flat-btn">
                CANCEL
            </button>
        </div>
    </div>
</div>