<div class="main-content">
    <div class="d-flex flex-column page-container">
        <div [formGroup]="formGroup">
            <div class="row mb-3">
                <div class="col-12">
                    <div class="display-4">
                        Add New Team Information
                        <div class="sub-heading">Create a brand new team</div>
                    </div>
                </div>
            </div>

            <div class="row border-bottom">
                <div class="col-6">
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Team Name
                            </label>
                        </div>

                        <div class="col-8">
                            <input pInputText formControlName="name" class="w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <p-accordion expandIcon="pi pi-fw pi-chevron-right" collapseIcon="pi pi-fw pi-chevron-down" [multiple]="true">
        <p-accordionTab class="d-flex flex-column page-container" header="Add new User to the Team">
            <user-detail [formGroup]="formGroupUser" [isFromTeam]="true" header="Team" (onSaveClick)="saveChanges()" [user]="newUser"></user-detail>
        </p-accordionTab>
        <p-accordionTab class="d-flex flex-column page-container" header="Assign existing User to the Team">
            <user-picklist [isFromTeam]="true" resource="Team" [targetUsers]="teamUsers"></user-picklist>
        </p-accordionTab>
    </p-accordion> -->

    <!-- <div class="row mt-5 mb-3">
        <div class="col-12">
            <div class="display-4 mx-4">
                Add New User to the Team

                 <div class="sub-h]eading">
                    Pick new user to assign to the team
                </div> 
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-12">
            <user-detail [formGroup]="formGroupUser" [isFromTeam]="true" header="Team" (onSaveClick)="saveChanges()" [user]="newUser">
            </user-detail>
        </div>
    </div>

    <!-- <div class="row mt-5 mb-3">
        <div class="col-12">
            <div class="display-4 mx-4">
               Add Existing User to the Team 
             <div class="sub-heading">
                    Pick Existing User to the Team
                </div> 
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-12">
            <user-picklist [isFromTeam]="true" resource="Team" [targetUsers]="teamUsers"></user-picklist>
        </div>
    </div>

    <div class="row mb-5 mt-3 mx-3">
        <div class="col-12 d-flex justify-content-end">
            <button (click)="onCancel()" class="btn btn-light flat-btn mr-3">CANCEL</button>

            <button [disabled]=" (formGroupUser.invalid && teamUsers.length==0) || formGroup.invalid || formGroupUser.invalid" (click)="saveChanges()" label="SAVE & EXIT" pButton class="p-button-primary"></button>
            <!-- 
            <button [disabled]="formGroup.invalid || (formGroupUser.invalid && teamUsers.length==0)" pButton class="p-button-primary" label="SAVE & NEXT"></button> -->
        </div>
    </div>