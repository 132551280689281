<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>YouTube Video</strong></span>
</div>
<div class="pl-3">
    <div *ngIf="creative.name" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="creative.flightStartDate && creative.flightEndDate"> | {{(this.creative.flightStartDate) | date : constants.DEFAULT_FORMAT_DATE_US}} - {{(this.creative.flightEndDate) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
        </div>
    </div>

    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div class="mb-4 mt-3 pt-3">
        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Client YouTube Channel URL</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input *ngIf="!reviewPage" [disabled]='!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)' [maxlength]=2000 class="form-control" type="text"  style="overflow: hidden; text-overflow: ellipsis;" [(ngModel)]="creative.detailJson.clientYoutubeChannelUrl" [ngClass]="this.isChannelUrlOk == false ? 'border-wrong' : ''" (focusout)="validationUrlMessage('clientChannelUrl')">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.clientYoutubeChannelUrl}}</label>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Client YouTube Email</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input *ngIf="!reviewPage" [disabled]='!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)' [maxlength]=2000 class="form-control" type="text" style="overflow: hidden; text-overflow: ellipsis;" [(ngModel)]="creative.detailJson.clientYoutubeEmail" [ngClass]="this.isClientYoutubeEmailOk == false ? 'border-wrong' : ''" (focusout)="validationClientYoutubeEmailMessage()">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.clientYoutubeEmail}}</label>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>YouTube URL to Video</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input *ngIf="!reviewPage" [disabled]='!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)' [maxlength]=2000 class="form-control" type="text" style="overflow: hidden; text-overflow: ellipsis;" [(ngModel)]="creative.detailJson.youTubeUrlToVideo" [ngClass]="this.isYouTubeToVideoUrlOk == false ? 'border-wrong' : ''" (focusout)="validationUrlMessage('youtubeToVideoUrl')">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.youTubeUrlToVideo}}</label>
            </div>
        </div>
    </div>
</div>