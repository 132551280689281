import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import {  SelectItem,  MessageService } from 'primeng/api';
import { GeographicDialog } from '../../../../../shared-core/components/geographic-dialog/geographic.dialog';
import { CampaignController } from '@Workspace/_generated/services';
import { eStandardCampaginChannel,  eSweepstakesCategory, eCreativeTheme, eGameMechanic } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute } from '@angular/router';
import { EnumStringPipe } from '@Workspace/pipes';
import { FileUpload } from '../../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IFacebookDetailsJson, IFacebookNewsFeedDetailJson, IInStreamVideoJson, IDetailJson, ISelectItem, IDetailSocialCampaignJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto, IGeographicTargetingJson } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { Functions, LookUps, Constants } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../../../mogo/services/campaign.service';
import { constants } from 'os';

@Component({
    selector: 'social-cataboom',
    templateUrl: './social-cataboom.component.html',
    styleUrls: ['./social-cataboom.component.scss']
})
export class SocialCataboom implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
        private campaignService: CampaignService
    ) { }

    @ViewChild('geographics') geographicsDialog: GeographicDialog;
    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() campaign: IBaseCampaignDto;

    @Output() Basecampaign: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();

    isPreviewOpen: boolean;
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    constants = Constants;
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    isUploadOk: boolean = true;
    gameMechanicDD: SelectItem[] = [];
    gameMechanicLabel: string;
    
    currentUser = this.authService.userSettings;
    creativeTypes:any = eStandardCampaginChannel;
    minStartDate: Date = new Date();
    creativeName: string;
    geographicsValues = ["", "", "", ""];

    ngOnInit() {

        this.creativeName = new EnumStringPipe().transform(this.creative.creativeTypeId, eStandardCampaginChannel);
        
        if (!!this.campaign.creatives[0].detailSocialCampaignJson && !!this.campaign.creatives[0].detailSocialCampaignJson.geographicTargetingJson)
                    this.setGeographicsCardText(this.campaign.creatives[0].detailSocialCampaignJson.geographicTargetingJson);
        // if (!!this.campaign.creatives[0].detailSocialCampaignJson || !!this.campaign.creatives[0].detailSocialCampaignJson.startDate)
        //     {
        //     this.campaign.creatives[0].detailSocialCampaignJson.startDate = new Date();
        //     this.campaign.creatives[0].detailSocialCampaignJson.endDate = new Date();
        //     }

        this.gameMechanicLabel = Functions.getEnumName(eGameMechanic, this.creative.detailSocialCampaignJson.gameMechanic);
        this.gameMechanicDD = LookUps.getGameMechanicEnum();
        this.gameMechanicDD.unshift({ label: "-", value: 0 });
        
    }
    
    setGameMechanic(event) {
        this.gameMechanicLabel = Functions.getEnumName(eGameMechanic, event.value);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    setGeographicsCardText(event: IGeographicTargetingJson) {
        if (event == null) return;
        this.geographicsValues[0] = !!event.cities && event.cities.length > 0 ? event.cities.map(x => x.name).join(";") : "";
        this.geographicsValues[1] = !!event.dmaCodes && event.dmaCodes.length > 0 ? event.dmaCodes.map(x => x.name).join(";") : "";
        this.geographicsValues[2] = !!event.states && event.states.length > 0 ? event.states.map(x => x.name).join(";") : "";
        this.geographicsValues[3] = !!event.zipCodes && event.zipCodes.length > 0 ? event.zipCodes.map(x => x.name).join(";") : "";
    }

    geographicSaved(event: IGeographicTargetingJson) {
        this.campaign.creatives[0].detailSocialCampaignJson.geographicTargetingJson = event;
        this.setGeographicsCardText(event);
    }

    editEvent(event: string) {
        this.geographicsDialog.openDialog(
            this.campaign.creatives[0].detailSocialCampaignJson.geographicTargetingJson,
            this.campaign.defaultGeographicTargetingJson,
            true,
            this.campaign
        );
        
    }

}
