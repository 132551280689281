import { Component, OnInit } from '@angular/core';
import { eFileType } from '@Workspace/_generated/enums';
import { IFileDto } from '@Workspace/_generated/interfaces';
import { Constants } from '@Workspace/common';

@Component({
    selector: 'media-preview-dialog',
    templateUrl: './media-preview.dialog.html',
    styleUrls: ['./media-preview.dialog.scss']
})
export class MediaPreviewDialog implements OnInit {

    isVisible = false;
    mediaInfo: IFileDto;
    mediaType = eFileType;

    constructor() { }

    ngOnInit() {
    }

    openDialog(mediaInfo: IFileDto) {
        this.mediaInfo = mediaInfo;
        this.isVisible = true;
    }

    IsImage() {
        return Constants.ImageFileTyps.some(x => x == this.mediaInfo.fileType);
    }

    IsAudio() {
        return Constants.AudioFileTyps.some(x => x == this.mediaInfo.fileType);

    }

    IsVideo() {
        return Constants.VideoFileTyps.some(x => x == this.mediaInfo.fileType);

    }

    close() {
        this.isVisible = false;
        this.mediaInfo = null;
    }
}
