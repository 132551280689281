<div class="dropzone" filedrop>
    <input type="file" accept="allowedTypes" [disabled]="disabled" #fileDropRef (change)="onFileSelected($event)" />

    <label *ngIf="!label; else customLabel" for="fileDropRef">Drag and Drop your file or <a class="bold-blue">browse</a></label>
    <ng-template #customLabel for="fileDropRef">{{ label }}</ng-template>
    <div class="allowed-types">
        <div>
            Allowed file types: {{ allowedList }}
        </div>
    </div>
    <div *ngIf="!!files && files.length>0">
        <div>Uploaded:</div>
        <div *ngFor="let file of files">{{ file.name }}</div>
    </div>
    <div *ngIf="incorrectInput" class="error-alert">{{ errorMessage }}</div>
</div>