<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>{{header}}</p-header>

    <!-- <ng-template> -->

    <div class="row mb-3">
        <div class="col-4">
            <label class="required">
                Page Template
            </label>
        </div>

        <div class="col-8">
            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" [(ngModel)]="keyValuePairPageTemplateId" [options]="keyValuePairsFriendlyValuePageTemplate"></p-dropdown>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-4">
            <label class="required">
                Section
            </label>
        </div>

        <div class="col-8">
            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" [(ngModel)]="keyValuePairSectionId" [options]="keyValuePairsFriendlyValueSection"></p-dropdown>
        </div>
    </div>

    <!-- </ng-template> -->


    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" *ngIf="!!keyValuePairPageTemplateId && !!keyValuePairSectionId" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>