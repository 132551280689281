<div *ngIf="account">
    <div class="mx-3">
        <div [formGroup]="formGroup">

            <div class="row mb-3">
                <div class="col-12">
                    <div class="display-4">
                        Reporting
                    </div>

                    <div class="sub-heading">
                        Add links to reports that are associated to this account
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="row mb-3">
                        <div class="col-5">
                            <label class="required">
                                URL Link Name
                            </label>
                        </div>

                        <div class="col-7">
                            <input pInputText formControlName="urlLinkName" class="w-100" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-5">
                            <label class="required">
                                URL
                            </label>
                        </div>

                        <div class="col-7">
                            <input pInputText formControlName="url" class="w-100" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button [disabled]="formGroup.invalid" label="Add Link" pButton class="p-button-secondary" (click)="addReportLink()"></button>
                        </div>
                    </div>
                </div>
                <div class="col-6 links-table px-0">
                    <div class="links-table-header pl-2 py-2">Assigned Links</div>
                    <div *ngFor="let report of account.accountReportingLinks" class="row px-2 pt-2">
                        <div class="col-5">{{report.urlLinkName}}</div>
                        <div class="col-5">{{report.url}}</div>
                        <div class="col-2 text-right">
                            <i (click)="editReportLink(report)" class="fa fa-edit"></i>
                            <i (click)="removeReportLink(report)" class="fa fa-trash"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>