<p-dialog [modal]="true" [contentStyle]="{'overflow': 'visible'}" [(visible)]="isVisible" (onHide)="cancel()">
    <p-header>Creative Flight</p-header>

    <div class="mb-2 mt-2 row">
        <div class="col-md-12">
            <div class="row mb-2">
                <div class="col-3 d-flex align-items-center">
                    <label class="mb-0">Campaign Impressions</label>
                </div>
                <div class="col-5">
                    <input class="form-control" [ngModel]="impressions" type="text"
                        [disabled]="true">
                </div>
                <div class="col-4 d-flex align-items-center">
                    <p-checkbox class="mr-2" #checkBoxAlign [disabled]="atLeastOneBuild" (onChange)="alignCreativeFlights($event)"  [binary]="true" [(ngModel)]="isAlignCreativeFlights"></p-checkbox>
                    <label class="mb-0">Align with creative flights</label>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-3 d-flex align-items-center">
                    <label class="mb-0">Campaign Dates</label>
                </div>
                <div class="col-5">
                    <p-calendar [(ngModel)]="camapignStartDate" [disabled]="true"></p-calendar>
                    <span class="custom-separator">-</span>
                    <p-calendar [(ngModel)]="camapignEndDate" [disabled]="true"></p-calendar>
                </div>
            </div>

            <div [formGroup]="impressionsFormGroup">
                <div *ngFor="let flights of impressionsFlights.controls; let i = index" class="row mt-2">
                    <div [formGroup]="flights" class="d-flex align-items-center">
                        <div class="col-3 d-flex align-items-center">
                            <label class="mb-0">Impression flight {{i+1}}</label>
                        </div>
                        <div class="col-5" *ngIf="checkBoxAlign.model">
                            <p-calendar [minDate]="flights.value.impressionMinStartDate" [maxDate]="flights.value.impressionMaxStartDate" [disabled]="true" formControlName="impressionStartDate"></p-calendar>
                            <span class="custom-separator">-</span>
                            <p-calendar [minDate]="flights.value.impressionMinEndDate" [maxDate]="flights.value.impressionMaxEndDate" [disabled]="true" formControlName="impressionEndDate"></p-calendar>
                        </div>
                        <div class="col-5" *ngIf="!checkBoxAlign.model">
                            <p-calendar [minDate]="flights.value.impressionMinStartDate > tommorowDate ? flights.value.impressionMinStartDate : tommorowDate" 
                                        [maxDate]="flights.value.impressionMaxStartDate"
                                        [defaultDate]="flights.value.impressionMinStartDate" 
                                        (onSelect)="onFlightStartDateChange($event, i)"
                                        formControlName="impressionStartDate"></p-calendar>
                            <span class="custom-separator">-</span>
                            <p-calendar [minDate]="flights.value.impressionMinEndDate < tommorowDate ? tommorowDate : flights.value.impressionMinEndDate" 
                                        [maxDate]="flights.value.impressionMaxEndDate" 
                                        (onSelect)="onFlightEndDateChange($event, i)"  
                                        formControlName="impressionEndDate"></p-calendar>
                        </div>

                       
                        <div class="col-3 d-flex align-items-center justify-content-between">
                    <p-inputNumber required formControlName="impression"
                            [readonly]="validateImpressions(i+1,flights)"
                            [placeholder]="i === impressionsFlights.controls.length - 1 ? lastPlaceholder : ''" [(ngModel)]="flights.value.impression" (ngModelChange)="checkSum($event, i)" locale="en-US">
                            </p-inputNumber>
                            <i class="my-0 pl-3 fa fa-trash-alt delete remove-flight-btn" *ngIf="canRemove(flights, checkBoxAlign)" (click)="removeImpression(i)"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-3 d-flex align-items-center">
                    <button (click)="addImpression()" [disabled]="addImpressionDisabled()" pButton
                        class="p-button-primary" label="Add impression flight"></button>
                </div>
                <div class="col-5">

                </div>
                <div class="col-4">
                    <div class="custom-input-width  d-flex align-items-center" [ngClass]="showImpressionExceed ? 'invalid-message-box' : ''"
                        style="min-height: 2em;">
                        <span class="pl-2 font-weight-bold">{{ impressionExceedAmount | number: '1.0-2' }} / {{ baseCampaign?.impressions | number: '1.0-2' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" 
            [disabled]="!impressionsFlights.dirty || impressionsFlights.invalid || showImpressionExceed || impressionFlightsDatesInvalid()" pButton class="px-3 p-button-primary" label="Save"></button>
            <!-- [disabled]="checkIsDisable()" -->
        </div>
    </p-footer>
</p-dialog>