<div class="header-container">
    <app-header-component></app-header-component>
</div>
<main [@routeAnimation]="prepareRoute(outlet)">

    <p-scrollPanel [style]="{width: '100%', height: '92.2vh'}">
        <div class="main-container">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
    </p-scrollPanel>
</main>