<div class="">
    <p-dialog header="User Profile" class="actions-dialog" [modal]="true" [closable]="false" [(visible)]="displayDialog">
        <div [formGroup]="formGroup">
            <div class="row pb-3 mx-3">
                <div class="col-12">
                    <div class="row mt-3">
                        <label class="col-5">
                            First Name
                        </label>
                        <input pInputText formControlName="firstName" class="col-7" />
                    </div>
                    <div class="row mt-3">
                        <label class="col-5">
                            Last Name
                        </label>
                        <input pInputText formControlName="lastName" class="col-7" />
                    </div>
                    <div class="row mt-3">
                        <label class="col-5 required">
                            Email
                        </label>
                        <input type="email" (change)="onEmaileChange()" pInputText formControlName="email" class="col-7" />
                        <div class="offset-5 col-7 pl-0" style="color: red;" *ngIf="equalEmail">
                            This email adress is already taken, please enter the new one.
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-5">
                            Phone
                        </label>
                        <input pInputText formControlName="phone" class="col-7" />
                    </div>
                    <div class="row mt-3">
                        <label class="col-5">
                            Username
                        </label>
                        <input pInputText (change)="onUsernameChange()" type="text" formControlName="username" class="col-7" />
                        <div class="offset-5 col-7 pl-0" style="color: red;" *ngIf="equalUserName">
                            This username is already taken, please enter the new one.
                        </div>
                    </div>
                    <!-- <div class="row mt-3">
                        <label class="col-5">
                            Password
                        </label>
                        <input *ngIf="currentUser.id != user.id && !currentUser.permissions.isAdmin" [readOnly]="true" type="password" pInputText formControlName="password" class="col-7" />

                        <div *ngIf="currentUser.id == user.id || currentUser.permissions.isAdmin" class="col-7 px-0 input-group-append">
                            <input [type]="fieldTextType ? 'text' : 'password'" pInputText formControlName="password" class="col-11 pl-0" />

                            <span class="col-1 input-group-text">
                                <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
                            </span>

                        </div>
                        <div class="offset-5 px-0 col-7" style="color: red;" *ngIf="!!formGroup.controls['password'].errors && formGroup.controls['password'].errors.pattern && formGroup.controls['password'].touched">
                            Password must contain at least one uppercase character, one special character, one digit character and be 8 or more characters length.
                        </div>
                    </div> -->
                    <change-password-form #changePasswordForm 
                        *ngIf="currentUser.id == user.id || currentUser.permissions.isAdmin" 
                        [userId]="user.id" [showSaveButton]="false"
                        (onSaved)="onPasswordSaved()">
                    </change-password-form>
                    <span *ngIf="isClient(); else employee">
                        <div class="row mt-3">
                            <label class="col-5">
                                Account
                            </label>
                            <div class="col-7 px-0">
                                <p-dropdown [filter]="true" appendTo="body" *ngIf="!!accounts && !!accounts.length; else accountName" autoWidth="false" [style]="{'width':'100%'}" formControlName="account" [options]="accounts"></p-dropdown>
                                <ng-template #accountName *ngIf="!!user.userAccounts[0]">{{user.userAccounts[0].accountName}}</ng-template>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <label class="col-5">
                                Account Role
                            </label>
                            <div class="col-7 px-0">
                                <p-dropdown appendTo="body" autoWidth="false" [style]="{'width':'100%'}" formControlName="accountRole" [options]="userRoles"></p-dropdown>
                            </div>
                        </div>
                    </span>
                    <ng-template #employee>
                        <div class="row pl-0 my-2 pb-2">
                            <label class="col-5">
                                Role
                            </label>

                            <div class="col-7 px-0">
                                <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="accountRole" [options]="userRoles"></p-dropdown>
                            </div>
                        </div>
                        <!-- <div class="col-12 p-0 my-2">
                            <pick-list titleSource="Active Accounts" titleTarget="Assigned Accounts" [sourceList]="accountsSource" [targetList]="userAccounts"></pick-list>
                        </div> -->
                        <div class="col-12 p-0 my-2">
                            <pick-list titleSource="Active Teams" titleTarget="Assigned Teams" [sourceList]="teamsSource" [targetList]="userTeams"></pick-list>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <p-footer>
            <button type="submit" pButton label="Save" class="p-button-primary" [disabled]="formGroup.invalid || equalEmail || equalUserName 
            || (changePasswordForm && (changePasswordForm.formGroup.value.oldPassword || changePasswordForm.formGroup.value.newPassword || changePasswordForm.formGroup.value.confirmPassword) && !changePasswordForm.isValid())" icon="fa fa-save" (click)="submit()"></button>
            <button type="cancel" pButton label="Cancel" class="p-button-secondary" icon="fa fa-times" (click)="cancel()"></button>
        </p-footer>
    </p-dialog>

</div>