<div id="uploadPage" *ngIf="isLoaded" class="row mx-3 mt-3 mb-5">
    <div class="col-xl-2 col-lg-3 col-12 card-wrapper" *ngIf="showCards && !reviewPage" [@componentFadeIn]>
        <card-item-details #cardItemDetails [editButtonShown]="reviewPage"></card-item-details>
    </div>
    <div *ngIf="!isPaymentVisible" [ngClass]="!reviewPage?'col-xl-10 col-lg-9 col-12 pl-lg-0 pl-3': 'col-xl-12 col-12'">

        <div *ngIf="reviewPage" class="col-md-11 mb-4" style="background-color: var(--background-color-primary);">
            <h4 style="color:white">Please review</h4>
            <span style="color:white">Before submitting for approval, please check if all of the assets you uploaded are correct.</span>
        </div>
        <div *ngIf="!reviewPage" class="col-md-11 mb-4">
            <h4 class="display-4">Upload campaign assets</h4>
            <span>Upload all of your pictures, video, text and other assets here.</span>
        </div>

        <div *ngIf="!!baseCampaign" class="col-md-11">


            <div *ngIf="!isPaymentVisible">

                <p-accordion class="card card-body mb-2" *ngIf="baseCampaign.isGeographicTargetingUnlocked">
                    <p-accordionTab>
                        <p-header>
                            <span>GEOGRAPHIC TARGETING</span>
                            <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.geographicsInfo' class="fal fa-info-circle info float-right mt-1"></span>
                        </p-header>
                        <campaign-geographic-accordion [baseCampaign]="baseCampaign" [disabled]="reviewPage" (onSave)="geographicChanges($event)"></campaign-geographic-accordion>
                    </p-accordionTab>
                </p-accordion>

                 <!-- PPU and Abandoned for HMP -->
                 <div *ngIf="baseCampaign.account.isCampaignCreation && ((!!cartDisplayBannerCreative && cartDisplayBannerCreative.length>0) || (!!ppuDisplayBannerCreative && ppuDisplayBannerCreative.length>0))">
                    <div class="card card-body mb-2" *ngIf="!!cartDisplayBannerCreative && cartDisplayBannerCreative.length>0">
                        <div>
                            <p-accordion>
                                <p-accordionTab>
                                    <p-header>
                                        <span>ABANDONED CART</span>
                                    </p-header>
                                    <div *ngFor="let item of cartDisplayBannerCreative; let i = index">
                                        <display (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage'
                                            [isApprove]='isApprove'
                                            [campaign]="this.baseCampaign"
                                            [premiumCreative]="cartPremiumDisplayCreative[i]" [creative]="item"
                                            [accordionName]="'abandonedCart'">
                                        </display>
                                    </div>
                                    
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                    <div class="card card-body mb-2" *ngIf="!!ppuDisplayBannerCreative && ppuDisplayBannerCreative.length>0">
                        <div>
                            <p-accordion>
                                <p-accordionTab>
                                    <p-header>
                                        <span>POST PURCHASE UPSELL</span>
                                    </p-header>
                                    <div *ngFor="let item of ppuDisplayBannerCreative; let i = index">
                                        <display (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage'
                                            [isApprove]='isApprove'
                                            [campaign]="this.baseCampaign" [creative]="item"
                                            [premiumCreative]="postPPremiumDisplayCreative[i]"
                                            [accordionName]="'postPurchaseUpsell'"></display>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                </div>


                <div *ngFor="let flight of numberOfFlights; let c = index">

                    <div class="d-flex align-items-center" *ngIf="numberOfFlights.length > 1">
                        <div class="col-3 d-flex align-items-center">
                            <h4 class="display-3">Creative Flight {{c+1}} dates:</h4>
                        </div>
                        <div class="col-5" *ngIf="!!creativeFlightsDates">
                        <p-calendar appendTo="body" [minDate]="creativeFlightsDates[c].flightStartDateMin" [maxDate]="creativeFlightsDates[c].flightStartDateMax" [disabled]="c+1 == 1" [(ngModel)]="creativeFlightsDates[c].flightStartDate" (onSelect)="creativeFlightDateChange($event, c+1, true)" ></p-calendar>
                            <span class="mx-2">-</span>
                        <p-calendar appendTo="body" [minDate]="creativeFlightsDates[c].flightEndDateMin" [maxDate]="creativeFlightsDates[c].flightEndDateMax" [disabled]="numberOfFlights.length == c+1" [(ngModel)]="creativeFlightsDates[c].flightEndDate" (onSelect)="creativeFlightDateChange($event, c+1, false)" ></p-calendar> 
                        </div>
                    </div>

                    <!-- DISPLAY -->
                    <div *ngIf="(!!standardDisplayCreative && standardDisplayCreative.length>0) || (!!premiumDisplayCreative && premiumDisplayCreative.length>0) ||
                    (!!cartDisplayBannerCreative && cartDisplayBannerCreative.length>0) || (!!ppuDisplayBannerCreative && ppuDisplayBannerCreative.length>0)" class="card card-body mb-2">
                        <div>
                            <p-accordion>
                                <p-accordionTab>
                                    <p-header>
                                        <span>DISPLAY</span>
                                    </p-header>

                                    <div *ngFor="let item of standardDisplayCreative; let i = index">
                                        <display (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' *ngIf="item.creativeGroup == c+1" [isApprove]='isApprove' [campaign]="this.baseCampaign" [premiumCreative]="standardPremiumDisplayCreative[i]" [creative]="item"></display>
                                    </div>
                                    <div *ngIf="baseCampaign.account.isHubStandardCampaign">
                                        <div  *ngFor="let item of cartDisplayBannerCreative; let i = index">
                                            <display (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage'
                                                *ngIf="item.creativeGroup == c+1" [isApprove]='isApprove'
                                                [campaign]="this.baseCampaign"
                                                [premiumCreative]="cartPremiumDisplayCreative[i]" [creative]="item">
                                            </display>
                                        </div>
                                        <div *ngFor="let item of ppuDisplayBannerCreative; let i = index">
                                            <display (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage'
                                                *ngIf="item.creativeGroup == c+1" [isApprove]='isApprove'
                                                [campaign]="this.baseCampaign" [creative]="item"
                                                [premiumCreative]="postPPremiumDisplayCreative[i]"></display>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>

                    <!-- FACEBOOK -->
                    <div *ngIf="(!!facebookStaticCreative && facebookStaticCreative.length>0) || (!!facebookDynamicCreative && facebookDynamicCreative.length>0) ||
                    (!!facebookDynamicVideoCreative && facebookDynamicVideoCreative.length>0) || (!!facebookStaticVideoCreative && facebookStaticVideoCreative.length>0)
                    || (!!facebookDynamicCarouselCreative && facebookDynamicCarouselCreative.length>0) || (!!stories && stories.length>0)
                    || (!!leadGeneration && leadGeneration.length>0) || (!!instantExperience && instantExperience.length>0)" class="card card-body mb-2">
                        <div>
                            <span class="text-secondary"></span>
                            <p-accordion>
                                <p-accordionTab>
                                    <p-header>
                                        <span>FACEBOOK AND INSTAGRAM</span>
                                    </p-header>

                                    <div *ngFor="let item of facebookStaticCreative; let i = index">
                                    <facebook-static (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' (onCreativetDelete)="removeCreative($event)" [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item" [canDeleteCreative]="!reviewPage && baseCampaign.account.isCampaignCreation && facebookStaticCreativeLengthCheck(c+1) > 1 ? booleanTrue : booleanFalse"></facebook-static>
                                    </div>

                                    <!-- adding new facebook static creative -->
                                <div *ngIf="!reviewPage && baseCampaign.account.isCampaignCreation && !!facebookStaticCreative && facebookStaticCreative.length > 0 && facebookStaticCreativeLengthCheck(c+1) < 4
                                    && baseCampaign.campaignStatusId != enumCampaignStatus.Payment_Completed && baseCampaign.campaignStatusId != enumCampaignStatus.Campaign_Ended && baseCampaign.campaignStatusId != enumCampaignStatus.Campaign_Expired
                                    && baseCampaign.campaignStatusId != enumCampaignStatus.Campaign_Live && baseCampaign.campaignStatusId != enumCampaignStatus.Campaign_Paused && baseCampaign.statusId != enumCampaignStatus.Sent_to_API_Build 
                                    && baseCampaign.statusId != enumCampaignStatus.Build_Success" class="row md-2 mb-2">
                                    <div class="bd-highlight"><button type="button" pButton (click)="addCreative(c+1)" class="flat-btn" label="+ Add Creative"></button></div>
                                    <div class="d-flex align-items-center justify-content-center px-2">You can add up to {{4 - facebookStaticCreativeLengthCheck(c+1)}} more Facebook Static News Feed{{facebookStaticCreativeLengthCheck(c+1)==3?'':'s'}}</div>
                                    </div>

                                    <div *ngFor="let item of facebookDynamicCreative; let i = index">
                                        <facebook-static (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item" [dynamic]="booleanTrue"></facebook-static>
                                    </div>

                                    <div *ngFor="let item of facebookStaticVideoCreative; let i = index">
                                    <facebook-static-video (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></facebook-static-video>
                                    </div>

                                    <div *ngFor="let item of facebookDynamicVideoCreative; let i = index">
                                        <facebook-static-video (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item" [dynamic]="booleanTrue"></facebook-static-video>
                                    </div>

                                    <div *ngFor="let item of facebookDynamicCarouselCreative; let i = index">
                                        <facebook-carousel (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></facebook-carousel>
                                    </div>

                                    <div *ngFor="let item of stories; let i = index">
                                    <stories (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></stories>
                                    </div>

                                    <div *ngFor="let item of leadGeneration; let i = index">
                                    <lead-generation (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></lead-generation>
                                    </div>

                                    <div *ngFor="let item of instantExperience; let i = index">
                                    <instant-experience (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [dynamic]="booleanTrue" [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></instant-experience>
                                    </div>

                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>

                    <!-- LINKEDIN -->
                    <div *ngIf="baseCampaign.campaignType == enumAccountType.HS && (linkedinImageCreative?.length > 0 || linkedinVideoCreative?.length > 0 || linkedinCarouselCreative?.length > 0)" class="card card-body mb-2">
                            <div>
                                <span class="text-secondary"></span>
                                <p-accordion>
                                    <p-accordionTab>
                                        <p-header>
                                            <span>LINKEDIN</span>
                                        </p-header>
    
                                        <div *ngFor="let item of linkedinImageCreative; let i = index">
                                            <linkedin-news-feed (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item" [isLinkedinImage]="true"></linkedin-news-feed>
                                        </div>

                                        <div *ngFor="let item of linkedinVideoCreative; let i = index">
                                            <linkedin-news-feed (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></linkedin-news-feed>
                                        </div>
                                        

                                        <div *ngFor="let item of linkedinCarouselCreative; let i = index">
                                            <linkedin-carousel (openPreviewEvent)="openPreview($event)" *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></linkedin-carousel>
                                        </div>
    
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                        </div>

                        <!-- TIKTOK -->
                    <div *ngIf="baseCampaign.campaignType == enumAccountType.HS && tikTokInFeedAdsCreative?.length > 0" class="card card-body mb-2">
                        <div>
                            <span class="text-secondary"></span>
                            <p-accordion>
                                <p-accordionTab>
                                    <p-header>
                                        <span>TIKTOK</span>
                                    </p-header>

                                    <div *ngFor="let item of tikTokInFeedAdsCreative; let i = index">
                                        <tiktok-in-feed-ads *ngIf="item.creativeGroup == c+1" [reviewPage]='reviewPage' [campaign]="this.baseCampaign" [isApprove]='isApprove' [creative]="item"></tiktok-in-feed-ads>
                                    </div>

                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>

                    <!-- OTHER -->
                <div *ngIf="(!!inStreamVideoVideoCreative && inStreamVideoVideoCreative.length>0) || (!!nativeImageCreative && nativeImageCreative.length>0) 
                    || (!!nativeVideoCreative && nativeVideoCreative.length>0) || (!!spotifyVideoCreative && spotifyVideoCreative.length>0)
                    || (!!spotifyAudioCreative && spotifyAudioCreative.length>0) || (!!googleAdWorkCreative && googleAdWorkCreative.length>0)
                    || (!!connectedTvCreative && connectedTvCreative.length>0) || (!!youtubeVideoCreative && youtubeVideoCreative.length>0)" class="card card-body mb-2">
                    <div>
                        <p-accordion>
                            <p-accordionTab>
                                <p-header>
                                    <span>OTHER</span>
                                </p-header>

                                <div *ngFor="let item of inStreamVideoVideoCreative; let i = index">
                                    <in-stream-video *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></in-stream-video>
                                </div>

                                <div *ngFor="let item of nativeImageCreative; let i = index">
                                    <native-image *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></native-image>
                                </div>

                                <div *ngFor="let item of nativeVideoCreative; let i = index">
                                    <native-video *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></native-video>
                                </div>

                                <div *ngFor="let item of spotifyVideoCreative; let i = index">
                                    <spotify-video *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></spotify-video>
                                </div>

                                <div *ngFor="let item of spotifyAudioCreative; let i = index">
                                    <spotify-audio *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></spotify-audio>
                                </div>

                                <div *ngFor="let item of googleAdWorkCreative; let i = index">
                                    <google-adWords *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></google-adWords>
                                </div>

                                <div *ngFor="let item of connectedTvCreative; let i = index">
                                    <connected-tv *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></connected-tv>
                                </div>

                                <div *ngFor="let item of youtubeVideoCreative; let i = index">
                                    <youtube-video *ngIf="item.creativeGroup == c+1" (openPreviewEvent)="openPreview($event)" (onCreativetDelete)="removeCreative($event)" [reviewPage]='reviewPage' [isApprove]='isApprove' [campaign]="this.baseCampaign" [creative]="item"></youtube-video>
                                </div>
                                
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    </div>
                </div>

                <!-- NOTES -->
                <div class="card card-body" *ngIf="checkForCreatives()">
                    <span>
                        <div>
                            <span class="mb-0 ml-3 text-secondary"><strong>Add notes or comments</strong></span>
                        </div>
                    <div class="ml-5 pl-3 mt-3" style=" border:0; border-left: 1px; border-style: solid; border-color: gray;">
                            <div class="col-12">
                            <textarea type="text" [(ngModel)]="note" class="form-control" style="width: 100%; height: 6em;" placeholder="Start Typing..."></textarea>
                            </div>
                        </div>
                    </span>
                <rc-galleria *ngIf='isPreviewOpen' [isBuilt]="previewedCreativeIsBuilt" [isApproveProcess]="isApprove" (previewDialogDisplay)="showHideDialog($event)" [isVisible]='isPreviewOpen' [medias]="creativeAssetsForPreview" [activeIndex]="0" [active]="currentIndex"></rc-galleria>
                </div>

            </div>

            <div *ngIf="checkForCreatives()">
                <div class="d-flex flex-row-reverse flex-wrap bd-highlight mt-3 mb-3">
                <div [ngClass]="getSubmittButtonLabel() == 'Payment' ? '' : 'pl-2'" class="py-2 bd-highlight"><button type="button" pButton (click)="next()" *ngIf="!reviewPage && !isApprove" [attr.title]="!isLandingUrlOk? 'Missing Landing Page URL for uploaded asset!':null" [disabled]="checkIsDisabled() || !isLandingUrlOk" class="p-button-primary" label="Review Upload"></button></div>

                <div class="py-2 bd-highlight"><button type="button" pButton (click)="save()" [attr.title]="!isLandingUrlOk? 'Missing Landing Page URL for uploaded asset!' : (tooltipDisabled != null ? tooltipDisabled : null)" [disabled]="checkIsDisabled() || !isLandingUrlOk " class="flat-btn" [label]="getSubmittButtonLabel()"> </button></div>

                <div class="py-2 pr-2 bd-highlight"><button type="button" pButton (click)="back()" *ngIf="reviewPage || (currentUser.permissions.isHMPClient && baseCampaign.campaignStatusId == enumCampaignStatus.Campaign_Draft)" class="flat-btn" label="Back"></button></div>
                <div class="py-2 pr-2 bd-highlight"><button type="button" pButton (click)="cancel()" class="flat-btn" label="Cancel"></button></div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="isPaymentVisible" class="col-xl-10 col-lg-9 col-12 pl-lg-0 pl-3">
        <campaign-payment (onGoBack)="backFromPayment()" [baseCampaign]="baseCampaign" [isCampaignSucessfullySaved]="isCampaignSucessfullySaved" (onSave)="saveCampaign($event)" (onPay)="goToLastStep($event)"></campaign-payment>
    </div>
</div>

<p-confirmDialog header="Invalid Campaign Dates" icon="pi pi-exclamation-triangle" key="invalidDates" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>