<div [formGroup]="formGroup">
    <div class="row pb-2" [ngClass]="styleClass">
        <div class="col-12">

            <div class="row mt-3" *ngIf="showOldPassword">
                <label class="col-5">
                    Current Password
                </label>
                <div class="col-7 px-0 input-group-append">
                    <input pInputText formControlName="oldPassword" [type]="oldPasswordFieldTextType ? 'text' : 'password'" class="col-11 pl-0" />
                    <span class="input-group-text" (click)="oldPasswordFieldTextType = !oldPasswordFieldTextType">
                        <i class="fa" [ngClass]="{'fa-eye-slash': !oldPasswordFieldTextType,'fa-eye': oldPasswordFieldTextType}"></i>
                    </span>
                </div>
                <div class="offset-5 px-0 col-7" style="color: red;" *ngIf="(!formGroup.controls['oldPassword'].value || (!!formGroup.controls['oldPassword'].errors && formGroup.controls['oldPassword'].errors.pattern)) && formGroup.controls['oldPassword'].touched">
                    Current Password must be entered.
                </div>
            </div>

            <div class="row mt-3">
                <label class="col-5">
                    New Password
                </label>
                <div class="col-7 px-0 input-group-append">
                    <input pInputText formControlName="newPassword" [type]="newPasswordFieldTextType ? 'text' : 'password'" class="col-11 pl-0" />
                    <span class="input-group-text" (click)="newPasswordFieldTextType = !newPasswordFieldTextType">
                        <i class="fa" [ngClass]="{'fa-eye-slash': !newPasswordFieldTextType,'fa-eye': newPasswordFieldTextType}"></i>
                    </span>
                </div>
                <div class="offset-5 px-0 col-7" style="color: red;" *ngIf="(!formGroup.controls['newPassword'].value || (!!formGroup.controls['newPassword'].errors && formGroup.controls['newPassword'].errors.pattern)) && formGroup.controls['newPassword'].touched">
                    Password must contain at least one uppercase character, one special character, one digit character and be 8 or more characters length.
                </div>
            </div>

            <div class="row mt-3">
                <label class="col-5">
                    Confirm Password
                </label> 
                <div class="col-7 px-0 input-group-append">
                    <input pInputText formControlName="confirmPassword" [type]="confirmPasswordFieldTextType ? 'text' : 'password'" class="col-11 pl-0" />
                    <span class="input-group-text pointer" (click)="confirmPasswordFieldTextType = !confirmPasswordFieldTextType">
                        <i class="fa" [ngClass]="{'fa-eye-slash': !confirmPasswordFieldTextType,'fa-eye': confirmPasswordFieldTextType}"></i>
                    </span>
                </div>
                <div class="offset-5 px-0 col-7" style="color: red;" *ngIf="(formGroup.controls['newPassword'].value != formGroup.controls['confirmPassword'].value) && formGroup.controls['confirmPassword'].touched">
                    Confrirmed Password must match New Password.
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="showSaveButton" class="col-5 offset-7 py-2 float-right">
        <button pButton (click)="save()" [disabled]="!isValid()" class="px-3 p-button-primary" style="float: right;" label="Save"></button>
    </div>
</div>