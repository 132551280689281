import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
// import { SummernoteOptions } from 'ngx-summernote/lib/summernote-options';
import { IInstructionPageDto, IUploadPayloadModelDto, IUpload } from '@Workspace/_generated/interfaces';
import { AccountController, InstructionsController } from '@Workspace/_generated/services';
import { PageLoaderService, NotificationService, SettingsService } from '@Workspace/services';
import { Constants, Functions } from '@Workspace/common';
import { FileUploadService } from '../../../services/file-upload.service';
import { HttpEvent, HttpResponse } from '@angular/common/http';

@Component({
    selector: 'edit-instructions-dialog',
    templateUrl: './edit-instructions.dialog.html',
    styleUrls: ['./edit-instructions.dialog.scss']
})
export class EditInstructionsDialog {

    @Output('onSaved') onSaved: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('editor') editor: ElementRef;
    constructor(
        private instructionsController: InstructionsController,
        private pageLoader: PageLoaderService,
        private notification: NotificationService,
        private settingsService: SettingsService,
        private uploadService: FileUploadService
    ) { }

    isVisible: boolean = false;
    errorMessage: string;

    // editorCfg: SummernoteOptions;
    instructionPage: IInstructionPageDto;
    initialInstruction: any;
    private textFileType = { type: 'text/plain;charset=utf-8' };
    action: string;
    editorContent: string = "";

    openDialog(instructionPage: IInstructionPageDto, action: string, maxOrderNumber: number = null) {
        this.action = action;
        this.isVisible = true;
        // this.editorCfg = this.getEditorConfiguration();
        if(instructionPage != null){
            this.initialInstruction = JSON.parse(JSON.stringify(instructionPage));
            this.instructionPage = instructionPage;
            this.editorContent = this.instructionPage.markupContent;
        }
        else{
            this.instructionPage = {
                id: Constants.GuidEmpty,
                title: '',
                orderNumber: maxOrderNumber + 1,
                markupContent: null,
              } as IInstructionPageDto;
              this.editorContent = "";
        }
    }

    // private getEditorConfiguration() {
    //     return {
    //         tabsize: 4,
    //         height: 400,
    //         toolbar: [
    //             ['misc', ['undo', 'redo']],
    //             //['style', ['bold', 'italic', 'underline', 'clear']],
    //             ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
    //             ['fontsize', ['fontname', 'fontsize', 'color']],
    //             ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
    //             ['insert', ['table', 'picture', 'link', 'hr']]
    //         ],
    //         fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],

    //     } as SummernoteOptions;
    // }

    save() {
        this.pageLoader.showPageLoader();
        if(this.action == 'edit' || this.action == 'add'){
            const blob = new Blob([this.editorContent], this.textFileType);
            const file = this.blobToFile(blob, 'new content');
    
            const details = { id: this.instructionPage.id, title: this.instructionPage.title, orderNumber: this.instructionPage.orderNumber } as IInstructionPageDto;
            const detailsJson = JSON.stringify(details);
    
            const upload = { file: file, fileName: file.name, detailsJson: detailsJson } as IUpload;
            const payload = { uploads: [upload] } as IUploadPayloadModelDto;
    
            this.uploadService.UpdateInstructionPage(payload)
                .subscribe(
                    result => {
                        this.pageLoader.hidePageLoader();
                        const fileData = (<HttpResponse<any>>result).body;
                        if (!!fileData) {
                            const blob = this.byteArrayToFileBlob(fileData);
                            const reader = new FileReader();
                            reader.readAsText(blob);
                            reader.onloadend = () => {
                                this.instructionPage.markupContent = <string>reader.result;
                                if(this.instructionPage.id == Constants.GuidEmpty){
                                    const pageObject = {instructionPage: this.instructionPage, action: 'add'};
                                    this.onSaved.emit(pageObject);
                                }
                                else{
                                    const pageObject = {instructionPage: this.instructionPage, action: 'edit'};
                                    this.onSaved.emit(pageObject);
                                }
                                this.pageLoader.hidePageLoader();
                                this.notification.success('Page updated');
                                this.isVisible = false;
                            }
                        }
                    },
                    error => {
                        this.pageLoader.hidePageLoader();
                        this.notification.error('Failed to update page');
                    });
        }
        else if(this.action == 'remove'){
            const instructionPage = { id: this.instructionPage.id, title: this.instructionPage.title } as IInstructionPageDto;
            this.instructionsController.RemoveInstructionPage(instructionPage).subscribe(
                result => {
                    const object = {instructionPage: this.instructionPage, action: 'remove'};
                    this.onSaved.emit(object);
                    this.pageLoader.hidePageLoader();
                    this.notification.success('Page removed');
                    this.isVisible = false;
                },
                error => {
                    this.pageLoader.hidePageLoader();
                    this.notification.error('Failed to remove page');
                });
        }
    }

    cancel() {
        this.instructionPage = this.initialInstruction;
        const pageObject = {instructionPage: this.instructionPage, action: 'edit'};
        this.onSaved.emit(pageObject);

        this.isVisible = false;
    }

    // misc

    private blobToFile(blob: Blob, fileName: string): File {
        let file: any = blob;
        file.lastModifiedDate = Date.now();
        file.name = fileName;

        return <File>file;
    }

    private byteArrayToFileBlob(base64: string) {
        let binaryString = window.atob(base64);
        const length = binaryString.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }


        return new Blob([bytes], this.textFileType);
    }
}