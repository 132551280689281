
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeletonLoaderComponent } from './skeleton-loader.component';



@NgModule({
    declarations: [SeletonLoaderComponent],
    imports: [
        CommonModule
    ],
    exports: [SeletonLoaderComponent]
})
export class SkeletonLoaderModule { }