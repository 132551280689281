import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { AuthService } from '@Workspace/auth';
import { ILoginModel } from '@Workspace/_generated/interfaces';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { CampaignService } from '../../../mogo/services/campaign.service';

@Component({
  selector: 'app-home-component',
  templateUrl: './home-component.component.html',
  styleUrls: ['./home-component.component.scss']
})

export class HomeComponentComponent implements OnInit, OnDestroy {
    
    loginModel: ILoginModel = {
        userName: "",
        password: ""
    } as ILoginModel;

    loginMessage = "";
    errorMessage = "";
    public userLoggedIn = false;
    ngUnsubscribe = new Subject();

    constructor(
        public authService: AuthService,
        public messageService : MessageService,
        public campaignService : CampaignService
        
    ) {}

    ngOnInit() {
        this.authService.$userState
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (response) => {
                    if (response) 
                    {
                        this.userLoggedIn = true;
                        this.loginMessage = "Welcome " + this.authService.userSettings.fullName;
                        this.errorMessage = "";
                    }
                    else 
                    {
                        this.userLoggedIn = false;
                        this.loginMessage = "Logged out";
                        this.errorMessage = "";
                    }
                }
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onSubmit() {
        this.onSubmitAsync();
    }

    async onSubmitAsync(){
        try{
            await this.authService.login(
                this.loginModel.userName,
                this.loginModel.password
                );
        }
        catch{
            this.errorMessage = "Wrong login";
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Wrong Login.' });

            console.log("Wrong login");
        }
    }

    onLogout(){
        this.onLogoutAsync();
        this.loginModel.password = "";
        this.loginModel.userName = "";
    }
    
    async onLogoutAsync(){
        await this.authService.logOut();
    }
}
