import { HttpClient } from '@angular/common/http';
import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { eFilterDefiniton } from '@Workspace/_generated/enums';
import { Constants } from '@Workspace/common';
import { IGridOptions, IPagingResult } from '@Workspace/interfaces';
import { BaseGridComponent } from '@Workspace/models';
import { PageLoaderService } from '@Workspace/services';
import { QueryBuilder } from 'odata-query-builder';
import { ObjectUtils } from 'primeng/utils';
import { TableState, BlockableUI, FilterMetadata, PrimeTemplate, SortMeta, MessageService } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { TableService } from 'primeng/table';

@Component({
    selector: 'rc-grid',
    templateUrl: './rc-grid.component.html',
    styleUrls: ['./rc-grid.component.scss'],
    providers: [TableService]
})
export class GridComponent extends BaseGridComponent
    implements OnInit, AfterViewInit, AfterContentInit, BlockableUI {
    @Input() frozenColumns: any[];

    @Input() frozenValue: any[];

    @Input() style: any;

    @Input() styleClass: string;

    @Input() tableStyle: any;

    @Input() tableStyleClass: string;

    @Input() paginator: boolean = false;

    @Input() isCurentPageInputEnabled: boolean = true;

    @Input() rows: number = Constants.ROWS_PER_PAGE_INITIAL_OPTION;

    @Input() first: number = 0;

    @Input() pageLinks: number = 5;

    @Input() rowsPerPageOptions: number[] = Constants.ROWS_PER_PAGE_OPTIONS;

    @Input() alwaysShowPaginator: boolean = true;

    @Input() paginatorPosition: string = 'bottom';

    @Input() paginatorDropdownAppendTo: any;

    @Input() defaultSortOrder: number = 1;

    @Input() sortMode: string = 'single';

    @Input() resetPageOnSort: boolean = true;

    @Input() selectionMode: string = 'none';

    @Output() selectionChange: EventEmitter<any> = new EventEmitter();

    @Input() contextMenuSelection: any;

    @Output() contextMenuSelectionChange: EventEmitter<
        any
    > = new EventEmitter();

    @Input() contextMenuSelectionMode: string = 'separate';

    @Input() dataKey: string;

    @Input() metaKeySelection: boolean;

    @Input() rowTrackBy: Function = (index: number, item: any) => item;

    @Input() lazy: boolean = true;

    @Input() lazyLoadOnInit: boolean = false;

    @Input() compareSelectionBy: string = 'deepEquals';

    @Input() csvSeparator: string = ',';

    @Input() exportFilename: string = 'download';

    @Input() filters: { [s: string]: FilterMetadata | FilterMetadata[] } = {};

    @Input() globalFilterFields: string[];

    @Input() filterDelay: number = 300;

    @Input() expandedRowKeys: { [s: string]: number } = {};

    @Input() rowExpandMode: string = 'multiple';

    @Input() scrollable: boolean;

    @Input() scrollHeight: string;

    @Input() virtualScroll: boolean;

    @Input() virtualScrollDelay: number = 150;

    @Input() virtualRowHeight: number = 28;

    @Input() frozenWidth: string;

    @Input() responsive: boolean = true;

    @Input() contextMenu: any;

    @Input() resizableColumns: boolean;

    @Input() columnResizeMode: string = 'fit';

    @Input() reorderableColumns: boolean;

    @Input() loading: boolean;

    @Input() loadingIcon: string = 'pi pi-spinner';

    @Input() rowHover: boolean;

    @Input() customSort: boolean;

    @Input() autoLayout: boolean;

    @Input() exportFunction;

    @Input() stateKey: string;

    @Input() stateStorage: string = 'session';

    @Output() onRowSelect: EventEmitter<any> = new EventEmitter();

    @Output() onRowUnselect: EventEmitter<any> = new EventEmitter();

    @Output() onPage: EventEmitter<any> = new EventEmitter();

    @Output() onSort: EventEmitter<any> = new EventEmitter();

    @Output() onFilter: EventEmitter<any> = new EventEmitter();

    @Output() onLazyLoad: EventEmitter<any> = new EventEmitter();

    @Output() onRowExpand: EventEmitter<any> = new EventEmitter();

    @Output() onRowCollapse: EventEmitter<any> = new EventEmitter();

    @Output() onContextMenuSelect: EventEmitter<any> = new EventEmitter();

    @Output() onColResize: EventEmitter<any> = new EventEmitter();

    @Output() onColReorder: EventEmitter<any> = new EventEmitter();

    @Output() onRowReorder: EventEmitter<any> = new EventEmitter();

    @Output() onEditInit: EventEmitter<any> = new EventEmitter();

    @Output() onEditComplete: EventEmitter<any> = new EventEmitter();

    @Output() onEditCancel: EventEmitter<any> = new EventEmitter();

    @Output() onHeaderCheckboxToggle: EventEmitter<any> = new EventEmitter();

    @Output() sortFunction: EventEmitter<any> = new EventEmitter();

    @Output() onBeforeDataLoad: EventEmitter<any> = new EventEmitter();

    @Output() onAfterDataLoad: EventEmitter<any> = new EventEmitter();

    @ViewChild('container') containerViewChild: ElementRef;

    @ViewChild('resizeHelper') resizeHelperViewChild: ElementRef;

    @ViewChild('reorderIndicatorUp') reorderIndicatorUpViewChild: ElementRef;

    @ViewChild('reorderIndicatorDown')
    reorderIndicatorDownViewChild: ElementRef;

    @ViewChild('table') tableViewChild: ElementRef;

    @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

    @ContentChild('customButton') customButton;

    @ContentChild('customText') customText;

    _value: IPagingResult;

    _options: IGridOptions;

    _columns: any[];

    _totalCount: number = 0;

    _tableBody: string;

    filteredValue: any[];

    headerTemplate: TemplateRef<any>;

    bodyTemplate: TemplateRef<any>;

    captionTemplate: TemplateRef<any>;

    frozenRowsTemplate: TemplateRef<any>;

    footerTemplate: TemplateRef<any>;

    summaryTemplate: TemplateRef<any>;

    colGroupTemplate: TemplateRef<any>;

    expandedRowTemplate: TemplateRef<any>;

    frozenHeaderTemplate: TemplateRef<any>;

    frozenBodyTemplate: TemplateRef<any>;

    frozenFooterTemplate: TemplateRef<any>;

    frozenColGroupTemplate: TemplateRef<any>;

    emptyMessageTemplate: TemplateRef<any>;

    paginatorLeftTemplate: TemplateRef<any>;

    paginatorRightTemplate: TemplateRef<any>;

    selectionKeys: any = {};

    lastResizerHelperX: number;

    reorderIconWidth: number;

    reorderIconHeight: number;

    draggedColumn: any;

    draggedRowIndex: number;

    droppedRowIndex: number;

    rowDragging: boolean;

    dropPosition: number;

    editingCell: Element;

    editingCellClick: boolean;

    documentEditListener: any;

    _multiSortMeta: SortMeta[];

    _sortField: string;

    _sortOrder: number = 1;

    virtualScrollTimer: any;

    virtualScrollCallback: Function;

    preventSelectionSetterPropagation: boolean;

    _selection: any;

    anchorRowIndex: number;

    rangeRowIndex: number;

    filterTimeout: any;

    initialized: boolean;

    rowTouched: boolean;

    restoringSort: boolean;

    restoringFilter: boolean;

    stateRestored: boolean;

    columnOrderStateRestored: boolean;

    columnWidthsState: string;

    tableWidthState: string;

    footerText: string;

    constructor(
        private pageLoaderService: PageLoaderService,
        private httpClient: HttpClient,
        public el: ElementRef,
        public zone: NgZone,
        public messageService: MessageService,
        public tableService: TableService
    ) {
        super();
    }

    ngOnInit() {
        if (this.lazy && this.lazyLoadOnInit) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());

            this.setFooterText();
        }

        this.initialized = true;
    }

    ngAfterContentInit() {
        this.templates.forEach(item => {
            switch (item.getType()) {
                case 'caption':
                    this.captionTemplate = item.template;
                    break;

                case 'header':
                    this.headerTemplate = item.template;
                    break;

                case 'body':
                    this.bodyTemplate = item.template;
                    break;

                case 'footer':
                    this.footerTemplate = item.template;
                    break;

                case 'summary':
                    this.summaryTemplate = item.template;
                    break;

                case 'colgroup':
                    this.colGroupTemplate = item.template;
                    break;

                case 'rowexpansion':
                    this.expandedRowTemplate = item.template;
                    break;

                case 'frozenrows':
                    this.frozenRowsTemplate = item.template;
                    break;

                case 'frozenheader':
                    this.frozenHeaderTemplate = item.template;
                    break;

                case 'frozenbody':
                    this.frozenBodyTemplate = item.template;
                    break;

                case 'frozenfooter':
                    this.frozenFooterTemplate = item.template;
                    break;

                case 'frozencolgroup':
                    this.frozenColGroupTemplate = item.template;
                    break;

                case 'emptymessage':
                    this.emptyMessageTemplate = item.template;
                    break;

                case 'paginatorleft':
                    this.paginatorLeftTemplate = item.template;
                    break;

                case 'paginatorright':
                    this.paginatorRightTemplate = item.template;
                    break;
            }
        });

        this.setFooterText();
    }

    ngAfterViewInit() {
        if (this.isStateful() && this.resizableColumns) {
            this.restoreColumnWidths();
        }

        this.setFooterText();
    }

    @Input()
    get options(): IGridOptions {
        return this._options;
    }
    set options(options: IGridOptions) {
        if (this.isStateful() && !this.stateRestored) {
            this.restoreState();
        }

        if (!!options) {
            this._options = options;
            if (!!this._options.columns) {
                this.columns = [...this._options.columns];
            }
            if (typeof options.data === 'string') {
                // this.sortField = options.sortField;
                if (!!options.sortField)
                    this._sortField = options.sortField;

                this.getPagedData();
            } else if (typeof options.data !== 'string') {
                this._value = options.data;
                this.composeSettings();
            }
        }
    }

    @Input()
    get value(): IPagingResult {
        return this._value;
    }
    set value(val: IPagingResult) {
        if (this.isStateful() && !this.stateRestored) {
            this.restoreState();
        }

        this._value = val;

        this._value.data = val.data ? val.data : [];
        this.rows = val.take
            ? val.take
            : Constants.ROWS_PER_PAGE_INITIAL_OPTION;

        this._totalCount = val.totalCount;
        this.tableService.onTotalRecordsChange(this._totalCount);
        this.setFooterText();

        this.composeSettings();
    }

    @Input()
    get columns(): any[] {
        return this._columns;
    }
    set columns(cols: any[]) {
        this._columns = cols;
        this.tableService.onColumnsChange(cols);

        if (
            this._columns &&
            this.isStateful() &&
            this.reorderableColumns &&
            !this.columnOrderStateRestored
        ) {
            this.restoreColumnOrder();
        }
    }

    @Input()
    get totalCount(): number {
        return this._totalCount;
    }
    set totalCount(val: number) {
        this._totalCount = val;
        this.tableService.onTotalRecordsChange(this._totalCount);

        this.setFooterText();
    }

    @Input()
    get sortField(): string {
        return this._sortField;
    }

    set sortField(val: string) {
        this._sortField = val;

        //avoid triggering lazy load prior to lazy initialization at onInit
        if (!this.lazy || this.initialized) {
            if (this.sortMode === 'single') {
                this.sortSingle();
            }
        }
    }

    @Input()
    get sortOrder(): number {
        return this._sortOrder;
    }
    set sortOrder(val: number) {
        this._sortOrder = !!val ? val : 1;

        //avoid triggering lazy load prior to lazy initialization at onInit
        if (!this.lazy || this.initialized) {
            if (this.sortMode === 'single') {
                this.sortSingle();
            }
        }
    }

    @Input()
    get multiSortMeta(): SortMeta[] {
        return this._multiSortMeta;
    }

    set multiSortMeta(val: SortMeta[]) {
        this._multiSortMeta = val;
        if (this.sortMode === 'multiple') {
            this.sortMultiple();
        }
    }

    @Input()
    get selection(): any {
        return this._selection;
    }

    set selection(val: any) {
        this._selection = val;

        if (!this.preventSelectionSetterPropagation) {
            this.updateSelectionKeys();
            this.tableService.onSelectionChange();
        }
        this.preventSelectionSetterPropagation = false;
    }

    //TODO:
    getPagedData() {
        if(!this._options.isOData){
            return this.onBeforeDataLoad.emit({
                skip: this.first,
                take: this.rows,
                totalRecords: this.totalCount,
                sortOrder: this._sortOrder,
                sortField: this.sortField
            });
        }
        
        this.pageLoaderService.showPageLoader();
        let url = this._options.data;

        if (!!this._options && this._options.isOData) {
            this.onBeforeDataLoad.emit({
                skip: this.first,
                first: this.rows,
                totalRecords: this.totalCount
            });

            let query = this.composeGridODataQuery({
                first: this.first,
                rows: this.rows,
                sortOrder: this._sortOrder
            });

            if ((url as string).indexOf('?') >= 0 && query.indexOf('?') === 0) {
                query = '&' + query.substr(1);
            }

            url += query;
        } else if (!!this._options && !this._options.isOData) {
            let query = this.composeQuery({
                first: this.first,
                rows: this.rows,
                sortOrder: this._sortOrder
            });

            url += query;

            if (this._options.queryString) {
                url += this._options.queryString;
            }
        }

        if (
            !!this._options &&
            !!this._options.expand &&
            this._options.expand.length > 0
        ) {
            let expandUrlPart = '&$expand=';
            for (let expandItem of this._options.expand) {
                expandUrlPart += `${expandItem},`;
            }

            expandUrlPart = expandUrlPart.substr(0, expandUrlPart.length - 1);
            url += expandUrlPart;
        }

        if (
            !!this._options &&
            !!this._options.select &&
            this._options.select.length > 0
        ) {
            let selectUrlPart = '&$expand=';
            for (let selectItem of this._options.select) {
                selectUrlPart += `${selectItem},`;
            }

            selectUrlPart = selectUrlPart.substr(0, selectUrlPart.length - 1);
            url += selectUrlPart;
        }

        this.httpClient
            .get<IPagingResult>(<string>url, {
                responseType: 'json',
                observe: 'response',
                withCredentials: true
            })
            .subscribe(
                response => {


                    this.value = response.body;
                    this.composeSettings();

                    if (!!this._options && this._options.isSimpleView) {
                        this.composeTableBody(response.body.data);
                    }

                    this.setFooterText();
                    this.onAfterDataLoad.emit({
                        data: this.value.data,
                        skip: this.first,
                        first: this.rows,
                        totalCount: this.totalCount
                    });
                    this.pageLoaderService.hidePageLoader();
                },
                error => {
                    if(!!error.error.text){
                        console.error(error.error.text);
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
                    }
                    else
                        console.error(error);
                        
                    this.pageLoaderService.hidePageLoader();
                }
            );
    }

    protected combineUrlWithOData(url: string, oDataQueryUrl: string) {
        if (
            (url as string).indexOf('?') >= 0 &&
            oDataQueryUrl.indexOf('?') === 0
        ) {
            return `${url}&${oDataQueryUrl.substr(1)}`;
        }

        return `${url}${oDataQueryUrl}`;
    }

    protected composeODataQuery(queryBuilder: QueryBuilder): void {
        if (!!this._options.filter) {
            if (typeof this._options.filter === 'string') {
                const filterComposition = this._options.filter;

                queryBuilder.filter(filter =>
                    filter.filterPhrase(filterComposition)
                );
            } else if (
                typeof this._options.filter !== 'string' &&
                this._options.filter.definition === eFilterDefiniton.Equals
            ) {
                const filterComposition = this._options.filter;

                queryBuilder.filter(filter =>
                    filter.filterExpression(
                        filterComposition.field,
                        'eq',
                        filterComposition.value
                    )
                );
            } else if (
                typeof this._options.filter !== 'string' &&
                this._options.filter.definition === eFilterDefiniton.Contains
            ) {
                const filterComposition = this._options.filter;

                queryBuilder.filter(filter =>
                    filter.filterPhrase(
                        `contains(${filterComposition.field}, '${
                        filterComposition.value
                        }')`
                    )
                );
            }
        }
    }

    getOrderBy(baseOrderByColumn: string, event?): string {
        let result = event
            ? event.sortField
                ? event.sortField
                : baseOrderByColumn
            : baseOrderByColumn;

        result +=
            ' ' + (event ? (event.sortOrder === 1 ? 'asc' : 'desc') : 'asc');

        return result;
    }

    composeSettings() {
        if (!this.lazy) {
            this.totalCount = this._value.totalCount
                ? this._value.totalCount
                : 0;

            if (this.sortMode === 'single' && this.sortField) this.sortSingle();
            else if (this.sortMode === 'multiple' && this.multiSortMeta)
                this.sortMultiple();
            else if (this.hasFilter())
                //sort already filters
                this._filter();
        }

        if (this.virtualScroll && this.virtualScrollCallback) {
            this.virtualScrollCallback();
        }

        this.tableService.onValueChange(this._value);
    }

    //TODO:
    composeTableBody(data: any[]) {
        this._tableBody = '';

        data.forEach(item => {
            this._tableBody += '<tr>';
            this._options.columns.forEach(column => {
                if (!column.template) {
                    column.template = '<td>{{columnData}}</td>';
                }

                if (column.template.includes('columnData')) {
                    this._tableBody += column.template.replace(
                        '{{columnData}}',
                        item[column.value] ? item[column.value] : ''
                    );
                } else if (column.template.includes('rowData')) {
                    const regExp = new RegExp(/{{\s*rowData\.(\S*)\s*}}/g);
                    let columnValue;
                    let match;

                    while ((match = regExp.exec(column.template)) !== null) {
                        columnValue = columnValue
                            ? columnValue.replace(match[0], item[match[1]])
                            : column.template.replace(match[0], item[match[1]]);

                        if (!columnValue.includes('rowData')) {
                            this._tableBody += columnValue;
                        }
                    }
                } else {
                    this._tableBody += column.template;
                }
            });

            this._tableBody += '</tr>';
        });
    }

    updateSelectionKeys() {
        if (this.dataKey && this._selection) {
            this.selectionKeys = {};
            if (Array.isArray(this._selection)) {
                for (let data of this._selection) {
                    this.selectionKeys[
                        String(ObjectUtils.resolveFieldData(data, this.dataKey))
                    ] = 1;
                }
            } else {
                this.selectionKeys[
                    String(
                        ObjectUtils.resolveFieldData(
                            this._selection,
                            this.dataKey
                        )
                    )
                ] = 1;
            }
        }
    }

    onPageChange(event) {
        this.first = event.first;
        this.rows = event.rows;

        if (this.lazy) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
            this.getPagedData();
        }

        this.onPage.emit({
            first: this.first,
            rows: this.rows
        });

        this.tableService.onValueChange(this.value);

        if (this.isStateful()) {
            this.saveState();
        }

        this.setFooterText();
    }

    sort(event) {
        if(event.field != ''){

            let originalEvent = event.originalEvent;
    
            if (this.sortMode === 'single') {
                this._sortOrder =
                    this.sortField === event.field
                        ? this.sortOrder * -1
                        : this.defaultSortOrder;
                this._sortField = event.field;
                this.sortSingle();
            }
            if (this.sortMode === 'multiple') {
                let metaKey = originalEvent.metaKey || originalEvent.ctrlKey;
                let sortMeta = this.getSortMeta(event.field);
    
                if (sortMeta) {
                    if (!metaKey) {
                        this._multiSortMeta = [
                            { field: event.field, order: sortMeta.order * -1 }
                        ];
                    } else {
                        sortMeta.order = sortMeta.order * -1;
                    }
                } else {
                    if (!metaKey || !this.multiSortMeta) {
                        this._multiSortMeta = [];
                    }
                    this.multiSortMeta.push({
                        field: event.field,
                        order: this.defaultSortOrder
                    });
                }
    
                this.sortMultiple();
            }
    
            if (this.isStateful()) {
                this.saveState();
            }
        }
    }

    sortSingle() {
        if (this.sortField && this.sortOrder) {
            if (this.restoringSort) this.restoringSort = false;
            else if (this.resetPageOnSort) this.first = 0;

            if (this.lazy) {
                this.onLazyLoad.emit(this.createLazyLoadMetadata());
                this.getPagedData();
            } else if (this.value) {
                if (this.customSort) {
                    this.sortFunction.emit({
                        data: this.value,
                        mode: this.sortMode,
                        field: this.sortField,
                        order: this.sortOrder
                    });
                } else {
                    this.value.data.sort((data1, data2) => {
                        let value1 = ObjectUtils.resolveFieldData(
                            data1,
                            this.sortField
                        );
                        let value2 = ObjectUtils.resolveFieldData(
                            data2,
                            this.sortField
                        );
                        let result = null;

                        if (value1 == null && value2 != null) result = -1;
                        else if (value1 != null && value2 == null) result = 1;
                        else if (value1 == null && value2 == null) result = 0;
                        else if (
                            typeof value1 === 'string' &&
                            typeof value2 === 'string'
                        )
                            result = value1.localeCompare(value2);
                        else
                            result =
                                value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

                        return this.sortOrder * result;
                    });
                }

                if (this.hasFilter()) {
                    this._filter();
                }
            }

            let sortMeta: SortMeta = {
                field: this.sortField,
                order: this.sortOrder
            };

            this.onSort.emit(sortMeta);
            this.tableService.onSort(sortMeta);
        }
    }

    sortMultiple() {
        if (this.multiSortMeta) {
            if (this.lazy) {
                this.onLazyLoad.emit(this.createLazyLoadMetadata());
                this.getPagedData();
            } else if (this.value) {
                if (this.customSort) {
                    this.sortFunction.emit({
                        data: this.value,
                        mode: this.sortMode,
                        multiSortMeta: this.multiSortMeta
                    });
                } else {
                    this.value.data.sort((data1, data2) => {
                        return this.multisortField(
                            data1,
                            data2,
                            this.multiSortMeta,
                            0
                        );
                    });
                }

                if (this.hasFilter()) {
                    this._filter();
                }
            }

            this.onSort.emit({
                multisortmeta: this.multiSortMeta
            });
            this.tableService.onSort(this.multiSortMeta);
        }
    }

    multisortField(data1, data2, multiSortMeta, index) {
        let value1 = ObjectUtils.resolveFieldData(
            data1,
            multiSortMeta[index].field
        );
        let value2 = ObjectUtils.resolveFieldData(
            data2,
            multiSortMeta[index].field
        );
        let result = null;

        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        if (typeof value1 === 'string' || value1 instanceof String) {
            if (value1.localeCompare && value1 !== value2) {
                return (
                    multiSortMeta[index].order * value1.localeCompare(value2)
                );
            }
        } else {
            result = value1 < value2 ? -1 : 1;
        }

        if (value1 === value2) {
            return multiSortMeta.length - 1 > index
                ? this.multisortField(data1, data2, multiSortMeta, index + 1)
                : 0;
        }

        return multiSortMeta[index].order * result;
    }

    getSortMeta(field: string) {
        if (this.multiSortMeta && this.multiSortMeta.length) {
            for (let i = 0; i < this.multiSortMeta.length; i++) {
                if (this.multiSortMeta[i].field === field) {
                    return this.multiSortMeta[i];
                }
            }
        }

        return null;
    }

    isSorted(field: string) {
        if (this.sortMode === 'single') {
            return this._sortField && this._sortField === field;
        } else if (this.sortMode === 'multiple') {
            let sorted = false;
            if (this.multiSortMeta) {
                for (let i = 0; i < this.multiSortMeta.length; i++) {
                    if (this.multiSortMeta[i].field === field) {
                        sorted = true;
                        break;
                    }
                }
            }
            return sorted;
        }
    }

    handleRowClick(event) {
        let target = <HTMLElement>event.originalEvent.target;
        let targetNode = target.nodeName;
        let parentNode = target.parentElement.nodeName;
        if (
            targetNode === 'INPUT' ||
            targetNode === 'BUTTON' ||
            targetNode === 'A' ||
            parentNode === 'INPUT' ||
            parentNode === 'BUTTON' ||
            parentNode === 'A' ||
            DomHandler.hasClass(event.originalEvent.target, 'p-clickable')
        ) {
            return;
        }

        if (this.selectionMode) {
            this.preventSelectionSetterPropagation = true;
            if (
                this.isMultipleSelectionMode() &&
                event.originalEvent.shiftKey &&
                this.anchorRowIndex != null
            ) {
                DomHandler.clearSelection();
                if (this.rangeRowIndex != null) {
                    this.clearSelectionRange(event.originalEvent);
                }

                this.rangeRowIndex = event.rowIndex;
                this.selectRange(event.originalEvent, event.rowIndex);
            } else {
                let rowData = event.rowData;
                let selected = this.isSelected(rowData);
                let metaSelection = this.rowTouched
                    ? false
                    : this.metaKeySelection;
                let dataKeyValue = this.dataKey
                    ? String(
                        ObjectUtils.resolveFieldData(rowData, this.dataKey)
                    )
                    : null;
                this.anchorRowIndex = event.rowIndex;
                this.rangeRowIndex = event.rowIndex;

                if (metaSelection) {
                    let metaKey =
                        event.originalEvent.metaKey ||
                        event.originalEvent.ctrlKey;

                    if (selected && metaKey) {
                        if (this.isSingleSelectionMode()) {
                            this._selection = null;
                            this.selectionKeys = {};
                            this.selectionChange.emit(null);
                        } else {
                            let selectionIndex = this.findIndexInSelection(
                                rowData
                            );
                            this._selection = this.selection.filter(
                                (val, i) => i !== selectionIndex
                            );
                            this.selectionChange.emit(this.selection);
                            if (dataKeyValue) {
                                delete this.selectionKeys[dataKeyValue];
                            }
                        }

                        this.onRowUnselect.emit({
                            originalEvent: event.originalEvent,
                            data: rowData,
                            type: 'row'
                        });
                    } else {
                        if (this.isSingleSelectionMode()) {
                            this._selection = rowData;
                            this.selectionChange.emit(rowData);
                            if (dataKeyValue) {
                                this.selectionKeys = {};
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        } else if (this.isMultipleSelectionMode()) {
                            if (metaKey) {
                                this._selection = this.selection || [];
                            } else {
                                this._selection = [];
                                this.selectionKeys = {};
                            }

                            this._selection = [...this.selection, rowData];
                            this.selectionChange.emit(this.selection);
                            if (dataKeyValue) {
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        }

                        this.onRowSelect.emit({
                            originalEvent: event.originalEvent,
                            data: rowData,
                            type: 'row',
                            index: event.rowIndex
                        });
                    }
                } else {
                    if (this.selectionMode === 'single') {
                        if (selected) {
                            this._selection = null;
                            this.selectionKeys = {};
                            this.selectionChange.emit(this.selection);
                            this.onRowUnselect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row'
                            });
                        } else {
                            this._selection = rowData;
                            this.selectionChange.emit(this.selection);
                            this.onRowSelect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row',
                                index: event.rowIndex
                            });
                            if (dataKeyValue) {
                                this.selectionKeys = {};
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        }
                    } else if (this.selectionMode === 'multiple') {
                        if (selected) {
                            let selectionIndex = this.findIndexInSelection(
                                rowData
                            );
                            this._selection = this.selection.filter(
                                (val, i) => i !== selectionIndex
                            );
                            this.selectionChange.emit(this.selection);
                            this.onRowUnselect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row'
                            });
                            if (dataKeyValue) {
                                delete this.selectionKeys[dataKeyValue];
                            }
                        } else {
                            this._selection = this.selection
                                ? [...this.selection, rowData]
                                : [rowData];
                            this.selectionChange.emit(this.selection);
                            this.onRowSelect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row',
                                index: event.rowIndex
                            });
                            if (dataKeyValue) {
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        }
                    }
                }
            }

            this.tableService.onSelectionChange();

            if (this.isStateful()) {
                this.saveState();
            }
        }

        this.rowTouched = false;
    }

    handleRowTouchEnd() {
        this.rowTouched = true;
    }

    handleRowRightClick(event) {
        if (this.contextMenu) {
            const rowData = event.rowData;

            if (this.contextMenuSelectionMode === 'separate') {
                this.contextMenuSelection = rowData;
                this.contextMenuSelectionChange.emit(rowData);
                this.onContextMenuSelect.emit({
                    originalEvent: event.originalEvent,
                    data: rowData,
                    index: event.rowIndex
                });
                this.contextMenu.show(event.originalEvent);
                this.tableService.onContextMenu(rowData);
            } else if (this.contextMenuSelectionMode === 'joint') {
                this.preventSelectionSetterPropagation = true;
                let selected = this.isSelected(rowData);
                let dataKeyValue = this.dataKey
                    ? String(
                        ObjectUtils.resolveFieldData(rowData, this.dataKey)
                    )
                    : null;

                if (!selected) {
                    if (this.isSingleSelectionMode()) {
                        this.selection = rowData;
                        this.selectionChange.emit(rowData);
                    } else if (this.isMultipleSelectionMode()) {
                        this.selection = [rowData];
                        this.selectionChange.emit(this.selection);
                    }

                    if (dataKeyValue) {
                        this.selectionKeys[dataKeyValue] = 1;
                    }
                }

                this.contextMenu.show(event.originalEvent);
                this.onContextMenuSelect.emit({
                    originalEvent: event,
                    data: rowData,
                    index: event.rowIndex
                });
            }
        }
    }

    selectRange(event: MouseEvent, rowIndex: number) {
        let rangeStart, rangeEnd;

        if (this.anchorRowIndex > rowIndex) {
            rangeStart = rowIndex;
            rangeEnd = this.anchorRowIndex;
        } else if (this.anchorRowIndex < rowIndex) {
            rangeStart = this.anchorRowIndex;
            rangeEnd = rowIndex;
        } else {
            rangeStart = rowIndex;
            rangeEnd = rowIndex;
        }

        for (let i = rangeStart; i <= rangeEnd; i++) {
            let rangeRowData = this.filteredValue
                ? this.filteredValue[i]
                : this.value[i];
            if (!this.isSelected(rangeRowData)) {
                this._selection = [...this.selection, rangeRowData];
                let dataKeyValue: string = this.dataKey
                    ? String(
                        ObjectUtils.resolveFieldData(
                            rangeRowData,
                            this.dataKey
                        )
                    )
                    : null;
                if (dataKeyValue) {
                    this.selectionKeys[dataKeyValue] = 1;
                }
                this.onRowSelect.emit({
                    originalEvent: event,
                    data: rangeRowData,
                    type: 'row'
                });
            }
        }

        this.selectionChange.emit(this.selection);
    }

    clearSelectionRange(event: MouseEvent) {
        let rangeStart, rangeEnd;

        if (this.rangeRowIndex > this.anchorRowIndex) {
            rangeStart = this.anchorRowIndex;
            rangeEnd = this.rangeRowIndex;
        } else if (this.rangeRowIndex < this.anchorRowIndex) {
            rangeStart = this.rangeRowIndex;
            rangeEnd = this.anchorRowIndex;
        } else {
            rangeStart = this.rangeRowIndex;
            rangeEnd = this.rangeRowIndex;
        }

        for (let i = rangeStart; i <= rangeEnd; i++) {
            let rangeRowData = this.value[i];
            let selectionIndex = this.findIndexInSelection(rangeRowData);
            this._selection = this.selection.filter(
                (val, i) => i !== selectionIndex
            );
            let dataKeyValue: string = this.dataKey
                ? String(
                    ObjectUtils.resolveFieldData(rangeRowData, this.dataKey)
                )
                : null;
            if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
            }
            this.onRowUnselect.emit({
                originalEvent: event,
                data: rangeRowData,
                type: 'row'
            });
        }
    }

    isSelected(rowData) {
        if (rowData && this.selection) {
            if (this.dataKey) {
                return (
                    this.selectionKeys[
                    ObjectUtils.resolveFieldData(rowData, this.dataKey)
                    ] !== undefined
                );
            } else {
                if (this.selection instanceof Array)
                    return this.findIndexInSelection(rowData) > -1;
                else return this.equals(rowData, this.selection);
            }
        }

        return false;
    }

    findIndexInSelection(rowData: any) {
        let index: number = -1;
        if (this.selection && this.selection.length) {
            for (let i = 0; i < this.selection.length; i++) {
                if (this.equals(rowData, this.selection[i])) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }

    toggleRowWithRadio(event: any, rowData: any) {
        this.preventSelectionSetterPropagation = true;

        if (this.selection !== rowData) {
            this._selection = rowData;
            this.selectionChange.emit(this.selection);
            this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'radiobutton'
            });

            if (this.dataKey) {
                this.selectionKeys = {};
                this.selectionKeys[
                    String(ObjectUtils.resolveFieldData(rowData, this.dataKey))
                ] = 1;
            }
        } else {
            this._selection = null;
            this.selectionChange.emit(this.selection);
            this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'radiobutton'
            });
        }

        this.tableService.onSelectionChange();

        if (this.isStateful()) {
            this.saveState();
        }
    }

    toggleRowWithCheckbox(event, rowData: any) {
        this.selection = this.selection || [];
        let selected = this.isSelected(rowData);
        let dataKeyValue = this.dataKey
            ? String(ObjectUtils.resolveFieldData(rowData, this.dataKey))
            : null;
        this.preventSelectionSetterPropagation = true;

        if (selected) {
            let selectionIndex = this.findIndexInSelection(rowData);
            this._selection = this.selection.filter(
                (val, i) => i !== selectionIndex
            );
            this.selectionChange.emit(this.selection);
            this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'checkbox'
            });
            if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
            }
        } else {
            this._selection = this.selection
                ? [...this.selection, rowData]
                : [rowData];
            this.selectionChange.emit(this.selection);
            this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'checkbox'
            });
            if (dataKeyValue) {
                this.selectionKeys[dataKeyValue] = 1;
            }
        }

        this.tableService.onSelectionChange();

        if (this.isStateful()) {
            this.saveState();
        }
    }

    toggleRowsWithCheckbox(event: Event, check: boolean) {
        this._selection = check
            ? this.filteredValue
                ? this.filteredValue.slice()
                : this.value.data.slice()
            : [];
        this.preventSelectionSetterPropagation = true;
        this.updateSelectionKeys();
        this.selectionChange.emit(this._selection);
        this.tableService.onSelectionChange();
        this.onHeaderCheckboxToggle.emit({
            originalEvent: event,
            checked: check
        });

        if (this.isStateful()) {
            this.saveState();
        }
    }

    equals(data1, data2) {
        return this.compareSelectionBy === 'equals'
            ? data1 === data2
            : ObjectUtils.equals(data1, data2, this.dataKey);
    }

    filter(value, field, matchMode) {
        if (this.filterTimeout) {
            clearTimeout(this.filterTimeout);
        }

        if (!this.isFilterBlank(value)) {
            this.filters[field] = { value: value, matchMode: matchMode };
        } else if (this.filters[field]) {
            delete this.filters[field];
        }

        this.filterTimeout = setTimeout(() => {
            this._filter();
            this.filterTimeout = null;
        }, this.filterDelay);
    }

    filterGlobal(value, matchMode) {
        this.filter(value, 'global', matchMode);
    }

    isFilterBlank(filter: any): boolean {
        if (filter !== null && filter !== undefined) {
            if (
                (typeof filter === 'string' && filter.trim().length === 0) ||
                (filter instanceof Array && filter.length === 0)
            )
                return true;
            else return false;
        }
        return true;
    }

    _filter() {
        if (this.lazy) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
            this.getPagedData();
        } else {
            if (!this.value) {
                return;
            }

            if (!this.hasFilter()) {
                this.filteredValue = null;
                if (this.paginator) {
                    this.totalCount = this.value ? this.value.data.length : 0;
                }
            } else {
                let globalFilterFieldsArray;
                if (this.filters['global']) {
                    if (!this.columns && !this.globalFilterFields)
                        throw new Error(
                            'Global filtering requires dynamic columns or globalFilterFields to be defined.'
                        );
                    else
                        globalFilterFieldsArray =
                            this.globalFilterFields || this.columns;
                }

                this.filteredValue = [];

                for (let i = 0; i < this.value.data.length; i++) {
                    let localMatch = true;
                    let globalMatch = false;
                    let localFiltered = false;

                    for (let prop in this.filters) {
                        if (
                            this.filters.hasOwnProperty(prop) &&
                            prop !== 'global'
                        ) {
                            localFiltered = true;
                            let filterMeta = this.filters[prop];
                            let filterField = prop;
                            let filterValue = filterMeta[0].value;
                            let filterMatchMode =
                                filterMeta[0].matchMode || 'startsWith';
                            let dataFieldValue = ObjectUtils.resolveFieldData(
                                this.value[i],
                                filterField
                            );
                            let filterConstraint = this.filterConstraints[
                                filterMatchMode
                            ];

                            if (
                                !filterConstraint(dataFieldValue, filterValue)
                            ) {
                                localMatch = false;
                            }

                            if (!localMatch) {
                                break;
                            }
                        }
                    }

                    if (
                        this.filters['global'] &&
                        !globalMatch &&
                        globalFilterFieldsArray
                    ) {
                        for (
                            let j = 0;
                            j < globalFilterFieldsArray.length;
                            j++
                        ) {
                            let globalFilterField =
                                globalFilterFieldsArray[j].field ||
                                globalFilterFieldsArray[j];
                            globalMatch = this.filterConstraints[
                                this.filters['global'][0].matchMode
                            ](
                                ObjectUtils.resolveFieldData(
                                    this.value[i],
                                    globalFilterField
                                ),
                                this.filters['global'][0].value
                            );

                            if (globalMatch) {
                                break;
                            }
                        }
                    }

                    let matches: boolean;
                    if (this.filters['global']) {
                        matches = localFiltered
                            ? localFiltered && localMatch && globalMatch
                            : globalMatch;
                    } else {
                        matches = localFiltered && localMatch;
                    }

                    if (matches) {
                        this.filteredValue.push(this.value[i]);
                    }
                }

                if (this.filteredValue.length === this.value.data.length) {
                    this.filteredValue = null;
                }

                if (this.paginator) {
                    this.totalCount = this.filteredValue
                        ? this.filteredValue.length
                        : this.value
                            ? this.value.data.length
                            : 0;
                }
            }
        }

        this.onFilter.emit({
            filters: this.filters,
            filteredValue: this.filteredValue || this.value
        });

        this.tableService.onValueChange(this.value);

        if (this.isStateful() && !this.restoringFilter) {
            this.saveState();
        }

        if (this.restoringFilter) this.restoringFilter = false;
        else this.first = 0;
    }

    hasFilter() {
        let empty = true;
        for (let prop in this.filters) {
            if (this.filters.hasOwnProperty(prop)) {
                empty = false;
                break;
            }
        }

        return !empty;
    }

    filterConstraints = {
        startsWith(value, filter): boolean {
            if (
                filter === undefined ||
                filter === null ||
                filter.trim() === ''
            ) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            let filterValue = ObjectUtils.removeAccents(
                filter.toString()
            ).toLowerCase();
            let stringValue = ObjectUtils.removeAccents(
                value.toString()
            ).toLowerCase();

            return stringValue.slice(0, filterValue.length) === filterValue;
        },

        contains(value, filter): boolean {
            if (
                filter === undefined ||
                filter === null ||
                (typeof filter === 'string' && filter.trim() === '')
            ) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            let filterValue = ObjectUtils.removeAccents(
                filter.toString()
            ).toLowerCase();
            let stringValue = ObjectUtils.removeAccents(
                value.toString()
            ).toLowerCase();

            return stringValue.indexOf(filterValue) !== -1;
        },

        endsWith(value, filter): boolean {
            if (
                filter === undefined ||
                filter === null ||
                filter.trim() === ''
            ) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            let filterValue = ObjectUtils.removeAccents(
                filter.toString()
            ).toLowerCase();
            let stringValue = ObjectUtils.removeAccents(
                value.toString()
            ).toLowerCase();

            return (
                stringValue.indexOf(
                    filterValue,
                    stringValue.length - filterValue.length
                ) !== -1
            );
        },

        equals(value, filter): boolean {
            if (
                filter === undefined ||
                filter === null ||
                (typeof filter === 'string' && filter.trim() === '')
            ) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() === filter.getTime();
            else
                return (
                    ObjectUtils.removeAccents(
                        value.toString()
                    ).toLowerCase() ===
                    ObjectUtils.removeAccents(filter.toString()).toLowerCase()
                );
        },

        notEquals(value, filter): boolean {
            if (
                filter === undefined ||
                filter === null ||
                (typeof filter === 'string' && filter.trim() === '')
            ) {
                return false;
            }

            if (value === undefined || value === null) {
                return true;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() !== filter.getTime();
            else
                return (
                    ObjectUtils.removeAccents(
                        value.toString()
                    ).toLowerCase() !==
                    ObjectUtils.removeAccents(filter.toString()).toLowerCase()
                );
        },

        in(value, filter: any[]): boolean {
            if (
                filter === undefined ||
                filter === null ||
                filter.length === 0
            ) {
                return true;
            }

            for (let i = 0; i < filter.length; i++) {
                if (
                    filter[i] === value ||
                    (value != null &&
                        (value.getTime &&
                            filter[i].getTime &&
                            value.getTime() === filter[i].getTime()))
                ) {
                    return true;
                }
            }

            return false;
        },

        lt(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() < filter.getTime();
            else return value < filter;
        },

        lte(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() <= filter.getTime();
            else return value <= filter;
        },

        gt(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() > filter.getTime();
            else return value > filter;
        },

        gte(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() >= filter.getTime();
            else return value >= filter;
        }
    };

    createLazyLoadMetadata(): any {
        return {
            first: this.first,
            rows: this.virtualScroll ? this.rows * 2 : this.rows,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
            filters: this.filters,
            globalFilter:
                this.filters && this.filters['global']
                    ? this.filters['global'][0].value
                    : null,
            multiSortMeta: this.multiSortMeta
        };
    }

    public reset() {
        this._sortField = null;
        this._sortOrder = this.defaultSortOrder;
        this._multiSortMeta = null;
        this.tableService.onSort(null);

        this.filteredValue = null;
        this.filters = {};

        this.first = 0;

        if (this.lazy) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
            this.getPagedData();
        } else {
            this.totalCount = this._value ? this._value.data.length : 0;
        }
    }

    public resetSkipTake(){
        this.first = 0;
        this.totalCount = this._value ? this._value.data.length : 0;
    }

    public setPage(pageIndex: number) {
        this.first = pageIndex >= 0 ? pageIndex : 0;
    }

    public exportCSV(options?: any) {
        let data = this.filteredValue || this.value.data;
        let csv = '\ufeff';

        if (options && options.selectionOnly) {
            data = this.selection || [];
        }

        //headers
        for (let i = 0; i < this.columns.length; i++) {
            let column = this.columns[i];
            if (column.exportable !== false && column.field) {
                csv += '"' + (column.header || column.field) + '"';

                if (i < this.columns.length - 1) {
                    csv += this.csvSeparator;
                }
            }
        }

        //body
        data.forEach(record => {
            csv += '\n';
            for (let i = 0; i < this.columns.length; i++) {
                let column = this.columns[i];
                if (column.exportable !== false && column.field) {
                    let cellData = ObjectUtils.resolveFieldData(
                        record,
                        column.field
                    );

                    if (cellData != null) {
                        if (this.exportFunction) {
                            cellData = this.exportFunction({
                                data: cellData,
                                field: column.field
                            });
                        } else cellData = String(cellData).replace(/"/g, '""');
                    } else cellData = '';

                    csv += '"' + cellData + '"';

                    if (i < this.columns.length - 1) {
                        csv += this.csvSeparator;
                    }
                }
            }
        });

        let blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8;'
        });

        if ((window.navigator as any).msSaveOrOpenBlob) {
            (navigator as any).msSaveOrOpenBlob(blob, this.exportFilename + '.csv');
        } else {
            let link = document.createElement('a');
            link.style.display = 'none';
            document.body.appendChild(link);
            if (link.download !== undefined) {
                link.setAttribute('href', URL.createObjectURL(blob));
                link.setAttribute('download', this.exportFilename + '.csv');
                link.click();
            } else {
                csv = 'data:text/csv;charset=utf-8,' + csv;
                window.open(encodeURI(csv));
            }
            document.body.removeChild(link);
        }
    }

    updateEditingCell(cell) {
        this.editingCell = cell;
        this.bindDocumentEditListener();
    }

    isEditingCellValid() {
        return (
            this.editingCell &&
            DomHandler.find(this.editingCell, '.ng-invalid.ng-dirty').length ===
            0
        );
    }

    bindDocumentEditListener() {
        if (!this.documentEditListener) {
            this.documentEditListener = () => {
                if (
                    this.editingCell &&
                    !this.editingCellClick &&
                    this.isEditingCellValid()
                ) {
                    DomHandler.removeClass(this.editingCell, 'p-editing-cell');
                    this.editingCell = null;
                    this.unbindDocumentEditListener();
                }

                this.editingCellClick = false;
            };

            document.addEventListener('click', this.documentEditListener);
        }
    }

    unbindDocumentEditListener() {
        if (this.documentEditListener) {
            document.removeEventListener('click', this.documentEditListener);
            this.documentEditListener = null;
        }
    }

    toggleRow(rowData: any, event?: Event) {
        if (!this.dataKey) {
            throw new Error('dataKey must be defined to use row expansion');
        }

        let dataKeyValue = String(
            ObjectUtils.resolveFieldData(rowData, this.dataKey)
        );

        if (this.expandedRowKeys[dataKeyValue] != null) {
            delete this.expandedRowKeys[dataKeyValue];
            this.onRowCollapse.emit({
                originalEvent: event,
                data: rowData
            });
        } else {
            if (this.rowExpandMode === 'single') {
                this.expandedRowKeys = {};
            }

            this.expandedRowKeys[dataKeyValue] = 1;
            this.onRowExpand.emit({
                originalEvent: event,
                data: rowData
            });
        }

        if (event) {
            event.preventDefault();
        }

        if (this.isStateful()) {
            this.saveState();
        }
    }

    isRowExpanded(rowData: any): boolean {
        return (
            this.expandedRowKeys[
            String(ObjectUtils.resolveFieldData(rowData, this.dataKey))
            ] === 1
        );
    }

    isSingleSelectionMode() {
        return this.selectionMode === 'single';
    }

    isMultipleSelectionMode() {
        return this.selectionMode === 'multiple';
    }

    onColumnResizeBegin(event) {
        let containerLeft = DomHandler.getOffset(
            this.containerViewChild.nativeElement
        ).left;
        this.lastResizerHelperX =
            event.pageX -
            containerLeft +
            this.containerViewChild.nativeElement.scrollLeft;
        event.preventDefault();
    }

    onColumnResize(event) {
        let containerLeft = DomHandler.getOffset(
            this.containerViewChild.nativeElement
        ).left;
        DomHandler.addClass(
            this.containerViewChild.nativeElement,
            'p-unselectable-text'
        );
        this.resizeHelperViewChild.nativeElement.style.height =
            this.containerViewChild.nativeElement.offsetHeight + 'px';
        this.resizeHelperViewChild.nativeElement.style.top = 0 + 'px';
        this.resizeHelperViewChild.nativeElement.style.left =
            event.pageX -
            containerLeft +
            this.containerViewChild.nativeElement.scrollLeft +
            'px';

        this.resizeHelperViewChild.nativeElement.style.display = 'block';
    }

    onColumnResizeEnd(column) {
        let delta =
            this.resizeHelperViewChild.nativeElement.offsetLeft -
            this.lastResizerHelperX;
        let columnWidth = column.offsetWidth;
        let minWidth = parseInt(column.style.minWidth || 15);

        if (columnWidth + delta < minWidth) {
            delta = minWidth - columnWidth;
        }

        const newColumnWidth = columnWidth + delta;

        if (newColumnWidth >= minWidth) {
            if (this.columnResizeMode === 'fit') {
                let nextColumn = column.nextElementSibling;
                while (!nextColumn.offsetParent) {
                    nextColumn = nextColumn.nextElementSibling;
                }

                if (nextColumn) {
                    let nextColumnWidth = nextColumn.offsetWidth - delta;
                    let nextColumnMinWidth = nextColumn.style.minWidth || 15;

                    if (
                        newColumnWidth > 15 &&
                        nextColumnWidth > parseInt(nextColumnMinWidth)
                    ) {
                        if (this.scrollable) {
                            let scrollableView = this.findParentScrollableView(
                                column
                            );
                            let scrollableBodyTable = DomHandler.findSingle(
                                scrollableView,
                                'table.p-table-scrollable-body-table'
                            );
                            let scrollableHeaderTable = DomHandler.findSingle(
                                scrollableView,
                                'table.p-table-scrollable-header-table'
                            );
                            let scrollableFooterTable = DomHandler.findSingle(
                                scrollableView,
                                'table.p-table-scrollable-footer-table'
                            );
                            let resizeColumnIndex = DomHandler.index(column);

                            this.resizeColGroup(
                                scrollableHeaderTable,
                                resizeColumnIndex,
                                newColumnWidth,
                                nextColumnWidth
                            );
                            this.resizeColGroup(
                                scrollableBodyTable,
                                resizeColumnIndex,
                                newColumnWidth,
                                nextColumnWidth
                            );
                            this.resizeColGroup(
                                scrollableFooterTable,
                                resizeColumnIndex,
                                newColumnWidth,
                                nextColumnWidth
                            );
                        } else {
                            column.style.width = newColumnWidth + 'px';
                            if (nextColumn) {
                                nextColumn.style.width = nextColumnWidth + 'px';
                            }
                        }
                    }
                }
            } else if (this.columnResizeMode === 'expand') {
                if (newColumnWidth > minWidth) {
                    if (this.scrollable) {
                        let scrollableView = this.findParentScrollableView(
                            column
                        );
                        let scrollableBodyTable = DomHandler.findSingle(
                            scrollableView,
                            'table.p-table-scrollable-body-table'
                        );
                        let scrollableHeaderTable = DomHandler.findSingle(
                            scrollableView,
                            'table.p-table-scrollable-header-table'
                        );
                        let scrollableFooterTable = DomHandler.findSingle(
                            scrollableView,
                            'table.p-table-scrollable-footer-table'
                        );
                        scrollableBodyTable.style.width =
                            scrollableBodyTable.offsetWidth + delta + 'px';
                        scrollableHeaderTable.style.width =
                            scrollableHeaderTable.offsetWidth + delta + 'px';
                        if (scrollableFooterTable) {
                            scrollableFooterTable.style.width =
                                scrollableHeaderTable.offsetWidth +
                                delta +
                                'px';
                        }
                        let resizeColumnIndex = DomHandler.index(column);

                        this.resizeColGroup(
                            scrollableHeaderTable,
                            resizeColumnIndex,
                            newColumnWidth,
                            null
                        );
                        this.resizeColGroup(
                            scrollableBodyTable,
                            resizeColumnIndex,
                            newColumnWidth,
                            null
                        );
                        this.resizeColGroup(
                            scrollableFooterTable,
                            resizeColumnIndex,
                            newColumnWidth,
                            null
                        );
                    } else {
                        this.tableViewChild.nativeElement.style.width =
                            this.tableViewChild.nativeElement.offsetWidth +
                            delta +
                            'px';
                        column.style.width = newColumnWidth + 'px';
                        let containerWidth = this.tableViewChild.nativeElement
                            .style.width;
                        this.containerViewChild.nativeElement.style.width =
                            containerWidth + 'px';
                    }
                }
            }

            this.onColResize.emit({
                element: column,
                delta: delta
            });

            if (this.isStateful()) {
                this.saveState();
            }
        }

        this.resizeHelperViewChild.nativeElement.style.display = 'none';
        DomHandler.removeClass(
            this.containerViewChild.nativeElement,
            'p-unselectable-text'
        );
    }

    findParentScrollableView(column) {
        if (column) {
            let parent = column.parentElement;
            while (
                parent &&
                !DomHandler.hasClass(parent, 'p-table-scrollable-view')
            ) {
                parent = parent.parentElement;
            }

            return parent;
        } else {
            return null;
        }
    }

    resizeColGroup(table, resizeColumnIndex, newColumnWidth, nextColumnWidth) {
        if (table) {
            let colGroup =
                table.children[0].nodeName === 'COLGROUP'
                    ? table.children[0]
                    : null;

            if (colGroup) {
                let col = colGroup.children[resizeColumnIndex];
                let nextCol = col.nextElementSibling;
                col.style.width = newColumnWidth + 'px';

                if (nextCol && nextColumnWidth) {
                    nextCol.style.width = nextColumnWidth + 'px';
                }
            } else {
                throw 'Scrollable tables require a colgroup to support resizable columns';
            }
        }
    }

    onColumnDragStart(event, columnElement) {
        this.reorderIconWidth = DomHandler.getHiddenElementOuterWidth(
            this.reorderIndicatorUpViewChild.nativeElement
        );
        this.reorderIconHeight = DomHandler.getHiddenElementOuterHeight(
            this.reorderIndicatorDownViewChild.nativeElement
        );
        this.draggedColumn = columnElement;
        event.dataTransfer.setData('text', 'b'); // For firefox
    }

    onColumnDragEnter(event, dropHeader) {
        if (this.reorderableColumns && this.draggedColumn && dropHeader) {
            event.preventDefault();
            let containerOffset = DomHandler.getOffset(
                this.containerViewChild.nativeElement
            );
            let dropHeaderOffset = DomHandler.getOffset(dropHeader);

            if (this.draggedColumn !== dropHeader) {
                let dragIndex = DomHandler.indexWithinGroup(
                    this.draggedColumn,
                    'preorderablecolumn'
                );
                let dropIndex = DomHandler.indexWithinGroup(
                    dropHeader,
                    'preorderablecolumn'
                );
                let targetLeft = dropHeaderOffset.left - containerOffset.left;
                let columnCenter =
                    dropHeaderOffset.left + dropHeader.offsetWidth / 2;

                this.reorderIndicatorUpViewChild.nativeElement.style.top =
                    dropHeaderOffset.top -
                    containerOffset.top -
                    (this.reorderIconHeight - 1) +
                    'px';
                this.reorderIndicatorDownViewChild.nativeElement.style.top =
                    dropHeaderOffset.top -
                    containerOffset.top +
                    dropHeader.offsetHeight +
                    'px';

                if (event.pageX > columnCenter) {
                    this.reorderIndicatorUpViewChild.nativeElement.style.left =
                        targetLeft +
                        dropHeader.offsetWidth -
                        Math.ceil(this.reorderIconWidth / 2) +
                        'px';
                    this.reorderIndicatorDownViewChild.nativeElement.style.left =
                        targetLeft +
                        dropHeader.offsetWidth -
                        Math.ceil(this.reorderIconWidth / 2) +
                        'px';
                    this.dropPosition = 1;
                } else {
                    this.reorderIndicatorUpViewChild.nativeElement.style.left =
                        targetLeft -
                        Math.ceil(this.reorderIconWidth / 2) +
                        'px';
                    this.reorderIndicatorDownViewChild.nativeElement.style.left =
                        targetLeft -
                        Math.ceil(this.reorderIconWidth / 2) +
                        'px';
                    this.dropPosition = -1;
                }

                if (
                    (dropIndex - dragIndex === 1 && this.dropPosition === -1) ||
                    (dropIndex - dragIndex === -1 && this.dropPosition === 1)
                ) {
                    this.reorderIndicatorUpViewChild.nativeElement.style.display =
                        'none';
                    this.reorderIndicatorDownViewChild.nativeElement.style.display =
                        'none';
                } else {
                    this.reorderIndicatorUpViewChild.nativeElement.style.display =
                        'block';
                    this.reorderIndicatorDownViewChild.nativeElement.style.display =
                        'block';
                }
            } else {
                event.dataTransfer.dropEffect = 'none';
            }
        }
    }

    onColumnDragLeave(event) {
        if (this.reorderableColumns && this.draggedColumn) {
            event.preventDefault();
            this.reorderIndicatorUpViewChild.nativeElement.style.display =
                'none';
            this.reorderIndicatorDownViewChild.nativeElement.style.display =
                'none';
        }
    }

    onColumnDrop(event, dropColumn) {
        event.preventDefault();
        if (this.draggedColumn) {
            let dragIndex = DomHandler.indexWithinGroup(
                this.draggedColumn,
                'preorderablecolumn'
            );
            let dropIndex = DomHandler.indexWithinGroup(
                dropColumn,
                'preorderablecolumn'
            );
            let allowDrop = dragIndex !== dropIndex;
            if (
                allowDrop &&
                ((dropIndex - dragIndex === 1 && this.dropPosition === -1) ||
                    (dragIndex - dropIndex === 1 && this.dropPosition === 1))
            ) {
                allowDrop = false;
            }

            if (
                allowDrop &&
                (dropIndex < dragIndex && this.dropPosition === 1)
            ) {
                dropIndex = dropIndex + 1;
            }

            if (
                allowDrop &&
                (dropIndex > dragIndex && this.dropPosition === -1)
            ) {
                dropIndex = dropIndex - 1;
            }

            if (allowDrop) {
                ObjectUtils.reorderArray(this.columns, dragIndex, dropIndex);

                this.onColReorder.emit({
                    dragIndex: dragIndex,
                    dropIndex: dropIndex,
                    columns: this.columns
                });

                if (this.isStateful()) {
                    this.saveState();
                }
            }

            this.reorderIndicatorUpViewChild.nativeElement.style.display =
                'none';
            this.reorderIndicatorDownViewChild.nativeElement.style.display =
                'none';
            this.draggedColumn.draggable = false;
            this.draggedColumn = null;
            this.dropPosition = null;
        }
    }

    onRowDragStart(event, index) {
        this.rowDragging = true;
        this.draggedRowIndex = index;
        event.dataTransfer.setData('text', 'b'); // For firefox
    }

    onRowDragOver(event, index, rowElement) {
        if (this.rowDragging && this.draggedRowIndex !== index) {
            let rowY =
                DomHandler.getOffset(rowElement).top +
                DomHandler.getWindowScrollTop();
            let pageY = event.pageY;
            let rowMidY = rowY + DomHandler.getOuterHeight(rowElement) / 2;
            let prevRowElement = rowElement.previousElementSibling;

            if (pageY < rowMidY) {
                DomHandler.removeClass(rowElement, 'p-table-dragpoint-bottom');

                this.droppedRowIndex = index;
                if (prevRowElement)
                    DomHandler.addClass(
                        prevRowElement,
                        'p-table-dragpoint-bottom'
                    );
                else DomHandler.addClass(rowElement, 'p-table-dragpoint-top');
            } else {
                if (prevRowElement)
                    DomHandler.removeClass(
                        prevRowElement,
                        'p-table-dragpoint-bottom'
                    );
                else DomHandler.addClass(rowElement, 'p-table-dragpoint-top');

                this.droppedRowIndex = index + 1;
                DomHandler.addClass(rowElement, 'p-table-dragpoint-bottom');
            }
        }
    }

    onRowDragLeave(rowElement) {
        let prevRowElement = rowElement.previousElementSibling;
        if (prevRowElement) {
            DomHandler.removeClass(prevRowElement, 'p-table-dragpoint-bottom');
        }

        DomHandler.removeClass(rowElement, 'p-table-dragpoint-bottom');
        DomHandler.removeClass(rowElement, 'p-table-dragpoint-top');
    }

    onRowDragEnd() {
        this.rowDragging = false;
        this.draggedRowIndex = null;
        this.droppedRowIndex = null;
    }

    onRowDrop(event, rowElement) {
        if (this.droppedRowIndex != null) {
            let dropIndex =
                this.draggedRowIndex > this.droppedRowIndex
                    ? this.droppedRowIndex
                    : this.droppedRowIndex === 0
                        ? 0
                        : this.droppedRowIndex - 1;
            ObjectUtils.reorderArray(
                this.value.data,
                this.draggedRowIndex,
                dropIndex
            );

            this.onRowReorder.emit({
                dragIndex: this.draggedRowIndex,
                dropIndex: this.droppedRowIndex
            });
        }
        //cleanup
        this.onRowDragLeave(rowElement);
        this.onRowDragEnd();
    }

    handleVirtualScroll(event) {
        this.first = (event.page - 1) * this.rows;
        this.virtualScrollCallback = event.callback;

        this.zone.run(() => {
            if (this.virtualScrollTimer) {
                clearTimeout(this.virtualScrollTimer);
            }

            this.virtualScrollTimer = setTimeout(() => {
                this.onLazyLoad.emit(this.createLazyLoadMetadata());
                this.getPagedData();
            }, this.virtualScrollDelay);
        });
    }

    isEmpty() {
        const value = !!this._value ? this._value.data : [];
        let data = this.filteredValue || value;
        return data == null || data.length === 0;
    }

    getBlockableElement(): HTMLElement {
        return this.el.nativeElement.children[0];
    }

    getStorage() {
        switch (this.stateStorage) {
            case 'local':
                return window.localStorage;

            case 'session':
                return window.sessionStorage;

            default:
                throw new Error(
                    this.stateStorage +
                    ' is not a valid value for the state storage, supported values are "local" and "session".'
                );
        }
    }

    isStateful() {
        return this.stateKey != null;
    }

    saveState() {
        const storage = this.getStorage();
        let state: TableState = {};

        if (this.paginator) {
            state.first = this.first;
            state.rows = this.rows;
        }

        if (this.sortField) {
            state.sortField = this.sortField;
            state.sortOrder = this.sortOrder;
        }

        if (this.multiSortMeta) {
            state.multiSortMeta = this.multiSortMeta;
        }

        if (this.hasFilter()) {
            state.filters = this.filters;
        }

        if (this.resizableColumns) {
            this.saveColumnWidths(state);
        }

        if (this.reorderableColumns) {
            this.saveColumnOrder(state);
        }

        if (this.selection) {
            state.selection = this.selection;
        }

        // if (Object.keys(this.expandedRowKeys).length) {
        //     state.expandedRowKeys = this.expandedRowKeys;
        // }

        if (Object.keys(state).length) {
            storage.setItem(this.stateKey, JSON.stringify(state));
        }
    }

    clearState() {
        const storage = this.getStorage();

        if (this.stateKey) {
            storage.removeItem(this.stateKey);
        }
    }

    restoreState() {
        const storage = this.getStorage();
        const stateString = storage.getItem(this.stateKey);

        if (stateString) {
            let state: TableState = JSON.parse(stateString);

            if (this.paginator) {
                this.first = state.first;
                this.rows = state.rows;
            }

            if (state.sortField) {
                this.restoringSort = true;
                this._sortField = state.sortField;
                this._sortOrder = state.sortOrder;
            }

            if (state.multiSortMeta) {
                this.restoringSort = true;
                this._multiSortMeta = state.multiSortMeta;
            }

            if (state.filters) {
                this.restoringFilter = true;
                this.filters = state.filters;
            }

            if (this.resizableColumns) {
                this.columnWidthsState = state.columnWidths;
                this.tableWidthState = state.tableWidth;
            }

            // if (state.expandedRowKeys) {
            //     this.expandedRowKeys = state.expandedRowKeys;
            // }

            if (state.selection) {
                this.selection = state.selection;
            }

            this.stateRestored = true;
        }
    }

    saveColumnWidths(state) {
        let widths = [];
        let headers = DomHandler.find(
            this.containerViewChild.nativeElement,
            '.p-table-thead > tr:first-child > th'
        );
        headers.map(header => widths.push(DomHandler.getOuterWidth(header)));
        state.columnWidths = widths.join(',');

        if (this.columnResizeMode === 'expand') {
            state.tableWidth = this.scrollable
                ? DomHandler.findSingle(
                    this.containerViewChild.nativeElement,
                    '.p-table-scrollable-header-table'
                ).style.width
                : DomHandler.getOuterWidth(this.tableViewChild.nativeElement) +
                'px';
        }
    }

    restoreColumnWidths() {
        if (this.columnWidthsState) {
            let widths = this.columnWidthsState.split(',');

            if (this.columnResizeMode === 'expand' && this.tableWidthState) {
                if (this.scrollable) {
                    let scrollableBodyTable = DomHandler.findSingle(
                        this.containerViewChild.nativeElement,
                        '.p-table-scrollable-body-table'
                    );
                    let scrollableHeaderTable = DomHandler.findSingle(
                        this.containerViewChild.nativeElement,
                        '.p-table-scrollable-header-table'
                    );
                    let scrollableFooterTable = DomHandler.findSingle(
                        this.containerViewChild.nativeElement,
                        '.p-table-scrollable-footer-table'
                    );
                    scrollableBodyTable.style.width = this.tableWidthState;
                    scrollableHeaderTable.style.width = this.tableWidthState;

                    if (scrollableFooterTable) {
                        scrollableFooterTable.style.width = this.tableWidthState;
                    }
                } else {
                    this.tableViewChild.nativeElement.style.width = this.tableWidthState;
                    this.containerViewChild.nativeElement.style.width = this.tableWidthState;
                }
            }

            if (this.scrollable) {
                let headerCols = DomHandler.find(
                    this.containerViewChild.nativeElement,
                    '.p-table-scrollable-header-table > colgroup > col'
                );
                let bodyCols = DomHandler.find(
                    this.containerViewChild.nativeElement,
                    '.p-table-scrollable-body-table > colgroup > col'
                );

                headerCols.map(
                    (col, index) => (col.style.width = widths[index] + 'px')
                );
                bodyCols.map(
                    (col, index) => (col.style.width = widths[index] + 'px')
                );
            } else {
                let headers = DomHandler.find(
                    this.tableViewChild.nativeElement,
                    '.p-table-thead > tr:first-child > th'
                );
                headers.map(
                    (header, index) =>
                        (header.style.width = widths[index] + 'px')
                );
            }
        }
    }

    saveColumnOrder(state) {
        if (this.columns) {
            let columnOrder: string[] = [];
            this.columns.map(column => {
                columnOrder.push(column.field || column.key);
            });

            state.columnOrder = columnOrder;
        }
    }

    restoreColumnOrder() {
        const storage = this.getStorage();
        const stateString = storage.getItem(this.stateKey);
        if (stateString) {
            let state: TableState = JSON.parse(stateString);
            let columnOrder = state.columnOrder;
            if (columnOrder) {
                let reorderedColumns = [];
                columnOrder.map(key =>
                    reorderedColumns.push(this.findColumnByKey(key))
                );
                this.columnOrderStateRestored = true;
                this.columns = reorderedColumns;
            }
        }
    }

    findColumnByKey(key) {
        if (this.columns) {
            for (let col of this.columns) {
                if (col.key === key || col.field === key) return col;
                else continue;
            }
        } else {
            return null;
        }
    }

    setFooterText() {
        if (this.rows && this._totalCount) {
            if (this._totalCount > this.rows) {
                const start = this.first + 1;
                let end = start + this.rows - 1;

                if (end > this._totalCount) {
                    end = this._totalCount;
                }

                this.footerText = `${start} - ${end} of ${
                    this._totalCount
                    } items`;

                return;
            }

            if (this._totalCount === 1) {
                this.footerText = `${this._totalCount} item`;
            } else {
                this.footerText = `${this._totalCount} items`;
            }
        }
        else
            this.footerText = '';
    }

    ngOnDestroy() {
        this.unbindDocumentEditListener();
        this.editingCell = null;
        this.initialized = null;
    }
}
