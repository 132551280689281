import { DOCUMENT } from '@angular/common';
import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { eProviderType, eUserRole } from '@Workspace/_generated/enums';
import { Constants } from '@Workspace/common';

import { SettingsService } from '../settings/settings.service';
import { StorageService } from '../storage/storage.service';
import { AuthAdSevice } from './auth.ad.service';
import { AuthTokenService } from './auth.token.service';
import { IAuthService } from './iauth.service';

@Injectable()
export class AuthService implements IAuthService {
    private authService: IAuthService;

    constructor(
        private settingsService: SettingsService,
        private storageService: StorageService,
        private authTokenService: AuthTokenService,
        private authAdService: AuthAdSevice,
        @Inject(DOCUMENT) private _document: HTMLDocument
    ) {}

    get $userState() {
        return this.authService.$userState;
    }

    get userSettings() {
        if (this.authService.userSettings?.id) {
            return this.authService.userSettings;
        }
        else if (this.authService.userSettings['Errors'] && !this.authService.userSettings.id) {
            alert('WARNING: Login Failed.');
            window.location.href = 'https://hub.mogointeractive.com/#/sign-in';
        }
        else {
            alert('WARNING: Login Expired.');
            location.replace("/");
        }
    }

    get isLogged() {
        return this.authService.isLogged;
    }

    public async getIsAd() {
        return !!(await this.storageService.get(Constants.IS_AD_KEY));
    }

    private async setIsAd(isAdValue: boolean) {
        await this.storageService.set(Constants.IS_AD_KEY, isAdValue);
    }

    async reloadUserdataAsync() {
        await this.authService.reloadUserdataAsync();
        this.updateTheme();
    }

    async reloadUserSettingsAsync() {
        await this.authService.reloadUserSettingsAsync();
        this.updateTheme();
    }

    async initializeAsync() {
        const isAd = await this.getIsAd();

        if (
            (this.settingsService.IsLive || this.settingsService.isQA) &&
            (this.settingsService.IsLearfield || isAd)
        ) {
            this.authService = this.authAdService;
        } else {
            this.authService = this.authTokenService;
        }

        await this.authService.initializeAsync();
        this.updateTheme();
    }

    async login(username: string, password: string): Promise<void> {
        await this.setIsAd(false);
        await this.initializeAsync();
        await this.authService.login(username, password);
        this.updateTheme();
    }

    async loginSocial(socialProvider: eProviderType): Promise<void> {
        await this.setIsAd(true);
        await this.initializeAsync();

        await this.authService.loginSocial(socialProvider);
        this.updateTheme();
    }

    async logOut(): Promise<void> {
        await this.authService.logOut();
    }

    authorizeRequest(request: HttpRequest<any>, next: HttpHandler) {
        return this.authService.authorizeRequest(request, next);
    }

    private updateTheme() {
        let userSettings = this.authService.userSettings;
        if (!userSettings.permissions) {
            return;
        }
        if (userSettings.permissions.isLearfieldClient || userSettings.permissions.isOASClient || userSettings.permissions.isSocialClient
                    || userSettings.userRole == eUserRole.LIMGCSocialAdOps || userSettings.userRole == eUserRole.LIMGC_AdOps_Admin) {
            document.body.classList.remove('mogo-hub-theme');
            document.body.classList.add('fan-365-theme');
            this._document
                .getElementById('appFavicon')
                .setAttribute('href', '/assets/fan365.ico');
        } else {
            document.body.classList.add('mogo-hub-theme');
            document.body.classList.remove('fan-365-theme');
            this._document
                .getElementById('appFavicon')
                .setAttribute('href', '/assets/mogo.ico');
        }
    }
}
