<div class="mt-3">

    <div class="card card-body mx-3" *ngIf="currentUser.permissions.isHMPClient && messageVisable">
        <div class="row align-items-center">
            <div class="col-12">
                <ul class="list-unstyled text-danger text-style">
                    <li>MogoARTS’ offices will be closed on:
                        <ul>
                            <br/>
                            <li>Monday, December 20th - Friday, January 1st for the end of year holidays</li>
                        </ul>
                    </li>
                    <br/>
                    <li>During this time off, our team will be monitoring email daily to ensure campaigns are running smoothly as planned and to keep an eye out for any urgent needs. In preparation for the upcoming holiday closure, our standard 5-business day turnaround time for new orders will be extended:
                        <ul>
                            <br/>
                            <li>For any orders planned to launch between December 20, 2021 and January 7, 2022, please submit your order (creative, payment and CRM) to <a href="https://hub.mogointeractive.com/#/mogo">HUB</a><strong> no later than Wednesday, December 8, 2021</strong>.
                            </li>
                        </ul>
                    </li>
                    <br/>
                    <li>If you have any questions at all, please feel free to contact us. </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="card card-body mx-3 mt-3">
        <div class="row align-items-center" *ngIf="(!currentUser.permissions.isLearfieldClient && !currentUser.permissions.isOASClient && !currentUser.permissions.isSocialClient) 
        || (this.currentUser.userRole == enumUserRole.LIMGCSocialAdOps || this.currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin)">
            <div class="col-6">
                <div class="display-3">Campaigns</div>
            </div>

            <div class="col-6 d-flex flex-wrap justify-content-end align-items-center">
                <button (click)="navigateToGrid()" pButton class="p-button-primary mr-3" label="Grid"></button>
                <button (click)="navigateToGroupGrid()" pButton class="p-button-secondary" label="Group"></button>
            </div>
        </div>

        <div class="row align-items-center" *ngIf="(currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient)
        && this.currentUser.userRole != enumUserRole.LIMGCSocialAdOps && this.currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin">
            <div class="col-6">
                <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb>
            </div>

            <div class="col-6 d-flex flex-wrap justify-content-end align-items-center">
                <div class="col-3" *ngIf="!!selectedSponsor && !(currentUser.permissions.isClient && currentUser.userRole != enumUserRole.LIMGCSocialAdOps && currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin)">
                    <button (click)="navigateToGrid()" pButton class="p-button-primary mr-3" label="Grid"></button>
                    <button (click)="navigateToGroupGrid()" pButton class="p-button-secondary" label="Group"></button>
                </div>

                <div class="col-5 p-inputgroup" *ngIf="!isGrouping">
                    <input type="text" *ngIf="!selectedSponsor || selectedSponsor == ''" class="learfieldClientSearchInput" pInputText [(ngModel)]="searchParameterSponsor" placeholder="Search" (keyup.enter)="composeSearchParameter()" style="border-right: 0;"/>

                    <input type="text" *ngIf="selectedSponsor && selectedSponsor != ''" class="learfieldClientSearchInput" pInputText [(ngModel)]="searchParameter" [maxlength]=70 placeholder="Search" (keyup.enter)="applyFilters()" style="border-right: 0;"/>
                    <button pButton type="button" icon="pi pi-search" class="learfieldClientSearchButton p-inputgroup-addon" (click)="!this.campaignListComponent.selectedSponsor ? composeSearchParameter() : applyFilters()"></button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(!!grids && !!grids.length) || ((!grids || !grids.length) && !isGrouping)" class="card card-body mx-3" [ngClass]="currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient ? '' : 'mt-3'">

        <div class="learfieldClient" *ngIf="(currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient)
                                        && this.currentUser.userRole != enumUserRole.LIMGCSocialAdOps && this.currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin">
            <div class="row px-3 search d-flex flex-wrap">
                <div class=" d-flex flex-wrap justify-content-end align-items-center mb-1 " *ngIf="currentUser.permissions.isClient">
                    <div class="d-flex flex-wrap justify-content-between ">

                        <div class="mr-2"  *ngIf="!selectedSponsor && !isGrouping">
                            <div class="mr-2">
                                <p-dropdown [style]="{width: '100%'}" class="mr-0 pr-0" autoWidth="false" [(ngModel)]="clientNameFilter" (onChange)="onClientChange()" [options]="clientNameSelectOptions"></p-dropdown>
                            </div>
                        </div>

                        <div class="mr-2 multiSelectNoBorder" *ngIf="!isGrouping">
                            <p-multiSelect placeholder="Fiscal Year" class="mr-0 pr-0" [style]="{width: '100%'}" appendTo="body" autoWidth="false" [(ngModel)]="fiscalYearFilter" [options]="fiscalYearSelectOptions" (onPanelHide)="onChangeFiscalYear()" selectedItemsLabel="{0} items selected"></p-multiSelect>
                        </div>

                        <div class="mr-2" *ngIf="!!selectedSponsor">
                            <div class="mr-2">
                                <p-dropdown autoWidth="false" [(ngModel)]="gridId" class="col-4 p-0" (onChange)="onChangeGroupedGrid($event)" [options]="grids"></p-dropdown>
                            </div>
                        </div>

                        <div class="mr-2 multiSelectNoBorder" *ngIf="!!selectedSponsor && !isGrouping">
                            <p-multiSelect placeholder="Status" class="mr-0 pr-0 campaign-status-multiselect" appendTo="body" [style]="{width: '100%'}" autoWidth="false" [(ngModel)]="campaignStatusFilter" [options]="campaignStatusSelectOptions" (onPanelHide)="onChangeGroupedStatus()" (onChange)="onSelectionChange()" [selectedItemsLabel]="campaignStatusSelectOptions.length == campaignStatusFilter.length ? 'All Statuses' : '{0} items selected'"></p-multiSelect>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="(!currentUser.permissions.isLearfieldClient && !currentUser.permissions.isOASClient && !currentUser.permissions.isSocialClient) 
                            || (this.currentUser.userRole == enumUserRole.LIMGCSocialAdOps || this.currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin)">
            <div *ngIf="!!grids && !!grids.length" class="col-3" >
                <p-dropdown *ngIf="!(currentUser.permissions.isClient && currentUser.userRole != enumUserRole.LIMGCSocialAdOps && currentUser.userRole != enumUserRole.LIMGC_AdOps_Admin)" autoWidth="false" [(ngModel)]="gridId" class="col-4 p-0" (onChange)="onChangeGrid($event)" [options]="grids"></p-dropdown>
            </div>

            <div *ngIf="(!grids || !grids.length)" class="col-3 ">All Campaigns</div>

            <div *ngIf="!isGrouping" class="offset-3 offset-xxl-0"></div>

            <div *ngIf="!isGrouping" class="col-6 col-xxl-9">
                <div class="row pl-3 search d-flex flex-wrap">
                    <div *ngIf="!currentUser.permissions.isClient || currentUser.userRole == enumUserRole.LIMGCSocialAdOps || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin || currentUser.permissions.isHMPClient || currentUser.permissions.isHSClient" class=" col-12 justify-content-end mr-0">
                        <div class=" d-flex flex-wrap justify-content-end align-items-center mb-1 ">
                            <div class="d-flex  justify-content-between align-items-start no-wrap">
                                <div class="ml-xl-0 ml-md-5 ml-0 mr-1 filter-region" *ngIf="!currentUser.permissions.isClient || currentUser.userRole == enumUserRole.LIMGCSocialAdOps || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin">
                                    <p-dropdown placeholder="Region" class="mr-0 pr-0" [style]="{width: '100%'}" autoWidth="false" [(ngModel)]="regionFilter" [options]="regionSelectOptions" (onChange)="applyFilters();resetSelectedCampaigns();"></p-dropdown>
                                </div>
                                <div class="mr-xl-1 mr-md-5 mr-1">
                                    <p-dropdown [placeholder]="gridId==1 || gridId==4 || gridId==5 || gridId==6 || gridId==7 ?'Property':'Client'" [style]="{width: '100%'}" class="mr-0 pr-0" autoWidth="false" (onChange)="applyFilters();resetSelectedCampaigns();" [(ngModel)]="clientNameFilter" [options]="clientNameSelectOptions"></p-dropdown>
                                </div>
                                <div class="ml-xl-0 ml-md-5 ml-0 mt-xl-0 mt-md-2 mt-0 mr-1 multiSelectBorderColorFix" *ngIf="!currentUser.permissions.isClient || currentUser.userRole == enumUserRole.LIMGCSocialAdOps || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin">
                                    <p-multiSelect placeholder="Status" class="mr-0 pr-0 campaign-status-multiselect" appendTo="body" [style]="{width: '100%'}" autoWidth="false" [(ngModel)]="campaignStatusFilter" [options]="campaignStatusSelectOptions" (onPanelHide)="applyFilters();resetSelectedCampaigns();" (onChange)="onSelectionChange()" [selectedItemsLabel]="campaignStatusSelectOptions.length == campaignStatusFilter.length ? 'All Statuses' : '{0} items selected'"></p-multiSelect>
                                </div>
                                <div class="ml-xl-0 ml-md-5 ml-0 mt-xl-0 mt-md-2 mt-0 mr-xl-1mr-md-5 mr-1  multiSelectBorderColorFix filter-years"> 
                                   <p-multiSelect placeholder="Fiscal Year" class="mr-0 pr-0" [style]="{width: '100%'}" appendTo="body" autoWidth="false" [(ngModel)]="fiscalYearFilter" [options]="fiscalYearSelectOptions" (onPanelHide)="applyFilters();resetSelectedCampaigns();" selectedItemsLabel="{0} items selected"></p-multiSelect>
                                </div>
                                <!-- <div class="ml-xl-0 ml-md-5 ml-0 mt-xl-0 mt-md-2 mt-0 mr-1 d-flex">
                                    <button (click)="clearFilters()" pButton class="p-button-secondary text-uppercase filter-clear" label="Clear"></button>
                                </div> -->
                                <div class="ml-xl-0 ml-md-5 ml-0 mt-xl-0 mt-md-2 mt-0 mr-xl-0 mr-md-5 mr-0 d-flex p-inputgroup justify-content-end filter-search" *ngIf="!currentUser.permissions.isClient || currentUser.userRole == enumUserRole.LIMGCSocialAdOps || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin">
                                    <input type="text" pInputText [(ngModel)]="searchParameter" [maxlength]=70 placeholder="Search" (keyup.enter)="applyFilters()" style="border-right: 0;"/>
                                    <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="applyFilters()"></button>
                                </div>
                                <div class="ml-xl-1 mt-xl-0 mt-lg-1 mt-md-2 mt-0 d-flex">
                                    <button *ngIf="gridId == 1 && isBulkEditVisible && campaignListComponentViewChild && campaignListComponentViewChild.selectedCampaigns.length > 0" class="p-button-primary btn-bulk-edit" pButton type="button" label="BULK EDIT" (click)="openBulkEditDialog()"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <campaign-list-component #campaignListComponent *ngIf="!isGrouping" [showCheckboxes]="true" [fiscalYearFilter]="fiscalYearFilter" [clientNameFilter]="clientNameFilter" [currentAccount]="currentAccount" [selectedSponsorInput]="selectedSponsor" [gridId]="gridId" (selectedCampaignsEmmiter)="showBulkEdit($event)" (updateBreadCrumb)="updateBreadCrumb($event)" (applyFilters)="applyFilters()"></campaign-list-component>
    </div>
    <div *ngIf="isGrouping">
        <campaign-list-component #campaignListByStatuses [status]='status' [selectedSponsorInput]="selectedSponsor" [currentAccount]="currentAccount" *ngFor="let status of campaignStatuses" (updateBreadCrumb)="updateBreadCrumb($event)" (applyFilters)="applyFilters()" [gridId]="gridId">
        </campaign-list-component>
    </div>

    <div class="card card-body mx-3 mt-3" *ngIf="currentUser.userRole == enumUserRole.Associate">
        <div class="row">
            <div class="col-6">
                CRM
            </div>
            <div class="col-6 d-flex justify-content-end">
                <div class="p-inputgroup w-25">
                    <input type="text" pInputText [(ngModel)]="searchParameterCRM" placeholder="Search" (keyup.enter)="composeFilterCRM()" style="border-right: 0;"/>
                    <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="composeFilterCRM()"></button>
                </div>
            </div>
        </div>
        <crm-list></crm-list>
    </div>

</div>

<campaign-bulk-edit-settings-dialog #bulkEditDialog></campaign-bulk-edit-settings-dialog>