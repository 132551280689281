<p-dialog header="Account" class="actions-dialog" [modal]="true" [closable]="false" [(visible)]="displayDialog">
    <div class="mx-3">
        <div *ngIf="!!formGroup" [formGroup]="formGroup">
            <div class="row mb-3">
                <div class="col-12">
                    <div class="display-4">
                        Account Information
                    </div>
                    <div class="sub-heading">
                        General information about the account
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6" [ngClass]="account.isLearfieldCampaign? 'col-12': 'col-6'">
                    <span *ngIf="!currentUser.permissions.isLearfieldClient">
                        <div class="mb-3 row">
                            <label class="col-5 required">
                                Company Name
                            </label>
                            <input pInputText formControlName="name" [readOnly]="account.isLearfieldCampaign" class="col-7" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-5 required">
                                Type
                            </label>
                            <p-dropdown autoWidth="false" [disabled]="account.isLearfieldCampaign" [style]="{'width':'100%'}" formControlName="type" class="col-7 p-0" [options]="accountTypes"></p-dropdown>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-5">
                                Industry
                            </label>
                            <p-dropdown autoWidth="false" [disabled]="account.isLearfieldCampaign" [style]="{'width':'100%'}" formControlName="industry" class="col-7 p-0" [options]="industries"></p-dropdown>
                        </div>
                    </span>
                    
                    <div *ngIf="account.isLearfieldCampaign" class="mb-3 row">
                        <label class="col-5">
                            Region
                        </label>
                        <p-dropdown autoWidth="false" [disabled]='currentUser.permissions.isLearfieldClient' [style]="{'width':'100%'}" formControlName="region" class="col-7 p-0" [options]="regions"></p-dropdown>
                    </div>
                    <div *ngIf="!currentUser.permissions.isLearfieldClient" class="mb-3 row">
                        <label class="col-5">
                            Notification Team
                        </label>
                        <p-dropdown autoWidth="false" [disabled]='currentUser.permissions.isLearfieldClient' [style]="{'width':'100%'}" formControlName="notificationTeam" class="col-7 p-0" [options]="notificationGroups"></p-dropdown>
                    </div>
                    <div *ngIf="!currentUser.permissions.isLearfieldClient" class="mb-3 row">
                        <label class="col-5">
                            Automatic API build
                        </label>
                        <div class="col-7">
                            <p-inputSwitch [disabled]='currentUser.permissions.isLearfieldClient' formControlName="apiBuild"></p-inputSwitch>
                        </div>
                    </div>
                    <p-fieldset *ngIf="account.isLearfieldCampaign" class="mb-3 fieldset-roles" legend="Property Roles" [toggleable]="false">
                        <div class="my-3 fieldset-div">
                            <label class="col-5">
                                Coordinators
                            </label>
                            <p-multiSelect [placeholder]="'Choose'" [options]="accountUsers" appendTo="body" [style]="{'width':'100%'}" formControlName="coordinator" class="col-7 p-0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                            <!-- <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="coordinator" class="col-7 p-0" [options]="accountUsers"></p-dropdown> -->
                        </div>
                        <div class="mb-3 fieldset-div">
                            <label class="col-5">
                                Sellers
                            </label>
                            <p-multiSelect [placeholder]="'Choose'" autoWidth="false" appendTo="body" [options]="accountUsers" [style]="{'width':'100%'}" formControlName="seller" class="col-7 p-0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                            <!-- <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="seller" class="col-7 p-0" [options]="accountUsers"></p-dropdown> -->
                        </div>
                    </p-fieldset>
                    <p-fieldset *ngIf="account.isLearfieldCampaign && (currentUser.isCoordinator || currentUser.isSeller)" legend="Your settings" class="fieldset-sponsor " [toggleable]="false">
                        <div class="my-3 fieldset-div">
                            <label class="col-5">
                                Sponsor Assignments
                            </label>
                            <p-multiSelect [placeholder]="'Choose'" autoWidth="false" appendTo="body" [options]="sponsors" [style]="{'width':'100%'}" formControlName="sponsor" class="col-7 p-0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                            <!-- <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="seller" class="col-7 p-0" [options]="accountUsers"></p-dropdown> -->
                        </div>
                    </p-fieldset>



                </div>
                <div *ngIf="!account.isLearfieldCampaign" class="col-6">

                    <div class="mb-3 row">
                        <label class="col-5">
                            GCM Advertiser ID
                        </label>
                        <input pInputText type="number" formControlName="GCMAdvertiserId" class="col-7" />
                    </div>
                    <div class="mb-3 row">
                        <label class="col-5">
                            GCM Advertiser Name
                        </label>
                        <input pInputText formControlName="GCMAdvertiserName" class="col-7" />
                    </div>
                    <div class="mb-3 row">
                        <label class="col-5">
                            TTD Advertiser ID
                        </label>
                        <input pInputText formControlName="TTDAdvertiserId" class="col-7" />
                    </div>
                    <div class="mb-3 row">
                        <label class="col-5">
                            DV360 Advertiser ID
                        </label>
                        <input pInputText formControlName="DV360AdvertiserId" class="col-7" />
                    </div>
                    <div class="mb-3 row">
                        <label class="col-5">
                            Facebook Ad Account ID
                        </label>
                        <input pInputText formControlName="facebookAdAccountId" class="col-7" />
                    </div>
                    <div class="mb-3 row">
                        <label class="col-5">
                            Facebook Page ID
                        </label>
                        <input pInputText type="text"  [maxlength]=50 formControlName="facebookPageId" class="col-7" />
                    </div>
                    <div class="mb-3 row">
                        <label class="col-5">
                            Instagram ID
                        </label>
                        <input pInputText type="text"  [maxlength]=50 formControlName="instagramId" class="col-7" />
                    </div>

                </div>
                <div *ngIf="account.isCampaignCreation || currentUser.permissions.isAdmin" class="col-12">

                    <component-loader-spinner [isLoading]="loading"></component-loader-spinner>
                
                        <div class="mb-2 row align-items-center" >
                            <div class="col-md-2 font-weight">
                                DMA
                            </div>
                            <div class="col-md-10" style="padding-right: inherit;">
                                <p-dropdown [placeholder]="'Choose'" autoWidth="false"  [style]="{'width':'100%'}" formControlName="dma" class="col-7 p-0" [options]="dmas"></p-dropdown>
                            </div>
                        </div>
                        
                        <div class="mb-2 row align-items-center">
                            <div class="col-md-2 font-weight">
                                City
                            </div>
                            <div class="col-md-10" style="padding-right: inherit;">
                                <p-multiSelect [placeholder]="'Choose'" autoWidth="false" appendTo="body" [options]="cities" [style]="{'width':'100%'}" formControlName="cities" class="col-7 p-0" [virtualScroll]="true" [itemSize]="30" selectedItemsLabel="{0} items selected"></p-multiSelect>

                            </div>
                        </div>
                        <div class="mb-2 row align-items-center" >
                            <div class="col-md-2 font-weight">
                                State
                            </div>
                            <div class="col-md-10" style="padding-right: inherit;">
                                <p-dropdown [placeholder]="'Choose'" autoWidth="false"  [style]="{'width':'100%'}" formControlName="state" class="col-7 p-0" [options]="states"></p-dropdown>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    <div *hasPermission="[constants.CanManageAccountUsers]" class="col-12 p-0 my-2">
        <account-reporting *ngIf="!account.isLearfieldCampaign" [account]="account"></account-reporting>
        <!-- <user-picklist *ngIf="!account.isLearfieldCampaign" [isFromAccount]="true" resource="Account" [targetUsers]="accountUsers"></user-picklist> -->
    </div>
    <p-footer>
        <button type="submit" class="p-button-primary" pButton label="Save" [disabled]="" icon="fa fa-save" (click)="submit()"></button>
        <button type="cancel" class="p-button-secondary" pButton label="Cancel" icon="fa fa-times" (click)="cancel()"></button>
    </p-footer>
</p-dialog>