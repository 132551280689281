import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';

import { AuthService } from '@Workspace/auth';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
    > { 
        if (req.withCredentials) {
            return this.authService.authorizeRequest(req, next);
        }
        
        return next.handle(req);
    }
}
