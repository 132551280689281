<div *ngIf="!isReviewStepVisible" class="row p-3">
    <div class="col-12 col-lg-6 d-lg-flex d-block flex-wrap pr-lg-0">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 pb-3">
                    <h3 class="display-4 mb-0">Campaign Information</h3>
                    <p class="sub-heading">
                        Enter your new campaign name, goal, dates, and other here
                    </p>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-5">
                    <label class="required">
                        Company
                    </label>
                </div>

                <div class="col-7">
                    <p-dropdown (onChange)="onCompanySelected($event)" [(ngModel)]="campaign.accountId" placeholder="-" [options]="companyOptions" autoWidth="false" [style]="{'width': '100%'}"></p-dropdown>
                </div>
            </div>

            <div class="row">
                <div class="col-5">
                    <label class="required">
                        Contact Name
                    </label>
                </div>

                <div class="col-7">
                    <p-dropdown [options]="usersOptions" (ngModelChange)="onContactChange($event)" [(ngModel)]="campaign.contactId" autoWidth="false" [style]="{'width': '100%'}"></p-dropdown>
                </div>

                <!-- <label *ngIf="!showPackage">{{campaignGoalLabel}}</label> -->
            </div>
        </div>
    </div>

    <div class="col-12 col-lg-6 mt-3 mt-lg-0">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 pb-3">
                    <h3 class="display-4 mb-0">Company Information</h3>
                    <p class="sub-heading">
                        Select a company and contact to start
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-5">
                    <label class="required">
                        Campaign Name
                    </label>
                </div>

                <div class="col-7">
                    <input [(ngModel)]="campaign.name" pInputText class="w-100" />
                </div>
            </div>

            <div class="row">
                <div class="col-5">
                    <label class="required">
                        Campaign Dates
                    </label>
                </div>

                <div class="col-7 d-flex justify-content-around align-items-center">
                    <p-calendar appendTo="body" (onSelect)="onStartDateChange($event)" [minDate]="minStartDate" [maxDate]="maxStartDate" [(ngModel)]="startDate"></p-calendar> <span class="mx-2">-</span>
                    <p-calendar appendTo="body" (onSelect)="onEndDateChange($event)" [disabled]="startDate == null" [minDate]="minEndDate" [(ngModel)]="endDate"></p-calendar>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-5">
                    <label class="required">
                        Set Due Dates
                    </label>
                </div>

                <div class="col-7">
                    <p-calendar appendTo="body" [minDate]="minDueDates" (onSelect)="onDueDateChange($event)" [(ngModel)]="dueDate" [style]="{'width': '100%'}" class="w-100"></p-calendar>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="col-sm-12 pl-3">
    <su-campaign-detail-channels [creativeDtoFromPreviousScreen]="creativeDto" [isBackButtonActivated]="IsPreviousPage" [startDate]="startDate" [endDate]="endDate" [campaign]="campaign" (onStepTwo)="onInput($event)" *ngIf="isChannelOptionVisible && !isReviewStepVisible && !isCreativePageVisible"></su-campaign-detail-channels>
    <su-campaign-detail-creatives [selectedBaseCampaignDto]="campaign" (onChannelSelectionStep)="onPreviousStep($event)" (onReviewStep)="onReview($event)" [selectedDimensions]="selectedDimensions" [selectedCreatives]="creativeDto" [startDate]="startDate" [endDate]="endDate" *ngIf="isCreativePageVisible && !isReviewStepVisible"></su-campaign-detail-creatives>
    <su-campaign-review (onCreativeSelectionStep)="onCreativeStep($event)" [dueDate]="dueDate" [startDate]="startDate" [endDate]="endDate" [campaign]="campaign" [channelGroup]="channelTypes" *ngIf="isReviewStepVisible"> </su-campaign-review>
</div>