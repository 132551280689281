import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/api';
import {  ISelectItem } from '@Workspace/_generated/interfaces';
import { Subject, BehaviorSubject } from 'rxjs';
import { eCampaignStatus } from '@Workspace/_generated/enums';

@Component({
    selector: 'payment-status-dialog',
    templateUrl: './payment-status-dialog.html',
    styleUrls: ['./payment-status-dialog.scss']
})
export class PaymentStatusDialog implements OnInit {

    isVisible: boolean = false;
    canSave: boolean = true;
    defaultValues: any;
    header: string;
    paymentStatus: boolean;

    constructor() { }

    ngOnInit() {

    }

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    private ngUnsubscribe = new Subject();

    openDialog(value: any, header: string) {
        this.defaultValues = { ...arguments };
        this.header = header;
        this.paymentStatus = value == eCampaignStatus.Payment_Completed;
        this.isVisible = true;

    }

    resetDefault() {
        this.paymentStatus = this.defaultValues[0];
    }

    save() {
        const dialogResult = {
            dialogType: 'payment status',
            paymentStatus: this.paymentStatus,
        };
        this.onSave.emit(dialogResult);

        this.isVisible = false;
    }

    cancel() {
        this.isVisible = false;
    }
}