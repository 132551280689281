import { OnInit, Component, Input, EventEmitter, Output, OnChanges, ViewChild, ElementRef, AfterViewInit, DoCheck, ChangeDetectorRef, HostListener } from '@angular/core';
import { CartItemService } from './card-item.service';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Constants } from '@Workspace/common';
import { eAccountType } from '@Workspace/_generated/enums';

@Component({
    selector: 'card-item',
    templateUrl: './card-item.component.html',
    styleUrls: ['./card-item.component.scss']
})
export class CardItemComponent implements OnInit, AfterViewInit {
    isContentArray: boolean;
    @Input('header') set header(value: string) { this._header = value; }
    @Input('content') set content(value: [any]) { this._content = value; }
    @Input('editButton') editButton: boolean = false;
    @Input() isNoteValid: boolean = true;
    @Input() baseCampaign: IBaseCampaignDto = {} as IBaseCampaignDto;
    @Input() isShareOfVoice: boolean = false;

    @Output() onEdit = new EventEmitter();

    @Input('cardType') cardType: "generic" | "social" | "landingPage" | "inventoryAddOn" | "contact" | "advertiser" | "keyValuePair" | "additionalSettings" | "paymentStatus" | "unlockFeatures" | "geographics" | "demographics" = "generic";

    @ViewChild('contactDiv', { static: false }) contactDiv!: ElementRef;
    @ViewChild('contactSpan', { static: false }) contactSpan!: ElementRef;

    additionalSettingChanged: boolean = false;
    demographicsChanged: boolean = false;
    geographicsChanged: boolean = false;
    paymentStatusChanged: boolean = false;
    @Input() contactInfo: string = null;
    constants = Constants;
    enumAccountType = eAccountType;
    showIcon: boolean = null;
    isIconVisible: boolean = false;

    constructor(private cardItemService: CartItemService, private cdr: ChangeDetectorRef) { }


    ngOnInit(): void {

        if (this.cardType == 'geographics') {
            var geoArray = this._content as string[];
            this.geographicsChanged = geoArray.some(x => x != "");
        }
        if (this.cardType == 'demographics') {
            var demoArray = this._content as string[];
            this.demographicsChanged = demoArray.some(x => x != "");
        }
        this.isContentArray = Array.isArray(this._content);
    }

    ngAfterViewInit() {
        this.checkContactWidths();
      }
    
    @HostListener('window:resize', ['$event'])
        onResize(event: any) {
        this.checkContactWidths();
    }

    public hasDate = false;
    public _header: string;
    public _content: string | [string];
    public _flipped: boolean;

    editClicked(value: string) {
        this.onEdit.emit(value);
        if (value == 'Additional Settings') {
            this.additionalSettingChanged = true;

        } else if (value == 'Demographics') {
            this.demographicsChanged = true;
        }
        else if (value == 'Georaphical targeting') {
            this.geographicsChanged = true;
        }
        else if (value == 'Payment status') {
            this.paymentStatusChanged = true;
        }
    }

    checkContactWidths() {
        if(!!this.contactDiv || !!this.contactSpan){
            const divWidth = this.contactDiv.nativeElement.offsetWidth;
            const spanWidth = this.contactSpan.nativeElement.offsetWidth;
        
            if (spanWidth >= divWidth) {
                if(!this.isIconVisible){
                    this.isIconVisible = true;
                    this.cdr.detectChanges();
                }
              } else {
                if(this.isIconVisible){
                    this.isIconVisible = false;
                    this.cdr.detectChanges();
                }
              } 
        }
        else{
            this.isIconVisible = false;
        }
    }
}