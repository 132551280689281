import { SelectItem } from "primeng/api";
import * as e from "../_generated/enums";
import { Functions } from "./Functions";

export class LookUps {

    public static getIndustryLu(): SelectItem[] {
        return this.enumValues(e.eIndustry, e.eIndustry.NotSet);
    }
    public static getAccountTypesLu(): SelectItem[] {
        return this.enumValues(e.eAccountType, e.eAccountType.NotSet, e.eAccountType.FAN365);
    }
    public static getAllAccountTypesLu(): SelectItem[] {
        return this.enumValues(e.eAccountType, e.eAccountType.NotSet);
    }
    public static getRegionsLu(): SelectItem[] {
        return this.enumValues(e.eRegion, e.eRegion.Not_set);
    }
    public static getCallToActionEnum(): SelectItem[] {
        return this.enumValues(e.eCallToAction, e.eCallToAction.Not_Set);
    }
    public static getCTALinkedinImageEnum(): SelectItem[] {
        return this.enumValues(e.eCTALinkedinImage, e.eCTALinkedinImage.Not_Set);
    }
    public static getCTALinkedinVideoEnum(): SelectItem[] {
        return this.enumValues(e.eCTALinkedinVideo, e.eCTALinkedinVideo.Not_Set);
    }
    public static getCTATikTokEnum(): SelectItem[] {
        return this.enumValues(e.eCTATikTok, e.eCTATikTok.Not_Set);
    }
    public static getStoriesEnum(): SelectItem[] {
        return this.enumValues(e.eStoriesType);
    }
    public static getCreativeThemeEnum(): SelectItem[] {
        return this.enumValues(e.eCreativeTheme, e.eCreativeTheme.Not_Set).sort((a, b) => a.value - b.value);
    }
    public static getGameMechanicEnum(): SelectItem[] {
        return this.enumValues(e.eGameMechanic, e.eGameMechanic.Not_Set).sort((a, b) => a.value - b.value);
    }
    public static getSweepstakesCategoryEnum(): SelectItem[] {
        return this.enumValues(e.eSweepstakesCategory, e.eSweepstakesCategory.NotSet).sort((a, b) => a.value - b.value);
    }
    public static getInventoryTypeSocial(): SelectItem[] {
        return this.enumValues(e.eStandardCampaginChannel, e.eStandardCampaginChannel.NotSet,
                                       e.eStandardCampaginChannel.Facebook_Static_News_Feed, 
                                       e.eStandardCampaginChannel.Facebook_Dynamic_News_Feed, 
                                       e.eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video, 
                                       e.eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel, 
                                       e.eStandardCampaginChannel.Standard_Display_Banner, 
                                       e.eStandardCampaginChannel.Premium_Display_Banner, 
                                       e.eStandardCampaginChannel.Abandoned_Cart_Display_Banner, 
                                       e.eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner, 
                                       e.eStandardCampaginChannel.Spotify_Video, 
                                       e.eStandardCampaginChannel.Spotify_Audio, 
                                       e.eStandardCampaginChannel.In_Stream_Video, 
                                       e.eStandardCampaginChannel.Google_AdWords, 
                                       e.eStandardCampaginChannel.Native_Image, 
                                       e.eStandardCampaginChannel.Native_Video, 
                                       e.eStandardCampaginChannel.Facebook_Static_News_Feed_Video, 
                                       e.eStandardCampaginChannel.Lead_Generation, 
                                       e.eStandardCampaginChannel.Instant_Experience, 
                                       e.eStandardCampaginChannel.Stories, 
                                       e.eStandardCampaginChannel.ConnectedTv, 
                                       e.eStandardCampaginChannel.OAS_Display, 
                                       e.eStandardCampaginChannel.OAS_Video,
                                       e.eStandardCampaginChannel.Flexible_Campaign,
                                       ).sort((a, b) => a.value - b.value);
    }
    public static getContentTypeEnum(): SelectItem[] {
        return this.enumValues(e.eContentType, e.eContentType.Not_Set).sort((a, b) => a.value - b.value);
    }
    public static getCampaignStatusesEnum(): SelectItem[] {
        return this.enumValues(e.eCampaignStatus, e.eCampaignStatus.Notset);
    }
    public static getTeamLu(): SelectItem[] {
        return this.enumValues(e.eTeam, e.eTeam.NotSet);
    }
    public static getCampaignGoalLu(): SelectItem[] {
        return this.enumValues(e.eCampaignGoal, e.eCampaignGoal.Notset);
    }
    public static getCampaignStatusesLu(): SelectItem[] {
        return this.enumValues(e.eCampaignStatus, e.eCampaignStatus.Notset);
    }
    public static getUserClientRolesLu(): SelectItem[] {
        return this.enumValues(e.eUserAccountRole, e.eUserAccountRole.NotSet, e.eUserAccountRole.Associate, e.eUserAccountRole.Clien_Solution, e.eUserAccountRole.Media_Activation);
    }
    public static getUserEmployeeRolesLu(): SelectItem[] {
        return this.enumValues(e.eUserRole, e.eUserRole.Client, e.eUserRole.NotSet);
    }
    public static getChannelTypesLu(): SelectItem[] {

        return this.enumValues(e.eStandardCampaginChannel, e.eUserRole.NotSet).sort((a, b) => a.value - b.value);
    }
    public static getIncomeFrom(): SelectItem[] {
        return this.enumValues(e.eIncomeFrom, e.eIncomeFrom.None);
    }
    public static getIncomeTo(): SelectItem[] {
        return this.enumValues(e.eIncomeTo, e.eIncomeTo.None);
    }
    public static getAgeFromList(): SelectItem[] {
        return this.enumValues(e.eAgeFrom, e.eAgeFrom.None);
    }

    public static getAgeToList(): SelectItem[] {
        return this.enumValues(e.eAgeTo, e.eAgeTo.None);
    }
    public static getCrmTypeList(): SelectItem[] {
        return this.enumValues(e.eCRMType, e.eCRMType.NotSet);
    }
    public static getGenderList(): SelectItem[] {
        return this.enumValues(e.eGender, e.eGender.None);
    }
    public static getParentalStatusList(): SelectItem[] {
        return this.enumValues(e.eParentalStatus, e.eParentalStatus.None);
    }
    public static getVideoTypeLu(): SelectItem[] {
        return this.enumValues(e.eVideoType, e.eVideoType.Not_set);
    }
    public static getPerformanceGoalTypeDV360(): SelectItem[]{
        return this.enumValues(e.ePerformanceGoalTypeDV360, e.ePerformanceGoalTypeDV360.NotSet);
    }
    public static getPerformanceGoalTypeTTD(): SelectItem[]{
        return this.enumValues(e.ePerformanceGoalTypeTTD, e.ePerformanceGoalTypeTTD.NotSet);
    }

    private static enumValues(value, ...excludedValues): SelectItem[] {
        return Functions.iterateEnum(value, null, true, excludedValues).sort((v1, v2) => {
            // tslint:disable-next-line:max-line-length
            return v1.label.toLowerCase().localeCompare(v2.label.toLowerCase()) >
                v2.label.toLowerCase().localeCompare(v1.label.toLowerCase())
                ? 1
                : v1.label.toLowerCase().localeCompare(v2.label.toLowerCase()) <
                    v2.label.toLowerCase().localeCompare(v1.label.toLowerCase())
                    ? -1
                    : 0;
        });
    }
}
