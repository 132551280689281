<div class="align-self-center">
    <div class="row card card-body">
        <div class="col-lg-6 col-12 pl-lg-5 pl-0 pb-lg-0 pb-3">
            <div class="mt-lg-5 mt-3">
                <div class="mt-5">
                    <h1 class="orange">Sky's the limit</h1>
                    <h5 class="pb-4">Get a dedicated representative.</h5>
                    <p>Reaching the sky alone is tought.</p>
                    <p>
                        That's why we want to provide Thrive campaigns with a
                        dedicated
                    </p>
                    <p>
                        representative who will guide and help achieve
                        greatness.
                    </p>
                </div>
                <div class="pt-5">
                    <div class="row pb-3">
                        <div class="col-5">
                            <label class="required">
                                Expected start date
                            </label>
                        </div>

                        <div class="col-7">
                            <p-calendar appendTo="body" (ngModelChange)="onStartDateChanged($event)" [(ngModel)]="startDate"> </p-calendar>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="required"> Budget </label>
                        </div>

                        <div class="col-7">
                            <p-inputNumber [(ngModel)]="budget" inputId="currency-us" mode="currency" currency="USD" locale="en-US">
                            </p-inputNumber>
                        </div>

                        <!-- <label *ngIf="!showPackage">{{campaignGoalLabel}}</label> -->
                    </div>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="row">
                <div class="col-12 px-5 mb-3">
                    <div class="d-flex flex-wrap justify-content-end px-3">
                        <button pButton label="Cancel" class="flat-btn text-uppercase mr-3" (click)="handleCloseDialog()"></button>
                        <span>
                            <button pButton label="Finish" class="p-button-primary text-uppercase mr-3 py-1" (click)="sendData()"></button>
                        </span>
                    </div>
                </div>
            </div>
        </p-footer>
    </div>
</div>
<p-dialog [showHeader]="true" header="Your request has been submitted" [modal]="true" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">

    <p class="pt-2">Thank you for your request.</p>
    <p class="pt-3">Someone from our team will contact you shortly.</p>
    <p-footer>


        <button type="button" (click)="onClose()" pButton class="p-button-primary" label="Sure, thank you"></button>

        <!-- <p-button icon="pi pi-check" (click)="displayBasic=false" label="Sure, thank you" styleClass="p-button-text"></p-button> -->
    </p-footer>
</p-dialog>