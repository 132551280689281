<div class="col-md-9 mx-auto p-3 bg-white rounded shadow-sm pt-4 border-bottom pb-4" [style]="{'display': 'flex'}">
    <div>
        <div class="py-2 float-left">
            <h3 class="first">Campaign External IDs</h3>
        </div> 
    
        <div class=" pt-4 d-flex flex-column col-md-12">
            <p class="external-ids-override-p">Override</p>
            <div class="col-md-12 py-2 d-flex flex-row" *ngIf="isLearfieldCampaign">
                <label class="my-auto col-md-4">DV360</label>
                <p-inputNumber [disabled]="true" [ngModel]="DV360_Campaign_ID" [useGrouping]="false" maxlength="10" emptyValue="empty"></p-inputNumber>
                <p-inputSwitch (onChange)="changeStatus($event, 'dv360')" class="external-ids-input-switch" [(ngModel)]="overrideDV360"></p-inputSwitch>
                <p-inputNumber [disabled]="!overrideDV360" [max]="2147483647" [useGrouping]="false" [(ngModel)]="DV360_Campaign_ID_External" emptyValue="empty"></p-inputNumber>
            </div>
    
            <div class="col-md-12 py-2 d-flex flex-row">
                <label class="my-auto col-md-4">GCM</label>
                <p-inputNumber [disabled]="true" [ngModel]="GCM_Campaign_ID" [useGrouping]="false" maxlength="10" emptyValue="empty"></p-inputNumber>
                <p-inputSwitch (onChange)="changeStatus($event, 'gcm')" class="external-ids-input-switch" [(ngModel)]="overrideGCM"></p-inputSwitch>
                <p-inputNumber [disabled]="!overrideGCM" [max]="2147483647" [useGrouping]="false" [(ngModel)]="GCM_Campaign_ID_External" emptyValue="empty"></p-inputNumber>
            </div>
            
            <div class="col-md-12 py-2 d-flex flex-row" *ngIf="isLearfieldCampaign">
                <label class="my-auto col-md-4">TTD</label>
                <input [disabled]="true" [value]="TTD_Campaign_ID" type="text" placeholder="" pInputText>
                <p-inputSwitch (onChange)="changeStatus($event, 'ttd')" class="external-ids-input-switch" [(ngModel)]="overrideTTD"></p-inputSwitch>
                <input type="text" [disabled]="!overrideTTD" [(ngModel)]="TTD_Campaign_ID_External" placeholder="" pInputText>
            </div>

            <div class="col-md-12 py-2 d-flex flex-row" *ngIf="isOASCampaign">
                <label class="my-auto col-md-4">GAM LineItem ID</label>
                <input [disabled]="true" type="text" placeholder="" pInputText>
                <p-inputSwitch (onChange)="changeStatus($event, 'gam_line_item')" class="external-ids-input-switch" [(ngModel)]="overrideGAMLineItem"></p-inputSwitch>
                <input type="text" [disabled]="!overrideGAMLineItem" [(ngModel)]="GAM_Line_Item_ID_External" placeholder="" pInputText>
            </div>
    
            <div class="col-md-12 py-2 d-flex flex-row" *ngIf="isOASCampaign">
                <label class="my-auto col-md-4">GAM Order ID</label>
                <p-inputNumber [disabled]="true" [ngModel]="GAM_Campaign_ID" [useGrouping]="false" maxlength="10" emptyValue="empty"></p-inputNumber>
                <p-inputSwitch (onChange)="changeStatus($event, 'gam_order')" class="external-ids-input-switch" [(ngModel)]="overrideGAMOrder"></p-inputSwitch>
                <p-inputNumber [disabled]="!overrideGAMOrder" [max]="2147483647" [useGrouping]="false" [(ngModel)]="GAM_Order_ID_External" emptyValue="empty"></p-inputNumber>
            </div>
            
            
        </div>
    </div>
    
    <div style="width: -webkit-fill-available;">
        <button (click)="save()" pButton class="px-3 p-button-primary" style="position: absolute; bottom: 1rem; right: 3rem;" label="Save"></button>
    </div>

</div>