import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ISelectItem, IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Subject } from 'rxjs';
import { CampaignController } from '@Workspace/_generated/services';
import { DecimalPipe } from '@angular/common';
import { eAccountType, eCampaignStatus } from '@Workspace/_generated/enums';


@Component({
    selector: 'inventory-add-on-accordion',
    templateUrl: './inventory-add-on-accordion.component.html',
    styleUrls: ['./inventory-add-on-accordion.component.scss'],
    providers: [DecimalPipe]
})
export class InventoryAddOnAccordion implements OnInit, OnDestroy {

    pssLines: any[];
    addOns: SelectItem[];
    selectedAddOns: SelectItem[] = [];
    totalImpresions: string;
    addOnImpressions: string;
    canEditImpressions: boolean;
    isContent: boolean;

    private ngUnsubscribe = new Subject();

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() disabled: boolean;

    constructor(
        public campaignController: CampaignController,
        private decimalPipe: DecimalPipe
    ) { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.canEditImpressions = this.baseCampaign.campaignType != eAccountType.FAN365 ? this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending
            : this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Uploaded
             || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined || this.baseCampaign.campaignStatusId == eCampaignStatus.Creative_Swaps;
        this.totalImpresions = this.decimalPipe.transform(this.baseCampaign.impressions);
        this.addOnImpressions = !!this.baseCampaign.adOnImpressions && this.baseCampaign.adOnImpressions > 0 ? this.decimalPipe.transform(this.baseCampaign.adOnImpressions) : "N/A";
        // this.addOnImpressions = this.decimalPipe.transform(this.baseCampaign.adOnImpressions);
        this.isContent = this.baseCampaign.campaignType == eAccountType.Content || this.baseCampaign.campaignInventoryItems[0]?.inventoryItemContent != null;

        this.selectedAddOns = !!this.baseCampaign.pssAdOnsJson ? this.baseCampaign.pssAdOnsJson.map(x => {
            return x.id;
        }) : [];

        this.campaignController.GetPSSByDealNumberQuery(this.baseCampaign.id, this.baseCampaign.dealId).subscribe(x => {
            this.pssLines = x;
            this.addOns = this.pssLines.map(x => {
                let adOn = {} as SelectItem;
                adOn.value = x.kore_ContractLineId;
                let totalImpresions = x.inventoryDuration * x.quantity * x.events;
                adOn.label = x.inventoryItem;
                adOn.title = `
                <span>${x.inventoryItem}</span>
                <span class="${this.selectedAddOns.includes(adOn.value) ? "impression-value-selected" : "impression-value-not-selected"}"> (Add-on Total Impressions - ${this.decimalPipe.transform(totalImpresions)})</span>
                `;

                return adOn;
            });
        });
    }


    onSelectAdOn(event: any) {
        let addOn = this.addOns.find(x => x.value == event.itemValue);
        if(event.value.includes(event.itemValue))
            addOn.title = addOn.title.replace("impression-value-not-selected","impression-value-selected")
        else
            addOn.title = addOn.title.replace("impression-value-selected","impression-value-not-selected")

        this.baseCampaign.impressions -= this.baseCampaign.adOnImpressions;
        this.baseCampaign.totalHardCost = this.baseCampaign.hardCost;
        this.baseCampaign.totalInventoryItemPrice = this.baseCampaign.inventoryItemPrice;
        this.baseCampaign.adOnImpressions = 0;

        this.selectedAddOns.forEach(x => {
            let line = this.pssLines.find(l => l.kore_ContractLineId == x);
            this.baseCampaign.adOnImpressions += !this.isContent ? (line.inventoryDuration * line.quantity * line.events) : 0;
            this.baseCampaign.totalHardCost = (this.baseCampaign.hardCost ? this.baseCampaign.hardCost : 0) +  line.hardCost;
            this.baseCampaign.totalInventoryItemPrice = (this.baseCampaign.inventoryItemPrice? this.baseCampaign.inventoryItemPrice : 0) + line.inventoryItemPrice;
        });

        this.addOnImpressions = !!this.baseCampaign.adOnImpressions && this.baseCampaign.adOnImpressions > 0 ? this.decimalPipe.transform(this.baseCampaign.adOnImpressions) : "N/A";
        // this.addOnImpressions = this.decimalPipe.transform(this.baseCampaign.adOnImpressions);
        this.baseCampaign.impressions += this.baseCampaign.adOnImpressions;
        this.totalImpresions = this.decimalPipe.transform(this.baseCampaign.impressions);
        this.save();
    }

    save() {
        const dialogResult = {
            dialogType: 'inventoryAddOn',
            selectedAddOns: this.addOns.filter(x => this.selectedAddOns.some(si => si == x.value)).map(x => {
                let line = this.pssLines.find(l => l.kore_ContractLineId == x.value);
                let adOn = {} as ISelectItem;
                adOn.id = x.value;
                adOn.name = line != null ? line.inventoryItem : x.label;
                return adOn;
            })
        };

        this.onSave.emit(dialogResult);
    }

}