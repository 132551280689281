<div [class]="styleClass" [ngStyle]="style" [ngClass]="
        'p-paginator p-component p-component-header p-unselectable-text p-helper-clearfix ml-0 mr-0 py-3 row justify-content-between'
    " *ngIf="alwaysShow ? true : pageLinks && pageLinks.length > 1">
    <div class="col-4 d-flex">
        <div class="p-paginator-left-content" *ngIf="templateLeft">
            <ng-container *ngTemplateOutlet="
                    templateLeft;
                    context: { $implicit: paginatorState }
                "></ng-container>
        </div>

        <div class="d-flex flex-wrap align-items-center" *ngIf="shouldShowRowsDropdown">

           <div>Showing</div>
           <p-dropdown [options]="rowsPerPageItems" [(ngModel)]="rows" (onChange)="onRppChange($event)" [appendTo]="dropdownAppendTo" dropdownIcon="fas fa-sort-down"></p-dropdown> 
           <div class="ml-2">per page</div>
        </div>
    </div>
    <div class="col-4 d-flex flex-wrap justify-content-center text-right pl-0" [ngClass]="{'align-items-center': pageLinks.length == 1}">
        <a class="paginator-btn p-paginator-first" (click)="changePageToFirst($event)" (keydown.enter)="changePageToFirst($event)" [ngClass]="{ 'p-disabled': isFirstPage() }" [tabindex]="isFirstPage() ? -1 : null">
            <span class="p-paginator-icon fas fa-step-backward color-blue-background"></span>
        </a>
        <a tabindex="0" class="paginator-btn p-paginator-prev" (click)="changePageToPrev($event)" (keydown.enter)="changePageToPrev($event)" [ngClass]="{ 'p-disabled': isFirstPage() }" [tabindex]="isFirstPage() ? -1 : null">
            <span class="p-paginator-icon back-forward-icon fas fa-caret-left fa-lg color-blue-background"></span>
        </a>
        <span class="p-paginator-pages">
            <div *ngFor="let pageLink of pageLinks" class="page-link-wrapper" [ngClass]="
                    pageLink - 1 == getPage()
                        ? 'page-link-wrapper active-link-wrapper'
                        : 'page-link-wrapper inactive-link-wrapper'
                ">
                <a *ngIf="!(pageLink - 1 == getPage())" tabindex="0" class="p-paginator-page color-black-primary text-center inactive-link paginator-page" (click)="onPageLinkClick($event, pageLink - 1)" (keydown.enter)="onPageLinkClick($event, pageLink - 1)">{{ pageLink }}</a>
                <input #activeLink *ngIf="pageLink - 1 == getPage()" class="active-link" maxlength="3" type="text" value="{{getPage() + 1}}" (keydown.enter)="
                        onPageLinkClick($event, $event.target.value - 1)
                    " (keypress)="isNumericKey($event)" (blur)="activeLink.value = getPage() + 1" (focus)="activeLink.value = ''" />
            </div>
        </span>
        <a [attr.tabindex]="isLastPage() ? null : '0'" class="paginator-btn p-paginator-next" (click)="changePageToNext($event)" (keydown.enter)="changePageToNext($event)" [ngClass]="{ 'p-disabled': isLastPage() }" [tabindex]="isLastPage() ? -1 : null">
            <span class="p-paginator-icon back-forward-icon fas fa-caret-right fa-lg color-blue-background"></span>
        </a>
        <a [attr.tabindex]="isLastPage() ? null : '0'" class="paginator-btn p-paginator-last" (click)="changePageToLast($event)" (keydown.enter)="changePageToLast($event)" [ngClass]="{ 'p-disabled': isLastPage() }" [tabindex]="isLastPage() ? -1 : null">
            <span class="p-paginator-icon fas fa-step-forward color-blue-background"></span>
        </a>
    </div>
    <div class="col-4 d-flex align-items-center justify-content-end">
        <div class="text-right p-paginator-right-content d-flex align-items-center" *ngIf="templateRight">
            <ng-container *ngTemplateOutlet="
                    templateRight;
                    context: { $implicit: paginatorState }
                "></ng-container>
        </div>
    </div>

</div>