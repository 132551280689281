import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MenuItem, SelectItem, TreeNode, MessageService } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eStandardCampaginChannel, eChannel, eChannelType, eFileType, eUserRole, eVideoType, eCallToAction } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { EnumStringPipe } from '@Workspace/pipes';
import { FileUpload } from '../../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IFacebookDetailsJson, IFacebookNewsFeedDetailJson, IInStreamVideoJson, IDetailJson, ISelectItem, IDetailSocialCampaignJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { Functions, LookUps, Constants } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../../../mogo/services/campaign.service';
import { constants } from 'os';

@Component({
    selector: 'social-general',
    templateUrl: './social-general.component.html',
    styleUrls: ['./social-general.component.scss']
})
export class SocialGeneralComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
        private campaignService: CampaignService
    ) { }

     @Input() creative: ICreativeDto;
     @Input() reviewPage: boolean = false;
     @Input() isApprove: boolean = false;
     @Input() campaign: IBaseCampaignDto;

    @Output() Basecampaign: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    @Output() onCreativetDelete: EventEmitter<any> = new EventEmitter();
     ngUnsubscribe = new Subject();
    currentUser = this.authService.userSettings;

    ngOnInit() {

        if (this.creative.detailSocialCampaignJson == null) {
            this.creative.detailSocialCampaignJson = {} as IDetailSocialCampaignJson;
            
        }
        

    }


    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
