import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subscription } from 'rxjs';
import { GridComponent } from '../rc-grid.component';

@Component({
    selector: 'p-sortIcon',
    templateUrl: './sort-icon.component.html'
})
export class SortIconComponent implements OnInit, OnDestroy {
    @Input() field: string;

    @Input() ariaLabel: string;

    @Input() ariaLabelDesc: string;

    @Input() ariaLabelAsc: string;

    subscription: Subscription;

    sortOrder: number;

    constructor(public dt: GridComponent) {
        this.subscription = this.dt.tableService.sortSource$.subscribe(
            sortMeta => {
                this.updateSortState();
            }
        );
    }

    ngOnInit() {
        this.updateSortState();
    }

    onClick(event) {
        event.preventDefault();
    }

    updateSortState() {
        if (this.dt.sortMode === 'single') {
            this.sortOrder = this.dt.isSorted(this.field)
                ? this.dt.sortOrder
                : 0;
        } else if (this.dt.sortMode === 'multiple') {
            let sortMeta = this.dt.getSortMeta(this.field);
            this.sortOrder = sortMeta ? sortMeta.order : 0;
        }
    }

    get ariaText(): string {
        let text: string;

        switch (this.sortOrder) {
            case 1:
                text = this.ariaLabelAsc;
                break;

            case -1:
                text = this.ariaLabelDesc;
                break;

            default:
                text = this.ariaLabel;
                break;
        }

        return text;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
