<!-- <div class="col-md-9 mx-auto p-3 bg-white rounded shadow-sm"> -->

<div class="col-md-9 mx-auto p-3 bg-white rounded shadow-sm pt-4 border-bottom pb-4">

    <h3 class="first">Manage Campaign Reports</h3>
    <h6 class="border-gray mb-0 text-muted ">Upload PDF reports or add a campaign report link</h6>
    <div class=" pt-4 d-flex flex-column col-md-12">
        <div class="col-md-9 py-2 d-flex flex-row">
            <label class=" my-auto col-md-4">Upload Report</label>
            <div class="d-flex mr-2">
                <p-fileUpload *ngIf="baseCampaign.reportFile ==null" #upload mode="basic" accept=".pdf, .doc, .docx, .csv" (onSelect)='isFileValid(upload)' chooseLabel='Choose'></p-fileUpload>
                <a *ngIf="baseCampaign.reportFile" (click)="openReportFile(file)">{{baseCampaign.reportFile.fileName}}</a>
            </div>
            <div class="col-md-2" *ngIf="(!!upload && !!upload.files[0]) || baseCampaign.reportFile">
                <i class="fa fa-trash fa-md" tooltipPosition="top" pTooltip='Delete report file' [ngClass]="!baseCampaign.reportFile? 'pt-3': ''" (click)="removeFile(upload)"></i>
            </div>

        </div>
        <div class="col-md-9 py-2 d-flex flex-row">
            <label class="my-auto col-md-4">Tableau Live Report</label>
            <input *ngIf="baseCampaign.reportLink==null || isEditLink" type="text" [(ngModel)]="campaignReportLink" style="width: 90%;" placeholder="  https://www..." pInputText>
            <a clsass="d-block" *ngIf="baseCampaign.reportLink && !isEditLink" truncateText (click)="openReport(baseCampaign.reportLink)">{{baseCampaign.reportLink}}</a>
            <div class="col-md-2" tooltipPosition="top" pTooltip='Edit report link' *ngIf="baseCampaign.reportLink && !isEditLink">
                <i class="fa fa-pencil fa-md" (click)="editLink()"></i>
            </div>
        </div>

        <div class="col-5 offset-7 py-2 float-right">
            <button (click)="save()" [disabled]="!isEditLink && !isEditFile && (!campaignReportLink || (!!campaignReportLink == !!baseCampaign.reportLink))" pButton class="px-3 p-button-primary" style="float: right;" label="Save"></button>
        </div>
    </div>
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="ReportDelete" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>
    <!-- </div> -->
</div>