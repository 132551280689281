import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem, MenuItem } from 'primeng/api';
import { AuthService } from '@Workspace/auth';
import { AccountController } from '@Workspace/_generated/services';
import { NotificationService, StorageService, SettingsService, PageLoaderService } from '@Workspace/services';
import { Router, NavigationStart } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '@Workspace/common';
import { IUserPropertiesDto, IAccountReportingLinkDto, IAccountDto } from '@Workspace/_generated/interfaces';
import { eUserRole } from '@Workspace/_generated/enums';
import { CampaignService } from '../../services/campaign.service';
import { CampaignCalendar } from '../campaign-calendar-page/campaign-calendar-page';


@Component({
    selector: 'app-header-component',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    constructor(
        private authService: AuthService,
        private accountController: AccountController,
        private storageService: StorageService,
        private router: Router,
        public settingsService: SettingsService,
        public campaignService: CampaignService,
    ) {
    }

    isLogged: boolean = false;;
    expandableMenuItems: MenuItem[];
    expandableUserManagement: MenuItem[];
    expandableAnalytics: MenuItem[];
    headerMenu: MenuItem[];
    reports: IAccountReportingLinkDto[];
    accounts: any[];
    accountId: string;
    fullName: string = ' ';
    shouldShowAccountSettings: boolean = false;
    selectedTimeZone: string;
    selectedUserTitle: string;
    timeZoneOptions: SelectItem[] = [];
    USER_SETTINGS_KEY = 'user_Settings';
    currentUser: IUserPropertiesDto;
    userId: string;
    userTimeZone: string;
    constants = Constants;
    ngUnsubscribe: Subject<void> = new Subject();
    uploadNewCRMModalState: boolean = false;
    newCampaign = 'Add New Campaign';
    uploadCRM = 'Upload CRM' // I am not sure will be just Upload or Upload CRM.
    mobileNavbarState: boolean = false;
    userOptionVisible: boolean = false;
    isAccountDialogOpen: boolean = false;
    userAccount: IAccountDto;
    hrefLinkLogo = '';
    eUserRole = eUserRole;
    isChangePasswordDialogDisplayed: boolean;
    isChangePasswordDialogOpen: boolean;

    @ViewChild('CalendarDialog') campaignCalendarDialog: CampaignCalendar;
    @ViewChild("navbarDropdown")
    navbarDropdown: ElementRef;

    ngOnInit() {
        this.initialize();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.mobileNavbarState = false;
            }
        });
    }

    initialize() {
        this.authService.$userState
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(userState => {
                this.isLogged = this.authService.isLogged;
                this.currentUser = this.authService.userSettings;
                this.storageService.set(Constants.ACCOUNT_ID, this.accountId);
                this.hrefLinkLogo = (this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient 
                                                || this.currentUser.permissions.isSocialClient) ? '/fan365' : '/mogo';
                if (this.isLogged) {
                    this.loadUserSettings();
                    this.userId = this.authService.userSettings.id;
                    this.composeTimeZoneOptions();

                    if (this.currentUser.permissions.isClient && !!this.currentUser.accountId && !this.currentUser.permissions.isLearfieldClient && !this.currentUser.permissions.isOASClient && !this.currentUser.permissions.isSocialClient) {
                        let filter = `?$filter=AccountId eq ${this.currentUser.accountId}`
                        this.accountController.GetAccountReportingLinks(filter).subscribe(x => {
                            this.reports = x.data;
                            this.composeHeaderMenu();

                        })

                    } else
                        this.composeHeaderMenu();

                    this.composeExpandableMenuItems();
                    this.composeExpandableUserManagement();

                } else {
                    this.fullName = '';
                    this.resetSettings();
                }
            });
    }

    private loadUserSettings() {
        this.fullName = this.authService.userSettings.fullName;
    }

    handleUserClick(event) {
        event.stopPropagation();
        this.userOptionVisible = true;
    }

    getUrlWithoutHttps(url: string) {
        if (url.indexOf('https') == -1) {

            url = 'https://' + url;
        }
        return url;

    }

    @HostListener('document:click', ['$event.target'])
    public onClick(target) {
        if (!!this.navbarDropdown) {
            const clickedInside = this.navbarDropdown.nativeElement.contains(target);
            if (!clickedInside) {
                if (!!this.userOptionVisible) {
                    this.userOptionVisible = false;
                }
            }
        }
    }

    redirect(url) {

        window.open(url, "_blank");
    }

    private composeHeaderMenu() {
        
        if (!this.currentUser.permissions.isClient && !this.currentUser.permissions.isEmployee && !this.currentUser.permissions.isAdmin)
            this.headerMenu = []
        else

            this.headerMenu = [
                // {
                //     label: 'Home',
                //     icon: '',
                //     routerLink: "/mogo",
                // },
                {
                    label: 'User Management',
                    icon: '',

                    visible: this.currentUser.userRole != 6 && this.currentUser.userRole != 7,
                    routerLink: this.currentUser.permissions.isClient && !this.currentUser.permissions.isLearfieldClient && !this.currentUser.permissions.isOASClient && !this.currentUser.permissions.isSocialClient && this.currentUser.userRole !=6 && this.currentUser.userRole !=7 ? '/mogo/accounts/users'
                        : null,
                    items: !this.currentUser.permissions.isClient && this.currentUser.userRole !=6 && this.currentUser.userRole !=7 ? [
                        {
                            label: 'Client Accounts',
                            icon: '',
                            routerLink: '/mogo/accounts'
                        },
                        {
                            label: 'Mogo Users',
                            icon: '',
                            routerLinkActiveOptions: {},
                            routerLink: '/mogo/teams'
                        }
                    ] : null
                },
                {
                    label: 'Help Document',
                    icon: '',
                    url: 'https://learfield.sharepoint.com/sites/Fan365SalesSupport/SitePages/HUB-for-Fan365-User-Tools.aspx',

                    visible: (this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient
                                    || this.currentUser.userRole ==6) && this.currentUser.userRole != 7
                                         && (this.currentUser.isCoordinator || this.currentUser.isSeller)
                },
                {
                    label: 'Resources',
                    icon: '',
                    routerLink: '/mogo/resources',
                    visible: !this.currentUser.permissions.isLearfieldClient && !this.currentUser.permissions.isOASClient && !this.currentUser.permissions.isSocialClient,
                    items: [
                        {
                            label: 'Creative Specs',
                            icon: '',
                            routerLink: '/mogo/resources/creative-specs'
                        },
                        {
                            label: 'Documents',
                            icon: '',
                            routerLink: '/mogo/resources/documents'
                        }
                    ]
                },

                {
                    label: 'Reporting',
                    icon: '',
                    visible: this.currentUser.permissions.isClient && !this.currentUser.permissions.isLearfieldClient && !this.currentUser.permissions.isOASClient && !this.currentUser.permissions.isSocialClient,
                    items: !!this.reports ? this.reports.map(x => {
                        return {
                            label: x.urlLinkName,
                            icon: '',
                            url: this.getUrlWithoutHttps(x.url)
                        }
                    }) : null
                },

            ]
    }

    showSubMenu(item) {
        if (!!item.items)
            item.expanded = true;
    }

    openEditAccountDialog() {
        if ((this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient || this.currentUser.userRole ==6 || this.currentUser.userRole ==7) && this.currentUser.accounts.length > 0) {
            if(this.currentUser.accounts.length > 1)
                this.userAccount = this.currentUser.accounts.find(x => x.id = this.currentUser.accountId);
            this.userAccount = this.currentUser.accounts[0];
            this.showHideDialog(true);
        }
    }

    showHideDialog(event: any) {
        this.isAccountDialogOpen = event;
    }

    openChangePasswordDialog(){
        this.showHideChangePasswordDialog(true);
    }

    showHideChangePasswordDialog(event: boolean) {
        this.isChangePasswordDialogDisplayed = event;
        setTimeout(() => {
            this.isChangePasswordDialogOpen = event;
        }, 0);
    }

    openCalendarDialog() {
        this.router.navigate(['campaign-calendar']);
    }

    private resetSettings() {
        this.expandableMenuItems = [];
        this.timeZoneOptions = [];
        this.router.navigate(['/sign-in']);


    }


    private async composeTimeZoneOptions() {
        let selectedTimeZone = this.timeZoneOptions.find(
            i => i.label === this.userTimeZone
        );

        if (!!selectedTimeZone) {
            this.selectedTimeZone = selectedTimeZone.value;
        }
    }


    private composeExpandableUserManagement() {
        if (this.currentUser.userRole != eUserRole.Client) {
            this.expandableUserManagement = [
                {
                    label: 'Accounts',
                    icon: '',
                    command: () => {
                        this.redirectToAccountSettings();
                    }
                },
                {
                    label: 'Mogo Employee',
                    icon: '',
                    command: () => {
                        this.redirectToUserSettings();
                    }
                }]
        }
        else {
            this.expandableUserManagement = [

                {
                    label: 'Accounts',
                    icon: '',
                    command: () => {
                        this.redirectToAccountSettings();
                    }
                }]
        }

        this.expandableAnalytics = [
            {
                label: ' Product Dashboard ',
                icon: '',
                command: () => {
                    // this.redirectToAccountSettings();
                }
            },
            {
                label: ' Paid Search ',
                icon: '',
                command: () => {
                    //    this.redirectToUserSettings();
                }
            },
            {
                label: ' Google Grant ',
                icon: '',
                command: () => {
                    // this.redirectToUserSettings();
                }
            }]

    }

    public openUploadNewCRM() {
        this.uploadNewCRMModalState = true;
    }

    public handleCloseDialog() {
        this.uploadNewCRMModalState = false;
    }

    public updateMobileNavbarState() {
        this.mobileNavbarState = !this.mobileNavbarState;
    }

    private composeExpandableMenuItems() {
        let accountMenuItem = {
            label: 'Account',
            icon: 'fa fa-list',
            items: !!this.currentUser.accounts ? this.currentUser.accounts.map(x => { return { label: x.name, value: x.id } }) : null,
            id: this.currentUser.accountId,
        };

        this.expandableMenuItems = [
            {
                label: 'Logout',
                icon: 'fa fa-sign-out',
                command: () => {
                    this.logout();
                }
            }
        ];

        if (!!this.currentUser.accounts && this.currentUser.userRole != eUserRole.Admin)
            this.expandableMenuItems.unshift(accountMenuItem);
    }

    public logout() {
        if (this.authService.isLogged) {
            this.campaignService.setGridIdId(null);
            localStorage.removeItem('gridFilter');
            localStorage.removeItem('sponsor');
            this.authService.logOut();
            this.router.navigate(['/sign-in']);
        }
    }

    public redirectToUserSettings() {

        this.router.navigate(['/mogo/teams']);
    }

    public redirectToAccountSettings() {
        if (this.currentUser.permissions.isClient)
            this.router.navigate(['/mogo/accounts/users']);
        else
            this.router.navigate(['/mogo/accounts']);
    }
    public redirectToInstructions() {
        this.router.navigate(['/mogo/resources']);

    }

    public async onChangeAccount(event) {
        this.accountId = event.value;
        this.storageService.set(Constants.ACCOUNT_ID, this.accountId);
        await this.authService.reloadUserSettingsAsync();
    }

    public create() {
        if (this.currentUser.permissions.isClient)
            return "/#/mogo/campaign/" + Constants.GuidEmpty;
        else
            return "/#/mogo/su-campaign/" + Constants.GuidEmpty;

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onSubmit() { }

    clearSessionIfClient(){
        if((this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient)
            && this.currentUser.userRole != 6 && this.currentUser.userRole != 7){
                localStorage.removeItem('sponsor');
            }
    }
}
