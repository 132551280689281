<div *ngIf="!_flipped; else flipped_card" class="w-100 card-container">
    <div class="px-3 pt-3">
        <span class="header break-word">
            {{ _header }}
        </span>
    </div>

    <div class="content px-3 pb-3 ">{{ _content }}</div>
</div>

<ng-template #flipped_card>
    <div class="w-100 card-container">
        <div class="px-3 pt-3">
            <span class="header-flipped break-word">
                {{ _header }}
            </span>
        </div>
    
        <div class="content-flipped px-3 pb-3 ">{{ _content }}</div>
    </div>
</ng-template>
