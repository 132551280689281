import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MenuItem, SelectItem, TreeNode, MessageService } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eStandardCampaginChannel, eChannel, eChannelType, eFileType, eUserRole, eVideoType } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FileUpload } from '../../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IFacebookDetailsJson, IFacebookNewsFeedDetailJson, IInStreamVideoJson, IDetailJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { Functions, LookUps, Constants } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'instant-experience',
    templateUrl: './instant-experience.component.html',
    styleUrls: ['./instant-experience.component.scss']
})
export class InstantExperienceComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() dynamic: boolean = false;
    @Input() landingPage: string;
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    isPreviewOpen: boolean;
    currentIndex: number;
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isUploadOk: boolean = true;
    constants = Constants;
    isLandingUrlOk: boolean = true;
    titleWrongUrl: string = 'Please enter a valid Url.';
    functions = Functions;
    enumUserRole = eUserRole;
    maxNumberOfFlights: number = 1;
    currentUser = this.authService.userSettings;

    ngOnInit() {
        this.creative.creativeAssets.sort((x, y) => { return <any>new Date(x.createdOn) - <any>new Date(y.createdOn) });

        this.creative.creativeAssets.forEach(x => {
            if (!!x.file && !!x.file.urlWithToken)
                this.creativeAssetsForPreview.push(x);
        });

        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));
        //this.campaign.creatives.sort((x, y) => { return y.creativeGroup - x.creativeGroup })[0].creativeGroup;

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId);
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            });

    }
    removeAsset(asset: ICreativeAssetDto) {
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights))
        {
            let index = this.creative.creativeAssets.indexOf(asset);
            this.creative.creativeAssets.splice(index, 1);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }
    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }
    addCreativeAsset() {
        if (this.creative.creativeAssets.length < 12) {

            let newAsset = {} as ICreativeAssetDto;
            newAsset.id = Constants.GuidEmpty;
            newAsset.width = 0;
            newAsset.height = 0;
            newAsset.isValid = null;
            newAsset.creativeId = this.creative.id;
            newAsset.fileType = null;
            newAsset.fileSize = 0;
            newAsset.imageUrl = null;
            newAsset.fileName = null;
            newAsset.url = null;
            newAsset.isChanged = null;
            newAsset.isUploadValid = null;
            newAsset.isDeleted = null;

            if (newAsset.detailJson == null) {
                newAsset.detailJson = {} as IDetailJson;

                newAsset.detailJson.headline = '';
                newAsset.detailJson.bodyText = [];
                newAsset.detailJson.bodyText.push('');
                newAsset.detailJson.linkDescription = '';
                newAsset.detailJson.landingPageUrl = '';
                newAsset.detailJson.facebookPageUrl = '';
                newAsset.detailJson.videoType = 0;
                // newAsset.detailJson.destinationUrl = '';
                newAsset.detailJson.campaignTitle = '';
                newAsset.detailJson.advertiser = '';
                newAsset.detailJson.companyName = '';
                newAsset.detailJson.shortTitle = '';
                newAsset.detailJson.longTitle = '';
                newAsset.detailJson.shortDescription = '';
                newAsset.detailJson.longDescription = '';
                newAsset.detailJson.callToAction = '';
                newAsset.detailJson.headline2 = '';
                newAsset.detailJson.description = '';
                newAsset.detailJson.path1 = '';
                newAsset.detailJson.path2 = '';
                newAsset.detailJson.displayUrl = '';
                newAsset.detailJson.adGroup = '';
                newAsset.detailJson.destinationUrl = '';


            }
            this.creative.creativeAssets.push(newAsset);

        }
    }

    validationLandingPageMessage() {
        let textToMacth = !!this.landingPage ? this.landingPage : this.creative.detailJson.destinationUrl;
        // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?%#[\]@!\$&'\(\)\*\+,;=.]+$/;
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        var found = textToMacth.match(regex);
        this.isLandingUrlOk = true;
        if (found == null) {
            this.isLandingUrlOk = false;
        }
    }
}
