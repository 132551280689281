<div *ngIf="!!creative.contentDetailJson" class="pl-3" class="left-border ml-5">

    <div class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">Content Creative</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2 mt-2">
        <div class="col-lg-3 col-12">
            <label class="required"><strong>Please write a brief description of the creative that was sold</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input [disabled]="reviewPage" class="form-control {{creative.contentDetailJson.description == '' ? 'invalid-input' : ''}}" type="text" style="overflow: hidden; text-overflow: ellipsis;" [(ngModel)]="creative.contentDetailJson.description">
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2 mt-4" >
        <div class="col-lg-3 col-12">
            <label class="required"><strong>What sport will this be across?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-multiSelect [disabled]="reviewPage" [placeholder]="'Choose'" [options]="sportOptions" [(ngModel)]="selectedSports"  (onChange)="onMultiSelectChange('sports')" [virtualScroll]="yes" itemSize="30" selectedItemsLabel="{0} items selected" class="mr-0 pr-0 {{creative.contentDetailJson.sports == null || creative.contentDetailJson.sports == ''? 'ng-invalid' : ''}}" [style]="{width: '100%'}" autoWidth="false"></p-multiSelect>
            <input *ngIf="selectedSports.includes('Other')" [(ngModel)]="otherSportsInput" [disabled]="reviewPage" (change)="onChangeOtherField('sports')" class="form-control other-input" type="text" [style]="{width: '100%'}">
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2 mt-4" >
        <div class="col-lg-3 col-12">
            <label class="required"><strong>Who is producing this content?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-multiSelect [disabled]="reviewPage" [placeholder]="'Choose'" [options]="producerOptions" [(ngModel)]="selectedProducers" (onChange)="onMultiSelectChange('producers')" [virtualScroll]="yes" itemSize="30" selectedItemsLabel="{0} items selected" class="mr-0 pr-0 {{creative.contentDetailJson.contentProducer == null || creative.contentDetailJson.contentProducer == ''? 'ng-invalid' : ''}}" [style]="{width: '100%'}" autoWidth="false"></p-multiSelect>
        </div>
    </div>

    <div class="row align-items-center mb-4 ml-2 mt-4 {{creative.contentDetailJson.included == '' ? 'bordered-div' : ''}}" >
        <div class="col-lg-3 col-12">
            <label class="required"><strong>What is included?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-checkbox *ngFor="let cb of includedCheckboxes" [disabled]="reviewPage || (cb.value != 'N/A' && isNAchecked)" class="row ml-0" (onChange)="onChangeCheckbox($event, cb.value, 'included')" [ngModel]="isSelected(includedArray, cb.value)" binary="true" name="included" [value]="cb.value" [label]="cb.label"></p-checkbox>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2 mt-2">
        <div class="col-lg-3 col-12">
            <label class="required"><strong>Has athletics approved the concept and sponsorship elements?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-dropdown [disabled]="reviewPage" class="mr-0 pr-0 {{creative.contentDetailJson.athleticsApproved == null ? 'ng-invalid' : ''}}" [style]="{width: '100%'}" autoWidth="false" [options]="approvedAthleticsOptions" [(ngModel)]="creative.contentDetailJson.athleticsApproved"></p-dropdown>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2 mt-4 {{creative.contentDetailJson.brandObjectives == '' ? 'bordered-div' : ''}}" >
        <div class="col-lg-3 col-12">
            <label class="required"><strong>What is the Brand’s Objective for the content?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-checkbox *ngFor="let cb of brandObjectiveCheckboxes" [disabled]="reviewPage" class="row ml-0" (onChange)="onChangeCheckbox($event, cb.value, 'brandObjectives')" [ngModel]="isSelected(brandObjectiveArray, cb.value)" binary="true" name="brandObjectives" [value]="cb.value" [label]="cb.label"></p-checkbox>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2 mt-2">
        <div class="col-lg-3 col-12">
            <label class="required"><strong>How many posts were guaranteed?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-dropdown [disabled]="reviewPage" class="mr-0 pr-0 {{creative.contentDetailJson.numberOfOrganicPosts == null ? 'ng-invalid' : ''}}" [style]="{width: '100%'}" autoWidth="false" [options]="numberOfOrganicPostOptions" [(ngModel)]="creative.contentDetailJson.numberOfOrganicPosts"></p-dropdown>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2 mt-2">
        <div class="col-lg-3 col-12">
            <label class="required"><strong>How many impressions were guaranteed?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-dropdown [disabled]="reviewPage" class="mr-0 pr-0 {{creative.contentDetailJson.numberOfImpressions == null ? 'ng-invalid' : ''}}" [style]="{width: '100%'}" autoWidth="false" [options]="numberOfImpressionsOptions" [(ngModel)]="creative.contentDetailJson.numberOfImpressions"></p-dropdown>
        </div>
    </div>

    <label style="margin-left: 25px; margin-top: 25px;"><strong>Attach Files</strong></label>
    <div class="file-upload-container d-flex">
        <file-upload class="flex-item" (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' filetypes="jpg, jpeg, png, gif" [footer]='booleanFalse' [header]="booleanTrue" [campaign]="campaign" [creative]="creative" [asset]="0"></file-upload>
        <file-upload class="flex-item" [reviewPage]='reviewPage' [bulkUpload]="true" filetypes="all" [header]="booleanTrue" [campaign]="this.campaign" [creative]="this.creative" [asset]="1"></file-upload>
    </div>
    <div *ngFor="let item of creative.creativeAssets; let i = index" class="ml-2">
        <div *ngIf="!!item.file && !!item.fileName && i>0">
            <label class="my-0 font-weight-bold pl-3 mr-3">{{item.fileName}}</label>
            <i *ngIf="campaign.campaignStatusId == enumCampaignStatus.Assets_Pending || campaign.campaignStatusId == enumCampaignStatus.Assets_Uploaded" (click)="removeAsset(item)" class="fa fa-trash-alt delete" pTooltip='Remove asset'></i>
        </div>
    </div>


</div>