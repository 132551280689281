<div class="card card-body mx-5 my-3">
    <div class="row mt-3 px-3">
        <div class="col-12">
            <h3 class="font-weight-bold">Campaign Documents</h3>
            <div>Learn about how to access your analytics and social pages. Click on any button to view PDF.</div>
        </div>
    </div>

    <div class="row mt-4 px-3">
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.GoogleAnalyticsAccess)">Google Analytics Access</button>
        </div>
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.SocialFacebookInstagramAccess)">Social Facebook & Instagram Access</button>
        </div>
    </div>

    <div class="row mt-2 px-3">
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.GoogleTagManager)">Google Tag Manager Access</button>
        </div>
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.LinkedInAccess)">LinkedIn Access</button>
        </div>
    </div>

    <div class="row mt-2 px-3">
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.MogoCRMDataTempalte)">Mogo CRM Data Template</button>
        </div>
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.YouTube)">YouTube Access</button>
        </div>
    </div>

    <div class="row mt-2 px-3 justify-content-end">
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.Pinterest)">Pinterest Access</button>
        </div>
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="download(constants.Snapchat)">Snapchat Access</button>
        </div>
    </div>

    <div class="mt-5 px-3">
        <hr />
    </div>

    <div class="row mt-3 px-3">
        <div class="col-12">
            <h3 class="font-weight-bold">Help Documents</h3>
            <div>Please see our privacy and refund policy here.</div>
        </div>
    </div>

    <div class="row mt-4 px-3">
        <div class="col-lg-6 col-12 mt-3 mt-lg-0">
            <button (click)="redirectPP()">Privacy Policy</button>
        </div>
        <div class="col-lg-6 col-12 mt-lg-0 my-3">
            <!-- <button (click)="showHideRefundPolicy()">Refund Policy</button> -->
            <p-toggleButton [(ngModel)]="refundPolicyShown" onLabel="Refund Policy" offLabel="Refund Policy"></p-toggleButton>
            
        </div>
    </div>
</div>
<div class="card card-body mx-5 my-3" *ngIf="refundPolicyShown">
    <div class="row mt-3 px-3">
        <div class="col-12">
            <h3 class="font-weight-bold">Refund Policy</h3>
            Client acknowledges that all amounts invoiced to Client will be based upon estimates for media purchases provided by media suppliers, and in respect of any given media purchase, 
            the amount that Client may actually be required to pay may not correspond precisely to the estimated amount previously invoiced to Client. 
            Any Hub by Mogo Package campaign budgets that are under-spent or over-spent will not be reconciled to either Agency or Client. 
            When Agency overspends, there is no expectation for Client to pay the overage, and/or when Agency underspends, there is no expectation for the Agency to refund the Client.
        </div>
    </div>
</div>