<nav id="invisibleForPrint" *ngIf="isLogged" class="navbar header-wrapper navbar-expand-lg primary-navbar d-lg-flex d-none p-0">
    <a [routerLink]="hrefLinkLogo" (click)="clearSessionIfClient()" class="navbar-brand text-uppercase px-3 py-0" [ngClass]="{
            'fan-365-navbar': (currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient
            || currentUser.userRole == 6 || currentUser.userRole == 7)
        }">
        <span *ngIf="currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient
                    || currentUser.userRole == 6 || currentUser.userRole == 7">
            <img src="../../../../../assets/fan365_white.png" />
        </span>
        <span class="hub-logo-span" *ngIf="!currentUser.permissions.isLearfieldClient && !currentUser.permissions.isOASClient && !currentUser.permissions.isSocialClient
                            && currentUser.userRole != 6 && currentUser.userRole != 7">
            <img src="../../../../../assets/Hub Logo.png" />
        </span>
    </a>
    <ul class="navbar-nav mr-auto p-3" *ngIf=" 1==0 && (currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || currentUser.userRole == eUserRole.LIMGCSocialAdOps)  ">
        <li class="nav-item pr-3">
            <a routerLinkActive="active-link" routerLink="../campaign-calendar" (click)="openCalendarDialog()" class="nav-link px-0">Calendar</a>
        </li>
    </ul>

    <ul class="navbar-nav mr-auto p-3">
        <ng-template ngFor let-item [ngForOf]="headerMenu">
            <li class="nav-item pr-3" [ngClass]="!!item.items ? 'dropdown' : ''" *ngIf="item.visible">
                <a *ngIf="!item.items && !item.url" routerLinkActive="active-link" (click)="openEditAccountDialog()" [routerLink]="item.routerLink" class="nav-link px-0">{{ item.label }}</a>
                <a *ngIf="!!item.url" (click)="redirect(item.url)" class="nav-link px-0">{{ item.label }}</a>
                <a *ngIf="!!item.items" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ item.label }}
                </a>
                <div *ngIf="!!item.items && !!item.items.length" class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <span *ngFor="let subitem of item.items">
                        <a *ngIf="!!subitem.url" target="_blank" [href]="
                                item.label == 'Reporting' 
                                    ?  subitem.url
                                    : null
                            " class=" dropdown-item">{{ subitem.label }}</a>
                        <a *ngIf="!!subitem.routerLink" [routerLink]="
                                item.label != 'Reporting'
                                    ? subitem.routerLink
                                    : null
                            " class=" dropdown-item">{{ subitem.label }}</a>
                    </span>
                </div>
            </li>
        </ng-template>
    </ul>

    <ul class="navbar-nav ml-auto h-100">
        <li class="nav-item center-y">
            <!-- <button-expandable [isUserButton]="true" label="{{fullName}}" icon="fa fa-caret-down" (onChange)="onChangeAccount($event)" [model]="expandableMenuItems" buttonTheme="transparent"></button-expandable> -->
            <div class="d-flex flex-wrap align-items-center right-nav-items" *ngIf="(currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient
                                                                                            || currentUser.userRole == 6 || currentUser.userRole == 7)">
                <div class="pr-3 user-avatar" (click)="handleUserClick($event)" style="position: relative;">
                    <!-- <img style="width: 25px;" class="ml-3 card-img-top" src="https://rosieshouse.org/wp-content/uploads/2016/06/avatar-large-square.jpg" alt="Avatar Image"> -->
                    <i class="fal fa-user"></i>

                    <div *ngIf="userOptionVisible" id="user-options" #navbarDropdown>
                        <div class="arrow-up"></div>
                        <div class="d-flex flex-column user-options-wrapper card card-body">
                            <div>{{ fullName }}</div>
                            <button *ngIf="!currentUser.isMigrated && currentUser.permissions.isClient" (click)="openChangePasswordDialog()" class="btn logout-link">
                                Change Password
                            </button>
                            <a (click)="logout()" class="btn logout-link">
                                Logout
                            </a>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-wrap h-100">
                    <div [pTooltip]="newCampaign" class="p-4 bg-primary text-white nav-btn" *hasPermission="
                            [
                                constants.CanCreateCampaignEmployee,
                                constants.CanCreateCampaignClient
                            ];
                            op: 'OR'
                        " (click)="create()">
                        <i class="fa fa-plus px-1" aria-hidden="true"></i>
                    </div>

                    <div [pTooltip]="uploadCRM" class="p-4 bg-secondary text-white nav-btn tooltip" (click)="openUploadNewCRM()" *hasPermission="[constants.CanManageCRM]">
                        <i class="fa fa-upload px-1" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-wrap align-items-center right-nav-items h-100" *ngIf="!currentUser.permissions.isLearfieldClient && !currentUser.permissions.isOASClient && !currentUser.permissions.isSocialClient
                                                                                            && currentUser.userRole != 6 && currentUser.userRole != 7">
                <div class="pr-3 d-flex flex-wrap align-items-center" (click)="handleUserClick($event)">
                    <!-- <i class="fal fa-user"></i> -->

                    <div>
                        <!-- <div class="arrow-up"></div> -->
                        <div class="d-flex flex-column">
                            <div>{{ fullName }}</div>
                            <a (click)="logout()" class="btn logout-link">
                                Logout
                            </a>
                        </div>
                    </div>

                    <img style="width: 30px;height: 30px;" class="ml-3 card-img-top" src="https://rosieshouse.org/wp-content/uploads/2016/06/avatar-large-square.jpg" alt="Avatar Image" />
                </div>

                <div class="d-flex flex-wrap h-100">
                    <a class="p-4 bg-secondary text-white nav-btn link" href="{{create()}}" aria-hidden="true" *hasPermission="
                            [
                                constants.CanCreateCampaignEmployee,
                                constants.CanCreateCampaignClient
                            ];
                            op: 'OR'
                        " >
                        <div [pTooltip]="newCampaign" class="fa fa-plus px-1" >
                        </div>
                    </a>
                    <div [pTooltip]="uploadCRM" class="p-4 bg-primary text-white nav-btn" (click)="openUploadNewCRM()" *hasPermission="[constants.CanManageCRM]">
                        <i class="fa fa-upload px-1" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</nav>

<div id="invisibleForPrint" class="d-flex d-lg-none align-items-center justify-content-between header-wrapper" [ngClass]="{ 'bg-primary': (currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient
                                                                                                                                                        || currentUser.userRole == 6 || currentUser.userRole == 7) }">
    <div>
        <a routerLink="/mogo" class="navbar-brand text-uppercase p-3" [ngClass]="{
                'fan-365-navbar': ((currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient
                                                || currentUser.userRole == 6 || currentUser.userRole == 7))
            }">
            <span *ngIf="(currentUser.permissions.isLearfieldClient || currentUser.permissions.isOASClient || currentUser.permissions.isSocialClient
                                    || currentUser.userRole == 6 || currentUser.userRole == 7)">
                <img src="../../../../../assets/fan365_white.png" />
            </span>
            <span *ngIf="!currentUser.permissions.isLearfieldClient && !currentUser.permissions.isOASClient && !currentUser.permissions.isSocialClient
                                    && currentUser.userRole != 6 && currentUser.userRole != 7">
                <img src="../../../../../assets/Hub Logo.png" />
            </span>
        </a>
    </div>

    <div class="pr-3 user-avatar" (click)="handleUserClick($event)" style="position: relative;">
        <!-- <img style="width: 25px;" class="ml-3 card-img-top" src="https://rosieshouse.org/wp-content/uploads/2016/06/avatar-large-square.jpg" alt="Avatar Image"> -->
        <i class="fal fa-user"></i>

        <div *ngIf="userOptionVisible" id="user-options" #navbarDropdown>
            <div class="arrow-up"></div>
            <div class="d-flex flex-column user-options-wrapper card card-body">
                <div>{{ fullName }}</div>
                <a (click)="logout()" class="btn logout-link">
                    Logout
                </a>
            </div>
        </div>
    </div>

    <div class="navbar-icon pr-3" *ngIf="!currentUser.permissions.isLearfieldClient && !currentUser.permissions.isOASClient && !currentUser.permissions.isSocialClient
                                            && currentUser.userRole != 6 && currentUser.userRole != 7" (click)="updateMobileNavbarState()">
        <i class="fas fa-bars"></i>
    </div>
</div>

<nav id="invisibleForPrint" class="mobile-navbar d-block d-lg-none" *ngIf="mobileNavbarState">
    <div class="navbar-wrapper">
        <!-- <div class="brand-wrapper d-flex flex-wrap align-items-center justify-content-between">
            <div class="text-uppercase p-3">
                <span *ngIf="!currentUser.permissions.isLearfieldClient">
                    <img src="../../../../../assets/Hub Logo.png" />
                </span>
            </div>

            <div class="p-3" (click)="updateMobileNavbarState(false)">
                <i class="fa fa-times"></i>
            </div>
        </div> -->

        <ul class="navbar-nav mr-auto p-3">
            <ng-template ngFor let-item [ngForOf]="headerMenu">
                <li class="nav-item pr-3" [ngClass]="!!item.items ? 'dropdown' : ''" *ngIf="item.visible">
                    <a *ngIf="!item.items && !item.url" routerLinkActive="active-link" [routerLink]="item.routerLink" class="nav-link px-0">{{ item.label }}</a>
                    <a *ngIf="!!item.url" (click)="redirect(item.url)" class="nav-link px-0">{{ item.label }}</a>
                    <a *ngIf="!!item.items">
                        {{ item.label }}
                    </a>

                    <ul>
                        <li *ngFor="let subitem of item.items" [routerLink]="subitem.routerLink" class="p-2">
                            <a [routerLink]="subitem.routerLink">{{
                                subitem.label
                            }}</a>
                        </li>
                    </ul>
                </li>
            </ng-template>

            <li class="nav-item active">
                <div class="d-flex flex-wrap justify-content-between align-items-center mt-3">
                    <div>
                        <img style="width: 30px;height: 30px;" class="mr-3 card-img-top" src="https://rosieshouse.org/wp-content/uploads/2016/06/avatar-large-square.jpg" alt="Avatar Image" />

                        {{fullName}}
                    </div>

                    <div>
                        <a (click)="logout()" class="nav-link px-0">
                            Logout
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>

<app-upload-crm-modal *ngIf="uploadNewCRMModalState" (closeDialog)="handleCloseDialog()"></app-upload-crm-modal>

<account-edit-dialog *ngIf="!!userAccount && isAccountDialogOpen" (accountDialogDisplay)="showHideDialog($event)" [displayDialog]="isAccountDialogOpen" [account]="userAccount"></account-edit-dialog>

<change-password-dialog *ngIf="isChangePasswordDialogOpen" (changePasswordDialogDisplay)="showHideChangePasswordDialog($event)" [displayDialog]="isChangePasswordDialogDisplayed" [userId]="currentUser.id"></change-password-dialog>
