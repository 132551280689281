import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { eProviderType } from '@Workspace/_generated/enums';
import { ILoginModel, IResetPasswordModel, IUserDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';
import { PageLoaderService, SettingsService } from '@Workspace/services';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CampaignService } from '../../services/campaign.service';
import { AccountController } from '@Workspace/_generated/services';

@Component({
    selector: 'sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
    loginModel: ILoginModel = {
        userName: '',
        password: ''
    } as ILoginModel;

    resetPasswordModel: IResetPasswordModel = {
        newPassword: '',
        confirmPassword: '',
        userId: ''
    } as IResetPasswordModel;

    loginMessage = '';
    errorMessage = '';
    public userLoggedIn = false;
    ngUnsubscribe = new Subject();
    currentUrl: string = '';
    route: string = 'sign-in';
    guidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    userDto: IUserDto = {} as IUserDto;
    userId: string = '';
    changePasswordUsername: string = '';
    isValidPassword = true;
    emailForRecover = '';
    isValidEmail = false;

    constructor(
        public authService: AuthService,
        private router: Router,
        public settings: SettingsService,
        public messageService: MessageService,
        public campaignService: CampaignService,
        private pageLoaderService: PageLoaderService,
        private accountController: AccountController
    ) { }

    signupIFrameState: boolean;
    
    ngOnInit() {
        this.currentUrl = this.router.url;
        if(this.currentUrl.includes('recover-password'))
            this.route = 'recover-password';
        else if(this.currentUrl.includes('reset-password')){
            this.userId = this.currentUrl.split('/').pop();
            if(this.guidPattern.test(this.userId)){
                this.accountController.GetUser(this.userId).subscribe(data => {
                    if(data != null){
                        this.resetPasswordModel.userId = data.id;
                        this.userDto.id = data.id;
                        this.userDto.email = data.email.toLowerCase();
                        this.userDto.fullName = data.fullName;
                        this.userDto.userName = data.userName.toLowerCase();
                    }
                    else{
                        this.router.navigate(['/sign-in']);
                    }
                });
                this.route = 'reset-password'; 
            }
            else{
                this.router.navigate(['/sign-in']);
            }
        }
        this.authService.$userState
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(response => {
                if (response) {
                    this.userLoggedIn = true;
                    this.loginMessage =
                        'Welcome ' + this.authService.userSettings.fullName;
                    this.errorMessage = '';
                    this.navigateFromLogin();
                } else {
                    this.userLoggedIn = false;
                    this.loginMessage = 'Logged out';
                    this.errorMessage = '';
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onSubmit() {
        this.pageLoaderService.showPageLoader();
        localStorage.removeItem('gridFilter');
        localStorage.removeItem('sponsor');
        this.onSubmitAsync();
    }

    onSubmitAd() {
        this.onSubmitAdAsync();
    }

    async onSubmitAdAsync() {
        await this.authService.loginSocial(eProviderType.AzureAD);
    }

    async onSubmitAsync() {
        try {
            await this.authService.login(
                this.loginModel.userName,
                this.loginModel.password
            );
        } catch {
            this.errorMessage = 'Wrong login';
            this.messageService.add({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Wrong Login.'
            });

            console.log('Wrong login');
        } finally {
            this.pageLoaderService.hidePageLoader();
        }
    }

    onLogout() {
        this.onLogoutAsync();
        this.loginModel.userName = '';
        this.loginModel.password = '';
    }

    private async navigateFromLogin() {
        this.router.navigate(['/mogo']).catch(error => {
            this.router.navigate(['/sign-in']);
        });
    }

    async onLogoutAsync() {
        await this.authService.logOut();
    }

    openSignupFrame() {
        this.signupIFrameState = true;
        document.querySelector('body').classList.add('bg-white');
    }

    closeSignupFrame() {
        this.signupIFrameState = false;
        document.querySelector('body').classList.remove('bg-white');
    }

    onRecover(){
        this.pageLoaderService.showPageLoader();
        this.userDto.email = this.emailForRecover;
        this.accountController
            .SendRecoveryEmail(this.userDto)
                .subscribe(
                res => {
                    this.pageLoaderService.hidePageLoader();  
                    this.messageService.add({ severity: 'success', summary: 'Successfully Sent', detail: 'Recovery Email is Successfully Sent. Check your email please.' })
                },
                error => {
                    this.pageLoaderService.hidePageLoader();  
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
                });
    }

    onReset(){
        this.pageLoaderService.showPageLoader();
        this.accountController.ResetPassword(this.resetPasswordModel).subscribe(
            res => {
                this.pageLoaderService.hidePageLoader();  
                this.messageService.add({ severity: 'success', summary: 'Successfully Changed', detail: 'Password is Successfully Changed.' })
                this.router.navigate(['/sign-in']);
            },
            error => {
                this.pageLoaderService.hidePageLoader();    
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
            }
        );
    }

    isValidResetPassword(){
        return (this.changePasswordUsername.toLowerCase() == this.userDto.email || this.changePasswordUsername.toLowerCase() == this.userDto.userName)
            && this.resetPasswordModel.newPassword == this.resetPasswordModel.confirmPassword 
            && this.resetPasswordModel.newPassword != '' && this.resetPasswordModel.confirmPassword != '';
    }

    passwordValidation(){
        this.isValidPassword = this.passwordPattern.test(this.resetPasswordModel.newPassword);
    }

    validateEmail(){
        this.isValidEmail = this.emailPattern.test(this.emailForRecover);
    }
}
