<div class="mb-2 mt-2 row align-items-center" *ngIf="editableFields[0]">
    <div class="col-md-3 font-weight-bold">
        Requires site visit tracking
        <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.siteVisitTrackingInfo'
            class="fal fa-info-circle info"></span>
    </div>
    <div class="col-md-4">
        <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
        <p-inputSwitch (onChange)="save()" [(ngModel)]="isSiteVisitTrackingEnabled" [disabled]="disabled"></p-inputSwitch>
    </div>
</div>


<div class="mb-2 row align-items-center" *ngIf="editableFields[1]">
    <div class="col-md-3 font-weight-bold">
        Requires conversion tracking
        <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.conversionTrackingInfo'
            class="fal fa-info-circle info"></span>
    </div>
    <div class="col-md-4">
        <p-inputSwitch (onChange)="save()" [(ngModel)]="isConversionTrackingEnabled" [disabled]="disabled"></p-inputSwitch>
    </div>
</div>

<div class="mb-2 row align-items-center" *ngIf="editableFields[2]">
    <div class="col-md-3 font-weight-bold">
        Requires additional targeting segments
        <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.additionalTargerSegmentsInfo'
            class="fal fa-info-circle info"></span>
    </div>
    <div class="col-md-4">
        <p-inputSwitch (onChange)="save()" [(ngModel)]="isAdditionalTargetingSegmentsEnabled" [disabled]="disabled"></p-inputSwitch>
    </div>
</div>
<div class="mb-2 row align-items-center">
    <div class="col-md-8 font-weight-bold reset-default-text" (click)="resetDefault()" [ngStyle]="{'pointer-events': disabled ? 'none' : 'auto'}">
        Click here to restore default values
    </div>
</div>