import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { LookUps, Constants } from '@Workspace/common';
import { IAccountDto, IUserAccountDto, IAccountReportingLinkDto, IUserCreateDto, IUserAccountSponsorDto, IGeographicTargetingJson } from '@Workspace/_generated/interfaces';
import { eUserRole, eEntityStatus, eAccountType, eUserAccountRole } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { TeamController, AccountController, TargetingController } from '@Workspace/_generated/services';
import { Subject } from 'rxjs';
import { PageLoaderService } from '@Workspace/services';

@Component({
    selector: 'account-edit-dialog',
    templateUrl: 'account-edit-dialog.component.html',
    styleUrls: ["./account-edit-dialog.component.scss"]
})
export class AccountEditDialogComponent implements OnInit, OnDestroy {
    constructor(
        public fb: UntypedFormBuilder,
        private authService: AuthService,
        private teamController: TeamController,
        private accountController: AccountController,
        private pageLoaderService: PageLoaderService,
        private targetingController: TargetingController,
        private pageLoader: PageLoaderService
    ) { }

    @Input() account: IAccountDto;
    @Input() displayDialog: boolean = false;
    @Input() dialogText: string = "";
    @Output() accountDialogDisplay: EventEmitter<boolean> = new EventEmitter<null>();
    @Output() onSubmit: EventEmitter<object> = new EventEmitter<object>();

    dmas: SelectItem[];
    loading: boolean = false;
    states: SelectItem[];
    cities: SelectItem[];

    formGroup: UntypedFormGroup;
    accountTypes: SelectItem[] = [];
    industries: SelectItem[] = [];
    accountUsers: SelectItem[] = [];
    sponsors: SelectItem[] = [];
    regions: SelectItem[] = [];
    accountReportingLinks: IAccountReportingLinkDto[] = [];
    notificationGroups: SelectItem[] = [];
    constants = Constants;
    currentUser = this.authService.userSettings;
    ngUnsubscribe: Subject<void> = new Subject();


    async ngOnInit() {
        await this.getAccount();

        this.getData();
    }

    selectedCompanyType(event) {

    }

    formInit(): void {
        this.formGroup = this.fb.group({
            name: [""],
            type: [""],
            industry: [""],
            notificationTeam: [""],
            GCMAdvertiserId: [""],
            GCMAdvertiserName: [""],
            TTDAdvertiserId: [""],
            DV360AdvertiserId: [""],
            facebookAdAccountId: [""],
            facebookPageId: [""],
            instagramId: [""],
            apiBuild: [],
            region: [],
            coordinator: [],
            seller: [],
            sponsor: [],
            cities: [],
            dma: [""],
            state: [""]

        });
        if (!!this.account)
            this.patchFormValue();
    }

    async initLists() {
        let orderBy = `?$orderby=Name asc`;
        let teams = await this.teamController.GetTeams(orderBy).toPromise();
        this.notificationGroups = teams.data.map(t => { return { label: t.name, value: t.id } });
        this.notificationGroups.unshift({ label: "-", value: 0 });
        if (this.account.isLearfieldCampaign)
            this.accountTypes = LookUps.getAllAccountTypesLu();
        else
            this.accountTypes = LookUps.getAccountTypesLu();
        this.accountTypes.unshift({ label: "-", value: 0 });
        this.industries = LookUps.getIndustryLu();
        this.industries.unshift({ label: "-", value: 0 });
        this.accountUsers = this.account.accountUsersDto.map(x => { return { value: x.userId, label: x.userFullName } });
        this.sponsors = this.account.sponsorsDto.map(x => { return { value: x.id, label: x.accountName } });

        this.accountReportingLinks = this.account.accountReportingLinks;
        this.regions = LookUps.getRegionsLu();
        this.regions.unshift({ label: "-", value: 0 });

    }

    async getAccount() {
        this.pageLoaderService.showPageLoader();
        await this.accountController.GetAccountDetails(this.account.id).subscribe(async x => {
            this.account = x;
            await this.initLists();
            this.formInit();

            this.pageLoaderService.hidePageLoader();
        });
    }

    getAccountValues() {
        this.account.name = this.formGroup.value.name;
        this.account.industry = this.formGroup.value.industry;
        this.account.typeId = this.formGroup.value.type;
        this.account.notificationTeamId = this.formGroup.value.notificationTeam;
        this.account.advertiserId = this.formGroup.value.GCMAdvertiserId;
        this.account.advertiserName = this.formGroup.value.GCMAdvertiserName;
        this.account.ttdAdvertiserId = this.formGroup.value.TTDAdvertiserId;
        this.account.dV360AdvertiserId = this.formGroup.value.DV360AdvertiserId;

        this.account.facebookAdAccountId = this.formGroup.value.facebookAdAccountId;
        this.account.facebookPageId = this.formGroup.value.facebookPageId;
        this.account.instagramId = this.formGroup.value.instagramId;
        this.account.automaticApiBuild = this.formGroup.value.apiBuild;
        this.account.region = this.formGroup.value.region;

        this.account.geographicTargetDMA = this.formGroup.value.dma;
        this.account.geographicTargetState = this.formGroup.value.state;
        if(this.formGroup.value.cities == null || this.formGroup.value.cities.length==0){
            this.account.geographicTargetCity =null;
        }
        else{
            this.account.geographicTargetCity = JSON.stringify(this.formGroup.value.cities).replace('[','').replace(']','');
        }


        if (this.formGroup.value.coordinator) {
            this.formGroup.value.coordinator.forEach(c => {
                if (!!c)
                    this.account.accountUsersDto.find(x => x.userId == c).isCoordinator = true;
            })
        }

        this.account.accountUsersDto.forEach(x => {
            if ((!!this.formGroup.value.coordinator && !this.formGroup.value.coordinator.some(c => c == x.userId)) || !this.formGroup.value.coordinator)
                x.isCoordinator = false;
        });

        if (this.formGroup.value.seller) {
            this.formGroup.value.seller.forEach(s => {
                if (!!s)
                    this.account.accountUsersDto.find(x => x.userId == s).isSeller = true;
            })
        }

        this.account.accountUsersDto.forEach(x => {
            if ((!!this.formGroup.value.seller && !this.formGroup.value.seller.some(c => c == x.userId)) || !this.formGroup.value.seller)
                x.isSeller = false;
        });

        if (this.formGroup.value.sponsor) {
            this.formGroup.value.sponsor.forEach(c => {
                if (!!c) {
                    if (!this.account.accountUsersDto.find(x => x.userId == this.currentUser.id).userAccountSponsors.some(x => x.sponsorId == c)) {
                        let userSponsor = {} as IUserAccountSponsorDto;
                        userSponsor.id = Constants.GuidEmpty;
                        userSponsor.userAccountId = this.account.accountUsersDto.find(x => x.userId == this.currentUser.id).id;
                        userSponsor.sponsorId = c;

                        this.account.accountUsersDto.find(x => x.userId == this.currentUser.id).userAccountSponsors.push(userSponsor);
                    }
                }
                this.account.accountUsersDto.find(x => x.userId == this.currentUser.id).userAccountSponsors.forEach(us => {
                    if ((!!this.formGroup.value.sponsor.length && !this.formGroup.value.sponsor.some(s => s == us.sponsorId)) || (!this.formGroup.value.sponsor.length && this.formGroup.value.sponsor != us.sponsorId)) {
                        us.entityStatus = eEntityStatus.Deleted;
                    }
                })
            })
        } else {

            if ((this.currentUser.isCoordinator || this.currentUser.isSeller) && !!this.account.accountUsersDto && !!this.account.accountUsersDto.find(x => x.userId == this.currentUser.id))
                this.account.accountUsersDto.find(x => x.userId == this.currentUser.id).userAccountSponsors.forEach(us => {
                    us.entityStatus = eEntityStatus.Deleted;
                });
        }
    }

    patchFormValue() {
        this.formGroup.patchValue({
            name: this.account.name,
            industry: this.account.industry,
            type: this.account.typeId,
            notificationTeam: this.account.notificationTeamId,
            GCMAdvertiserId: this.account.advertiserId,
            GCMAdvertiserName: this.account.advertiserName,
            TTDAdvertiserId: this.account.ttdAdvertiserId,
            DV360AdvertiserId: this.account.dV360AdvertiserId,
            facebookAdAccountId: this.account.facebookAdAccountId,
            facebookPageId: this.account.facebookPageId,
            instagramId: this.account.instagramId,
            apiBuild: this.account.automaticApiBuild,
            region: this.account.region,
            coordinator: this.account.accountUsersDto.some(x => x.isCoordinator) ? this.account.accountUsersDto.filter(x => x.isCoordinator).map(x => {
                return x.userId
            }) : null,
            seller: this.account.accountUsersDto.some(x => x.isSeller) ? this.account.accountUsersDto.filter(x => x.isSeller).map(x => {
                return x.userId
            }) : null,

            sponsor: !!this.account.accountUsersDto.find(x => x.userId == this.currentUser.id) && !!this.account.accountUsersDto.find(x => x.userId == this.currentUser.id).userAccountSponsors ? this.account.accountUsersDto.find(x => x.userId == this.currentUser.id).userAccountSponsors.map(x => {
                return x.sponsorId
            }) : null,
            cities: this.account.geographicTargetCity != null ? this.account.geographicTargetCity.split(',').map(function (b, i) {return Number(b)}) : null,
            dma: parseInt(this.account.geographicTargetDMA),
            state: parseInt(this.account.geographicTargetState)

        });
    }

    getData(preselected: IGeographicTargetingJson = null, defaultPreselected: IGeographicTargetingJson = null) {
        this.loading = true;
        this.targetingController.GetGeographicDialogData(Constants.GuidEmpty, false, this.account.stateAbbreviation === null ? '' : this.account.stateAbbreviation)
            .subscribe(
                (result) => {
                    const stateMap = ({ id, name }) => ({ label: name, value: id });
                    const dmaMap = ({ dmaName, dmaCode }) => ({ label: dmaName, value: dmaCode });
                    const cityMap = ({ id, name, stateAbbreviation }) => ({ label: `${name}, ${stateAbbreviation}`, value: id });

                    const states = result.states.map(stateMap);
                    this.states = states;
                    this.states.unshift({ label: "-", value: null });

                    const dmas = result.dmas.map(dmaMap);
                    this.dmas = this.mapUnique(dmas);
                    this.dmas.unshift({ label: "-", value: null });

                    const cities = result.cities.map(cityMap);
                    this.cities = this.mapUnique(cities);

                    this.loading = false;
                    this.pageLoader.hidePageLoader()

                },
                (error) => { this.pageLoader.hidePageLoader() });
    }

    private mapUnique(items: SelectItem[]): SelectItem[] {
        let result: SelectItem[] = [];
        let map = new Map();

        items.forEach(x => {
            if (!map.has(x.label)) {
                map.set(x.label, true);
                result.push({ label: x.label, value: x.value });
            }
        });

        return result;
    }

    cancel() {
        this.displayDialog = false;
        this.accountDialogDisplay.emit(false);
    }

    submit() {
        this.getAccountValues();
        this.pageLoaderService.showPageLoader();
        this.accountController.CreateAccount(this.account).subscribe(async x => {
            //await this.authService.reloadUserSettingsAsync();
            this.displayDialog = false;
            this.pageLoaderService.hidePageLoader();

            this.accountDialogDisplay.emit(false);
            this.onSubmit.emit(x)
        })

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
 

}