import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit, AfterContentChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractFormGroupDirective } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { LookUps, Constants } from '@Workspace/common';
import { IUserDto, IUserCreateDto, IUserAccountDto, IUserTeamDto } from '@Workspace/_generated/interfaces';
import { eUserRole, eEntityStatus } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { AccountController, TeamController } from '@Workspace/_generated/services';
import { Router } from '@angular/router';
import { PageLoaderService, StorageService } from '@Workspace/services';
import { Subject } from 'rxjs';
import { CampaignService } from '../../../services/campaign.service';

@Component({
    selector: 'user-detail',
    templateUrl: 'user-detail.component.html',
    styleUrls: ['user-detail.component.scss']
})

export class UserDetailComponent implements OnInit, OnDestroy {
    constructor(
        public fb: UntypedFormBuilder,
        private authService: AuthService,
        private accountController: AccountController,
        private teamController: TeamController,
        private router: Router,
        private storageService: StorageService,
        private campaignService: CampaignService,
        public messageService : MessageService,
        private pageLoader: PageLoaderService
    ) { }

    @Input() user: IUserCreateDto = null;
    @Input() isFromAccount: boolean;
    @Input() isFromTeam: boolean;
    @Input() header: string;

    @Output() onSaveClick: EventEmitter<any> = new EventEmitter<any>();

    @Input() formGroup: UntypedFormGroup;


    userRoles: SelectItem[] = [];
    accounts: SelectItem[] = [];
    teams: SelectItem[] = [];
    userAccounts: SelectItem[] = [];
    userTeams: SelectItem[] = [];
    accountsSource: SelectItem[] = [];
    isAddingClient: boolean;
    enumUserRoles = eUserRole;
    currentUser = this.authService.userSettings;
    ngUnsubscribe: Subject<void> = new Subject();
    usernames: any;
    emails: any;
    equalEmail: boolean = false;
    equalUserName: boolean = false;
    passwordPattern: string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
    passwordMatch: boolean = false;
    passwordFieldTextType: boolean;
    confirmPasswordFieldTextType: boolean;
    @Input() showRolesDropdowns: boolean = false;

    async ngOnInit() {
        this.isAddingClient = await this.storageService.get(Constants.IS_FROM_ACCOUNT);
        this.formInit();
        this.initLists();
    }

    formInit(): void {
        if (!this.formGroup)
            this.formGroup = this.fb.group({
                firstName: ["", Validators.required],
                lastName: ["", Validators.required],
                email: ["", [Validators.email, Validators.required]],
                phone: [""],
                username: ["", Validators.required],
                password: ["", [Validators.required, Validators.pattern(this.passwordPattern)]],
                confirmPassword: ["", Validators.required],
                sendUserNotification: [false],
                accountRole: ["", Validators.required],
                account: ["", this.isAddingClient && !this.isFromAccount ? Validators.required : null],
                team: [""]
            }); 
    }

    get username() {
        return this.formGroup.get('username').value;
    }

    initLists() {
        this.getTeams();
        this.emails = [];
        this.usernames = [];

        this.accountsSource = !!this.currentUser.accounts ? this.currentUser.accounts.filter(x => !x.isLearfieldCampaign).map(x => { return { label: x.name, value: x.id } }) : null;
        this.userRoles = this.isFromAccount || this.isAddingClient ? LookUps.getUserClientRolesLu() : LookUps.getUserEmployeeRolesLu();
        this.userRoles.unshift({ label: "-", value: null });

        if (!!this.accountsSource && this.accountsSource.length > 1) {
            this.accountsSource.unshift({ label: "-", value: null });
        }
            
        if(!!this.accountsSource && this.accountsSource.length == 1){
            this.formGroup.get('account').disable();
        }

        this.accountController.GetAllUsers().subscribe(x => {
            x.map(y => {
                this.emails.push(y.email);
                this.usernames.push(y.userName);
            });

        });
    }

    initDataFromAccount(formGroup: UntypedFormGroup, header: string, newUser: IUserCreateDto, isFromAccount: boolean){
        this.formGroup=formGroup;
        this.header=header;
        this.user=newUser;
        this.isFromAccount=isFromAccount;
    }

    checkIsValid() {

        if (this.formGroup.controls['password'].errors) {
            return true;
        } else return false;
    }
    onChange(event: any) {
        let email = this.formGroup.get('email').value;

        if (this.emails.find(x => x != null && x.toLowerCase() == email.toLowerCase()))
            this.equalEmail = true;
        else
            this.equalEmail = false;
    }

    onUsernameChange(event: any) {
        let username = this.formGroup.get('username').value;

        if (this.usernames.find(x => x != null &&  x.toLowerCase() == username.toLowerCase()))
            this.equalUserName = true;
        else
            this.equalUserName = false;
    }

    onPasswordChange() {
        if (this.formGroup.controls['confirmPassword'].value != this.formGroup.controls['password'].value)
            this.passwordMatch = false;
        else
            this.passwordMatch = true;
    }

    getTeams() {
        let orderBy = `?$orderby=Name asc`;
        this.teamController.GetTeams(orderBy).subscribe(x => {
            this.teams = x.data.map(t => { return { label: t.name, value: t.id } })
        })
    }

    getUserValues() {
        if (!this.isFromAccount && !this.isFromTeam)
            this.user = {} as IUserCreateDto;

        if (!!this.formGroup.value.username) {
            this.user.fullName = this.formGroup.value.firstName + " " + this.formGroup.value.lastName;
            this.user.userName = this.formGroup.value.username;
            this.user.email = this.formGroup.value.email;
            this.user.phone = this.formGroup.value.phone;
            this.user.password = this.formGroup.value.password;
            this.user.confirmPassword = this.formGroup.value.confirmPassword;
            this.user.roleId = this.isAddingClient || this.isFromAccount ? eUserRole.Client : this.formGroup.value.accountRole;

            this.user.userAccounts = [];
            this.user.userTeams = [];

            if (this.isFromAccount) {
                let userAccount = {} as IUserAccountDto;
                userAccount.sendAccountNotifications = this.formGroup.value.sendUserNotification;
                userAccount.roleId = this.formGroup.value.accountRole;
                userAccount.accountId = !!this.formGroup.value.account ? this.formGroup.value.account : Constants.GuidEmpty;
                userAccount.entityStatus = eEntityStatus.Active;
                this.user.userAccounts.push(userAccount);
            }
            //else if (this.isFromTeam) {
            //     //  this.user.roleId = this.formGroup.value.accountRole;
            //     this.user.userTeams = [];
            //     let userTeam = {} as IUserTeamDto;
            //     userTeam.roleId = this.formGroup.value.accountRole;
            //     this.user.userTeams.push(userTeam);
            // }
        }

        // this.userAccounts.forEach(x => {
        //     var ua = {} as IUserAccountDto;
        //     ua.userId = this.user.id;
        //     ua.accountId = x.value;
        //     ua.entityStatus = eEntityStatus.Active;
        //     if (!this.user.userAccounts.some(a => a.accountId == ua.accountId))
        //         this.user.userAccounts.push(ua);
        // });
        //  if (this.formGroup.value.accountRole != eUserRole.Admin)
        this.userTeams.forEach(x => {
            var ut = {} as IUserTeamDto;
            ut.userId = this.user.id;
            ut.teamId = x.value;
            ut.roleId = this.formGroup.value.accountRole;
            ut.entityStatus = eEntityStatus.Active
            if (!this.user.userTeams.some(a => a.teamId == ut.teamId))
                this.user.userTeams.push(ut);
        });

        if (!!this.formGroup.value.account) {
            var ua = {} as IUserAccountDto;
            ua.userId = this.user.id;
            ua.accountId = this.formGroup.value.account;
            ua.entityStatus = eEntityStatus.Active;
            ua.roleId = this.formGroup.value.accountRole
            ua.sendAccountNotifications = this.formGroup.value.sendUserNotification;
            if (!this.user.userAccounts.some(a => a.accountId == ua.accountId))
                this.user.userAccounts.push(ua);
        }

        return this.user;
    }

    onSave() {
        this.pageLoader.showPageLoader();
        this.getUserValues();

        if (this.isFromAccount || this.isFromTeam)
            this.onSaveClick.emit(this.user);
        else
            this.accountController.CreateUser(this.user).subscribe(async x => {
                await this.authService.reloadUserSettingsAsync();
                this.campaignService.setIsGrid(true);

                this.pageLoader.hidePageLoader();
                if (this.isAddingClient)
                    this.router.navigate(["mogo/accounts"]);
                else
                    this.router.navigate(["mogo/teams"]);
            },
            error => {
                this.pageLoader.hidePageLoader();
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
            });

    }

    onCancel() {
        this.campaignService.setIsGrid(true);
        if (this.isAddingClient)
            this.router.navigate(["mogo/accounts"]);
        else
            this.router.navigate(["mogo/teams"]);

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}