import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { TargetingController } from '@Workspace/_generated/services';
import { IGoogleGeoTargetDto, IGoogleDmaDto, IGeographicTargetingJson, IGeographicDialogDataDto, ISelectItem } from '@Workspace/_generated/interfaces';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, subscribeOn, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'additional-settings-dialog',
    templateUrl: './additional-settings-dialog.component.html',
    styleUrls: ['./additional-settings-dialog.component.scss']
})
export class AdditionalSettingsDialog implements OnInit {

    isVisible: boolean = false;
    isSiteVisitTrackingEnabled: boolean = false;
    isConversionTrackingEnabled: boolean;
    isAdditionalTargetingSegmentsEnabled: boolean;
    defaultValues: any;
    editableFields: boolean[] = [];
    isActivation: boolean;
    isActivatedOutsideOfHub: boolean = false;

    constructor() { }

    ngOnInit() {
    }
    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    private ngUnsubscribe = new Subject();

    openDialog(isSiteVisitTrackingEnabled: boolean, isConversionTrackingEnabled: boolean, isAdditionalTargetingSegmentsEnabled: boolean, editable: boolean[], isActivation?: boolean, isActivatedOutsideOfHub?: boolean) {
        this.isActivation = isActivation;
        if (!isActivation) {
            this.defaultValues = { ...arguments };
            this.isSiteVisitTrackingEnabled = arguments[0];
            this.isConversionTrackingEnabled = arguments[1];
            this.isAdditionalTargetingSegmentsEnabled = arguments[2];
            this.editableFields = arguments[3];
            this.isVisible = true;

        } else {
            this.isActivatedOutsideOfHub = isActivatedOutsideOfHub;
            this.isVisible = true;
        }
    }
    resetDefault() {
        this.isSiteVisitTrackingEnabled = this.defaultValues[0];
        this.isConversionTrackingEnabled = this.defaultValues[1];
        this.isAdditionalTargetingSegmentsEnabled = this.defaultValues[2];
    }
    save() {
            const dialogResult = {
            isSiteVisitTrackingEnabled: this.isSiteVisitTrackingEnabled,
            isConversionTrackingEnabled: this.isConversionTrackingEnabled,
            isAdditionalTargetingSegmentsEnabled: this.isAdditionalTargetingSegmentsEnabled,
            isActivatedOutsideOfHub: this.isActivatedOutsideOfHub
        };
        this.onSave.emit(dialogResult);
        this.isVisible = false;
    }
    cancel() {
        this.isVisible = false;
    }
}