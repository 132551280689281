<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>Demographics</p-header>

    <div *ngIf="!!errorMessage" class="alert alert-danger">
        <b>{{ errorMessage}}</b>
    </div>
    <div class="row mx-auto h-100">
        <div class="left-pane p-0 col-md-4 col-lg-4 col-xs-4">
            <div (click)="openCard(1)" class="selectable p-2 font-weight-bold" [ngClass]="{'selected': currentCard === 1}">Gender</div>
            <div (click)="openCard(2)" class="selectable p-2 font-weight-bold" [ngClass]="{'selected': currentCard === 2}">Age</div>
            <!-- <div (click)="openCard(3)" class="selectable p-2 font-weight-bold" [ngClass]="{'selected': currentCard === 3}">Parental Status</div> -->
            <div (click)="openCard(3)" class="selectable p-2 font-weight-bold" [ngClass]="{'selected': currentCard === 3}">Household Income</div>
        </div>

        <div class="right-pane col-md-8 col-lg-8 col-xs-8">
            <ng-container [ngSwitch]="currentCard">
                <ng-container *ngSwitchCase="1">
                    <ng-container *ngTemplateOutlet="genderTemplate"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <ng-container *ngTemplateOutlet="ageTemplate"></ng-container>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="3">
                    <ng-container *ngTemplateOutlet="parentalStatusTemplate"></ng-container>
                </ng-container> -->
                <ng-container *ngSwitchCase="3">
                    <ng-container *ngTemplateOutlet="householdStatusTemplate"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" [disabled]="!isValid" pButton class="p-button-primary px-3" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>

<ng-template #genderTemplate>
    <!-- <div class="mb-2 p-2 d-flex justify-content-end align-items-center">
        <button pButton (click)="toggleAllGenders(true)" label="Select all" class="mr-2"></button>
        <button pButton (click)="toggleAllGenders(false)" label="Unselect all" class="mr-2"></button>
    </div> -->

    <div *ngFor="let gender of genders;" class="pl-3 row align-items-center pt-2">
        <div class="mr-3">
            <p-checkbox [binary]="true" [(ngModel)]="gender.checked" (onChange)="onGenderOptionClick(gender.value, $event)"></p-checkbox>
        </div>
        <div> {{ gender.label }} </div>
    </div>
</ng-template>


<ng-template #ageTemplate>
    <div class="col-12 mt-2 row">
    </div>

    <div class=" row pt-2 pl-1 p-field-checkbox">
        <p-checkbox [(ngModel)]="isNone" (onChange)="OnUnknownChanged($event)" binary="true" inputId="none"></p-checkbox>
        <label class="pl-2" for="none">No Age Targeting</label>
    </div>
    <div class="d-flex flex-row">


        <div class="col-xs-12 pt-2">

            <p-slider [step]="10" [min]="15" [max]="75" [disabled]="isNone" (onSlideEnd)="handleChange($event)" (onChange)="onChange($event)" orientation="vertical" [(ngModel)]="rangeValues" [range]="true"></p-slider>
        </div>
        <div class=" pl-2 d-flex flex-column">
            <label>65+</label>
            <label>65</label>
            <label>55</label>
            <label>45</label>
            <label>35</label>
            <label>25</label>
            <label>18</label>

        </div>
    </div>
    <div class="row pl-1 pt-1">
        <h6 *ngIf="!isNone">Ages: <label *ngIf="rangeValues[0]== 15">18</label> <label *ngIf="rangeValues[0] != 15">{{rangeValues[0]}}</label> - <label *ngIf="rangeValues[1] == 75">65+</label><label *ngIf="rangeValues[1]!=75">{{rangeValues[1]}}</label></h6>
    </div>
</ng-template>

<ng-template #parentalStatusTemplate>
    <div *ngFor="let status of parentalStatuses;" class="pl-3 row align-items-center pt-2">
        <div class="mr-3">
            <p-checkbox binary=true [(ngModel)]="status.checked" (onChange)="onParentOptionClick(status.value, $event)"></p-checkbox>
        </div>
        <div>{{ status.label }}</div>
    </div>
    <div class="pl-3 row align-items-center pt-2">
        <div class="mr-3">
            <p-checkbox binary=true [(ngModel)]="demographics.parentalStatusUnknown" (onChange)="onParentUnknownOptionClick($event)"></p-checkbox>
        </div>
        <div>Include Unknown Parental Status</div>
    </div>
</ng-template>

<ng-template #householdStatusTemplate>
    <div class=" row pt-2 pl-1 p-field-checkbox">
        <p-checkbox (onChange)="OnRangeChanged($event)" [(ngModel)]="isNoneIncome" binary="true" inputId="none"></p-checkbox>
        <label class="pl-2" for="none">No Household Income Targeting</label>
    </div>
    <div class="d-flex flex-row">
        <div class="col-xs-12 pt-2">
            <p-slider [step]="10" [min]="15" [max]="65" [disabled]="isNoneIncome" (onSlideEnd)="handleIncomeChange($event)" (onChange)="onChangeIncome($event)" orientation="vertical" [(ngModel)]="rangeValuesIncome" [range]="true"></p-slider>
        </div>
        <div class=" pl-2 d-flex flex-column">
            <label class="py-1">Top 10%</label>
            <label class="pb-1">11-20%</label>
            <label class="pb-1">21-30%</label>
            <label class="pb-1">31-40%</label>
            <label class="pb-1">41-50%</label>
            <label class="">Lower 50%</label>
        </div>
    </div>
    <div class="row pl-1 pt-1">
        <!-- <h6 *ngIf="!isNoneIncome">Household Income Range: <label *ngIf="rangeValuesIncome[0]== 15">Lower 50%</label> <label *ngIf="rangeValuesIncome[0] != 15">{{rangeValuesIncome[0]}}</label> - <label *ngIf="rangeValuesIncome[1] == 65">Top 10%</label><label *ngIf="rangeValuesIncome[1]!=65">{{rangeValuesIncome[1]}}</label></h6> -->
    </div>
    <!-- <div *ngFor="let income of incomes" class="pl-3 row align-items-center pt-2">
        <div class="mr-3">
            <p-checkbox binary=true [(ngModel)]="income.checked" (onChange)="onIncomeOptionClick(income.value, $event)"></p-checkbox>
        </div>
        <div> {{ income.label }} </div>
    </div> -->

    <!-- <div class="pl-3 row align-items-center pt-2">
        <div class="mr-3">
            <p-checkbox binary=true [(ngModel)]="demographics.incomeUnknown" (onChange)="onIncomeUnknownOptionClick($event)"></p-checkbox>
        </div>
        <div>Include Unknown Incomes</div>
    </div> -->

</ng-template>