<!-- <div  class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Post Details</strong></span>
</div> -->


<div class="pl-3" class="left-border ml-5">

    <div class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">Additional Details</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId == creativeTypes.Social_Lead_Generation_Premium">
        <div class="col-lg-3 col-12">
            <label><strong>Additional Engagement Element</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" (onChange)="setSweepstakesCategory($event)" *ngIf="!reviewPage && campaign.campaignStatusId == 1" autoWidth="false" [(ngModel)]="creative.detailSocialCampaignJson.additionalEngagementElement" [options]="sweepstakesCategoryDD"></p-dropdown>
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1 " class="mr-0 pr-0">{{sweepstakesCategoryLabel}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId != creativeTypes.Snap_AR">
        <div class="col-lg-3 col-12">
            <label><strong>Campaign Title</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input [ngClass]="creative.detailSocialCampaignJson.campaignTitle == campaign.name ? 'greyText form-control': 'form-control'" *ngIf="!reviewPage && campaign.campaignStatusId == 1 " type="text" [(ngModel)]="creative.detailSocialCampaignJson.campaignTitle" [title]="creative.detailSocialCampaignJson.campaignTitle" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1 " class="mr-0 pr-0">{{creative.detailSocialCampaignJson.campaignTitle}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <div class="row col-lg-12 col-12">
                <label class="mb-0"><strong>Concept and Objective(s)</strong></label>
            </div>
            <div class="row col-lg-12 col-12">
                <span id="spanToolTip">*Please be as detailed as possible.</span>
            </div>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.conceptObjective" [title]="creative.detailSocialCampaignJson.conceptObjective" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.conceptObjective}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId != creativeTypes.Snap_AR && creative.creativeTypeId != creativeTypes.Digital_Game">
        <div class="col-lg-3 col-12">
            <label><strong>Detailed Prize Description</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.detailedPrizeDescription" [title]="creative.detailSocialCampaignJson.detailedPrizeDescription" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.detailedPrizeDescription}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId != creativeTypes.Snap_AR  && creative.creativeTypeId != creativeTypes.Digital_Game">
        <div class="col-lg-3 col-12">
            <label><strong>Total Estimated Prize Value</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.totalEstimatePrizeValue" [title]="creative.detailSocialCampaignJson.totalEstimatePrizeValue" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.totalEstimatePrizeValue}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId == creativeTypes.Content || creative.creativeTypeId == creativeTypes.Digital_Game">
        <div class="col-lg-3 col-12">
            <div class="row col-lg-12 col-12">
                <label class="mb-0"><strong>Content Type</strong></label>
            </div>
            <div class="row col-lg-12 col-12">
                <span id="spanToolTip">(to learn more, but no giveaway)</span>
            </div>
        </div>
        <div class="col-lg-9 col-12">
            <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" (onChange)="setContentType($event)" *ngIf="!reviewPage && campaign.campaignStatusId == 1" autoWidth="false" [(ngModel)]="creative.detailSocialCampaignJson.contentType" [options]="contentTypeDD"></p-dropdown>
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{contentTypeLabel}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="(creative.creativeTypeId == creativeTypes.Content || creative.creativeTypeId == creativeTypes.Digital_Game) && contentTypeLabel == 'Traffic Driver'">
        <div class="col-lg-3 col-12">
            <label><strong>Link</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.contentTypeLink" [title]="creative.detailSocialCampaignJson.contentTypeLink" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.contentTypeLink}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>Creative Theme</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" (onChange)="setCreativeTheme($event)" *ngIf="!reviewPage && campaign.campaignStatusId == 1" autoWidth="false" [(ngModel)]="creative.detailSocialCampaignJson.creativeTheme" [options]="creativeThemeDD"></p-dropdown>
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creativeThemeLabel}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>Notes for Creative/Graphics</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.notesCreativeGraphics" [title]="creative.detailSocialCampaignJson.notesCreativeGraphics" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.notesCreativeGraphics}}</label>
        </div>
    </div>

    <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
        <file-upload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[0].isValid!=null' [isApprove]='isApprove' filetypes="jpg, jpeg, png, gif" [footer]='booleanFalse' [header]="booleanTrue" [headerTitle]="'Sponsor logo (required)'" [campaign]="this.campaign" [creative]="this.creative" [asset]="0"></file-upload>

        <file-upload [reviewPage]='reviewPage' [bulkUpload]="booleanTrue" filetypes="all" [header]="booleanTrue" [campaign]="this.campaign" [creative]="this.creative" [asset]="1"></file-upload>

        <div *ngFor="let item of creative.creativeAssets; let i = index">
            <div *ngIf="!!item.file && !!item.fileName && i>0">
                <label class="my-0 font-weight-bold pl-3 mr-3">{{item.fileName}}</label>
                <i *ngIf="campaign.campaignStatusId == enumCampaignStatus.Assets_Pending" (click)="removeAsset(item)" class="fa fa-trash-alt delete" pTooltip='Remove asset'></i>
            </div>
        </div>


    </div>


</div>