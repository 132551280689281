import { Component, ViewChild, OnInit, Output, EventEmitter  } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { IKeyValuesPairOASDto } from '@Workspace/_generated/interfaces';
import { Subject, BehaviorSubject } from 'rxjs';
import { CampaignController } from '@Workspace/_generated/services';

@Component({
    selector: 'key-value-pair-dialog',
    templateUrl: './key-value-pair-dialog.component.html',
    styleUrls: ['./key-value-pair-dialog.component.scss']
})
export class KeyValuePairDialog implements OnInit {
    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    isVisible: boolean = false;
    keyValuePairSectionId: any;
    keyValuePairPageTemplateId: any;
    header: string;
    keyValuePairsFriendlyValueSection: SelectItem[] = [];
    keyValuePairsFriendlyValuePageTemplate: SelectItem[] = [];
    keyValuePairsFriendlyValue: SelectItem[] = [];
    keyValuesPairsOAS: IKeyValuesPairOASDto[] = [];

    constructor(
        public campaignController: CampaignController,
    ) { }

    ngOnInit() {
        this.campaignController.GetKeyValuesPairsOAS().subscribe(data => {
            this.keyValuesPairsOAS = data;
            this.keyValuePairsFriendlyValueSection = data.filter(x => x.definition == "Sport Section").map(x => { return { value: x.id, label: x.friendlyValues } });
            this.keyValuePairsFriendlyValuePageTemplate = data.filter(x => x.definition == "Page Template").map(x => { return { value: x.id, label: x.friendlyValues } });
            this.keyValuePairsFriendlyValueSection.unshift({ value: null, label: '-' });
            this.keyValuePairsFriendlyValuePageTemplate.unshift({ value: null, label: '-' });
        });
    }
    openDialog(keyValueSection: any, keyValuePageTamplate: any, header: string) {
        this.header = header;
        this.keyValuePairSectionId = keyValueSection;
        this.keyValuePairPageTemplateId = keyValuePageTamplate;
        this.isVisible = true;
    }
    save() {
        const dialogResult = {
            dialogType: 'keyValuePair',
            keyValuePairOASSection : this.keyValuesPairsOAS.find(x => x.id == this.keyValuePairSectionId),
            keyValuePairOASPageTemplate : this.keyValuesPairsOAS.find(x => x.id == this.keyValuePairPageTemplateId),
        };
        this.onSave.emit(dialogResult);
        this.isVisible = false;
    }
    cancel() {
        this.isVisible = false;
    }
}