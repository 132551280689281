import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@Workspace/auth';
import { Functions } from 'libs/common/Functions';


@Pipe({ name: 'dateToUserTimeZone' })
export class DateToUserTimeZonePipe implements PipeTransform {
    constructor(
        private authService: AuthService,
    ) {
    }

    public transform(dateString: string, formatString?: string): string {
        return Functions.dateToUserTimeZone(dateString, this.authService.userSettings.timeZoneJs, formatString);
    }
}