import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    ElementRef,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@Workspace/auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUserPropertiesDto } from '@Workspace/_generated/interfaces';

@Directive({
    selector: '[hasPermission]'
})

export class PermissionDirective implements OnInit {

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private router: Router,
        private authService: AuthService,
    ) {

    }

    private permissions = [];
    private logicalOp = 'AND';
    private isHidden = true;
    private _currentUser: IUserPropertiesDto;
    private _isLogged: boolean = false;

    private ngUnsubscribe: Subject<void> = new Subject();

    @Input()
    set hasPermission(val) {
        this.permissions = val;
        this.updateView();
    }

    @Input()
    set hasPermissionOp(permop) {
        this.logicalOp = permop;
        this.updateView();
    }

    ngOnInit() {
        this.authService.$userState
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(async userState => {
                this._isLogged = this.authService.isLogged;

                if (this._isLogged) {
                    this._currentUser = this.authService.userSettings;
                    this.updateView();
                }
                else {
                    this._currentUser = null;
                    this.updateView();
                }

            });
    }

    private updateView() {
        if (this.checkPermission()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        let hasPermission = false;

        if (this._currentUser && this._currentUser.permissions) {
            for (let checkPermission of this.permissions) {
                let permissionFound = true;
                if (checkPermission.indexOf('!') > -1) {
                    checkPermission = checkPermission.substring(1, checkPermission.length);
                    permissionFound = !this._currentUser.permissions[checkPermission];
                }
                else
                    permissionFound = this._currentUser.permissions[checkPermission];

                if (permissionFound) {
                    hasPermission = true;

                    if (this.logicalOp === 'OR') {
                        break;
                    }
                } else {
                    hasPermission = false;
                    if (this.logicalOp === 'AND') {
                        break;
                    }
                }
            }
        }

        return hasPermission;
    }
}