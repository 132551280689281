import { Component, Input, OnInit } from "@angular/core";
import { IBaseCampaignDto, ICampaignCostsTableDto } from '@Workspace/_generated/interfaces';

@Component({
    selector: 'campaign-costs-table',
    templateUrl: './campaign-costs-table.component.html',
    styleUrls: ['./campaign-costs-table.component.scss']
})
export class CampaignCostsTableComponent implements OnInit {


    @Input('campaignCosts') campaignCosts: ICampaignCostsTableDto = {} as ICampaignCostsTableDto
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() isPrint: boolean;

    totalPerMedia: number;
    totalCost: number;
    minCpm: number=9;
    maxCpm: number=2;
    costsTableValidation: boolean = true;

    ngOnInit(): void {
        this.initialize();
    }

    private initialize() {
        if (!!this.baseCampaign) {
            this.totalPerMedia = this.baseCampaign.amount - (this.baseCampaign.totalDisplayAddOnAmount + this.baseCampaign.totalFacebookAddOnAmount)
            this.totalCost = this.baseCampaign.totalAmount;
            this.campaignCosts.addOnsDisplay = this.baseCampaign.totalDisplayAddOnAmount;
            this.campaignCosts.addOnsFacebook = this.baseCampaign.totalFacebookAddOnAmount;
            this.campaignCosts.addOnsOther = this.baseCampaign.totalOtherAddOnAmount;
            this.campaignCosts.addOnsCreativeFlight = this.baseCampaign.totalCreativeFlightsAddOnAmount;
            this.campaignCosts.buildFee = 0;
            //this.baseCampaign.totalAmount - (this.campaignCosts.addOnsDisplay + this.campaignCosts.addOnsFacebook);
            if(this.campaignCosts.addOnsDisplay < 0 || this.campaignCosts.addOnsFacebook < 0
                || this.campaignCosts.addOnsOther < 0 || this.campaignCosts.addOnsCreativeFlight < 0
            )
                this.costsTableValidation = false;

        }

        if (!this.campaignCosts) {
            this.campaignCosts = {
                mediaPerChannelFacebook: 0,
                mediaPerChannelDisplay: 0,
                addOnsFacebook: 0,
                addOnsDisplay: 0,
                buildFee: 0
            } as ICampaignCostsTableDto;
        }

    }
}