import { OnInit, Component, AfterViewInit } from '@angular/core';

@Component({
    selector: "sign-up-component",
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})

export class SignupComponent implements AfterViewInit { 
    constructor() {

    }
    ngAfterViewInit(): void {
        let body = document.querySelector("body");
        let html = document.querySelector("html");

        body.classList.add("bg-white");
        body.classList.add("h-100");
        html.classList.add("h-100");
    }

}