<div [ngClass]="!isPrint? 'table-wrapper' : ''">
    <div *ngIf="costsTableValidation" [ngClass]="isPrint? 'table-inner-wrapper': 'table-inner-wrapper'">
        <div [ngClass]="!isPrint? 'table-cell-padding-top': ''">
            <!-- <div class="table-cell-border">
                <div class="table-cell-header">Media Per Channel</div>

                <div class="padding-b-1 d-flex justify-content-between align-items-center">
                    <div>Facebook</div>
                    <div>${{ campaignCosts.mediaPerChannelFacebook }}</div>
                </div>

                <div class="padding-b-1 d-flex justify-content-between align-items-center">
                    <div>Display</div>
                    <div>${{ campaignCosts.mediaPerChannelDisplay }}
                    </div>
                </div>
            </div> -->


            <div class="py-2 d-flex justify-content-between align-items-center">
                <div class="table-cell-total">Total MPC Cost</div>
                <div class="table-cell-total-amount">
                    ${{ (baseCampaign.amount - (campaignCosts.addOnsFacebook + campaignCosts.addOnsDisplay + campaignCosts.addOnsOther + campaignCosts.addOnsCreativeFlight))| number:'1.2-2' }}</div>
            </div>
        </div>

        <div [ngClass]="!isPrint ? 'table-cell-padding-middle' : ''">
            <div class="table-cell-border">
                <div class="table-cell-header">Add-Ons</div>

                <div class="padding-b-1 d-flex justify-content-between align-items-center">
                    <div>Facebook</div>
                    <div>${{ campaignCosts.addOnsFacebook | number:'1.2-2' }}</div>
                </div>

                <div class="padding-b-1 d-flex justify-content-between align-items-center">
                    <div>Display</div>
                    <div>${{ campaignCosts.addOnsDisplay | number:'1.2-2'}}</div>
                </div>

                <div class="padding-b-1 d-flex justify-content-between align-items-center">
                    <div>Other</div>
                    <div>${{ campaignCosts.addOnsOther | number:'1.2-2'}}</div>
                </div>

                <div class="padding-b-1 d-flex justify-content-between align-items-center">
                    <div>Creative flights</div>
                    <div>${{ campaignCosts.addOnsCreativeFlight | number:'1.2-2'}}</div>
                </div>
            </div>


            <div class="py-2 d-flex justify-content-between align-items-center">
                <div class="table-cell-total">Total Add-Ons Cost</div>
                <div class="table-cell-total-amount">
                    ${{ (campaignCosts.addOnsFacebook + campaignCosts.addOnsDisplay + campaignCosts.addOnsOther + campaignCosts.addOnsCreativeFlight) | number:'1.2-2' }}</div>
            </div>
        </div>

        <div [ngClass]="!isPrint ? 'table-cell-padding-middle' : ''">
            <div>

                <div class="py-2 d-flex justify-content-between align-items-center">
                    <div class="table-cell-total">Total Build Fee</div>
                    <div class="table-cell-total-amount">${{ baseCampaign.buildFee | number:'1.2-2'}}</div>
                </div>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="costsTableValidation" class="table-total d-flex justify-content-between">
        <div class="text-uppercase">Total Cost</div>
        <div class="text-uppercase">${{ totalCost | number:'1.2-2'}}</div>
    </div>
    <div *ngIf="costsTableValidation" class="table-total justify-content-between" [ngStyle]="{'margin-top': '7px'}">
            <div class="justify-content-between" [ngStyle]="{'display': 'flex'}">
                <div class="text-uppercase">MINIMUM IMPRESSIONS:</div>
                <div class="text-uppercase">{{ (baseCampaign.amount / minCpm * 1000) - (baseCampaign.amount / minCpm * 1000)%1 | number:'1.0-0'}}</div>
            </div>
            <div class="justify-content-between" [ngStyle]="{'display': 'flex', 'margin-top': '-4px'}">
                <div class="text-uppercase">MAXIMUM IMPRESSIONS:</div>
                <div class="text-uppercase">{{ (baseCampaign.amount / maxCpm * 1000) - (baseCampaign.amount / 10 * 1000)%1 | number:'1.0-0'}}</div>
            </div>
            <p [ngStyle]="{'font-size': '11px', 'margin-top': '-4px'}">*Impression amounts are subject to change based on time of year and add-ons selected.</p>
    </div>
    
    
    <div *ngIf="!costsTableValidation" class="invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="px-2 py-1">Something went wrong with costs calculation. Please check did you select everything properly.</span>
    </div>
        
        