import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'campaign-approval',
    templateUrl: './campaign-approval.component.html',
    styleUrls: ['./campaign-approval.component.scss']
})
export class CampaignApprovalComponent implements OnInit, OnDestroy {
    constructor(

        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    campaign: string;
    ngUnsubscribe: Subject<void> = new Subject();

    ngOnInit(): void {
        this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {

            let filter = `?$filter=Id eq ${params.slug}`;
            this.campaignController.GetAllCampaigns(filter).subscribe(data => {
                this.campaign = data.data[0];

            },
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
            });
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}