import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'rc-checkbox',
    templateUrl: './rc-checkbox.component.html',
    styleUrls: ['./rc-checkbox.component.scss']
})
export class RcCheckboxComponent implements OnInit {

    constructor() { }
    ngOnInit() { }

    @Input() checked = false;
    @Input() disabled = false;

    @Output() onClick: EventEmitter<boolean> = new EventEmitter();

    onChange(isChecked) {
        this.checked = isChecked;
        this.onClick.emit(this.checked);
    }
}
