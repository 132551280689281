import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApiErrorResult } from '@Workspace/interfaces';
import { SettingsService, StorageService } from '@Workspace/services';
import { eProviderType } from 'libs/_generated/enums';
import { ILoginModel, IRefreshTokenModel, IUserPropertiesDto } from 'libs/_generated/interfaces';
import * as moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, from, Subject, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { IAuthService } from './iauth.service';

@Injectable()
export class AuthTokenService implements IAuthService {
    private static USER_ACCES_TOKEN_KEY: string = 'accessToken';
    private static USER_ACCES_TOKEN_EXPIRE_KEY: string = 'expires';
    private static USER_REFRESH_TOKEN_KEY: string = 'refreshToken';
    private static USER_REFRESH_TOKEN_EXPIRE_KEY: string = 'refreshTokenExpires';
    private static USER_SETTINGS: string = 'user_Settings';

    private static LOGIN_PATH: string = 'Account/SignIn';
    private static REFRESH_LOGIN_PATH: string = 'Account/RefreshToken';
    public static accesTokenExpiration: Date = new Date();

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private settingsService: SettingsService,
        private router: Router,
        private messageService: MessageService
    ) //@Inject(DOCUMENT) private _document: HTMLDocument
    // private angularFireAuth: AngularFireAuth
    {
        this.headers = this.headers.append('Accept', 'application/json');
    }
    private headers: HttpHeaders = new HttpHeaders();
    private _userAccessToken: string;
    private _userProperties: IUserPropertiesDto = {} as IUserPropertiesDto;
    private _isLogged = false;
    private _$userStateChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private logoutTimeout: any;
    private logoutWarningTimeout: any;
    
    public async GetUserAccessToken() {
        var accessTokenExpiration = await this.storageService.getExpiration(
            AuthTokenService.USER_ACCES_TOKEN_KEY
        );
        if (!!accessTokenExpiration) {
            accessTokenExpiration.setMinutes(
                accessTokenExpiration.getMinutes() - 30
            );
            if (accessTokenExpiration.getTime() < new Date().getTime()) {
                this.refreshToken();
            }
            return this._userAccessToken;
        } else {
            await this.refreshToken();
            return this._userAccessToken;
        }
    }

    public get $userState() {
        return this._$userStateChange.asObservable();
    }

    public get userSettings() {
        return this._userProperties;
    }

    public get isLogged() {
        return this._isLogged;
    }

    public async reloadUserdataAsync() {
        await this.refreshToken();
    }

    public async reloadUserSettingsAsync() {
        await this.postSaveUserProperties(this._userAccessToken);
    }

    async login(username: string, password: string) {
        await this.loginApi(username, password);
    }

    async loginSocial(socialProvider: eProviderType) {
        await this.loginSocialProvider(socialProvider);
    }

    async logOut() {
        await this.storageService.clear();
        clearTimeout(this.logoutWarningTimeout);
        this.messageService.clear();
        await this.reloadLogin();
    }

    async initializeAsync() {
        await this.reloadLogin();
    }

    erroHandler(error: HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                window.location.href = '#/sign-in';
            }
        }
        return throwError(error.message || 'server Error');
    }

    authorizeRequest(request: HttpRequest<any>, next: HttpHandler) {
        return from(this.GetUserAccessToken()).pipe(
            concatMap(apiToken => {
                if (!request.withCredentials || !apiToken) {
                    return next.handle(request);
                }

                const newRequest = request.clone({
                    setHeaders: { Authorization: `Bearer ${apiToken}` }
                });

                return next.handle(newRequest);
            })
        );
    }

    private async loginApi(username: string, password: string) {
        var formData = this.createFormData(username, password);
        var accessToken = await this.postSaveLogin(
            AuthTokenService.LOGIN_PATH,
            formData
        );
        await this.postSaveUserProperties(accessToken);
        await this.reloadLogin();
    }

    private async loginSocialProvider(socialProvider: eProviderType) {
        // let socialProviderId: firebaseAuth.AuthProvider;
        // switch (socialProvider) {
        //     case eProviderType.Google:
        //         socialProviderId = new firebaseAuth.GoogleAuthProvider();
        //         break;
        //     default:
        //         break;
        // }
        // var result = await this.angularFireAuth.auth.signInWithPopup(
        //     socialProviderId
        // );
        // var formData = await this.createFormDataSocial(
        //     socialProvider,
        //     (<any>result.credential).accessToken
        // );
        // var accessToken = await this.postSaveLogin(formData);
        // await this.postSaveUserProperties(accessToken);
        // await this.reloadLogin();
    }

    private createFormData(username: string, password: string): ILoginModel {
        return <any>{
            password: password,
            userName: username
        };
    }

    private async postSaveLogin(
        url: string,
        formData: ILoginModel | IRefreshTokenModel,
        isReferesh: boolean = false
    ): Promise<string> {
        try {
            var response = await this.http
                .post(this.settingsService.createApiUrl(url), formData, {
                    headers: this.headers
                })
                .toPromise();
            var data = response || {};
            this.messageService.clear();
            return await this.saveAndGetAccessToken(data);
        } catch (error) {
            if (isReferesh) {
                this.logOut();
                this.router.navigate(['sign-in']);
                throw error.json();
            }
            switch (error.status) {
                case 400:
                    throw error.json();
                default:
                    throw error;
            }
        }
    }

    private async saveAndGetAccessToken(data) {
        if (!data) {
            return undefined;
        }

        const expire: Date = moment
            .utc(data[AuthTokenService.USER_ACCES_TOKEN_EXPIRE_KEY])
            .toDate();
        const expireRefresh: Date = moment
            .utc(data[AuthTokenService.USER_REFRESH_TOKEN_EXPIRE_KEY])
            .toDate();

        await this.storageService.set(
            AuthTokenService.USER_ACCES_TOKEN_KEY,
            data[AuthTokenService.USER_ACCES_TOKEN_KEY],
            expire
        );
        await this.storageService.set(
            AuthTokenService.USER_REFRESH_TOKEN_KEY,
            data[AuthTokenService.USER_REFRESH_TOKEN_KEY],
            expireRefresh
        );
        this.setLogutWarning();
        this.setLogutTime();
        return data[AuthTokenService.USER_ACCES_TOKEN_KEY];
    }

    private async setLogutWarning(){
        clearTimeout(this.logoutWarningTimeout);
        var accessTokenExpiration = await this.storageService.getExpiration(
            AuthTokenService.USER_ACCES_TOKEN_KEY
        ); 
        accessTokenExpiration.setMinutes(
            accessTokenExpiration.getMinutes() - 3);
        var now = new Date();
        var timeSpan = accessTokenExpiration.getTime() - now.getTime();
        this.logoutWarningTimeout = setTimeout(() => {
            this.messageService.add(
                { 
                    severity: 'warn', 
                    summary: 'Session expiration', 
                    detail: 'You\'re log-in will expire in 2 minutes if you\'re inactive. Please continue using the application to refresh you\'re log-in expiration time.', 
                    life: 120000
                })
            }, timeSpan);
    }

    private async setLogutTime(){
        clearTimeout(this.logoutTimeout);
        var accessTokenExpiration = await this.storageService.getExpiration(
            AuthTokenService.USER_ACCES_TOKEN_KEY
        ); 
        accessTokenExpiration.setMinutes(
            accessTokenExpiration.getMinutes() - 1);
        var now = new Date();
        AuthTokenService.accesTokenExpiration = accessTokenExpiration;
        var timeSpan = accessTokenExpiration.getTime() - now.getTime();
        this.logoutTimeout = setTimeout(() => {
            if (this.storageService.getExpiration(AuthTokenService.USER_ACCES_TOKEN_KEY)) {
                alert('WARNING: Login Expired.');
                this.logOut();   
                this.router.navigate(['sign-in']);
            }
            }, timeSpan);
    }

    private async postSaveUserProperties(token: string): Promise<boolean> {
        var headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + token);
        try {
            var data = await new Promise((resolve, reject) => {
                this.http
                    .get(
                        this.settingsService.createApiUrl(
                            'Account/GetCurrentUserProperties'
                        ),

                        {
                            headers: headers
                        }
                    )
                    .subscribe(
                        response => {
                            resolve(response || {});
                        },
                        (error: IApiErrorResult) => {
                            reject(error);
                        }
                    );
            });
        } catch (error) {
            await this.storageService.clear();
            return false;
        }
        var expiration = await this.storageService.getExpiration(
            AuthTokenService.USER_ACCES_TOKEN_KEY
        );
        await this.storageService.set(
            AuthTokenService.USER_SETTINGS,
            data,
            expiration
        );

        return true;
    }

     private async reloadLogin() {
        var accessToken = await this.storageService.get(
            AuthTokenService.USER_ACCES_TOKEN_KEY
        );
        this._isLogged = !!(await this.storageService.get(
            AuthTokenService.USER_REFRESH_TOKEN_KEY
        ));
        var userProperties: IUserPropertiesDto =
            (await this.storageService.get(AuthTokenService.USER_SETTINGS)) ||
            ({} as IUserPropertiesDto);
        this._userAccessToken = accessToken;
        this._userProperties = userProperties;
        //this.updateTheme();
        this._$userStateChange.next(this._isLogged);
    }

    // private updateTheme() {
    //     if (!this._userProperties.permissions) {
    //         return;
    //     }
    //     if (this._userProperties.permissions.isLearfieldClient) {
    //         document.body.classList.remove("mogo-hub-theme");
    //         document.body.classList.add("fan-365-theme");
    //         this._document.getElementById('appFavicon').setAttribute('href', '/assets/fan365.ico');

    //     }

    //     else {
    //         document.body.classList.add("mogo-hub-theme");
    //         document.body.classList.remove("fan-365-theme");
    //         this._document.getElementById('appFavicon').setAttribute('href', '/assets/mogo.ico');
    //     }
    // }

    private _isRunningRefreshToken: boolean = false;
    private _$refreshTokenProgress = new Subject();
    private refreshToken() {
        return new Promise<void>(resolve => {
            let subscribtion = this._$refreshTokenProgress.subscribe(() => {
                resolve();
                subscribtion.unsubscribe();
                this._isRunningRefreshToken = false;
            });
            if (!this._isRunningRefreshToken) {
                this._isRunningRefreshToken = true;
                this.refreshTokenAsync().then(() => {
                    this._$refreshTokenProgress.next();
                });
            }
        });
    }

    private async refreshTokenAsync() {
        var refreshToken = await this.storageService.get(
            AuthTokenService.USER_REFRESH_TOKEN_KEY
        );
        if (!refreshToken) {
            await this.reloadLogin();
            return;
        }

        var refreshTokenModel: IRefreshTokenModel = {
            refreshToken: refreshToken
        };
        var token = await this.postSaveLogin(
            AuthTokenService.REFRESH_LOGIN_PATH,
            refreshTokenModel,
            true
        );
        await this.postSaveUserProperties(token);
        await this.reloadLogin();
    }
}
