import { Constants } from '@Workspace/common';
import { IPagingResult } from '@Workspace/interfaces';
import { QueryBuilder } from 'odata-query-builder';
import { SelectItem } from 'primeng/api';

export abstract class BaseGridComponent {
    constructor() {}

    public PagingResult: IPagingResult = {
        data: [],
        skip: 0,
        take: Constants.ROWS_PER_PAGE_INITIAL_OPTION,
        totalCount: 0
    };
    public Columns: SelectItem[] = [];
    public FooterText: string;

    protected abstract get sortField(): string;

    protected abstract composeODataQuery(queryBuilder: QueryBuilder): void;

    public composeGridODataQuery(
        event?,
        isFilterReset: boolean = false
    ): string {
        let query = new QueryBuilder()
            .skip(this.getSkip(isFilterReset, event))
            .top(this.getTake(isFilterReset, event))
            .orderBy(this.getOrderBy(this.sortField, event));

        this.composeODataQuery(query);

        return query.toQuery();
    }

    public composeQuery(event?, isFilterReset: boolean = false): string {
        return `?skip=${this.getSkip(isFilterReset, event)}&take=${this.getTake(
            isFilterReset,
            event
        )}`;
    }

    public composeFilterByColumns(): SelectItem[] {
        const result = this.Columns.filter(
            c => !c.label.includes('Id') || !c.label.includes('id')
        );

        return result;
    }

    public composeFooterText(): void {
        if (this.PagingResult.totalCount > 50) {
            const start =
                (this.PagingResult.skip - 1) * this.PagingResult.take + 1;
            let end = start + this.PagingResult.take - 1;

            if (end > this.PagingResult.totalCount) {
                end = this.PagingResult.totalCount;
            }

            this.FooterText = `${start} - ${end} of ${
                this.PagingResult.totalCount
            } items`;

            return;
        }

        if (this.PagingResult.totalCount === 1) {
            this.FooterText = `${this.PagingResult.totalCount} item`;
        } else {
            this.FooterText = `${this.PagingResult.totalCount} items`;
        }
    }

    protected composeFilterById(): string {
        return;
    }

    protected onClearFilterSearch(inputValue: string) {
        if (inputValue === '') {
            this.composeGridODataQuery(null, true);
        }
    }

    protected getSkip(isFilterReset: boolean, event?): number {
        let result = isFilterReset
            ? 0
            : event
            ? event.first
            : this.PagingResult.skip;

        return result;
    }

    protected getTake(isFilterReset: boolean, event?): number {
        let result = isFilterReset
            ? this.PagingResult.take
            : event
            ? event.rows
            : this.PagingResult.take;

        return result;
    }

    protected getOrderBy(baseOrderByColumn: string, event?): string {
        let result = event
            ? event.sortField
                ? event.sortField
                : baseOrderByColumn
            : baseOrderByColumn;

        result +=
            ' ' + (event ? (event.sortOrder === 1 ? 'asc' : 'desc') : 'asc');

        return result;
    }
}
