<div class="col-md-9 mx-auto p-3 bg-white rounded shadow-sm pt-4 border-bottom pb-4">
    
    <div class="col-6 py-2 float-left">
        <h3 class="first">{{title}} Report</h3>

        <div class="mb-2 row align-items-center" *ngIf="currentUser.userRole == enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin || currentUser.userRole == enumUserRole.Client_Solution || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin || currentUser.userRole == enumUserRole.Associate">
            <div class="col-md-4 font-weight-bold">
                Report Visible to Property
            </div>
            <div class="col-md-4">
                <p-inputSwitch [(ngModel)]="isReportVisible"></p-inputSwitch>
            </div>
        </div>
        <div class="mb-2 row align-items-center" *ngIf="currentUser.userRole == enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin || currentUser.userRole == enumUserRole.Client_Solution || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin || currentUser.userRole == enumUserRole.Associate">
            <div class="col-md-8 font-weight-bold reset-default-text">
                <button (click)="saveIsReportVisible()" pButton class="px-3 p-button-primary" style="float: left;" label="Save report visibility"></button>
            </div>
        </div>
    </div> 

    <div class="col-6 py-2 float-right">
        <button *ngIf="reportExist && campaignReportPagingResult.totalCount > 0" (click)="exportToCSV()" pButton class="px-3 p-button-primary" style="float: right;" label="Export to CSV"></button>
    </div>

    <div class="text-muted pt-4 d-flex flex-column col-md-12" *ngIf="!reportExist" style="text-align: center; padding-top: 5em; padding-bottom: 5em;">
        <strong>No report data available.</strong>
    </div>

    <div *ngIf="reportExist" class=" pt-4 d-flex flex-column col-md-12">
        <rc-grid [columns]="options.columns" [value]="campaignReportPagingResult" >
            <ng-template pTemplate="header" let-item>
                <tr>
                    <th *ngFor="let column of options.columns" [ngStyle]="{'width':column.width}" class="p-3">
                        {{column.label}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr *ngIf="i==0" class="highLightColumn">
                    <td class="p-3 font-weight-bold">Total ({{totalStartDate | date : constants.DEFAULT_FORMAT_DATE_US}} - {{totalEndDate | date : constants.DEFAULT_FORMAT_DATE_US}})</td>
                    <td class="p-3 font-weight-bold">{{ totalImpressions | number }}</td>
                    <td class="p-3 font-weight-bold">{{ totalClicks }}</td>
                    <td class="p-3 font-weight-bold">{{ totalCTR}}%</td>
                </tr>
                <tr *ngIf="i>0">
                    <td class="p-3">{{ rowData.date | date : constants.DEFAULT_FORMAT_DATE_US }}</td>
                    <td class="p-3">{{ rowData.impressions | number }}</td>
                    <td class="p-3">{{ rowData.clicks }}</td>
                    <td class="p-3">{{ rowData.ctr}}%</td>
                </tr>
            </ng-template>
        </rc-grid>
        
        <!-- <div *ngIf="totalCount>0" class="text-right">
            {{skipParam ==0 ? 1 : (skipParam * takeParam)+1}} -
            {{((skipParam+1) * takeParam) > totalCount ? totalCount : ((skipParam+1) * takeParam)}}
            of {{totalCount}} items
        </div>
        <rc-paginator [rows]="10" [totalCount]="totalCount" (onPageChange)="paginate($event)">
        </rc-paginator> -->

    </div>
</div>