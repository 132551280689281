<div class="">
    <p-dialog header="Team" class="actions-dialog" [modal]="true" [closable]="false" [(visible)]="displayDialog">
        <div [formGroup]="formGroup">
            <!-- <div class="d-flex col-12 pb-3">
                <div class="col-12 p-0">
                    <div class="col-12 row p-0 my-2">
                        <label class="col-6">
                            Name
                        </label>
                        <input pInputText formControlName="name" class="col-6" />
                    </div>
                </div>
            </div> -->

            <div class="row my-3">
                <div class="col-5">
                    <label>
                        Name
                    </label>
                </div>

                <div class="col-7">
                    <input pInputText formControlName="name" class="w-100" />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <user-picklist [isFromTeam]="true" resource="Team" [targetUsers]="teamUsers"></user-picklist>
                </div>
            </div>
        </div>
        <!-- <user-picklist [isFromTeam]="true" resource="Team" [targetUsers]="teamUsers"></user-picklist> -->

        <p-footer>
            <button type="submit" pButton label="Save" [disabled]="" icon="fa fa-save" class="p-button-primary" (click)="submit()"></button>

            <button type="cancel" pButton label="Cancel" icon="fa fa-times" class="p-button-secondary" (click)="cancel()"></button>
        </p-footer>
    </p-dialog>

</div>