import { Injectable, Output, EventEmitter } from '@angular/core';

import { Message } from 'primeng/api';
import { IApiErrorResult } from '@Workspace/interfaces';

@Injectable()
export class NotificationService {
  constructor() {}

  @Output() public Messages: EventEmitter<Message[]> = new EventEmitter();

  private Msgs: Message[] = [];

  public success(message: string): void {
    this.Msgs = []; // temp fix fot zombie messages reappearing
    this.Msgs.push({ detail: message, severity: 'success' });
    this.Messages.emit(this.Msgs);
  }

  public info(message: string): void {
    this.Msgs = [];
    this.Msgs.push({ detail: message, severity: 'info' });
    this.Messages.emit(this.Msgs);
  }

  public warning(message: string, clearMsg: boolean = true): void {
    if (clearMsg) {
      this.Msgs = [];
    }
    this.Msgs.push({ detail: message, severity: 'warn' });
    this.Messages.emit(this.Msgs);
  }

  public error(message: string): void {
    this.Msgs = [];
    this.Msgs.push({ detail: message, severity: 'error' });
    this.Messages.emit(this.Msgs);
  }

  public errorApi(error: IApiErrorResult) {
    if (!!error.ModelState['']) {
      this.error(error.ModelState[''].join('</br>'));
    }
  }

  public clearMsg(): void {
    this.Msgs = [];
  }
}
