import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'Workspace-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    progressBarValue: Number = 0;

    constructor() {}

    ngOnInit() {
        window.addEventListener('beforeunload', function (event) {
            event.stopImmediatePropagation();
        }); 
    }

    onProgressBarChange(number){
        this.progressBarValue = number;
      }
}
