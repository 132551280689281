import {
    trigger,
    transition,
    style,
    query,
    group,
    animateChild,
    animate,
    keyframes
} from '@angular/animations';

export const fader = trigger('routerAnimation', [
    transition('* <=> *', [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                left: 0,
                width: '100%',
                opacity: 0,
                transform: 'scale(0) translateY(100%)'
            })
        ]),
        query(':enter', [
            animate('600ms ease', style({ opacity: 1, transform: 'scale(1)' }))
        ])
    ])
]);

const optional = { optional: true };

let toTheRight = [
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: '0',
            right: 0,
            width: '100%',
        })
    ], optional),
    query(':enter', [
        style({ right: '-100%', })
    ],optional),
    group([
        query(':leave', [
            animate('300ms ease', style({ right: '100%', }))
        ], optional),
        query(':enter', [
            animate('300ms ease', style({ right: '0%' }))
        ],optional)
    ]),
];
let toTheLeft = [
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: '0',
            left: 0,
            width: '100%',
        })
    ], optional),
    query(':enter', [
        style({ right: '-100%', })
    ],optional),
    group([
        query(':leave', [
            animate('300ms ease', style({ left: '100%', }))
        ], optional),
        query(':enter', [
            animate('300ms ease', style({ left: '0%' })),
        ],optional)
    ]),
]
export const slider = trigger('routeAnimation', [
    transition('* => isLeft', toTheLeft),
    transition('* => isRight', toTheRight),
    transition('isRight => *', toTheRight),
    transition('isLeft => *', toTheLeft)
]);

function slideTo(direction) {
    const optional = { optional: true };
    return [
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    [direction]: 0,
                    width: '100%'
                })
            ],
            optional
        ),
        query(':enter', [style({ [direction]: '-100%' })]),
        group([
            query(
                ':leave',
                [animate('600ms ease', style({ [direction]: '100%' }))],
                optional
            ),
            query(':enter', [
                animate('600ms ease', style({ [direction]: '0%' }))
            ])
        ])
        // Normalize the page style... Might not be necessary

        // Required only if you have child animations on the page
        // query(':leave', animateChild()),
        // query(':enter', animateChild()),
    ];
}

export const componentFadeIn = trigger('componentFadeIn', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in', style({ opacity: 1 }))
    ])
]);
