import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@Workspace/auth';
import { Constants, Functions } from '@Workspace/common';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {
    constructor(
        private authService: AuthService,
    ) {
    }
    transform(value: any, args?: any): any {
        if (value) {
            let currentTime = new Date();
            let timeZone = moment.tz.guess();
            let dateString = Functions.dateToUserTimeZone(value, this.authService.userSettings.timeZoneJs, Constants.DEFAULT_FORMAT_DATETIME);
            let dateInLocalTZ = new Date(Functions.dateToUserTimeZone(value, this.authService.userSettings.timeZoneJs, Constants.DEFAULT_FORMAT_DATETIME));
            const seconds = Math.floor((+ currentTime - + dateInLocalTZ) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Just now';
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}