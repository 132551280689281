import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'pick-list',
    templateUrl: './pick-list.component.html',
    styleUrls: ['./pick-list.component.scss'],
})
export class PickListComponent implements OnInit, OnDestroy {

    constructor() {

    }

    @Input() sourceList: SelectItem[] = [];
    @Input() targetList: SelectItem[] = [];
    @Input() titleTarget: string = "";
    @Input() titleSource: string = "";
    @Output() onSelect: EventEmitter<any> = new EventEmitter();

    @Input() sourcePlaceholder: string = "";
    @Input() targetPlaceholder: string = "";

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }
}