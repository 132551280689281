import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DomHandler } from 'primeng/dom';

@Component({
    selector: 'button-expandable',
    templateUrl: './button-expandable.component.html',
    styleUrls: ['./button-expandable.component.scss'],
    animations: [
        trigger('overlayAnimation', [
            state(
                'void',
                style({
                    transform: 'translateY(5%)',
                    opacity: 0
                })
            ),
            state(
                'visible',
                style({
                    transform: 'translateY(0)',
                    opacity: 1
                })
            ),
            transition('void => visible', animate('{{showTransitionParams}}')),
            transition('visible => void', animate('{{hideTransitionParams}}'))
        ])
    ]
})
export class ButtonExpandableComponent implements OnDestroy {
    constructor(
        public el: ElementRef,
        public renderer: Renderer2,
        public router: Router,
        public cd: ChangeDetectorRef
    ) { }

    @Input()
    set buttonTheme(value: string) {
        if (!!value) {
            this._isBaseTheme = value === 'base';
        }
    }

    @Input() model: MenuItem[];
    @Input() isUserButton: boolean;

    @Input() icon: string;

    @Input() iconPos: string = 'right';

    @Input() label: string;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onDropdownClick: EventEmitter<any> = new EventEmitter();

    @Output() onDropdownItemClick: EventEmitter<any> = new EventEmitter();

    @Output() onChange: EventEmitter<any> = new EventEmitter();

    @Input() style: any;

    @Input() styleClass: string;

    @Input() menuStyle: any;

    @Input() menuStyleClass: string;

    @Input() disabled: boolean;

    @Input() tabindex: number;

    @Input() appendTo: any;

    @Input() dir: string;

    @Input() showTransitionOptions: string = '225ms ease-out';

    @Input() hideTransitionOptions: string = '195ms ease-in';

    @ViewChild('container', { static: false } as any)
    containerViewChild: ElementRef;

    @ViewChild('defaultbtn', { static: false } as any)
    buttonViewChild: ElementRef;

    overlay: HTMLDivElement;

    public overlayVisible: boolean = false;

    public documentClickListener: any;

    public dropdownClick: boolean;
    public isItemClick: boolean;


    public shown: boolean;

    documentResizeListener: any;

    _isBaseTheme: boolean = true;

    onDefaultButtonClick(event: Event) {
        this.onClick.emit(event);
    }

    itemClick(event: Event, item: MenuItem) {
        if (item.disabled) {
            event.preventDefault();
            return;
        }

        if (!item.url) {
            event.preventDefault();
        }

        if (item.command) {
            item.command({
                originalEvent: event,
                item: item
            });
        }

        if (item.command || item.url) {
            this.onDropdownItemClick.emit(item);
            this.overlayVisible = false;
        }
        this.isItemClick = true;
    }

    show() {
        this.overlayVisible = !this.overlayVisible;
    }

    onOverlayAnimationStart(event) {
        switch (event.toState) {
            case 'visible':
                this.overlay = event.element;
                this.appendOverlay();
                this.overlay.style.zIndex = String(++DomHandler.zindex);
                this.alignOverlay();
                this.bindDocumentClickListener();
                this.bindDocumentResizeListener();
                break;

            case 'void':
                this.onOverlayHide();
                break;
        }
    }

    onDropdownButtonClick(event: Event) {
        this.onDropdownClick.emit(event);
        this.dropdownClick = true;
        this.show();
    }

    onChangeSelection(event: Event) {
        this.onChange.emit(event);
    }

    alignOverlay() {
        if (this.appendTo)
            DomHandler.absolutePosition(
                this.overlay,
                this.containerViewChild.nativeElement
            );
        else
            DomHandler.relativePosition(
                this.overlay,
                this.containerViewChild.nativeElement
            );
    }

    appendOverlay() {
        if (this.appendTo) {
            if (this.appendTo === 'body')
                document.body.appendChild(this.overlay);
            else DomHandler.appendChild(this.overlay, this.appendTo);

            this.overlay.style.minWidth =
                DomHandler.getWidth(this.el.nativeElement.children[0]) + 'px';
        }
    }

    restoreOverlayAppend() {
        if (this.overlay && this.appendTo) {
            this.el.nativeElement.appendChild(this.overlay);
        }
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.documentClickListener = this.renderer.listen(
                'document',
                'click',
                () => {
                    if (this.dropdownClick || this.isItemClick) {
                        this.dropdownClick = false;
                        this.isItemClick = false;
                    } else {
                        this.overlayVisible = false;
                        this.unbindDocumentClickListener();
                        this.cd.markForCheck();
                    }
                }
            );
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    bindDocumentResizeListener() {
        this.documentResizeListener = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.documentResizeListener);
    }

    unbindDocumentResizeListener() {
        if (this.documentResizeListener) {
            window.removeEventListener('resize', this.documentResizeListener);
            this.documentResizeListener = null;
        }
    }

    onWindowResize() {
        this.overlayVisible = false;
    }

    onOverlayHide() {
        this.unbindDocumentClickListener();
        this.unbindDocumentResizeListener();
        this.overlay = null;
    }

    ngOnDestroy() {
        this.restoreOverlayAppend();
        this.onOverlayHide();
    }
}
