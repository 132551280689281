import { Component, Input, TemplateRef } from '@angular/core';

import { GridComponent } from '../rc-grid.component';
import { IColumn } from '@Workspace/interfaces';

@Component({
    selector: '[GridBody]',
    templateUrl: './table-body.component.html'
})
export class GridBodyComponent {
    @Input('GridBody') columns: IColumn[];

    @Input('GridBodyTemplate') template: TemplateRef<any>;

    constructor(public dt: GridComponent) { }
}
