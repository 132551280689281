import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectItem, MessageService } from 'primeng/api';
import { LookUps } from '@Workspace/common';
import { IUserDto, IUserCreateDto, IUserAccountDto, IUserTeamDto, IChangePasswordModel } from '@Workspace/_generated/interfaces';
import { eUserRole, eEntityStatus } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { AccountController, TeamController } from '@Workspace/_generated/services';
import { Subject } from 'rxjs';
import { CampaignService } from '../../../services/campaign.service';
import { PageLoaderService } from '@Workspace/services';
import { ChangePasswordFormComponent } from '../change-password-form/change-password-form.component';

@Component({
    selector: 'user-edit-dialog',
    templateUrl: 'user-edit-dialog.component.html',
    styleUrls: ['user-edit-dialog.componet.scss']
})
export class UserEditDialogComponent implements OnInit, OnDestroy {
    constructor(
        public fb: UntypedFormBuilder,
        public accountController: AccountController,
        public campaignService: CampaignService,
        public messageService: MessageService,
        private teamController: TeamController,
        private authService: AuthService,
        private pageLoaderService: PageLoaderService

    ) { }


    @Input() user: IUserCreateDto;
    @Input() displayDialog: boolean = false;
    @Input() dialogText: string = "";
    @Output() userDialogDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSubmit: EventEmitter<object> = new EventEmitter<object>();
    @ViewChild('changePasswordForm') changePasswordForm: ChangePasswordFormComponent;

    formGroup: UntypedFormGroup;
    userRoles: SelectItem[] = [];
    accounts: SelectItem[] = [];
    userAccounts: SelectItem[] = [];
    userTeams: SelectItem[] = [];
    usernames: any;
    emails: any;
    teamsSource: SelectItem[] = [];
    accountsSource: SelectItem[] = [];
    ngUnsubscribe: Subject<void> = new Subject();
    fieldTextType: boolean;
    enumUserRoles = eUserRole;
    equalEmail: boolean = false;
    equalUserName: boolean = false;
    currentUser = this.authService.userSettings;
    dialogUserEmail: string = '';
    dialogUserUsername: string = '';

    passwordPattern: string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
    async ngOnInit() {
        this.formInit();
        await this.initLists();
    }

    selectedCompanyType(event) {

    }

    formInit(): void {
        this.formGroup = this.fb.group({
            firstName: [""],
            lastName: [""],
            email: ["", [Validators.required, Validators.email]],
            phone: [""],
            username: ["", Validators.required],
            password: ["", Validators.pattern(this.passwordPattern)],
            account: this.isClient()? ["", Validators.required] : [""],
            // sendUserNotification: [false],
            accountRole: [""]
        });
        if (!!this.user){
            this.patchFormValue();
            this.dialogUserEmail = this.formGroup.value.email;
            this.dialogUserUsername = this.formGroup.value.username;
        }
    }


    async initLists() {
        this.userTeams = this.user.userTeams.map(x => { return { label: x.teamName, value: x.teamId } });
        await this.getTeams();
        this.userRoles = this.isClient() ? LookUps.getUserClientRolesLu() : LookUps.getUserEmployeeRolesLu();
        this.userRoles.unshift({ label: "-", value: 0 });
        this.accounts = !!this.currentUser.accounts ? this.currentUser.accounts.filter(x => !x.isLearfieldCampaign).map(x => { return { label: x.name, value: x.id } }) : null;
        this.accounts.unshift({ label: "-", value: null });
        this.userAccounts = this.user.userAccounts.map(x => { return { label: x.accountName, value: x.accountId } });
        this.accountsSource = this.accounts.filter(x => !!this.userAccounts.length ? !this.userAccounts.some(a => x.value == a.value) : x);
        this.emails = [];
        this.usernames = [];
        this.accountController.GetAllUsers().subscribe(x => {
            x.map(y => {
                this.emails.push(y.email);
                this.usernames.push(y.userName);
            });
        });
    }

    getTeams() {
        let orderBy = `?$orderby=Name asc`;
        this.teamController.GetTeams(orderBy).subscribe(x => {
            let teams = x.data.map(t => { return { label: t.name, value: t.id } });
            this.teamsSource = teams.filter(x => !!this.userTeams.length ? !this.userTeams.some(a => x.value == a.value) : x);
        })
    }

    getUserValues() {
        this.user.fullName = this.formGroup.value.firstName + " " + this.formGroup.value.lastName;
        this.user.userName = this.formGroup.value.username;
        this.user.email = this.formGroup.value.email;
        this.user.phone = this.formGroup.value.phone;
        if (!!this.formGroup.value.password)
            this.user.password = this.formGroup.value.password;

        if (this.user.roleId == eUserRole.Client) {
            if (!!this.user.userAccounts[0]) {
                this.user.userAccounts[0].roleId = this.formGroup.value.accountRole;
                this.user.userAccounts[0].accountId = this.formGroup.value.account;
            }
            else {
                let userAccount = {} as IUserAccountDto;
                userAccount.roleId = this.formGroup.value.accountRole;
                userAccount.accountId = this.formGroup.value.account;
                userAccount.entityStatus = eEntityStatus.Active;
                this.user.userAccounts.push(userAccount);
            }
        }
        else {
            this.user.roleId = this.formGroup.value.accountRole;

            this.user.userAccounts.forEach(x => {
                if (!this.userAccounts.some(a => a.value == x.accountId))
                    x.entityStatus = eEntityStatus.Deleted;
            });

            this.user.userTeams.forEach(x => {
                if (!this.userTeams.some(a => a.value == x.teamId))
                    x.entityStatus = eEntityStatus.Deleted;
            });

            this.userAccounts.forEach(x => {
                var ua = {} as IUserAccountDto;
                ua.userId = this.user.id;
                ua.accountId = x.value;
                ua.entityStatus = eEntityStatus.Active;
                if (!this.user.userAccounts.some(a => a.accountId == ua.accountId))
                    this.user.userAccounts.push(ua);
            });

            this.userTeams.forEach(x => {
                var ut = {} as IUserTeamDto;
                ut.userId = this.user.id;
                ut.teamId = x.value;
                ut.entityStatus = eEntityStatus.Active
                if (!this.user.userTeams.some(a => a.teamId == ut.teamId))
                    this.user.userTeams.push(ut);
            });
        }

    }

    patchFormValue() {
        this.formGroup.patchValue({
            firstName: this.user.fullName.split(" ")[0],
            lastName: this.user.fullName.split(" ")[1],
            email: this.user.email,
            phone: this.user.phone,
            username: this.user.userName,
            password: this.user.password,
            account: !!this.user.userAccounts[0] ? this.user.userAccounts[0].accountId : null,
            accountRole: !!this.user.userAccounts[0] && this.user.roleId == eUserRole.Client ? this.user.userAccounts[0].roleId : this.user.roleId,
        });
    }

    isClient() {
        if (this.user.roleId == eUserRole.Client)
            return true
        else
            return false;
    }

    onUsernameChange() {
        let username = this.formGroup.get('username').value;

        if (this.usernames.find(x => x != null && x.toLowerCase() == username.toLowerCase()) && username.toLowerCase() != this.dialogUserUsername.toLowerCase())
            this.equalUserName = true;
        else
            this.equalUserName = false;

    }

    onEmaileChange() {
        let email = this.formGroup.get('email').value;

        if (this.emails.find(x => x != null && x.toLowerCase() == email.toLowerCase()) && email.toLowerCase() != this.dialogUserEmail.toLowerCase())
            this.equalEmail = true;
        else
            this.equalEmail = false;

    }

    cancel() {
        this.displayDialog = false;
        this.userDialogDisplay.emit(this.displayDialog);
    }

    onPasswordSaved() {
        this.displayDialog = false;
        this.campaignService.setRefresh(true);
        this.campaignService.setUserSearchParameter("");
        this.pageLoaderService.hidePageLoader();

        this.userDialogDisplay.emit(this.displayDialog);
    }

    submit() {
        this.getUserValues();
        this.pageLoaderService.showPageLoader();
        this.accountController.CreateUser(this.user).subscribe(async x => {
            this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'User updated.' })
            await this.authService.reloadUserSettingsAsync();
            this.pageLoaderService.hidePageLoader();

            if (this.changePasswordForm && !!this.changePasswordForm.formGroup.value.newPassword) {
                this.changePasswordForm.save();
                // let passwordModel = {} as IChangePasswordModel;
                // passwordModel.oldPassword = this.formGroup.value.password;
                // passwordModel.newPassword = this.formGroup.value.password;
                // passwordModel.confirmPassword = this.formGroup.value.password;
                // passwordModel.userId = this.user.id;
                // this.pageLoaderService.showPageLoader();

                // await this.accountController.ChangePassword(passwordModel).subscribe(x => {
                //     this.displayDialog = false;
                //     this.campaignService.setRefresh(true);
                //     this.campaignService.setUserSearchParameter("");
                //     this.pageLoaderService.hidePageLoader();

                //     this.userDialogDisplay.emit(this.displayDialog);

                // })
            } else {
                this.displayDialog = false;
                this.campaignService.setRefresh(true);
                // this.campaignService.setUserSearchParameter("");
                this.userDialogDisplay.emit(this.displayDialog);
            }
        });
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}