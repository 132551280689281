
import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators';
import {
    AccountController
} from '@Workspace/_generated/services';
import { Constants } from '@Workspace/common';
import { IAccountDto } from '@Workspace/_generated/interfaces';
import { SelectItem } from 'primeng/api';
import { AuthService } from '@Workspace/auth';
import { StorageService } from '@Workspace/services';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { takeUntil, map } from 'rxjs/operators';
import { CampaignService } from '../../services/campaign.service';

@Component({
    selector: 'employee-page',
    templateUrl: './employee.page.html',
    styleUrls: ['./employee.page.scss'],


})
export class EmployeePage implements OnInit, OnDestroy {

    constructor(
        public accountController: AccountController,
        public authService: AuthService,
        public route: ActivatedRoute,
        public campaignService: CampaignService,
        private storageService: StorageService,
        private router: Router,
        private activatedRoute: ActivatedRoute


    ) {


        this.gridId = document.URL.indexOf("users") > -1 ? 2 : 1;
    }

    ngUnsubscribe = new Subject();
    accounts: IAccountDto[] = [];
    gridId: number;
    grids: SelectItem[] = [];
    isGrid: boolean = true;
    searchParameter: string;

    showFilterOptions: boolean = true;

    currentUser = this.authService.userSettings;

    @ViewChild("leftTemplate")
    leftInternalHeaderTemplate: TemplateRef<any>;

    @ViewChild("rightTemplate")
    rightInternalHeaderTemplate: TemplateRef<any>;

    ngOnInit() {
        this.campaignService.getIsGrid().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            this.isGrid = x;
        });
        this.campaignService.setUserSearchParameter("");
        if (!this.currentUser.permissions.isClient && this.currentUser.permissions.canManageAccountUsers)
            this.grids = [
                {
                    value: 1,
                    label: 'All Teams'
                },
                {
                    value: 2,
                    label: 'All Users'
                }];

        this.campaignService.setSearchParameter(this.searchParameter);

        // this.gridId = this.grids[0].value;

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                let url: string = event.url;

                if (url.includes("teams/")) {
                    this.showFilterOptions = false;
                }

                else {
                    this.showFilterOptions = true;
                }

                if (!url.includes("users")) {
                    this.gridId = 1;
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.campaignService.setSearchParameter("");
    }

    checkRoute() {
        let visible = !this.route.snapshot.children[0].params.slug && !this.currentUser.permissions.isClient
        return visible;
    }

    onSubmit() { }

    navigateTeam() {
        //this.campaignService.setIsGrid(false);

        this.searchParameter = "";
        this.campaignService.setSearchParameter("");
        this.storageService.set(Constants.IS_FROM_ACCOUNT, false);
        this.router.navigate(['mogo/teams/' + Constants.GuidEmpty]);
    }

    navigateUser() {
        //this.campaignService.setIsGrid(false);

        this.searchParameter = "";
        this.campaignService.setSearchParameter("");
        this.storageService.set(Constants.IS_FROM_ACCOUNT, false);
        this.router.navigate(['mogo/teams/users/' + Constants.GuidEmpty]);


    }

    onChangeGrid(event) {
        this.gridId = event.value;
        this.searchParameter = null;
        this.composeFilter();
        if (event.value == 1)
            this.router.navigate(['mogo/teams']);
        else
            this.router.navigate(['mogo/teams/users']);

    }

    composeFilter() {
        if (this.gridId == 1){
            this.campaignService.setSearchParameter(this.searchParameter);
        }
            
        else
            this.campaignService.setUserSearchParameter(this.searchParameter);


    }
}
