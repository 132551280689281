<div class="top-margin-5 pb-4 d-flex justify-content-center align-items-center">
    <div *ngIf="!currentUser.permissions.isHMPClient" class="h-50 w-50 p-4 mx-auto bg-white">
        <div class="font-huge text-red d-flex justify-content-center">
            <i class="fas fa-check"></i>
        </div>
        <div class="font-big text-red font-weight-bold text-center mb-3">Success</div>
        <div class="text-center mb-3"><b>Your campaign has been submitted for review.</b></div>

        <div *ngIf="currentUser.permissions.isLearfieldClient" class="text-grey text-center">The Fan365 Activation team will review your campaign to make sure everything is ok.</div>
        <div *ngIf="currentUser.permissions.isHSClient" class="text-grey text-center">Our admins will review your campaign to make sure everything is ok.</div>
        <div class="text-grey text-center">Once the campaign is approved, you will receive an email.</div>
        <div *ngIf="currentUser.permissions.isHSClient" class="button-margin d-flex justify-content-center align-items-center">
            <button (click)="openUploadNewCRM()" pButton class="btn flat-btn" label="UPLOAD CRM"></button>
        </div>

        <div [ngClass]="currentUser.permissions.isLearfieldClient ? 'button-margin' : ''" class="d-flex justify-content-center align-items-center">
            <button (click)="goHome()" pButton class="btn flat-btn" [label]="currentUser.permissions.isHSClient? 'EXIT TO HOME' : 'Exit to home'"></button>
        </div>
    </div>
    <div id="printArea" class="h-150 w-50 px-5 pt-5 mx-auto bg-white" *ngIf="currentUser.permissions.isHMPClient">
        <div class="font-huge pb-5 text-red d-flex justify-content-center">
            <img src="../../../../../../assets/Hub Logo@2x.png" />
        </div>
        <div class="h-30 w-40 p-4 mx-auto success-background">

            <div class="font-big text-red font-weight-bold text-center mb-3">Success</div>
            <div class="text-center mb-3"><b>Your campaign has been submitted for review.</b></div>

            <div class="text-grey text-center">Our admins will review your campaign to make sure everything is ok.</div>
            <div class="text-grey text-center">Once the campaign is approved, you will receive an email.</div>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center font-bigger"><b>Order Summary</b> <button id="invisible" type="button" pButton (click)="print(); " [attr.title]="" class="print-btn" label="PRINT ORDER"> </button></div>
        <hr />
        <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-12 mt-3">
                <div class="row justify-content-between mb-3">
                    <div class="col-sm-12 col-md-5 font-weight-bold">Campaign Name</div>
                    <div class="col-sm-12 col-md-7">{{ campaignDto.name }} </div>
                </div>

                <div class="row justify-content-between mb-3">
                    <div class="col-sm-12 col-md-5 font-weight-bold">Campaign Dates</div>
                    <div class="col-sm-12 col-md-7"> <label>{{(campaignDto.startDate) | date : constants.DEFAULT_FORMAT_DATE_US }}</label>
                        - <label>{{(campaignDto.endDate) | date : constants.DEFAULT_FORMAT_DATE_US }}</label></div>
                </div>
                <div class="row justify-content-between mb-3">
                    <div class="col-sm-12 col-md-5 font-weight-bold">Invoice Number</div>
                    <div class="col-sm-12 col-md-7">{{ campaignDto.billingInvoiceNumber }}</div>
                </div>
                <div class="row justify-content-between mb-3">
                    <div *ngIf="paymentData.isCard" class="col-sm-12 col-md-5 font-weight-bold">Credit Card</div>
                    <div *ngIf="!paymentData.isCard" class="col-sm-12 col-md-5 font-weight-bold">Bank Account</div>

                    <div class="col-sm-12 col-md-7">{{ paymentData.paymentData | creditCardMask}}</div>
                </div>

                <div id="invisible" class="mt-5">
                    <a class="link font-weight-bold" (click)="redirectSA()">Mogo's Service Agreement</a>
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12">
                <campaign-costs-table id="visibleOnPrint" [isPrint]="isPrint" [baseCampaign]="campaignDto"></campaign-costs-table>
                <campaign-costs-table id="invisible" [baseCampaign]="campaignDto"></campaign-costs-table>
            </div>
        </div>
        <div id="invisible" class="pr-0 pb-3">
            <div class="d-flex flex-row-reverse flex-wrap bd-highlight mt-3 mb-3">
                <div [ngClass]="'pl-2'" class="py-2 bd-highlight"><button type="button" pButton (click)="openUploadNewCRM()" [attr.title]="" class="p-button-primary" label="UPLOAD CRM"></button></div>

                <div class="py-2 bd-highlight"><button type="button" pButton (click)="createNewCampaign()" [attr.title]="" class="flat-btn" label="CREATE NEW CAMPAIGN"> </button></div>

                <!-- <div class="py-2 pr-2 bd-highlight"><button type="button" pButton class="flat-btn" label="Back"></button></div> -->
                <div class="py-2 pr-2 bd-highlight"><button type="button" pButton class="flat-btn" (click)="cancel()" label="EXIT TO HOME"></button></div>
            </div>
        </div>
    </div>
</div>
<app-upload-crm-modal *ngIf="uploadNewCRMModalState" (closeDialog)="handleCloseDialog()"></app-upload-crm-modal>
