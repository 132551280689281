<div class="card card-body m-3">
    <div class="row align-items-center">
        <div class="col-6">
            <ng-container *ngTemplateOutlet="templateLeft"></ng-container>
        </div>

        <div class="col-6 d-flex justify-content-end">
            <ng-container *ngTemplateOutlet="templateRight"></ng-container>
        </div>
    </div>
</div>