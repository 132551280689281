<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Lead Generation</strong></span>
</div>
<div class="pl-3" [ngClass]="this.currentUser.permissions.isLearfieldClient ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign || (creative.name && this.campaign.account.isHubStandardCampaign)" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="creative.flightStartDate && creative.flightEndDate"> | {{(this.creative.flightStartDate ) | date : constants.DEFAULT_FORMAT_DATE_US}} - {{(this.creative.flightEndDate ) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
        </div>
    </div>

    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div class="mb-4 mt-3 pt-3">
        <div class="row">
            <div class="col-lg-2 col-12">
                <label>News Feed Post:</label>
            </div>
        </div>
        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Headline</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.headline}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=25 [(ngModel)]="creative.detailJson.headline" [title]="creative.detailJson.headline" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <!-- <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{25-creative.detailJson.headline.length}} CHARACTERS LEFT</label> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Body text</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.bodyText[0]}}</label>
                        <div *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=125 [(ngModel)]="creative.detailJson.bodyText[0]" [title]="creative.detailJson.bodyText[0]" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <!-- <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{125-creative.detailJson.postText.length}} CHARACTERS LEFT</label> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>News Feed Link Description</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.linkDescription}}</label>
                        <div *ngIf="!reviewPage" class="input-group">
                            <input class="form-control" type="text" [maxlength]=30 [(ngModel)]="creative.detailJson.linkDescription" [title]="creative.detailJson.linkDescription" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <!-- <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{30-creative.detailJson.linkDescription.length}} CHARACTERS LEFT</label> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-2 col-12">
                <label>Lead Form Fill:</label>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Privacy Policy Link/URL</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input *ngIf="!reviewPage" type="text" class="form-control" [(ngModel)]="creative.detailJson.privacyPolicy" [title]="isLandingUrlOk == false ? titleWrongUrl : creative.detailJson.privacyPolicy" style="overflow: hidden; text-overflow: ellipsis;" [ngClass]="this.isLandingUrlOk == false ? 'border-wrong' : ''" (focusout)="validationLandingPageMessage()" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.privacyPolicy}}</label>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Custom Disclaimer Title</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input *ngIf="!reviewPage" type="text" class="form-control" [(ngModel)]="creative.detailJson.customDisclaimer" [title]="creative.detailJson.customDisclaimer" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.customDisclaimer}}</label>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Headline</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.headline2}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=45 [(ngModel)]="creative.detailJson.headline2" [title]="creative.detailJson.headline2" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <!-- <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{45-creative.detailJson.headline2.length}} CHARACTERS LEFT</label> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" *ngFor="let item of creative.detailJson.copyOption1; let i = index; trackBy:indexTracker">
            <div class="col-lg-2 col-12">
                <label><strong>{{i==0? "Copy Option 1 - Bullets" : ""}}</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label [title]="creative.detailJson.copyOption1[i]" style="overflow: hidden; text-overflow: ellipsis;" *ngIf="reviewPage">{{creative.detailJson.copyOption1[i]}}</label>
                        <div *ngIf="!reviewPage" class="input-group mb-2">
                            <input class="form-control" type="text" [maxlength]=90 [(ngModel)]="creative.detailJson.copyOption1[i]" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <div class="input-group-append align-items-center px-2" [ngClass]="i>0 && this.currentUser.permissions.isClient?'clickDiv':''">
                                <label class=" m-auto" [ngClass]="i>2 && this.currentUser.permissions.isClient?'charLen':''">{{80-creative.detailJson.copyOption1[i].length}} CHARACTERS LEFT</label>
                                <label *ngIf="i>0 && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" (click)="removeBullet(i)" class="delete m-auto"><i class="fas fa-trash-alt"></i></label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.copyOption1}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=80 [(ngModel)]="creative.detailJson.copyOption1" [title]="creative.detailJson.copyOption1" style="overflow: hidden; text-overflow: ellipsis;">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{80-creative.detailJson.copyOption1.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div *ngIf="!reviewPage && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" class="row offset-md-2 mb-2">
            <div class="col-sm-push-2 bd-highlight"><button type="button" pButton (click)="addBullet()" class="flat-btn" label="+ Add Bullet"></button></div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Copy Option 2 - Paragraph</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input *ngIf="!reviewPage" type="text" class="form-control" [(ngModel)]="creative.detailJson.copyOption2" [title]="creative.detailJson.copyOption2" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.copyOption2}}</label>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Button Text</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.buttonText}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=30 [(ngModel)]="creative.detailJson.buttonText" [title]="creative.detailJson.buttonText" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{30-creative.detailJson.buttonText.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- <div class="mb-4 mt-3 pt-3">
        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Destination URL</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input class="form-control" *ngIf="!reviewPage " type="text" [(ngModel)]="creative.detailJson.destinationUrl" [title]="creative.detailJson.destinationUrl" style="overflow: hidden; text-overflow: ellipsis;">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.destinationUrl}}</label>
            </div>
        </div>
    </div> -->

    <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
        <file-upload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[0].isValid!=null' [isApprove]='isApprove' filetypes="jpg, jpeg, mp4" size=1073741824 [footer]='booleanFalse' [campaign]="this.campaign" [creative]="this.creative" [asset]="0"></file-upload>
    </div>

</div>