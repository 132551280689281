import { Component, OnInit, OnDestroy } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { Subject } from 'rxjs';
import { Constants } from '@Workspace/common';
import * as FileSaver from 'file-saver';
import { PageLoaderService, SettingsService } from '@Workspace/services';

@Component({
    selector: 'help-documents',
    templateUrl: './help-documents.component.html',
    styleUrls: ['./help-documents.component.scss']
})
export class HelpDocumentsComponent implements OnInit, OnDestroy {


    constructor(
        private campaignController: CampaignController,
        private pageLoaderService: PageLoaderService,
        private settingsService: SettingsService
    ) {

    }
    constants = Constants;
    ngUnsubscribe: Subject<void> = new Subject();
    refundPolicyShown: boolean;

    ngOnInit(): void {

    }

    redirectPP() {
        // let url: string = this.settingsService.createClientUrl('/#/mogo/privacy-policy');
        // window.open('url', "_blank");
        window.open('https://mogosme.com/company/privacy-policy/#security', "_blank");
    }

    redirectRP() {
        window.open(Constants.REFUND_POLICY_LINK, "_blank");

    }

    download(link: string) {
        this.pageLoaderService.showPageLoader();
        this.campaignController.GetCampaignFile(link, Constants.GuidEmpty)
            .subscribe(
                result => {
                    if (!!result) {
                        let file = this.byteArrayToFileBlob(result as any);
                        var fileURL = window.URL.createObjectURL(file);
                 
                        if (this.getMimeTypeFromBase64(result as any) != null) {
                            window.open(fileURL, '_blank'); 
                        } else {
                            FileSaver.saveAs(file, link);
                        }
                    }
                    this.pageLoaderService.hidePageLoader();
                })

    }
    
    private getMimeTypeFromBase64(base64Data: string): string | null {
        const base64 = base64Data.replace(/^data:[^;]+;base64,/, '');
        const binaryData = atob(base64);
        const magicNumber = binaryData.charCodeAt(0).toString(16) +
          binaryData.charCodeAt(1).toString(16);
      
        switch (magicNumber) {
          case '8950': return 'image/png';
          case '4749': return 'image/gif';
          case '2550': return 'application/pdf';
          case 'ffd8': return 'image/jpeg'; // JPEG, JPG magic number
          // Add more cases for other file types
          default: return null; // Unknown file type
        }
    }

    private byteArrayToFileBlob(base64: string) {
        let binaryString = window.atob(base64);
        const length = binaryString.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        return new Blob([bytes], { type: this.getMimeTypeFromBase64(base64) });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    showHideRefundPolicy(){
        this.refundPolicyShown = !this.refundPolicyShown;
    }

}