            <!-- <app-seleton-loader *ngIf="!loaded" cWidthPc="100" cHeightPx="400"></app-seleton-loader> -->

            <rc-grid #teamTable [options]="options" tableStyleClass="prime-table-font-size" [customSort]="true" [columns]="options.columns" class="common-grid-datatable" [lazy]="true" [expandedRowKeys]="expandedRows" [resizableColumns]="true" columnResizeMode="fit" headerCheckboxToggleAllPages="true" rowExpandMode="single" [paginator]="true" [rows]="10" dataKey="id">
                <ng-template pTemplate="header" let-item>

                    <tr>
                        <th style="width: 3em">

                        </th>
                        <th *ngFor="let column of options.columns" [pSortableColumn]="column.value" [ngStyle]="{ width: column.width + '%' }" [ngClass]="getTextAlignClass(column.align)">
                            <span *ngIf="column.label != 'CheckBox'">
                                {{ column.label }}
                                <p-sortIcon *ngIf="column.label !='Action'" [field]="column.value"></p-sortIcon>

                            </span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-cols let-expanded="expanded" let-rowIndex="rowIndex" class="p-table-scrollable-wrapper errorTooltip">
                    <tr tooltipPosition="top">

                        <td style="width: 3em">
                            <span (click)="teamTable.toggleRow(rowData)">
                                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                            </span>
                        </td>
                        <td class="pr-3">{{ rowData.name }}</td>

                        <td class="p-3 text-right">
                            <i (click)="openEditDialog(rowData)" class="fa fa-edit pr-1">
                            </i>
                            <i (click)="remove(rowData)" class="fa fa-times"></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-item let-columns="options.columns">
                    <tr>

                        <td [attr.colspan]="options.columns.length">
                            <div *ngIf="item" class="col-xs-12">
                                <user-list [team]="item" (onRemove)="composeGridColumns()"></user-list>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </rc-grid>

            <team-edit-dialog [displayDialog]="isEditDialogDisplayed" *ngIf="!!selectedTeam && isEditDialogOpen" (teamDialogDisplay)="showHideDialog($event)" [team]="selectedTeam"></team-edit-dialog>
            <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="teamDelete" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>