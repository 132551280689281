import {Component,OnInit,OnDestroy,Output,EventEmitter} from '@angular/core';
import { IUserDto } from '@Workspace/_generated/interfaces';
import { AccountController } from '@Workspace/_generated/services';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'contact-dialog',
    templateUrl: './contact-dialog.html',
    styleUrls: ['./contact-dialog.scss'],
    providers: []
})
export class ContactDialog implements OnInit, OnDestroy {

    isVisible: boolean=false;
    header: string;
    usersOptions: SelectItem[] = [];
    userDto: IUserDto[] = [];
    selectedUser: string;
    user: IUserDto;
    accountId: string;
    email: string;
    contactInfo: string = null;

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    
    constructor(public accountController: AccountController) {}

    ngOnInit() {
    }

    ngOnDestroy(): void {
    }

    onChange(){
        if(this.selectedUser==null){
            this.email=null;
            this.selectedUser = '';
            this.contactInfo = 'Missing contact.';
        }
        else{
            this.email = this.userDto.filter(x => x.id == this.selectedUser)[0].email;
            this.contactInfo = null;
        }
    }

    openDialog(accountId: string, email:string, contactInfo: string, header: string) {
        this.accountId=accountId;
        if (!!this.accountId) {
            this.accountController.GetUsersByAccount(this.accountId).subscribe(usersDetail => {
                this.userDto = usersDetail;
                this.usersOptions = usersDetail.map(users => {
                    let userOption = {} as SelectItem;
                    userOption.label = users.fullName;
                    userOption.value = users.id;
                    return userOption;
                });
                this.usersOptions.unshift({ value: null, label: '-' });
                this.email = email;
                if(email==null){
                    this.selectedUser='';
                    this.email = null;
                }
                else{
                    this.userDto.forEach(x=>{
                        if(x.email==email){
                            this.selectedUser=x.id;
                            return;
                        }
                    });
                }
            });
        }
        this.contactInfo = contactInfo;
        this.header=header;
        this.isVisible=true;
    }


    save(){
        this.user=null;
        this.userDto.forEach(u=>{
            if(u.id==this.selectedUser){
                this.user=u;
            }
        })
        if (this.header == 'Contact') {
            const dialogResult = {
                dialogType: 'contact',
                user: this.user,
                contactInfo: this.contactInfo
            };
            this.onSave.emit(dialogResult);

        } 
        this.isVisible = false;
    }

    cancel(){
        this.isVisible = false;
    }
}
