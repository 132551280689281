<p-dialog [modal]="true" [(visible)]="isVisible" (onHide)="reset()">
    <p-header>Bulk edit settings ({{step}}/2)</p-header>


    <div *ngIf="step == 2">
        <div class="alert alert-danger my-3 p-1 border-0 rounded-0 text-center text-light orangeReview py-3">
            <p class="h5">Please review </p>
            <p>Before submitting, please check If all the settings are correct.</p>
        </div>

        <div class="text-muted display-3 mb-2">
            Setting summary
        </div>

        <span>You will edit following settings in <strong>{{campaignSettings.campaignBulkSettings.length}} {{ campaignSettings.campaignBulkSettings.length > 1 ? 'campaigns' : 'campaign' }}</strong> ({{regionSort}}).</span>
    </div>


    <span [formGroup]="formGroup">
        <p-tabView>
            <p-tabPanel header="DV360">

                    <!-- Display empty message -->
                    <div *ngIf="step == 2 && (this.formGroup.get('dV360PerformanceGoalType').value == 0 && this.formGroup.get('dV360FcapUnlimited').value == null && this.formGroup.get('dV360BidStrategyPerformanceGoalType').value == 0 && this.formGroup.get('dV360InventorySourceId').value.length == 0)">
                        <span class="text-muted display-3 d-flex justify-content-center">
                            No selected values for DV360
                        </span>
                    </div>

                    <!-- Primary goal -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('dV360PerformanceGoalType').value > 0)">
                        <span class="text-muted display-3">
                            Performance Goal at Campaign and IO level
                        </span>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4">
                                <label>
                                    <strong>Goal</strong>
                                </label>
                            </div>
    
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-8">
                                        <label>What goal would you like to focus on?</label>
                                        <br>
                                        <p-dropdown *ngIf="step == 1" [formControl]="formGroup.controls['dV360PerformanceGoalType']" class="custom-dropdown custom-dd-width d-flex flex-row mr-2" (onChange)="changeGoalType($event, 'DV360')" [options]="performanceGoalTypeDV360List"></p-dropdown>
                                        <strong *ngIf="step == 2">{{enumMapToValue(ePerformanceGoalTypeDV360Name ,this.formGroup.get('dV360PerformanceGoalType').value) }}: {{this.formGroup.get('dV360PerformanceGoalValue').value | number : '1.2-2'}}  {{isPercentage ? '%' : '$' }} </strong>
                                    </div>
                                    <div class="col-4 d-flex flex-column justify-content-end" *ngIf="step == 1">
                                        <div class="p-inputgroup" *ngIf="this.formGroup.get('dV360PerformanceGoalType').value > 0">
                                            <input formControlName="dV360PerformanceGoalValue" type="number" class="custom-input custom-input-padding" pInputText style="border-right: 0;"/>
                                            <button pButton [icon]="isPercentage ? 'fas fa-percent' : 'fas fa-dollar-sign'" class="btn-static p-inputgroup-addon"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    
                    <!-- Frequency caps -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('dV360FcapUnlimited').value != null)">
                        <span class="text-muted display-3">
                            Frequency caps at IO level
                        </span>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4">
                                <label>
                                    <strong>Frequency caps</strong>
                                </label>
                            </div>
    
                            <div class="col-8">
                                <strong *ngIf="step == 2 && this.formGroup.get('dV360FcapUnlimited').value">No Limit</strong>
                                <p-checkbox *ngIf="step == 1" [binary]="true" label="No Limit" formControlName="dv360FcapNoLimit" (onChange)="dv360FcapNoLimitOnChange($event)" ></p-checkbox>
    
                                <div *ngIf="step == 1" class="d-flex align-items-center justify-content-between mt-3">
                                    <p-checkbox [binary]="true" [value]="false" label="Limit frequency to" formControlName="dv360FcapLimited" (onChange)="dv360FcapLimitedOnChange($event)"></p-checkbox>
                                    <p-inputNumber placeholder="15" class="custom-input-number mr-2" formControlName="dV360FcapMaxImpressions" [disabled]="this.formGroup.get('dV360FcapUnlimited').value || this.formGroup.get('dV360FcapUnlimited').value == null"></p-inputNumber>
                                    <span class="">exposures per </span>
                                    <p-inputNumber placeholder="1" class="custom-input-number ml-2" formControlName="dV360FcapTimeUnitCount" [disabled]="this.formGroup.get('dV360FcapUnlimited').value || this.formGroup.get('dV360FcapUnlimited').value == null || this.formGroup.get('dV360FcapTimeUnit').value == eTimeUnit.TIME_UNIT_UNSPECIFIED || this.formGroup.get('dV360FcapTimeUnit').value == eTimeUnit.TIME_UNIT_LIFETIME"></p-inputNumber>
                                    <p-dropdown class="custom-dropdown" [options]="timeUnit" (onChange)="timeUnitOnChange($event);" [formControl]="formGroup.controls['dV360FcapTimeUnit']" [disabled]="this.formGroup.get('dV360FcapUnlimited').value || this.formGroup.get('dV360FcapUnlimited').value == null"></p-dropdown>
                                </div>

                                <div *ngIf="step == 2" class="d-flex align-items-center justify-content-between mt-3">
                                    <strong *ngIf="step == 2 && !this.formGroup.get('dV360FcapUnlimited').value && this.formGroup.get('dV360FcapTimeUnit').value != eTimeUnit.TIME_UNIT_UNSPECIFIED && this.formGroup.get('dV360FcapTimeUnit').value != eTimeUnit.TIME_UNIT_LIFETIME">
                                        Limit frequency to {{this.formGroup.get('dV360FcapMaxImpressions').value}} exposures per {{this.formGroup.get('dV360FcapTimeUnitCount').value}} {{ enumMapToValue(eTimeUnitName ,this.formGroup.get('dV360FcapTimeUnit').value) }}
                                    </strong>
                                    <strong *ngIf="step == 2 && !this.formGroup.get('dV360FcapUnlimited').value && (this.formGroup.get('dV360FcapTimeUnit').value == eTimeUnit.TIME_UNIT_UNSPECIFIED || this.formGroup.get('dV360FcapTimeUnit').value == eTimeUnit.TIME_UNIT_LIFETIME)">
                                        Limit frequency to {{ enumMapToValue(eTimeUnitName ,this.formGroup.get('dV360FcapTimeUnit').value) }}
                                    </strong>
                                </div>
                            </div>
    
                        </div>
                    </div>

                    <!-- Big Strategy -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('dV360BidStrategyPerformanceGoalType').value > 0)">
                        <span class="text-muted display-3">
                            Bid Strategy at IO level
                        </span>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4">
                                <label>
                                    <strong>How would you like to optimize?</strong>
                                </label>
                            </div>
    
                            <div class="col-8">
                                <label>
                                    <span>Automate bid & budget at insertion level</span>
                                </label>
                                <div class="my-2">
                                    <p>Allow system automatically adjust bids and shift budget to better-performing line items. </p>
                                    <p-dropdown *ngIf="step == 1" class="custom-dropdown custom-dd-width d-flex flex-row my-2" (onChange)="bidStrategyOnChange($event)" [options]="dV360BidStrategyPerformanceGoalType" [formControl]="formGroup.controls['dV360BidStrategyPerformanceGoalType']"></p-dropdown>
                                    <p *ngIf="step == 1">while prioritizing spending my full budget (recommended)</p>
                                    <strong *ngIf="step == 2">{{enumMapToValue(eBiddingStrategyPerformanceGoalTypeName ,this.formGroup.get('dV360BidStrategyPerformanceGoalType').value) }} while prioritizing spending my full budget (recommended)</strong>
                                    <p class="alert alert-info my-3 p-1 border-0 rounded-0"><b class="pl-1">Your insertion order may only contain displayand cross-exchange video line items. </b></p>
                                </div>
                                
                                <p-accordion *ngIf="step == 1 && this.formGroup.get('dV360BidStrategyPerformanceGoalType').value > 0"> 
                                    <p-accordionTab class="custom-accordion-tab" header="Additional options">
                                       <div class="align-items-center d-flex flex-row">
                                        <p-checkbox label="Do not exceed average CPM of" [binary]="true" formControlName="dv360AverageCpmCheck" (onChange)="averageCpmOnChange($event)"></p-checkbox>
                                        <div class="p-inputgroup ml-2">
                                            <input type="text" pInputText class="py-3 w-50" style="border-right: 0;" placeholder="1.00" formControlName="dV360MaxAverageCpmBidAmountMicros" *ngIf="averageCpmDisable"/>
                                            <button pButton class="btn-static p-inputgroup-addon d-flex align-items-center" *ngIf="averageCpmDisable" style="color:#DB4726 !important;font-weight: bold;">USD</button>
                                        </div>
                                       </div>
                                    </p-accordionTab>
                                </p-accordion>
                                <strong *ngIf="step == 2 && this.formGroup.get('dV360MaxAverageCpmBidAmountMicros').value > 0">Do not exceed average CPM of {{ this.formGroup.get('dV360MaxAverageCpmBidAmountMicros').value | number : '1.2-2' }} USD</strong>

                            </div>
    
                        </div>
                    </div>
                    
                    <!-- Deal ID -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('dV360InventorySourceId').value.length > 0)">
                        <div class="text-muted display-3 mb-2">
                            Deal ID's at Line Item level
                        </div>
                        <span>Impact only OAS line items</span>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4 d-flex align-items-center">
                                <label class="mb-0">
                                    <strong>Inventory source ID</strong>
                                </label>
                            </div>
    
                            <div class="col-8">
                                <input *ngIf="step == 1" class="custom-input py-2"  type="text" pInputText formControlName="dV360InventorySourceId" />
                                <strong *ngIf="step == 2">{{this.formGroup.get('dV360InventorySourceId').value}}</strong>
                            </div>
                        </div>
                    </div>

            </p-tabPanel>
            <!-- DRUGI TAB -->
            <p-tabPanel header="TTD">

                    <!-- Display empty message -->
                    <div *ngIf="step == 2 && (this.formGroup.get('ttdPrimaryGoal').value == 0 && this.formGroup.get('ttdFrequencyCapUnlimited').value == null && this.formGroup.get('TTDBaseBidCPMAmount').value == 0 && this.formGroup.get('ttdPrivateContractId').value.length == 0)">
                        <span class="text-muted display-3 d-flex justify-content-center">
                            No selected values for TTD
                        </span>
                    </div>

                    <!-- Primary goal -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('ttdPrimaryGoal').value > 0)">
                        <span class="text-muted display-3">
                            Performance goals type at IO level
                        </span>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4">
                                <label>
                                    <strong>Primary Goal</strong>
                                </label>
                            </div>
    
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-8">
                                        <label>Select your primary goal</label>
                                        <br>
                                        <p-dropdown *ngIf="step == 1" class="custom-dropdown custom-dd-width d-flex flex-row mr-2" (onChange)="changeGoalType($event, 'TTD')" [options]="performanceGoalTypeTTDList" [formControl]="formGroup.controls['ttdPrimaryGoal']"></p-dropdown>
                                        <strong *ngIf="step == 2">{{enumMapToValue(ePerformanceGoalTypeTTDName ,this.formGroup.get('ttdPrimaryGoal').value) }}: {{this.formGroup.get('ttdPrimaryGoalTarget').value | number : '1.2-2'}}  {{isPercentage ? '%' : '$' }} </strong>

                                    </div>
                                    <div *ngIf="step == 1" class="col-4">
                                        <label *ngIf="this.formGroup.get('ttdPrimaryGoal').value > 0" >Target</label>
                                        <br>
                                        <div class="p-inputgroup justify-content-end d-flex flex-row" *ngIf="this.formGroup.get('ttdPrimaryGoal').value > 0">
                                            <input type="text" class="custom-input custom-input-padding" formControlName="ttdPrimaryGoalTarget" pInputText style="border-right: 0;"/>
                                            <button pButton [icon]="isPercentage ? 'fas fa-percent' : 'fas fa-dollar-sign'" class="btn-static p-inputgroup-addon" ></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>

                    <!-- Frequency caps -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('ttdFrequencyCapUnlimited').value != null)">
                        <div class="text-muted display-3 mb-2">
                            Frequency Caps at Campaign Level
                        </div>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4">
                                <label>
                                    <strong>Frequency Cap</strong>
                                </label>
                            </div>
    
                            <div class="col-8">
                                <p-checkbox *ngIf="step == 1" [binary]="true" label="No Limit" formControlName="ttdFcapNoLimit" (onChange)="ttdFcapNoLimitOnChange($event)" ></p-checkbox>
                                <strong *ngIf="step == 2 && this.formGroup.get('ttdFrequencyCapUnlimited').value">No Limit</strong>

                                <div *ngIf="step == 1" class="d-flex align-items-center justify-content-between mt-3">
                                    <p-checkbox [binary]="true" [value]="false" label="Limit frequency to" formControlName="ttdFcapLimited" (onChange)="ttdFcapLimitedOnChange($event)"></p-checkbox>
                                    <p-inputNumber placeholder="15" class="custom-input-number mx-2" formControlName="ttdFrequencyCapCount" [disabled]="this.formGroup.get('ttdFrequencyCapUnlimited').value || this.formGroup.get('ttdFrequencyCapUnlimited').value == null"></p-inputNumber>
                                    <span>ads per </span>
                                    <p-inputNumber placeholder="1" class="custom-input-number ml-2" formControlName="ttdResetIntervalInMinutes" [disabled]="this.formGroup.get('ttdFrequencyCapUnlimited').value || this.formGroup.get('ttdFrequencyCapUnlimited').value == null || this.formGroup.get('ttdFrequencyCapTimeUnit').value == eTimeUnitTTD.TTD_LIFETIME"></p-inputNumber>
                                    <p-dropdown class="custom-dropdown" [options]="timeUnitTTD" (onChange)="timeUnitTTDOnChange($event);" [formControl]="formGroup.controls['ttdFrequencyCapTimeUnit']" [disabled]="this.formGroup.get('ttdFrequencyCapUnlimited').value || this.formGroup.get('ttdFrequencyCapUnlimited').value == null" ></p-dropdown>
                                </div>

                                <div *ngIf="step == 2" class="d-flex align-items-center justify-content-between mt-3">
                                    <strong *ngIf="step == 2 && !this.formGroup.get('ttdFrequencyCapUnlimited').value && this.formGroup.get('ttdFrequencyCapTimeUnit').value != eTimeUnitTTD.TTD_LIFETIME">
                                        Limit frequency to {{this.formGroup.get('ttdFrequencyCapCount').value}} exposures per {{this.formGroup.get('ttdResetIntervalInMinutes').value}} {{ enumMapToValue(eTimeUnitTTDName ,this.formGroup.get('ttdFrequencyCapTimeUnit').value) }}
                                    </strong>
                                    <strong *ngIf="step == 2 && !this.formGroup.get('ttdFrequencyCapUnlimited').value && this.formGroup.get('ttdFrequencyCapTimeUnit').value == eTimeUnitTTD.TTD_LIFETIME">
                                        Limit frequency to {{ enumMapToValue(eTimeUnitTTDName ,this.formGroup.get('ttdFrequencyCapTimeUnit').value) }}
                                    </strong>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    
                    <!-- Big Strategy -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('TTDBaseBidCPMAmount').value.length > 0 && this.formGroup.get('ttdMaxBidCPMAmount').value.length > 0)">
                        <span class="text-muted display-3">
                            Bid Strategy at AdGroup level
                        </span>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4 d-flex flex-column justify-content-around">
                                <label>
                                    <strong>Base bid</strong>
                                </label>
                                <label>
                                    <strong>Max bid</strong>
                                </label>
                            </div>
                            <div class="col-8">
                                <div class="p-inputgroup mb-2" style="width: 35%">
                                    <input *ngIf="step == 1" type="text" pInputText class="custom-input w-50" placeholder="1.00" formControlName="TTDBaseBidCPMAmount" style="border-right: 0;"/>
                                    <button *ngIf="step == 1" pButton class="btn-static p-inputgroup-addon d-flex align-items-center" style="color:#DB4726 !important;font-weight: bold;">USD</button>
                                    <strong *ngIf="step == 2">{{this.formGroup.get('TTDBaseBidCPMAmount').value | number : '1.2-2'}} USD</strong>
                                </div>
                                <div class="p-inputgroup" style="width: 35%">
                                    <input *ngIf="step == 1" type="text" pInputText class="custom-input w-50" placeholder="1.00" formControlName="ttdMaxBidCPMAmount" style="border-right: 0;"/>
                                    <button *ngIf="step == 1" pButton class="btn-static p-inputgroup-addon d-flex align-items-center" style="color:#DB4726 !important;font-weight: bold;">USD</button>
                                    <strong *ngIf="step == 2">{{this.formGroup.get('ttdMaxBidCPMAmount').value | number : '1.2-2'}} USD</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Deal ID -->
                    <div *ngIf="step == 1 || (step == 2 && this.formGroup.get('ttdPrivateContractId').value.length > 0)">
                        <div class="text-muted display-3 mb-2">
                            Deal ID's at AdGroup level
                        </div>
                        <span>Impact only OAS line items</span>
    
                        <div class="row mb-3 mt-4">
                            <div class="col-4 align-items-center d-flex">
                                <label class="mb-0">
                                    <strong>Private contract ID</strong>
                                </label>
                            </div>
    
                            <div class="col-8">
                                <input  *ngIf="step == 1" class="custom-input py-2"  type="text" pInputText formControlName="ttdPrivateContractId" />
                                <strong *ngIf="step == 2">{{this.formGroup.get('ttdPrivateContractId').value}}</strong>
                            </div>
    
                        </div>
                    </div>

            </p-tabPanel>

        </p-tabView>
    </span>


    <p-footer>
        <div class="mb-3 d-flex " [ngClass]="step == 1 ? 'justify-content-between' : 'justify-content-end'">
            <button *ngIf="step == 1" (click)="reset()" pButton class="px-3 flat-btn pull-left greenReset" label="Reset"></button>
            <div class="d-flex">
                <button *ngIf="step == 1" (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
                <button *ngIf="step == 2" (click)="cancel()" pButton class="px-3 flat-btn" label="Back"></button>
                <button *ngIf="step == 1" [disabled]="!this.formGroup.dirty || formGroup.invalid" (click)="next()" pButton class="px-3 p-button-primary" label="Next"></button>
                <button *ngIf="step == 2" (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>