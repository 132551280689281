<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>Geographic</p-header>

    <div class="alert alert-warning text-center">
        Geographic targeting must comply with IP usage guidelines set by Learfield.
    </div>
    <div *ngIf="!!errorMessage" class="alert alert-danger">
        <b>{{ errorMessage}}</b>
    </div>

    <div class="row mx-auto h-100">
        <div class="left-pane p-0 col-md-4 col-lg-4 col-xs-4">
            <div (click)="openCard(1)" class="selectable p-2 font-weight-bold" [ngClass]="{'selected': currentCard === 1}">Select Geo</div>
            <div *ngIf="customZip && !isStateChecked" (click)="openCard(2)" class="selectable p-2 font-weight-bold" [ngClass]="{'selected': currentCard === 2}">Bulk Upload Zips</div>
        </div>

        <div class="right-pane col-md-8 col-lg-8 col-xs-8">
            <ng-container [ngSwitch]="currentCard">
                <ng-container *ngSwitchCase="1">
                    <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <ng-template #dropdownTemplate>
        <div class="mb-2 mt-2 row align-items-center">

            <div class="col-md-2">
                <p-checkbox [(ngModel)]="isStateChecked" (onChange)="disableOther($event)" binary="true" inputId="none"></p-checkbox>
            </div>
            <div class="col-md-10 font-weight-bold">
                State targeting
            </div>
        </div>

        <div class="mb-2 row align-items-center">
            <div class="col-md-2 font-weight-bold">
                DMA
            </div>
            <div class="col-md-10">
                <p-multiSelect [options]="dmas" [disabled]='isStateChecked' [(ngModel)]="selectedDmas" [virtualScroll]="yes" itemSize="30" selectedItemsLabel="{0} items selected"></p-multiSelect>
            </div>
        </div>

        <div class="mb-2 row align-items-center">
            <div class="col-md-2 font-weight-bold">
                City
            </div>
            <div class="col-md-10">
                <p-multiSelect [options]="cities" [disabled]='isStateChecked' [(ngModel)]="selectedCities" [virtualScroll]="yes" itemSize="30" selectedItemsLabel="{0} items selected"></p-multiSelect>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="customZip">
            <div class="col-md-2 font-weight-bold">
                Zip Code
            </div>
            <div class="col-md-10">
                <p-multiSelect [options]="zipCodes" [disabled]='isStateChecked' [(ngModel)]="selectedZipCodes" [virtualScroll]="yes" itemSize="30" selectedItemsLabel="{0} items selected"></p-multiSelect>
            </div>
        </div>
    </ng-template>


    <ng-template #customTemplate>
        <div class="mb-2 mt-2">
            <div class="mb-1 font-weight-bold">Type or paste ZIP codes into the text box bellow (comma delimited)</div>
            <textarea rows="3" [(ngModel)]="pastedZipcodes" (ngModelChange)="onTextareaChanged($event)"></textarea>
        </div>

        <div>
            <div class="mb-1 font-weight-bold">Or</div>
            <rc-filedrop [files]="files" [allowedExtensions]="allowedFileExtensions" (onFilesDropped)="parseFile($event)"></rc-filedrop>
        </div>
    </ng-template>

    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>