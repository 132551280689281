import { Component, OnInit, Input, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter, OnChanges } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { eCampaignStatus } from '@Workspace/_generated/enums';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { event } from 'jquery';
import { MenuItem } from 'primeng/api';


@Component({
    selector: 'steps-navigation',
    templateUrl: 'steps-navigation.component.html',
    styleUrls: ['steps-navigation.component.scss']


})
export class StepsNavigationComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    constructor(private el: ElementRef) { }

    items: MenuItem[];
    @Input() activeIndex: number = 0;
    @Input() campaignDto: IBaseCampaignDto;
    @Output() onChangeStep: EventEmitter<any> = new EventEmitter();

    enumCampaignStatus = eCampaignStatus;

    ngOnInit() {
        this.initItems(this.campaignDto);
    }

    ngOnChanges(){

        if(!!this.items && this.items.length>0)
        {
            this.items.forEach(x=>{
                if(this.items.indexOf(x) < this.activeIndex) {
                    x.disabled = false;
                    x.styleClass = 'completed'
                }
                else {
                    x.disabled = true;
                    x.styleClass = ''
                }

                if (this.campaignDto.campaignStatusId != eCampaignStatus.Campaign_Draft && this.items.indexOf(x) < 2) {
                    x.disabled = true;
                }
            });
        }
    }
   
    ngAfterViewInit() {
        document.querySelector(".p-scrollpanel-content").classList.add("stepper-scroll");
    }

    onActiveIndexChange(event: any) {
        
    }

    initItems(baseCampaign){
        this.campaignDto = baseCampaign;
        this.items = [{
            label: '1 Select Package',
            command: (event: any) => {
                this.activeIndex = 0;
                this.onChangeStep.emit({ activeIndex: this.activeIndex = 0, campaign: this.campaignDto});
            },
        },
        {
            label: '2 Add-Ons',
            command: (event: any) => {
                this.activeIndex = 1;
                this.onChangeStep.emit({ activeIndex: this.activeIndex = 1, campaign: this.campaignDto});
            },
            disabled: true,
        },
        {
            label: '3 Upload Assets',
            command: (event: any) => {
                this.activeIndex = 2;
                this.onChangeStep.emit({ activeIndex: this.activeIndex = 2, campaign: this.campaignDto });
            },
            disabled: true,
        },
        {
            label: '4 Review Assets',
            command: (event: any) => {
                this.activeIndex = 3;
                this.onChangeStep.emit({ activeIndex: this.activeIndex = 3, campaign: this.campaignDto });
            },
            disabled: true,
        },
        {
            label: '5 Payment',
            command: (event: any) => {
                this.activeIndex = 4;
                this.onChangeStep.emit({ activeIndex: this.activeIndex = 4, campaign: this.campaignDto });
            },
            disabled: true,
        },
        {
            label: '6 Finish',
            command: (event: any) => {
                this.activeIndex = 5;
            },
            disabled: true,
        }
        ];
        this.ngOnChanges();
    }
    
    ngOnDestroy() {
        document.querySelector(".p-scrollpanel-content").classList.remove("stepper-scroll");
    }
}