
import { Component, OnInit, OnDestroy, ViewChild, ContentChild, AfterContentInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {
    AccountController
} from '@Workspace/_generated/services';
import { Constants } from '@Workspace/common';
import { IAccountDto } from '@Workspace/_generated/interfaces';
import { SelectItem } from 'primeng/api';
import { AuthService } from '@Workspace/auth';
import { StorageService } from '@Workspace/services';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { CampaignService } from '../../services/campaign.service';
import { UserListComponent } from '../../components/user/user-list/user-list.component';
import { AccountListComponent } from '../../components/account/account-list/account-list.component';

@Component({
    selector: 'account-page',
    templateUrl: './account.page.html',
    styleUrls: ['./account.page.scss'],
    viewProviders: [AccountListComponent, UserListComponent]

})
export class AccountPage implements OnInit, OnDestroy {

    constructor(
        public accountController: AccountController,
        public activatedRoute: ActivatedRoute,
        public route: ActivatedRoute,
        public campaignService: CampaignService,
        private authService: AuthService,
        private storageService: StorageService,
        private router: Router,

    ) {
        this.gridId = document.URL.indexOf("users") > -1 ? 2 : 1;
    }

    ngUnsubscribe = new Subject();
    accounts: IAccountDto[] = [];
    gridId: number = 1;
    grids: SelectItem[] = [];
    isGrid: Observable<boolean>;
    searchParameter: string;
    currentUser = this.authService.userSettings;
    constants = Constants;

    ngOnInit() {


        if (this.currentUser.permissions.canManageAccountUsers && !this.currentUser.permissions.isLearfieldClient)
            this.grids = [
                {
                    value: 1,
                    label: 'All Accounts'
                },
                {
                    value: 2,
                    label: 'All Users'
                }];
        this.campaignService.setUserSearchParameter("");

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            let url: string = event.url;

            if (!url.includes("users")) {
                this.gridId = 1;
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.campaignService.setSearchParameter("");
    }

    onSubmit() { }

    checkRoute() {
        let visible = !this.route.snapshot.children[0].params.slug && !this.currentUser.permissions.isClient
        return visible;
    }

    navigateAccount() {
        this.searchParameter = "";
        this.campaignService.setSearchParameter("");
        this.storageService.set(Constants.IS_FROM_ACCOUNT, true);
        this.router.navigate(['mogo/accounts/' + Constants.GuidEmpty]);
    }

    navigateUser() {
        this.searchParameter = "";
        this.campaignService.setSearchParameter("");
        this.storageService.set(Constants.IS_FROM_ACCOUNT, true);
        this.router.navigate(['mogo/accounts/users/' + Constants.GuidEmpty]);
    }

    onChangeGrid(event) {
        this.gridId = event.value;
        if (event.value == 1)
        {
            this.router.navigate(['mogo/accounts']);
            this.searchParameter = null;
            this.composeFilter();
        }
        else
        {
            this.router.navigate(['mogo/accounts/users']);
            this.searchParameter = null;
            this.composeFilter();
        }

    }

    composeFilter() {
        if (this.gridId == 1)
            this.campaignService.setSearchParameter(this.searchParameter);
        else
            this.campaignService.setUserSearchParameter(this.searchParameter);

    }

}
