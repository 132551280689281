import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sign-in-page-component',
    templateUrl: './sign-in.page.html',
    styleUrls: ['./sign-in.page.scss']
})
export class SignInPageComponent implements OnInit {
    constructor(
    ) { }

    ngOnInit() {
    }
}