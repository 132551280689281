import { Component, OnInit, Output, EventEmitter, Input  } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { IBaseCampaignDto, IKeyValuesPairOASDto } from '@Workspace/_generated/interfaces';
import { Subject } from 'rxjs';
import { CampaignController } from '@Workspace/_generated/services';
import { eInventoryTypeOAS } from '@Workspace/_generated/enums';

@Component({
    selector: 'key-value-pair-accordion',
    templateUrl: './key-value-pair-accordion.component.html',
    styleUrls: ['./key-value-pair-accordion.component.scss']
})
export class KeyValuePairAccordion implements OnInit {

    keyValuePairSectionId: any;
    keyValuePairPageTemplateId: any;
    header: string;
    keyValuePairsFriendlyValueSection: SelectItem[] = [];
    keyValuePairsFriendlyValuePageTemplate: SelectItem[] = [];
    keyValuePairsFriendlyValue: SelectItem[] = [];
    keyValuesPairsOAS: IKeyValuesPairOASDto[] = [];
    enumInventoryTypeOAS = eInventoryTypeOAS;

    
    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() disabled: boolean = false;

    constructor(
        public campaignController: CampaignController,
    ) { }

    ngOnInit() {

        this.campaignController.GetKeyValuesPairsOAS().subscribe(data => {
            this.keyValuesPairsOAS = data;
            if(this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium)
            {
                this.keyValuePairsFriendlyValueSection = data.filter(x => x.definition == "Sport Section" && x.season == this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.season).map(x => { return { value: x.id, label: x.friendlyValues } });
                this.keyValuePairsFriendlyValueSection.unshift({ value: null, label: '-' });
            }
            else{
                this.keyValuePairsFriendlyValueSection = data.filter(x => x.definition == "Sport Section").map(x => { return { value: x.id, label: x.friendlyValues } });
                this.keyValuePairsFriendlyValuePageTemplate = data.filter(x => x.definition == "Page Template").map(x => { return { value: x.id, label: x.friendlyValues } });
                this.keyValuePairsFriendlyValueSection.unshift({ value: null, label: '-' });
                this.keyValuePairsFriendlyValuePageTemplate.unshift({ value: null, label: '-' });
            }

        });

        this.loadData(
            this.baseCampaign.keyValuesPairOASSectionId,
            this.baseCampaign.keyValuesPairOASPageTamplateId,
            'OAS site',
        );
    }

    loadData(keyValueSection: any, keyValuePageTamplate: any, header: string) {
        this.header = header;
        this.keyValuePairSectionId = keyValueSection;
        this.keyValuePairPageTemplateId = keyValuePageTamplate;
    }
   
    save() {
        if(this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.inventoryType == eInventoryTypeOAS.Page_Takeover && 
            !!this.keyValuePairPageTemplateId && !!this.keyValuePairSectionId)
        {
            const dialogResult = {
                dialogType: 'keyValuePair',
                keyValuePairOASSection : this.keyValuesPairsOAS.find(x => x.id == this.keyValuePairSectionId),
                keyValuePairOASPageTemplate : this.keyValuesPairsOAS.find(x => x.id == this.keyValuePairPageTemplateId),
            };
            this.onSave.emit(dialogResult);
        }

        else if(this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium && !!this.keyValuePairSectionId)
        {
            const dialogResult = {
                dialogType: 'keyValuePair',
                keyValuePairOASSection : this.keyValuesPairsOAS.find(x => x.id == this.keyValuePairSectionId),
                keyValuePairOASPageTemplate : this.keyValuesPairsOAS.find(x => x.id == this.keyValuePairPageTemplateId),
            };
            this.onSave.emit(dialogResult);
        }
        
    }

}