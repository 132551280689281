import { OnInit, Component, Input } from '@angular/core';

@Component({
    selector: 'info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {
    constructor() { }

    public hasDate = false;

    public _header: string;
    public _content: string;
    public _flipped: boolean;

    @Input('header') set header(value: string) { this._header = value; }
    @Input('content') set content(value: string) { this._content = value; }
    @Input('flipped') set flippedt(value: boolean) { this._flipped = value; }
}