import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { StepsNavigationComponent } from '../../components/steps-navigation/steps-navigation.component';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Constants } from '@Workspace/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignController } from '@Workspace/_generated/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageLoaderService, StorageService } from '@Workspace/services';


@Component({
    selector: 'steps-navigation-page',
    templateUrl: './steps-navigation.page.html',
    styleUrls: ['./steps-navigation.page.scss'],
})

export class StepsNavigationPageComponent implements OnInit, OnChanges, OnDestroy {
    constructor(
        public activatedRoute: ActivatedRoute,
        public campaignController: CampaignController,
        private storageService: StorageService,
        public router: Router

    ) {

    }

    activeIndex: number = 0;
    campaignId: string;
    campaignDto: IBaseCampaignDto = {} as IBaseCampaignDto;
    paymentData: any;
    numberOfCreativeFlightsAmplifyFlex: number;
    ngUnsubscribe = new Subject();

    @ViewChild('stepsNavigationComponent') stepsNavigationComponent: StepsNavigationComponent;

    async ngOnInit() {
        let isEdit = await this.storageService.get("isEdit");
        if (isEdit || document.URL.indexOf(Constants.GuidEmpty) > -1) {
            this.activeIndex = 0;
        } else {
            this.activeIndex = 2;
        }

    }

    ngOnChanges(event: any) {
        if (!!event.activeIndex || event.activeIndex == 0) {
            this.activeIndex = Number(event.activeIndex);
            this.campaignDto = event.campaign;
            this.numberOfCreativeFlightsAmplifyFlex = event.numberOfCreativeFlightsAmplifyFlex;
            if (!!event.paymentData) {
                this.paymentData = event.paymentData;
            }
            if (event.isCampaignPaid)
                this.router.navigate(['/campaign/' + Constants.GuidEmpty]);

        }
        else this.activeIndex = event;
    }

    campaignAdded(event: any) {
        this.campaignDto = event;
    }

    changeStepNavigationItems(baseCampaign){
        this.stepsNavigationComponent.initItems(baseCampaign);
    }

    ngOnDestroy(): void {
        this.storageService.set("isEdit", false);
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

    }
}