    <div class="mb-2 mt-2 row align-items-center">
        <div *ngIf="baseCampaign.campaignType != enumAccountType.Content" class="col-md-12">
            <p-calendar [disabled]="disabled || (baseCampaign.campaignType == enumAccountType.Social && (baseCampaign.creatives[0].creativeTypeId == 28 || baseCampaign.creatives[0].creativeTypeId == 0)) || (baseCampaign.campaignType == enumAccountType.OAS && baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == enumInventoryTypeOAS.Share_Of_Voice)" (onSelect)="onStartDateChange($event)" appendTo="body" [minDate]="minStartDate" [maxDate]="maxStartDate" [(ngModel)]="startDate"></p-calendar> <span class="ml-2 mr-2">-</span>
            <p-calendar [disabled]="(baseCampaign.campaignType == enumAccountType.Social && (baseCampaign.creatives[0].creativeTypeId == 28 || baseCampaign.creatives[0].creativeTypeId == 0)) || (startDate == null || disableEndDate) || disabled || (baseCampaign.campaignType == enumAccountType.OAS && baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == enumInventoryTypeOAS.Share_Of_Voice)" (onSelect)="onEndDateChange($event)" appendTo="body"  [minDate]="minEndDate" [maxDate]="maxEndDate" [(ngModel)]="endDate"></p-calendar>
        </div>
        <div *ngIf="baseCampaign.campaignType == enumAccountType.Content" class="col-md-12">
            <p-calendar [disabled]="disabled" (onSelect)="onLaunchDateChange($event)" appendTo="body" [minDate]="minLaunchDate" [maxDate]="maxLaunchDate" [(ngModel)]="launchDate" view="month" dateFormat="mm/yy" [readonlyInput]="true"></p-calendar>
        </div>
    </div>
    <div class="p-0 col-md-12 mt-2 font-weight-bold">
        All impressions will deliver in full within the date range selected.
    </div>
    <div *ngIf="!!validationMessage" class=" col-md-10 mb-2 mt-2 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">{{validationMessage}}</span>
    </div> 