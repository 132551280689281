import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { on } from '@ngrx/store';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'campaign-detail-page',
    templateUrl: 'campaign-detail-page.html'
})
export class CampaignDetailPageComponent implements OnInit {
    constructor(
        public authService: AuthService
    ) { }

    @Output() onStepTwo: EventEmitter<any> = new EventEmitter();
    @Output() campaignDto: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    currentUser = this.authService.userSettings;

    @Input() baseCampaign: IBaseCampaignDto;
    @Input() showPackage: boolean;
    activeIndex: number = 0;

    ngOnInit() {
    }

    onNextStepTwo(event: number) {
        this.onStepTwo.emit(this.activeIndex = event);
    }
    
    campaginDetailAdded(event: any) {
        this.campaignDto.emit(event);
    }
}