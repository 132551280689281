import { Component } from "@angular/core";

@Component({
    selector: 'app-upload-new-crm',
    templateUrl: './upload-new-crm.page.html',
    styleUrls: ['./upload-new-crm.page.scss']
})

export class UploadNewCrmPage {

}