<h1>MOGOSME SERVICES AGREEMENT (revised 8.25.23)</h1>
<button (click)="openAgreementDoc()" pButton class="px-3 p-button-primary download-btn" label="Download"></button>
<div class="service-agreement-content">
    <p>This Master Services Agreement (“Agreement”) is made and entered into as of today’s date (“Effective Date”) between Mogo Marketing and Media, LLC (“Agency”) 
        and HUB by MogoSME Registered Account Holder (“Client”) regarding the Services (as defined below).</p>
    <div>
        <p>
            <span>
                1. Services. 
            </span> 
            Client hereby retains Agency to perform certain services as set forth 
            in the HUB by MogoSME Package (“SOW”), hereby incorporated by reference 
            and made a part hereof (collectively, the “Services”). The parties generally 
            contemplate that the Services to be rendered by Agency will consist of Agency
            acting as advertising, media planning/buying agency and online ad network for 
            Client. The campaign parameters set forth in the SOW, including but not limited 
            to campaign dates, media budgets, channels, etc., may be modified via email if 
            mutually agreed upon by the parties. Data derived from use of the Services may 
            be used by Agency and its Services providers (i.e. advertising platforms) in 
            aggregated and anonymized form.
        </p>
        <p>
            <span>
                2. Advertising Platform Terms. 
            </span> 
            “Advertising Platform Terms” means the Services 
            providers’ (i.e. advertising platforms) terms and conditions, policies and agreements 
            described in this Section 2 and also including such terms and conditions, policies and 
            agreements from any other advertising platform used by Agency on Client’s behalf. 
            Client agrees and acknowledges that the Advertising Platform Terms may be modified 
            from time to time by the Services provider (e.g. Google, YouTube, Facebook, Twitter, 
            LinkedIn, Snap, TikTok, Spotify, The Trade Desk, Pinterest, Yelp, etc.). Client shall 
            be responsible for regularly reviewing the applicable Advertising Platform Terms and 
            becoming familiar with any such modifications. Client's use of the Services after the 
            applicable Advertising Platform Terms have changed constitutes Client’s acceptance of 
            the new Advertising Platform Terms. Client authorizes Agency to agree to the 
            applicable Advertising Platform Terms online on behalf of Client and make decisions, 
            and take actions relating to the Client’s applicable advertising platform accounts and/or 
            Agency’s own advertising platform accounts with respect to provision of the Services
            on Client’s behalf. All terms and conditions of the Advertising Platform Terms 
            applicable to “Advertiser”, “advertiser”, “Client” and “you” shall be applicable to 
            Client. Client hereby grants Agency the full right and authority to legally bind Client to 
            the applicable Advertising Platform Terms and Client hereby agrees to be bound by the
            applicable Advertising Platform Terms. Client agrees to undertake and agrees to all 
            applicable obligations, representations and warranties contemplated by the Advertising 
            Platform Terms in order to enable Agency to facilitate the provision of the Services for 
            the benefit of Client via the applicable advertising platform(s) pursuant to the 
            Advertising Platform Terms.
        </p>
        <p>
            <span>
                a. Google Terms. 
            </span> 
            Agency’s delivery of the Services to Client via Google shall be 
            subject to the Google Inc. Advertising Program Terms available at 
            <a href="https://adwords.google.com/select/tsandcsfinder">https://adwords.google.com/select/tsandcsfinder</a>, 
            <a href="https://policies.google.com/terms?hl=en">https://policies.google.com/terms?hl=en</a> or otherwise available at google.com and all 
            applicable Google and Google Partner policies referenced therein, including without 
            limitation the Google Privacy Policy, Advertising Cookies Policy, and Trademark 
            Guidelines, and Google and Partner ad specification requirements (collectively, 
            "Google Terms"), which shall constitute Advertising Platform Terms. 
        </p>
        <p>
            <span>
                c. Meta/Facebook Terms. 
            </span> 
            Client shall comply with the following Meta/Facebook 
            terms, conditions, agreements, policies and guidelines, which shall govern delivery of 
            the Services to Client (“FB Terms”): Meta/Facebook Statement of Rights and 
            Responsibilities (<a href="https://www.facebook.com/legal/terms">https://www.facebook.com/legal/terms</a>); Meta/Facebook Pages Terms 
            (<a href="https://www.facebook.com/page_guidelines.php">https://www.facebook.com/page_guidelines.php</a>); Advertising Policies 
            (<a href="https://www.facebook.com/policies/ads/">https://www.facebook.com/policies/ads/</a>); and the FB Terms referenced therein, which 
            shall collectively constitute Advertising Platform Terms.
        </p>
        <p>
            <span>
                d. Meta/Instagram Terms. 
            </span> 
            Agency’s delivery of the Services to Client via 
            Meta/Instagram shall be subject to Meta/Instagram Terms of Use available at
            <a href="https://help.instagram.com/581066165581870">https://help.instagram.com/581066165581870</a> or otherwise available at Instagram.com 
            and all other applicable Meta/Instagram policies, documents, terms and conditions, 
            including without limitation, Meta/Instagram’s Privacy Policy (collectively, "Instagram
            Terms"), which shall constitute Advertising Platform Terms.
        </p>
        <p>
            <span>
                3. Compensation.
            </span> 
            Client shall compensate Agency for the Services by paying 
            Agency fees and expenses in accordance with the applicable SOW. Except as 
            otherwise provided in this Agreement, all orders placed online through Hub by 
            Mogo require payment on order date via credit card or ACH Bank Transfer. Client 
            shall reimburse or pay Agency in advance for all out-of-pocket expenses approved 
            by Client or authorized by this Agreement. Client shall pay for all sales, use, value 
            added, excise, withholding, and other similar taxes required to be paid by law with 
            respect to the Services and expenses incurred in connection with this Agreement, 
            excluding taxes based upon Agency’s income. In the event that Client is required 
            by law to withhold any such taxes from any payments due hereunder, Client shall 
            gross up the payments to ensure that Agency receives and retains the full amount 
            of the compensation set forth on the SOW.
        </p>
        <p>
            <span>
                4. Campaign Budgets.
            </span> 
            Agency purchases advertising inventory and provides 
            comprehensive ad serving Services on Client’s behalf across dozens of display, 
            video, social, search and mobile advertising marketplaces. The pricing model is 
            based on real-time bidding auction environments common to ad networks. These 
            highly complex marketplaces are in a constant state of fluctuation based on supply, 
            demand, seasonality, and audience targeting criteria. Agency’s programmatic 
            buying platform provides visibility as to spend; however, it can be difficult to 
            deliver planned media budgets to the exact dollar. Any HUB by MogoSME
            Package campaign budgets that are under-spent or over-spent will not be 
            reconciled to either Agency or Client. When Agency overspends, there is no 
            expectation for Client to pay the overage, and/or when Agency underspends, there 
            is no expectation for the Agency to refund the Client.. Agency may purchase media 
            on sequential liability terms and subject to the media provider’s standard terms 
            and conditions, so that Agency shall not be responsible for such purchases unless 
            and until Client has paid Agency in full for such purchases. Until such time, Client 
            shall remain solely responsible for such purchases.
        </p>
        <p>
            <span>
                5. Privacy.
            </span>
            Client will ensure that visitors to its websites are provided with clear 
            and comprehensive information about, and consent to, the storing and accessing 
            of cookies or other information on the visitors’ devices where such activity occurs 
            in connection with the Services and where providing such information and 
            obtaining such consent is required by law. Client will ensure that each of its web 
            sites contains a privacy policy that (a) discloses (i) the usage of third-party 
            technology and (ii) the data collection and usage resulting from the Services and 
            use of Client’s websites and (b) complies with all applicable privacy laws, rules 
            and regulations. Client shall comply with all applicable laws, rules and regulations 
            in connection with its use of the Services, including, but not limited to, inclusion 
            of all privacy notices, disclosures, banners and opt outs on its website. To the 
            extent Client receives any retargeting Services, Client represents and warrants that 
            Client’s website (i) contains a privacy policy that (a) permits the operation of a 
            retargeting consumer marketing program, including, but not limited to, the 
            provision of anonymous consumer information and/or consumer email addresses 
            to third party partners and (b) provides a link to the Network Advertising 
            Initiative’s consumer opt-out page located at 
            <a href="www.networkadvertising.org/managing/opt_out.asp">www.networkadvertising.org/managing/opt_out.asp</a> allowing the consumer to 
            opt-out of such retargeting programs and (ii) provides clear and conspicuous 
            disclosure regarding such retargeting practices. Client understands that the 
            technology required to operate the retargeting Services utilizes a pixel (“Pixel”) 
            placed on the Client’s websites to set a cookie on the browser of visitors to the 
            Client’s website. Client will place the Pixel on its website in accordance with 
            Agency’s instructions and Client agrees that it shall not remove, modify or move 
            the Pixel.
        </p>
        <p>
            <span>
                6. Client Obligations.
            </span>
            Client agrees to comply with all reasonable requests of 
            Agency and to provide access to all documents and social media/advertising 
            platform accounts reasonably necessary to the performance of Agency’s duties 
            under this Agreement. Client grants Agency full rights and authority to undertake 
            advertising and media buying Services on behalf of Client and to solicit 
            introductions to Client and its product(s) from any online media outlets. Client 
            represents and warrants that Client has all rights, licenses, consents and 
            agreements necessary for use of any creative content provided by Client for use 
            with the Services (the “Client Creative”). Client represents and warrants that the 
            Client Creative shall not infringe upon or violate the rights of any third party or 
            any applicable law. Client shall be responsible for all materials and information 
            provided by, on behalf of or approved by, Client for use in connection with the 
            Services, including but not limited to, all rights to use such materials, their 
            accuracy and completeness and information regarding its products, services, 
            industry and competitors. Agency may rely upon all decisions and approvals from 
            Client, and Agency shall not be liable for any matter as a result of following 
            Client’s specific instructions. For the avoidance of doubt, Client shall hold all 
            right, title, and interest in and to the Client Creative that are used to create the 
            advertisements to be used in connection with the Services. Certain Services may 
            require Client’s provision of, or access to, its customers’ (i.e. consumers) email 
            addresses and other non-financial personally identifiable information (“Client 
            Information”). Client represents and warrants that Client has provided and will 
            continue to provide all notices and has and will continue to have all rights, consents 
            and approvals under all applicable laws and in compliance with its privacy policy, 
            to provide the Client Information to Agency to be used in connection of provision 
            of Services hereunder, including but not limited to, the provision of proper notice 
            to, and consent obtained from, the individuals whose email addresses are included 
            in the upload for the purpose of certain Services.
        </p>
        <p>
            <span>
                7. Indemnity.
            </span>
            Client agrees to defend, indemnify and hold Agency and its 
            affiliates and their respective members, directors, officers and employees (the 
            “Indemnified Parties”) harmless against any loss, cost or expense the Indemnified 
            Parties may sustain or incur as the result of any claim, suit or proceeding made, 
            brought or threatened against any Indemnified Party arising out of or related to 
            this Agreement. The expenses indemnified against include reasonable attorneys’ 
            fees and costs incurred in any litigation identified above. Client shall maintain, at 
            its own expense, comprehensive general liability insurance and such other 
            insurance policies, and in such amounts, as are generally maintained according to 
            standard industry practices sufficient to cover any claims in connection with its 
            obligations under this Agreement. Client shall present insurance certificates or 
            other proof of coverage to Agency upon written request.
        </p>
        <p>
            <span>
                8. Disclaimers and Limitation of Liability.
            </span>
            TO THE EXTENT PERMITTED 
            UNDER APPLICABLE LAW, THE SERVICES ARE PROVIDED “AS IS” 
            AND AGENCY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, 
            INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF 
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. TO 
            THE EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO 
            CIRCUMSTANCES WILL AGENCY BE LIABLE TO CLIENT OR ANY 
            OTHER PERSON OR ENTITY FOR LOSS OF PROFIT, BUSINESS OR DATA, 
            OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, 
            PUNITIVE DAMAGES OR EXEMPLARY DAMAGES OF ANY KIND OR 
            NATURE ARISING FROM THIS AGREEMENT. TO THE EXTENT 
            PERMITTED UNDER APPLICABLE LAW, AGENCY’S AGGREGATE 
            LIABILITY ARISING UNDER THE AGREEMENT WILL BE LIMITED TO 
            THE TOTAL AMOUNT PAID BY CLIENT TO AGENCY UNDER THE 
            AGREEMENT DURING THE 12 MONTHS BEFORE THE DATE OF SUCH 
            LIABILITY.
        </p>
        <p>
            <span>
                9. Confidentiality.
            </span>
            “Confidential Information” means information that one party 
            discloses to the other party under this Agreement, and that is marked as 
            confidential or would normally be considered confidential information under the 
            circumstances. It does not include information that is independently developed by 
            the recipient, is rightfully given to the recipient by a third party without 
            confidentiality obligations, or becomes public through no fault of the recipient. 
            The recipient will not disclose the discloser’s Confidential Information, except to 
            employees, affiliates, agents, or professional advisors who need to know it and 
            who have agreed in writing (or in the case of professional advisors are otherwise 
            bound) to keep it confidential. The recipient will ensure that those people and 
            entities use the Confidential Information only to exercise rights and fulfill 
            obligations under this Agreement, and that they keep it confidential. The recipient 
            may disclose Confidential Information when legally compelled by a court or other 
            government authority. To the extent permitted by law, recipient will promptly 
            provide the discloser with sufficient notice of all available details of the legal 
            requirement and reasonably cooperate with the discloser’s efforts to challenge the 
            disclosure, seek an appropriate protective order, or pursue such other legal action, 
            as the discloser may deem appropriate.
        </p>
        <p>
            <span>
                10. Non-solicitation.
            </span>
            Each party acknowledges that the other party’s employees 
            are a valuable asset of such party. Accordingly, each party agrees that during this 
            Agreement and for one (1) year thereafter, such party shall not, directly or 
            indirectly, knowingly recruit or solicit, or employ, engage as a consultant, or 
            otherwise retain, any of the other party’s employees who were involved in the 
            performance of the Services. Each party agrees that the other party’s remedy at 
            law for a breach of this section shall be inadequate and therefore the non-breaching 
            party shall be entitled to injunctive relief for such breach, without proof of 
            irreparable injury and without posting bond, in addition to any other right or 
            remedy it may have.
        </p>
        <p>
            <span>
                11. Term and Termination.
            </span>
            This Agreement shall commence on the Effective 
            Date and shall continue for the term outlined in the purchased HUB by MogoSME
            Campaign Package. The Agreement may be terminated by a party for cause with 
            written notice, if a party materially breaches the Agreement and does not cure such 
            breach within thirty (30) days following written notice thereof from the non-breaching party. Termination shall not relieve Client from any obligation to pay 
            Agency the required fees for Services rendered and expenses incurred prior to such 
            termination date, including the payment of the fees set forth in the applicable 
            SOW. Upon the termination or expiration of this Agreement, Agency will, at the 
            request of Client, exercise commercially reasonable efforts to assign all 
            commitments and contracts for media purchases to Client or Client’s assignee. In 
            the case of the assignment of any such commitment or contract, Agency shall be 
            released from any liability in connection with such assignment. Regardless of any 
            termination or expiration of this Agreement, Client shall honor and complete all 
            commitments and contracts, including without limitation the payment of all 
            committed media expenditures, applicable fees, expenses, materials and services 
            obtained from third parties entered into by Agency, as authorized by Client, prior 
            to the date of termination or expiration of this Agreement, for and on behalf of 
            Client, regardless of whether such commitments or contracts are required to be 
            performed before or after the date of such expiration or termination. The 
            provisions of the Agreement, which expressly or by implication are intended to 
            survive termination or expiration, will survive and continue to bind the parties after 
            termination, including but not limited to, the confidentiality, limitations of 
            liability, disclaimer, payment and indemnification provisions.
        </p>
        <p>
            <span>
                12. General Provisions.
            </span>
            The relationship of the parties shall be solely that of 
            agency and independent contractor. This Agreement does not create any 
            partnership, joint venture, single business entity, or employment relationship 
            between the parties. Any notices required to be given under this Agreement by 
            either party to the other shall be in writing and may be given either by personal 
            delivery (including, but not limited to, delivery by messenger) or courier service
            with proof of delivery. Notices delivered personally or via courier shall be deemed 
            effective upon actual delivery. Notices shall be sent to the respective address of 
            each party on the signature page below. This Agreement, including the SOW,
            supersedes any and all prior or contemporaneous agreements, either oral or 
            written, between the parties with respect to the rendering of Services by Agency
            for Client and contains all of the representations, covenants, and agreements 
            between the parties with respect to the rendering of those Services. Each party to 
            this Agreement acknowledges that no representations, inducements, promises or 
            agreements, oral or otherwise, have been made by any party, or by anyone acting 
            on behalf of any party, which are not contained in this Agreement, and that no 
            other agreement, statement or promise not contained in this Agreement shall be 
            valid or binding. Any modification or amendment of this Agreement must be made 
            in writing and signed by both parties except for any modification to the campaign 
            parameters set forth in the SOW, which may be modified via email if mutually 
            agreed upon by the parties. Neither party may assign, transfer or convey any of its 
            rights, duties or interests under this Agreement, nor shall it delegate any 
            obligations or duties it is required to keep or perform under this Agreement, 
            without the express written consent of the other party; provided, however, no such 
            consent shall be required for a party’s assigning this Agreement to an entity owned 
            or controlled by or under common control with such party. Agency may 
            subcontract the performance of Services herein, provided that, it shall remain 
            liable for the acts or omissions of such subcontractors. If any provision of this 
            Agreement is held by a court of competent jurisdiction to be invalid, void, or 
            unenforceable, the remaining provisions shall continue in full force and effect 
            without being impaired or invalidated in any way. In the event of a dispute between 
            the parties to this Agreement, the prevailing party shall be entitled to recover 
            attorneys' fees and costs from the non-prevailing party. Attorneys' fees shall be 
            awarded whether the claim for relief is based on contract law, tort law, or both. 
            This Agreement shall be governed by the laws of the State of California and each 
            party submits to exclusive jurisdiction and venue in the courts located in Marin 
            County, California. A party may submit an electronically executed counterpart via 
            PDF, which will be treated as an originally signed document.
        </p>
        <p>
            IN WITNESS WHEREOF, each party has caused this Agreement to be executed as of the Effective Date.
        </p>
    </div>
</div>