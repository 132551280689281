import { Injectable } from '@angular/core';
import { AuthService } from '@Workspace/auth';
import { SettingsService } from '@Workspace/services';
import { MsalWrapperService } from 'libs/services/auth/msad/msal-wrapper.service';

@Injectable()
export class AppInitializer {
    constructor(
        private settingsService: SettingsService,
        private authService: AuthService,
        private msalWrapperService: MsalWrapperService
    ) {}

    async initializeAsync() {
        await this.settingsService.initializeAsync();
        await this.msalWrapperService.initializeAsync();
        const isAd = await this.authService.getIsAd();

        if (
            (this.settingsService.IsLive || this.settingsService.isQA) &&
            (this.settingsService.IsLearfield || isAd)
        ) {
            this.msalWrapperService.msalService.handleRedirectCallback(
                (authError, response) => {
                    if (authError) {
                        console.error(
                            'Redirect Error: ',
                            authError.errorMessage
                        );
                        return;
                    }

                    console.log('Redirect Success: ', response.accessToken);
                }
            );
        }

        await this.authService.initializeAsync();
    }
}
