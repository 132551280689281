import { Injectable } from '@angular/core';
import { SettingsService } from 'libs/services/settings/settings.service';
import { BroadcastService } from './broadcast.service';
import { MsalService } from './msal.service';

@Injectable()
export class MsalWrapperService {
    constructor(
        private settingsService: SettingsService,
        private broadcastService: BroadcastService
    ) {}

    public msalService: MsalService;

    public async initializeAsync() {
        this.msalService = new MsalService(
            this.settingsService,
            this.broadcastService
        );
    }
}
