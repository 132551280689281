import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';

@Injectable()
export class ProgressBarService {
    private progressBar = new BehaviorSubject({});

    public get isPageLoading(): Observable<any>{
        return this.progressBar.asObservable(); 
    }

    public showProgressBar(value: number): void {
        this.progressBar.next({visible: true, progressBar: value});
    }

    public hideProgressBar(): void {
        this.progressBar.next({visible: false, progressBar: 0});
    }
}
