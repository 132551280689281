import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { eAccountType, eCampaignStatus, eChannelType, eStandardCampaginChannel, eUserRole} from '@Workspace/_generated/enums';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IDetailJson} from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Constants, Functions } from '@Workspace/common';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CreativeSwapsDialog } from '../../../../../shared-core/components/creative-swaps-dialog/creative-swaps-dialog';
import { AuthService } from '@Workspace/auth';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { FileUpload } from 'apps/core/src/app/modules/shared-core/components/file-upload/file-upload.component';

@Component({
    selector: 'display',
    templateUrl: './display.component.html',
    styleUrls: ['./display.component.scss'],
    providers: [DatePipe, DecimalPipe]
})
export class DisplayComponent implements OnInit, OnDestroy {
    constructor(
        private cardItemService: CartItemService,
        private authService: AuthService,
        private dateTime: DatePipe,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() landingPage: string;
    @Input() validationLandingUrlMessageText: string;
    @Input() premiumCreative: ICreativeDto;
    @Input() accordionName: string="";

    @ViewChild('creativeSwaps') creativeSwaps: CreativeSwapsDialog;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    @Output() onCreativetDelete: EventEmitter<any> = new EventEmitter();
    @Output() onDateChange: EventEmitter<any> = new EventEmitter();
    @Output() uploadValidationMessage: EventEmitter<any> = new EventEmitter();
    isPreviewOpen: boolean;
    currentIndex: number;
    //creativeAssetsForPreview: ICreativeAssetDto[] = [];
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isUploadOk: boolean = true;
    isLandingUrlOk: boolean = true;
    constants = Constants;
    currentUser = this.authService.userSettings;
    creativeName: string;
    _creativeAssets: ICreativeAssetDto[] = [];
    campaignDates = '';
    enumCampaignStatus = eCampaignStatus;
    enumAccountType = eAccountType;
    isOnlyFlight:boolean = true;
    functions = Functions;
    maxNumberOfFlights: number = 1;
    acceptedBannerSizes: string = "160x600, 300x250, 300x600, 320x50, 728x90, 970x66, 970x90, 970x250";

    ngOnInit() {

        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));
        //this.campaign.creatives.sort((x, y) => { return y.creativeGroup - x.creativeGroup })[0].creativeGroup;

        if(this.campaign.creatives.some(x=> x.creativeGroup > 1))
            this.isOnlyFlight = false;

        if (this.creative.creativeAssets.some(x => x.width == 160 && x.height == 600)) {
            this.creative.creativeAssets.filter(x => x.width == 160 && x.height == 600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 300 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 300 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 728 && x.height == 90)) {
            this.creative.creativeAssets.filter(x => x.width == 728 && x.height == 90).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 300 && x.height == 600)) {
            this.creative.creativeAssets.filter(x => x.width == 300 && x.height == 600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 50)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 50).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 100)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 100).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 90)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 90).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 66)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 66).forEach(el => {
                this._creativeAssets.push(el);
            });
        }
        
        if (this.creative.creativeAssets.some(x => !this._creativeAssets.includes(x)))
        {
            this.creative.creativeAssets.filter(x => !this._creativeAssets.includes(x)).forEach(el => {
                this._creativeAssets.push(el);
            });
        }
        
        this.creative.creativeAssets = this._creativeAssets;

        this.creativeName = this.creative.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner ?
            (this.campaign.campaignType == eAccountType.FAN365 && !this.campaign.creatives.some(c => c.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner) && this.campaign.campaignInventoryItems[0].inventoryItemNew?.premiumCreative>0 ?
                "Display Banner" : "Standard Display Banner") :
            (this.creative.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner ? "Abandoned Cart Display Banner" :
                (this.creative.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner ? "Post Purchase Upsell Display Banner" : ''));

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                //this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId &&  x.creativeGroup === this.creative.creativeGroup);
                this.isUploadOk = true;

                this.isLandingUrlOk = true;
                if (!!!data.creatives) {
                    return;
                }
                this.creative.creativeAssets.forEach(ca => {
                    if (!!this.landingPage && !!ca.file) {

                        ca.imageUrl = this.landingPage;
                        ca.isLandingPageExist = true;
                        this.isLandingUrlOk = true;
                    }
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                    if (ca.isLandingPageExist != null || ca.isLandingPageExist != undefined)
                        this.isLandingUrlOk = this.isLandingUrlOk && ca.isLandingPageExist;
                });
            });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }
    checkIsFileUploaded() {
        if (this.creative.creativeAssets.some(x => x.fileAdditional != null && x.file == null)) {

            return true;
        }
        else {
            return false;
        }
    }

    validationLandingPageMessage(event: any) {
        this.validationLandingUrlMessageText = event;
    }

    delete(){
        this.creativeSwaps.openDialog(
            this.campaign, this.creative.creativeGroup, 'Remove');
    }

    edit(){
        if(this.creativeSwaps){

            this.creativeSwaps.openDialog(
                this.campaign, this.creative.creativeGroup, 'Edit');
        }
    }

    editCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged) {
            this.creative.flightStartDate = event.startDate;
        }
        if (event.isEndDateChanged) {
            this.creative.flightEndDate = event.endDate;
        }

        this.creative.creativeName = event.creativeName;

        this.campaignDates = `${this.formatDate(
            (this.campaign.startDate)
        )} - ${this.formatDate(this.campaign.endDate)}`;
    }

    removeCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged || event.isEndDateChanged) {
            this.onCreativetDelete.emit({ baseCampaign: event.baseCampaign, creativeGroup: this.creative.creativeGroup });
        }
    }

    formatDate(dateProp: any) {
        var date = new Date(dateProp);
        return `${this.dateTime.transform(dateProp, this.constants.DEFAULT_FORMAT_DATE_US)}`;
    }

    addCreativeAsset(){
        if (this.creative.creativeAssets.length < 8) {

            let newAsset = {} as ICreativeAssetDto;
            newAsset.id = Constants.GuidEmpty;
            newAsset.width = 0;
            newAsset.height = 0;
            newAsset.isValid = null;
            newAsset.creativeId = this.creative.id;
            newAsset.fileType = null;
            newAsset.fileSize = 0;
            newAsset.imageUrl = null;
            newAsset.fileName = null;
            newAsset.url = null;
            newAsset.isChanged = null;
            newAsset.isUploadValid = null;
            newAsset.isDeleted = null;

            if (newAsset.detailJson == null) {
                newAsset.detailJson = {} as IDetailJson;

                newAsset.detailJson.headline = '';
                newAsset.detailJson.bodyText = [];
                newAsset.detailJson.bodyText.push('');
                newAsset.detailJson.linkDescription = '';
                newAsset.detailJson.landingPageUrl = '';
                newAsset.detailJson.facebookPageUrl = '';
                newAsset.detailJson.videoType = 0;
                // newAsset.detailJson.destinationUrl = '';
                newAsset.detailJson.campaignTitle = '';
                newAsset.detailJson.advertiser = '';
                newAsset.detailJson.companyName = '';
                newAsset.detailJson.shortTitle = '';
                newAsset.detailJson.longTitle = '';
                newAsset.detailJson.shortDescription = '';
                newAsset.detailJson.longDescription = '';
                newAsset.detailJson.callToAction = '';
                newAsset.detailJson.headline2 = '';
                newAsset.detailJson.description = '';
                newAsset.detailJson.path1 = '';
                newAsset.detailJson.path2 = '';
                newAsset.detailJson.displayUrl = '';
                newAsset.detailJson.adGroup = '';

            }
            this.creative.creativeAssets.push(newAsset);
        }
    }

    removeAsset(asset: ICreativeAssetDto) {
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights))
        {
            let index = this.creative.creativeAssets.indexOf(asset);
            this.creative.creativeAssets.splice(index, 1);
        }
    }

    uploadWarning(message: string){
        this.uploadValidationMessage.emit(message);
    }

    addAssetValidation(){
        return this.campaign.campaignType == eAccountType.FAN365 
            && !this.campaign.creatives.some(c => c.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner) 
            // && this.campaign.campaignInventoryItems[0].inventoryItemNew?.premiumCreative>0 && !this.reviewPage && this.creative.creativeAssets.length<8 
            && Functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights);
    }

    assetHeaderTitle(i: number){
        return (this.campaign.campaignType == eAccountType.FAN365 && !this.campaign.creatives.some(c => c.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner) && this.campaign.campaignInventoryItems[0].inventoryItemNew?.premiumCreative>0 ?
            "Display Banner" : "Standard Banner") + ' ' + (i+1);
    }
}
