import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants, Functions } from '@Workspace/common';
import { PageLoaderService } from '@Workspace/services';
import { eBiddingStrategyPerformanceGoalType, eBiddingStrategyPerformanceGoalTypeName, ePerformanceGoalTypeDV360, ePerformanceGoalTypeDV360Name, ePerformanceGoalTypeTTD, ePerformanceGoalTypeTTDName, eRegion, eTimeUnit, eTimeUnitName, eTimeUnitTTD, eTimeUnitTTDName } from '@Workspace/_generated/enums';
import { ICampaignBulkSettingsDto, ICampaignSettingsDto, IEnumDataAttribute } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import * as _ from 'lodash';
import { MessageService, SelectItem } from 'primeng/api';
import { take } from 'rxjs/operators';


@Component({
    selector: 'campaign-bulk-edit-settings-dialog',
    templateUrl: './campaign-bulk-edit-settings-dialog.html',
    styleUrls: ['./campaign-bulk-edit-settings-dialog.scss']
})
export class CampaignBulkEditSettingsDialog implements OnInit {

    formGroup: UntypedFormGroup;
    isVisible: boolean = false;
    defaultValues: any;
    selectedCampaignsBulkEdit: ICampaignBulkSettingsDto[] = [];
    constants = Constants;
    campaignSettings: ICampaignSettingsDto;
    performanceGoalTypeDV360List: SelectItem[] = [];
    performanceGoalTypeTTDList: SelectItem[] = [];
    timeUnit: SelectItem[] = [];
    timeUnitTTD: SelectItem[] = [];
    dV360BidStrategyPerformanceGoalType: SelectItem[] = [];
    dollarPercentage: string = 'fas fa-percent';
    isPercentage: boolean = true;
    averageCpmDisable: boolean = false;
    step: number = 1;
    regionSort: string = '';

    eBiddingStrategyPerformanceGoalTypeName = eBiddingStrategyPerformanceGoalTypeName;
    ePerformanceGoalTypeDV360Name = ePerformanceGoalTypeDV360Name;
    ePerformanceGoalTypeTTDName = ePerformanceGoalTypeTTDName;
    eTimeUnitName = eTimeUnitName;
    eTimeUnit = eTimeUnit;
    eTimeUnitTTDName = eTimeUnitTTDName;
    eTimeUnitTTD = eTimeUnitTTD;
    enumCampaignRegion = eRegion;

    constructor(
        private formBuilder: UntypedFormBuilder, 
        private campaignController: CampaignController,
        private messageService: MessageService,
        private pageLoader: PageLoaderService
        ) { }

    ngOnInit() {
        this.initLists();

        this.formGroup = this.formBuilder.group({
            dV360PerformanceGoalType: [""],
            dV360PerformanceGoalValue: [""],
            dV360FcapUnlimited: [null],
            dV360FcapTimeUnit: [""],
            dV360FcapTimeUnitCount: [""],
            dV360FcapMaxImpressions: [""],
            dV360BidStrategyPerformanceGoalType: [""],
            dV360BidStrategyPerformanceGoalName: [""],
            dV360MaxAverageCpmBidAmountMicros: [""],
            dv360AverageCpmCheck: [null],
            dV360InventorySourceId: [""],
            ttdPrimaryGoal: [""],
            ttdPrimaryGoalTarget: [""],
            ttdPrimaryGoalName: [""],
            ttdFrequencyCapUnlimited: [null],
            ttdFrequencyCapTimeUnit: [""],
            ttdFrequencyCapTimeUnitName: [""],
            ttdIntervalUnit: [""],
            ttdFrequencyCapCount: [""],
            ttdResetIntervalInMinutes: [""],
            TTDBaseBidCPMAmount: [""],
            ttdMaxBidCPMAmount: [""],
            ttdPrivateContractId: [""],
            dv360FcapLimited: [null],
            dv360FcapNoLimit: [null],
            ttdFcapLimited: [null],
            ttdFcapNoLimit: [null] 
        });
    }

    initLists() {
        this.performanceGoalTypeDV360List = this.enumMapToArray(new Map<number, IEnumDataAttribute>([...ePerformanceGoalTypeDV360Name]));
        this.performanceGoalTypeDV360List.unshift({ label: "-", value: 0 });

        this.performanceGoalTypeTTDList = this.enumMapToArray(new Map<number, IEnumDataAttribute>([...ePerformanceGoalTypeTTDName]));
        this.performanceGoalTypeTTDList.unshift({ label: "-", value: 0 });

        this.timeUnit = this.enumMapToArray(new Map<number, IEnumDataAttribute>([...eTimeUnitName]))
        this.timeUnit.unshift({ label: "-", value: 0 });

        this.timeUnitTTD = this.enumMapToArray(new Map<number, IEnumDataAttribute>([...eTimeUnitTTDName]));
        this.timeUnitTTD.unshift({ label: "-", value: 0 });

        this.dV360BidStrategyPerformanceGoalType = this.enumMapToArray(new Map<number, IEnumDataAttribute>([...eBiddingStrategyPerformanceGoalTypeName]));
        this.dV360BidStrategyPerformanceGoalType.unshift({ label: "-", value: 0 });
    }

    private enumMapToArray(map: Map<number, IEnumDataAttribute>): SelectItem[] {
        let result: SelectItem[] = [];

        map.forEach((enumDataAttr, key) => {
            if (key != 0) result.push({ label: enumDataAttr.name, value: key, icon: enumDataAttr.userTypeId.toString() });
        });

        return result;
    }

    private enumMapToValue(map: any, enumKey){
        let result = '';

        map.forEach((enumDataAttr, key) => {
            if (key == enumKey && key != 0) {
                result = enumDataAttr.name;
            }
        });

        return result;
    }

    
    openDialog(value: any, selectedCampaigns: ICampaignBulkSettingsDto[]) {

        this.isVisible = true;
        this.selectedCampaignsBulkEdit = selectedCampaigns;
        this.campaignSettings = {} as ICampaignSettingsDto;
        this.campaignSettings.campaignBulkSettings = selectedCampaigns;

        let regionSortArray = _.groupBy(selectedCampaigns, region => region.regionId);
        this.regionSort = '';

        let i = 0;
        for (let key in regionSortArray) {
            if(i == 0){
                this.regionSort = this.regionSort + regionSortArray[key].length + ' from ' + Functions.getEnumName(eRegion, parseInt(key));
                
            }
            else{
                this.regionSort = this.regionSort + ' and ' + regionSortArray[key].length + ' from ' + Functions.getEnumName(eRegion, parseInt(key));
            }
            i++;
        }
        this.regionSort = this.regionSort + ' region';

    }

    changeGoalType(event, typeOfGoal: string){
        if(typeOfGoal == 'DV360'){
            this.isPercentage = this.performanceGoalTypeDV360List.filter(x => x.value == event.value)[0]["icon"] == '1' ? true : false;
        }
        else{
            this.isPercentage = this.performanceGoalTypeTTDList.filter(x => x.value == event.value)[0]["icon"] == '1' ? true : false;
        }

        if(event.value == 0){
            if(typeOfGoal == 'DV360'){
                this.formGroup.controls['dV360PerformanceGoalType'].reset();
                this.formGroup.controls['dV360PerformanceGoalValue'].clearValidators();
                this.formGroup.controls['dV360PerformanceGoalValue'].updateValueAndValidity();
                this.formGroup.controls['dV360PerformanceGoalValue'].reset();
                this.formGroup.updateValueAndValidity();
            }else{
                this.formGroup.controls['ttdPrimaryGoal'].reset();
                this.formGroup.controls['ttdPrimaryGoalTarget'].clearValidators();
                this.formGroup.controls['ttdPrimaryGoalTarget'].updateValueAndValidity();
                this.formGroup.controls['ttdPrimaryGoalTarget'].reset();
                this.formGroup.updateValueAndValidity();
            } 
        }else{
            if(typeOfGoal == 'DV360'){
                this.formGroup.controls['dV360PerformanceGoalValue'].setValidators([Validators.required]);
                if(this.isPercentage){
                    this.formGroup.controls['dV360PerformanceGoalValue'].setValidators([Validators.max(100)]);
                }
                this.formGroup.controls['dV360PerformanceGoalValue'].updateValueAndValidity();
            }else{
                this.formGroup.controls['ttdPrimaryGoalTarget'].setValidators([Validators.required]);
                this.formGroup.controls['ttdPrimaryGoalTarget'].updateValueAndValidity();
            }
        }
    }

    bidStrategyOnChange(event){
        if(event.value == 0){
            this.formGroup.controls['dV360BidStrategyPerformanceGoalType'].reset();
            this.formGroup.controls['dV360MaxAverageCpmBidAmountMicros'].reset();
            this.formGroup.controls['dv360AverageCpmCheck'].patchValue(false);
            this.formGroup.updateValueAndValidity();
        }
    }

    averageCpmOnChange(event){
        this.averageCpmDisable = event.checked;
        if(event.checked){
            this.formGroup.controls['dV360MaxAverageCpmBidAmountMicros'].setValidators([Validators.required]);
            this.formGroup.controls['dV360MaxAverageCpmBidAmountMicros'].updateValueAndValidity();
        }else{
            this.formGroup.controls['dV360MaxAverageCpmBidAmountMicros'].clearValidators();
            this.formGroup.controls['dV360MaxAverageCpmBidAmountMicros'].updateValueAndValidity();
            this.formGroup.value.dV360MaxAverageCpmBidAmountMicros = '';
        }
    }

    timeUnitOnChange(event){
        if(event.value == eTimeUnit.TIME_UNIT_UNSPECIFIED || event.value == eTimeUnit.TIME_UNIT_LIFETIME){
            this.formGroup.controls['dV360FcapTimeUnit'].clearValidators();
            this.formGroup.controls['dV360FcapTimeUnit'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapTimeUnitCount'].clearValidators();
            this.formGroup.controls['dV360FcapTimeUnitCount'].patchValue('');
            this.formGroup.controls['dV360FcapTimeUnitCount'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapMaxImpressions'].patchValue('');
            this.formGroup.controls['dV360FcapTimeUnit'].setValidators([Validators.required]);
            this.formGroup.controls['dV360FcapTimeUnit'].updateValueAndValidity();
        }
        else{
            this.formGroup.controls['dV360FcapTimeUnit'].setValidators([Validators.required]);
            this.formGroup.controls['dV360FcapTimeUnit'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapTimeUnitCount'].setValidators([Validators.required]);
            this.formGroup.controls['dV360FcapTimeUnitCount'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapMaxImpressions'].setValidators([Validators.required]);
            this.formGroup.controls['dV360FcapMaxImpressions'].updateValueAndValidity();
        }
    }

    timeUnitTTDOnChange(event){
        if(event.value == eTimeUnitTTD.TTD_LIFETIME){
            this.formGroup.controls['ttdFrequencyCapCount'].setValidators([Validators.required]);
            this.formGroup.controls['ttdFrequencyCapCount'].updateValueAndValidity();

            this.formGroup.controls['ttdResetIntervalInMinutes'].clearValidators();
            this.formGroup.controls['ttdResetIntervalInMinutes'].updateValueAndValidity();
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].clearValidators();
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].updateValueAndValidity();
        }
        else{
            this.formGroup.controls['ttdFrequencyCapCount'].setValidators([Validators.required]);
            this.formGroup.controls['ttdFrequencyCapCount'].updateValueAndValidity();
            this.formGroup.controls['ttdResetIntervalInMinutes'].setValidators([Validators.required]);
            this.formGroup.controls['ttdResetIntervalInMinutes'].updateValueAndValidity();
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].setValidators([Validators.required]);
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].updateValueAndValidity();
        }
    }

    dv360FcapNoLimitOnChange(event){
        if(event.checked){
            this.formGroup.controls['dv360FcapLimited'].patchValue(false);
            this.formGroup.controls['dV360FcapUnlimited'].patchValue(true);

            this.formGroup.controls['dV360FcapTimeUnit'].clearValidators();
            this.formGroup.controls['dV360FcapTimeUnit'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapTimeUnitCount'].clearValidators();
            this.formGroup.controls['dV360FcapTimeUnitCount'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapMaxImpressions'].clearValidators();
            this.formGroup.controls['dV360FcapMaxImpressions'].updateValueAndValidity();
        }
        else{
            this.formGroup.controls['dV360FcapUnlimited'].patchValue(null);
            this.formGroup.controls['dV360FcapUnlimited'].markAsPristine();
            this.formGroup.controls['dV360FcapUnlimited'].markAsUntouched();
            
            this.formGroup.controls['dv360FcapLimited'].patchValue(null);
            this.formGroup.controls['dv360FcapLimited'].markAsPristine();
            this.formGroup.controls['dv360FcapLimited'].markAsUntouched();

            this.formGroup.controls['dv360FcapNoLimit'].patchValue(null);
            this.formGroup.controls['dv360FcapNoLimit'].markAsPristine();
            this.formGroup.controls['dv360FcapNoLimit'].markAsUntouched();
            this.formGroup.updateValueAndValidity();
        }
    }
    
    dv360FcapLimitedOnChange(event){
        if(event.checked){
            this.formGroup.controls['dv360FcapNoLimit'].patchValue(false);
            this.formGroup.controls['dV360FcapUnlimited'].patchValue(false);

            this.formGroup.controls['dV360FcapTimeUnit'].setValidators([Validators.required]);
            this.formGroup.controls['dV360FcapTimeUnit'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapTimeUnitCount'].setValidators([Validators.required]);
            this.formGroup.controls['dV360FcapTimeUnitCount'].updateValueAndValidity();
            this.formGroup.controls['dV360FcapMaxImpressions'].setValidators([Validators.required]);
            this.formGroup.controls['dV360FcapMaxImpressions'].updateValueAndValidity();
        }
        else{
            this.formGroup.controls['dV360FcapUnlimited'].patchValue(null);
            this.formGroup.controls['dV360FcapUnlimited'].markAsPristine();
            this.formGroup.controls['dV360FcapUnlimited'].markAsUntouched();
            
            this.formGroup.controls['dv360FcapLimited'].patchValue(null);
            this.formGroup.controls['dv360FcapLimited'].markAsPristine();
            this.formGroup.controls['dv360FcapLimited'].markAsUntouched();

            this.formGroup.controls['dv360FcapNoLimit'].patchValue(null);
            this.formGroup.controls['dv360FcapNoLimit'].markAsPristine();
            this.formGroup.controls['dv360FcapNoLimit'].markAsUntouched();
            this.formGroup.updateValueAndValidity();
        }
    }

    ttdFcapNoLimitOnChange(event){
        if(event.checked){
            this.formGroup.controls['ttdFcapLimited'].patchValue(false);
            this.formGroup.controls['ttdFrequencyCapUnlimited'].patchValue(true);

            this.formGroup.controls['ttdFrequencyCapCount'].clearValidators();
            this.formGroup.controls['ttdFrequencyCapCount'].updateValueAndValidity();
            this.formGroup.controls['ttdResetIntervalInMinutes'].clearValidators();
            this.formGroup.controls['ttdResetIntervalInMinutes'].updateValueAndValidity();
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].clearValidators();
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].updateValueAndValidity();
        }
        else{
            this.formGroup.controls['ttdFrequencyCapUnlimited'].patchValue(null);
            this.formGroup.controls['ttdFrequencyCapUnlimited'].markAsPristine();
            this.formGroup.controls['ttdFrequencyCapUnlimited'].markAsUntouched();
            
            this.formGroup.controls['ttdFcapLimited'].patchValue(null);
            this.formGroup.controls['ttdFcapLimited'].markAsPristine();
            this.formGroup.controls['ttdFcapLimited'].markAsUntouched();

            this.formGroup.controls['ttdFcapNoLimit'].patchValue(null);
            this.formGroup.controls['ttdFcapNoLimit'].markAsPristine();
            this.formGroup.controls['ttdFcapNoLimit'].markAsUntouched();
            this.formGroup.updateValueAndValidity();
        }
    }
    
    ttdFcapLimitedOnChange(event){
        if(event.checked){
            this.formGroup.controls['ttdFcapNoLimit'].patchValue(false);
            this.formGroup.controls['ttdFrequencyCapUnlimited'].patchValue(false);

            this.formGroup.controls['ttdFrequencyCapCount'].setValidators([Validators.required]);
            this.formGroup.controls['ttdFrequencyCapCount'].updateValueAndValidity();
            this.formGroup.controls['ttdResetIntervalInMinutes'].setValidators([Validators.required]);
            this.formGroup.controls['ttdResetIntervalInMinutes'].updateValueAndValidity();
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].setValidators([Validators.required]);
            this.formGroup.controls['ttdFrequencyCapTimeUnit'].updateValueAndValidity();
        }
        else{
            this.formGroup.controls['ttdFrequencyCapUnlimited'].patchValue(null);
            this.formGroup.controls['ttdFrequencyCapUnlimited'].markAsPristine();
            this.formGroup.controls['ttdFrequencyCapUnlimited'].markAsUntouched();
            
            this.formGroup.controls['ttdFcapLimited'].patchValue(null);
            this.formGroup.controls['ttdFcapLimited'].markAsPristine();
            this.formGroup.controls['ttdFcapLimited'].markAsUntouched();

            this.formGroup.controls['ttdFcapNoLimit'].patchValue(null);
            this.formGroup.controls['ttdFcapNoLimit'].markAsPristine();
            this.formGroup.controls['ttdFcapNoLimit'].markAsUntouched();
            this.formGroup.updateValueAndValidity();
        }
    }

    reset() {
        this.step = 1;
        this.formGroup.reset();
        
        this.formGroup = this.formBuilder.group({
            dV360PerformanceGoalType: [""],
            dV360PerformanceGoalValue: [""],
            dV360FcapUnlimited: [null],
            dV360FcapTimeUnit: [""],
            dV360FcapTimeUnitCount: [""],
            dV360FcapMaxImpressions: [""],
            dV360BidStrategyPerformanceGoalType: [""],
            dV360BidStrategyPerformanceGoalName: [""],
            dV360MaxAverageCpmBidAmountMicros: [""],
            dv360AverageCpmCheck: [null],
            dV360InventorySourceId: [""],
            ttdPrimaryGoal: [""],
            ttdPrimaryGoalTarget: [""],
            ttdPrimaryGoalName: [""],
            ttdFrequencyCapUnlimited: [null],
            ttdFrequencyCapTimeUnit: [""],
            ttdFrequencyCapTimeUnitName: [""],
            ttdIntervalUnit: [""],
            ttdFrequencyCapCount: [""],
            ttdResetIntervalInMinutes: [""],
            TTDBaseBidCPMAmount: [""],
            ttdMaxBidCPMAmount: [""],
            ttdPrivateContractId: [""],
            dv360FcapLimited: [null],
            dv360FcapNoLimit: [null],
            ttdFcapLimited: [null],
            ttdFcapNoLimit: [null]
        });

        this.averageCpmDisable = false;

        this.campaignSettings = {} as ICampaignSettingsDto;
        this.campaignSettings.campaignBulkSettings = this.selectedCampaignsBulkEdit;
    }

    next(){
        this.step = 2;

        this.campaignSettings = {} as ICampaignSettingsDto;
        this.campaignSettings.campaignBulkSettings = this.selectedCampaignsBulkEdit;

        this.campaignSettings.dV360PerformanceGoalType = this.formGroup.value.dV360PerformanceGoalType;
        this.campaignSettings.dV360PerformanceGoalName = Functions.getEnumName(ePerformanceGoalTypeDV360, this.formGroup.value.dV360PerformanceGoalType);
        this.campaignSettings.dV360PerformanceGoalName = this.campaignSettings.dV360PerformanceGoalName?.split(' ').join('_');

        if(this.isPercentage){
            this.campaignSettings.dV360PerformanceGoalPercentageMicros = this.formGroup.value.dV360PerformanceGoalValue;
        }
        else{
            this.campaignSettings.dV360PerformanceGoalAmountMicros = this.formGroup.value.dV360PerformanceGoalValue;
        }

        this.campaignSettings.dV360FcapUnlimited = this.formGroup.value.dV360FcapUnlimited;
        this.campaignSettings.dV360FcapTimeUnit = this.formGroup.value.dV360FcapTimeUnit;
        this.campaignSettings.dV360FcapTimeUnitName = Functions.getEnumName(eTimeUnit, this.formGroup.value.dV360FcapTimeUnit);
        this.campaignSettings.dV360FcapTimeUnitName = this.campaignSettings.dV360FcapTimeUnitName?.split(' ').join('_');

        this.campaignSettings.dV360FcapTimeUnitCount = this.formGroup.value.dV360FcapTimeUnitCount;
        this.campaignSettings.dV360FcapMaxImpressions = this.formGroup.value.dV360FcapMaxImpressions;

        this.campaignSettings.dV360InventorySourceId = this.formGroup.value.dV360InventorySourceId;

        this.campaignSettings.dV360BidStrategyPerformanceGoalType = this.formGroup.value.dV360BidStrategyPerformanceGoalType;
        this.campaignSettings.dV360BidStrategyPerformanceGoalName = Functions.getEnumName(eBiddingStrategyPerformanceGoalType, this.formGroup.value.dV360BidStrategyPerformanceGoalType);
        this.campaignSettings.dV360BidStrategyPerformanceGoalName = this.campaignSettings.dV360BidStrategyPerformanceGoalName?.split(' ').join('_');

        this.campaignSettings.dV360MaxAverageCpmBidAmountMicros = this.formGroup.value.dV360MaxAverageCpmBidAmountMicros;

        this.campaignSettings.ttdFrequencyCapUnlimited = this.formGroup.value.ttdFrequencyCapUnlimited;
        this.campaignSettings.ttdPrimaryGoal = this.formGroup.value.ttdPrimaryGoal;
        this.campaignSettings.ttdPrimaryGoalTarget = this.formGroup.value.ttdPrimaryGoalTarget;
        this.campaignSettings.ttdPrimaryGoalName = Functions.getEnumName(ePerformanceGoalTypeTTD, this.formGroup.value.ttdPrimaryGoal);
        this.campaignSettings.ttdPrimaryGoalName = this.campaignSettings.ttdPrimaryGoalName?.split(' ').join('_');

        this.campaignSettings.ttdFrequencyCapTimeUnit = this.formGroup.value.ttdFrequencyCapTimeUnit;
        this.campaignSettings.ttdFrequencyCapTimeUnitName = Functions.getEnumName(eTimeUnitTTD, this.formGroup.value.ttdFrequencyCapTimeUnit);
        this.campaignSettings.ttdFrequencyCapTimeUnitName = this.campaignSettings.ttdFrequencyCapTimeUnitName?.split(' ')[1].charAt(0).toUpperCase() + this.campaignSettings.ttdFrequencyCapTimeUnitName?.split(' ')[1].slice(1).toLowerCase();
        this.campaignSettings.ttdResetIntervalInMinutes = this.formGroup.value.ttdResetIntervalInMinutes;
        this.campaignSettings.ttdFrequencyCapCount = this.formGroup.value.ttdFrequencyCapCount;

        this.campaignSettings.ttdBaseBidCPMAmount = this.formGroup.value.TTDBaseBidCPMAmount;
        this.campaignSettings.ttdMaxBidCPMAmount = this.formGroup.value.ttdMaxBidCPMAmount;

        this.campaignSettings.ttdPrivateContractId = this.formGroup.value.ttdPrivateContractId;
    }

    save(){
        this.pageLoader.showPageLoader();
        this.campaignController.BulkChangeCampaignSettings(this.campaignSettings).pipe(take(1)).subscribe(
            (result) => {
                this.reset();
                this.isVisible = false;
                this.messageService.add({ severity: 'success', summary: 'Success Message', detail: 'Settings successfully changed.' });
                this.pageLoader.hidePageLoader();
            },

            (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong.' });
                this.pageLoader.hidePageLoader();
            })
    }

    cancel() {
        if(this.step == 2){
            this.step = 1;
        }
        else{
            this.reset();
            this.isVisible = false;
        }
    }
}