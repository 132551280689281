import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { Constants } from '@Workspace/common';
import { FileUploadService } from '../../../../services/file-upload.service';
import { PageLoaderService, ProgressBarService } from '@Workspace/services';
import { eCampaignStatus } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { MessageService } from 'primeng/api';
@Component({
    selector: 'campaign-report-assets',
    templateUrl: './campaign-report-assets.component.html',
    styleUrls: ['./campaign-report-assets.component.scss'],

})
export class CampaignReportAssetsComponent implements OnInit {
    constructor(
        private campaignController: CampaignController,
        private activatedRoute: ActivatedRoute,
        private fileuploadService: FileUploadService,
        public router: Router,
        public authService: AuthService,
        public progressBar: ProgressBarService,
        public pageLoaderService: PageLoaderService,
        private messageService: MessageService

    ) { }

    ngUnsubscribe = new Subject();
    campaignId: string;
    enumCampaignStatus = eCampaignStatus;
    currentUser = this.authService.userSettings;
    @Input() baseCampaign: IBaseCampaignDto;
    @Input() file: any;
    constants = Constants;

    totalFileSize: number=0;
    valueProgressBar: number=0;
    isDownloaded: boolean = false;
    downloadInterval: any;

    ngOnInit() {
        this.activatedRoute.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                this.campaignId = params.slug;
            });

        let filter = `?$filter=Id eq ${this.campaignId}`;
        this.campaignController.GetAllCampaigns(filter).subscribe(data => {
            if (data.totalCount > 0) {
                this.baseCampaign = data.data[0];

            }
        },
        error => {
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
        });
    }
    OnViewCampaign() {
        if (this.baseCampaign.account.isLearfieldCampaign)
            this.router.navigate(['/campaign/' + this.baseCampaign.id + '/campaign-upload-fan365']);
        else
            this.router.navigate(['/campaign/' + this.baseCampaign.id]);

    }

    downloadAssetsZip() {
        if (this.baseCampaign.creatives.length > 0) {

            this.baseCampaign.creatives.forEach(creative=>{
                creative.creativeAssets.forEach(asset=>{
                    if(asset.file!=null){
                        this.totalFileSize +=asset.file.sizeInBytes;
                    }
                })
                });
            this.pageLoaderService.showPageLoader();
            var counter=0;
            var downloadSpeed = 1250000;

            if(this.totalFileSize/downloadSpeed>=5 && this.totalFileSize/downloadSpeed<25){
                this.downloadInterval = setInterval(() => {
                    if(this.isDownloaded){
                        this.progressBar.showProgressBar(100);
                    }else{
                        counter = counter+10;
                        this.valueProgressBar = counter;
                        this.progressBar.showProgressBar(this.valueProgressBar);
                        if (counter >= 90) {
                            counter = 89;
                        }
                    }
                    }, ((this.totalFileSize/downloadSpeed)*1000*10)/100);
            }
            else if(this.totalFileSize/downloadSpeed>=25){
                this.downloadInterval = setInterval(() => {
                    if(this.isDownloaded){
                        this.progressBar.showProgressBar(100);
                    }else{
                    this.valueProgressBar = counter++;
                    this.progressBar.showProgressBar(this.valueProgressBar);
                    if (counter >= 100) {
                        counter=99;
                    }}
                    }, (this.totalFileSize/downloadSpeed*1000)/100);
            }

            this.pageLoaderService.showPageLoader();
            var x = this.fileuploadService.DownloadZipFile(this.baseCampaign.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                this.isDownloaded = true;
                this.progressBar.showProgressBar(100);
                setTimeout(() => {
                    this.progressBar.hideProgressBar();
                    clearInterval(this.downloadInterval);
                    this.pageLoaderService.hidePageLoader();
                    let file = new Blob([x as any], { type: 'application/zip' });
                    FileSaver.saveAs(file, this.baseCampaign.name + ".zip");
                }, 2000);
            });
        }

    }

    redirectReport(link?: string) {
        if (!!link)
            window.open(this.baseCampaign.reportLink, "_blank");
        else
            FileSaver.saveAs(this.file, this.baseCampaign.reportFile.fileName);


    }

    redirect(link) {
        if (!!link)
            window.open(link, "_blank");
    }

}



