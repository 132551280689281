<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>{{isActivation? 'Activation' : 'Additional Settings'}}</p-header>


    <!-- <ng-template> -->
    <span *ngIf="!isActivation">
        <div class="mb-2 mt-2 row align-items-center" *ngIf="editableFields[0]">
            <div class="col-md-8 font-weight-bold">
                Requires site visit tracking
            </div>
            <div class="col-md-4">
                <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
                <p-inputSwitch [(ngModel)]="isSiteVisitTrackingEnabled"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="editableFields[1]">
            <div class="col-md-8 font-weight-bold">
                Requires conversion tracking
            </div>
            <div class="col-md-4">
                <p-inputSwitch [(ngModel)]="isConversionTrackingEnabled"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="editableFields[2]">
            <div class="col-md-8 font-weight-bold">
                Requires additional targeting segments
            </div>
            <div class="col-md-4">
                <p-inputSwitch [(ngModel)]="isAdditionalTargetingSegmentsEnabled"></p-inputSwitch>
            </div>
        </div>
        <div class="mb-2 row align-items-center">
            <div class="col-md-8 font-weight-bold reset-default-text" (click)="resetDefault()">
                Click here to restore default values
            </div>
        </div>
        <!-- </ng-template> -->
    </span>
    
    <span *ngIf="isActivation">
        <div class="mb-2 mt-2 row align-items-center">
            <div class="col-md-8 font-weight-bold">
                Activated outside of HUB
            </div>
            <div class="col-md-4">
                <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
                <p-inputSwitch [(ngModel)]="isActivatedOutsideOfHub"></p-inputSwitch>
            </div>
        </div>

    </span>
    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>