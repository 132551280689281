<div>
    <rc-grid [options]="options" #accountTable [expandedRowKeys]="expandedRows" headerCheckboxToggleAllPages="true" rowExpandMode="single" [paginator]="!currentUser.permissions.isLearfieldClient" dataKey="id">
        <ng-template pTemplate="header" let-item>
            <tr>
                <th style="width: 3em">

                </th>
                <th *ngFor="let column of options.columns" [pSortableColumn]="column.value" [ngStyle]="{'width':column.width}" [ngClass]=getTextAlignClass(column.align)>
                    <span *ngIf="column.label != 'CheckBox'">
                        {{column.label}}
                        <p-sortIcon *ngIf="column.label !='Action'" [field]="column.value"></p-sortIcon>

                    </span>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-cols let-expanded="expanded" let-rowIndex="rowIndex" class="p-table-scrollable-wrapper errorTooltip">
            <tr tooltipPosition="top">
                <td style="width: 3em">
                    <span *ngIf="!currentUser.permissions.isLearfieldClient" (click)="accountTable.toggleRow(rowData)">
                        <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                    </span>
                </td>
                <td class="pr-3 pl-1">{{ rowData.name }}</td>
                <td class="pr-3 pl-1">{{ rowData.typeId | enumString : enumAccountType }}</td>
                <td class="pr-3 pl-1">{{ rowData.industry | enumString: enumIndustry }}</td>
                <td class="pr-3 pl-1">{{ rowData.region | enumString: enumRegion }}</td>
                <!-- <td class="pr-3 pl-0">{{ rowData.statusId }}</td> -->
                <td class="text-right">
                    <i *ngIf="!rowData.isLearfieldCampaign || (rowData.isLearfieldCampaign && (currentUser.permissions.isEmployee || currentUser.permissions.isAdmin || currentUser.permissions.isLearfieldClient))" class="pr-3 pl-0 text-right" (click)="openEditDialog(rowData)" class="fa fa-edit pr-1"> </i>
                    <i *ngIf="!rowData.isLearfieldCampaign || (rowData.isLearfieldCampaign && currentUser.permissions.isEmployee)" class="pr-3 pl-0 text-right" (click)="remove(rowData)" class="fa fa-times"></i></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-item let-columns="options.columns">
            <tr>
                <td [attr.colspan]="options.columns.length+1">
                    <div *ngIf="item" class="col-xs-12">
                        <user-list [account]="item"></user-list>
                    </div>
                </td>
            </tr>
        </ng-template>
    </rc-grid>
    <account-edit-dialog [displayDialog]="isEditDialogDisplayed" *ngIf="!!selectedAccount && isEditDialogOpen" (accountDialogDisplay)="showHideDialog($event)"(onSubmit)="composeGridColumns()" [account]="selectedAccount"></account-edit-dialog>

    <p-confirmDialog acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary" header="Confirmation" icon="pi pi-exclamation-triangle" key="accountDelete"></p-confirmDialog>
</div>