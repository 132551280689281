
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { componentFadeIn } from '../../../route-animations';
import {
    AccountController, TeamController
} from '@Workspace/_generated/services';
import { eEntityStatus } from '@Workspace/_generated/enums';
import { IUserCreateDto, ITeamDto, IUserTeamDto } from '@Workspace/_generated/interfaces';
import { Router } from '@angular/router';
import { UserDetailComponent } from '../../user/user-detail/user-detail.component';
import { CampaignService } from '../../../services/campaign.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PageLoaderService } from '@Workspace/services';

@Component({
    selector: 'team-detail-component',
    templateUrl: './team-detail.component.html',
    styleUrls: ['./team-detail.component.scss'],
    animations: [componentFadeIn]
})
export class TeamDetailComponent implements OnInit, OnDestroy {

    constructor(
        public accountController: AccountController,
        public teamController: TeamController,
        protected fb: UntypedFormBuilder,
        private router: Router,
        private campaignService: CampaignService,
        private pageLoader: PageLoaderService

    ) {

    }
    @ViewChild(UserDetailComponent) userDetailComponent: UserDetailComponent;
    formGroup: UntypedFormGroup;
    formGroupUser: UntypedFormGroup;
    newUser: IUserCreateDto;
    newTeam: ITeamDto;
    teamUsers: SelectItem[] = [];
    passwordPattern: string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

    ngUnsubscribe: Subject<void> = new Subject();

    ngOnInit(): void {

        this.formInit();
        this.createNewUser();
    }

    formInit(): void {
        this.formGroup = this.fb.group({
            name: ["", Validators.required]
        });
        this.formGroupUser = this.fb.group({
            firstName: [""],
            lastName: [""],
            email: ["", [Validators.email, Validators.required]],
            phone: [""],
            username: ["", Validators.required],
            password: ["", [Validators.required, Validators.pattern(this.passwordPattern)]],
            confirmPassword: ["", Validators.required],
            sendUserNotification: [false],
            accountRole: ["", Validators.required],
            account: [""],
            team: [""]
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    createNewUser() {
        this.newUser = {} as IUserCreateDto;
    }

    getTeamValues() {
        this.newTeam = {} as ITeamDto;
        this.newTeam.name = this.formGroup.value.name;
        this.newTeam.entityStatus = eEntityStatus.Active;

    }

    saveChanges() {
        this.pageLoader.showPageLoader();
        this.newUser = this.userDetailComponent.getUserValues();
        this.getTeamValues();
        this.newTeam.teamUsers = [];

        if (!!this.newUser.userName) {
            var ut = {} as IUserTeamDto;
            ut.teamId = this.newTeam.id;
            ut.entityStatus = eEntityStatus.Active;
            ut.userId = this.newUser.id;
            ut.userCreate = this.newUser;
            this.newTeam.teamUsers.push(ut);
        }
        this.teamUsers.forEach(x => {
            var ut = {} as IUserTeamDto;
            ut.teamId = this.newTeam.id;
            ut.userId = x.value;
            ut.entityStatus = eEntityStatus.Active;
            this.newTeam.teamUsers.push(ut);
        })

        this.teamController.CreateTeam(this.newTeam).subscribe(x => {
            this.campaignService.setIsGrid(true);
            this.pageLoader.hidePageLoader();
            this.router.navigate(["mogo/teams"])
        })
    }

    onCancel() {
        this.campaignService.setIsGrid(true);
        this.router.navigate(["mogo/teams"]);
    }

}