<div class="mt-3 row">
    <div class="col-12">
        <rc-grid [options]="options" [paginator]="true">
            <ng-template pTemplate="header" let-item>
                <tr>
                    <th *ngFor="let column of options.columns" [pSortableColumn]="column.value" [ngStyle]="{'width':column.width}" class="p-3" [ngClass]=getTextAlignClass(column.align)>
                        <!-- <span *ngIf="column.label != 'CheckBox'"> -->
                        {{column.label}}
                        <p-sortIcon *ngIf="column.label!=''" [field]="column.value"></p-sortIcon>
                        <!-- </span> -->
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                    <td class="p-3">{{ !!rowData.campaignName ? rowData.campaignName : '-' }}</td>
                    <td class="p-3">{{ rowData.crmType | enumString : enumCrmType }}</td>
                    <td class="p-3">{{ !!rowData.clientName ? rowData.clientName : '-' }}</td>
                    <td class="p-3">{{ rowData.comment }}</td>
                    <td class="p-3">{{( rowData.modifiedOn | dateToUserTimeZone) | dateAgo }} ({{ (rowData.modifiedOn | dateToUserTimeZone) | date : constants.DEFAULT_FORMAT_DATE_US }})</td>

                    <!-- <td class="pr-3 pl-0">{{ rowData.statusId }}</td> -->
                    <td class="text-right p-3">
                        <i (click)="download(rowData)" class="text-right px-3 fa-lg fa fa-download"></i>
                        <i (click)="remove(rowData)" class="text-right px-3 fa-lg fa fa-trash"></i>
                    </td>
                </tr>
            </ng-template>
        </rc-grid>
        <!-- <account-edit-dialog *ngIf="!!selectedAccount && isEditDialogOpen" (accountDialogDisplay)="showHideDialog($event)" [displayDialog]="isEditDialogOpen" [account]="selectedAccount"></account-edit-dialog>

    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="accountDelete"></p-confirmDialog> -->
    </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" key="CRMDelete" acceptButtonStyleClass="p-button-primary" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>
