import { Inject, Injectable, InjectionToken, OnInit } from '@angular/core';
import {
    UserAgentApplication,
    Configuration,
    AuthenticationParameters,
    AuthResponse,
    AuthError,
    Logger
} from 'msal';
import { Router } from '@angular/router';
import { BroadcastService } from './broadcast.service';
import { MSALError } from './MSALError';
import { AuthCache } from 'msal/lib-commonjs/cache/AuthCache';
import { MsalAngularConfiguration } from './msal-angular.configuration';
import {
    authResponseCallback,
    errorReceivedCallback,
    tokenReceivedCallback
} from 'msal/lib-commonjs/UserAgentApplication';
import { UrlUtils } from 'msal/lib-commonjs/utils/UrlUtils';
// import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from './constants';
import { SettingsService } from 'libs/services/settings/settings.service';

const buildMsalConfig = (config: Configuration): Configuration => {
    return {
        ...config,
        framework: {
            ...config.framework,
            isAngular: true
        }
    };
};

@Injectable()
export class MsalService extends UserAgentApplication {
    constructor(
        // @Inject(MSAL_CONFIG) private msalConfig: Configuration,
        // @Inject(MSAL_CONFIG_ANGULAR) private msalAngularConfig: MsalAngularConfiguration,
        // private router: Router
        private settingsService: SettingsService,
        private broadcastService: BroadcastService
    ) {
        super(buildMsalConfig(settingsService.AzureAdConfig));

        window.addEventListener(
            'msal:popUpHashChanged',
            this.handlepopUpHashChanged
        );

        window.addEventListener('msal:popUpClosed', this.handlepopUpClosed);
    }

    private handlepopUpHashChanged(e: CustomEvent) {
        console.log('popUpHashChanged ');
    }

    private handlepopUpClosed(e: CustomEvent) {
        var errorParts = e.detail.split('|');
        var msalError = new MSALError(errorParts[0], errorParts[1]);
        if (this.getLoginInProgress()) {
            this.broadcastService.broadcast('msal:loginFailure', msalError);
            this.setloginInProgress(false);
        } else if (this.getAcquireTokenInProgress()) {
            this.broadcastService.broadcast(
                'msal:acquireTokenFailure',
                msalError
            );
            this.setAcquireTokenInProgress(false);
        }
    }

    private isUnprotectedResource(url: string): boolean {
        const frameworkUnprotectedResources =
            this.settingsService.AzureAdConfig.framework &&
            this.settingsService.AzureAdConfig.framework.unprotectedResources;
        const configUnprotectedResources =
            this.settingsService.AzureAdAngularConfig.unprotectedResources ||
            [];

        const unprotectedResources =
            frameworkUnprotectedResources &&
            frameworkUnprotectedResources.length
                ? frameworkUnprotectedResources
                : configUnprotectedResources;

        return unprotectedResources.some(
            resource => url.indexOf(resource) > -1
        );
    }

    private isEmpty(str: string): boolean {
        return typeof str === 'undefined' || !str || 0 === str.length;
    }

    public getCacheStorage(): AuthCache {
        return this.cacheStorage;
    }

    public loginPopup(request?: AuthenticationParameters): Promise<any> {
        return super
            .loginPopup(request)
            .then((authResponse: AuthResponse) => {
                this.broadcastService.broadcast(
                    'msal:loginSuccess',
                    authResponse
                );
                return authResponse;
            })
            .catch((error: AuthError) => {
                this.broadcastService.broadcast('msal:loginFailure', error);
                console.error('Error during login:\n' + error.errorMessage);
                throw error;
            });
    }

    public acquireTokenSilent(
        request: AuthenticationParameters
    ): Promise<AuthResponse> {
        return super
            .acquireTokenSilent(request)
            .then((authResponse: AuthResponse) => {
                this.broadcastService.broadcast(
                    'msal:acquireTokenSuccess',
                    authResponse
                );
                return authResponse;
            })
            .catch((error: AuthError) => {
                this.broadcastService.broadcast(
                    'msal:acquireTokenFailure',
                    error
                );
                console.error(
                    'Error when acquiring token for scopes: ' +
                        request.scopes +
                        ' ' +
                        error
                );
                throw error;
            });
    }

    public acquireTokenPopup(
        request: AuthenticationParameters
    ): Promise<AuthResponse> {
        return super
            .acquireTokenPopup(request)
            .then((authResponse: AuthResponse) => {
                this.broadcastService.broadcast(
                    'msal:acquireTokenSuccess',
                    authResponse
                );
                return authResponse;
            })
            .catch((error: AuthError) => {
                this.broadcastService.broadcast(
                    'msal:acquireTokenFailure',
                    error
                );
                console.error(
                    'Error when acquiring token for scopes : ' +
                        request.scopes +
                        ' ' +
                        error
                );
                throw error;
            });
    }

    handleRedirectCallback(
        tokenReceivedCallback: tokenReceivedCallback,
        errorReceivedCallback: errorReceivedCallback
    ): void;
    handleRedirectCallback(authCallback: authResponseCallback): void;
    handleRedirectCallback(
        authOrTokenCallback: authResponseCallback | tokenReceivedCallback,
        errorReceivedCallback?: errorReceivedCallback
    ): void {
        super.handleRedirectCallback(
            (authError: AuthError, authResponse: AuthResponse) => {
                if (authResponse) {
                    if (authResponse.tokenType === 'id_token') {
                        this.broadcastService.broadcast(
                            'msal:loginSuccess',
                            authResponse
                        );
                    } else {
                        this.broadcastService.broadcast(
                            'msal:acquireTokenSuccess',
                            authResponse
                        );
                    }

                    if (errorReceivedCallback) {
                        (authOrTokenCallback as tokenReceivedCallback)(
                            authResponse
                        );
                    } else {
                        (authOrTokenCallback as authResponseCallback)(
                            null,
                            authResponse
                        );
                    }
                } else if (authError) {
                    if (authResponse.tokenType === 'id_token') {
                        this.broadcastService.broadcast(
                            'msal:loginFailure',
                            authError
                        );
                    } else {
                        this.broadcastService.broadcast(
                            'msal:acquireTokenFailure',
                            authError
                        );
                    }

                    if (errorReceivedCallback) {
                        errorReceivedCallback(
                            authError,
                            authResponse.accountState
                        );
                    } else {
                        (authOrTokenCallback as authResponseCallback)(
                            authError
                        );
                    }
                }
            }
        );
    }

    public clearCacheForScope(accessToken: string) {
        return super.clearCacheForScope(accessToken);
    }

    public getScopesForEndpoint(endpoint: string): Array<string> {
        if (
            this.settingsService.AzureAdConfig.framework &&
            this.settingsService.AzureAdConfig.framework.unprotectedResources
        ) {
            console.info(
                'msalConfig.framework.unprotectedResources is deprecated, use msalAngularConfig.unprotectedResources'
            );
        }

        // if user specified list of unprotectedResources, no need to send token to these endpoints, return null.
        const isUnprotected = this.isUnprotectedResource(endpoint);
        if (isUnprotected) {
            return null;
        }

        const frameworkProtectedResourceMap =
            this.settingsService.AzureAdConfig.framework &&
            this.settingsService.AzureAdConfig.framework.protectedResourceMap;
        if (frameworkProtectedResourceMap) {
            console.info(
                'msalConfig.framework.protectedResourceMap is deprecated, use msalAngularConfig.protectedResourceMap'
            );
        }

        const protectedResourceMap =
            frameworkProtectedResourceMap && frameworkProtectedResourceMap.size
                ? frameworkProtectedResourceMap
                : new Map(
                      this.settingsService.AzureAdAngularConfig.protectedResourceMap
                  );

        // process all protected resources and send the matched one
        const keyForEndpoint = Array.from(protectedResourceMap.keys()).find(
            key => endpoint.indexOf(key) > -1
        );
        if (keyForEndpoint) {
            return protectedResourceMap.get(keyForEndpoint);
        }

        /*
         * default resource will be clientid if nothing specified
         * App will use idtoken for calls to itself
         * check if it's staring from http or https, needs to match with app host
         */
        if (
            endpoint.indexOf('http://') > -1 ||
            endpoint.indexOf('https://') > -1
        ) {
            if (
                UrlUtils.getHostFromUri(endpoint) ===
                UrlUtils.getHostFromUri(super.getRedirectUri())
            ) {
                return new Array<string>(
                    this.settingsService.AzureAdConfig.auth.clientId
                );
            }
        } else {
            /*
             * in angular level, the url for $http interceptor call could be relative url,
             * if it's relative call, we'll treat it as app backend call.
             */
            return new Array<string>(
                this.settingsService.AzureAdConfig.auth.clientId
            );
        }

        // if not the app's own backend or not a domain listed in the endpoints structure
        return null;
    }
}
