import { Directive, ElementRef, EventEmitter, OnDestroy, Output, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressBarService } from '@Workspace/services';

@Directive({
    selector: '[progressBar]'
})
export class ProgressBarDirective implements OnDestroy {
    
    @Output() onProgressBarChange: EventEmitter<any> = new EventEmitter();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private progressBarService: ProgressBarService
    ) {
        this.sub = this.progressBarService.isPageLoading.subscribe(
            (isLoading: any) => {
                if (isLoading.visible) {
                    this.renderer.addClass(
                        this.el.nativeElement,
                        'd-block'
                    );
                    this.renderer.removeClass(
                        this.el.nativeElement,
                        'd-none'
                    );

                    this.onProgressBarChange.emit(isLoading.progressBar);

                } else {
                    this.renderer.addClass(
                        this.el.nativeElement,
                        'd-none'
                    );
                    this.renderer.removeClass(
                        this.el.nativeElement,
                        'd-block'
                    );

                    this.onProgressBarChange.emit(isLoading.progressBar);
                }
            }
        );
    }

    private sub: Subscription;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
