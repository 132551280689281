import { OnInit, OnDestroy, Component } from '@angular/core';
import { AuthService } from '@Workspace/auth';


@Component({
    selector: 'campaign-report-detail',
    templateUrl: './campaign-report-detail.component.html',
    // styleUrls: ['./campaign-detail.component.scss'],

})
export class CampaignReportDetailComponent implements OnInit {
    constructor(public authService: AuthService,
    ) { }

    currentUser = this.authService.userSettings;



    ngOnInit() {


    }
}



