import { Component, HostListener, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'rc-filedrop',
    templateUrl: './rc-filedrop.component.html',
    styleUrls: ['./rc-filedrop.component.scss']
})
export class FileDropComponent implements OnInit, OnDestroy {

    ngOnInit() {

        window.addEventListener("dragover", e => {
            e && e.preventDefault();
        }, false);
        window.addEventListener("drop", e => {
            e && e.preventDefault();
        }, false);

        let length = this.allowedExtensions.length;

        if (length > 0) {
            this.allowedList = '';
            this.allowedExtensions.forEach((x, i) => this.allowedList += (i + 1 < length) ? `${x}, ` : `${x}`);
        }
    }

    @Input('allowedExtensions') allowedExtensions: string[] = [];
    @Input('label') label: string;
    @Input('multiple') multiple: boolean = false;

    @Output('onFilesDropped') onFilesDropped: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Input() files: File[];
    @Input() disabled: boolean = false;

    allowedList: string = 'any';
    draggedOver: boolean = false;
    incorrectInput: boolean = false;
    errorMessage: string = '';
    private ngUnsubscribe = new Subject();

    @HostListener('dragover', ['$event']) onDragover(e) {
        e.preventDefault();
        e.stopPropagation();

        this.draggedOver = true;
    }

    @HostListener('dragleave', ['$event']) onDragleave(e) {
        e.preventDefault();
        e.stopPropagation();

        this.draggedOver = false;
    }

    @HostListener('drop', ['$event']) onDrop(e) {
        e.preventDefault();
        e.stopPropagation();

        this.errorMessage = '';
        this.incorrectInput = false;
        this.files = [];

        this.handleDrop(e.dataTransfer.files);
    }

    onFileSelected(event: any) {
        this.handleDrop(event.target.files);
    }

    private handleDrop(files: FileList) {
        const validExtensions = this.validateExtensions(files);

        if (!this.multiple && files.length > 1) {
            this.incorrectInput = true;
            this.errorMessage = 'More than one file uploaded';

            return;
        }

        if (validExtensions) {
            this.incorrectInput = false;
            this.errorMessage = '';
        } else {
            this.incorrectInput = true;
            this.errorMessage = 'Incorrect file type noticed';

            return;
        }

        this.files = Array.from(files);
        this.onFilesDropped.emit(files);
    }

    private validateExtensions(files: FileList): boolean {
        if (this.allowedExtensions.length === 0) {
            return true;
        }

        let extensions: string[] = [];
        const extensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi;

        Array.from(files).map(x =>
            x.name.match(extensionPattern)
                .map(ext =>
                    extensions.push(ext.toLowerCase())));

        const forbidden = extensions.filter((x) => this.allowedExtensions.indexOf(x) === -1);
        const valid = forbidden.length === 0;


        return valid;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}