
<div class="row mx-3 mt-3">
    
    <div class="col-xl-10 col-9 pl-0">
        <div class="card card-body">
            <div *ngIf="!!baseCampaign" class="col-md-11 my-3 p-3">
                <div *ngIf="!!standardDisplayCreative || !!premiumDisplayCreative || !!cartDisplayBannerCreative || !!ppuDisplayBannerCreative">
                    <span class="text-secondary"><strong>DISPLAY</strong></span>
                    <span class="mt-2 ml-2" *ngIf="this.urls.length>1"><strong>2/4</strong></span>

                    <div class="ml-5 pl-3" style=" border:0; border-left: 1px; border-style: solid; border-color: gray;">
                        <div *ngFor="let item of standardDisplayCreative; let i = index">
                            <div class="mb-3 mt-3 row ml-0" style=" display: flex; border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
                                <div class="col-9 p-2 text-center d-flex align-items-center">
                                    <label class="my-0 font-weight-bold pl-3">{{this.standardDisplayCreative[i].name ? 'Flight ' + (i+1) : this.standardDisplayCreative[i].displayName}}</label>
                                    <label class="my-0"> | {{this.standardDisplayCreative[i].name? this.standardDisplayCreative[i].name : 'Lorem Ipsum' }}</label>
                                </div>
                                <div class="col-3 p-1 float-right">
                                    <label class="my-0 float-right"> Accepted banner sizes:</label>
                                    <label class="my-0 float-right"> 160x600, 300x250, 728x90, 320x50</label>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-wrap">
                                <div class="mb-4" [ngClass]="isApprove? 'col-6':'col-4'" *ngFor="let item of standardDisplayCreative[i].creativeAssets; let i = index">
                                    <!-- <div>
                                        <h6 class="font-weight-normal text-left mb-2">Standard Banner {{i+1}}</h6>
                                    </div> -->
                                    <file-upload [headerTitle]="'Standard Banner' + ' ' + (i+1)" [isApprove]='isApprove' [footer]='booleanTrue' filetypes="png, jpg, jpeg, gif" size=300000 [campaign]="baseCampaign" [creative]="item" [asset]="i"></file-upload>

                                </div>
                            </div>
                        </div>

                        <div *ngFor="let item of premiumDisplayCreative; let i = index">
                            <div class="mb-3 mt-3 row ml-0" style=" display: flex; border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
                                <div class="col-9 p-2 text-center d-flex align-items-center">
                                    <label class="my-0 font-weight-bold pl-3">Premium Banner </label>
                                    <label class="my-0"> | Lorem Ipsum Creative</label>
                                </div>
                                <div class="col-3 p-1 float-right">
                                    <label class="my-0 float-right"> Accepted banner sizes:</label>
                                    <label class="my-0 float-right"> 160x600, 300x250, 300x600, 320x50, 728x90, 970x66, 970x90, 970x250</label>
                                </div>
                            </div>

                            <div class="mb-3 d-flex flex-wrap">
                                <div class="mb-4" [ngClass]="isApprove? 'col-6':'col-4'" *ngFor="let item of premiumDisplayCreative[i].creativeAssets; let i = index">
                                    <!-- <div>
                                        <h6 class="font-weight-normal text-left mb-2">Premium Banner {{i+1}}</h6>
                                    </div> -->
                                    <file-upload [headerTitle]="'Premium Banner' + ' ' + (i+1)" [isApprove]='isApprove' [footer]='booleanTrue' filetypes="png, jpg, jpeg, gif" size=300000 [campaign]="this.baseCampaign" [creative]="item" [asset]="i"></file-upload>

                                </div>
                            </div>
                        </div>

                        <div *ngFor="let item of cartDisplayBannerCreative; let i = index">
                            <div class="mb-3 mt-3 row ml-0" style=" display: flex; border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
                                <div class="col-9 p-2 text-center d-flex align-items-center">
                                    <label class="my-0 font-weight-bold pl-3">Abondened Cart Display Banner </label>
                                    <label class="my-0"> | Lorem Ipsum Creative</label>
                                </div>
                                <div class="col-3 p-1 float-right">
                                    <label class="my-0 float-right"> Accepted banner sizes:</label>
                                    <label class="my-0 float-right"> 160x600, 300x250, 728x90, 320x50</label>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-wrap">
                                <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'" *ngFor="let item of cartDisplayBannerCreative[i].creativeAssets; let i = index">
                                    <!-- <div>
                                        <h6 class="font-weight-normal text-left mb-2">Banner {{i+1}}</h6>
                                    </div> -->
                                    <file-upload [headerTitle]="'Banner' + ' ' + (i+1)" [isApprove]='isApprove' [footer]='booleanTrue' filetypes="png, jpg, jpeg, gif" size=300000 [campaign]="this.baseCampaign" [creative]="item" [asset]="i"></file-upload>

                                </div>
                            </div>
                        </div>

                        <div *ngFor="let item of ppuDisplayBannerCreative; let i = index">
                            <div class="my-3 row ml-0" style=" display: flex; border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
                                <div class="col-9 p-2 text-center d-flex align-items-center">
                                    <label class="my-0 font-weight-bold pl-3">Post Purchase Upsell Display Banner </label>
                                    <label class="my-0"> | Lorem Ipsum Creative</label>
                                </div>
                                <div class="col-3 p-1 float-right">
                                    <label class="my-0 float-right"> Accepted banner sizes:</label>
                                    <label class="my-0 float-right"> 160x600, 300x250, 728x90, 320x50</label>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-wrap">
                                <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'" *ngFor="let item of ppuDisplayBannerCreative[i].creativeAssets; let i = index">
                                    <div>
                                        <h6 class="font-weight-normal text-left mb-2">Banner {{i+1}}</h6>
                                    </div>
                                    <file-upload [headerTitle]="'Banner' + ' ' + (i+1)" [isApprove]='isApprove' [footer]='booleanTrue' filetypes="png, jpg, jpeg, gif" size=300000 [campaign]="this.baseCampaign" [creative]="item" [asset]="i"></file-upload>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let item of facebookStaticCreative; let i = index">
                    <div>
                        <span class="mb-3 ml-3 text-secondary"><strong>Facebook Static News Feed</strong></span>
                    </div>
                    <div class="ml-5 pl-3" style=" border:0; border-left: 1px; border-style: solid; border-color: gray;">
                        <div class="mb-4 mt-3 pt-3">

                            <div class="row">
                                <div class="col-2">
                                    <label><strong>Headline</strong></label>
                                </div>
                                <div class="col-8 mr-0 pr-0">
                                    <input type="text" class="mr-0 pr-0" #headline [(ngModel)]="headline.value" style="width: 100%; height: 3em;" [ngClass]="headline.value.length > 25 ? 'border border-2' : ''" (keyup)="onkeyup(headline.value, linkDescription.value, landingPage.value, bodyText.value, facebookPage.value)">
                                </div>
                                <div class="col-2 ml-0 pl-0">
                                    <label class="text-muted d-flex align-items-center justify-content-center border border-dark" style="border-left-width: 0 !important; width: 100%; height: 3em; font-size: 1em; ">{{25-headline.value.length}} CHARACTERS LEFT</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-2">
                                    <label><strong>Body Text</strong></label>
                                </div>
                                <div class="col-8 mr-0 pr-0">
                                    <input type="text" #bodyText [(ngModel)]="bodyText.value" style="width: 100%; height: 3em; " [ngClass]="bodyText.value.length > 90 ? 'border border-2' : ''" (keyup)="onkeyup(headline.value, linkDescription.value, landingPage.value, bodyText.value, facebookPage.value)">
                                </div>
                                <div class="col-2 ml-0 pl-0">
                                    <label class="text-muted d-flex align-items-center justify-content-center border border-dark" style="border-left-width: 0 !important; width: 100%; height: 3em; font-size: 1em; ">{{90-bodyText.value.length}} CHARACTERS LEFT</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-2">
                                    <label><strong>Link Description</strong></label>
                                </div>
                                <div class="col-8 mr-0 pr-0">
                                    <input type="text" #linkDescription [(ngModel)]="linkDescription.value" style="width: 100%; height: 3em;" [ngClass]="linkDescription.value.length > 25 ? 'border border-2' : ''" (keyup)="onkeyup(headline.value, linkDescription.value, landingPage.value, bodyText.value, facebookPage.value)">
                                </div>
                                <div class="col-2 ml-0 pl-0">
                                    <label class="text-muted d-flex align-items-center justify-content-center border border-dark" style="border-left-width: 0 !important; width: 100%; height: 3em; font-size: 1em; ">{{25-linkDescription.value.length}} CHARACTERS LEFT</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-2">
                                    <label><strong>Landing Page URL</strong></label>
                                </div>
                                <div class="col-8 mr-0 pr-0">
                                    <input type="text" #landingPage [(ngModel)]="landingPage.value" style="width: 100%; height: 3em;" [ngClass]="landingPage.value.length > 25 ? 'border border-2' : ''" (keyup)="onkeyup(headline.value, linkDescription.value, landingPage.value, bodyText.value, facebookPage.value)">
                                </div>
                                <div class="col-2 ml-0 pl-0">
                                    <label class="text-muted d-flex align-items-center justify-content-center border border-dark" style="border-left-width: 0 !important; width: 100%; height: 3em; font-size: 1em; ">{{25-landingPage.value.length}} CHARACTERS LEFT</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-2">
                                    <label><strong>Facebook Page URL</strong></label>
                                </div>
                                <div class="col-8 mr-0 pr-0">
                                    <input type="text" #facebookPage [(ngModel)]="facebookPage.value" style="width: 100%; height: 3em;" [ngClass]="facebookPage.value.length > 25 ? 'border border-2' : ''" (keyup)="onkeyup(headline.value, linkDescription.value, landingPage.value, bodyText.value, facebookPage.value)">
                                </div>
                                <div class="col-2 ml-0 pl-0">
                                    <label class="text-muted d-flex align-items-center justify-content-center border border-dark" style="border-left-width: 0 !important; width: 100%; height: 3em; font-size: 1em; ">{{25-facebookPage.value.length}} CHARACTERS LEFT</label>
                                </div>
                            </div>

                        </div>

                        <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
                            <file-upload [isApprove]='isApprove' filetypes="png, jpg, jpeg, gif" size=300000 [footer]='booleanFalse' [campaign]="this.baseCampaign" [creative]="item" [asset]="i"></file-upload>

                        </div>
                    </div>
                </div>


                <div *ngFor="let item of facebookDynamicVideoCreative; let i = index">
                    <div class="mb-3">
                        <span class="mb-3 ml-3 text-secondary"><strong>Facebook Dynamic News Video</strong></span>
                    </div>
                    <div class="ml-5 pl-3" style=" border:0; border-left: 1px; border-style: solid; border-color: gray;">
                        <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
                            <file-upload [isApprove]='isApprove' filetypes="mp4" size=30000000 [footer]='booleanFalse' [campaign]="this.baseCampaign" [creative]="item" [asset]="i"></file-upload>

                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row-reverse  bd-highlight mt-3 mb-3">
                    <div class="p-2 bd-highlight"><button type="button" pButton class="p-button-primary" label="Submit"></button></div>
                    <div class="p-2 bd-highlight"><button type="button" pButton (click)="save()" class="btn flat-btn" label="Save & exit to Home"></button></div>
                    <div class="p-2 bd-highlight"><button type="button" pButton class="btn flat-btn" label="Cancel"></button></div>
                </div>

            </div>


        </div>
    </div>
</div>