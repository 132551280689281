<div class="row">
    <h2 class="col-6">Login</h2>
</div>
<div class="row">
    <div class="col">
        <p>{{loginMessage}}</p> 
    </div>
</div>

<div class="row" *ngIf="!userLoggedIn">
    <label class="col-3">Username</label>
    <div class="col-3">
        <input pInputText [(ngModel)]="loginModel.userName" />
    </div>
</div>

<div class="row" *ngIf="!userLoggedIn">
    <label class="col-3">Password</label>
    <div class="col-3">
        <input pInputText [(ngModel)]="loginModel.password" type="password" />
    </div>
</div>

<div class="row">
    <div class="col-3">
        <p-button *ngIf="!userLoggedIn" (click)="onSubmit()" label="Login"></p-button>
        <p-button *ngIf="userLoggedIn"  (click)="onLogout()" label="Logout"></p-button>
    </div>
    <div class="col" *ngIf="this.errorMessage!=''">       
        <p-message severity="error" text=""></p-message>
        <label>{{errorMessage}}</label>
    </div>
</div>

<div class="row">
    <div class="col">&nbsp;</div>
</div>

<div class="row">
    <div class="col-3">
        <p-button *ngIf="userLoggedIn" routerLink="user-component" icon='pi pi-info' label="Users info and settings"></p-button>
        <p-button *ngIf="!userLoggedIn" routerLink="reset-password-component" label="Password reset" class="p-button-rounded p-button-warning"></p-button>
    </div>
</div>

<div class="row">
    <div class="col">&nbsp;</div>
</div>

<div class="row" *ngIf="userLoggedIn">
    <div class="col-3" >
        <p>Email management</p>
        <p-button routerLink="manage-emails-component" label="Manage emails"></p-button>
    </div>
    <div class="col-3" >
        <p>User view (dynamic table structure)</p>
        <p-button routerLink="view-users-component" label="View users"></p-button>
    </div>
    <div class="col-3" >
        <p>User management (static table structure)</p>
        <p-button routerLink="manage-users-component" label="Manage users"></p-button>
    </div>
    <div class="col-3" >
        <p>Role management (static table structure)</p>
        <p-button routerLink="manage-roles-component" label="Manage roles"></p-button>
    </div>
    
</div>