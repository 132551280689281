 <div *ngIf="!!errorMessage" class="alert alert-danger">
        <b>{{ errorMessage}}</b>
    </div>
 
    <p-tabView>
        <p-tabPanel header="Gender">

            <div class="pl-3 row align-items-center pt-2">
                <div class="mr-3">
                    <p-checkbox [(ngModel)]="isNoGenderTargeting" (onChange)="onGenderChange($event, 'noGender')" binary="true" inputId="none" [disabled]="disabled"></p-checkbox></div>
                <div> No Gender Targeting </div>
            </div>
            <div class="pl-3 row align-items-center pt-2">
                <div class="mr-3">
                    <p-checkbox [binary]="true" [(ngModel)]="isMale" (onChange)="onGenderChange($event, 'male')" [disabled]="disabled || isNoGenderTargeting"></p-checkbox>
                </div>
                <div> Male </div>
            </div>
            <div class="pl-3 row align-items-center pt-2">
                <div class="mr-3">
                    <p-checkbox [binary]="true" [(ngModel)]="isFemale" (onChange)="onGenderChange($event, 'female')" [disabled]="disabled || isNoGenderTargeting"></p-checkbox>
                </div>
                <div> Female </div>
            </div>
        </p-tabPanel>

        <p-tabPanel header="Age">
            <div class=" row pt-2 pl-1 p-field-checkbox">
                <p-checkbox [(ngModel)]="isNone" (onChange)="OnUnknownChanged($event)" binary="true" inputId="none" [disabled]="disabled"></p-checkbox>
                <div style="margin-left: 10px;"> No Age Targeting</div>
            </div>
                <div class="col-xs-12 pt-2">
                    <p-slider [step]="10" [min]="15" [max]="75" [disabled]="isNone || disabled" (onSlideEnd)="handleChange($event)" (onChange)="onChange($event)" [style]="{'width':'18em'}" [(ngModel)]="rangeValues" [range]="true"></p-slider>
                </div>
                <div class="col-xs-12 pt-2">
                    <label>18</label>
                    <label class="pl-4">25</label>
                    <label class="pl-4">35</label>
                    <label class="pl-4">45</label>
                    <label class="pl-4">55</label>
                    <label class="pl-4">65</label>
                    <label class="pl-4">65+</label>
                </div>

                <div class="row pl-1 pt-1">
                    <h6 *ngIf="!isNone">Ages: <label *ngIf="rangeValues[0]== 15">18</label> <label *ngIf="rangeValues[0] != 15">{{rangeValues[0]}}</label> - <label *ngIf="rangeValues[1] == 75">65+</label><label *ngIf="rangeValues[1]!=75">{{rangeValues[1]}}</label></h6>
                </div>
        </p-tabPanel>

        <p-tabPanel header="Household Income">
            <div class=" row pt-2 pl-1 p-field-checkbox">
                <p-checkbox (onChange)="OnRangeChanged($event)" [(ngModel)]="isNoneIncome" binary="true" inputId="none" [disabled]="disabled"></p-checkbox>
                <div style="margin-left: 10px;"> No Household Income Targeting</div>
            </div>
                <div class="col-xs-12 pt-2">
                    <p-slider [step]="10" [min]="15" [max]="65" [disabled]="isNoneIncome || disabled" (onSlideEnd)="handleIncomeChange($event)" (onChange)="onChangeIncome($event)" [style]="{'width':'35em'}" [(ngModel)]="rangeValuesIncome" [range]="true"></p-slider>
                </div>
                <div class="col-xs-12 pt-2">
                    <label class="pr-4">Top 10%</label>
                    <label class="pr-5">11-20%</label>
                    <label class="pr-5">21-30%</label>
                    <label class="pr-5">31-40%</label>
                    <label class="pr-4">41-50%</label>
                    <label class="">Lower 50%</label>
                </div>
        </p-tabPanel>

    </p-tabView>



