
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-seleton-loader',
    template: `
    <div [ngStyle]="getMyStyles()" class="skelt-load loader"></div>
  `,
    styleUrls: ['./skeleton-loader.component.scss']
})
export class SeletonLoaderComponent implements OnInit {

    @Input() cWidthPx;
    @Input() cHeightPx;
    @Input() circle: boolean;
    @Input() cWidthPc
    @Input() cHeightPc;

    constructor() { }



    ngOnInit() {
    }

    getMyStyles() {
        var width = this.cWidthPx ? 'width.px' : 'width.%';
        var height = this.cHeightPx ? 'height.px' : 'height.%'
        const myStyles = {};
        myStyles[width] = this.cWidthPx ? this.cWidthPx : (this.cWidthPc ?? ''),
            myStyles[height] = this.cHeightPx ? this.cHeightPx : (this.cHeightPc ?? ''),
            myStyles['border-radius'] = this.circle ? '50%' : ''

        return myStyles;
    }

}