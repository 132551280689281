import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem, ConfirmationService, MessageService } from 'primeng/api';
import { componentFadeIn } from '../../../route-animations';
import { IGridOptions } from '@Workspace/interfaces';
import { Functions, Constants } from '@Workspace/common';
import {
    AccountController, TeamController
} from '@Workspace/_generated/services';
import { IAccountDto, IUserDto, IUserAccountDto, IUserCreateDto, ITeamDto } from '@Workspace/_generated/interfaces';
import { eUserRole, eUserAccountRole, eEntityStatus } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../services/campaign.service';
import { SettingsService, PageLoaderService } from '@Workspace/services';
import { takeUntil } from 'rxjs/operators';
import { GridComponent } from '../../../../shared-core/components/grid/rc-grid.component';

@Component({
    selector: 'team-list',
    templateUrl: './team-list.component.html',
    styleUrls: ['team-list.component.scss'],
    animations: [componentFadeIn]
})
export class TeamListComponent implements OnInit, OnDestroy {

    constructor(
        public teamController: TeamController,
        public authService: AuthService,
        private confirmationService: ConfirmationService,
        private settingsService: SettingsService,
        private campaignService: CampaignService,
        private pageLoaderService: PageLoaderService,
        public messageService: MessageService,

    ) {

    }

    options: IGridOptions;
    loaded: boolean = false;
    expandedRows: any = [];
    teams: ITeamDto[] = [];
    selectedTeam: ITeamDto;
    isEditDialogOpen: boolean;
    isEditDialogDisplayed: boolean;
    currentUser = this.authService.userSettings;
    private searchParameter: string;
    constants = Constants;
    public enums: any = {
        enumUserRole: eUserRole,
    }
    @ViewChild('teamTable') teamTable: GridComponent;

    ngUnsubscribe: Subject<void> = new Subject();

    ngOnInit() {
        this.campaignService.getSearchPareameter().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            this.composeGridColumns(x);
            this.searchParameter = x;
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    composeGridColumns(searchParameter?: string) {
        if (this.teamTable) {
            this.teamTable.resetSkipTake();
        }
        this.options = {} as IGridOptions;
        this.options.columns = [
            {
                label: 'Name',
                value: 'name'
            },
            {
                label: 'Action',
                value: '',
                align: 'right'
            }
        ];

        this.options.isOData = true;
        this.options.isSimpleView = false;
        this.options.sortField = 'Name';
        this.composeFilter(searchParameter);

        Functions.composeGridOptions(
            this.options,
            'Name',
            this.settingsService.createApiUrl('Team/GetTeams'),
            false
        );
    }

    composeFilter(searchParameter?: string) {
        this.options.filter = `EntityStatus eq Learfield.Mogo_Fan365.Domain.Enums.eEntityStatus'${eEntityStatus.Active} '`

        if (!!searchParameter) {
            const searchTerm = searchParameter.replace(
                /\'/g,
                "''"
            );
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') + ` (contains(Name, '${searchTerm}'))`

        }

        let options = this.options;
        this.options = Object.assign({}, options);
    }

    getTextAlignClass(textAlign: string): string {
        let textAlignClass: string = "text-left";
        switch (textAlign) {
            case 'left':
                textAlignClass = "text-left";
                break;
            case 'center':
                textAlignClass = "text-center";
                break;
            case 'right':
                textAlignClass = "text-right";
                break;
        }
        return textAlignClass;
    }

    openEditDialog(event) {
        this.selectedTeam = event;
        this.showHideDialog(true);
    }

    showHideDialog(event: boolean) {
        this.isEditDialogDisplayed = event;
        setTimeout(() => {
            this.isEditDialogOpen = event;
        }, 0);
        if (!event) {
            this.composeGridColumns(this.searchParameter);
        }
    }

    remove(team) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'teamDelete',
            message:
                'This action will delete this team from HUB. Are you sure you want to proceed?',
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                this.pageLoaderService.showPageLoader();
                team.entityStatus = eEntityStatus.Deleted;
                this.teamController.CreateTeam(team).subscribe(x => {
                    this.campaignService.setRefresh(true)
                    this.messageService.add({ severity: 'success', detail: 'Team removed successfully' });


                    this.composeGridColumns(this.searchParameter);

                });
            }
        });

    }

}
