import { Pipe, PipeTransform } from '@angular/core';
import { Functions } from '@Workspace/common';


@Pipe({ name: 'enumString' })
export class EnumStringPipe implements PipeTransform {

    constructor() { }

    transform(value: number, type: object): string {
        return Functions.getEnumName(type, value);
    }
}
