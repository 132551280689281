import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';

import { PageLoaderService, StorageService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';

import { Constants } from '@Workspace/common';

@Injectable()
export class LoggedInGuard  {
    constructor(
        private authService: AuthService,
        private storageService: StorageService,
        private router: Router,
        private pageLoaderService: PageLoaderService
    ) { }

    private route: Route;

    public canLoad(route: Route) {
        this.route = route;
        return this.canActivatePage();
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.canActivatePage();
    }

    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.canActivatePage();
    }

    private canActivatePage(): boolean {
        if (this.authService.isLogged) {
            if (!this.authService.userSettings.permissions.isLearfieldClient)
                return true;
            else {
                this.storageService.set(
                    Constants.LAST_URL_KEY,
                    window.location.hash.substr(2)
                );

                this.router.navigate(['/fan365']);
                return false;
            }
            //   return true;

        } else {
            this.storageService.set(
                Constants.LAST_URL_KEY,
                window.location.hash.substr(2)
            );

            this.router.navigate(['/sign-in']);
            return false;
        }
    }
}
