import { Injectable } from '@angular/core';

import { IStorageService, IStorageExpirationItem } from '@Workspace/interfaces';

@Injectable()
export class StorageService implements IStorageService {
    constructor() {
        this.storage = localStorage;
    }

    private storage: Storage;
    private EXPIRE_PREFIX: string = 'EXPIRATION_';

    public async get(key: string, noExpire?: boolean) {
        var validKey = await this.checkAndGetKey(key, noExpire);

        if (!validKey) {
            return null;
        }

        var keyItem = this.storage.getItem(validKey);

        if (keyItem === undefined || keyItem === 'undefined') {
            return '';
        }

        var itemFromStorage: any;
        try {
            itemFromStorage = JSON.parse(keyItem);
        } catch (e) {
            itemFromStorage = keyItem;
        }

        if (this.isExpirationKey(validKey)) {
            var isValid = await this.isItemValid(validKey, itemFromStorage);

            return isValid ? itemFromStorage.Value : null;
        } else {
            return itemFromStorage;
        }
    }

    public async set(key: string, value: any);
    public async set(key: string, value: any, expiration: Date);
    public async set(
        key: string,
        value: any,
        expiration?: Date
    ): Promise<void> {
        if (!expiration) {
            this.storage.setItem(key, JSON.stringify(value));
        } else {
            await this.setWithExpiration(
                this.EXPIRE_PREFIX + key,
                expiration,
                value
            );
        }
    }

    public async remove(key: string) {
        var validKey = await this.checkAndGetKey(key);
        if (!validKey) {
            return;
        }

        this.storage.removeItem(validKey);
    }

    public async exist(key: string) {
        var validKey = await this.checkAndGetKey(key);
        if (this.isExpirationKey(validKey)) {
            var itemFromStorage = JSON.parse(this.storage.getItem(validKey));

            return await this.isItemValid(validKey, itemFromStorage);
        }

        return !!validKey;
    }

    public async getExpiration(key: string) {
        var validKey = await this.checkAndGetKey(key);
        if (this.isExpirationKey(validKey)) {
            var itemFromStorage = JSON.parse(this.storage.getItem(validKey));

            if (await this.isItemValid(validKey, itemFromStorage)) {
                return new Date(itemFromStorage.Expiration);
            }
        }

        return undefined;
    }

    public async clear(...excludedKeys: string[]) {
        if (!Array.isArray(excludedKeys) || excludedKeys.length === 0) {
            this.storage.clear();
        }

        var keys = Object.keys(this.storage);

        keys.forEach(key => {
            if (excludedKeys.indexOf(key) < 0) {
                this.storage.removeItem(key);
            }
        });
    }

    private async setWithExpiration(key: string, expiration: Date, value: any) {
        var itemToStorage: IStorageExpirationItem = {
            Expiration: expiration.toString(),
            Value: value
        };

        return this.storage.setItem(key, JSON.stringify(itemToStorage));
    }

    private async checkAndGetKey(key: string, noExpire?: boolean) {
        var keys = Object.keys(this.storage);
        var resultKey: string = null;
        var expirationKey = this.EXPIRE_PREFIX + key;

        keys.some(value => {
            if (
                value === key ||
                (noExpire !== true && value === expirationKey)
            ) {
                resultKey = value;

                return true;
            }

            return false;
        });

        return resultKey;
    }

    private isExpirationKey(key: string): boolean {
        return key && key.indexOf(this.EXPIRE_PREFIX) >= 0;
    }

    private async isItemValid(key: string, item: IStorageExpirationItem) {
        if (
            !!item &&
            !!item.Expiration &&
            new Date(item.Expiration).getTime() > new Date().getTime()
        ) {
            return true;
        }

        await this.remove(key);

        return false;
    }
}
