import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Subject} from 'rxjs';
import { AuthService } from '@Workspace/auth';
import { eAccountType, eStandardCampaginChannel, eUserRole } from '@Workspace/_generated/enums';

@Component({
    selector: 'campaign-dates-dialog',
    templateUrl: './campaign-dates-dialog.component.html',
    styleUrls: ['./campaign-dates-dialog.component.scss']
})
export class CampaignDatesDialog implements OnInit {
    currentUser = this.authService.userSettings;
    isVisible: boolean = false;
    defaultValues: any;
    editableFields: boolean[] = [];
    minStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    maxStartDate: Date;
    isStartDateChanged: boolean;
    isEndDateChanged: boolean;
    startDate: Date = new Date;
    endDate: Date = new Date;
    baseCampaign: IBaseCampaignDto;
    disableEndDate: boolean = false;
    validationMessage:string = '';

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public campaignController: CampaignController,
        private authService: AuthService,
    ) { }

    ngOnInit() {

    }

    private ngUnsubscribe = new Subject();

    openDialog(startDate: any, endDate: any, minStartDate: Date, maxEndDate: Date, minLength: number, baseCampaign: IBaseCampaignDto) {
        this.defaultValues = { ...arguments };
        this.startDate = !!startDate? new Date(startDate) : null;
        this.endDate = !!endDate?  new Date(endDate): null;
        this.baseCampaign = baseCampaign;

        if (baseCampaign.campaignType == eAccountType.OAS){
            this.campaignController.GetAllSeasonMapping().subscribe(data => {
                var seasons = data.filter(x => x.seasonId == baseCampaign.seasonMapping.seasonId);
                seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());

                this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
                this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;

                minStartDate = seasons[0].startDate;
                maxEndDate = seasons[3].endDate;

                if (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                {
                    let nextDateFromToday = new Date();
                    nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);
                    if (new Date(minStartDate) < nextDateFromToday ) {
                        minStartDate = nextDateFromToday;
                    }

                    this.minStartDate = new Date(minStartDate);
                    this.minEndDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);

                    this.minEndDate.setDate(this.minStartDate.getDate() + minLength);
                    this.maxEndDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxEndDate.getDate() - minLength);

                    if(baseCampaign.campaignInventoryItems[0].inventoryItemOAS.pageTakeover || baseCampaign.campaignInventoryItems[0].inventoryItemOAS.premiumPageTakeover)
                        this.disableEndDate = true;
                }
                else{
                    this.minStartDate = new Date(minStartDate);
                    this.minEndDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);

                    this.minEndDate.setDate(this.minStartDate.getDate() + 1);
                    this.maxEndDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxEndDate.getDate() - 1);

                    if(baseCampaign.campaignInventoryItems[0].inventoryItemOAS.pageTakeover || baseCampaign.campaignInventoryItems[0].inventoryItemOAS.premiumPageTakeover)
                        this.disableEndDate = true;
                }

                
                
            });
        }
        else if (baseCampaign.campaignType == eAccountType.Social || baseCampaign.campaignType == eAccountType.Content){
            this.campaignController.GetAllSeasonMapping().subscribe(data => {
                var seasons = data.filter(x => x.seasonId == baseCampaign.seasonMapping.seasonId);
                seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());

                this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
                this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;

                minStartDate = seasons[0].startDate;
                maxEndDate = seasons[3].endDate;

                if (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                {
                    let nextDateFromToday = new Date();
                    nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);
                    let datePlus14 = new Date();
                    datePlus14.setDate(datePlus14.getDate() + 14);
                    let datePlus7 = new Date();
                    datePlus7.setDate(datePlus7.getDate() + 7);
                    var minDateFromToday = nextDateFromToday;
                    if(this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Content || this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Snap_AR)
                        minDateFromToday = datePlus7;
                    else if(this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation 
                        || this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation_Premium
                        || this.baseCampaign.creatives[0].creativeTypeId == eStandardCampaginChannel.Digital_Game)
                        minDateFromToday = datePlus14;
                    
                    if (new Date(minStartDate) < minDateFromToday) {
                        minStartDate = minDateFromToday;
                    }

                    this.minStartDate = new Date(minStartDate);
                    this.minEndDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);

                    this.minEndDate.setDate(this.minStartDate.getDate() + minLength);
                    this.maxEndDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxEndDate.getDate() - minLength);
                }
                else{
                    this.minStartDate = new Date(minStartDate);
                    this.minEndDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);
                    this.minEndDate.setDate(this.minStartDate.getDate() + 1);
                    this.maxEndDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxEndDate.getDate() - 1);
                }
            });
        }
        else if (baseCampaign.campaignType == eAccountType.FAN365)
        {
            this.campaignController.GetAllSeasonMapping().subscribe(data => {
                var seasons = data.filter(x => x.seasonId == baseCampaign.seasonMapping.seasonId);
                seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());

                this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
                this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;

                if (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                {
                    minStartDate = new Date(this.baseCampaign.seasonMapping.startDate);
                    maxEndDate = new Date(this.baseCampaign.seasonMapping.endDate);
                    let twoDaysFromToday = new Date();
                    twoDaysFromToday.setDate(twoDaysFromToday.getDate() + 2);
                    if (new Date(minStartDate) < twoDaysFromToday ) {
                        minStartDate = twoDaysFromToday;
                    }
        
                    this.minStartDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxStartDate.getDate() - 30);
                    this.minEndDate = new Date(this.minStartDate);
                    this.minEndDate.setDate(this.minEndDate.getDate() + 30);
                    this.maxEndDate = new Date(maxEndDate);
                }
                else 
                {
                    minStartDate = seasons[0].startDate;
                    maxEndDate = seasons[3].endDate;
                    this.minStartDate = new Date(minStartDate);
                    this.maxStartDate = new Date(maxEndDate);
                    this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
                    this.minEndDate = new Date(this.minStartDate);
                    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
                    this.maxEndDate = new Date(maxEndDate);
                }
                
                });
        }
        else
        {
            this.startDate = this.baseCampaign.startDate != null ? new Date(this.baseCampaign.startDate) : null;
            this.endDate = this.baseCampaign.endDate != null ? new Date(this.baseCampaign.endDate) : null;

            minStartDate = new Date(this.baseCampaign.seasonMapping.startDate);
            maxEndDate = new Date(this.baseCampaign.seasonMapping.endDate);

            let nextDateFromToday = new Date();
                    nextDateFromToday.setDate(nextDateFromToday.getDate() + 1);
            if (new Date(minStartDate) < nextDateFromToday && (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin)) {
                minStartDate = nextDateFromToday;
            }
    
            this.minStartDate = new Date(minStartDate);
            this.maxStartDate = new Date(maxEndDate);
            this.maxStartDate.setDate(this.maxStartDate.getDate() - 30);
            this.minEndDate = new Date();
            this.minEndDate.setDate(this.minStartDate.getDate() + 30);
            this.maxEndDate = new Date(maxEndDate);
        }

        this.isVisible = true;

    }

    resetDefault() {
        this.startDate = this.defaultValues[0];
        this.endDate = this.defaultValues[1];
    }

    save() {
        const dialogResult = {
            startDate: this.startDate,
            endDate: this.endDate,
            isStartDateChanged: this.isStartDateChanged,
            isEndDateChanged: this.isEndDateChanged

        };

        this.onSave.emit(dialogResult);
        this.isVisible = false;
    }

    cancel() {
        this.isVisible = false;
    }


    onStartDateChange(startDate: Date) {
        if (!!startDate) {
            this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            if(this.baseCampaign.campaignType == eAccountType.FAN365 && (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin))
                this.minEndDate.setDate(this.startDate.getDate() + 30);
            else
                this.minEndDate.setDate(this.startDate.getDate() + 1);

            this.isStartDateChanged = true;
            this.validationMessage= '';

            if(this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.pageTakeover)
            {
                // from ticket
                //Days live for every campaign should be a single day not a range of days. For example, if a campaign is to run on 9/4 the range should be 9/4 to 9/4.
                this.endDate = new Date(startDate);
                if(this.endDate <= this.maxEndDate){
                    this.isEndDateChanged = true;
                    this.validationMessage= '';
                }
                    
                else{
                    this.validationMessage= 'Campaign end date is higher than season end date.';
                }
            }

            else if(this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignInventoryItems[0].inventoryItemOAS.premiumPageTakeover)
            {
                // from ticket
                //Days live for every campaign should be a 3 days for Premium Page Takeover campaigns
                this.endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                this.endDate.setDate(this.endDate.getDate() + 2);
                
                if(this.endDate <= this.maxEndDate){
                    this.isEndDateChanged = true;
                    this.validationMessage= '';
                }
                else{
                    this.validationMessage= 'Campaign end date is higher than season end date.';
                }
            }
        }

        if (startDate == null || startDate > this.endDate) {
            this.validationMessage= 'Campaign dates are wrong.';
            this.endDate = null;
            this.isEndDateChanged = true;
        }   

    }

    onEndDateChange(endDate: Date) {

        if (!!endDate) {
            this.maxStartDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
            if(this.baseCampaign.campaignType == eAccountType.FAN365 && (this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin))
                this.maxStartDate.setDate(this.endDate.getDate() - 30);
            else
                this.maxStartDate.setDate(this.endDate.getDate() - 1);
                
            this.isEndDateChanged = true;
            this.validationMessage = '';

            if (this.startDate > this.maxStartDate) {
                this.startDate = this.maxStartDate;
                this.isStartDateChanged = true;
            }

        }

        if (this.startDate > this.maxStartDate) {
            this.startDate = null;
            this.isStartDateChanged = true;
        }
    }
}