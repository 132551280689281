<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>Unlock Features</p-header>


    <!-- <ng-template> -->
        <div class="mb-2 mt-2 row align-items-center" *ngIf="isLearfieldCampaign">
            <div class="col-md-8 font-weight-bold">
                Site tracking can edit
            </div>
            <div class="col-md-4">
                <p-inputSwitch [(ngModel)]="isSiteVisitTrackingUnlocked"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="isLearfieldCampaign">
            <div class="col-md-8 font-weight-bold">
                Conversion tracking can edit
            </div>
            <div class="col-md-4">
                <p-inputSwitch [(ngModel)]="isConversionTrackingUnlocked"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="isLearfieldCampaign">
            <div class="col-md-8 font-weight-bold">
                Date range can edit
            </div>
            <div class="col-md-4">
                <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
                <p-inputSwitch [(ngModel)]="isDataRangeUnlocked"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="isLearfieldCampaign">
            <div class="col-md-8 font-weight-bold">
                Interest Targeting can edit
            </div>
            <div class="col-md-4">
                <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
                <p-inputSwitch [(ngModel)]="isInterestSegmentsUnlocked"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="isLearfieldCampaign">
            <div class="col-md-8 font-weight-bold">
                Demographic targeting can edit
            </div>
            <div class="col-md-4">
                <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
                <p-inputSwitch [(ngModel)]="isDemographicTargetingUnlocked"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center">
            <div class="col-md-8 font-weight-bold">
                Geographic targeting can edit
            </div>
            <div class="col-md-4">
                <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
                <p-inputSwitch [(ngModel)]="isGeographicTargetingUnlocked"></p-inputSwitch>
            </div>
        </div>

        <div class="mb-2 row align-items-center" *ngIf="isLearfieldCampaign">
            <div class="col-md-8 font-weight-bold">
                Zip codes targeting can edit
            </div>
            <div class="col-md-4">
                <!-- <p-multiSelect [options]="states" [(ngModel)]="selectedStates" [virtualScroll]="yes" itemSize="30"></p-multiSelect> -->
                <p-inputSwitch [(ngModel)]="isGeographicTargetingZipCodesUnlocked"></p-inputSwitch>
            </div>
        </div>
    <!-- </ng-template> -->


    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>
