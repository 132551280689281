import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { eCallToAction, eVideoType } from '@Workspace/_generated/enums';
import { IBaseCampaignDto, ICreativeAssetDto, ICreativeDto, IDetailJson } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';
import { Constants, Functions, LookUps } from '@Workspace/common';
import { CartItemService } from 'apps/core/src/app/modules/shared-core/components/card-item/card-item.service';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'linkedin-carousel',
    templateUrl: './linkedin-carousel.component.html',
    styleUrls: ['./linkedin-carousel.component.scss']
})
export class LinkedinCarouselComponent implements OnInit, OnDestroy {
    constructor(
        private cardItemService: CartItemService,
        private authService: AuthService,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() landingPage: string;
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    
    ngUnsubscribe = new Subject();
    constants = Constants;
    functions = Functions;
    currentUser = this.authService.userSettings;

    isDestinationUrlOk: boolean = true;
    maxNumberOfFlights: number = 1;
    isUploadOk: boolean = true;

    ngOnInit() {
        this.creative.creativeAssets.sort((x, y) => { return <any>new Date(x.createdOn) - <any>new Date(y.createdOn) });

        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId && x.creativeGroup == this.creative.creativeGroup);
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            });

        this.creative.creativeAssets.forEach(x => {
            if (x.detailJson == null) {
                x.detailJson = {} as IDetailJson;
                x.detailJson.headline = '';
                x.detailJson.bodyText = [];
                x.detailJson.bodyText.push('');
                x.detailJson.linkDescription = '';
                x.detailJson.landingPageUrl = '';
                x.detailJson.facebookPageUrl = '';
                x.detailJson.videoType = eVideoType.Not_set;
                // x.detailJson.destinationUrl = '';
                x.detailJson.campaignTitle = '';
                x.detailJson.advertiser = '';
                x.detailJson.companyName = '';
                x.detailJson.shortTitle = '';
                x.detailJson.longTitle = '';
                x.detailJson.shortDescription = '';
                x.detailJson.longDescription = '';
                x.detailJson.callToAction = '';
                x.detailJson.headline2 = '';
                x.detailJson.description = '';
                x.detailJson.path1 = '';
                x.detailJson.path2 = '';
                x.detailJson.displayUrl = '';
                x.detailJson.adGroup = '';
                //x.inStreamVideoJson.videoType = eVideoType.Not_set;
                x.detailJson.postText = null;
                x.detailJson.privacyPolicy = null;
                x.detailJson.customDisclaimer = null;
                x.detailJson.copyOption1 = null;
                x.detailJson.copyOption2 = null;
                x.detailJson.buttonText = null;
                x.detailJson.destinationUrl = null;
                x.detailJson.storiesType = null;
                x.detailJson.introductoryText = '';
            }
        });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    addCard() {
        if (this.creative.creativeAssets.length < 10) {

            let newAsset = {} as ICreativeAssetDto;
            newAsset.id = Constants.GuidEmpty;
            newAsset.width = 0;
            newAsset.height = 0;
            newAsset.isValid = null;
            newAsset.creativeId = this.creative.id;
            newAsset.fileType = null;
            newAsset.fileSize = 0;
            newAsset.imageUrl = null;
            newAsset.fileName = null;
            newAsset.url = null;
            newAsset.isChanged = null;
            newAsset.isUploadValid = null;
            newAsset.isDeleted = null;

            if (newAsset.detailJson == null) {
                newAsset.detailJson = {} as IDetailJson;

                newAsset.detailJson.headline = '';
                newAsset.detailJson.bodyText = [];
                newAsset.detailJson.bodyText.push('');
                newAsset.detailJson.linkDescription = '';
                newAsset.detailJson.landingPageUrl = '';
                newAsset.detailJson.facebookPageUrl = '';
                newAsset.detailJson.videoType = 0;
                // newAsset.detailJson.destinationUrl = '';
                newAsset.detailJson.campaignTitle = '';
                newAsset.detailJson.advertiser = '';
                newAsset.detailJson.companyName = '';
                newAsset.detailJson.shortTitle = '';
                newAsset.detailJson.longTitle = '';
                newAsset.detailJson.shortDescription = '';
                newAsset.detailJson.longDescription = '';
                newAsset.detailJson.callToAction = '';
                newAsset.detailJson.headline2 = '';
                newAsset.detailJson.description = '';
                newAsset.detailJson.path1 = '';
                newAsset.detailJson.path2 = '';
                newAsset.detailJson.displayUrl = '';
                newAsset.detailJson.adGroup = '';
                newAsset.detailJson.postText = null;
                newAsset.detailJson.privacyPolicy = null;
                newAsset.detailJson.customDisclaimer = null;
                newAsset.detailJson.copyOption1 = null;
                newAsset.detailJson.copyOption2 = null;
                newAsset.detailJson.buttonText = null;
                newAsset.detailJson.destinationUrl = null;
                newAsset.detailJson.storiesType = null;
                newAsset.detailJson.introductoryText = '';
            }
            this.creative.creativeAssets.push(newAsset);

        }
    }

    validationDestinationUrlMessage(i: number) {
        let textToMacth = this.creative.creativeAssets[i].detailJson.destinationUrl;
        // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?%#[\]@!\$&'\(\)\*\+,;=.]+$/;
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        var found = textToMacth.match(regex);
        this.isDestinationUrlOk = true;
        if (found == null) {
            this.isDestinationUrlOk = false;
        }
    }
}