<p-dialog [modal]="true" [contentStyle]="{'overflow': 'visible'}" [(visible)]="isVisible" [closable]="false">
    <p-header>{{action}} Creative Flight</p-header>

    <!-- <ng-template> -->
    <div *ngIf="action == 'Remove' " class="mb-2 mt-2 row align-items-center">
        <div class="col-lg-12 col-12 mb-3">
            <label><strong>You are about to remove &nbsp;</strong></label> 
            <label style="color: var(--text-color-primary) !important"><strong>{{ creative.creativeName ? creative.creativeName : creative.name}}</strong></label>
        </div>
        <div class="col-lg-12 col-12 mb-3" *ngIf="maxCreativeGroup != creativeGroup">
            <label>You are deleting a creative flight. Please adjust the dates of each flight so there is always a flight delivering throughout the life od the campaign.</label>
        </div>
        <div class="col-lg-12 col-12 mb-3">
            <label>Remember, your campaign will run from {{ (!!baseCampaign.startDate ? baseCampaign.startDate : null) | date : constants.DEFAULT_FORMAT_DATE_US}} to {{(!!baseCampaign.endDate ? baseCampaign.endDate : null) | date : constants.DEFAULT_FORMAT_DATE_US}}</label>
        </div>
    </div>

    <div *ngIf="action == 'New'" class="mb-2 mt-2 row align-items-center">
        <div class="col-lg-3 col-12">
            <label><strong>Creative Set Name</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" type="text" [(ngModel)]="newCreativeName" [title]="newCreativeName" style="overflow: hidden; text-overflow: ellipsis;">
        </div>
    </div>

    <div *ngIf="action == 'New'" class="mb-2 mt-2 row align-items-center">
        <div class="col-lg-3 col-12">
            <label><strong>New Flight Start Date</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-calendar (onSelect)="onStartDateChange($event)" [minDate]="minStartDate" [maxDate]="maxEndDate" [(ngModel)]="startDate"></p-calendar>
        </div>
    </div>

    <div *ngIf="action == 'Remove' && maxCreativeGroup != creativeGroup" class="mb-2 mt-2 row align-items-center">
        <div class="row col-lg-12 col-12" ng-if="!!prevCreative">
            <div class="col-lg-4 col-12">
                <label><strong>{{prevCreative.creativeName ? prevCreative.creativeName : prevCreative.name}}</strong></label>
            </div>
            <div class="col-lg-8 col-12">
                <p-calendar [disabled]="true" [minDate]="minStartDate" [maxDate]="maxEndDate" [(ngModel)]="prevFlightStartDate"></p-calendar>
                <span class="mx-2">-</span>
                <p-calendar (onSelect)="onRemoveEndDateChange($event)" [minDate]="minStartDate" [maxDate]="maxEndDateFirst" [(ngModel)]="prevFlightEndDate"></p-calendar> 
            </div>
        </div>

        <div class="row col-lg-12 col-12" *ngIf="!!nextCreative">
            <div class="col-lg-4 col-12">
                <label><strong>{{nextCreative.creativeName ? nextCreative.creativeName : nextCreative.name}}</strong></label>
            </div>
            <div class="col-lg-8 col-12">
                <p-calendar (onSelect)="onRemoveStartDateChange($event)" [minDate]="minEndDateSecond" [maxDate]="maxEndDate" [(ngModel)]="nextFlightStartDate"></p-calendar>
                <span class="mx-2">-</span>
                <p-calendar (onSelect)="onEndDateChange($event)" [disabled]="true"  [minDate]="minEndDate" [maxDate]="maxEndDate" [(ngModel)]="nextFlightEndDate"></p-calendar> 
            </div>
        </div>
    </div>

    <div *ngIf="action == 'Edit'"  class="mb-2 mt-2 row align-items-center">
        <!-- <div class="col-lg-3 col-12">
            <label><strong>Flight Dates</strong></label>
        </div> -->
        <div *ngFor="let creative of editCreatives; let i = index" class="col-lg-9 col-12" style="display: contents;">
            <div class="col-lg-3 col-12">
                <label><strong>Flight {{i+1}}</strong></label>
            </div>
            <p-calendar (onSelect)="editOnStartDateChange($event, creative.id, creative.creativeGroup)" 
                [disabled]="creative.creativeGroup == 1 || (currentUser.permissions.isClient && currentUser.userRole != eUserRole.LIMGCSocialAdOps && currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                                || !functions.checkCanChangeFlight(baseCampaign.campaignStatusId, baseCampaign.statusId, baseCampaign.campaignType, creative.isBuilt)"
                [minDate]="creative.startDateMin" [maxDate]="creative.startDateMax" [(ngModel)]="creative.flightStartDate"></p-calendar>
                
            <span class="mx-2">-</span>
            <p-calendar (onSelect)="editOnEndDateChange($event, creative.id, creative.creativeGroup)" 
                [disabled]="creative.creativeGroup == maxCreativeGroup || (currentUser.permissions.isClient && currentUser.userRole != eUserRole.LIMGCSocialAdOps && currentUser.userRole != eUserRole.LIMGC_AdOps_Admin) 
                || !functions.checkCanChangeFlight(baseCampaign.campaignStatusId, baseCampaign.statusId, baseCampaign.campaignType, creative.isBuilt)"
                [minDate]="creative.endDateMin" [maxDate]="creative.endDateMax" [(ngModel)]="creative.flightEndDate"></p-calendar> 
        </div>
    </div>

    <div *ngIf="action == 'New'" class="mb-2 mt-2 row align-items-center">
        <div class="col-lg-3 col-12">
            <label></label>
        </div>
        <div class="col-lg-9 col-12">
            <label>As an industry best practice creative flight must run for at least 7 days </label>
        </div>
    </div>

    <div *ngIf="action == 'Remove'" class="mb-2 mt-2 row align-items-center">
        <div class="col-lg-3 col-12">
            <label></label>
        </div>
        <div class="col-lg-9 col-12">
            <label>Creative flights must run for at least 7 days to achieve peak performance.</label>
        </div>
        <div *ngIf="!!nextCreative && checkIsDisable()" class="col-md-12 d-flex align-items-center" style="min-height: 3em;">
            <span class="col-md-12 alert alert-warning text-center px-2 py-2" style="width: 100%;">Note: Flights dates must be revised to avoid gaps within campaign run dates.</span>
        </div>
    </div>

    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button [disabled]="checkIsDisable()" (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>