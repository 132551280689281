import { OnInit, Component, Input, EventEmitter, Output, OnDestroy, ViewChild, OnChanges} from '@angular/core';
import { IBaseCampaignDto, IGeographicTargetingJson, IDemographicsDto, ILandingPageJson, ISponsorDto } from '@Workspace/_generated/interfaces';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { SelectItem } from 'primeng/api';
import { GeographicDialog } from '../geographic-dialog/geographic.dialog';
import { DemographicDialog } from '../demographic-dialog/demographic.dialog';
import { CartItemService } from '../card-item/card-item.service';
import { eChannelType, eGender, eAgeFrom, eAgeTo, eAgeFromName, eAgeToName, eIncomeTo, eIncomeToName, eIncomeFrom, eIncomeFromName, eStandardCampaginChannel, eEntityStatus, eCampaignStatus, eUserRole, eInventoryTypeOAS, eAccountType } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { AdditionalSettingsDialog } from '../additional-settings-dialog/additional-settings-dialog.component';
import { UnlockFeaturesDialog } from '../unlock-features-dialog/unlock-features-dialog.component';
import { LookUps, Constants, Functions } from '@Workspace/common';
import { DatePipe, DecimalPipe } from '@angular/common';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { AdvertiserDialog } from '../advertiser-dialog/advertiser-dialog.component';
import { PaymentStatusDialog } from '../payment-status-dialog/payment-status-dialog';
import { CampaignController, AccountController } from '@Workspace/_generated/services';
import { EnumStringPipe } from '@Workspace/pipes';
import { CampaignDatesDialog } from '../campaign-dates-dialog/campaign-dates-dialog.component';
import { KeyValuePairDialog } from '../key-value-pair-dialog/key-value-pair-dialog.component';
import { InventoryAddOnDialog } from '../inventory-add-on-dialog/inventory-add-on-dialog.component';
import * as _ from 'lodash';
import { ContactDialog } from '../contact-dialog/contact-dialog';
import { CardItemComponent } from '../card-item/card-item.component';
import { InventoryItemsDialog } from '../inventory-items-dialog/inventory-items-dialog';

@Component({
    selector: 'card-item-details',
    templateUrl: './campaign-card-details.component.html',
    styleUrls: ['./campaign-card-details.component.scss'],
    providers: [DatePipe, DecimalPipe]
})
export class CampaignCardDetails implements OnInit, OnDestroy {
    baseCampaign: IBaseCampaignDto;
    // editButtonShown = true;
    campaignDates = '';
    ngUnsubscribe = new Subject();
    editCampaignDemographics = false;
    @ViewChild('demographics') demographicsDialog: DemographicDialog;
    @ViewChild('geographics') geographicsDialog: GeographicDialog;
    @ViewChild('additionalSettings') additionalSettings: AdditionalSettingsDialog;
    @ViewChild('unlockFeatures') unlockFeatures: UnlockFeaturesDialog;
    @ViewChild('advertiser') advertiser: AdvertiserDialog;
    @ViewChild('paymentStatus') paymentStatus: PaymentStatusDialog;
    @ViewChild('campaignDatesDialog')
    campaignDatesDialog: CampaignDatesDialog;
    @ViewChild('keyValuePair') keyValuePair: KeyValuePairDialog;
    @ViewChild('inventoryAddOn') inventoryAddOn: InventoryAddOnDialog;
    @ViewChild('contact') contact: ContactDialog;
    @ViewChild('contactCard') contactCard: CardItemComponent;
    @ViewChild('inventoryItemsDialog') inventoryItemsDialog: InventoryItemsDialog;

    display = false;
    modalContext = '';
    name: string = '';
    id: string = '';
    URL: string = '';
    creativesSelected: string;
    inventoryItems: string[] | string;
    impressions: string[] = [];
    campaignStatusId: eCampaignStatus;

    @Output() onActivatedOutsideOfHub: EventEmitter<any> = new EventEmitter();
    @Output() onDateChange: EventEmitter<any> = new EventEmitter();


    @Input('editButtonShown') editButtonShown: boolean = false;
    @Input('showDemographics') showDemographics: boolean;
    @Input() isCampaignActivatedOutsideOfHub: boolean = false;
    @Input() isNoteValid: boolean = true;
    @Input() isVisible: boolean = true;
    

    facebookData: string;
    userType: any;
    additionalSettingsList: string[] = [];
    unlockFeaturesList: string[] = [];
    activation: string;
    campaignGoals: SelectItem[] = [];
    campaignGoalLabel: string = "";

    demographicsValues: string[] = ["", "", ""];
    contactValues: string[] = ["", ""];
    geographicsValues = ["", "", "", ""];
    canEditDemographics = false;
    canEditCampaignDates = false;
    canEditKeyValuePair = false;

    canEditGeographics = false;
    canEditGeographicsZipCodes = false;
    advertiserId: number;
    ttdadvertiserId: string;
    dv360advertiserId: string;
    gamadvertiserId: string;
    canEditSiteVisitTracking: boolean;
    canEditConversionTracking: boolean;
    canEditInterestTargeting: boolean;
    currentUser = this.authService.userSettings;
    enumAccountType = eAccountType;
    enumUserRole = eUserRole;
    enumCampaignStatus = eCampaignStatus;
    buildFee: number;
    constants = Constants;
    isPageTakeOver: boolean = false;
    minCpm: number=9;
    maxCpm: number=2;
    contactInfo: string = null;
    enumInventoryTypeOAS = eInventoryTypeOAS;
    costsTableValidation: boolean = true;

    constructor(
        public accountController: AccountController,
        private cardItemService: CartItemService,
        private authService: AuthService,
        private dateTime: DatePipe,
        private decimalPipe: DecimalPipe,
        public campaignController: CampaignController,
    ) { }


    ngOnInit(): void {
        this.initLists();
        this.userType = this.authService.userSettings;
        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.baseCampaign = data;
                this.campaignStatusId = this.baseCampaign.campaignStatusId;
                if (this.baseCampaign.totalDisplayAddOnAmount == null || this.baseCampaign.totalFacebookAddOnAmount == null || this.baseCampaign.totalOtherAddOnAmount == null || this.baseCampaign.totalCreativeFlightsAddOnAmount == null) {
                    let getDayDifference = this.getDayDifference();
                    this.baseCampaign.totalFacebookAddOnAmount=0;
                    this.baseCampaign.totalDisplayAddOnAmount=0;
                    this.baseCampaign.totalOtherAddOnAmount=0;
                    this.baseCampaign.totalCreativeFlightsAddOnAmount = 0;

                    this.baseCampaign.campaignAddOns.forEach(x=>{
                        if(x.addOn.name=="Facebook Dynamic News Feed Carousel" || x.addOn.name=="Facebook Dynamic News Feed" || x.addOn.name=="Facebook Dynamic News Feed Video")
                        {
                            this.baseCampaign.totalFacebookAddOnAmount += x.addOn.price * getDayDifference;
                        }
                        else if(x.addOn.name=="Abandoned Cart Display Banners" || x.addOn.name=="Post Purchase Display Banners")
                        {
                            this.baseCampaign.totalDisplayAddOnAmount += x.addOn.price * getDayDifference;
                        }
                        else if(x.addOn.name=="Display In-Stream Video" || x.addOn.name=="Static Native")
                        {
                            this.baseCampaign.totalOtherAddOnAmount += x.addOn.price * getDayDifference;
                        }
                        else if(x.addOn.name=="Premium Display Banners" && this.baseCampaign.package.name != "Amplify Flex" && this.baseCampaign.package.name != "Amplify"){
                            this.baseCampaign.totalDisplayAddOnAmount += x.addOn.price * getDayDifference;
                        }
                        else if(x.addOn.name=="Facebook Video News Feed" && this.baseCampaign.package.name != "Amplify Flex" && this.baseCampaign.package.name != "Amplify"){
                            this.baseCampaign.totalFacebookAddOnAmount += x.addOn.price * getDayDifference;
                        }
                    });
                    
                    if(!!this.baseCampaign.package && this.baseCampaign.package.name!='Amplify Flex'){
                        let maxNumberOfFlights = _.orderBy(this.baseCampaign.creatives, 'creativeGroup', 'asc')[this.baseCampaign.creatives.length-1].creativeGroup;
              
                        // if(this.baseCampaign.campaignGoalId==3){
                        //     this.baseCampaign.totalCreativeFlightsAddOnAmount = (maxNumberOfFlights - 1) * 500 * getDayDifference/7;
                        // }
                        // else{
                        //     this.baseCampaign.totalCreativeFlightsAddOnAmount = (maxNumberOfFlights - 1) * 500 * getDayDifference;
                        // }
                        this.baseCampaign.totalCreativeFlightsAddOnAmount = ((maxNumberOfFlights - 1)>0 ? 1 : 0) 
                        * (this.baseCampaign.package.name == 'Grow' ? 250 : 500) * getDayDifference;
                    }

                    if(this.baseCampaign.totalFacebookAddOnAmount < 0 || this.baseCampaign.totalDisplayAddOnAmount < 0
                        || this.baseCampaign.totalOtherAddOnAmount < 0 || this.baseCampaign.totalCreativeFlightsAddOnAmount < 0)
                        this.costsTableValidation = false;
                    
                    // this.baseCampaign.totalFacebookAddOnAmount = this.baseCampaign.campaignAddOns.find(x => x.addOn.name == "Facebook Video News Feed") ?
                    //     this.baseCampaign.campaignAddOns.find(x => x.addOn.name == "Facebook Video News Feed").addOn.price * getDayDifference : 0;
                    // this.baseCampaign.totalDisplayAddOnAmount = this.baseCampaign.campaignAddOns.find(x => x.addOn.name == "Premium Display Banners") ?
                    //     this.baseCampaign.campaignAddOns.find(x => x.addOn.name == "Premium Display Banners").addOn.price * getDayDifference : 0;
                }

                this.campaignGoalLabel = this.campaignGoals.find(x => x.value == this.baseCampaign.campaignGoalId).label;
                this.baseCampaign.buildFee = this.baseCampaign.amount * 12 / 100;
                this.baseCampaign.totalAmount = this.baseCampaign.amount + this.baseCampaign.buildFee;

                if (this.baseCampaign.id == Constants.GuidEmpty && this.currentUser.permissions.isHMPClient) {
                    this.baseCampaign.propertyEmails = this.currentUser.email;
                }

                let filter = `?$filter=Email eq '${this.baseCampaign.propertyEmails}'`;

                if (this.baseCampaign.propertyEmails != null)
                {
                    this.accountController.GetUsers(filter).subscribe(x => {
                        this.baseCampaign.contact = x.data[0];
                        if(!!this.baseCampaign.contact){
                            this.contactInfo = null;
                            this.contactValues[0] = !!this.baseCampaign.contact.fullName ? 
                                this.baseCampaign.contact.fullName : '';
                            this.contactValues[1] = !!this.baseCampaign.contact.fullName ? 
                                this.baseCampaign.contact.email : this.baseCampaign.propertyEmails ;
                        }
                        else{
                            this.contactInfo = 'Your property contact is removed please set another one.';
                            this.contactValues[0]="";
                            this.contactValues[1]="";
                            this.baseCampaign.propertyEmails=null;
                        }
                    });
                }
                else
                {
                    this.contactInfo = 'Missing contact.';
                }
                    

                this.canEditDemographics = (!!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.demoTargeting :
                    (x.inventoryItemNew != null ? x.inventoryItemNew.demoTargeting :
                        (x.inventoryItemOAS != null ? x.inventoryItemOAS.demoTargeting : false))))
                    || this.baseCampaign.isDemographicTargetingUnlocked;
                    
                this.canEditGeographics = (!!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.customGeo :
                    (x.inventoryItemNew != null ? x.inventoryItemNew.customGeo :
                        (x.inventoryItemOAS != null ? x.inventoryItemOAS.geoTargeting : false))))
                    || this.baseCampaign.isGeographicTargetingUnlocked || this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.package?.name == 'Amplify Flex';

                this.canEditGeographicsZipCodes = (!!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.customZip : (x.inventoryItemNew != null ? x.inventoryItemNew.customZip : false))) || this.baseCampaign.isGeographicTargetingZipCodesUnlocked;

                this.baseCampaign.isGeographicTargetingUnlocked = this.canEditGeographics;
                this.baseCampaign.isDemographicTargetingUnlocked = this.canEditDemographics;
                this.baseCampaign.isGeographicTargetingZipCodesUnlocked = this.canEditGeographicsZipCodes;

                this.isPageTakeOver = this.baseCampaign.campaignType == eAccountType.OAS && !!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItemOAS != null && (x.inventoryItemOAS.pageTakeover || x.inventoryItemOAS.premiumPageTakeover ));

                this.baseCampaign.isDataRangeUnlocked = this.baseCampaign.isDataRangeUnlocked == null || this.baseCampaign.isDataRangeUnlocked == undefined ? true : this.baseCampaign.isDataRangeUnlocked;

                if (!!this.currentUser && !!this.currentUser.accounts) {
                    this.canEditCampaignDates = (this.baseCampaign.campaignType == eAccountType.FAN365 || this.baseCampaign.campaignType == eAccountType.OAS || this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignType == eAccountType.Content)  
                        && ((this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending && this.currentUser.permissions.isClient)
                            || ((this.currentUser.permissions.isAdmin || this.currentUser.permissions.isEmployee || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin 
                                    ||  this.currentUser.userRole == eUserRole.LIMGCSocialAdOps) 
                                //&& (
                                    // this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Uploaded
                                    // || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Approved || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined || 
                                // ( (this.baseCampaign.isOASCampaign || this.baseCampaign.isSocialCampaign) &&  (this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Ended || this.baseCampaign.statusId == eCampaignStatus.Campaign_Ended)) )
                                ));
                    this.canEditKeyValuePair = this.baseCampaign.campaignType == eAccountType.OAS && (this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending); 
                }

                if (!!this.baseCampaign.account && this.baseCampaign.account.isLearfieldCampaign) {
                    this.changeAdditionalSettingsPermission();
                    if (this.isCampaignActivatedOutsideOfHub) {
                        this.activation = 'true';

                    } else {
                        this.activation = 'false';
                    }
                }
                if (!!this.baseCampaign.demographics)
                    this.setDemographicsCardText(this.baseCampaign.demographics);
                if (!!this.baseCampaign.geographicTargetingJson)
                    this.setGeographicsCardText(this.baseCampaign.geographicTargetingJson);
                if (this.baseCampaign.startDate != undefined && this.baseCampaign.endDate) {
                    this.campaignDates = `${this.formatDate((this.baseCampaign.startDate))} - ${this.formatDate((this.baseCampaign.endDate))}`;
                }
                var creativeArray = !!this.baseCampaign.creatives
                    ? this.baseCampaign.creatives.filter(x => x.channelId != 0).map(x =>
                            eChannelType[x.channelId].toString().replace('_', ' '))
                    : [''];

                this.creativesSelected = [...new Set(creativeArray)].join(';');

                if (this.baseCampaign.campaignInventoryItems) {
                    this.baseCampaign.campaignInventoryItems.sort((x, y) => (y.inventoryItem != null ? y.inventoryItem.impressions : null) - (x.inventoryItem != null ? x.inventoryItem.impressions : null));
                    this.inventoryItems =
                        (!!this.baseCampaign.campaignInventoryItems &&
                            this.baseCampaign.campaignInventoryItems.length > 0) ? this.baseCampaign.campaignInventoryItems.filter(ci => ci.entityStatus == eEntityStatus.Active).map(x => '-' + (x.inventoryItem != null ? x.inventoryItem.name :
                                (x.inventoryItemNew != null ? x.inventoryItemNew.name :
                                    (x.inventoryItemOAS != null ? x.inventoryItemOAS.name : 
                                        (x.inventoryItemSocial != null ? x.inventoryItemSocial.name : x.inventoryItemContent.name))))) : '';
                }

                this.setupImpressions();
            });
    }

    setupImpressions() {
        this.impressions = [];
        if(this.baseCampaign.campaignType == eAccountType.HS || this.baseCampaign.campaignType == eAccountType.HMP){
            if (!!this.baseCampaign.faceBookImpressions && !!this.baseCampaign.displayImpressions) {
                this.impressions.push("Total: " + this.decimalPipe.transform(this.baseCampaign.impressions));
                this.impressions.push("Display: " + this.decimalPipe.transform(this.baseCampaign.displayImpressions));
                this.impressions.push("Facebook: " + this.decimalPipe.transform(this.baseCampaign.faceBookImpressions));
            }
            else if (!!this.baseCampaign.displayImpressions) {
                this.impressions.push("Display: " + this.decimalPipe.transform(this.baseCampaign.displayImpressions));
            }
            else if (!!this.baseCampaign.faceBookImpressions) {
                this.impressions.push("Facebook: " + this.decimalPipe.transform(this.baseCampaign.faceBookImpressions));
            }
            else 
                this.impressions.push("Total: " + this.decimalPipe.transform(this.baseCampaign.impressions));
        }
        else{
            if (!!this.baseCampaign.displayImpressions) 
                this.impressions.push("Inventory Impressions: " + this.decimalPipe.transform(this.baseCampaign.displayImpressions));
            else if(!!this.baseCampaign.faceBookImpressions)
                this.impressions.push("Inventory Impressions: " + this.decimalPipe.transform(this.baseCampaign.faceBookImpressions));
            // if(!!this.baseCampaign.adOnImpressions){
            //     this.impressions.push("Add-on Impressions: " + this.decimalPipe.transform(this.baseCampaign.adOnImpressions));
            //     this.impressions.push("");
            //     this.impressions.push("Total Impressions: " + this.decimalPipe.transform(this.baseCampaign.impressions));
            // }

            let addOnImpressions = !!this.baseCampaign.adOnImpressions && this.baseCampaign.adOnImpressions > 0 ? this.decimalPipe.transform(this.baseCampaign.adOnImpressions) : "N/A";
            this.impressions.push("Add-on Impressions: " + addOnImpressions);
            this.impressions.push("");
            this.impressions.push("Total Impressions: " + this.decimalPipe.transform(this.baseCampaign.impressions));
        }
    }
    

    initLists() {
        this.campaignGoals = LookUps.getCampaignGoalLu();
        this.campaignGoals.unshift({ value: 0, label: "-" });
    }

    changeAdditionalSettingsPermission() {
        this.canEditSiteVisitTracking = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.siteVisitTracking : (x.inventoryItemNew != null ? x.inventoryItemNew.siteVisitTracking : false)) || this.baseCampaign.isSiteVisitTrackingUnlocked;
        this.baseCampaign.isSiteVisitTrackingEnabled = this.canEditSiteVisitTracking ? this.baseCampaign.isSiteVisitTrackingEnabled : null;
        this.baseCampaign.isSiteVisitTrackingUnlocked = this.canEditSiteVisitTracking;

        this.canEditConversionTracking = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.conversionTracking : (x.inventoryItemNew != null ? x.inventoryItemNew.conversionTracking : false)) || this.baseCampaign.isConversionTrackingUnlocked;
        this.baseCampaign.isConversionTrackingEnabled = this.canEditConversionTracking ? this.baseCampaign.isConversionTrackingEnabled : null;
        this.baseCampaign.isConversionTrackingUnlocked = this.canEditConversionTracking;

        this.canEditInterestTargeting = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.interestTargeting : (x.inventoryItemNew != null ? x.inventoryItemNew.interestTargeting : false)) || this.baseCampaign.isInterestSegmentsUnlocked;
        this.baseCampaign.isAdditionalTargetingSegmentsEnabled = this.canEditInterestTargeting ? this.baseCampaign.isAdditionalTargetingSegmentsEnabled : null;
        this.baseCampaign.isInterestSegmentsUnlocked = this.canEditInterestTargeting;

        this.additionalSettingsList.push(
            this.canEditSiteVisitTracking ? ('' + this.baseCampaign.isSiteVisitTrackingEnabled) : "uneditable",
            this.canEditConversionTracking ? ('' + this.baseCampaign.isConversionTrackingEnabled) : "uneditable",
            this.canEditInterestTargeting ? ('' + this.baseCampaign.isAdditionalTargetingSegmentsEnabled) : "uneditable",
        );

        this.unlockFeaturesList.push(
            ('' + this.baseCampaign.isSiteVisitTrackingUnlocked),
            ('' + this.baseCampaign.isConversionTrackingUnlocked),
            ('' + this.baseCampaign.isDataRangeUnlocked),
            ('' + this.baseCampaign.isInterestSegmentsUnlocked),
            ('' + this.baseCampaign.isDemographicTargetingUnlocked),
            ('' + this.baseCampaign.isGeographicTargetingUnlocked),
            ('' + this.baseCampaign.isGeographicTargetingZipCodesUnlocked),
        );
    }

    editEvent(event: string) {
        if (event == 'Demographics') {
            this.demographicsDialog.openDialog(
                this.baseCampaign.demographics
            );
        } else if (event == 'Geographic') {
            this.geographicsDialog.openDialog(
                this.baseCampaign.geographicTargetingJson,
                this.baseCampaign.defaultGeographicTargetingJson,
                this.canEditGeographicsZipCodes,
                this.baseCampaign
            );
        } else if (event == 'Sponsor Instagram Page' || event == 'Instagram Page') {
            this.display = true;
            this.name = this.baseCampaign.instagramName;
            this.id = this.baseCampaign.instagramId;
            this.modalContext = 'Instagram';
        } else if (event == 'Sponsor Facebook Page' || event == 'Facebook Page') {
            this.display = true;
            this.modalContext = 'Facebook';
            this.name = this.baseCampaign.facebookPageName;
            this.id = this.baseCampaign.facebookPageId;
            this.URL = this.baseCampaign.facebookPageUrl;
        } else if (event == 'Additional Settings') {
            this.additionalSettings.openDialog(
                this.baseCampaign.isSiteVisitTrackingEnabled,
                this.baseCampaign.isConversionTrackingEnabled,
                this.baseCampaign.isAdditionalTargetingSegmentsEnabled,
                [this.canEditSiteVisitTracking, this.canEditConversionTracking, this.canEditInterestTargeting]
            );
        } else if (event == 'Unlock Features') {
            this.unlockFeatures.openDialog(
                this.baseCampaign.isSiteVisitTrackingUnlocked,
                this.baseCampaign.isConversionTrackingUnlocked,
                this.baseCampaign.isDataRangeUnlocked,
                this.baseCampaign.isInterestSegmentsUnlocked,
                this.baseCampaign.isDemographicTargetingUnlocked,
                this.baseCampaign.isGeographicTargetingUnlocked,
                this.baseCampaign.isGeographicTargetingZipCodesUnlocked,
                this.baseCampaign.account.isLearfieldCampaign
            );
        } else if (event == 'Advertiser ID') {
            this.campaignController.GetSponsors(this.baseCampaign.accountId).subscribe(async data => {
                    let sponsors = data;
            this.advertiser.openDialog(
                this.baseCampaign.advertiserId != null && this.baseCampaign.advertiserId !='' ? this.baseCampaign.advertiserId : 
                    !!sponsors.find(x => x.accountName == this.baseCampaign.sponsor) ? sponsors.find(x => x.accountName == this.baseCampaign.sponsor).gcmAdvertiserId : null,
                "Advertiser",
                [this.advertiserId],
                this.baseCampaign.campaignType,
                this.baseCampaign.advertiserName,
                this.baseCampaign.ttdAdvertiserId != null && this.baseCampaign.ttdAdvertiserId !='' ? this.baseCampaign.ttdAdvertiserId : 
                    !!sponsors.find(x => x.accountName == this.baseCampaign.sponsor) ? sponsors.find(x => x.accountName == this.baseCampaign.sponsor).ttdAdvertiserId : null,
                this.baseCampaign.dV360AdvertiserId != null && this.baseCampaign.dV360AdvertiserId !='' ? this.baseCampaign.dV360AdvertiserId : 
                    !!sponsors.find(x => x.accountName == this.baseCampaign.sponsor) ? sponsors.find(x => x.accountName == this.baseCampaign.sponsor).dV360AdvertiserId : null,
                this.baseCampaign.gamAdvertiserId != null && this.baseCampaign.gamAdvertiserId !='' ? this.baseCampaign.gamAdvertiserId : 
                    !!sponsors.find(x => x.accountName == this.baseCampaign.sponsor) ? sponsors.find(x => x.accountName == this.baseCampaign.sponsor).gamAdvertiserId : null,
            );
        });
        }   
        else if (event == 'Advertiser') {
            this.advertiser.openDialog(
                this.baseCampaign.advertiserId,
                "Advertiser",
                [this.advertiserId],
                this.baseCampaign.campaignType,
                this.baseCampaign.advertiserName,
                this.baseCampaign.ttdAdvertiserId,
                this.baseCampaign.dV360AdvertiserId,
                this.baseCampaign.gamAdvertiserId
            );               
        } else if (event == 'Page Selection') {
            this.keyValuePair.openDialog(
                this.baseCampaign.keyValuesPairOASSectionId,
                this.baseCampaign.keyValuesPairOASPageTamplateId,
                'OAS site',
            );
        } else if (event == 'Impressions') {
            this.inventoryAddOn.openDialog(
                this.baseCampaign,
            );
        } else if (event == 'Campaign Dates') {
            this.campaignDatesDialog.openDialog(
                this.baseCampaign.startDate != null ? (this.baseCampaign.startDate.toString()) : null,
                this.baseCampaign.endDate != null ? (this.baseCampaign.endDate.toString()) : null,
                this.baseCampaign.campaignInventoryItems[0].inventoryItem != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItem.startDate : this.baseCampaign.seasonMapping.startDate,
                this.baseCampaign.campaignInventoryItems[0].inventoryItem != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItem.endDate : this.baseCampaign.seasonMapping.endDate,
                this.baseCampaign.campaignInventoryItems[0].inventoryItem != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItem.minimumCampaignLength :
                    (this.baseCampaign.campaignInventoryItems[0].inventoryItemNew != null ? this.baseCampaign.campaignInventoryItems[0].inventoryItemNew.minimumCampaignLength : 0),
                this.baseCampaign,
            );
        } else if (event == 'Landing Page') {
            this.advertiser.openDialog(
                this.baseCampaign.landingPageJson.url,
                "Landing Page",
                [this.advertiserId],
                null,
                '',
                this.ttdadvertiserId,
                this.dv360advertiserId,
                this.gamadvertiserId
            );
        } else if (event == 'Activation') {
            this.additionalSettings.openDialog(
                this.baseCampaign.isSiteVisitTrackingEnabled,
                this.baseCampaign.isConversionTrackingEnabled,
                this.baseCampaign.isAdditionalTargetingSegmentsEnabled,
                [this.canEditSiteVisitTracking, this.canEditConversionTracking, this.canEditInterestTargeting], true,
                this.isCampaignActivatedOutsideOfHub
            );
        }
        else if (event == 'Payment Status') {
            this.paymentStatus.openDialog(
                this.baseCampaign.campaignStatusId,
                "Payment Status"
            );
        }
        else if (event == 'Contact') {
            this.contact.openDialog(
                this.baseCampaign.accountId,
                this.baseCampaign.propertyEmails,
                this.contactInfo,
                "Contact"
            );               
        }
        else if (event == 'Inventory items') {
            this.inventoryItemsDialog.openDialog(
                this.baseCampaign
            );               
        }
    }

    save() {
        this.display = false;
        if (this.modalContext == 'Facebook') {
            this.baseCampaign.facebookPageId = this.id;
            this.baseCampaign.facebookPageName = this.name;
            this.baseCampaign.facebookPageUrl = this.URL;
        } else {
            this.baseCampaign.instagramId = this.id;
            this.baseCampaign.instagramName = this.name;
        }
    }

    checkIsFacebookInventoryItem() {
        if (!!this.baseCampaign && !!this.baseCampaign.creatives && this.baseCampaign.creatives.some(x => x.channelId == eChannelType.Social)) {
            return true;
        }
        else {
            return false;
        }
    }

    cancel() {
        this.display = false;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    geographicSaved(event: IGeographicTargetingJson) {
        this.baseCampaign.geographicTargetingJson = event;
        this.setGeographicsCardText(event);
    }

    setGeographicsCardText(event: IGeographicTargetingJson) {
        if (event == null) return;
        this.geographicsValues[0] = !!event.cities && event.cities.length > 0 ? event.cities.map(x => x.name).join(";") : "";
        this.geographicsValues[1] = !!event.dmaCodes && event.dmaCodes.length > 0 ? event.dmaCodes.map(x => x.name).join(";") : "";
        this.geographicsValues[2] = !!event.states && event.states.length > 0 ? event.states.map(x => x.name).join(";") : "";
        this.geographicsValues[3] = !!event.zipCodes && event.zipCodes.length > 0 ? event.zipCodes.map(x => x.name).join(";") : "";
    }

    demographicsSaved(event: IDemographicsDto) {
        if (!!event) {
            this.baseCampaign.demographics = event;
            this.setDemographicsCardText(event);
        }
    }

    setDemographicsCardText(event: IDemographicsDto) {
        if (!!event && !!event.genderList) { //  && event.genderList != 0
            const genderStr: string[] = [];
            let i = 0;
            let gender: number;
            while (eGender[(gender = 1 << i++)]) {
                if (event.genderList & gender) {
                    genderStr.push(eGender[gender]);
                }
            }
            this.demographicsValues[0] = genderStr.join(";");
        } else {
            this.demographicsValues[0] = "";
        }

        if (!!event && !!event.ageRange && event.ageRange.ageFrom != 0 && event.ageRange.ageTo != 0) {
            if (event.ageRange.ageTo == eAgeTo.Plus) {
                this.demographicsValues[1] = eAgeFromName.get(event.ageRange.ageFrom).name + " " + eAgeToName.get(event.ageRange.ageTo).name;
            }

            else {
                this.demographicsValues[1] = eAgeFromName.get(event.ageRange.ageFrom).name + ' - ' + eAgeToName.get(event.ageRange.ageTo).name;
            }
        } else {
            this.demographicsValues[1] = "";
        }
        if (!!event && event.incomeRange != null && event.incomeRange.from != 0 && event.incomeRange.to != 0) {
            this.demographicsValues[2] = eIncomeFromName.get(event.incomeRange.from).name + ';' + eIncomeToName.get(event.incomeRange.to).name;
        } else {
            this.demographicsValues[2] = "";
        }
    }

    additionalSettingsSaved(event: any) {
        this.baseCampaign.isSiteVisitTrackingEnabled =
            event.isSiteVisitTrackingEnabled;
        this.baseCampaign.isConversionTrackingEnabled =
            event.isConversionTrackingEnabled;
        this.baseCampaign.isAdditionalTargetingSegmentsEnabled =
            event.isAdditionalTargetingSegmentsEnabled;
        this.additionalSettingsList = [
            this.canEditSiteVisitTracking ? ('' + event.isSiteVisitTrackingEnabled) : "uneditable",
            this.canEditConversionTracking ? ('' + event.isConversionTrackingEnabled) : "uneditable",
            this.canEditInterestTargeting ? ('' + event.isAdditionalTargetingSegmentsEnabled) : "uneditable"
        ];

        this.isCampaignActivatedOutsideOfHub = event.isActivatedOutsideOfHub;
        if(this.isCampaignActivatedOutsideOfHub)
            this.campaignStatusId = eCampaignStatus.Activated_outside_of_Hub;
        else
            this.campaignStatusId = this.baseCampaign.campaignStatusId;
        this.onActivatedOutsideOfHub.emit(this.isCampaignActivatedOutsideOfHub);
        this.activation = '' + event.isActivatedOutsideOfHub;
    }

    unlockFeaturesSaved(event: any) {
        this.baseCampaign.isSiteVisitTrackingUnlocked = event.isSiteVisitTrackingUnlocked;
        this.baseCampaign.isConversionTrackingUnlocked = event.isConversionTrackingUnlocked;
        this.baseCampaign.isDataRangeUnlocked = event.isDataRangeUnlocked;
        this.baseCampaign.isInterestSegmentsUnlocked = event.isInterestSegmentsUnlocked;
        this.baseCampaign.isDemographicTargetingUnlocked = event.isDemographicTargetingUnlocked;
        this.baseCampaign.isGeographicTargetingUnlocked = event.isGeographicTargetingUnlocked;
        this.baseCampaign.isGeographicTargetingZipCodesUnlocked = event.isGeographicTargetingZipCodesUnlocked;
        this.unlockFeaturesList = [
            ('' + event.isSiteVisitTrackingUnlocked),
            ('' + event.isConversionTrackingUnlocked),
            ('' + event.isDataRangeUnlocked),
            ('' + event.isInterestSegmentsUnlocked),
            ('' + event.isDemographicTargetingUnlocked),
            ('' + event.isGeographicTargetingUnlocked),
            ('' + event.isGeographicTargetingZipCodesUnlocked),
        ];
    }

    dataSaved(event: any) {
        if (event.dialogType == 'advertiser') {
            this.baseCampaign.advertiserName =
                event.advertiserName;

            if(this.baseCampaign.campaignType == eAccountType.FAN365){
                this.baseCampaign.advertiserId =
                    event.advertiserId;
                this.baseCampaign.ttdAdvertiserId =
                    event.ttdadvertiserId;
                this.baseCampaign.dV360AdvertiserId =
                    event.dv360advertiserId;
                this.baseCampaign.isTTDAdveriserIdEnteredInHub = event.isTTDAdveriserIdEnteredInHub == true ? event.isTTDAdveriserIdEnteredInHub : this.baseCampaign.isTTDAdveriserIdEnteredInHub;
                this.baseCampaign.isDv360AdveriserIdEnteredInHub = event.isDv360AdveriserIdEnteredInHub == true ? event.isDv360AdveriserIdEnteredInHub : this.baseCampaign.isDv360AdveriserIdEnteredInHub;
                this.baseCampaign.isAdveriserIdEnteredInHub = event.isAdveriserIdEnteredInHub == true ? event.isAdveriserIdEnteredInHub : this.baseCampaign.isAdveriserIdEnteredInHub;
            }
            else if(this.baseCampaign.campaignType == eAccountType.OAS){
                this.baseCampaign.advertiserId =
                    event.advertiserId;
                this.baseCampaign.gamAdvertiserId =
                    event.gamadvertiserId;
                this.baseCampaign.isAdveriserIdEnteredInHub = event.isAdveriserIdEnteredInHub == true ? event.isAdveriserIdEnteredInHub : this.baseCampaign.isAdveriserIdEnteredInHub;
                this.baseCampaign.isGAMAdveriserIdEnteredInHub = event.isGAMAdveriserIdEnteredInHub == true ? event.isGAMAdveriserIdEnteredInHub : this.baseCampaign.isGAMAdveriserIdEnteredInHub;
            }
        }
        else if (event.dialogType == 'keyValuePair') {
            this.baseCampaign.keyValuesPairOASSectionId = event.keyValuePairOASSection.id;
            this.baseCampaign.keyValuesPairOASPageTamplateId = event.keyValuePairOASPageTemplate.id;
            this.baseCampaign.keyValuesPairOASPageTamplate = event.keyValuePairOASPageTemplate;
            this.baseCampaign.keyValuesPairOASSection = event.keyValuePairOASSection;
        }
        else if (event.dialogType == 'inventoryAddOn') {
            // something
        }
        else if (event.dialogType == 'payment status') {
            this.baseCampaign.campaignStatusId = event.paymentStatus ? eCampaignStatus.Payment_Completed : eCampaignStatus.Payment_Pending;
        }
        else if(event.dialogType == 'contact'){
            this.contactInfo = event.contactInfo;
            if(!!event.user){
                    this.baseCampaign.contact = event.user;
                    this.contactValues[0] = !!this.baseCampaign.contact && !!this.baseCampaign.contact.fullName ?
                        this.baseCampaign.contact.fullName : '';
                    this.contactValues[1] = !!this.baseCampaign.contact && !!this.baseCampaign.contact.fullName ?
                        this.baseCampaign.contact.email : this.baseCampaign.propertyEmails;
                    this.baseCampaign.propertyEmails=!!this.baseCampaign.contact && !!this.baseCampaign.contact.email ? this.baseCampaign.contact.email: this.baseCampaign.propertyEmails;
            }
            else{
                this.contactValues[0]="";
                this.contactValues[1]="";
                this.baseCampaign.propertyEmails=null;
            }
        }

        else {
            this.baseCampaign.landingPageJson.url = event.landingPageUrl;
        }
    }

    campaignDatesSaved(event: any) {
        if (event.isStartDateChanged) {
            this.baseCampaign.startDate = event.startDate;
        }
        if (event.isEndDateChanged) {
            this.baseCampaign.endDate = event.endDate;
        }

        this.campaignDates = `${this.formatDate(
            (this.baseCampaign.startDate))} - ${this.formatDate(this.baseCampaign.endDate)}`;

        this.onDateChange.emit({ isStartDateChanged: event.isStartDateChanged, isEndDateChanged: event.isEndDateChanged });
    }

    pssAdOnSaved(event: any) {
        this.baseCampaign.pssAdOnsJson = event.selectedAddOns;
        this.setupImpressions();
    }

    formatDate(dateProp: any) {
        return `${this.dateTime.transform(dateProp, this.constants.DEFAULT_FORMAT_DATE_US)}`;
        //  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    getDayDifference() {
        let startDate = new Date(this.baseCampaign.startDate);
        let endDate = new Date(this.baseCampaign.endDate);
        var diffDays = ((Date.UTC(endDate.getUTCFullYear(), endDate.getMonth(), endDate.getDate()) -
            Date.UTC(startDate.getUTCFullYear(), startDate.getMonth(), startDate.getDate())) / 86400000)+1;
        // var difference_In_Time = new Date(this.baseCampaign.endDate).getTime() - new Date(this.baseCampaign.startDate).getTime();
        // var diff = Math.floor(difference_In_Time / (1000 * 3600 * 24));
        // var diffDays = diff + 1;
        if (this.baseCampaign.campaignGoalId == 3)
            return diffDays;
        else
            return diffDays / 7;
    }

    landingPageEditButtonValidation(){
       return (this.currentUser.permissions.isClient && this.currentUser.userRole!=eUserRole.LIMGCSocialAdOps && this.currentUser.userRole!=eUserRole.LIMGC_AdOps_Admin && (this.campaignStatusId == eCampaignStatus.Assets_Pending || !this.isCampaignActivatedOutsideOfHub)) 
       || ((!this.currentUser.permissions.isClient || this.currentUser.userRole==eUserRole.LIMGCSocialAdOps || this.currentUser.userRole==eUserRole.LIMGC_AdOps_Admin) && (this.campaignStatusId == eCampaignStatus.Assets_Pending || this.campaignStatusId == eCampaignStatus.Assets_Uploaded || this.campaignStatusId == eCampaignStatus.Assets_Approved || this.campaignStatusId == eCampaignStatus.Assets_Declined
        || this.baseCampaign.statusId == eCampaignStatus.Assets_Pending || this.baseCampaign.statusId == eCampaignStatus.Assets_Uploaded || this.baseCampaign.statusId == eCampaignStatus.Assets_Approved || this.baseCampaign.statusId == eCampaignStatus.Assets_Declined
        || this.baseCampaign.statusId == eCampaignStatus.Creative_Swaps || this.campaignStatusId == eCampaignStatus.Creative_Swaps
        || !this.isCampaignActivatedOutsideOfHub));
    }
}
