import {
    Component,
    OnDestroy,
    Input
} from '@angular/core';
import { eCampaignStatus } from '@Workspace/_generated/enums';
import { ICreativeAssetDto } from '@Workspace/_generated/interfaces';



@Component({
    selector: 'file-approve',
    templateUrl: './file-approve.component.html',
    styleUrls: ['./file-approve.component.scss']
})
export class FileApproveComponent implements OnDestroy {

    enumCampaignStatus = eCampaignStatus;

    @Input() creativeAsset: ICreativeAssetDto;
    @Input() isApprovedReported: boolean;
    @Input() reviewPage: boolean;
    @Input() isFromPreview: boolean;
    @Input() headerTitle: string;
    @Input() isBuilt: boolean;
    @Input() statusId: eCampaignStatus;

    approveReport(event) {
        this.creativeAsset.isValid = event;
        this.isApprovedReported = true;

    }

    ngOnDestroy(): void {

    }

}