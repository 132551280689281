import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TargetingController } from '@Workspace/_generated/services';
import { IGoogleGeoTargetDto, IGoogleDmaDto, IGeographicTargetingJson, IGeographicDialogDataDto, ISelectItem } from '@Workspace/_generated/interfaces';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, subscribeOn, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { eAccountType } from '@Workspace/_generated/enums';

@Component({
    selector: 'advertiser-dialog',
    templateUrl: './advertiser-dialog.component.html',
    styleUrls: ['./advertiser-dialog.component.scss']
})
export class AdvertiserDialog implements OnInit {

    isVisible: boolean = false;
    canSave: boolean = true;
    advertiserId: any;
    advertiserName: string = '';
    ttdadvertiserId: string;
    dv360advertiserId: string;
    gamadvertiserId: string;
    defaultValues: any;
    editableFields: boolean[] = [];
    header: string;
    landingPageUrl: string;
    campaignType: eAccountType;
    constructor() { }
    isAdveriserIdEnteredInHub : boolean;
    isDv360AdveriserIdEnteredInHub : boolean;
    isTTDAdveriserIdEnteredInHub : boolean;
    isGAMAdveriserIdEnteredInHub: boolean;  
    enumAccountType = eAccountType;

    ngOnInit() {

    }

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    private ngUnsubscribe = new Subject();

    openDialog(value: any, header: string, editable: any[], campaignType: eAccountType, advertiserName: string, ttdAdvertiserId: string, dv360AdvertiserId: string, gamadvertiserId: string) {
        this.defaultValues = { ...arguments };
        this.header = header;
        if (header == 'Advertiser') {
            this.advertiserName = advertiserName;
            this.canSave = true;
            if(campaignType == eAccountType.FAN365){
                this.advertiserId = value;
                this.ttdadvertiserId = ttdAdvertiserId;
                this.dv360advertiserId =  dv360AdvertiserId;
            }
            else if(campaignType == eAccountType.OAS){
                this.advertiserId = value;
                this.gamadvertiserId = gamadvertiserId;
            }
        } else {
            this.landingPageUrl = value;

        }
        this.editableFields = arguments[3];
        this.isVisible = true;
        this.campaignType = campaignType;

    }

    resetDefault() {
        if (this.header == 'Advertiser') {
            this.advertiserId = this.defaultValues[0];
            this.ttdadvertiserId = this.defaultValues[5];
            this.dv360advertiserId = this.defaultValues[6];
        } else {
            this.landingPageUrl = this.defaultValues[0];

        }


    }

    save() {
        if (this.header == 'Advertiser') {
            const dialogResult = {
                dialogType: 'advertiser',
                advertiserId: this.advertiserId,
                advertiserName: this.advertiserName,
                ttdadvertiserId: this.ttdadvertiserId,
                dv360advertiserId: this.dv360advertiserId,
                gamadvertiserId: this.gamadvertiserId,
                isAdveriserIdEnteredInHub : this.isAdveriserIdEnteredInHub,
                isDv360AdveriserIdEnteredInHub : this.isDv360AdveriserIdEnteredInHub,
                isTTDAdveriserIdEnteredInHub : this.isTTDAdveriserIdEnteredInHub,
                isGAMAdveriserIdEnteredInHub: this.isGAMAdveriserIdEnteredInHub

            };
            this.onSave.emit(dialogResult);

        } else {
            const dialogResult = {
                landingPageUrl: this.landingPageUrl,
            };
            this.onSave.emit(dialogResult);

        }

        this.isVisible = false;
    }

    cancel() {
        this.isVisible = false;
    }

    onChangeGCMAdvertiserId(){
        this.isAdveriserIdEnteredInHub = true;
    }

    onChangeTTDAdvertiserId(){
        this.isTTDAdveriserIdEnteredInHub = true;
    }

    onChangeDV360AdvertiserId(){
        this.isDv360AdveriserIdEnteredInHub = true;
    }

    onChangeGAMAdvertiserId(){
        this.isGAMAdveriserIdEnteredInHub = true;
    }
    

    validateLandingPage() {
        if (!!this.landingPageUrl) {
            let textToMacth = this.landingPageUrl;
            // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
            var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
            var found = textToMacth.match(regex);
            if (found != null) {
                this.canSave = true;
            }

            else {
                this.canSave = false;
            }
        }
    }


}