import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MenuItem, SelectItem, TreeNode, MessageService } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eStandardCampaginChannel, eChannel, eChannelType, eFileType, eUserRole, eVideoType, eCallToAction } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { EnumStringPipe } from '@Workspace/pipes';
import { FileUpload } from '../../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IFacebookDetailsJson, IFacebookNewsFeedDetailJson, IInStreamVideoJson, IDetailJson, ISelectItem, IDetailSocialCampaignJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { Functions, LookUps, Constants } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../../../mogo/services/campaign.service';
import { constants } from 'os';

@Component({
    selector: 'social-link-campaign',
    templateUrl: './social-link-campaign.component.html',
    styleUrls: ['./social-link-campaign.component.scss']
})
export class SocialLinkCampaign implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
        private campaignService: CampaignService
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() campaign: IBaseCampaignDto;

    @Output() Basecampaign: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();

    isPreviewOpen: boolean;
    ngUnsubscribe = new Subject();
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    isUploadOk: boolean = true;
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    constants = Constants;
    
    currentUser = this.authService.userSettings;
    creativeName: string;

    ngOnInit() {

        this.creativeName = new EnumStringPipe().transform(this.creative.creativeTypeId, eStandardCampaginChannel);

        this.creative.creativeAssets.forEach(x => {
            if (!!x.file && !!x.file.urlWithToken)
                this.creativeAssetsForPreview.push(x);
        });

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                //this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId);
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            });

        
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    openPreview(event) {
        this.openPreviewEvent.emit(event);
        
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }

}
