import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { componentFadeIn } from '../../route-animations';
import { IGridOptions } from '@Workspace/interfaces';
import { Functions, Constants } from '@Workspace/common';
import {
    AccountController,
    CampaignController
} from '@Workspace/_generated/services';
import { eCampaignStatus, eAccountType } from '@Workspace/_generated/enums';
import { SettingsService, StorageService } from '@Workspace/services';
import { IUserPropertiesDto, IAccountDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'campaign-list-group-component',
    templateUrl: './campaign-list-group.component.html',
    styleUrls: ['./campaign-list-group.component.scss'],
    animations: [componentFadeIn]
})
export class CampaignListGroupComponent implements OnInit, OnDestroy {

    constructor(
        public accountController: AccountController,
        public campaignController: CampaignController,
        private settingsService: SettingsService
    ) {
    }

    @Input() options: IGridOptions;
    @Input() gridId: number;

    loaded: boolean = false;
    constants = Constants;
    enumCampaignStatus = eCampaignStatus;
    enumAccountType = eAccountType;
    ngUnsubscribe = new Subject();
    currentUser: IUserPropertiesDto;
    searchParameter: string;
    currentAccount: IAccountDto;
    grids: SelectItem[] = [];

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getTextAlignClass(textAlign: string): string {
        let textAlignClass: string = "text-left";
        switch (textAlign) {
            case 'left':
                textAlignClass = "text-left";
                break;
            case 'center':
                textAlignClass = "text-center";
                break;
            case 'right':
                textAlignClass = "text-right";
                break;
        }

        return textAlignClass;
    }

    navigateToReport() {
        return "";
    }

    remove(item) {

    }

    openEditDialog() {

    }
}
