<!-- <div  class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Post Details</strong></span>
</div> -->


<div class="pl-3" class="left-border ml-5">

    <div class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">Additional Details</label>
        </div>
    </div>

    <!-- <div class="row align-items-center mb-2 ml-2" >
        <div class="col-lg-3 col-12">
            <label title="Should an opt-in box for Athletics information/promotions be included on the entry form?"><strong>Athletics Opt-In</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage " type="text" [(ngModel)]="creative.detailSocialCampaignJson.athleticsOptIn" [title]="creative.detailSocialCampaignJson.athleticsOptIn" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.athleticsOptIn}}</label>
        </div>
    </div> -->

    <!-- <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <div class="row col-lg-12 col-12">
                <label class="mb-0"><strong>Game Start Date</strong></label>
            </div>
        </div>
        <div class="col-lg-3 col-12">
            <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div class="calendar-wrapper">
                    <p-calendar placeholder="mm/dd/yyyy" [disabled]="reviewPage"  [(ngModel)]="creative.detailSocialCampaignJson.startDate" [showIcon]="'true'"></p-calendar>
                </div>
            </div>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <div class="row col-lg-12 col-12">
                <label class="mb-0"><strong>Game End Date</strong></label>
            </div>
        </div>
        <div class="col-lg-3 col-12">
            <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div class="calendar-wrapper">
                    <p-calendar placeholder="mm/dd/yyyy" [disabled]="reviewPage"  [(ngModel)]="creative.detailSocialCampaignJson.endDate" [showIcon]="'true'"></p-calendar>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="What type of game was sold? View all available CataBoom mechanics here: https://www.cataboom.com/resources/mechanics"><strong>Game Mechanic</strong></label>
        </div>
        <div class="col-lg-3 col-12">
            <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" (onChange)="setGameMechanic($event)" *ngIf="!reviewPage && campaign.campaignStatusId == 1 " autoWidth="false" [(ngModel)]="creative.detailSocialCampaignJson.gameMechanic" [options]="gameMechanicDD"></p-dropdown>
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{gameMechanicLabel}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId != creativeTypes.Snap_AR">
        <div class="col-lg-3 col-12">
            <label title="What is/are the objective(s) for the client/sponsor/brand in executing this game? Examples: lead generation, increase foot traffic, brand awareness, etc.***"><strong>
                Sponsor Objective</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.sponsorObjective" [title]="creative.detailSocialCampaignJson.sponsorObjective" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.sponsorObjective}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId != creativeTypes.Snap_AR">
        <div class="col-lg-3 col-12">
            <label><strong>Name of Game</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.nameOfGame" [title]="creative.detailSocialCampaignJson.nameOfGame" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.nameOfGame}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" >
        <div class="col-lg-3 col-12">
            <label title="Who will provide final approvals during the review and launch process? (Select all that apply)">
                <strong>Game Approval</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <p-checkbox class="row ml-0" name="gameApproval" [disabled]="reviewPage || campaign.campaignStatusId != 1" value=1 label="Property" [(ngModel)]="creative.detailSocialCampaignJson.gameApproval"></p-checkbox>
            <p-checkbox class="row ml-0" name="gameApproval" [disabled]="reviewPage || campaign.campaignStatusId != 1" value=2 label="Sponsor" [(ngModel)]="creative.detailSocialCampaignJson.gameApproval"></p-checkbox>
            <p-checkbox class="row ml-0" name="gameApproval" [disabled]="reviewPage || campaign.campaignStatusId != 1" value=3 label="Agency" [(ngModel)]="creative.detailSocialCampaignJson.gameApproval"></p-checkbox>
            <p-checkbox class="row ml-0" name="gameApproval" [disabled]="reviewPage || campaign.campaignStatusId != 1" value=4 label="Athletics" [(ngModel)]="creative.detailSocialCampaignJson.gameApproval"></p-checkbox>
            <p-checkbox class="row ml-0" name="gameApproval" [disabled]="reviewPage || campaign.campaignStatusId != 1" value=5 label="other" [(ngModel)]="creative.detailSocialCampaignJson.gameApproval"></p-checkbox>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="Is there an age restriction to play the game? If yes, how old must the player be to participate?">
                <strong>Age Restriction</strong></label>
        </div>
        <div class="col-lg-3 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="number" [(ngModel)]="creative.detailSocialCampaignJson.ageRestriction" [title]="creative.detailSocialCampaignJson.ageRestriction">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.ageRestriction}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="Is participation limited to students only?">
                <strong>Student Restriction</strong></label>
        </div>
        <div class="col-lg-5 col-12">
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="studentRestriction_1" name="studentRestriction" [(ngModel)]="creative.detailSocialCampaignJson.studentRestriction" class="p-radiobutton mr-2 custom-control-input" [value]=1>
                <label class="custom-control-label red" for="studentRestriction_1">Yes</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="studentRestriction_2" name="studentRestriction" [(ngModel)]="creative.detailSocialCampaignJson.studentRestriction" class="p-radiobutton mr-2 custom-control-input" [value]=2>
                <label class="custom-control-label red" for="studentRestriction_2">No</label>
            </div>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="Are participants and/or winners limited to a specific geographic location (city, county, state, region, etc.)? If yes, what is that area?">
                <strong>Location Restriction</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <div class="campaign-card-content" *ngIf="geographicsValues[2]!=''"><span style="font-weight: bold;">State: </span><span>{{ geographicsValues[2] }}</span></div>
            <div class="campaign-card-content" *ngIf="geographicsValues[1]!=''"><span style="font-weight: bold;">DMA: </span><span>{{ geographicsValues[1] }}</span></div>
            <div class="campaign-card-content" *ngIf="geographicsValues[0]!=''"><span style="font-weight: bold;">City: </span><span>{{ geographicsValues[0] }}</span></div>
            <div class="campaign-card-content" *ngIf="geographicsValues[3]!=''"><span style="font-weight: bold;">ZIP: </span><span>{{ geographicsValues[3] }}</span></div>
            <i (click)="editEvent($event)" class="fa fa-edit">Edit Location Restriction</i>
        </div>
        
    </div>

    <!-- <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>Is purchase necessary to play?</strong></label>
        </div>
        <div class="col-lg-5 col-12">
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage" id="isPurchaseNecessary_1" name="isPurchaseNecessary" [(ngModel)]="creative.detailSocialCampaignJson.isPurchaseNecessary" class="p-radiobutton mr-2 custom-control-input" [value]=1>
                <label class="custom-control-label red" for="isPurchaseNecessary_1">Yes</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage" id="isPurchaseNecessary_2" name="isPurchaseNecessary" [(ngModel)]="creative.detailSocialCampaignJson.isPurchaseNecessary" class="p-radiobutton mr-2 custom-control-input" [value]=2>
                <label class="custom-control-label red" for="isPurchaseNecessary_2">No</label>
            </div>
        </div>
    </div> -->

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>How often can an entrant play?</strong></label>
        </div>
        <div class="col-lg-5 col-12">
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="howOftenCanPlay_1" name="howOftenCanPlay" [(ngModel)]="creative.detailSocialCampaignJson.howOftenCanPlay" class="p-radiobutton mr-2 custom-control-input" [value]=1>
                <label class="custom-control-label red" for="howOftenCanPlay_1">Once a day (recommended)</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="howOftenCanPlay_2" name="howOftenCanPlay" [(ngModel)]="creative.detailSocialCampaignJson.howOftenCanPlay" class="p-radiobutton mr-2 custom-control-input" [value]=2>
                <label class="custom-control-label red" for="howOftenCanPlay_2">Once a week</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="howOftenCanPlay_3" name="howOftenCanPlay" [(ngModel)]="creative.detailSocialCampaignJson.howOftenCanPlay" class="p-radiobutton mr-2 custom-control-input" [value]=3>
                <label class="custom-control-label red" for="howOftenCanPlay_3">Once total</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="howOftenCanPlay_4" name="howOftenCanPlay" [(ngModel)]="creative.detailSocialCampaignJson.howOftenCanPlay" class="p-radiobutton mr-2 custom-control-input" [value]=4>
                <label class="custom-control-label red" for="howOftenCanPlay_4">Unlimited</label>
            </div>
        </div>
    </div>
</div>

<!-- Prizing detail -->
<div class="pl-3" class="left-border ml-5">

    <div class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">Prizing detail</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId != creativeTypes.Snap_AR">
        <div class="col-lg-3 col-12">
            <label><strong>Detailed Prize Description</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.detailedPrizeDescription" [title]="creative.detailSocialCampaignJson.detailedPrizeDescription" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.detailedPrizeDescription}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2" *ngIf="creative.creativeTypeId != creativeTypes.Snap_AR">
        <div class="col-lg-3 col-12">
            <label><strong>Total Estimated Prize Value</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.totalEstimatePrizeValue" [title]="creative.detailSocialCampaignJson.totalEstimatePrizeValue" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.totalEstimatePrizeValue}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>How will an entrant win?</strong></label>
        </div>
        <div class="col-lg-5 col-12">
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="entrantWin_1" name="entrantWin" [(ngModel)]="creative.detailSocialCampaignJson.entrantWin" class="p-radiobutton mr-2 custom-control-input" [value]=1>
                <label class="custom-control-label red" for="entrantWin_1">Instant win</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="entrantWin_2" name="entrantWin" [(ngModel)]="creative.detailSocialCampaignJson.entrantWin" class="p-radiobutton mr-2 custom-control-input" [value]=2>
                <label class="custom-control-label red" for="entrantWin_2">Grand prize drawing</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="entrantWin_3" name="entrantWin" [(ngModel)]="creative.detailSocialCampaignJson.entrantWin" class="p-radiobutton mr-2 custom-control-input" [value]=3>
                <label class="custom-control-label red" for="entrantWin_3">Combination of instant win and grand prize drawing</label>
            </div>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="Please list each prize type and whether each is an Instant Win prize or Grand Prize. Quantity and Approximate Retail Value for each prize type must be included.">
                <strong>What prizes will be awarded?</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.prizes" [title]="creative.detailSocialCampaignJson.prizes" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.prizes}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="If not an instant winner, do players receive a consolation prize/offer just for participating? If yes, please provide details.">
                <strong>Consolation Prize/Offer</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.consolationPrize" [title]="creative.detailSocialCampaignJson.consolationPrize" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.consolationPrize}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="Are any prizes limited to redemption at a specific location or in a certain area (retail, QSR, etc.)? If yes, please list details.">
                <strong>Prize Distribution</strong></label>
        </div>
        <div class="col-lg-5 col-12">
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="prizeDistribution_1" name="prizeDistribution" [(ngModel)]="creative.detailSocialCampaignJson.prizeDistribution" class="p-radiobutton mr-2 custom-control-input" [value]=1>
                <label class="custom-control-label red" for="prizeDistribution_1">Delivered digitally</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="prizeDistribution_2" name="prizeDistribution" [(ngModel)]="creative.detailSocialCampaignJson.prizeDistribution" class="p-radiobutton mr-2 custom-control-input" [value]=2>
                <label class="custom-control-label red" for="prizeDistribution_2">Delivered via shipment</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="prizeDistribution_3" name="prizeDistribution" [(ngModel)]="creative.detailSocialCampaignJson.prizeDistribution" class="p-radiobutton mr-2 custom-control-input" [value]=3>
                <label class="custom-control-label red" for="prizeDistribution_3">Pick up in person</label>
            </div>
            <div class="col-lg-12 col-12 custom-control custom-radio custom-control-inline">
                <input type="radio" [disabled]="reviewPage || campaign.campaignStatusId != 1" id="prizeDistribution_4" name="prizeDistribution" [(ngModel)]="creative.detailSocialCampaignJson.prizeDistribution" class="p-radiobutton mr-2 custom-control-input" [value]=4>
                <label class="custom-control-label red col-lg-4 pl-0" for="prizeDistribution_4">Other (form fill)</label>
                <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.prizeDistributionString" [title]="creative.detailSocialCampaignJson.prizeDistributionString" style="overflow: hidden; text-overflow: ellipsis;">
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.prizeDistributionString}}</label>
            </div>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="Are any prizes limited to redemption at a specific location or in a certain area (retail, QSR, etc.)? If yes, please list details">
                <strong>Prize Redemption Restrictions</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.prizeRedemptionRestrictions" [title]="creative.detailSocialCampaignJson.prizeRedemptionRestrictions" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.prizeRedemptionRestrictions}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label title="Who will be listed as the contact for any prizing notifications/issues/questions? (Property contact, sponsor contact, or both)">
                <strong>Prizing Contact</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.prizingContact" [title]="creative.detailSocialCampaignJson.prizingContact" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.prizingContact}}</label>
        </div>
    </div>

</div>


<geographic-dialog #geographics (onSave)="geographicSaved($event)"></geographic-dialog>