<!-- <div style="text-align: center;">[1]-[2]-[3]-[4]-[X]-[6]</div> -->

<div class="">

    <div class="col-lg 10">
        <div class="pl-0 col-md-11 mb-4">
            <h4 class="pl-0 display-4">Payment Portal</h4>
            <span>Please proceed with your payment details.</span>
        </div>
        <!-- <info-card header="Payment Portal" content="Please proceed with your payment details"></info-card> -->

        <div class="p-3 col-md-11 mt-3 bg-white">
            <div class="pt-3 d-flex justify-content-between align-items-center font-bigger">
                <b>Order Summary</b>
                <!-- <button type="button" pButton (click)="printOrder('printaArea')" [attr.title]="" class="print-btn" label="PRINT ORDER"> </button> -->
            </div>
            <hr />

            <div id="printaArea" class="row">
                <div class="col col-lg-6 col-md-6 col-sm-12 mt-3">
                    <div class="row justify-content-between mb-3">
                        <div class="col-sm-12 col-md-6 font-weight-bold">Description</div>
                        <div class="col-sm-12 col-md-6">{{ baseCampaign.name }} Mogo Campaign</div>
                    </div>

                    <div class="row justify-content-between mb-3">
                        <div class="col-sm-12 col-md-6 font-weight-bold">Invoice Number</div>
                        <div class="col-sm-12 col-md-6">{{ baseCampaign.billingInvoiceNumber }}</div>
                    </div>

                    <div class="mb-3 font-weight-bold">Terms and Conditions</div>

                    <div class="mb-3">Your credit card and/or bank information will not be saved.</div>

                    <div class="mb-3">
                        By submitting payment, you agree to
                        <a class="link font-weight-bold" (click)="redirectSA()">Mogo's Service Agreement</a>.
                    </div>
                </div>

                <div class="col col-lg-6 col-md-6 col-sm-12">
                    <campaign-costs-table #campaignCostsTable [baseCampaign]="baseCampaign"></campaign-costs-table>
                </div>
            </div>
        </div>

        <!--PAYMENT METHOD-->

        <div class="mt-3 px-0 col-md-11 font-bigger">
            <div class="row">
                <div *ngFor="let payment of paymentMethods" class="col-lg-6 col-12 mb-3 mb-lg-0">
                    <div class="p-3 d-flex justify-content-between align-items-center bg-white font-weight-bold">
                        <div class="d-flex justify-content-between align-items-center">
                            <i [ngClass]="payment.value == 1 ? 'far fa-credit-card mr-3' : 'fas fa-university mr-3'"></i>
                            <div>{{payment.label}}</div>
                        </div>
                        <div>
                            <input class="p-radiobutton" type="radio" [value]="payment.value" [(ngModel)]="defaultPaymentMethod">

                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-12">
                    <div class="p-3 d-flex justify-content-between align-items-center bg-white font-weight-bold">
                        <div class="d-flex justify-content-between align-items-center">
                            <i class="fas fa-university mr-3"></i>
                            <div>Bank Account (USA only)</div>
                        </div>
                        <div>
                            <input class="p-radiobutton" type="radio" [(ngModel)]="defaultPaymentMethod" [value]="paymentMethods[1].value">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <!--PAYMENT INFORMATION-->

        <div class="p-3 col-md-11 mt-3 bg-white">
            <div class="pt-3 font-bigger">
                <b>Credit Card Information</b>
            </div>
            <hr />
            <span *ngIf="defaultPaymentMethod == 1">
                <div class="row mb-1 mt-5">
                    <div class="required col-md-2">Card Number</div>
                    <div class="col-md-10"><input type="text" class="w-100" pInputText [(ngModel)]="cardNumber" (input)="onInputChange($event)" /></div>
                </div>

                <div class="row mb-5 align-items-center">
                    <div class="required col-md-2">Expiration Date</div>
                    <div class="col-md-1 monthDD">
                        <p-dropdown autoWidth="false" placeholder='--' [options]="expirationMonths" [(ngModel)]="expirationMonth"></p-dropdown>
                    </div>
                    <div class="col-md-2 ml-5">
                        <p-dropdown placeholder='----' [options]="expirationYears" [(ngModel)]="expirationYear"></p-dropdown>
                    </div>
                    <div class="required col-md-1">CVC</div>
                    <div class="col-md-2"><input type="number" pInputText [(ngModel)]="cvc" /></div>
                </div>
            </span>
            <span *ngIf="defaultPaymentMethod != 1">
                <div class="row mb-1 mt-5">
                    <div class="required col-md-2">Bank Name</div>
                    <div class="col-md-10"><input type="text" class="w-100" pInputText [(ngModel)]="bankName" /></div>
                </div>
                <div class="row mb-1">
                    <div class="required col-md-2">Account Number</div>
                    <div class="col-md-10"><input type="text" class="w-100" pInputText [(ngModel)]="accountNumber" /></div>
                </div>

                <div class="row mb-5 align-items-center">
                    <div class="required col-md-2">Routing Number</div>
                    <div class="col-md-10"><input type="text" class="w-100" pInputText [(ngModel)]="routingNumber" /></div>
                </div>
            </span>
            <div class="note p-3">
                <b class="mr-3 font-bigger font-weight-bold">Billing Address</b>
                <span class="text-muted">Please add information connected to the card above</span>
            </div>

            <div class="row mb-1 mt-5">
                <div class="required col-md-2">First Name</div>
                <div class="col-md-10"><input class="w-100" pInputText [(ngModel)]="firstName" /></div>
            </div>

            <div class="row mb-1">
                <div class="required col-md-2">Last Name</div>
                <div class="col-md-10"><input type="text" class="w-100" pInputText [(ngModel)]="lastName" /></div>
            </div>

            <div class="row mb-1">
                <div class="required col-md-2">Billing Street Address</div>
                <div class="col-md-10"><input class="w-100" type="text" class="w-100" pInputText [(ngModel)]="address" /></div>
            </div>

            <div class="row mb-5 align-items-center">
                <div class="required col-md-2">State</div>
                <div class="col-md-2">
                    <p-dropdown [options]="states" [(ngModel)]="selectedState" (onChange)="getCities($event.value)"></p-dropdown>
                </div>

                <div class="required col-md-2">Billing City</div>
                <div class="col-md-2">
                    <p-dropdown [disabled]="!selectedState" [options]="cities" [(ngModel)]="selectedCity"></p-dropdown>
                </div>

                <div class="required col-md-1">ZIP</div>
                <div class="col-md-2 float-right"><input type="text" [(ngModel)]="zipCode" pInputText /></div>
            </div>
        </div>

        <div *ngIf="!!errorMessage" class="alert col-md-11 alert-danger text-danger font-weight-bold text-center">Error</div>
        <div class="row col-md-12 pl-0 pr-3">
            <div class="col-2 mt-3"><button type="button" pButton class="flat-btn" (click)="goBack()" label="GO BACK"></button></div>
            <div class="col-md-9 ml-5">
                <div class="d-flex flex-row-reverse flex-wrap bd-highlight mt-3 mb-3">
                    <div [ngClass]="'pl-2'" class="py-2 bd-highlight"><button type="button" pButton (click)="pay()" [attr.title]="" [disabled]="!validate() || !validateCosts()" class="p-button-primary" label="PAY NOW & SUBMIT FOR REVIEW"></button></div>

                    <div class="py-2 bd-highlight"><button type="button" pButton (click)="save()" [disabled]="!validateCosts()" class="flat-btn" label="SAVE & EXIT TO HOME"> </button></div>

                    <!-- <div class="py-2 pr-2 bd-highlight"><button type="button" pButton class="flat-btn" label="Back"></button></div> -->
                    <div class="py-2 pr-2 bd-highlight"><button type="button" pButton class="flat-btn" (click)="cancel()" label="CANCEL"></button></div>

                </div>
            </div>
        </div>
        <!-- <div class="p-3 mt-3 bg-white">
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <button class="text-uppercase" label="Go Back" pButton></button>
                </div>
                <div class="col-sm-12 col-md-8 d-flex justify-content-end">
                    <button class="text-uppercase mr-1" label="Cancel" pButton></button>
                    <button class="text-uppercase mr-1" (click)="save()" label="Save & Exit to Home" pButton></button>
                    <button [disabled]="" (click)="pay()" class="text-uppercase" label="Pay now & submit for review" pButton></button>
                </div>
            </div>
        </div> -->
    </div>
</div>