import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem, ConfirmationService, MessageService } from 'primeng/api';
import { componentFadeIn } from '../../../route-animations';
import { IGridOptions } from '@Workspace/interfaces';
import { Functions, Constants } from '@Workspace/common';
import {
    AccountController
} from '@Workspace/_generated/services';
import { IAccountDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';
import { eIndustry, eEntityStatus, eAccountType, eRegion } from '@Workspace/_generated/enums';
import { Router } from '@angular/router';
import { SettingsService, PageLoaderService } from '@Workspace/services';
import { CampaignService } from '../../../services/campaign.service';
import { takeUntil } from 'rxjs/operators';
import { GridComponent } from '../../../../shared-core/components/grid/rc-grid.component';


@Component({
    selector: 'account-list',
    templateUrl: './account-list.component.html',
    styleUrls: ['./account-list.component.scss'],
    animations: [componentFadeIn]
})
export class AccountListComponent implements OnInit, OnDestroy {
    constructor(
        public accountController: AccountController,
        public authService: AuthService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private settingsService: SettingsService,
        private campaignService: CampaignService,
        private pageLoaderService: PageLoaderService,
        public messageService: MessageService

    ) {

    }

    ngUnsubscribe: Subject<void> = new Subject();
    options: IGridOptions;
    expandedRows: any = [];
    accounts: IAccountDto[] = [];
    currentUser = this.authService.userSettings;
    enumIndustry = eIndustry;
    enumAccountType = eAccountType;
    enumRegion = eRegion;
    selectedAccount: IAccountDto;
    isEditDialogOpen: boolean;
    isEditDialogDisplayed: boolean;
    searchParameter: string;
    @ViewChild('accountTable') accountTable: GridComponent;


    ngOnInit() {

        this.campaignService.getSearchPareameter().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            this.searchParameter = x;
            this.composeGridColumns(x);
        });

    }

    onChangeGrid(event) {
        if (event.value == 1)
            this.router.navigate(['/mogo/accounts']);
        else
            this.router.navigate(['/mogo/accounts/users']);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.campaignService.setSearchParameter("");
    }

    composeGridColumns(searchParameter?: string) {
        if (this.accountTable) {  
            this.accountTable.resetSkipTake();
        }
        this.options = {} as IGridOptions;

        this.options.columns = [
            {
                label: 'Account Name',
                value: 'name',
                width: '40%'
            },
            {
                label: 'Type',
                value: 'typeId',
                width: '10%'
            },
            {
                label: 'Industry',
                value: 'industry',
                width: '20%'
            },
            {
                label: 'Region',
                value: 'region',
                width: '20%'
            },

            {
                label: 'Action',
                value: '',
                align: 'right',
                width: '10%'

            }

        ];

        if (this.currentUser.permissions.isLearfieldClient) {
            this.options.filter = `contains(Name, '${this.currentUser.accounts[0].name}')`
        }
        if ((!!searchParameter || !!this.searchParameter) && !this.currentUser.permissions.isLearfieldClient) {
            const searchTerm = !!searchParameter ? searchParameter.replace(
                /\'/g,
                "''"
            ):
            this.searchParameter.replace(
                /\'/g,
                "''"
            );
            this.options.filter = `contains(Name, '${searchTerm}')`
        }


        let options = this.options;
        this.options = Object.assign({}, options);

        Functions.composeGridOptions(
            this.options,
            'Name',
            this.settingsService.createApiUrl('Account/GetAllAccounts'),
            false
        );
    }

    getTextAlignClass(textAlign: string): string {
        let textAlignClass: string = "text-left";
        switch (textAlign) {
            case 'left':
                textAlignClass = "text-left";
                break;
            case 'center':
                textAlignClass = "text-center";
                break;
            case 'right':
                textAlignClass = "text-right";
                break;
        }
        return textAlignClass;
    }

    openEditDialog(event) {
        this.selectedAccount = event;
        this.showHideDialog(true);

    }

    showHideDialog(event: any) {
        this.isEditDialogDisplayed = event;
        setTimeout(() => {
            this.isEditDialogOpen = event;
        }, 0);
    }

    remove(account) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'accountDelete',
            message:
                'This action will delete this account from HUB. Are you sure you want to proceed?',
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                this.pageLoaderService.showPageLoader();
                account.entityStatus = eEntityStatus.Deleted;
                this.accountController.CreateAccount(account).subscribe(x => {
                    this.campaignService.setRefresh(true);
                    this.messageService.add({ severity: 'success', detail: 'Account removed successfully'});
                    this.composeGridColumns();
                });
            }
        });

    }

}
