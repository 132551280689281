import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from '@Workspace/auth';
import { SelectItem } from 'primeng/api';
import { AccountController } from '@Workspace/_generated/services';
import { eUserRole } from '@Workspace/_generated/enums';
import { Subject } from 'rxjs';

@Component({
    selector: 'user-picklist',
    templateUrl: 'user-picklist.component.html'
})
export class UserPicklistComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private accountController: AccountController

    ) { }

    @Input() resource: string;
    @Input() sourceUsers: SelectItem[];
    @Input() targetUsers: SelectItem[];
    @Input() isFromAccount: boolean;
    @Input() isFromTeam: boolean;

    ngUnsubscribe: Subject<void> = new Subject();
    currentUser = this.authService.userSettings;

    ngOnInit() {
        let filter = '';

        filter = `?$filter=RoleId eq Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Client_Solution}' or RoleId eq Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Associate}' or RoleId eq Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Media_Activation}' or RoleId eq Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Admin}'`;
        // if (this.resource == "Account")
        //     filter += ` or (RoleId eq Learfield.Mogo_Fan365.Common.Enums.eUserRole'${eUserRole.Client}' and )`

        this.accountController.GetUsers(filter).subscribe(x => {
            let allUsers = x.data.map(u => { return { label: u.fullName, value: u.id } });
            if (!!this.targetUsers.length)
                this.targetUsers.forEach(x => x.label = allUsers.some(u => u.value == x.value) ? allUsers.find(u => u.value == x.value).label : null);
            this.sourceUsers = allUsers.filter(x => !!this.targetUsers.length ? !this.targetUsers.some(a => x.value == a.value) : x);
        })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}