import { HttpClient, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { eProviderType } from '@Workspace/_generated/enums';
import { IUserPropertiesDto } from '@Workspace/_generated/interfaces';
import { Constants, untilDestroy } from '@Workspace/common';
import { BehaviorSubject, empty, from, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

import { SettingsService } from '../settings/settings.service';
import { StorageService } from '../storage/storage.service';
import { IAuthService } from './iauth.service';
import { BroadcastService } from './msad/broadcast.service';
import { MsalWrapperService } from './msad/msal-wrapper.service';

@Injectable()
export class AuthAdSevice implements IAuthService, OnInit, OnDestroy {
    private static USER_SETTINGS: string = 'user_Settings';

    constructor(
        private broadcastService: BroadcastService,
        private msalWrapperService: MsalWrapperService,
        private storageService: StorageService,
        private settingsService: SettingsService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        this.broadcastService
            .getMSALItem()
            .pipe(untilDestroy(this))
            .subscribe(() => {
                let isLogged = !!this.msalWrapperService.msalService.getAccount();
                if (this._$userState.value != isLogged) {
                    this._$userState.next(isLogged);
                }
            });
    }

    ngOnDestroy(): void {}

    private _$userState = new BehaviorSubject(false);

    get $userState() {
        return this._$userState.asObservable();
    }

    userSettings: IUserPropertiesDto;

    get isLogged() {
        return this._$userState.value;
    }

    async reloadUserdataAsync(): Promise<void> {
        await this.loadUserSettings();
    }

    async reloadUserSettingsAsync() {
        await this.reloadUserdataAsync();
    }

    async initializeAsync(): Promise<void> {
        var isLogged = await this.loadUserSettings();
        if (!isLogged) {
            this.loginSocial(eProviderType.AzureAD);
        }
        await this.reloadUserdataAsync();
    }

    async login(username: string, password: string): Promise<void> {
        return;
    }

    async loginSocial(socialProvider: eProviderType): Promise<void> {
        this.msalWrapperService.msalService.loginRedirect();
        // await this.loadUserSettings();
    }

    async logOut(): Promise<void> {
        await this.storageService.clear();
        window.location.reload();
        // this.msalWrapperService.msalService.logout();
    }

    authorizeRequest(request: HttpRequest<any>, next: HttpHandler) {
        return this.getAuthResponse(request.url).pipe(
            concatMap(authResponse => {
                if (!authResponse || !authResponse.accessToken) {
                    return next.handle(request);
                }

                const newRequest = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${authResponse.accessToken}`
                    }
                });

                return next.handle(newRequest);
            })
        );
    }

    private acquireToken(scopes: string[]) {
        return from(
            this.msalWrapperService.msalService.acquireTokenSilent({
                scopes: scopes,
                redirectUri: this.settingsService.createClientUrl(
                    Constants.AD_IFRAME_REDIRECT_URL
                )
            })
        ).pipe(
            catchError(error => {
                console.log(error);
                const user = this.msalWrapperService.msalService.getAccount();

                if (
                    error.errorMessage.indexOf('AADSTS50058') >= 0 ||
                    error.errorCode === 'consent_required' ||
                    error.errorCode === 'interaction_required'
                ) {
                    this.msalWrapperService.msalService.acquireTokenRedirect({
                        scopes: scopes,
                        account: user
                    });
                }

                return throwError(error);
            })
        );
    }

    private getAuthResponse(url: string) {
        const scopes = this.msalWrapperService.msalService.getScopesForEndpoint(
            url
        );

        if (!scopes || !this.msalWrapperService.msalService.getAccount()) {
            return empty();
        }
        return this.acquireToken(scopes);
    }

    private async loadUserSettings(): Promise<boolean> {
        let url = this.settingsService.createApiUrl(
            'Account/GetCurrentUserProperties'
        );

        var authResponse = await this.getAuthResponse(url).toPromise();
        if (!authResponse) {
            await this.storageService.clear();
            return false;
        }

        var headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + authResponse.accessToken);

        try {
            var data = await new Promise((resolve, reject) => {
                this.http
                    .get(url, {
                        headers: headers
                    })
                    .subscribe(
                        response => {
                            resolve(response || {});
                        },
                        error => {
                            reject(error);
                        }
                    );
            });
        } catch (error) {
            await this.storageService.clear();
            this._$userState.next(false);
            return false;
        }

        var expiration = authResponse.expiresOn;

        await this.storageService.set(
            AuthAdSevice.USER_SETTINGS,
            data,
            expiration
        );

        this.userSettings = data as IUserPropertiesDto;
        this._$userState.next(true);
        return true;
    }
}
