import { Component, OnInit, Input, ViewChildren, AfterViewInit, OnChanges, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MenuItem, SelectItem, MessageService } from 'primeng/api';
import { SuCampaignChannelComponent } from '../su-campaign-channels/su-campaign-channels.component';
import { IChannelDto, IChannelJson, IBaseCampaignDto, IUserDto, IAccountDto, ICreativeDto, ICampaignDto, IPagingResult } from '@Workspace/_generated/interfaces';
import { AccountController, CampaignController } from '@Workspace/_generated/services';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ElementSanitizerPipe } from '@Workspace/pipes';
import { PageLoaderService } from '@Workspace/services';
import { Constants, Functions } from '@Workspace/common';
import { eChannelType } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';


@Component({
    selector: 'su-campaign-detail-create',
    templateUrl: 'su-campaign-detail-create.component.html',
    styleUrls: ['./su-campaign-detail-create.component.scss']
})
export class SuCampaignDetailComponent implements OnInit {
    constructor(public accountController: AccountController, public messageService: MessageService,
        public activatedRoute: ActivatedRoute,
        public authService: AuthService,
        public campaignController: CampaignController,
        public pageLoaderService: PageLoaderService,
        private cd: ChangeDetectorRef
    ) { }

    isChannelOptionVisible: boolean = true;
    isReviewStepVisible: boolean = false;
    isCreativePageVisible: boolean = false;
    currentUser = this.authService.userSettings;
    list: any[] = [];
    creativeDto: ICreativeDto[] = [];
    IsPreviousPage: boolean = false;
    channelTypes: any;
    companyOptions: SelectItem[] = [];
    usersOptions: SelectItem[] = [];
    userDto: IUserDto[] = [];
    campaign: IBaseCampaignDto = {} as IBaseCampaignDto;
    minDueDates: Date = new Date;
    minStartDate: Date = new Date;
    minEndDate: Date = new Date;
    startDate: Date;
    endDate: Date;
    dueDate: Date;
    @Output() channelOutput: EventEmitter<any> = new EventEmitter();
    formGroup: UntypedFormGroup;
    campaignId: string;
    ngUnsubscribe = new Subject();
    campaignDto: any
    selectedDimensions: string[] = [];

    maxStartDate: Date = null;

    ngOnInit() {
        this.pageLoaderService.showPageLoader();

        this.minDueDates.setDate(new Date().getDate() + 1)
        this.accountController.GetAccountsByCurrentUser().subscribe(x => {
            this.companyOptions = [];
            this.companyOptions = x.map(account => {
                let company = {} as SelectItem;
                company.value = account.id;
                company.label = account.name;
                return company;
            });
            this.cd.detectChanges();
            this.pageLoaderService.hidePageLoader();


            this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
                this.campaignId = params.slug;


                if (this.campaignId != Constants.GuidEmpty) {
                    this.pageLoaderService.showPageLoader();

                    let filter = `?$filter=Id eq ${this.campaignId}`;
                    this.campaignController.GetAllCampaigns(filter).subscribe(data => {
                        if (data.totalCount > 0) {
                            this.campaign.account = {} as IAccountDto;
                            this.campaign = data.data[0];
                            let selectedAccount = this.companyOptions.find(x => x.value == this.campaign.accountId)
                            this.campaign.accountId = selectedAccount.value;
                            this.onCompanySelected(this.campaign.accountId)
                            this.campaign.contactId = this.userDto.find(x => x.email == this.campaign.propertyEmails) ? this.userDto.find(x => x.email == this.campaign.propertyEmails).id : null;
                            // this.campaign.endDate = new Date(Functions.dateToUserTimeZone(this.campaign.endDate.toString(), this.currentUser.timeZoneJs));
                            //this.campaign.startDate = new Date(Functions.dateToUserTimeZone(this.campaign.startDate.toString(), this.currentUser.timeZoneJs));
                            // this.campaign.dueDate = new Date(Functions.dateToUserTimeZone(this.campaign.dueDate.toString(), this.currentUser.timeZoneJs));
                            this.endDate = new Date(this.campaign.endDate.toString());
                            this.startDate = new Date(this.campaign.startDate.toString());
                            this.dueDate = new Date(this.campaign.dueDate.toString());
                            var dimensions = [];
                            this.campaign.creatives.forEach(x => {
                                if (x.channelId == eChannelType.Display)
                                    x.creativeAssets.forEach(y => {
                                        dimensions.push(y.width + "x" + y.height)
                                    })
                                x.bannerDimensions = dimensions;
                            })

                            this.minEndDate.setDate(this.startDate.getDate() + 1);
                            if(this.endDate != null)
                            {
                                this.maxStartDate = new Date(this.endDate);
                                this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
                            }

                            this.pageLoaderService.hidePageLoader();
                        }
                    },
                    error => {
                        this.pageLoaderService.hidePageLoader();
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
                    });

                }
            });
        });

    }

    OnNextStep() {
        if (!!this.campaign.accountId || !!this.campaign.contactId || !!this.campaign.name || !!this.campaign.startDate || !!this.campaign.endDate || !!this.campaign.dueDate) {
            this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Due date is past the start date' });
        }
        else {
            this.campaign.propertyEmails = this.userDto.find(x => x.id == this.campaign.contactId).email;
            this.campaign.contact = {} as IUserDto;
            this.campaign.contact = this.userDto.find(x => x.id == this.campaign.contactId);
            this.campaign.account = {} as IAccountDto;
            let selectedAccount = this.companyOptions.find(x => x.value = this.campaign.accountId)
            this.campaign.account.id = selectedAccount.value;
            this.campaign.account.name = selectedAccount.label;
            this.campaign.id = this.campaignId;
            this.channelOutput.emit(this.creativeDto);
            this.isChannelOptionVisible = false;

        }

    }

    onReview(event: any) {
        this.campaign.propertyEmails = this.userDto.find(x => x.id == this.campaign.contactId).email;
        this.campaign.account = {} as IAccountDto;
        let selectedAccount = this.companyOptions.find(x => x.value == this.campaign.accountId)
        this.campaign.account.id = selectedAccount.value;
        this.campaign.account.name = selectedAccount.label;
        this.campaign.contact = {} as IUserDto;
        this.campaign.contact = this.userDto.find(x => x.id == this.campaign.contactId);
        this.isReviewStepVisible = event.isReviewPageVisible;
        this.channelTypes = event;

    }

    onInput(event: any) {
        if (!!this.campaign.accountId && !!this.campaign.contactId && !!this.campaign.name && (!!this.campaign.startDate || this.startDate) && (!!this.campaign.endDate || this.endDate) && (!!this.campaign.dueDate || this.dueDate)) {
            if (!this.campaign.startDate) {
                this.onStartDateChange(this.startDate);
            }
            if (!this.campaign.endDate) {
                this.onEndDateChange(this.endDate);
            }
            if (!this.campaign.dueDate) {
                this.onDueDateChange(this.dueDate);
            }

            this.isCreativePageVisible = true;
            this.creativeDto = event.creativeDto;
            this.selectedDimensions = event.dimensions;
        }
        else {
            this.messageService.add({ severity: 'info', detail: 'Please populate all necessary fields.' });
        }
    }

    onOutput() {

    }

    onCompanySelected(event: any) {
        this.pageLoaderService.showPageLoader();

        if (!!event.value) {
            this.accountController.GetUsersByAccount(event.value).subscribe(usersDetail => {
                this.userDto = usersDetail;
                this.usersOptions = usersDetail.map(users => {

                    let userOption = {} as SelectItem;

                    userOption.label = users.fullName;
                    userOption.value = users.id;

                    return userOption;
                });
                this.usersOptions.unshift({ value: null, label: '-' });
            })

        }
        else {
            this.accountController.GetUsersByAccount(event).subscribe(usersDetail => {
                this.userDto = usersDetail;
                this.campaign.contactId = !!this.userDto.find(x => x.email == this.campaign.propertyEmails) ? this.userDto.find(x => x.email == this.campaign.propertyEmails).id : this.userDto[0].id;
                this.usersOptions = usersDetail.map(users => {
                    
                    let userOption = {} as SelectItem;
                    userOption.label = users.fullName;
                    userOption.value = users.id;
                    return userOption;
                });
                this.usersOptions.unshift({ value: null, label: '-' });
            });
        }
        this.pageLoaderService.hidePageLoader();

    }

    onPreviousStep(event) {
        this.creativeDto = event.campaign
        this.IsPreviousPage = event.IsPrevious;
        this.isCreativePageVisible = false;
        this.isChannelOptionVisible = true;
    }

    onCreativeStep(event) {
        this.isCreativePageVisible = true;
        this.isReviewStepVisible = false;
    }

    onStartDateChange(startDate: Date) {
        if (!!startDate)
            this.minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()+1);
            //this.minEndDate.setDate(startDate.getDate() + 1)

        if (startDate == null || startDate > this.campaign.endDate)
            this.campaign.endDate = null;
        this.campaign.startDate = startDate;
        Functions.dateTimeZoneConversion(this.campaign.startDate);

    }

    onEndDateChange(endDate: Date) {
        this.maxStartDate = new Date(endDate);
        this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
        this.campaign.endDate = endDate;
        Functions.dateTimeZoneConversion(this.campaign.endDate);
    }

    onDueDateChange(dueDate: Date) {
        this.campaign.dueDate = dueDate;
        Functions.dateTimeZoneConversion(this.campaign.dueDate);

    }

    onContactChange(event: any) {
        if(!!event)
            this.campaign.propertyEmails = this.userDto.find(x => x.id == event).email;
    }
}