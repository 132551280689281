
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'component-loader-spinner',
    template: `
    <div [ngStyle]="loading()" class="full-page-loader page-loader-bg" ></div>
  `,
    styleUrls: ['./component-loader-spinner.component.scss']
})
export class ComponentLoaderSpinnerComponent implements OnInit {

    @Input() isLoading: boolean = false;

    constructor() { }

    ngOnInit() {
    }

    loading() {
        return this.isLoading ? '' : {'display': 'none'};
    }

}