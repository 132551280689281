import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { IUserPropertiesDto, IInstructionPageDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';
import { takeUntil } from 'rxjs/operators';
import { InstructionsController } from '@Workspace/_generated/services';
import { NotificationService, PageLoaderService } from '@Workspace/services';
import { EditInstructionsDialog } from './edit-instructions-dialog/edit-instructions.dialog';

@Component({
    selector: 'mogo-instructions',
    templateUrl: './instructions.component.html',
    styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private instructionsCotroller: InstructionsController,
        private confirmationService: ConfirmationService,
        private notificationService: NotificationService,
        private pageLoader: PageLoaderService
    ) { }

    @ViewChild('instructionContent') instructionContent: ElementRef<HTMLDivElement>;
    @ViewChild('editDialog') editDialog: EditInstructionsDialog;

    currentUser: IUserPropertiesDto;
    ngUnsubscribe: Subject<void> = new Subject();

    instructionPages: IInstructionPageDto[];
    currentCard: number = null;
    currentTitle: string;

    ngOnInit() {
        this.initialize();
    }

    ngAfterViewInit() {
        if (!!this.instructionPages) {
            this.instructionContent.nativeElement.innerHTML = this.instructionPages[0].markupContent;
        }
    }

    private initialize() {
        this.authService.$userState
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(userState => this.currentUser = this.authService.userSettings);

        this.getInstructionPages();
    }

    openCard(cardNumber: number) {
        this.currentCard = cardNumber;
        this.currentTitle = this.instructionPages.find(x=>x.orderNumber==cardNumber).title;

        if (!this.instructionPages.find(x=>x.orderNumber==cardNumber).markupContent) {
            this.getInstructionPageContent();
            return;
        }
        this.instructionContent.nativeElement.innerHTML = this.instructionPages.find(x=>x.orderNumber==cardNumber).markupContent;
    }

    private getInstructionPages() {
        this.pageLoader.showPageLoader();
        this.instructionsCotroller.GetAllInstructionPages()
            .subscribe(
                result => {
                    if (!!result && !!result.length) {
                        this.instructionPages = result;
                        if(!this.currentCard)
                            this.currentCard = this.instructionPages.slice().sort((a, b) => a.orderNumber - b.orderNumber)[0].orderNumber;
                        this.openCard(this.currentCard);
                    }
                    this.pageLoader.hidePageLoader();
                },
                error => {
                    this.notificationService.error('Something went wrong');
                    this.pageLoader.hidePageLoader();
                },
                () => {
                    this.pageLoader.hidePageLoader();

                }
            );
    }

    private getInstructionPageContent() {
        this.pageLoader.showPageLoader();
        const id = this.instructionPages.find(x=>x.orderNumber==this.currentCard).id;
        this.instructionsCotroller.GetInstructionPageContent(id)
            .subscribe(
                result => {
                    if (!!result) {
                        let anyResult = result as any;
                        if (anyResult.Errors == null) {
                            const blob = this.byteArrayToFileBlob(result as any);
                            const reader = new FileReader();

                            reader.readAsText(blob);
                            reader.onloadend = () => {
                                this.instructionContent.nativeElement.innerHTML = reader.result as string;
                                this.instructionPages.find(x=>x.orderNumber==this.currentCard).markupContent = reader.result as string;
                            }
                        }
                    }
                    this.pageLoader.hidePageLoader();
                },
                error => {
                    this.notificationService.error('Something went wrong');
                    this.pageLoader.hidePageLoader();
                });
    }

    onPageEditClick(page: IInstructionPageDto) {
        this.editDialog.openDialog(page, 'edit');
    }
    
    onPageRemoveClick(page: IInstructionPageDto) {
        this.editDialog.openDialog(page, 'remove');
    }

    onPageAddClick(){
        const ascending = (a, b) => (a.orderNumber > b.orderNumber ? 1 : -1);
        let maxOrderNumber: number = this.instructionPages.sort(ascending)[this.instructionPages.length - 1].orderNumber;
        this.editDialog.openDialog(null, 'add', maxOrderNumber);
    }
    
    onPageEdited(event: any) {
        if(event.action == 'add'){
            this.getInstructionPages();
        }
        const ascending = (a, b) => (a.orderNumber > b.orderNumber ? 1 : -1);
        let updated = this.instructionPages.filter(x => x.id !== event.instructionPage.id);
        if(event.action != 'remove'){
            updated.push(event.instructionPage);
        }
        updated.sort(ascending);

        this.instructionPages = [...updated];
        if(event.action != 'remove'){
            this.openCard(event.instructionPage.orderNumber);
        }
        else{
            this.openCard(this.instructionPages[0].orderNumber);
        }
    }
    

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    // misc

    private byteArrayToFileBlob(base64: string) {
        let binaryString = window.atob(base64);
        const length = binaryString.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }


        return new Blob([bytes], { type: 'text/plain;charset=utf-8' });
    }
}