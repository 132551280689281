<app-seleton-loader *ngIf="loading" cWidthPc="100" cHeightPx="400"></app-seleton-loader>
<div #container [ngStyle]="style" [class]="styleClass" [ngClass]="{
        'p-table p-component': true,
        'p-table-responsive': responsive,
        'p-table-resizable': resizableColumns,
        'p-table-resizable-fit':
            resizableColumns && columnResizeMode === 'fit',
        'p-table-hoverable-rows': rowHover || selectionMode,
        'p-table-auto-layout': autoLayout
    }">
    <div class="p-table-loading p-component-overlay" *ngIf="loading"></div>
    <div class="p-table-loading-content" *ngIf="loading">
        <i [class]="'p-table-loading-icon pi-spin ' + loadingIcon"></i>
    </div>
    <div *ngIf="captionTemplate" class="p-table-caption p-component-header">
        <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
    </div>
    <div *ngIf="
            !isCurentPageInputEnabled &&
            (paginatorPosition == 'top' || paginatorPosition == 'both')
        ">
        <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalCount" [pageLinkSize]="pageLinks" styleClass="p-paginator-top" [alwaysShow]="alwaysShowPaginator" (onPageChange)="onPageChange($event)" [rowsPerPageOptions]="rowsPerPageOptions" *ngIf="
                paginator &&
                (paginatorPosition === 'top' || paginatorPosition == 'both')
            " [templateLeft]="paginatorLeftTemplate" [templateRight]="paginatorRightTemplate" [dropdownAppendTo]="paginatorDropdownAppendTo"></p-paginator>
    </div>
    <div *ngIf="
            isCurentPageInputEnabled &&
            (paginatorPosition == 'top' || paginatorPosition == 'both') &&
            paginator
        " class="paginator-top padding-right-15 padding-left-5">
        <rc-paginator #paginator [rows]="rows" [first]="first" [totalCount]="totalCount" [rowsPerPageOptions]="rowsPerPageOptions" [templateRight]="templateRight" (onPageChange)="onPageChange($event)">
            <ng-template #templateRight>
                <div class="footer-text pull-right margin-top-5-md margin-top-5-sm color-gray-bold-primary">
                    {{ footerText }}
                </div>
            </ng-template>
        </rc-paginator>
    </div>
    <div class="p-table-wrapper" *ngIf="!scrollable">
        <table #table class="width-full" [ngClass]="tableStyleClass" [ngStyle]="tableStyle">
            <ng-container *ngTemplateOutlet="
                    colGroupTemplate;
                    context: { $implicit: columns }
                "></ng-container>
            <thead class="p-table-thead">
                <ng-container *ngIf="!options || !options.isSimpleView">
                    <ng-container *ngTemplateOutlet="
                            headerTemplate;
                            context: { $implicit: columns }
                        "></ng-container>
                </ng-container>
                <ng-container *ngIf="!!options && options.isSimpleView">
                    <td *ngFor="let column of _options.columns; let l = count" [ngStyle]="{ 'width.%': 100 / l }">
                        {{ column.label }}
                    </td>
                </ng-container>
            </thead>
            <tfoot *ngIf="footerTemplate" class="p-table-tfoot">
                <ng-container *ngTemplateOutlet="
                        footerTemplate;
                        context: { $implicit: columns }
                    "></ng-container>
            </tfoot>
            <tbody *ngIf="!options || !_options.isSimpleView" class="p-table-tbody" [GridBody]="_columns" [GridBodyTemplate]="bodyTemplate"></tbody>
            <tbody *ngIf="!!options && _options.isSimpleView" class="p-table-tbody" [GridBody]="_columns" [innerHTML]="_tableBody | elementSanitizer: 'html'"></tbody>
        </table>
    </div>
    <div class="p-table-scrollable-wrapper" *ngIf="scrollable">
        <div class="p-table-scrollable-view p-table-frozen-view" *ngIf="frozenColumns || frozenBodyTemplate" [ScrollableView]="frozenColumns" [frozen]="true" [ngStyle]="{ width: frozenWidth }" [scrollHeight]="scrollHeight"></div>

        <div class="p-table-scrollable-view" [ScrollableView]="columns" [frozen]="false" [scrollHeight]="scrollHeight" [ngStyle]="{
                left: frozenWidth,
                width: 'calc(100% - ' + frozenWidth + ')'
            }"></div>
    </div>
    <div *ngIf="
            !isCurentPageInputEnabled &&
            (paginatorPosition == 'bottom' || paginatorPosition == 'both')
        ">
        <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalCount" [pageLinkSize]="pageLinks" styleClass="p-paginator-bottom" [alwaysShow]="alwaysShowPaginator" (onPageChange)="onPageChange($event)" [rowsPerPageOptions]="rowsPerPageOptions" *ngIf="
                paginator &&
                (paginatorPosition === 'bottom' || paginatorPosition == 'both')
            " [templateLeft]="paginatorLeftTemplate" [templateRight]="paginatorRightTemplate" [dropdownAppendTo]="paginatorDropdownAppendTo"></p-paginator>
    </div>
    <div *ngIf="
            isCurentPageInputEnabled &&
            (paginatorPosition == 'bottom' || paginatorPosition == 'both') &&
            paginator
        " class="paginator-bottom padding-top-15 padding-right-15 padding-bottom-15 padding-left-5">
        <rc-paginator #paginator [rows]="rows" [first]="first" [totalCount]="totalCount" [rowsPerPageOptions]="rowsPerPageOptions" [templateRight]="templateRight" (onPageChange)="onPageChange($event)">
            <ng-template #templateRight>
                <div class="footer-text pull-right margin-top-5-md margin-top-5-sm color-gray-bold-primary">
                    {{ footerText }}
                </div>
            </ng-template>
        </rc-paginator>
    </div>
    <div *ngIf="summaryTemplate" class="p-table-summary p-component-header">
        <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
    </div>
    <div #resizeHelper class="p-column-resizer-helper p-highlight" style="display:none" *ngIf="resizableColumns"></div>
    <span #reorderIndicatorUp class="pi pi-arrow-down p-table-reorder-indicator-up" style="display:none" *ngIf="reorderableColumns"></span>
    <span #reorderIndicatorDown class="pi pi-arrow-up p-table-reorder-indicator-down" style="display:none" *ngIf="reorderableColumns"></span>
</div>