import { Directive, OnInit, OnDestroy, Input, HostListener } from '@angular/core';

import { Subscription } from 'rxjs';
import { GridComponent } from 'apps/core/src/app/modules/shared-core/components/grid/rc-grid.component';
import { DomHandler } from 'primeng/dom';


@Directive({
    selector: '[pSortableColumn]',
    host: {
        '[class.p-sortable-column]': 'isEnabled()',
        '[class.p-state-highlight]': 'sorted',
        '[attr.tabindex]': 'isEnabled() ? "0" : null'
    }
})
export class SortableColumnDirective implements OnInit, OnDestroy {

    @Input("pSortableColumn") field: string;

    @Input() pSortableColumnDisabled: boolean;

    sorted: boolean;

    subscription: Subscription;

    constructor(public dt: GridComponent) {
        if (this.isEnabled()) {
            this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
                this.updateSortState();
            });
        }
    }

    ngOnInit() {
        if (this.isEnabled()) {
            this.updateSortState();
        }
    }

    updateSortState() {
        this.sorted = this.dt.isSorted(this.field);
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        let targetNode = event.target;
        if ((DomHandler.hasClass(targetNode, 'p-sortable-column') || DomHandler.hasClass(targetNode, 'p-sortable-column-icon')) && this.isEnabled()) {
            this.updateSortState();
            this.dt.sort({
                originalEvent: event,
                field: this.field
            });

            DomHandler.clearSelection();
        }
    }

    @HostListener('keydown.enter', ['$event'])
    onEnterKey(event: MouseEvent) {
        this.onClick(event);
    }

    isEnabled() {
        return this.pSortableColumnDisabled !== true;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
