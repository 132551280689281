import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResetPasswordModel } from '@Workspace/_generated/interfaces';
import { eStandardCampaginChannel } from '@Workspace/_generated/enums';

@Injectable()
export class CampaignService {

    private gridId = new BehaviorSubject<number>(1);
    private fileTypeId = new BehaviorSubject<number>(0);
    private searchParameter = new BehaviorSubject<string>("");
    private userSearchParameter = new BehaviorSubject<string>("");
    private isRefresh = new BehaviorSubject<boolean>(true);
    private isGrid = new BehaviorSubject<boolean>(true);
    private isWarningMessageVisible = new BehaviorSubject<boolean>(true);



    setGridIdId(id: number) {
        this.gridId.next(id);
    }

    getGridIdId(): Observable<number> {
        return this.gridId.asObservable();
    }

    setIsWarningMessageVisible(isWarningMessageVisible: boolean) {
        this.isWarningMessageVisible.next(isWarningMessageVisible);
    }

    getIsWarningMessageVisible(): Observable<boolean> {
        return this.isWarningMessageVisible.asObservable();
    }

    setFileTypeId(id: number) {
        this.fileTypeId.next(id);
    }

    getFileTypeId(): Observable<number> {
        return this.fileTypeId.asObservable();
    }
    setRefresh(isRefresh: boolean) {
        this.isRefresh.next(isRefresh);
    }

    getRefresh(): Observable<boolean> {
        return this.isRefresh.asObservable();
    }

    setSearchParameter(searchParameter: string) {
        this.searchParameter.next(searchParameter);
    }

    getSearchPareameter(): Observable<string> {
        return this.searchParameter.asObservable();
    }


    setUserSearchParameter(searchParameter: string) {
        this.userSearchParameter.next(searchParameter);
    }

    getUserSearchPareameter(): Observable<string> {
        return this.userSearchParameter.asObservable();
    }

    setIsGrid(isGrid: boolean) {
        this.isGrid.next(isGrid);
    }

    getIsGrid(): Observable<boolean> {
        return this.isGrid.asObservable();
    }

    public isSocial(type: eStandardCampaginChannel) {
        return [
            eStandardCampaginChannel.Facebook_Static_News_Feed,
            eStandardCampaginChannel.Facebook_Dynamic_News_Feed,
            eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video,
            eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel
        ].includes(type);
    }
    public isOther(type: eStandardCampaginChannel) {
        return [
            eStandardCampaginChannel.Spotify_Audio,
            eStandardCampaginChannel.Spotify_Video,
            eStandardCampaginChannel.Native_Video,
            eStandardCampaginChannel.Native_Image,
            eStandardCampaginChannel.In_Stream_Video,
            eStandardCampaginChannel.Google_AdWords,


        ].includes(type);
    }
    public isDisplayBanner(type: eStandardCampaginChannel) {
        return [
            eStandardCampaginChannel.Standard_Display_Banner,
            eStandardCampaginChannel.Abandoned_Cart_Display_Banner,
            eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner
        ].includes(type);
    }

}
