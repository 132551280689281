import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'campaign-review-page',
    templateUrl: 'campaign-review-page.html'
})
export class CampaignReviewPage implements OnInit {
    constructor(
    ) { }

    ngOnInit() {
        
    }
}