<div>

    <div class="d-flex flex-row pb-3  bd-highlight">
        <div *ngIf="IsSocial" class="p-2 bd-highlight">
            <span class="btn btn-light" pButton label="Social" class="p-button-primary">
            </span>
        </div>
        <div *ngIf="IsDisplayBanner" class="p-2 bd-highlight">
            <span class="btn btn-light" pButton label="Display Banner" class="p-button-secondary">
            </span>
        </div>

        <div *ngIf="IsOtherBanner" class="p-2 bd-highlight">
            <span class="btn btn-info" pButton label="Other" class="p-button-info">
            </span>
        </div>


    </div>
</div>

<div *ngFor="let item of groupedCreatives | keyvalue; let i = index">

    <div class="py-2 mt-3 ad-card-wrapper" *ngIf="item.value[0].creativeTypeId !== 6">

        <p-accordion expandIcon=" pi pi-fw pi-chevron-right" collapseIcon="pi pi-fw pi-chevron-down" [activeIndex]="0">
            <p-accordionTab>
                <p-header class="pr-5">{{item.value[0].displayName}}</p-header>
                <p-header class="float-right">
                    <div class="quantity px-2 py-1">
                        {{ item.value[0].quantity }}
                    </div>
                </p-header>
                <div *ngFor="let data of item.value;let i = index" class="card-body border-bottom">
                    <div class="flight-wrapper ml-3 py-3 pl-3">
                        Flight {{i + 1}}
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="row mt-3 mx-3">
                                <div class="col-4">
                                    <label class="font-weight-bold required">
                                        Creative Set Name
                                    </label>
                                </div>

                                <div class="col-8">
                                    <input [(ngModel)]="data.name" pInputText class="w-100" />
                                </div>
                            </div>
                            <div class="row mt-3 mx-3">
                                <div class="col-4">
                                    <label class="font-weight-bold required">
                                        Flight Dates
                                    </label>
                                </div>


                                <div class="col-8 d-flex justify-content-between align-items-center">
                                    <p-calendar [minDate]="minFlightDate" (onSelect)="onFlightStartDateChange($event, data)" [maxDate]="maxFlightDate" [(ngModel)]="data.flightStartLocalDate"></p-calendar>
                                    <span class="mx-2">-</span>
                                    <p-calendar [minDate]="data.flightStartLocalDate" (onSelect)="onFlightEndDateChange($event, data)" [maxDate]="maxFlightDate" [(ngModel)]="data.flightEndLocalDate"></p-calendar>
                                </div>

                            </div>

                            <div *ngIf="item.value[0].creativeTypeId == 11" class="row mt-3 mx-3">

                                <div class="col-4">
                                    <label class="font-weight-bold">
                                        Video Type
                                    </label>
                                </div>

                                <div class="col-8">
                                    <p-dropdown autoWidth="false" [style]="{'width': '100%'}" [(ngModel)]="item.value[0].detailJson.videoType" [options]="videoTypes"></p-dropdown>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>

<div class="py-2 mt-3 ad-card-wrapper" *ngIf="IsDisplayBanner">
    <p-accordion>
        <p-accordionTab [selected]="true">
            <p-header class="pr-5 add-banner">Add Banner Dimensions</p-header>
            <div class="d-flex mx-3 justify-content-between border-top mt-2">
                <div class="pt-3" *ngFor="let item of displayBannerOptions">
                    <p-checkbox name="group2" value="{{item}}" label="{{item}}" [(ngModel)]="selectedCategories"></p-checkbox>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>


<div class="mt-4 mb-5">
    <div>
        <div class="row">
            <div class="col-12 d-flex flex-wrap">
                <div class="mr-auto p-2">
                    <button type="button" (click)="onPreviousStep()" class="btn btn-light flat-btn">
                        GO BACK</button>
                </div>
                <div class="p-2">
                    <button type="button" (click)="Cancel()" class="btn btn-light flat-btn">CANCEL</button>
                </div>
                <div class="p-2">
                    <button type="button" [disabled]="selectedCreatives.length==0" (click)="saveCampaign()" class="btn btn-light flat-btn">SAVE & EXIT TO HOME</button>
                </div>
                <div class="p-2">
                    <button type="button" [disabled]="selectedCreatives.length==0" (click)="onReview($event)" pButton class="p-button-primary" label="NEXT"></button>
                </div>
            </div>
        </div>
    </div>
</div>