<!-- <div class="login-container col-3">
    <div class="row col-12">
        <h6>Log in to create a campaign or upload your files</h6>
    </div>
    <hr>
    <div class="row" *ngIf="!userLoggedIn">
        <label class="col-12">Username</label>
        <div class="col-12">
            <input pInputText [(ngModel)]="loginModel.userName" />
        </div>
    </div>

    <div class="row mt-3" *ngIf="!userLoggedIn">
        <label class="col-12">Password</label>
        <div class="col-12">
            <input pInputText [(ngModel)]="loginModel.password" type="password" />
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <p-button *ngIf="!userLoggedIn" (click)="onSubmit()" label="Login"></p-button>
        </div>

    </div>
</div> -->

<div class="login-container px-lg-0 px-3" *ngIf="!(settings.IsLive && settings.IsLearfield) && !signupIFrameState">
    <div class="container">
        <div class="row">
            <div *ngIf="route == 'sign-in'" class="col-lg-4 col-12 login-wrapper mb-3 pr-lg-5 pr-0">
                <div class="card card-body login-card mb-5">
                    <div class="row">
                        <div class="col-12">
                            <h3>Log In</h3>
                            <p class="sub-heading-text">
                                Create campaigns or upload files
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Username or Email Address</label>
                            <input pInputText [(ngModel)]="loginModel.userName" id="username" class="form-control login-input" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Password</label>
                            <input pInputText [(ngModel)]="loginModel.password" type="password" id="password" class="form-control login-input" />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <button *ngIf="!userLoggedIn" (click)="onSubmit()" class="btn btn-block login-btn">
                                Log In
                            </button>
                        </div>
                    </div>

                    <a routerLink="/recover-password" class="forgot-password-link">Forgot Password?</a>

                    <!-- <div class="row mt-3">
                        <div class="col-12">
                            <button
                                *ngIf="!userLoggedIn"
                                (click)="onSubmitAd()"
                                class="btn btn-block login-btn"
                            >
                                Log In with AD
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>

            <div *ngIf="route == 'recover-password'" class="col-lg-4 col-12 login-wrapper mb-3 pr-lg-5 pr-0">
                <div class="card card-body login-card mb-5">
                    <div class="row">
                        <div class="col-12">
                            <h3>Password Reset</h3>
                            <p class="sub-heading-text">
                                Enter your email address registered to your username. We'll send you an email with a link to reset your password.
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Email Address</label>
                            <input pInputText [(ngModel)]="emailForRecover" (focusout)="validateEmail()" id="email" type="email" class="form-control login-input" />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <button *ngIf="!userLoggedIn" (click)="onRecover()" [disabled]="!isValidEmail" class="btn btn-block login-btn">
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="route == 'reset-password'" class="col-lg-4 col-12 login-wrapper mb-3 pr-lg-5 pr-0">
                <div class="card card-body login-card mb-5">
                    <div class="row">
                        <div class="col-12">
                            <h3>Reset Password</h3>
                            <p class="sub-heading-text">
                                Submit form to reset password
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Username or Email Address</label>
                            <input pInputText [(ngModel)]="changePasswordUsername" id="username" class="form-control login-input" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>New Password</label>
                            <input pInputText [(ngModel)]="resetPasswordModel.newPassword" (focusout)="passwordValidation()" type="password" id="newPassword" class="form-control login-input" />
                        </div>
                        <div class="px-0" style="color: red; margin-top: 5px;" *ngIf="!isValidPassword">
                            Password must contain at least one uppercase character, one special character, one digit character and be 8 or more characters length.
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Confirm Password</label>
                            <input pInputText [(ngModel)]="resetPasswordModel.confirmPassword" type="password" id="confirmPassword" class="form-control login-input" />
                        </div>
                        <div class="px-0" style="color: red; margin-top: 5px;" *ngIf="(resetPasswordModel.newPassword != resetPasswordModel.confirmPassword) && resetPasswordModel.confirmPassword != ''">
                            Confrirmed Password must match New Password.
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <button *ngIf="!userLoggedIn" [disabled]="!isValidResetPassword()" (click)="onReset()" class="btn btn-block login-btn">
                                Change Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-12 pl-lg-5 pl-0 pb-lg-0 pb-3">
                <div class="{{route == 'sign-in' ? 'mt-lg-5 mt-3' : 'logo-margin'}}">
                    <img class="logo" src="/assets/Hub Logo@2x.png" />
                    <div *ngIf="route == 'sign-in'" class="mt-5">
                        <h2>Welcome</h2>
                        <p>
                            The Mogo Campaign HUB provides our partners access
                            to key campaign information and tools for creating
                            new campaigns and securely uploading creative
                            assets.
                        </p>
                        <!-- <p>
                            Not a Mogo partner?
                            <a routerLink="/sign-up" class="create-account-link">Click here to request an account today</a>
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="redirect-wrapper" *ngIf="settings.IsLive && settings.IsLearfield && !signupIFrameState">
    <div class="container">
        <div class="row align-items-center h-100vh">
            <div class="col-12 d-flex flex-wrap justify-content-center">
                <div>
                    <img class="fan-logo" src="/assets/Fan365_LIMGC_2C@2x.png" alt="" />
                    <p class="fan-subtext">
                        Please wait, you will be redirected to your
                        organizations sign in page.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>