import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { LookUps } from '@Workspace/common';
import { IAccountDto, IUserAccountDto, ITeamDto, IUserTeamDto } from '@Workspace/_generated/interfaces';
import { eUserRole, eEntityStatus } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { TeamController } from '@Workspace/_generated/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'team-edit-dialog',
    templateUrl: 'team-edit-dialog.component.html',
    styleUrls: ["./team-edit-dialog.component.scss"]
})
export class TeamEditDialogComponent implements OnInit, OnDestroy {
    constructor(
        public fb: UntypedFormBuilder,
        private authService: AuthService,
        private teamController: TeamController

    ) { }

    @Input() team: ITeamDto;
    @Input() displayDialog: boolean = false;
    @Input() dialogText: string = "";
    @Output() teamDialogDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSubmit: EventEmitter<object> = new EventEmitter<object>();

    formGroup: UntypedFormGroup;
    teamUsers: SelectItem[] = [];
    ngUnsubscribe: Subject<void> = new Subject();

    currentUser = this.authService.userSettings;

    ngOnInit() {
        this.formInit();
        this.initLists();

    }

    initLists() {
        this.teamUsers = this.team.teamUsers.map(x => { return { value: x.userId, label: x.userName } })
    }

    formInit(): void {
        this.formGroup = this.fb.group({
            name: [""],
            type: [""],
            industry: [""],

        });

        if (!!this.team)
            this.patchFormValue();
    }

    getTeamValues() {
        this.team.name = this.formGroup.value.name;

        if (!!this.team.teamUsers)
            this.team.teamUsers.forEach(x => {
                if (!this.teamUsers.some(a => a.value == x.userId))
                    x.entityStatus = eEntityStatus.Deleted;
            });
        else
            this.team.teamUsers = [];

        this.teamUsers.forEach(x => {
            var ut = {} as IUserTeamDto;
            ut.userId = x.value;
            ut.teamId = this.team.id;
            ut.entityStatus = eEntityStatus.Active;
            if (!this.team.teamUsers.some(a => a.userId == ut.userId))
                this.team.teamUsers.push(ut);
        });
    }

    patchFormValue() {
        this.formGroup.patchValue({
            name: this.team.name
        });
    }

    cancel() {
        this.displayDialog = false;
        this.teamDialogDisplay.emit(this.displayDialog);
    }

    submit() {
        this.getTeamValues();
        this.teamController.CreateTeam(this.team).subscribe(x => {
            this.displayDialog = false;
            this.teamDialogDisplay.emit(this.displayDialog);
        });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}