import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { SelectItem, MessageService, ConfirmationService, MenuItem } from 'primeng/api';
import { CampaignController, AccountController } from '@Workspace/_generated/services';
import { eCampaignStatus, eStandardCampaginChannel, eChannelType, eUserRole, eVideoType, eGender, eAgeTo, eAgeFromName, eIncomeFromName, eIncomeToName, eAgeToName, eStandardCampaginChannelName, eEntityStatus, eInventoryTypeOAS, eAccountType } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute } from '@angular/router';
import { CartItemService } from '../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IDetailJson, IDemographicsDto, IGeographicTargetingJson, ILandingPageJson, IEnumDataAttribute, ICampaignImpressionFlightDto, IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { componentFadeIn } from '../../route-animations';
import { Subscription } from 'rxjs/internal/Subscription';
import { FileUploadService } from '../../services/file-upload.service';
import { Functions, LookUps, Constants } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { PageLoaderService, ProgressBarService } from '@Workspace/services';
import { CampaignService } from '../../services/campaign.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { CreativeSwapsDialog } from '../../../shared-core/components/creative-swaps-dialog/creative-swaps-dialog';
import { ImpressionFlightsDialog } from '../../../shared-core/components/impression-flights-dialog/impression-flights-dialog';
import { CampaignDatesAccordion } from '../../../shared-core/components/campaign-dates-accordion/campaign-dates-accordion.component';
import * as moment from 'moment';
import { InventoryAddOnAccordion } from '../../../shared-core/components/invertory-add-on-accordion/inventory-add-on-accordion.component';
import { CampaignCardDetails } from '../../../shared-core/components/campaign-card-details/campaign-card-details.component';

@Component({
    selector: 'campaign-upload-page-fan365',
    templateUrl: './campaign-upload-page-fan365.html',
    styleUrls: ['./campaign-upload-page-fan365.scss'],
    animations: [componentFadeIn]
})
export class CampaignUploadPageFan365 implements OnInit, OnDestroy {
    
    constructor(
        public campaignController: CampaignController,
        public accountController: AccountController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
        private pageLoader: PageLoaderService,
        private progressBar: ProgressBarService,
        private campaignService: CampaignService,
        private sanitizer: DomSanitizer,
        private cdref: ChangeDetectorRef,
        private dateTime: DatePipe,
        private confirmationService: ConfirmationService,
        @Inject(DOCUMENT) private document: Document
    ) { }

    @Output() onStepTwo: EventEmitter<any> = new EventEmitter();
    @Input() isApprove: boolean;
    @ViewChild('creativeSwaps') creativeSwaps: CreativeSwapsDialog;
    @ViewChild('impressionFlights') impressionFlights: ImpressionFlightsDialog;
    @ViewChild('initCalendar') initCalendar: CampaignDatesAccordion;
    @ViewChild('inventoryAddOns') inventoryAddOns: InventoryAddOnAccordion;
    @ViewChild('cardItemDetails') cardItemDetails: CampaignCardDetails;


    constants = Constants;
    isCampaignActivatedOutsideOfHub: boolean = false;
    creativeAssetsDto: ICreativeAssetDto = {} as ICreativeAssetDto;
    campaignId: string;
    isUploadOk: boolean = true;
    isLandingUrlOk: boolean = true;
    isAssetUploaded: boolean = false;
    baseCampaign: any;
    showCards = false;
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isStartDateChanged: boolean = false;
    isEndDateChanged: boolean = false;
    initialValueStartDate: any = null;
    initialValueEndDate: any = null;
    initialValueCreativeTypeId: number = null;

    ngUnsubscribe = new Subject();
    activeIndex: number = 0;
    creativesSelected: string;
    inventoryItems: string[];
    userType: any;

    subscription: Subscription;
    browserRefresh = false;

    enumAccountType = eAccountType;
    enumUserRole = eUserRole;
    enumInventoryTypeOAS = eInventoryTypeOAS;

    facebookStaticCreative: ICreativeDto[];
    facebookDynamicCreative: ICreativeDto[];
    facebookStaticVideoCreative: ICreativeDto[];
    facebookDynamicVideoCreative: ICreativeDto[];
    facebookDynamicCarouselCreative: ICreativeDto[];
    standardDisplayCreative: ICreativeDto[];
    premiumDisplayCreative: ICreativeDto[];
    cartDisplayBannerCreative: ICreativeDto[];
    ppuDisplayBannerCreative: ICreativeDto[];
    spotifyVideoCreative: ICreativeDto[];
    spotifyAudioCreative: ICreativeDto[];
    inStreamVideoVideoCreative: ICreativeDto[];
    googleAdWorkCreative: ICreativeDto[];
    nativeVideoCreative: ICreativeDto[];
    nativeImageCreative: ICreativeDto[];
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    stories: ICreativeDto[];
    instantExperience: ICreativeDto[];
    leadGeneration: ICreativeDto[];
    connectedTvCreative: ICreativeDto[];

    oasCreative: ICreativeDto[];
    socialLinkCampaign: ICreativeDto[];
    socialContentCreationCampaign: ICreativeDto[];
    socialFlexibleCampaign: ICreativeDto[];
    socialCataboom: ICreativeDto[];
    isPreviewOpen: boolean;
    currentUser = this.authService.userSettings;
    currentIndex: number;
    reviewPage: boolean = false;
    note: string;
    canSubmit: boolean = true;
    videoTypes: SelectItem[] = [];
    videoType: eVideoType[] = [];
    isLoaded: boolean;
    demographicsValues: string[] = ["", "", ""];
    geographicsValues = ["", "", "", ""];
    enumCampaignStatus = eCampaignStatus;
    isWarningMessageVisible: boolean = true;
    isNoteValid: boolean = true;
    creativeTypeDD: SelectItem[] = [];
    tooltipDisabled: string = '';
    numberOfFlights: number[];
    previewedCreativeIsBuilt: boolean;

    campaignDates = '';
    canEditGeographics: boolean = false;
    canEditDemographics: boolean = false;
    canEditSiteVisitTracking: boolean = false;
    canEditConversionTracking: boolean = false;
    canEditInterestTargeting: boolean = false;
    editButtonShown: boolean = false;
    isPageTakeOver: boolean = false;
    isPageTakeOverPremium: boolean = false;
    canEditKeyValuePair: boolean = false;
    isVisible: boolean = false;
    public breadcrumbItemsCampaignUpload: MenuItem[];

    valueProgressBar: number = 0; 
    payloadFileSize: number = 0;
    isFilesUploaded: boolean = false;
    isUploadedSuccessfully: boolean = null;
    isSavedCampaign: boolean = false;
    uploadInterval: any;
    isImpressionDisabled: boolean = true;
    impressionToolTip: string = '';
    maxCreativeGroup: number = 1;

    ngOnInit() {
        this.isLoaded = false;

        window.addEventListener("beforeunload", (event) => {
            event.returnValue = "";
        });

        this.activatedRoute.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                this.campaignId = params.slug;
            });
        this.pageLoader.showPageLoader();


        let filter = `?$filter=Id eq ${this.campaignId}`;
        this.campaignController.GetAllCampaigns(filter).subscribe(data => {
            if (data.totalCount > 0) {
                this.baseCampaign = data.data[0];
                this.maxCreativeGroup = Math.max(...this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).map(x => x.creativeGroup)); 
                if(data.data[0].entityStatus == eEntityStatus.Transfered){

                    this.campaignController.GetTransferedCampaign(data.data[0].id).pipe(take(1)).subscribe(x =>{
                        if(x.data){
                            this.confirmationService.confirm({
                                header: 'Campaign Transfered',
                                key: 'transferedCampaign',
                                message: 'This campaign has been transfered to <strong>' + data.data[0].name + '</strong>',
                                rejectVisible: true,
                                rejectLabel: 'Close',
                                acceptLabel: 'Open Target Campaign',
                                accept: async () => {
                                    this.confirmationService.close();
                                    setTimeout(() => {
                                        if (x.data[0].account.isLearfieldCampaign || x.data[0].account.isOASCampaign || x.data[0].account.isSocialCampaign || x.data[0].account.isContentCampaign)
                                            this.router.navigate(['/campaign/' + x.data[0].id + '/campaign-upload-fan365']);
                                        else if (x.data[0].account.isCampaignCreation && this.currentUser.permissions.isClient)
                                            this.router.navigate(['/campaign/' + x.data[0].id]);
                                        else
                                            this.router.navigate(['/campaign/' + x.data[0].id + '/campaign-upload']);
                                    }, 500);
                                },
                                reject: () => {
                                    setTimeout(() => {
                                        this.router.navigate(['/mogo']);
                                    }, 500);
                                }
                            });
                        }
                    });
                }

                else{
                    if(this.baseCampaign.campaignType == eAccountType.FAN365 || this.baseCampaign.campaignType == eAccountType.Social  || this.baseCampaign.campaignType == eAccountType.Content || this.baseCampaign.campaignType == eAccountType.OAS )
                    {
                        this.isVisible = true;
                    }

                    if (this.baseCampaign.campaignStatusId == eCampaignStatus.Activated_outside_of_Hub) {
    
                        this.isCampaignActivatedOutsideOfHub = true;
                    }
                    if (!this.baseCampaign.landingPageJson) {
                        this.baseCampaign.landingPageJson = {} as ILandingPageJson;
                    }
                    this.note = this.baseCampaign.note;

                    this.baseCampaign.creatives = this.baseCampaign.creatives.sort((x, y) => { return x.creativeGroup - y.creativeGroup });

                    this.initialValueStartDate = this.baseCampaign.startDate;
                    this.initialValueEndDate = this.baseCampaign.endDate;
                    this.initialValueCreativeTypeId = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeTypeId;
    
                    this.isApprove = (this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Uploaded || this.baseCampaign.statusId == eCampaignStatus.Assets_Uploaded 
                                            || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined || this.baseCampaign.statusId == eCampaignStatus.Assets_Declined 
                                            || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Approved || this.baseCampaign.statusId == eCampaignStatus.Assets_Approved
                                            || this.baseCampaign.campaignStatusId == eCampaignStatus.Creative_Swaps || this.baseCampaign.statusId == eCampaignStatus.Creative_Swaps  
                                            || (this.baseCampaign.statusId == eCampaignStatus.Sent_to_API_Build && this.baseCampaign.account.automaticApiBuild)) 
                                    && this.currentUser.userRole != eUserRole.Client;
    
                    this.isPageTakeOver = !!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItemOAS != null && (x.inventoryItemOAS.pageTakeover));
                    this.isPageTakeOverPremium = !!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItemOAS != null && (x.inventoryItemOAS.premiumPageTakeover));
    
                    this.editButtonShown = this.reviewPage || this.baseCampaign.campaignStatusId == eCampaignStatus.Activated_outside_of_Hub;
    
                    this.canEditDemographics = (!!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.demoTargeting :
                        (x.inventoryItemNew != null ? x.inventoryItemNew.demoTargeting :
                            (x.inventoryItemOAS != null ? x.inventoryItemOAS.demoTargeting : false))))
                        || this.baseCampaign.isDemographicTargetingUnlocked;
    
                    this.canEditGeographics = (!!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.customGeo :
                            (x.inventoryItemNew != null ? x.inventoryItemNew.customGeo :
                                (x.inventoryItemOAS != null ? x.inventoryItemOAS.geoTargeting : false))))
                            || this.baseCampaign.isGeographicTargetingUnlocked;
    
                    this.canEditSiteVisitTracking = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.siteVisitTracking : (x.inventoryItemNew != null ? x.inventoryItemNew.siteVisitTracking : false)) || this.baseCampaign.isSiteVisitTrackingUnlocked;
                    
                    this.canEditConversionTracking = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.conversionTracking : (x.inventoryItemNew != null ? x.inventoryItemNew.conversionTracking : false)) || this.baseCampaign.isConversionTrackingUnlocked;
                
                    this.canEditInterestTargeting = this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.interestTargeting : (x.inventoryItemNew != null ? x.inventoryItemNew.interestTargeting : false)) || this.baseCampaign.isInterestSegmentsUnlocked;
    
                    this.canEditKeyValuePair = this.baseCampaign.campaignType == eAccountType.OAS && (this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending);     
                    
                    if (this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).length > 0) {
                        this.pageLoader.showPageLoader();
                        this.campaignController.GetFilesFromAzure(this.baseCampaign)
                            .subscribe(
                                result => {
                                    this.baseCampaign = result;
                                    this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
                                    this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach(x => {
                                        if (x.detailJson == null) {
                                            x.detailJson = {} as IDetailJson;
                                            x.detailJson.headline = '';
                                            x.detailJson.bodyText = [];
                                            x.detailJson.bodyText.push('');
                                            x.detailJson.linkDescription = '';
                                            x.detailJson.landingPageUrl = '';
                                            x.detailJson.facebookPageUrl = '';
                                            // x.detailJson.destinationUrl = '';
                                            x.detailJson.campaignTitle = '';
                                            x.detailJson.advertiser = '';
                                            x.detailJson.companyName = '';
                                            x.detailJson.shortTitle = '';
                                            x.detailJson.longTitle = '';
                                            x.detailJson.shortDescription = '';
                                            x.detailJson.longDescription = '';
                                            x.detailJson.callToAction = '';
                                            x.detailJson.headline2 = '';
                                            x.detailJson.description = '';
                                            x.detailJson.path1 = '';
                                            x.detailJson.path2 = '';
                                            x.detailJson.displayUrl = '';
                                            x.detailJson.adGroup = '';
                                            x.detailJson.postText = '';
                                            x.detailJson.privacyPolicy = '';
                                            x.detailJson.customDisclaimer = '';
                                            x.detailJson.copyOption1 = [];
                                            x.detailJson.copyOption1.push('');
                                            x.detailJson.copyOption2 = '';
                                            x.detailJson.buttonText = '';
                                            x.detailJson.destinationUrl = '';
    
                                        }
                                    });
    
                                    this.splitCreative();
                                    
                                    this.cardItemService.updatedDataSelection(this.baseCampaign);
                                    this.showCards = true;
                                    this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach(x => {
                                        if (!!x.creativeAssets)
                                            x.creativeAssets.forEach(ca => {
                                                if (!!ca.file && !!ca.file.urlWithToken)
                                                    this.creativeAssetsForPreview.push(ca);
                                            })
                                    })
    
                                    this.isLoaded = true;
                                    this.pageLoader.hidePageLoader();
                                }
                            );
                    }
                    else {
                        this.isLoaded = true;
                    }

                    this.isApprove = (this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Uploaded || this.baseCampaign.statusId == eCampaignStatus.Assets_Uploaded 
                        || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined || this.baseCampaign.statusId == eCampaignStatus.Assets_Declined 
                        || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Approved || this.baseCampaign.statusId == eCampaignStatus.Assets_Approved
                        || this.baseCampaign.campaignStatusId == eCampaignStatus.Creative_Swaps || this.baseCampaign.statusId == eCampaignStatus.Creative_Swaps  
                        || (this.baseCampaign.statusId == eCampaignStatus.Sent_to_API_Build && this.baseCampaign.account.automaticApiBuild)) 
                        && this.currentUser.userRole != eUserRole.Client;

                        this.breadcrumbItemsCampaignUpload = [
                            {
                                label: this.baseCampaign.account.name,
                                icon: 'pi pi-home',
                                command: () => {
                                    localStorage.removeItem('sponsor');
                                    this.router.navigate(['mogo']);
                                }
                            },
                            {
                                label: this.baseCampaign.sponsor,
                                command: () => {
                                    this.router.navigate(['/fan365/' + this.baseCampaign.sponsor.replace('/','%2F')]);
                                }
                            },
                            {
                                label: this.baseCampaign.name
                            }
                        ];
                }
                if (this.baseCampaign.propertyEmails != null)
                    {
                        let filterContact = `?$filter=Email eq '${this.baseCampaign.propertyEmails}'`;
                        this.accountController.GetUsers(filterContact).subscribe(x => {
                            if(x.data[0])
                                this.baseCampaign.contact = x.data[0];
                            else
                            {
                                this.baseCampaign.contact = '';
                                setTimeout(() => {this.messageService.add({ severity: 'error', summary: 'Info Message', detail: 'Your property contact is removed please set another one.' }), 5000})
                            }
                        });
                    }
                    else
                        this.baseCampaign.propertyEmails=null;
            }
                
            this.pageLoader.hidePageLoader();
        },
        error => {
            this.pageLoader.hidePageLoader();
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
        });

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.isUploadOk = true;
                this.isLandingUrlOk = true;
                this.isAssetUploaded = false;
                if (!!!data.creatives) return;
                data.creatives.forEach(x => {
                    x.creativeAssets.forEach(ca => {
                        if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                            this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                        if (ca.isLandingPageExist != null || ca.isLandingPageExist != undefined){
                            if(this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin && (this.baseCampaign?.campaignType == eAccountType.FAN365 || data?.campaignType == eAccountType.FAN365))
                                this.isLandingUrlOk = (this.isLandingUrlOk && ca.isLandingPageExist);
                            else
                                this.isLandingUrlOk = (this.isLandingUrlOk && ca.isLandingPageExist) || ca.imageUrl==null || ca.imageUrl=="";
                        }
                        if ((ca.fileId != null && ca.fileId != Constants.GuidEmpty && ca.fileId != undefined) || (ca.file != null && ca.file != undefined) || (ca.fileWrapper != null && ca.fileWrapper != undefined))
                            this.isAssetUploaded = true;
                    });    
                });
            });

        this.initLists();
    }

    splitCreative()
    {
        //let maxNumberOfFlights = this.baseCampaign.creatives.sort((x, y) => { return y.creativeGroup - x.creativeGroup })[0].creativeGroup;
        let maxNumberOfFlights = Math.max(...this.baseCampaign.creatives.filter(x=> x.entityStatus == eEntityStatus.Active).map(x => x.creativeGroup));
            //this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].creativeGroup;
        this.numberOfFlights = Array(maxNumberOfFlights).fill(maxNumberOfFlights).map((x,i)=>i+1);

        this.standardDisplayCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.premiumDisplayCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.cartDisplayBannerCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.ppuDisplayBannerCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });

        this.facebookStaticCreative = this.baseCampaign.creatives != null ? this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) : null;
        this.facebookDynamicCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.facebookStaticVideoCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed_Video).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.facebookDynamicVideoCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.facebookDynamicCarouselCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.stories = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Stories).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.leadGeneration = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Lead_Generation).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.instantExperience = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Instant_Experience).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });

        this.spotifyVideoCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Spotify_Video).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.spotifyAudioCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Spotify_Audio).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.inStreamVideoVideoCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.In_Stream_Video).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.connectedTvCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.ConnectedTv).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });

        this.googleAdWorkCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Google_AdWords).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.nativeVideoCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Native_Video).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });
        this.nativeImageCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Native_Image).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });

        this.oasCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Display && (x.creativeTypeId == eStandardCampaginChannel.OAS_Display || x.creativeTypeId == eStandardCampaginChannel.OAS_Video)).sort((x, y) => { return <any>new Date(x.flightStartDate) - <any>new Date(y.flightStartDate) });

        this.socialLinkCampaign = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social_campaign && x.creativeTypeId == eStandardCampaginChannel.Paid_support);
        this.socialFlexibleCampaign = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social_campaign && (x.creativeTypeId == eStandardCampaginChannel.Flexible_Campaign || x.primaryCreativeTypeId == eStandardCampaginChannel.Flexible_Campaign));
        this.socialCataboom = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social_campaign && x.creativeTypeId == eStandardCampaginChannel.Digital_Game);
        this.socialContentCreationCampaign = this.baseCampaign.campaignType == eAccountType.Social && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social_campaign &&
            (x.creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation || x.creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation_Premium
                || x.creativeTypeId == eStandardCampaginChannel.Content
                || x.creativeTypeId == eStandardCampaginChannel.Snap_AR || x.creativeTypeId == eStandardCampaginChannel.Digital_Game));
    }
    checkIsDisabled() {
        let isDetailPopulate = false;
        this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach(x => {
            if (!!x.detailJson && x.detailJson != null &&
                ((!!x.detailJson.headline && x.detailJson.headline != '') ||
                    (!!x.detailJson.bodyText && !!x.detailJson.bodyText[0] && x.detailJson.bodyText[0] != '') ||
                    (!!x.detailJson.linkDescription && x.detailJson.linkDescription != '') ||
                    (!!x.detailJson.landingPageUrl && x.detailJson.landingPageUrl != '') ||
                    (!!x.detailJson.facebookPageUrl && x.detailJson.facebookPageUrl != '') ||
                    (!!x.detailJson.campaignTitle && x.detailJson.campaignTitle != '') ||
                    (!!x.detailJson.advertiser && x.detailJson.advertiser != '') ||
                    (!!x.detailJson.companyName && x.detailJson.companyName != '') ||
                    (!!x.detailJson.shortTitle && x.detailJson.shortTitle != '') ||
                    (!!x.detailJson.longTitle && x.detailJson.longTitle != '') ||
                    (!!x.detailJson.shortDescription && x.detailJson.shortDescription != '') ||
                    (!!x.detailJson.longDescription && x.detailJson.longDescription != '') ||
                    (!!x.detailJson.callToAction && x.detailJson.callToAction != '') ||
                    (!!x.detailJson.headline2 && x.detailJson.headline2 != '') ||
                    (!!x.detailJson.description && x.detailJson.description != '') ||
                    (!!x.detailJson.path1 && x.detailJson.path1 != '') ||
                    (!!x.detailJson.path2 && x.detailJson.path2 != '') ||
                    (!!x.detailJson.displayUrl && x.detailJson.displayUrl != '') ||
                    (!!x.detailJson.adGroup && x.detailJson.adGroup != '') ||
                    (!!x.detailJson.postText && x.detailJson.postText != '') ||
                    (!!x.detailJson.privacyPolicy && x.detailJson.privacyPolicy != '') ||
                    (!!x.detailJson.customDisclaimer && x.detailJson.customDisclaimer != '') ||
                    (!!x.detailJson.copyOption2 && x.detailJson.copyOption2 != '') ||
                    (!!x.detailJson.buttonText && x.detailJson.buttonText != '') ||
                    (!!x.detailJson.destinationUrl && x.detailJson.destinationUrl != '') ||
                    (!!x.detailJson.copyOption1 && !!x.detailJson.copyOption1[0] && x.detailJson.copyOption1[0] != '') ||
                    (!!x.detailJson.videoType && x.detailJson.videoType != 0))) {

                isDetailPopulate = true;
            }
        });
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        let validationFan365Creatives: boolean = false;
        if(this.baseCampaign.campaignType == eAccountType.FAN365 && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x=>x.creativeGroup==1 && x.name=="Standard Display Banner") && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x=>x.creativeGroup==1 && x.name=="Premium Display Banner")){
            this.numberOfFlights.forEach(num=>{
            if(this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x=>x.creativeGroup==num && x.name=="Standard Display Banner" && !x.creativeAssets.some(ca => !!ca.file))
            && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x=>x.creativeGroup==num && x.name=="Premium Display Banner" && !x.creativeAssets.some(ca => !!ca.file)))
                validationFan365Creatives=true;
            if(this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x => x.creativeGroup==num && x.name!="Standard Display Banner" && x.name!="Premium Display Banner" && !x.creativeAssets.some(ca => !!ca.file)))
                validationFan365Creatives=true;    
            });
        }
        else if(this.baseCampaign.campaignType == eAccountType.FAN365 && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(cr => !cr.creativeAssets.some(ca => !!ca.file))){
            validationFan365Creatives=true;
        }

        var oasCompareDate = new Date();
        oasCompareDate.setHours(0, 0, 0, 0);
        let daysFromTodayOAS: number;

        if(this.baseCampaign.campaignType == eAccountType.OAS){
            if(this.baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium){
                daysFromTodayOAS = 5;
                // oasCompareDate.setDate(oasCompareDate.getDate() + daysFromTodayOAS);
                oasCompareDate = this.addBusinessDays(oasCompareDate, daysFromTodayOAS);
            }
            else{
                daysFromTodayOAS = 3;
                // oasCompareDate.setDate(oasCompareDate.getDate() + daysFromTodayOAS);
                oasCompareDate = this.addBusinessDays(oasCompareDate, daysFromTodayOAS);
            }
        }

        if ((this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignType == eAccountType.Content || this.baseCampaign.campaignType == eAccountType.OAS || this.baseCampaign.campaignType == eAccountType.FAN365) && (!this.baseCampaign.startDate || !this.baseCampaign.endDate)) {
            this.tooltipDisabled = this.baseCampaign.campaignType != eAccountType.Content ? "Please select a start and end date before proceeding." : "Please select a launch month before proceeding.";
            return true;
        }

        else if (this.baseCampaign.campaignType == eAccountType.Social && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeTypeId != eStandardCampaginChannel.Paid_support && 
            this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeTypeId != eStandardCampaginChannel.Flexible_Campaign &&  !!!this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeAssets[0].file) {
            this.tooltipDisabled = 'Please upload Sponsor Logo before proceeding.';
            return true;
        }

        else if (this.baseCampaign.campaignType == eAccountType.OAS && !!this.baseCampaign.campaignInventoryItems 
            && ((this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItemOAS != null && x.inventoryItemOAS.pageTakeover )
                    && !!!this.baseCampaign.keyValuesPairOASPageTamplateId && !!!this.baseCampaign.keyValuesPairOASSectionId) 
                || (this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItemOAS != null && x.inventoryItemOAS.premiumPageTakeover )
                        && !!!this.baseCampaign.keyValuesPairOASSectionId) 
                )){
            //&& !!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItemOAS != null && x.inventoryItemOAS.pageTakeover)){
            this.tooltipDisabled = 'Please select Page Selection.';
            return true;
        }
        else if ((this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(cr => !cr.creativeAssets.some(ca => !!ca.file))) 
                    || (this.baseCampaign.campaignType == eAccountType.FAN365 && !!this.baseCampaign.seasonMapping && this.baseCampaign.seasonMapping.startDate > '2021-06-30' 
                            && validationFan365Creatives)) {
            if(this.currentUser.userRole == eUserRole.Media_Activation && this.isCampaignActivatedOutsideOfHub){
                this.tooltipDisabled = '';
                return false;
            }
            else{
                this.tooltipDisabled = 'Please upload at least one asset per creative flight';
                return true;
            }
        }

        else if (((
            (!this.isLandingUrlOk && !this.baseCampaign.landingPageJson.url) ||
            this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(c => c.fileAdditional != null && c.file == null)) ||
            (!this.isAssetUploaded && !isDetailPopulate) 
            ||
            (this.baseCampaign.campaignStatusId == eCampaignStatus.Activated_outside_of_Hub ||
                this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Expired ||
                (this.baseCampaign.statusId == eCampaignStatus.Sent_to_API_Build && !this.baseCampaign.account.automaticApiBuild) ||
                this.baseCampaign.campaignStatusId == eCampaignStatus.Payment_Pending ||
                this.baseCampaign.campaignStatusId == eCampaignStatus.Payment_Completed
                )) || (this.baseCampaign.creatives.some(x=>x.creativeTypeId==eStandardCampaginChannel.In_Stream_Video && (x.detailJson.landingPageUrl=='' || x.detailJson.landingPageUrl==null || x.detailJson.landingPageUrl==undefined || x.detailJson.landingPageUrl.match(regex)==null) && x.creativeAssets.some(x=>x.file!=null))))
             && (!this.isCampaignActivatedOutsideOfHub && this.baseCampaign.campaignStatusId != eCampaignStatus.Activated_outside_of_Hub)
             && (this.baseCampaign.campaignType == eAccountType.FAN365)
        ) {
            this.tooltipDisabled = 'Missing Landing Page URL for uploaded asset!';
            return true;
        }

        else if(this.currentUser.permissions.isClient && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps
            && this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.startDate < oasCompareDate){
            this.tooltipDisabled = 'The campaign\'s start date must be a minimum of '+ daysFromTodayOAS +' days from today.';
            return true;
        }

        else if(this.baseCampaign.campaignType == eAccountType.Content && (!!this.baseCampaign.creatives[0].contentDetailJson && 
            (this.baseCampaign.creatives[0].contentDetailJson.numberOfImpressions == null
            || this.baseCampaign.creatives[0].contentDetailJson.sports == '' || this.baseCampaign.creatives[0].contentDetailJson.sports == null
            || this.baseCampaign.creatives[0].contentDetailJson.contentProducer == '' || this.baseCampaign.creatives[0].contentDetailJson.contentProducer == null
            || this.baseCampaign.creatives[0].contentDetailJson.included == '' || this.baseCampaign.creatives[0].contentDetailJson.included == null
            || this.baseCampaign.creatives[0].contentDetailJson.brandObjectives == '' || this.baseCampaign.creatives[0].contentDetailJson.brandObjectives == null
            || this.baseCampaign.creatives[0].contentDetailJson.description == '' || this.baseCampaign.creatives[0].contentDetailJson.description == null 
            || this.baseCampaign.creatives[0].contentDetailJson.athleticsApproved == null || this.baseCampaign.creatives[0].contentDetailJson.numberOfOrganicPosts == null))){
                this.tooltipDisabled = 'Please enter values for all required fields!';
                return true;
            }

        else if ((!this.currentUser.permissions.isClient || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps)
             && this.baseCampaign.campaignStatusId != eCampaignStatus.Activated_outside_of_Hub &&
            this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Ended &&
            this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Expired &&
            this.baseCampaign.statusId != eCampaignStatus.Sent_to_API_Build &&
            this.baseCampaign.campaignStatusId != eCampaignStatus.Payment_Completed &&
            this.baseCampaign.statusId != eCampaignStatus.API_Build_Started &&
            this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Live &&
            this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Paused &&
            this.baseCampaign.statusId != eCampaignStatus.Build_Success &&
            !this.isCampaignActivatedOutsideOfHub && this.isLandingUrlOk &&
            ((this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.startDate && this.baseCampaign.endDate)
                || (this.baseCampaign.campaignType == eAccountType.Content && this.baseCampaign.startDate && this.baseCampaign.endDate)
                || (this.baseCampaign.campaignType == eAccountType.Social && this.baseCampaign.startDate && this.baseCampaign.endDate
                    && !!this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeAssets[1] ? !!this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeAssets[1].file : true
                )
                || this.baseCampaign.campaignType == eAccountType.FAN365)) {

            this.tooltipDisabled = '';
            return false;
        }

        else {
            return false;
        }

    }

    private enumMapToArray(map: Map<number, IEnumDataAttribute>): any[] {
        let result: any[] = [];
        map.forEach(((enumDataAttr, key) => {
            if (enumDataAttr.channelId == 4 && enumDataAttr.name != "Flexible campaign")
                result.push({ label: enumDataAttr.name, value: key, channelId: enumDataAttr.channelId, quantity: 0 })
        }
        ));
        return result;
    }

    initLists() {
        this.videoTypes = LookUps.getVideoTypeLu();
        this.videoTypes.unshift({ value: 0, label: "-" });

        var socialCreativeNames = new Map<number, IEnumDataAttribute>([...eStandardCampaginChannelName]);
        this.creativeTypeDD = this.enumMapToArray(socialCreativeNames);
        this.creativeTypeDD.unshift({ label: "-", value: 0 });
    }

    setCreativeType() {
        if(this.initialValueCreativeTypeId != this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeTypeId )
            {
                this.baseCampaign.startDate = this.initialValueStartDate;
                this.baseCampaign.endDate = this.initialValueEndDate;
                this.initialValueCreativeTypeId = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeTypeId;
                this.initCalendar.ngOnInit(); 
            }

        this.socialLinkCampaign = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social_campaign && x.creativeTypeId == eStandardCampaginChannel.Paid_support);
        this.socialCataboom = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social_campaign && x.creativeTypeId == eStandardCampaginChannel.Digital_Game);
        this.socialContentCreationCampaign = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Social_campaign &&
            (x.creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation || x.creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation_Premium
                || x.creativeTypeId == eStandardCampaginChannel.Content
                || x.creativeTypeId == eStandardCampaginChannel.Snap_AR || x.creativeTypeId == eStandardCampaginChannel.Digital_Game));
    }
    checkForCreatives() {
        if (this.facebookStaticCreative || this.facebookDynamicCreative || this.facebookDynamicVideoCreative || this.facebookDynamicCarouselCreative || this.facebookStaticVideoCreative
            || this.standardDisplayCreative || this.premiumDisplayCreative || this.cartDisplayBannerCreative || this.ppuDisplayBannerCreative
            || this.spotifyVideoCreative || this.spotifyAudioCreative || this.inStreamVideoVideoCreative || this.googleAdWorkCreative
            || this.nativeVideoCreative || this.nativeImageCreative || this.stories || this.leadGeneration || this.instantExperience || this.connectedTvCreative)

            return true;
        else
            return false;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        window.removeEventListener("beforeunload", (event) => {
            event.returnValue = "";
        });
        this.progressBar.hideProgressBar();
        this.isFilesUploaded = true;
    }

    async confirmSave(){
        if(this.baseCampaign.campaignType == eAccountType.FAN365){
            let isValid = true;
            let flightNumbersFirstWithOthers: string = "";
            let flightNumbersOtherWithFirst: string = "";
            let dimensionsFirstWithOthers: string = "";
            let dimensionsOtherWithFirst: string = "";
            let firstFlightCreatives = this.baseCampaign.creatives.filter(x => x.entityStatus !=eEntityStatus.Deleted && x.creativeGroup == 1);

            this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.creativeGroup > 1).forEach(creative =>{
                firstFlightCreatives.filter(x => x.creativeTypeId == creative.creativeTypeId)[0].creativeAssets.filter(x => x.entityStatus !=eEntityStatus.Deleted && x.height != 0 && x.width != 0).forEach(fca =>{
                    let dimensionExist: boolean = false;
                    creative.creativeAssets.filter(x => x.entityStatus !=eEntityStatus.Deleted && x.height != 0 && x.width != 0).forEach(ca =>{
                        if(fca.width == ca.width && fca.height == ca.height)
                            dimensionExist = true;
                    });
                    if(!dimensionExist){
                        isValid = false;
                        let dimension: string = '[' + fca.width + "x" + fca.height + ']';
                        let flightNumber = creative.creativeGroup == 2 ? creative.creativeGroup + "nd" : creative.creativeGroup == 3 ? creative.creativeGroup + "rd" : creative.creativeGroup + "th";
                        if(flightNumbersFirstWithOthers == "" && !flightNumbersFirstWithOthers.includes(flightNumber))
                            flightNumbersFirstWithOthers += flightNumber;
                        else if(!flightNumbersFirstWithOthers.includes(flightNumber))
                            flightNumbersFirstWithOthers += ', ' + flightNumber;
                        if(dimensionsFirstWithOthers == "" && !dimensionsFirstWithOthers.includes(dimension))
                            dimensionsFirstWithOthers += dimension;
                        else if(!dimensionsFirstWithOthers.includes(dimension))
                            dimensionsFirstWithOthers += ', ' + dimension;
                    }
                });

                creative.creativeAssets.filter(x => x.entityStatus !=eEntityStatus.Deleted && x.height != 0 && x.width != 0).forEach(ca => {
                    if(!firstFlightCreatives.filter(x => x.creativeTypeId == creative.creativeTypeId)[0].creativeAssets.filter(x => x.entityStatus != eEntityStatus.Deleted && x.height != 0 && x.width != 0).some(x => x.height == ca.height && x.width == ca.width)){
                        isValid = false;
                        let dimension: string = '[' + ca.width + "x" + ca.height + ']';
                        let flightNubmer = creative.creativeGroup == 2 ? creative.creativeGroup + "nd" : creative.creativeGroup == 3 ? creative.creativeGroup + "rd" : creative.creativeGroup + "th";
                        if(flightNumbersOtherWithFirst == "" && !flightNumbersOtherWithFirst.includes(flightNubmer))
                            flightNumbersOtherWithFirst += flightNubmer;
                        else if(!flightNumbersOtherWithFirst.includes(flightNubmer))
                            flightNumbersOtherWithFirst += ', ' + flightNubmer;
                        if(dimensionsOtherWithFirst == "" && !dimensionsOtherWithFirst.includes(dimension))
                            dimensionsOtherWithFirst += dimension;
                        else if(!dimensionsOtherWithFirst.includes(dimension))
                            dimensionsOtherWithFirst += ', ' + dimension;
                    }
                })
            });
            let numberOfDimensionsFWO: number = dimensionsFirstWithOthers.split('x').length - 1;
            let numberOfDimensionsOWF: number = dimensionsOtherWithFirst.split('x').length - 1;
            let compareFirstWithOthersMessage = flightNumbersFirstWithOthers != '' ? 'There ' + (numberOfDimensionsFWO == 1 ? 'is ' : 'are ') + 'no corresponding ' + dimensionsFirstWithOthers + (numberOfDimensionsFWO == 1 ? ' asset on the ' : ' assets on the ') + flightNumbersFirstWithOthers + ' flight compared to the 1st.</br></br>' : '';
            let compareOtherWithFirstMessage = flightNumbersOtherWithFirst != '' ? 'There ' + (numberOfDimensionsOWF == 1 ? 'is ' : 'are ') + dimensionsOtherWithFirst + (numberOfDimensionsOWF == 1 ? ' asset on the ' : ' assets on the ') + flightNumbersOtherWithFirst + ' flight that ' + (numberOfDimensionsOWF == 1 ? 'does ' : 'do ') + 'not exist on the 1st.</br></br>': '';
    
            let isAccepted: boolean = null;
            if(!isValid || (compareFirstWithOthersMessage == '' && compareOtherWithFirstMessage == '')){
                isAccepted = await this.showDimensionsConfirmationDialog(compareFirstWithOthersMessage,compareOtherWithFirstMessage);
            }
            if(isAccepted != false && this.inventoryAddOns != null && this.inventoryAddOns.canEditImpressions
                && this.inventoryAddOns.addOns?.length > 0 && this.inventoryAddOns.selectedAddOns?.length == 0){
                isAccepted = isAccepted != null ? isAccepted && await this.showAddOnConfirmationDialog() : await this.showAddOnConfirmationDialog();
            }

            if(isAccepted != false)
                this.save();
        }
        else if(this.baseCampaign.campaignType == eAccountType.Content){
            if(this.inventoryAddOns != null && this.inventoryAddOns.canEditImpressions && this.inventoryAddOns.addOns?.length > 0 && this.inventoryAddOns.selectedAddOns?.length == 0 
                && this.baseCampaign.creatives[0].contentDetailJson?.included?.includes("Paid Media")){
                this.confirmationService.confirm({
                    header: 'Confirm Save Campaign',
                    key: 'saveFan365Campaign',
                    message: 'This deal sheet contains an impression add-on that has yet to be assigned to a campaign in HUB. Please use the add-on drop-down in the activation form if you need increase the guaranteed impressions based on the deal sheet inventory.',
                    rejectVisible: true,
                    rejectLabel: 'Close',
                    acceptLabel: 'Save',
                    accept: async () => {
                        this.save();
                        this.confirmationService.close();
                        
                    },
                    reject: () => {
                        this.confirmationService.close();
                    }
                });  
            }
            else
                this.save();
        }
        else
            this.save();

    }

    save() {
        this.pageLoader.showPageLoader();
        this.isWarningMessageVisible = false;
        let redirectToSuccess = false;

        if(this.flightsDatesInvalid()){
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved. Flights dates are not valid.' });
            return;
        }

        if(this.impressionFlightsDatesInvalid()){
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved. Impression dates are not valid.' });
            return;
        }

        if (this.isApprove) {
            if (!this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(a => a.isValid == false || (!!a.file && a.isValid == null))) && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(a => a.isValid == true && !!a.file))
            && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).every(x => x.creativeAssets.some(a => a.entityStatus !=eEntityStatus.Deleted && !!a.file)))
            {
                if(this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Live || this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Paused)
                    this.baseCampaign.statusId = eCampaignStatus.Assets_Approved;
                else
                    this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Approved;
            }
                
            else if (this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(a => a.isValid == false)))
            {
                if(this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Live || this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Paused)
                        this.baseCampaign.statusId = eCampaignStatus.Assets_Declined;
                else
                    this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Declined;
            }
        }

        this.baseCampaign.note = this.note;

        if ((this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined || this.baseCampaign.campaignStatusId == eCampaignStatus.Creative_Swaps) && this.reviewPage == true) 
        {
            this.baseCampaign.isEdit = false;
            this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Uploaded;
            if (this.currentUser.permissions.isClient)
                redirectToSuccess = true;
        }

        else if (((this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Live || this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Paused)
             && this.baseCampaign.statusId == eCampaignStatus.Creative_Swaps) && this.reviewPage == true) 
        {
            this.baseCampaign.statusId = eCampaignStatus.Assets_Uploaded;
            if (this.currentUser.permissions.isClient)
                redirectToSuccess = true;
        }

        if (this.isStartDateChanged) {
            if (!!this.baseCampaign.startDate) {
                this.baseCampaign.startDate = new Date(this.baseCampaign.startDate);
                Functions.dateTimeZoneConversion(this.baseCampaign.startDate);
            }
        }
        if (this.isEndDateChanged) {
            if (!!this.baseCampaign.endDate) {
                this.baseCampaign.endDate = new Date(this.baseCampaign.endDate);
                Functions.dateTimeZoneConversion(this.baseCampaign.endDate);
            }
        }

        if (!this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(a => !!a.file)) && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].creativeTypeId != eStandardCampaginChannel.Paid_support && this.baseCampaign.campaignType != eAccountType.Content) {
            this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Pending;
        }

        if (!!this.baseCampaign.landingPageJson.url) {
            this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach(x => {
                if (x.creativeAssets.some(ca => ca.file)) {
                    x.detailJson.landingPageUrl = this.baseCampaign.landingPageJson.url;
                }
                else {
                    x.detailJson.landingPageUrl = "";
                }

                x.creativeAssets.forEach(ca => {
                    if (!!ca.file) {
                        ca.imageUrl = this.baseCampaign.landingPageJson.url;
                        if (!!ca.detailJson) {
                            ca.detailJson.landingPageUrl = this.baseCampaign.landingPageJson.url;
                        }
                    }
                    else {
                        ca.imageUrl = "";
                        if (!!ca.detailJson) {
                            ca.detailJson.landingPageUrl = "";
                        }
                    }
                })
            });
        }

        this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach(c => {
            c.creativeAssets.forEach(ca => {
                if (!!ca.file) {
                    ca.file.urlWithToken = null;
                }
            });
        });

        if (this.isCampaignActivatedOutsideOfHub) {
            this.baseCampaign.campaignStatusId = eCampaignStatus.Activated_outside_of_Hub;
        }

        if (!this.isCampaignActivatedOutsideOfHub && this.baseCampaign.campaignStatusId == eCampaignStatus.Activated_outside_of_Hub) {
            this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Pending;
        }


        this.campaignController
            .SaveCampaign(this.baseCampaign)
            .subscribe(data => {
                if(Functions.isDatesEqual(new Date(data.startDate),new Date(this.baseCampaign.startDate)) && Functions.isDatesEqual(new Date(data.endDate),new Date(this.baseCampaign.endDate)) 
                    && data.note == this.baseCampaign.note && data.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).length == this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).length){
                        this.isSavedCampaign = true;
                }
                else{
                    this.isSavedCampaign = false;
                }

                this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).forEach(x => {
                    let newAssets = x.creativeAssets.filter(nAssets => nAssets.id == Constants.GuidEmpty);
                    if(newAssets.length>0)
                    {
                        data.creatives.filter(d => d.id == x.id || (x.id == Constants.GuidEmpty && d.creativeGroup == x.creativeGroup && d.creativeTypeId == x.creativeTypeId)).forEach(d => {
                            if (d.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel) 
                            {
                                let addedAssets = d.creativeAssets.filter(c => !x.creativeAssets.some(a => a.id == c.id) && c.entityStatus != eEntityStatus.Deleted);
                                newAssets.forEach(na => {
                                    let existing = addedAssets.find(e => JSON.stringify(e.detailJson).toLowerCase() == JSON.stringify(na.detailJson).toLowerCase() && !e.isChanged);
                                    if (!!existing) {
                                        existing.isChanged = true;
                                        na.id = existing.id;
                                        na.creativeId = existing.creativeId;
                                    }
                                });
                            }
                            else if (d.creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation ||
                                d.creativeTypeId == eStandardCampaginChannel.Social_Lead_Generation_Premium ||
                                d.creativeTypeId == eStandardCampaginChannel.Digital_Game ||
                                d.creativeTypeId == eStandardCampaginChannel.Content ||
                                d.creativeTypeId == eStandardCampaginChannel.Snap_AR) 
                                {
                                    let addedAssets = d.creativeAssets.filter(c => !x.creativeAssets.some(a => a.id == c.id) && c.entityStatus != eEntityStatus.Deleted);
                                    newAssets.forEach(na => {
                                        let existing = addedAssets.find(e => e.fileName == na.fileName && e.fileType == na.fileType && e.fileSize == na.fileSize && !e.isChanged);
                                        if (!!existing) {
                                            existing.isChanged = true;
                                            na.id = existing.id;
                                            na.creativeId = existing.creativeId;
                                        }
                                    });
                                }
                            else {
                                let addedAssets = d.creativeAssets.filter(c => !x.creativeAssets.some(a => a.id == c.id) && c.entityStatus != eEntityStatus.Deleted);
                                newAssets.forEach(na => {
                                    let existing = addedAssets.find(e => e.imageUrl == na.imageUrl && e.fileName == na.fileName && e.fileSize == na.fileSize 
                                        && e.fileType == na.fileType && e.width==na.width && e.height == na.height && !e.isChanged);
                                    if (!!existing) {
                                        existing.isChanged = true;
                                        na.id = existing.id;
                                        na.creativeId = existing.creativeId;
                                    }
                                });
                            }
                        });
                    }
                });

                if (this.isApprove && !this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(ca => ca.isChanged)) && !this.currentUser.permissions.isClient) {
                    this.pageLoader.hidePageLoader();
                    var gridId = this.baseCampaign.campaignType == eAccountType.FAN365 ? 1 : (this.baseCampaign.campaignType == eAccountType.OAS ? 4 : 5);
                    this.campaignService.setGridIdId(gridId);

                    if(this.isSavedCampaign){
                        this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                    }
                    else
                    {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                    }
                    setTimeout(() => this.router.navigate(['/mogo']), 1000);
                }
                else if (redirectToSuccess && !this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(ca => ca.isChanged))) {
                    this.pageLoader.hidePageLoader();
                    if(this.isSavedCampaign){
                        this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' });
                    }
                    else
                    {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                    }
                    setTimeout(() => this.router.navigate(['/campaign/' + this.baseCampaign.id + '/campaign-success-card']), 1000);
                }
                else {
                    const blobToFile = (blob: Blob, fileName: string): File => {
                        let file: any = blob;
                        file.lastModifiedDate = Date.now();
                        file.name = fileName;
                        return <File>file;
                    }
                    let payload = { uploads: [] } as IUploadPayloadModelDto;
                    if(this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).some(x => x.creativeAssets.some(ca => ca.isChanged)))
                    {
                        this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).forEach(creative => {
                            creative.creativeAssets.filter(x=>x.entityStatus != eEntityStatus.Deleted).forEach(asset => {
                                if (this.reviewPage || !this.currentUser.permissions.isClient)
                                    asset.isFromReviewPage = true;
                                if (asset.fileWrapper) {
                                    let blob = new Blob([asset.fileWrapper]);
                                    let file = blobToFile(blob, asset.fileName);
                                    let upload = { detailsJson: JSON.stringify(asset), campaignId: this.baseCampaign.id, fileTypeId: asset.file.fileTypeId, fileExtension: asset.fileType, fileName: asset.id, pathName: `${this.baseCampaign.id}/${creative.id}`, file: file } as IUpload;
                                    payload.uploads.push(upload);
                                }
                                if (asset.fileWrapperAdditional) {
                                    let blob = new Blob([asset.fileWrapperAdditional]);
                                    let file = blobToFile(blob, asset.fileName);
                                    let upload = { detailsJson: JSON.stringify(asset), campaignId: this.baseCampaign.id, fileTypeId: asset.fileAdditional.fileTypeId, fileExtension: asset.fileAdditional.fileType, fileName: asset.id, pathName: `${this.baseCampaign.id}/${creative.id}`, file: file } as IUpload;
                                    payload.uploads.push(upload);
                                }
                                if (asset.isDeleted){
                                    let upload = { detailsJson: JSON.stringify(asset), campaignId: this.baseCampaign.id, fileName: asset.id, pathName: `${this.baseCampaign.id}/${creative.id}`} as IUpload;
                                    payload.uploads.push(upload);
                                }
                            });
                        });
                    }

                    else if(this.reviewPage){
                        let upload = { campaignId: this.baseCampaign.id } as IUpload;
                        payload.uploads.push(upload);
                    }

                    payload.uploads.forEach(x => {
                        this.payloadFileSize += !!x.file ? x.file.size : 0;
                    });

                    var counter = 0;
                    var uploadSpeed = 187500;

                      if ( this.payloadFileSize / uploadSpeed > 0 && this.payloadFileSize / uploadSpeed < 25 ) 
                      {
                          this.uploadInterval = setInterval(() => {
                              if (this.isFilesUploaded) {
                                this.progressBar.showProgressBar(100);
                              } 
                              else {
                                  counter = counter + 10;
                                  this.valueProgressBar = counter;
                                  this.progressBar.showProgressBar(
                                      this.valueProgressBar
                                  );
                                  if (counter >= 90) 
                                  {
                                      counter = 89;
                                  }
                              }
                          }, ((this.payloadFileSize / uploadSpeed) * 1000 * 10) / 100);
                      } 
                      else if (this.payloadFileSize / uploadSpeed >= 25) {
                        this.uploadInterval = setInterval(() => {
                              if (this.isFilesUploaded) 
                              {
                                  this.progressBar.showProgressBar(100);
                              } 
                              else {
                                  this.valueProgressBar = counter++;
                                  this.progressBar.showProgressBar( this.valueProgressBar );
                                  if (counter >= 100)
                                   {
                                      counter = 99;
                                  }
                              }
                          }, ((this.payloadFileSize / uploadSpeed) * 1000) / 100);
                      }

                    if (payload.uploads.length > 0 || this.reviewPage) {
                        this.pageLoader.showPageLoader();
                        this.fileUploadService.UploadCreativeAssets(payload, this.reviewPage).subscribe(
                            (res: any) => {
                                if (!!res && res.ok) {
                                    this.isFilesUploaded = true;
                                    setTimeout(() => {
                                        this.progressBar.hideProgressBar();
                                        clearInterval(this.uploadInterval);
                                        this.valueProgressBar = 0;
                                        this.pageLoader.hidePageLoader();
                                        this.isFilesUploaded = false;


                                    this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).forEach(creative => {
                                        creative.creativeAssets.forEach(x => {    
                                            if(x.fileName != null || (x.file != null && x.file.fileName != null)){
                                                this.isUploadedSuccessfully = false;
                                                    res.body.uploads.forEach(element => {
                                                        var asset = JSON.parse(element.detailsJson);
                                                        if(asset!=null && asset.FileName.split('.')[0] == x.id 
                                                            && (asset.SizeInBytes == x.file.sizeInBytes || asset.SizeInBytes == x.fileSize)
                                                            && (asset.Description == x.file.description || asset.Description == x.fileName)){
                                                            this.isUploadedSuccessfully = true;
                                                        }
                                                    });
                                                }
                                        });
                                    });
                                    let numberOfDeletedAssets: number = 0;
                                    this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).forEach(x => {numberOfDeletedAssets += x.creativeAssets.filter(y=>y.isDeleted).length;});
                                    this.isUploadedSuccessfully = this.isUploadedSuccessfully != null ? this.isUploadedSuccessfully && numberOfDeletedAssets == res.body.uploads.filter(z=> z.campaignId == Constants.GuidEmpty).length
                                        : numberOfDeletedAssets == res.body.uploads.filter(z=> z.campaignId == Constants.GuidEmpty).length;

                                    if(!this.isUploadedSuccessfully){
                                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something wrong with uploading assets.' });
                                    }


                                    if(this.baseCampaign.campaignType == eAccountType.OAS && !!this.baseCampaign.campaignInventoryItems && this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItemOAS != null && x.inventoryItemOAS.premiumPageTakeover)){
                                        this.campaignController.SendPremiumPageTakeOverEmail(this.baseCampaign.id).subscribe(
                                            res => {
                                            },
                                            err => {
                                            }
                                        )
                                    }
                                    
                                    this.pageLoader.hidePageLoader();
                                    if (this.reviewPage && this.currentUser.permissions.isClient) {
                                        if(this.isSavedCampaign){
                                            this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' });
                                        }
                                        else{
                                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                                        }
                                        setTimeout(() => this.router.navigate(['/campaign/' + this.baseCampaign.id + '/campaign-success-card']), 2000);
                                       
                                    }
                                    else {
                                        if(this.isSavedCampaign){
                                            this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                                        }
                                        else{
                                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                                        }
                                        setTimeout(() => this.router.navigate(['/mogo']), 2000);
                                        
                                    }

                                    }, 2000);
                                }
                            },
                            err => {
                                this.pageLoader.hidePageLoader();
                                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something wrong with uploading assets.' });

                                return '';
                            }
                        );
                    }
                    else {
                        this.pageLoader.hidePageLoader();
                        if(this.isSavedCampaign){
                            this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                        }
                        else{
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                        }
                        setTimeout(() => this.router.navigate(['/mogo']), 2000);
                    }
                }
            }, error => {
                this.pageLoader.hidePageLoader();    
                for (const property in error.error.Errors) {
                    error.error.Errors[property].forEach(e => {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: e });
                    });
                }
            });
    }
    
    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        //console.log('Back button pressed');
        return event.returnValue = 'Your changes will not be saved';
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isWarningMessageVisible;//window.confirm('Do you really want to cancel?');
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
    }

    @HostListener('window:onunload')
    canCloseWindow(): Observable<boolean> | boolean {
        return;//window.confirm('Do you really want to cancel?');
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
    }

    openPreview(event) {
        this.previewedCreativeIsBuilt = this.baseCampaign.creatives.find(c => c.creativeAssets.some(ca => ca.id == event.id) == true)?.isBuilt;
        this.creativeAssetsForPreview = [];
        if (!this.creativeAssetsForPreview || !this.creativeAssetsForPreview.some(x => x.id == event.id)) {
            this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach(c => c.creativeAssets.forEach(ca => {
                if (!!ca.file && !this.creativeAssetsForPreview.some(a => a.file == ca.file)) {
                    if (!ca.file.urlWithToken)
                        ca.file.urlWithToken = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(ca.fileWrapper));
                    this.creativeAssetsForPreview.push(ca);
                }
            }));

        }
        this.currentIndex = this.creativeAssetsForPreview.findIndex(x => x.id == event.id);
        this.isPreviewOpen = true;
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }

    setGeographicsCardText(event: IGeographicTargetingJson) {
        if (event == null) return;
        this.geographicsValues[0] = !!event.cities && event.cities.length > 0 ? event.cities.map(x => x.name).join(";") : "";
        this.geographicsValues[1] = !!event.dmaCodes && event.dmaCodes.length > 0 ? event.dmaCodes.map(x => x.name).join(";") : "";
        this.geographicsValues[2] = !!event.states && event.states.length > 0 ? event.states.map(x => x.name).join(";") : "";
        this.geographicsValues[3] = !!event.zipCodes && event.zipCodes.length > 0 ? event.zipCodes.map(x => x.name).join(";") : "";
    }

    setDemographicsCardText(event: IDemographicsDto) {
        if (!!event && !!event.genderList) { // && event.genderList != 0
            const genderStr: string[] = [];
            let i = 0;
            let gender: number;
            while (eGender[(gender = 1 << i++)]) {
                if (event.genderList & gender) {
                    genderStr.push(eGender[gender]);
                }
            }
            this.demographicsValues[0] = genderStr.join(";");
        } else {
            this.demographicsValues[0] = "";
        }

        if (!!event && !!event.ageRange && event.ageRange.ageFrom != 0 && event.ageRange.ageTo != 0) {
            if (event.ageRange.ageTo == eAgeTo.Plus) {
                this.demographicsValues[1] = eAgeFromName.get(event.ageRange.ageFrom).name + " " + eAgeToName.get(event.ageRange.ageTo).name;
            }

            else {
                this.demographicsValues[1] = eAgeFromName.get(event.ageRange.ageFrom).name + ' - ' + eAgeToName.get(event.ageRange.ageTo).name;
            }
        } else {
            this.demographicsValues[1] = "";
        }
        if (!!event && event.incomeRange != null && event.incomeRange.from != 0 && event.incomeRange.to != 0) {
            this.demographicsValues[2] = eIncomeFromName.get(event.incomeRange.from).name + ' - ' + eIncomeToName.get(event.incomeRange.to).name;
        } else {
            this.demographicsValues[2] = "";
        }
    }

    next() {
        if(new Date(this.baseCampaign.endDate).getMonth() + 1 != 6 && this.baseCampaign.campaignType == eAccountType.OAS && !this.isPageTakeOver && !this.isPageTakeOverPremium){
            this.confirmationService.confirm({
                header: 'Short Campaign Warning',
                key: 'shortCampaign',
                message: 'The campaign you are submitting will deliver all impressions by ' + this.dateTime.transform(this.baseCampaign.endDate, this.constants.DEFAULT_FORMAT_DATE_US) + '. Please ensure this is the last day you want impressions to deliver and not the end of the first flight of creative. <br> <br> <strong>Creative flighting: </strong> If you are attempting to flight creative please put the start and end date of the campaign (not the first flight of creative) and use the + <strong> Add Creative Flight </strong> button to update creative flighting.',
                rejectVisible: true,
                rejectLabel: 'Cancel',
                acceptLabel: 'Continue',
                accept: async () => {
                    this.confirmationService.close();
    
                    this.setDemographicsCardText(this.baseCampaign.demographics);
                    this.setGeographicsCardText(this.baseCampaign.geographicTargetingJson);
    
                    this.reviewPage = true;
    
                    document.querySelector(".p-scrollpanel-content").scrollTop = 0;
                },
                reject: () => {
                    this.confirmationService.close();
                }
            });
    
        }
        else{
            this.setDemographicsCardText(this.baseCampaign.demographics);
            this.setGeographicsCardText(this.baseCampaign.geographicTargetingJson);

            this.reviewPage = true;

            document.querySelector(".p-scrollpanel-content").scrollTop = 0;
        }
    }

    back() {
        this.reviewPage = false;
    }

    cancel() {
        this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Now, you will be redirected to Home Page.' });
        setTimeout(
            () => {
                var gridId = this.baseCampaign.campaignType == eAccountType.FAN365 ? 1 : (this.baseCampaign.campaignType == eAccountType.OAS ? 4 : 5);
                this.campaignService.setGridIdId(gridId);
                this.router.navigate(['mogo']),
                    1000
            }
        );
    }

    checkisDemographicVisible() {
        return this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.demoTargeting :
            (x.inventoryItemNew != null ? x.inventoryItemNew.demoTargeting :
                (x.inventoryItemOAS != null ? x.inventoryItemOAS.demoTargeting : false)))
            && !!this.baseCampaign.demographics;

    }
    checkisAdditionalTargetingVisible() {
        return this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.interestTargeting :
            (x.inventoryItemNew != null ? x.inventoryItemNew.interestTargeting : false));
    }
    checkIsSiteVisitTrackingVisible() {
        return this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.siteVisitTracking :
            (x.inventoryItemNew != null ? x.inventoryItemNew.siteVisitTracking : false));

    }

    checkIsConversionTrackingVisible() {
        return this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.conversionTracking :
            (x.inventoryItemNew != null ? x.inventoryItemNew.conversionTracking : false));
    }

    validateNotes() {
        var monthsToCheck = /(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)/g;
        var dateFormatsToCheck1 = /\d{1,2}[/.-]\d{1,2}[/.-]\d{2,4}\s([A-Z]{2,5})\s([A-Z]{2,5})\s([A-Z]{2,5})/;
        var dateFormatsToCheck2 = /\d{2,4}[/.-]\d{1,2}[/.-]\d{1,2}\s([A-Z]{2,5})\s([A-Z]{2,5})\s([A-Z]{2,5})/;
        var dateFormatsToCheck3 = /\d{1,2}[/.-]\d{1,2}/;

        if (!this.note && (this.note.match(dateFormatsToCheck1) || this.note.match(dateFormatsToCheck2) || this.note.match(dateFormatsToCheck3) || this.note.match(monthsToCheck)
            || this.note.toLowerCase().indexOf('campaign must start') > -1
            || this.note.toLowerCase().indexOf('campaign does not start until') > -1
            || this.note.toLowerCase().indexOf('begin the campaign') > -1
            || this.note.toLowerCase().indexOf('end campaign on') > -1)) {

            this.isNoteValid = false;
            document.querySelector(".p-scrollpanel-content").scrollTop = 265;

        } else {
            this.isNoteValid = true;
        }
    }

    addCreativeFlight() {
        if ((this.baseCampaign.campaignType == eAccountType.FAN365 || this.baseCampaign.campaignType == eAccountType.OAS) && this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).length-1].creativeGroup < 15)
        {
            let creatives = this.baseCampaign.creatives.filter(x =>  x.entityStatus == eEntityStatus.Active && x.creativeGroup == 1);
            let creativeGroupOfNewCreative = Math.max(...this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).map(x => x.creativeGroup)) + 1; //this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].creativeGroup + 1;
            
            creatives.forEach(ca => {
                let newCreative = {} as ICreativeDto;
                newCreative.id = Constants.GuidEmpty;
                newCreative.channelId = ca.channelId;
                newCreative.creativeTypeId = ca.creativeTypeId;
                newCreative.name = ca.name;
                newCreative.quantity = ca.quantity;
                newCreative.creativeGroup = creativeGroupOfNewCreative;
                newCreative.entityStatus = eEntityStatus.Active;
                newCreative.isBuilt = false;

                newCreative.creativeAssets = [];
                newCreative.detailJson = {} as IDetailJson;
                    newCreative.detailJson.headline = '';
                    newCreative.detailJson.bodyText = [];
                    newCreative.detailJson.bodyText.push('');
                    newCreative.detailJson.linkDescription = '';
                    newCreative.detailJson.landingPageUrl = '';
                    newCreative.detailJson.facebookPageUrl = '';
                    newCreative.detailJson.campaignTitle = '';
                    newCreative.detailJson.advertiser = '';
                    newCreative.detailJson.companyName = '';
                    newCreative.detailJson.shortTitle = '';
                    newCreative.detailJson.longTitle = '';
                    newCreative.detailJson.shortDescription = '';
                    newCreative.detailJson.longDescription = '';
                    newCreative.detailJson.callToAction = '';
                    newCreative.detailJson.headline2 = '';
                    newCreative.detailJson.description = '';
                    newCreative.detailJson.path1 = '';
                    newCreative.detailJson.path2 = '';
                    newCreative.detailJson.displayUrl = '';
                    newCreative.detailJson.adGroup = '';
                    newCreative.detailJson.postText = '';
                    newCreative.detailJson.privacyPolicy = '';
                    newCreative.detailJson.customDisclaimer = '';
                    newCreative.detailJson.copyOption1 = [];
                    newCreative.detailJson.copyOption1.push('');
                    newCreative.detailJson.copyOption2 = '';
                    newCreative.detailJson.buttonText = '';
                    newCreative.detailJson.destinationUrl = '';

                ca.creativeAssets.forEach(x => {
                    let creativeAssetDto = {} as ICreativeAssetDto;
                    creativeAssetDto.id = Constants.GuidEmpty;
                    creativeAssetDto.width = 0;
                    creativeAssetDto.height = 0;
                    creativeAssetDto.isValid = null;
                    creativeAssetDto.creativeId = newCreative.id;
                    creativeAssetDto.fileType = null;
                    creativeAssetDto.fileSize = 0;
                    creativeAssetDto.imageUrl = null;
                    creativeAssetDto.fileName = null;
                    creativeAssetDto.url = null;
                    creativeAssetDto.isChanged = null;
                    creativeAssetDto.isUploadValid = null;
                    creativeAssetDto.isDeleted = null;
                    creativeAssetDto.entityStatus = eEntityStatus.Active;

                    newCreative.creativeAssets.push(creativeAssetDto);
                });

                this.baseCampaign.creatives.push(newCreative);
                this.baseCampaign.creatives.sort((x, y) => { return x.creativeGroup - y.creativeGroup });
            });

            this.creativeSwaps.openDialog(this.baseCampaign, this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).length-1].creativeGroup, 'New');
        }       
    }

    addImpressionFlight(){
        // this.creativeSwaps.openDialog(this.baseCampaign, this.baseCampaign.creatives[this.baseCampaign.creatives.length-1].creativeGroup, 'New');
        this.impressionFlights.openDialog();
    }

    newCreativeFlightDatesSaved(event: any) {
        if (event.isStartDateChanged && !!event.creative) {
            this.maxCreativeGroup = Math.max(...this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).map(x => x.creativeGroup)); 
            this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).filter(cr => cr.creativeGroup == event.creativeGroup).forEach(cr => {
                cr.flightEndDate = null;
                cr.flightStartDate = event.startDate;
                cr.creativeName = event.creativeName;
            });
            
            this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).filter(_ => _.creativeGroup == event.creativeGroup-1).forEach(x => {
                x.flightEndDate = new Date(event.startDate.getFullYear(), event.startDate.getMonth(), event.startDate.getDate() - 1);
                Functions.dateTimeZoneConversion(x.flightEndDate); 
            });
            
            if (event.creativeGroup == 1)
                this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).filter(_ => _.creativeGroup == event.creativeGroup).forEach(cr => {
                    cr.flightStartDate = !!this.baseCampaign.startDate && !!this.baseCampaign.startDate < this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active)[0].flightEndDate
                    ? new Date(this.baseCampaign.startDate) : null;
                });

            this.splitCreative();

            this.initCalendar.ngOnInit();

            if (!!this.baseCampaign.statusId || this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Live
                || this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Paused)
                this.baseCampaign.statusId = eCampaignStatus.Creative_Swaps;
            else if((this.baseCampaign.campaignType == eAccountType.FAN365 && this.baseCampaign.campaignStatusId != eCampaignStatus.Assets_Pending && this.baseCampaign.campaignStatusId != eCampaignStatus.Assets_Uploaded)
            || ((this.baseCampaign.campaignType == eAccountType.OAS || this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignType == eAccountType.Content) && this.baseCampaign.campaignStatusId != eCampaignStatus.Assets_Pending))
                this.baseCampaign.campaignStatusId = eCampaignStatus.Creative_Swaps;
        }
    }

    removeCreative(event: any) {
        this.baseCampaign = event.baseCampaign;
        this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach(x => {
            if(x.creativeGroup == event.creativeGroup){
                x.entityStatus = eEntityStatus.Deleted;
            }
        });
        this.baseCampaign.creatives = this.baseCampaign.creatives.filter(x=> !(x.entityStatus==eEntityStatus.Deleted && x.id == Constants.GuidEmpty));
        let numOfCreativesPerFlight: number = this.baseCampaign.creatives.filter(x=>x.creativeGroup == 1).length;
        let counter: number = 0;
        let crGroup: number = 1;
        this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).forEach(x => {
           if(numOfCreativesPerFlight > counter){
                x.creativeGroup = crGroup;
                counter++;
            }
            else{
                counter=1;
                crGroup++;
                x.creativeGroup = crGroup;
            }
        });
        this.maxCreativeGroup = Math.max(...this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).map(x => x.creativeGroup)); 
        this.splitCreative();
        this.initCalendar.ngOnInit();
    }

    ngAfterContentChecked(){
        this.tooltipDisabled = '';
        this.cdref.detectChanges();
    }


    campaignDatesSaved(event: any) {
        if (event.isStartDateChanged) {
            this.baseCampaign.startDate = event.startDate;
            this.isStartDateChanged = event.isStartDateChanged;
            if(this.numberOfFlights.length > 1){
                this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active && x.creativeGroup == 1).forEach( y => {
                   y.flightStartDate = event.startDate;
                   Functions.dateTimeZoneConversion(y.flightStartDate); 
                });
            }
        }
        if (event.isEndDateChanged) {
            this.baseCampaign.endDate = event.endDate;
            this.isEndDateChanged = event.isEndDateChanged;
        }

        if (this.baseCampaign.campaignType == eAccountType.OAS && this.baseCampaign.campaignImpressionFlights.length > 0) {
            if (moment(new Date(this.baseCampaign.campaignImpressionFlights[0].impressionEndDate)).isSameOrBefore(this.baseCampaign.startDate)) {
                this.baseCampaign.campaignImpressionFlights.map(e => {
                    e.impressionStartDate = '';
                    e.impressionEndDate = '';
                });
            } else {
                this.baseCampaign.campaignImpressionFlights[0].impressionStartDate = new Date(this.baseCampaign.startDate);
                this.baseCampaign.campaignImpressionFlights[this.baseCampaign.campaignImpressionFlights.length - 1].impressionEndDate = new Date(this.baseCampaign.endDate);
                Functions.dateTimeZoneConversion(this.baseCampaign.campaignImpressionFlights[0].impressionStartDate);
                Functions.dateTimeZoneConversion(this.baseCampaign.campaignImpressionFlights[this.baseCampaign.campaignImpressionFlights.length - 1].impressionEndDate);
            }
        }
    }

    editModeCampaignDates(){
        let canEditCampaignDates = (this.baseCampaign.campaignType == eAccountType.FAN365 || this.baseCampaign.campaignType == eAccountType.OAS || this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignType == eAccountType.Content) && this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending;
        
        return !((canEditCampaignDates && !this.editButtonShown && this.baseCampaign.isDataRangeUnlocked) || 
        ((this.baseCampaign.campaignType == eAccountType.OAS || this.baseCampaign.campaignType == 4 || this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignType == 5 || this.baseCampaign.campaignType == 7 || this.baseCampaign.campaignType == eAccountType.Content) 
                && ((this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending && (this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient)) 
                    || ((this.currentUser.permissions.isAdmin || this.currentUser.permissions.isEmployee || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps) 
                            && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Live && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Paused && this.baseCampaign.StatusId != eCampaignStatus.Build_Success)
        )) 
        || (this.baseCampaign.campaignType == eAccountType.FAN365 && (this.currentUser.permissions.isAdmin || this.currentUser.permissions.isEmployee || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps)
                && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Live && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Paused && this.baseCampaign.StatusId != eCampaignStatus.Build_Success)
        );
    }

    editModeCampaignDemographic(){
        let canEditCampaigDemographic = (this.baseCampaign.campaignType == eAccountType.FAN365 || this.baseCampaign.campaignType == eAccountType.OAS) && this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending;

        return !((canEditCampaigDemographic && !this.editButtonShown && this.baseCampaign.isDataRangeUnlocked) || 
        ((this.baseCampaign.campaignType == eAccountType.OAS || this.baseCampaign.campaignType == 4 || this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignType == 5 || this.baseCampaign.campaignType == eAccountType.Content || this.baseCampaign.campaignType == 7) 
                && ((this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending && (this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient)) 
                    || ((this.currentUser.permissions.isAdmin || this.currentUser.permissions.isEmployee || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps))
        )) 
        || (this.baseCampaign.campaignType == eAccountType.FAN365 && (this.currentUser.permissions.isAdmin || this.currentUser.permissions.isEmployee || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps))
        );
    }

    editModeCampaignGeographic(){
        let canEditCampaignGeographic = (this.baseCampaign.campaignType == eAccountType.FAN365 || this.baseCampaign.campaignType == eAccountType.OAS) && this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending;

        return !((canEditCampaignGeographic && !this.editButtonShown && this.baseCampaign.isDataRangeUnlocked) || 
        ((this.baseCampaign.campaignType == eAccountType.OAS || this.baseCampaign.campaignType == 4 || this.baseCampaign.campaignType == eAccountType.Social || this.baseCampaign.campaignType == 5 || this.baseCampaign.campaignType == eAccountType.Content || this.baseCampaign.campaignType == 7) 
                && ((this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending && (this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient)) 
                    || ((this.currentUser.permissions.isAdmin || this.currentUser.permissions.isEmployee || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps))
        )) 
        || (this.baseCampaign.campaignType == eAccountType.FAN365 && (this.currentUser.permissions.isAdmin || this.currentUser.permissions.isEmployee || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps))
        );
    }

    geographicChanges(event: IGeographicTargetingJson) {
        if (!!event) {
            this.baseCampaign.geographicTargetingJson = event;
        }
    }

    demographicChanges(event: IDemographicsDto) {
        if (!!event) {
            this.baseCampaign.demographics = event;
        }
    }

    additionalSettingsChanges(event){
        this.baseCampaign.isSiteVisitTrackingEnabled =
            event.isSiteVisitTrackingEnabled;
        this.baseCampaign.isConversionTrackingEnabled =
            event.isConversionTrackingEnabled;
        this.baseCampaign.isAdditionalTargetingSegmentsEnabled =
            event.isAdditionalTargetingSegmentsEnabled;
        this.isCampaignActivatedOutsideOfHub = event.isActivatedOutsideOfHub
    }

    addOnImpressionsChanges(event){
        this.baseCampaign.pssAdOnsJson = event.selectedAddOns;
        this.cardItemDetails.setupImpressions();
    }

    keyValuePairChanges(event){
        this.baseCampaign.keyValuesPairOASSectionId = event.keyValuePairOASSection.id;
        this.baseCampaign.keyValuesPairOASSection = event.keyValuePairOASSection;
        this.baseCampaign.keyValuesPairOASPageTamplateId = event.keyValuePairOASPageTemplate?.id;
        this.baseCampaign.keyValuesPairOASPageTamplate = event.keyValuePairOASPageTemplate;
    }
    adUnitChanges(event){
        this.baseCampaign.adUnitTypeSelected = event.adUnitTypeSelectedId;
    }

    saveImpressionFlights(event: ICampaignImpressionFlightDto[]){
        this.baseCampaign.campaignImpressionFlights = event;
        if(this.baseCampaign.impressionFlightStatusId == eCampaignStatus.Build_Impression_Flight_Failed || this.baseCampaign.impressionFlightStatusId == eCampaignStatus.Build_Impression_Flight_Success){
            this.baseCampaign.impressionFlightStatusId = eCampaignStatus.Notset;
        }
    }

    openImpressionFlightDialog(){
        this.impressionFlights.openDialog();
    }

    checkIsImpressionDisabled(){
        if(!this.baseCampaign.startDate || !this.baseCampaign.endDate){
            this.isImpressionDisabled=true;
            this.impressionToolTip = "Please select a start and end date before proceeding.";
            return true;
        }
        this.isImpressionDisabled=false;
        return false;
    }
    
    canEditFlight(creativeGroup: number){
        let creatives: ICreativeDto[] = this.baseCampaign.creatives.filter(x=> x.entityStatus==eEntityStatus.Active && x.creativeGroup==creativeGroup);
        let canRemove: boolean = true;

        if(this.reviewPage)
            return false;

        creatives.forEach(x=>{

            if(!Functions.checkCanChangeFlight(this.baseCampaign.campaignStatusId, this.baseCampaign.statusId, this.baseCampaign.campaignType, x.isBuilt)){
                canRemove = false;
                return;
            }
            
            // if(Functions.ifFileExist(x)){
            //     canRemove = false;
            //     return;
            // }
        });
        
        return canRemove;
    }

    openDeleteFlightDialog(creativeGroup: number){
        this.creativeSwaps.openDialog(
            this.baseCampaign, creativeGroup, 'Remove');
    }

    openEditFlightDialog(creativeGroup: number){
        this.creativeSwaps.openDialog(
            this.baseCampaign, creativeGroup, 'Edit');
    }
    
    removeCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged || event.isEndDateChanged) {
            this.removeCreative({ baseCampaign: event.baseCampaign, creativeGroup: event.creativeGroup });
        }
    }

    editCreativeFlightsDates(event: any){
        this.baseCampaign = event.baseCampaign;
        this.maxCreativeGroup = Math.max(...this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).map(x => x.creativeGroup)); 
        this.initCalendar.ngOnInit();
    }

    flightsDatesInvalid(){
        let invalid: boolean = false;
        if(this.maxCreativeGroup == 1){
            return invalid;
        }
        this.baseCampaign.creatives.filter(x=>x.entityStatus == eEntityStatus.Active).forEach(x => {
            if((x.creativeGroup == this.maxCreativeGroup && (!Functions.isDateBefore(new Date(x.flightStartDate), new Date(this.baseCampaign.endDate)))) 
            || (x.creativeGroup != this.maxCreativeGroup && (!Functions.isDateBefore(new Date(x.flightStartDate), new Date(x.flightEndDate)) || !Functions.isDateBefore(new Date(x.flightEndDate), new Date(this.baseCampaign.creatives.filter(y=>y.entityStatus == eEntityStatus.Active && y.creativeGroup == x.creativeGroup + 1)[0].flightStartDate)) 
            || !Functions.isOneDayDifference(new Date(x.flightEndDate), new Date(this.baseCampaign.creatives.filter(y=>y.entityStatus == eEntityStatus.Active && y.creativeGroup == x.creativeGroup + 1)[0].flightStartDate))))
                || !Functions.isDateBetween(new Date(x.flightStartDate), new Date(this.baseCampaign.startDate), new Date(this.baseCampaign.endDate), false) || (x.creativeGroup != this.maxCreativeGroup && !Functions.isDateBetween(new Date(x.flightEndDate), new Date(this.baseCampaign.startDate), new Date(this.baseCampaign.endDate), true))){
                    invalid = true;
                    this.tooltipDisabled = 'Creative flights dates are invalid.';
                    return;
                }
        });
        return invalid;
    }    

    impressionFlightsDatesInvalid(){
        let invalid: boolean = false;
        
        if (this.baseCampaign.campaignImpressionFlights.some(f => !f.impressionStartDate) || this.baseCampaign.campaignImpressionFlights.some(f => !f.impressionEndDate)) {
            invalid = true;
            this.tooltipDisabled = 'Impression flights dates are invalid.';
        }
        return invalid;
    }
    
    uploadWarning(message: string){
        this.confirmationService.confirm({
            header: 'Upload Warning',
            key: 'uploadWarning',
            message: message,
            rejectVisible: true,
            acceptVisible: false,
            rejectLabel: 'Close',
            reject: () => {
                this.confirmationService.close();
            }
        });  
    }

    addBusinessDays(d, n) {
        d = new Date(d.getTime());
        var day = d.getDay();
        d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
        return new Date(d);
    }

    showDimensionsConfirmationDialog(compareFirstWithOthersMessage, compareOtherWithFirstMessage) {
        return new Promise<boolean>((resolve) => {
            this.confirmationService.confirm({
                header: 'Confirm Save Campaign',
                key: 'saveFan365Campaign',
                message: compareFirstWithOthersMessage + compareOtherWithFirstMessage + 'Are you sure that you want to save this campaign?',
                rejectVisible: true,
                rejectLabel: 'Close',
                acceptLabel: 'Save',
                accept: () => {
                    resolve(true);
                    this.confirmationService.close();
                },
                reject: () => {
                    resolve(false);
                    this.confirmationService.close();
                }
            });
        });
    }

    showAddOnConfirmationDialog() {
        return new Promise<boolean>((resolve) => {
            this.confirmationService.confirm({
                header: 'Confirm Save Campaign',
                key: 'saveFan365Campaign',
                message: 'This deal sheet contains an impression add-on that has yet to be assigned to a campaign in HUB. Please use the add-on drop-down in the activation form if you need increase the guaranteed impressions based on the deal sheet inventory.',
                rejectVisible: true,
                rejectLabel: 'Close',
                acceptLabel: 'Save',
                accept: () => {
                    resolve(true);
                    this.confirmationService.close();
                },
                reject: () => {
                    resolve(false);
                    this.confirmationService.close();
                }
            });
        });
    }

}
