import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as directives from '@Workspace/directives';

@NgModule({
    declarations: [
        //Directives
        directives.FullPageLoaderDirective,
        directives.PermissionDirective,
        directives.SortableColumnDirective,
        directives.FileDropDirective,
        directives.ProgressBarDirective,
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        FormsModule
    ],
    providers: [],
    exports: [
        //Directives
        directives.FullPageLoaderDirective,
        directives.PermissionDirective,
        directives.SortableColumnDirective,
        directives.FileDropDirective,
        directives.ProgressBarDirective,
    ]
})
export class SharedModule { }
