import { Injectable, OnInit } from "@angular/core";
import { Subject, BehaviorSubject } from 'rxjs';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';

@Injectable()
export class CartItemService {

    campaign: IBaseCampaignDto = {} as IBaseCampaignDto;

    private dataSource = new BehaviorSubject<IBaseCampaignDto>(this.campaign);
    data = this.dataSource.asObservable();

    constructor() {

    }

    updatedDataSelection(data: IBaseCampaignDto) {
        this.dataSource.next(data);
    }

}