<div class="bg-white col-md-9 mx-auto p-3 border-bottom py-4">

    <h3 class="first">Campaign Report</h3>
    <h6 class="border-gray mb-0 text-muted ">View Campaign statics</h6>
    <div class="bg-secondary p-2 d-flex justify-content-between">
        <label class="text-white my-auto">2018-19/Pike St/ Multi</label>
        <label class="text-white my-auto">Oct 26,2018- Dec 13,2018</label>

    </div>
    <div class="mt-2 row bg-none d-flex flex-wrap  justify-content-between">

        <div class=" col-md-12 d-flex flex-wrap justify-content-between">

            <div class=" bg-info rounded shadow-sm col-md-10 col-md-10 d-flex align-items-stretch">

            </div>
            <div class="d-flex flex-column col-md-2 h-25">

                <div class=" align-self-start bg-white rounded shadow-sm col-md-12 p-2">



                    <label>Date Filter</label>
                    <p-calendar></p-calendar>

                </div>
                <div class="align-self-end bg-white rounded shadow-sm col-md-12 mt-3 p-2">

                    <label>Channel Filter</label>
                    <p-dropdown></p-dropdown>


                </div>
            </div>

        </div>
        <div class="  d-flex h-50 mt-2 col-md-12 flex-wrap justify-content-between">

            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"> <label>Spend</label><label class="mb-3">$10.432</label> </div>
            <div class="col-md-2 d-flex flex-column   bg-white rounded shadow-sm  p-2"><label>Site Visits</label><label class="mb-3">6,256</label></div>
            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"><label>Soft Conversions</label><label class="mb-3">1,105</label></div>
            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"><label>Conversions</label><label class="mb-3">352</label></div>
            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"><label>Revenue</label><label class="mb-3">$50.117</label></div>
        </div>
        <div class=" d-flex h-50 mt-2 flex-wrap pb-3 col-md-12 justify-content-between">

            <div class="col-md-2 d-flex flex-column   bg-white rounded shadow-sm  p-2"> <label>Impressions</label><label class="mb-3">1.203.511</label> </div>
            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"><label>Clicks</label><label class="mb-3">719</label></div>
            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"><label>Avg Pages Views</label><label class="mb-3">6.0</label></div>
            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"><label>Avg Order</label><label class="mb-3">$142</label></div>
            <div class="col-md-2 d-flex flex-column  bg-white rounded shadow-sm  p-2"><label>XD Lift(Display)</label><label class="mb-3">11%</label></div>
        </div>
    </div>
</div>
<div>
</div>