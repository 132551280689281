import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';

import { PageLoaderService, StorageService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';

import { Constants } from '@Workspace/common';
import { CampaignService } from 'apps/core/src/app/modules/mogo/services/campaign.service';

@Injectable()
export class AuthGuard  {
    constructor(
        private authService: AuthService,
        private storageService: StorageService,
        private router: Router,
        private campaignService: CampaignService
    ) { }

    private route: Route;

    public canLoad(route: Route) {
        this.route = route;
        return true;
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        if (state.url.indexOf("mogo") > -1)
            return this.canActivateMogoPage();
        if (state.url.indexOf("accounts") > -1)
            return this.canActivateAccountsPage(state);
        if (state.url.indexOf("teams") > -1)
            return this.canActivateEmployeePage(state);
        if (state.url.indexOf("campaign/") > -1)
            return this.canActivateCamipaignPage(state);
    }

    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        if (state.url.indexOf("accounts") > -1)
            return this.canActivateAccountsPage(state);
        if (state.url.indexOf("teams") > -1)
            return this.canActivateEmployeePage(state);
    }

    private canActivateMogoPage() {
        if (!this.authService.userSettings.permissions.isLearfieldClient) {
            return true;
        } else {
            this.storageService.set(
                Constants.LAST_URL_KEY,
                window.location.hash.substr(2)
            );

            this.router.navigate(['/fan365']);
            return false;
        }
    }

    private canActivateCamipaignPage(state): boolean {
        if ((this.authService.userSettings.permissions.canCreateCampaignEmployee) || (this.authService.userSettings.permissions.isClient && this.authService.userSettings.permissions.canCreateCampaignClient) || (!this.authService.userSettings.permissions.isClient && state.url.indexOf("su-campaign") > -1)) {
            return true;
        } else {
            this.storageService.set(
                Constants.LAST_URL_KEY,
                window.location.hash.substr(2)
            );

            this.router.navigate(['/mogo']);
            return false;
        }
    }


    private canActivateAccountsPage(state): boolean {
        if ((this.authService.userSettings.permissions.isClient && (state.url.indexOf("users") > -1 || this.authService.userSettings.permissions.isLearfieldClient)) || !this.authService.userSettings.permissions.isClient) {
            return true;
        } else {
            this.storageService.set(
                Constants.LAST_URL_KEY,
                window.location.hash.substr(2)
            );

            this.router.navigate(['/mogo/accounts/users']);
            return false;
        }
    }

    private canActivateEmployeePage(state): boolean {
        if (state.url.indexOf('teams/users/') > -1 || (state.url.indexOf('teams/') > -1 && state.url.indexOf('teams/users') == -1))
            this.campaignService.setIsGrid(false);
        else
            this.campaignService.setIsGrid(true);
        if (!this.authService.userSettings.permissions.isClient) {
            return true;
        } else {
            this.storageService.set(
                Constants.LAST_URL_KEY,
                window.location.hash.substr(2)
            );

            this.router.navigate(['/mogo/accounts/users']);
            return false;
        }
    }
}
