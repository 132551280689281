import { OnInit, OnDestroy, Component, Input, ViewChild } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { MessageService, ConfirmationService } from 'primeng/api';
import { IBaseCampaignDto, ICampaignBuildLinkDto, IUploadPayloadModelDto, IUpload, IFileTypeDto } from '@Workspace/_generated/interfaces';
import { eLinkType, eFileType } from '@Workspace/_generated/enums';
import { CampaignService } from '../../../../services/campaign.service';
import { FileUploadService } from '../../../../services/file-upload.service';
import { PageLoaderService } from '@Workspace/services';
import { runInThisContext } from 'vm';
import { Functions } from '@Workspace/common';
import * as FileSaver from 'file-saver';
import { FileUpload } from 'primeng/fileupload';


@Component({
    selector: 'campaign-report-manage',
    templateUrl: './campaign-report-manage.component.html',
    styleUrls: ['./campaign-report-manage.component.scss'],

})
export class CampaignReportManageComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        private pageLoader: PageLoaderService,
        private campaignService: CampaignService,
        private uploadService: FileUploadService,
        private confirmationService: ConfirmationService

    ) { }
    @ViewChild('upload') upload: FileUpload;
    gcmLink: string;
    googleSheetLink: string;
    campaignBuldLinks: ICampaignBuildLinkDto[] = [];
    fileType: IFileTypeDto;
    campaignReportLink: string;
    isEditLink: boolean;
    isEditFile: boolean;
    @Input() file: any;

    @Input() baseCampaign: IBaseCampaignDto;

    ngOnInit() {
        this.campaignReportLink = this.baseCampaign.reportLink;
    }

    removeFile(upload) {
        if (!!upload) {
            this.upload.files = [];
            this.isEditFile = false;
        }
        else {
            this.confirmationService.confirm({
                header: 'Confirmation',
                key: 'ReportDelete',
                message:
                    'Are you sure you want to delete Campaign Report file?',
                rejectVisible: true,
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: async () => {
                    this.pageLoader.showPageLoader();
                    this.campaignController.DeleteCampaignReport(this.baseCampaign).subscribe(x => {
                        this.baseCampaign = x;
                        this.isEditFile = true;
                        this.pageLoader.hidePageLoader();

                    })
                }
            });

        }
    }

    editLink() {
        this.isEditLink = true;
        this.campaignReportLink = this.baseCampaign.reportLink;
    }

    isFileValid(upload) {
        this.fileType = {} as IFileTypeDto;
        this.fileType.fileTypeCsv = "doc,docx,pdf,csv";
        this.fileType.fileMaxSizeInBytes = 5242880;
        this.upload = upload;
        if (!Functions.IsFileValid(upload.files[0], this.fileType)) {
            upload.files = [];
            this.messageService.add({ severity: 'ERROR', summary: 'eRROR Message', detail: 'File is not valid' });
        } else {
            this.isEditFile = true;
        }
    }

    save() {
        if (!!this.upload && !!this.upload.files[0] || (!!this.campaignReportLink || (!!this.baseCampaign.reportLink && !this.campaignReportLink))) {
            this.pageLoader.showPageLoader();

            this.baseCampaign.reportLink = this.campaignReportLink;

            const upload = { file: this.upload.files[0], campaignId: this.baseCampaign.id, pathName: this.campaignReportLink, fileName: !!this.upload.files[0] ? this.upload.files[0].name : "", detailsJson: '', fileExtension: 'pdf' } as IUpload;

            const payload = { uploads: [upload] } as IUploadPayloadModelDto;

            this.uploadService.UploadReportFile(payload).subscribe(x => {
                this.isEditLink = false;
                this.campaignReportLink = '';
                if (!this.campaignReportLink && !this.isEditLink)
                    this.campaignService.setRefresh(true);
            })
        }
        // if (!!this.campaignReportLink || (!!this.baseCampaign.reportLink && !this.campaignReportLink)) {
        //     this.pageLoader.showPageLoader();

        //     this.baseCampaign.reportLink = this.campaignReportLink;
        //     this.campaignController.SaveCampaign(this.baseCampaign).subscribe(x => {
        //         this.baseCampaign = x;
        //         this.isEditLink = false;
        //         this.campaignService.setRefresh(true);


        //     })
        // }

    }

    openReport(link) {
        window.open(link, "_blank")
    }

    openReportFile(report) {
        FileSaver.saveAs(this.file, this.baseCampaign.reportFile.fileName);
    }

    ngOnDestroy(): void {

    }
}



