import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MessageService } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
import { CampaignController } from '@Workspace/_generated/services';
import { eAccountType, eCampaignStatus, eStandardCampaginChannel} from '@Workspace/_generated/enums';
import { Router, ActivatedRoute } from '@angular/router';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IFacebookDetailsJson, IFacebookNewsFeedDetailJson, IInStreamVideoJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { Constants, Functions } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CreativeSwapsDialog } from '../../../../../shared-core/components/creative-swaps-dialog/creative-swaps-dialog';
import { FileUpload } from 'apps/core/src/app/modules/shared-core/components/file-upload/file-upload.component';

@Component({
    selector: 'display-premium',
    templateUrl: './display-premium.component.html',
    styleUrls: ['./display-premium.component.scss']
})
export class DisplayPremiumComponent implements OnInit, OnDestroy {
    constructor(
        private cardItemService: CartItemService,
        private authService: AuthService,
        private dateTime: DatePipe,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() landingPage: string;
    @Input() accordionName: string="";

    @ViewChild('creativeSwaps') creativeSwaps: CreativeSwapsDialog;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    @Output() onCreativetDelete: EventEmitter<any> = new EventEmitter();
    @Output() onDateChange: EventEmitter<any> = new EventEmitter();
    @Output() uploadValidationMessage: EventEmitter<any> = new EventEmitter();
    isPreviewOpen: boolean;
    currentIndex: number;
    //creativeAssetsForPreview: ICreativeAssetDto[] = [];
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isUploadOk: boolean = true;
    isLandingUrlOk: boolean = true;
    constants = Constants;
    creativeName: string;
    _creativeAssets: ICreativeAssetDto[] = [];
    campaignDates = '';
    enumAccountType = eAccountType;
    enumCampaignStatus = eCampaignStatus;
    currentUser = this.authService.userSettings;
    isOnlyFlight:boolean = true;
    functions = Functions;
    maxNumberOfFlights: number = 1;
    acceptedBannerSizes = "160x600, 300x250, 300x600, 320x50, 728x90, 970x66, 970x90, 970x250";

    ngOnInit() {

        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));
        //this.campaign.creatives.sort((x, y) => { return y.creativeGroup - x.creativeGroup })[0].creativeGroup;
        
        if(this.campaign.creatives.some(x=> x.creativeGroup > 1))
            this.isOnlyFlight = false;
            
        if (this.creative.creativeAssets.some(x => x.width == 160 && x.height == 600)) {
            this.creative.creativeAssets.filter(x => x.width == 160 && x.height == 600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 300 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 300 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 728 && x.height == 90)) {
            this.creative.creativeAssets.filter(x => x.width == 728 && x.height == 90).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 300 && x.height == 600)) {
            this.creative.creativeAssets.filter(x => x.width == 300 && x.height == 600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 50)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 50).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 100)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 100).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 90)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 90).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 66)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 66).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => !this._creativeAssets.includes(x)))
        {
            this.creative.creativeAssets.filter(x => !this._creativeAssets.includes(x)).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        this.creative.creativeAssets = this._creativeAssets;

        this.creativeName = this.creative.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner ? "Premium Display Banner" : '';

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                //this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId && x.parentCreativeTypeId == this.creative.parentCreativeTypeId  &&  x.creativeGroup === this.creative.creativeGroup);
                this.isUploadOk = true;
                this.isLandingUrlOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                    if (ca.isLandingPageExist != null || ca.isLandingPageExist != undefined)
                        this.isLandingUrlOk = this.isLandingUrlOk && ca.isLandingPageExist;
                });
            });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }
    checkIsFileUploaded() {
        if (this.creative.creativeAssets.some(x => x.fileAdditional != null && x.file == null)) {

            return true;
        }
        else {
            return false;
        }
    }

    delete(){
        this.creativeSwaps.openDialog(
            this.campaign, this.creative.creativeGroup, 'Remove');
    }

    edit(){
        if(this.creativeSwaps){

            this.creativeSwaps.openDialog(
                this.campaign, this.creative.creativeGroup, 'Edit');
        }
    }

    editCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged) {
            this.creative.flightStartDate = event.startDate;
        }
        if (event.isEndDateChanged) {
            this.creative.flightEndDate = event.endDate;
        }

        this.creative.creativeName = event.creativeName;

        this.campaignDates = `${this.formatDate(
            (this.campaign.startDate)
        )} - ${this.formatDate(this.campaign.endDate)}`;
    }

    removeCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged || event.isEndDateChanged) {
            this.onCreativetDelete.emit({ baseCampaign: event.baseCampaign, creativeGroup: this.creative.creativeGroup });
        }
    }

    formatDate(dateProp: any) {
        var date = new Date(dateProp);
        return `${this.dateTime.transform(dateProp, this.constants.DEFAULT_FORMAT_DATE_US)}`;
    }

    
    uploadWarning(message: string){
        this.uploadValidationMessage.emit(message);
    }

}
