import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { faArrowRightFromFile, faArrowUpRightFromSquare, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { IGridOptions } from '@Workspace/interfaces';
import { PageLoaderService } from '@Workspace/services';
import { GridComponent } from '../grid/rc-grid.component';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'inventory-items-dialog',
    templateUrl: './inventory-items-dialog.html',
    styleUrls: ['./inventory-items-dialog.scss'],
})
export class InventoryItemsDialog implements OnInit, OnDestroy {
    skipParam: number = 0;
    takeParam: number = 10;
    isVisible: boolean = false;
    isGridEmpty: boolean = true;
    options: IGridOptions;
    baseCampaign: IBaseCampaignDto;
    pssBroadcastContractLines: any[];
    searchParameter: string = '';
    
    faArrowUpRightFromSquare: any = faArrowUpRightFromSquare;
    faClipboard: any = faClipboard;

    ngUnsubscribe = new Subject();
    @ViewChild(GridComponent) GridComponent: GridComponent;

    constructor(
        public campaignController: CampaignController,
        public pageLoader: PageLoaderService) {
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    openDialog(rowData: IBaseCampaignDto) {
        this.isVisible = true;
        this.options = {} as IGridOptions;
        this.options.isOData = false;
        this.options.columns = [
            {
                label: 'Inventory Type',
                value:'eventType',
                width: '15%'
            },
            {
                label: 'Inventory SKU',
                value: 'inventorySKU',
                width: '15%'
            },
            {
                label: 'Inventory Item',
                value: 'inventoryItem',
                width: '20%'
            },
            {
                label: 'Total Impressions or Percentage',
                value: 'inventoryDuration',
                width: '15%'
            },
            {
                label: 'HUB Campaign',
                value: 'inventoryDuration',
                width: '8%'
            },
            {
                label: 'Notes',
                value: 'notes',
                width: '8%'
            },
            {
                label: 'Contract Line ID',
                value: 'kore_ContractLineId',
                width: '18%'
            }
        ];
        
        this.baseCampaign = rowData;
        this.getData();
    }

    cancel(){
        this.isGridEmpty = true;
        this.isVisible = false;
    }

    getData(refresh: boolean = false){
        this.pageLoader.showPageLoader();
        this.campaignController.GetPSSBroadcastContractLineByDealId(this.baseCampaign.dealId, this.baseCampaign.pssContractLineId, this.searchParameter, this.skipParam, this.takeParam).pipe(take(1)).subscribe(
            result => {
                this.options.data = result;
                if (refresh) {
                    this.GridComponent.value = this.options.data;
                } else {
                    this.isGridEmpty = result.data.length != 0 ? false : true;
                }
                this.pageLoader.hidePageLoader();
                if (!this.isGridEmpty) {
                    setTimeout(() => {
                        this.GridComponent.totalCount = result.totalCount;
                    }, 0);
                }
            },
            error => {
                this.pageLoader.hidePageLoader();
            }
        );
    }

    search(){
        this.GridComponent.resetSkipTake();
        this.skipParam = 0;
        this.takeParam = 10;
        this.getData(true);
    }

    setParameters(event){
        this.skipParam = event.skip;
        this.takeParam = event.take;
        this.getData(true);
    }

    handleHubCampaign(rowData){
        if(!!rowData.baseCampaigns){
            let url = "/#/campaign/" + rowData.baseCampaigns[0].id + "/campaign-upload-fan365";
            window.open(window.location.origin + url, '_blank');
        }
    }
}