import {
    Component,
    AfterViewInit,
    OnDestroy,
    AfterViewChecked,
    Input,
    ViewChild,
    ElementRef,
    NgZone
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GridComponent } from '../rc-grid.component';
import { IColumn } from '@Workspace/interfaces';
import { DomHandler } from 'primeng/dom';

@Component({
    selector: '[ScrollableView]',
    templateUrl: './scrollable-view.component.html'
})
export class ScrollableViewComponent
    implements AfterViewInit, OnDestroy, AfterViewChecked {
    @Input('ScrollableView') columns: IColumn[];

    @Input() frozen: boolean;

    @ViewChild('scrollHeader') scrollHeaderViewChild: ElementRef;

    @ViewChild('scrollHeaderBox') scrollHeaderBoxViewChild: ElementRef;

    @ViewChild('scrollBody') scrollBodyViewChild: ElementRef;

    @ViewChild('scrollTable') scrollTableViewChild: ElementRef;

    @ViewChild('scrollFooter') scrollFooterViewChild: ElementRef;

    @ViewChild('scrollFooterBox') scrollFooterBoxViewChild: ElementRef;

    @ViewChild('virtualScroller') virtualScrollerViewChild: ElementRef;

    headerScrollListener: Function;

    bodyScrollListener: Function;

    footerScrollListener: Function;

    frozenSiblingBody: Element;

    scrollableSiblingBody: Element;

    _scrollHeight: string;

    subscription: Subscription;

    totalRecordsSubscription: Subscription;

    columnsSubscription: Subscription;

    initialized: boolean;

    constructor(
        public dt: GridComponent,
        public el: ElementRef,
        public zone: NgZone
    ) {
        this.subscription = this.dt.tableService.valueSource$.subscribe(() => {
            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.alignScrollBar();
                }, 50);
            });
        });

        if (this.dt.virtualScroll) {
            this.totalRecordsSubscription = this.dt.tableService.totalRecordsSource$.subscribe(
                () => {
                    this.zone.runOutsideAngular(() => {
                        setTimeout(() => {
                            this.setVirtualScrollerHeight();
                        }, 50);
                    });
                }
            );
        }

        this.initialized = false;
    }

    @Input()
    get scrollHeight(): string {
        return this._scrollHeight;
    }
    set scrollHeight(val: string) {
        this._scrollHeight = val;
        this.setScrollHeight();
    }

    ngAfterViewChecked() {
        if (!this.initialized && this.el.nativeElement.offsetParent) {
            this.alignScrollBar();
            this.setScrollHeight();
            this.initialized = true;
        }
    }

    ngAfterViewInit() {
        if (!this.frozen) {
            if (this.dt.frozenColumns || this.dt.frozenBodyTemplate) {
                DomHandler.addClass(
                    this.el.nativeElement,
                    'p-table-unfrozen-view'
                );
            }

            let frozenView = this.el.nativeElement.previousElementSibling;
            if (frozenView) {
                this.frozenSiblingBody = DomHandler.findSingle(
                    frozenView,
                    '.p-table-scrollable-body'
                );
            }
        } else {
            this.scrollBodyViewChild.nativeElement.style.marginBottom =
                DomHandler.calculateScrollbarWidth() + 'px';
            let scrollableView = this.el.nativeElement.nextElementSibling;
            if (scrollableView) {
                this.scrollableSiblingBody = DomHandler.findSingle(
                    scrollableView,
                    '.p-table-scrollable-body'
                );
            }
        }

        this.bindEvents();
        this.setScrollHeight();
        this.alignScrollBar();

        if (this.frozen) {
            this.columnsSubscription = this.dt.tableService.columnsSource$.subscribe(
                () => {
                    this.zone.runOutsideAngular(() => {
                        setTimeout(() => {
                            this.setScrollHeight();
                        }, 50);
                    });
                }
            );
        }

        if (this.dt.virtualScroll) {
            this.setVirtualScrollerHeight();
        }
    }

    bindEvents() {
        this.zone.runOutsideAngular(() => {
            let scrollBarWidth = DomHandler.calculateScrollbarWidth();

            if (
                this.scrollHeaderViewChild &&
                this.scrollHeaderViewChild.nativeElement
            ) {
                this.headerScrollListener = this.onHeaderScroll.bind(this);
                this.scrollHeaderBoxViewChild.nativeElement.addEventListener(
                    'scroll',
                    this.headerScrollListener
                );
            }

            if (
                this.scrollFooterViewChild &&
                this.scrollFooterViewChild.nativeElement
            ) {
                this.footerScrollListener = this.onFooterScroll.bind(this);
                this.scrollFooterViewChild.nativeElement.addEventListener(
                    'scroll',
                    this.footerScrollListener
                );
            }

            if (!this.frozen) {
                this.bodyScrollListener = this.onBodyScroll.bind(this);
                this.scrollBodyViewChild.nativeElement.addEventListener(
                    'scroll',
                    this.bodyScrollListener
                );
            }
        });
    }

    unbindEvents() {
        if (
            this.scrollHeaderViewChild &&
            this.scrollHeaderViewChild.nativeElement
        ) {
            this.scrollHeaderBoxViewChild.nativeElement.removeEventListener(
                'scroll',
                this.headerScrollListener
            );
        }

        if (
            this.scrollFooterViewChild &&
            this.scrollFooterViewChild.nativeElement
        ) {
            this.scrollFooterViewChild.nativeElement.removeEventListener(
                'scroll',
                this.footerScrollListener
            );
        }

        this.scrollBodyViewChild.nativeElement.removeEventListener(
            'scroll',
            this.bodyScrollListener
        );
    }

    onHeaderScroll(event) {
        this.scrollHeaderViewChild.nativeElement.scrollLeft = 0;
    }

    onFooterScroll(event) {
        this.scrollFooterViewChild.nativeElement.scrollLeft = 0;
    }

    onBodyScroll(event) {
        if (
            this.scrollHeaderViewChild &&
            this.scrollHeaderViewChild.nativeElement
        ) {
            this.scrollHeaderBoxViewChild.nativeElement.style.marginLeft =
                -1 * this.scrollBodyViewChild.nativeElement.scrollLeft + 'px';
        }

        if (
            this.scrollFooterViewChild &&
            this.scrollFooterViewChild.nativeElement
        ) {
            this.scrollFooterBoxViewChild.nativeElement.style.marginLeft =
                -1 * this.scrollBodyViewChild.nativeElement.scrollLeft + 'px';
        }

        if (this.frozenSiblingBody) {
            this.frozenSiblingBody.scrollTop = this.scrollBodyViewChild.nativeElement.scrollTop;
        }

        if (this.dt.virtualScroll) {
            let viewport = DomHandler.getOuterHeight(
                this.scrollBodyViewChild.nativeElement
            );
            let tableHeight = DomHandler.getOuterHeight(
                this.scrollTableViewChild.nativeElement
            );
            let pageHeight = this.dt.virtualRowHeight * this.dt.rows;
            let virtualTableHeight = DomHandler.getOuterHeight(
                this.virtualScrollerViewChild.nativeElement
            );
            let pageCount = virtualTableHeight / pageHeight || 1;
            let scrollBodyTop =
                this.scrollTableViewChild.nativeElement.style.top || '0';

            if (
                this.scrollBodyViewChild.nativeElement.scrollTop + viewport >
                parseFloat(scrollBodyTop) + tableHeight ||
                this.scrollBodyViewChild.nativeElement.scrollTop <
                parseFloat(scrollBodyTop)
            ) {
                let page =
                    Math.floor(
                        (this.scrollBodyViewChild.nativeElement.scrollTop *
                            pageCount) /
                        this.scrollBodyViewChild.nativeElement.scrollHeight
                    ) + 1;
                this.dt.handleVirtualScroll({
                    page: page,
                    callback: () => {
                        this.scrollTableViewChild.nativeElement.style.top =
                            (page - 1) * pageHeight + 'px';

                        if (this.frozenSiblingBody) {
                            (<HTMLElement>(
                                this.frozenSiblingBody.children[0]
                            )).style.top = this.scrollTableViewChild.nativeElement.style.top;
                        }
                    }
                });
            }
        }
    }

    setScrollHeight() {
        if (
            this.scrollHeight &&
            this.scrollBodyViewChild &&
            this.scrollBodyViewChild.nativeElement
        ) {
            if (this.scrollHeight.indexOf('%') !== -1) {
                let relativeHeight;
                this.scrollBodyViewChild.nativeElement.style.visibility =
                    'hidden';
                this.scrollBodyViewChild.nativeElement.style.height = '100px'; //temporary height to calculate static height
                let containerHeight = DomHandler.getOuterHeight(
                    this.dt.el.nativeElement.children[0]
                );

                if (this.scrollHeight.includes('calc')) {
                    let percentHeight = parseInt(
                        this.scrollHeight.slice(
                            this.scrollHeight.indexOf('(') + 1,
                            this.scrollHeight.indexOf('%')
                        )
                    );
                    let diffValue = parseInt(
                        this.scrollHeight.slice(
                            this.scrollHeight.indexOf('-') + 1,
                            this.scrollHeight.indexOf(')')
                        )
                    );
                    relativeHeight =
                        (DomHandler.getOuterHeight(
                            this.dt.el.nativeElement.parentElement
                        ) *
                            percentHeight) /
                        100 -
                        diffValue;
                } else {
                    relativeHeight =
                        (DomHandler.getOuterHeight(
                            this.dt.el.nativeElement.parentElement
                        ) *
                            parseInt(this.scrollHeight)) /
                        100;
                }

                let staticHeight = containerHeight - 100; //total height of headers, footers, paginators
                let scrollBodyHeight = relativeHeight - staticHeight;

                if (this.frozen) {
                    scrollBodyHeight -= DomHandler.calculateScrollbarWidth();
                }

                this.scrollBodyViewChild.nativeElement.style.height = 'auto';
                this.scrollBodyViewChild.nativeElement.style.maxHeight =
                    scrollBodyHeight + 'px';
                this.scrollBodyViewChild.nativeElement.style.visibility =
                    'visible';
            } else {
                if (
                    this.frozen &&
                    this.scrollableSiblingBody &&
                    DomHandler.getOuterWidth(this.scrollableSiblingBody) <
                    DomHandler.getOuterWidth(
                        this.scrollableSiblingBody.children[0]
                    )
                )
                    this.scrollBodyViewChild.nativeElement.style.maxHeight =
                        parseInt(this.scrollHeight) -
                        DomHandler.calculateScrollbarWidth() +
                        'px';
                else
                    this.scrollBodyViewChild.nativeElement.style.maxHeight = this.scrollHeight;
            }
        }
    }

    setVirtualScrollerHeight() {
        if (this.virtualScrollerViewChild.nativeElement) {
            this.virtualScrollerViewChild.nativeElement.style.height =
                this.dt.totalCount * this.dt.virtualRowHeight + 'px';
        }
    }

    hasVerticalOverflow() {
        return (
            DomHandler.getOuterHeight(this.scrollTableViewChild.nativeElement) >
            DomHandler.getOuterHeight(this.scrollBodyViewChild.nativeElement)
        );
    }

    alignScrollBar() {
        if (!this.frozen) {
            let scrollBarWidth = this.hasVerticalOverflow()
                ? DomHandler.calculateScrollbarWidth()
                : 0;
            this.scrollHeaderBoxViewChild.nativeElement.style.marginRight =
                scrollBarWidth + 'px';

            if (
                this.scrollFooterBoxViewChild &&
                this.scrollFooterBoxViewChild.nativeElement
            ) {
                this.scrollFooterBoxViewChild.nativeElement.style.marginRight =
                    scrollBarWidth + 'px';
            }
        }
        this.initialized = false;
    }

    ngOnDestroy() {
        this.unbindEvents();

        this.frozenSiblingBody = null;

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.totalRecordsSubscription) {
            this.totalRecordsSubscription.unsubscribe();
        }

        if (this.columnsSubscription) {
            this.columnsSubscription.unsubscribe();
        }

        this.initialized = false;
    }
}
