<div  class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Social Campaign</strong></span>
</div>


<div class="pl-3" class="left-border ml-5">

    <div class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">Post Details</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-12 col-12">
            <label><strong>Post Location</strong></label>
        </div>
        <div class="col-lg-12 col-12 ml-2">
            <table class="w-50">
                <tr>
                    <th></th>
                    <th>Paid</th>
                    <th>Organic</th>
                </tr>
                <tr>
                    <td>Facebook</td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=1 [(ngModel)]="creative.detailSocialCampaignJson.postLocation" ></p-checkbox></td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=2 [(ngModel)]="creative.detailSocialCampaignJson.postLocation" ></p-checkbox></td>
                </tr>
            
                <tr>
                    <td>Instagram</td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=3 [(ngModel)]="creative.detailSocialCampaignJson.postLocation"></p-checkbox></td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=4 [(ngModel)]="creative.detailSocialCampaignJson.postLocation" ></p-checkbox></td>
                </tr>
                <tr>
                    <td>Twitter</td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=5 [(ngModel)]="creative.detailSocialCampaignJson.postLocation" ></p-checkbox></td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=6 [(ngModel)]="creative.detailSocialCampaignJson.postLocation" ></p-checkbox></td>
                </tr>
                <tr>
                    <td>Snapchat</td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=7 [(ngModel)]="creative.detailSocialCampaignJson.postLocation"></p-checkbox></td>
                    <td> <p-checkbox [disabled]="reviewPage || campaign.campaignStatusId != 1" name="postLocation" value=8 [(ngModel)]="creative.detailSocialCampaignJson.postLocation"></p-checkbox></td>
                </tr>
            </table>
        </div>
    </div>


    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>Number of Posts</strong></label>
        </div>
        <div class="col-lg-2 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="number" pattern="^[0-9]" onkeypress="return event.charCode >= 48 && event.charCode <= 57" [min]=1 [step]=1  [(ngModel)]="creative.detailSocialCampaignJson.numberOfPosts" [title]="creative.detailSocialCampaignJson.numberOfPosts" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.numberOfPosts}}</label>
        </div>
    </div>

    <div class="row align-items-center mb-2 ml-2">
        <div class="col-lg-3 col-12">
            <label><strong>Target Audience</strong></label>
        </div>
        <div class="col-lg-9 col-12">
            <input class="form-control" *ngIf="!reviewPage && campaign.campaignStatusId == 1" type="text" [(ngModel)]="creative.detailSocialCampaignJson.targetAudience" [title]="creative.detailSocialCampaignJson.targetAudience" style="overflow: hidden; text-overflow: ellipsis;">
            <label *ngIf="reviewPage || campaign.campaignStatusId != 1" class="mr-0 pr-0">{{creative.detailSocialCampaignJson.targetAudience}}</label>
        </div>
    </div>

</div>