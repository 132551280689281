<div class="col-lg-9 col-12 mx-auto px-0 my-3">
    <!-- <div class="card card-body bg-none">
        <h3 class="display-4">Basic</h3>
        <h6>Create your next arts campaign</h6>
        <div class="media text-muted">
            <p class="media-body mb-0 small lh-125 border-gray">
                Start your next campaign in a few steps.
            </p>
        </div>
    </div> -->
    <h2><b>{{showPackage?'Select Your Package':'Select Add-Ons'}}</b></h2>
    <p class="media-body pb-3 mb-0 small lh-125 border-gray media text-muted">
        {{showPackage?'Start yout next campaign by select a package and adding general information.':'Next, please select add-ons for your package.'}}
    </p>
</div>
<div class="col-lg-9 col-12 mx-auto px-0 campaign-content">
    <div class="card card-body">
        <h6 class="display-4">General Information</h6>
        <div *ngIf="showPackage" class="media text-muted">
            <p class="media-body pb-3 mb-0 small lh-125 border-gray">
                Enter your new campaign name, goal and dates here
            </p>
        </div>
        <div class="border-bottom pb-3">
            <div [formGroup]="formGroup" class="row">
                <div class="col-12">
                    <div class="row mt-3">
                        <label class="col-md-3 col-12 font-weight-bold required">
                            Campaign Name
                        </label>
                        <label *ngIf="!showPackage">{{campaignDto.name}}</label>
                        <div class="col-md-9 col-12">
                            <input pInputText placeholder="Campaign name" *ngIf="showPackage" [ngStyle]="{'width': '100%'}" maxlength="50" formControlName="campaignName" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-3 col-12 font-weight-bold">
                            Campaign Goal
                        </label>
                        <label *ngIf="!showPackage">{{campaignGoalLabel}}</label>
                        <div class="col-md-9 col-12">
                            <p-dropdown autoWidth="false" placeholder="Campaign Goal" [style]="{'width': '100%'}" *ngIf="showPackage" formControlName="campaignGoal" (onChange)="selectedCampaignGoalType($event, true)" [options]="campaignGoals"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentUser.permissions.isLearfieldClient" class="col-12 ml-4 p-0">
                    <div class="row my-2">
                        <label class="col-4">
                            GCM Advertiser ID
                        </label>
                        <label *ngIf="!showPackage">{{currentAccount.advertiserId}}</label>
                        <input pInputText *ngIf="showPackage" formControlName="advertiserId" class="col-7" />
                    </div>
                    <div class="row my-2">
                        <label class="col-4">
                            Facebook Ad Account ID
                        </label>
                        <label *ngIf="!showPackage">{{currentAccount.facebookAdAccountId}}</label>
                        <input pInputText *ngIf="showPackage" formControlName="facebookAdAccountId" class="col-7" />
                    </div>
                    <div class="row my-2">
                        <label class="col-4">
                            Facebook Page ID
                        </label>
                        <label *ngIf="!showPackage">{{currentAccount.facebookPageId}}</label>
                        <input pInputText *ngIf="showPackage" formControlName="facebookPageId" class="col-7" />
                    </div>
                    <div class="row my-2">
                        <label class="col-4">
                            Instagram ID
                        </label>
                        <label *ngIf="!showPackage">{{currentAccount.instagramId}}</label>
                        <input pInputText *ngIf="showPackage" formControlName="instagramId" class="col-7" />
                    </div>
                </div>

            </div>
            <div class="row mt-3">
                <label class="col-md-3 col-12 font-weight-bold">
                    Campaign Dates
                </label>
                <div class="col-md-9 col-12">
                    <div class="row">
                        <div class="col-12 pl-0" *ngIf="!showPackage">
                            <label>{{(campaignDto.startDate) | date : constants.DEFAULT_FORMAT_DATE_US }}</label>
                            - <label>{{(campaignDto.endDate) | date : constants.DEFAULT_FORMAT_DATE_US }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" *ngIf="showPackage">
                            <div class="d-flex flex-wrap align-items-center justify-content-between">
                                <div class="calendar-wrapper">
                                    <p-calendar placeholder="mm/dd/yyyy" appendTo = "body" [minDate]="startDate" (onSelect)="onChangeStartDate($event)" [(ngModel)]="campaignDto.startDate" [showIcon]="'true'"></p-calendar>
                                </div>
   
                                
                                <div>
                                    <!-- <i class="fas fa-minus"></i> -->
                                    to
                                </div>

                                <div class="calendar-wrapper">
                                    <p-calendar placeholder="mm/dd/yyyy" appendTo = "body" [disabled]="!campaignDto.startDate" [minDate]="minEndDate" [maxDate]="maxEndDate" (onSelect)="onChangeEndDate($event)" [(ngModel)]="campaignDto.endDate" [showIcon]="'true'"></p-calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <campaign-package-preview [campaignDetails]="campaignDto" *ngIf="showPackage && !!selectedCampaignGoal" (getPackages)="selectedCampaignGoalType($event)" (onSelectedPackageItemAddOn)="onSelectedPackageItemAddOn($event)" [campaignGoalType]="selectedCampaignGoal" (onClick)="onShowPackage($event)" (onSelectedPackageItem)="onSelectedPackageItem($event)"></campaign-package-preview>
        <div *ngIf="!showPackage">
            <div class="d-flex">
                <div class="mr-auto p-2">
                    <div>

                        <h6 class="border-gray mb-0 display-4">Great Choice!</h6>
                        <div *ngIf="(packageItem != 'Amplify' && packageItem != 'Amplify Flex')" class="media text-muted media-body mb-0 small lh-125 border-gray">
                            Improve your <strong class="px-1">{{packageItem}}</strong> package by choose <span class="text-primary pl-2">add-ons.</span>
                        </div>
                        <div *ngIf="(packageItem == 'Amplify Flex')" class="media text-muted media-body mb-0 small lh-125 border-gray">
                            Improve your <strong class="px-1">{{packageItem}}</strong> by adding number of creative flights.
                        </div>

                    </div>
                </div>
                <div class="p-2"> Expand package :</div>
                <div *ngIf="selectedCampaignGoal != 3" class="p-2"> <strong>${{amount | number : '1.2-2'}}</strong>/ {{difference_In_Days / 7 | number:'1.0-2'}} {{unit}} </div>
                <div *ngIf="selectedCampaignGoal == 3" class="p-2"> <strong>${{amount | number : '1.2-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>
            </div>

        </div>

        <div *ngIf="!showPackage">

            <div *ngIf="this.formGroup.value.campaignGoal!=3" class="border p-2 mb-2 text-black">
                <div class="d-flex flex-wrap align-items-center">
                    <div>
                        <label>Number of Creative Flights: </label>
                    </div>
    
                    <div>
                        <p-inputNumber [size]="1" [min]="1" [max]="4" [(ngModel)]="numberOfCreativeFlightsAmplifyFlex"  [showButtons]="true" (ngModelChange)="handleNumberOfFlights($event)" buttonLayout="horizontal" spinnerMode="horizontal" decrementButtonClass="bg-white" incrementButtonClass="bg-white" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                        </p-inputNumber>
                    </div>
                    <div *ngIf="this.formGroup.value.campaignGoal!=3" class="ml-auto p-2"> <strong>${{campaignDto.totalCreativeFlightsAddOnAmount | number: '1.0-2' }}</strong>/ {{difference_In_Days/7 |  number:'1.0-2' }} weeks </div>
                </div>
                
            </div>

            

            <div *ngIf="(isDisplayAddOnVisible) && (packageItem !== 'Amplify' && packageItem !== 'Amplify Flex') " class="d-flex align-items-center border mt-2" [ngClass]="(IsAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': IsAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': IsAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="changePremiumAddOn(addOn[0].id)" class="btn shadow-none"><i [ngClass]=" IsAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button> </div>
                <div *ngIf="!!addOn[0]" class="p-2">{{addOn[0].name}}</div>
                <div *ngIf="!!addOn[0] && selectedCampaignGoal != 3" class="ml-auto p-2"> <strong>${{(addOn[0].price * difference_In_Days) / 7 | number: '1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}} </div>
                <div *ngIf="!!addOn[0] && selectedCampaignGoal == 3" class="ml-auto p-2"> <strong>${{(addOn[0].price * difference_In_Days) | number: '1.0-2'}}</strong>/ {{difference_In_Days | number:'1.0-2' }} days </div>

            </div>
            <div *ngIf="(isFacebookAddOnVisible) && (packageItem !== 'Amplify' && packageItem !== 'Amplify Flex')" class="d-flex align-items-center border my-2" [ngClass]="(IsFacebookAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': IsFacebookAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': IsFacebookAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="fbHandleChange(addOn[1].id)" class="btn shadow-none"><i [ngClass]="IsFacebookAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[1]">{{addOn[1].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[1] && selectedCampaignGoal != 3"> <strong>${{(addOn[1].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[1] && selectedCampaignGoal == 3"> <strong>${{(addOn[1].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>
            <div *ngIf="campaignGoalLabel != 'Quick Promotion'">
            <div *ngIf="(isAbandonedCartAddOnVisible) && (packageItem !== 'Amplify' && packageItem !== 'Amplify Flex')" class="d-flex align-items-center border my-2" [ngClass]="(IsAbandonedCartAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': IsAbandonedCartAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': IsAbandonedCartAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="abandonedCartAddOnChange(addOn[2].id)" class="btn shadow-none"><i [ngClass]="IsAbandonedCartAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[2]">{{addOn[2].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[2] && selectedCampaignGoal != 3"> <strong>${{(addOn[2].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[2] && selectedCampaignGoal == 3"> <strong>${{(addOn[2].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>

            <div *ngIf="(isPpuAddOnVisible) && (packageItem !== 'Amplify' && packageItem !== 'Amplify Flex')" class="d-flex align-items-center border my-2" [ngClass]="(isPpuAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': isPpuAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': isPpuAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="ppuAddOnChange(addOn[3].id)" class="btn shadow-none"><i [ngClass]="isPpuAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[3]">{{addOn[3].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[3] && selectedCampaignGoal != 3"> <strong>${{(addOn[3].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[3] && selectedCampaignGoal == 3"> <strong>${{(addOn[3].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>

            <div *ngIf="isInStreamVideoAddOnVisible" class="d-flex align-items-center border my-2" [ngClass]="(isInStreamVideoAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': isInStreamVideoAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': isInStreamVideoAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="inStreamVideoAddOnChange(addOn[4].id)" class="btn shadow-none"><i [ngClass]="isInStreamVideoAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[4]">{{addOn[4].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[4] && selectedCampaignGoal != 3"> <strong>${{(addOn[4].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[4] && selectedCampaignGoal == 3"> <strong>${{(addOn[4].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>

            <div *ngIf="isStaticNativeAddOnVisible" class="d-flex align-items-center border my-2" [ngClass]="(isStaticNativeAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': isStaticNativeAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': isStaticNativeAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="staticNativeVideoAddOnChange(addOn[5].id)" class="btn shadow-none"><i [ngClass]="isStaticNativeAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[5]">{{addOn[5].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[5] && selectedCampaignGoal != 3"> <strong>${{(addOn[5].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[5] && selectedCampaignGoal == 3"> <strong>${{(addOn[5].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>

            <div *ngIf="isFacebookDynamicVideoAddOnVisible" class="d-flex align-items-center border my-2" [ngClass]="(isFacebookDynamicVideoAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': isFacebookDynamicVideoAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': isFacebookDynamicVideoAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="facebookDynamicVideoAddOnChange(addOn[6].id)" class="btn shadow-none"><i [ngClass]="isFacebookDynamicVideoAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[6]">{{addOn[6].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[6] && selectedCampaignGoal != 3"> <strong>${{(addOn[6].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[6] && selectedCampaignGoal == 3"> <strong>${{(addOn[6].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>

            <div *ngIf="isFacebookDynamicStaticAddOnVisible" class="d-flex align-items-center border my-2" [ngClass]="(isFacebookDynamicStaticAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': isFacebookDynamicStaticAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': isFacebookDynamicStaticAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="facebookDynamicStaticAddOnChange(addOn[7].id)" class="btn shadow-none"><i [ngClass]="isFacebookDynamicStaticAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[7]">{{addOn[7].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[7] && selectedCampaignGoal != 3"> <strong>${{(addOn[7].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[7] && selectedCampaignGoal == 3"> <strong>${{(addOn[7].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>

            <div *ngIf="isFacebookCarouselAddOnVisible" class="d-flex align-items-center border my-2" [ngClass]="(isFacebookCarouselAddOnSelected)?'border-secondary':'border-1'">
                <div [ngStyle]="{'background-color': isFacebookCarouselAddOnSelected ? 'var(--background-color-active)' : 'var(--package-button-background-color)'}" class="border p-2 text-black">
                    <button [ngStyle]="{'color': isFacebookCarouselAddOnSelected ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'}" type="button" (click)="facebookCarouselAddOnChange(addOn[8].id)" class="btn shadow-none"><i [ngClass]="isFacebookCarouselAddOnSelected ? 'fas fa-minus test-white':'fas fa-plus'"></i></button>
                </div>
                <div class="p-2" *ngIf="!!addOn[8]">{{addOn[8].name}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[8] && selectedCampaignGoal != 3"> <strong>${{(addOn[8].price * difference_In_Days) / 7 | number :'1.0-2'}}</strong>/ {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}}</div>
                <div class="ml-auto p-2" *ngIf="!!addOn[8] && selectedCampaignGoal == 3"> <strong>${{(addOn[8].price * difference_In_Days) | number :'1.0-2'}}</strong>/ {{difference_In_Days |  number:'1.0-2' }} days </div>

            </div>
        </div>

            <div class="d-flex border-bottom  flex-row-reverse total-cost-wrapper">
                <div *ngIf="selectedCampaignGoal != 3" class="p-2"><strong>${{totalAmount | number:'1.2-2'}}</strong> / {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}} </div>
                <div *ngIf="selectedCampaignGoal == 3" class="p-2"><strong>${{totalAmount | number:'1.2-2'}}</strong> / {{difference_In_Days |  number:'1.0-2' }} days </div>
                <div class=" p-2"> Total Campaign Cost :</div>
            </div>
            <div class="border-bottom  flex-row-reverse impressions-wrapper" [ngStyle]="{'margin-top': '7px'}">
                <div class="justify-content-between" [ngStyle]="{'display': 'flex'}">
                    <div class=" p-2"> Minimum Campaign Impressions :</div>
                    <div *ngIf="selectedCampaignGoal != 3" class="p-2"><strong>{{( 1000 * totalAmount / minCpm) - ( 1000 * totalAmount / minCpm)%1 | number:'1.0-0'}}</strong> / {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}} </div>
                    <div *ngIf="selectedCampaignGoal == 3" class="p-2"><strong>{{( 1000 * totalAmount / minCpm) - ( 1000 * totalAmount / minCpm)%1 | number:'1.0-0'}}</strong> / {{difference_In_Days |  number:'1.0-2' }} days </div>
                </div>
                <div>
                <div class="justify-content-between" [ngStyle]="{'display': 'flex', 'margin-top': '-20px'}">
                    <div class=" p-2"> Maximum Campaign Impressions :</div>
                    <div *ngIf="selectedCampaignGoal != 3" class="p-2"><strong>{{( 1000 * totalAmount / maxCpm) - ( 1000 * totalAmount / maxCpm)%1 | number:'1.0-0'}}</strong> / {{difference_In_Days /7 |  number:'1.0-2' }} {{unit}} </div>
                    <div *ngIf="selectedCampaignGoal == 3" class="p-2"><strong>{{( 1000 * totalAmount / maxCpm) - ( 1000 * totalAmount / maxCpm)%1 | number:'1.0-0'}}</strong> / {{difference_In_Days |  number:'1.0-2' }} days </div>
                </div>
                <p [ngStyle]="{'font-size':'10px', 'margin-top': '-10px'}">*Impression amounts are subject to change based on time of year and add-ons selected.</p>
            </div>
        </div>
    </div>

    <div *ngIf="!showPackage">
        <div class="d-flex flex-row-reverse flex-wrap bd-highlight mt-3 mb-3">
            <div class="py-2 bd-highlight pl-2"><button type="button" pButton (click)="onContinue(true)" class="p-button-primary" [label]="((isInStreamVideoAddOnSelected || isStaticNativeAddOnSelected || isFacebookCarouselAddOnSelected || isFacebookDynamicStaticAddOnSelected || isFacebookDynamicVideoAddOnSelected || IsAddOnSelected || IsFacebookAddOnSelected || IsAbandonedCartAddOnSelected || isPpuAddOnSelected) &&  (packageItem != 'Amplify' && packageItem != 'Amplify Flex')) || numberOfCreativeFlightsAmplifyFlex > 1 || 
                ((isInStreamVideoAddOnSelected || isStaticNativeAddOnSelected || isFacebookCarouselAddOnSelected || isFacebookDynamicStaticAddOnSelected || isFacebookDynamicVideoAddOnSelected) && (packageItem == 'Amplify' || packageItem == 'Amplify Flex')) ? 'Next':'Skip'"></button></div>
            <div class="py-2 bd-highlight"><button type="button" pButton (click)="onContinue(false)" class="p-button-primary" label="Save & exit to Home"></button></div>
            <div class="py-2 pr-2 bd-highlight"><button type="button" pButton (click)="onShowPackage(true)" class="flat-btn" label="Back"></button></div>
            <div class="py-2 pr-2 bd-highlight"><button type="button" pButton (click)="cancel()" class="flat-btn" label="Cancel"></button></div>
        </div>
    </div>
</div>