import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { IChannelDto, ICreativeDto, IBaseCampaignDto, ICreativeAssetDto, IDetailJson } from '@Workspace/_generated/interfaces';
import { eStandardCampaginChannel, eCampaignStatus, eChannelType, eVideoType } from '@Workspace/_generated/enums';
import { CampaignService } from '../../../../services/campaign.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignController } from '@Workspace/_generated/services';
import { isNgTemplate } from '@angular/compiler';
import { Constants, Functions, LookUps } from '@Workspace/common';
import { EmptyError, EMPTY } from 'rxjs';
import { PageLoaderService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';


@Component({
    selector: 'su-campaign-detail-creatives',
    templateUrl: 'su-campaign-creatives.component.html',
    styleUrls: ['su-campaign-creatives.component.scss']


})
export class SuCampaignCreativeComponent implements OnInit {
    constructor(
        protected fb: UntypedFormBuilder,
        public campaignService: CampaignService,
        public router: Router,
        public authService: AuthService,
        public campaignController: CampaignController,
        public messageService: MessageService,
        private pageLoaderService: PageLoaderService
    ) { }
    IsSocial: boolean = false;
    IsDisplayBanner: boolean = false;
    IsOtherBanner: boolean = false;
    creativeDto: ICreativeDto[] = [];
    currentUser = this.authService.userSettings;
    @Input() selectedCreatives: ICreativeDto[];
    @Input() selectedDimensions: string[];
    @Input() startDate: Date;
    @Input() endDate: Date;

    @Input() selectedBaseCampaignDto: IBaseCampaignDto;
    @Output() onReviewStep: EventEmitter<object> = new EventEmitter();
    @Output() onChannelSelectionStep: EventEmitter<object> = new EventEmitter();
    creativesDto: ICreativeDto[] = [];
    allOptionChannels: ICreativeDto[] = [];
    isReviewVisible: boolean = false;
    displayBannerOptions: any[] = ['160x600', '300x250', '728x90', '320x50', '300x600', '970x250', '970x90', '320x100'];
    selectedCategories: string[] = ['160x600', '300x250', '728x90', '320x50', '300x600'];
    videoTypes: SelectItem[] = [];
    videoType: eVideoType[] = [];
    groupedCreatives: any;
    minFlightDate: Date;
    maxFlightDate: Date;

    ngOnInit() {

        this.initLists();
        this.minFlightDate = this.startDate;
        this.maxFlightDate = this.endDate;
        this.creativesDto = {} as ICreativeDto[];

        this.allOptionChannels = this.selectedCreatives;
        this.groupedCreatives = this.groupBy(this.selectedCreatives.filter(x => !!x.quantity && !!x.flightStartDate && !!x.flightEndDate), x => x.creativeTypeId)

        this.groupedCreatives.forEach(x => {
            x = x;
            if (x.creativeTypeId == eStandardCampaginChannel.In_Stream_Video) {
                if (x.detailJson == null) {
                    x.detailJson = {} as IDetailJson;
                    x.detailJson.videoType = 0;
                }
            }
        })

        if (this.selectedDimensions.length > 0)
            this.selectedCategories = this.selectedDimensions;

        this.selectedCreatives.forEach(element => {
            //element.flightEndDate = new Date(Functions.dateToUserTimeZone(element.flightEndDate.toString(), this.currentUser.timeZoneJs));
            // element.flightStartDate = new Date(Functions.dateToUserTimeZone(element.flightStartDate.toString(), this.currentUser.timeZoneJs));
            let isDiplayBanner = this.campaignService.isDisplayBanner(element.creativeTypeId);
            let isSocial = this.campaignService.isSocial(element.creativeTypeId);
            let isOther = this.campaignService.isOther(element.creativeTypeId);
            if (isDiplayBanner) {

                this.IsDisplayBanner = true;
                element.isDisplay = true;
                element.isSocial = false;
            }
            else if (isSocial) {
                element.isSocial = true;
                element.isDisplay = false;
                this.IsSocial = true;
            }
            else {

                this.IsOtherBanner = true;
            }

            if (element.creativeTypeId == eStandardCampaginChannel.In_Stream_Video) {
                if (element.detailJson == null) {
                    element.detailJson = {} as IDetailJson;
                    element.detailJson.videoType = 0;
                }
            }

            // element.forEach(details => {

            if (!this.selectedBaseCampaignDto.startDate && !this.selectedBaseCampaignDto.endDate) {

                element.flightStartDate = new Date(this.selectedBaseCampaignDto.startDate);
                element.flightEndDate = new Date(this.selectedBaseCampaignDto.endDate);
            }
            //   })

        });


    }

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    initLists() {
        this.videoTypes = LookUps.getVideoTypeLu();
        this.videoTypes.unshift({ value: 0, label: "-" });
    }

    Cancel() {
        var gridId = 2; // HS campaigns
        this.campaignService.setGridIdId(gridId);
        this.router.navigate(['/mogo']);
    }

    OnNextStep() {
        this.isReviewVisible = true;
    }

    onSelectedChannels(type: string) {
        if (type == 'Social') {
            this.creativeDto = [];

            this.selectedCreatives.forEach(x => {

                let tt = this.campaignService.isSocial(x.creativeTypeId)
                if (tt) {
                    this.creativeDto.push(this.selectedCreatives.find(y => y.id == x.id));
                    this.IsDisplayBanner = false;
                    this.IsSocial = true;
                }
            })
        }
        else if (type == 'Display Banner') {
            this.creativeDto = [];
            this.selectedCreatives.forEach(x => {

                let isDisplay = this.campaignService.isDisplayBanner(x.creativeTypeId)
                if (isDisplay) {
                    this.creativeDto.push(this.allOptionChannels.find(y => y.id == x.id));
                    this.IsDisplayBanner = true;
                }
            })



        }
        this.allOptionChannels = this.creativeDto;


    }

    onFlightStartDateChange(flightStartDate: Date, data: any) {
        data.flightStartDate = flightStartDate;
        Functions.dateTimeZoneConversion(data.flightStartDate);
    }

    onFlightEndDateChange(flightEndDate: Date, data: any) {
        data.flightEndDate = flightEndDate;
        Functions.dateTimeZoneConversion(data.flightEndDate);
    }

    onReview(event: any) {
        this.isReviewVisible = true;
        if (this.IsDisplayBanner)
            this.selectedCreatives.forEach(x => {

                x.bannerDimensions = this.selectedCategories
            })
        var creatives = this.selectedCreatives.filter(x => x.quantity > 0);
        this.onReviewStep.emit({ Display: this.IsDisplayBanner, Social: this.IsSocial, Other: this.IsOtherBanner, isReviewPageVisible: this.isReviewVisible, creativeDto: this.groupedCreatives, selectedCreatives: creatives })
    }

    onPreviousStep() {
        this.onChannelSelectionStep.emit({ campaign: this.allOptionChannels, IsPrevious: true });

    }

    saveCampaign() {
        this.pageLoaderService.showPageLoader();
        var crtest = [];
        this.selectedCreatives.forEach(x => {
            // x.creativeDetailsDtos.map(y => {
            let creative = {} as ICreativeDto;
            creative.flightEndDate = x.flightEndDate;
            creative.flightStartDate = x.flightStartDate;
            creative.creativeTypeId = x.creativeTypeId;
            creative.channelId = x.channelId;
            creative.name = x.name;
            creative.id = x.id;
            creative.isBuilt = x.isBuilt;
            creative.creativeAssets = x.creativeAssets;
            // x.name = y.name;
            // x.id = y.id;
            creative.detailJson = x.detailJson;
            if (this.IsDisplayBanner)
                creative.bannerDimensions = this.selectedCategories;
            crtest.push(creative);
            // })
            x.bannerDimensions = this.selectedCategories;
        });

        // this.selectedBaseCampaignDto.startDate = new Date(this.selectedBaseCampaignDto.startDate);

        // this.selectedBaseCampaignDto.startDate = new Date(Date.UTC(this.selectedBaseCampaignDto.startDate.getFullYear(), this.selectedBaseCampaignDto.startDate.getMonth(), this.selectedBaseCampaignDto.startDate.getDate()));

        // this.selectedBaseCampaignDto.endDate = new Date(this.selectedBaseCampaignDto.endDate);

        // this.selectedBaseCampaignDto.endDate = new Date(Date.UTC(this.selectedBaseCampaignDto.endDate.getFullYear(), this.selectedBaseCampaignDto.endDate.getMonth(), this.selectedBaseCampaignDto.endDate.getDate()));

        // this.selectedBaseCampaignDto.dueDate = new Date(Date.UTC(this.selectedBaseCampaignDto.dueDate.getFullYear(), this.selectedBaseCampaignDto.dueDate.getMonth(), this.selectedBaseCampaignDto.dueDate.getDate()));

        this.selectedBaseCampaignDto.creatives = crtest;
        this.selectedBaseCampaignDto.campaignStatusId = !!this.selectedBaseCampaignDto.id && this.selectedBaseCampaignDto.id != Constants.GuidEmpty ? this.selectedBaseCampaignDto.campaignStatusId : eCampaignStatus.Campaign_Draft;
        this.campaignController.SaveCampaignHS(this.selectedBaseCampaignDto).subscribe(
            x => {
                if(x.id != undefined)
                {
                    var gridId = 2; // Other campaigns
                    this.campaignService.setGridIdId(gridId);
                    this.pageLoaderService.hidePageLoader();
    
                    this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                    setTimeout(() => this.router.navigate(['/mogo']), 3000);
                }
                else
                {
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong with saving a campaign.' });
                    this.pageLoaderService.hidePageLoader();
                }
                
            }
        )
    }

}