import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IBankAccountDto, IBaseCampaignDto, ICreditCardDto, IPayCampaignByBankAccountModel, IPayCampaignByCardModel, IPaymentClientDto } from '@Workspace/_generated/interfaces';
import { CampaignController, EmailController, PaymentController } from '@Workspace/_generated/services';
import { NotificationService, PageLoaderService, SettingsService } from '@Workspace/services';
import { MessageService, SelectItem } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CampaignService } from '../../../services/campaign.service';
import { eCampaignGoal, eCampaignStatus } from '@Workspace/_generated/enums';
import { Constants } from '@Workspace/common';
import { CampaignCostsTableComponent } from '../../../../shared-core/components/campaign-costs-table/campaign-costs-table.component';

@Component({
    selector: 'campaign-payment',
    templateUrl: './campaign-payment.component.html',
    styleUrls: ['./campaign-payment.component.scss']
})
export class CampaignPaymentComponent implements OnInit, OnDestroy {
    @Input('campaignDescription') description: string;
    @Input('invoiceNumber') invoiceNumber: string;

    constructor(
        private campaignController: CampaignController,
        private paymentController: PaymentController,
        public messageService: MessageService,
        public router: Router,
        private pageLoader: PageLoaderService,
        private notificationService: NotificationService,
        protected formBuilder: UntypedFormBuilder,
        private mail: EmailController,
        private campaignService: CampaignService,
        private settingsService: SettingsService

    ) { }

    @Input() baseCampaign: any;
    @Input() isCampaignSucessfullySaved = new BehaviorSubject<boolean>(true);;

    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onPay: EventEmitter<any> = new EventEmitter();
    @Output() onGoBack: EventEmitter<any> = new EventEmitter();

    @ViewChild('campaignCostsTable') campaignCostsTable: CampaignCostsTableComponent;



    cardNumber: string;
    accountNumber: string;
    routingNumber: string;

    cvc: string;
    errorMessage: string;

    firstName: string;
    lastName: string;
    zipCode: string;
    address: string;
    bankName: string;
    states: SelectItem[];
    selectedState: SelectItem;

    cities: SelectItem[];
    selectedCity: SelectItem;

    expirationYears: SelectItem[];
    expirationYear: SelectItem;
    expirationMonths: SelectItem[];
    expirationMonth: SelectItem;
    paymentForm: UntypedFormGroup;
    defaultPaymentMethod = 1;
    ngUnsubscribe = new Subject();

    public paymentMethods: Array<SelectItem> = [
        { value: 1, label: 'Credit Card' },
        { value: 2, label: 'Bank Account (USA only)' }
    ];

    ngOnInit() {
        this.initialize();
    }

    private initialize() {
        this.campaignController.GenerateCampaignInvoiceNumber(this.baseCampaign).subscribe(data => {

            this.baseCampaign = data;
            this.getStates();
            this.getExpirationDates();
        })

    }



    private getStates() {
        this.pageLoader.showPageLoader();
        this.campaignController.GetBillingStates().subscribe(
            result => {
                this.states = [];
                result.forEach(x =>
                    this.states.push({ label: x.name, value: x.id })
                );
                this.states.unshift({ value: 0, label: "-" });
                this.pageLoader.hidePageLoader();
            },
            error => {
                this.pageLoader.hidePageLoader();
            }
        );
    }

    getCities(stateId: any) {
        this.pageLoader.showPageLoader();
        this.campaignController.GetBillingCities(stateId).subscribe(
            result => {
                this.cities = [];
                result.forEach(x =>
                    this.cities.push({ label: x.name, value: x.id })
                );
                this.cities.unshift({ value: 0, label: "-" });

                this.pageLoader.hidePageLoader();
            },
            error => {
                this.pageLoader.hidePageLoader();
            }
        );
    }

    getExpirationDates() {
        const currentYear = new Date().getFullYear();
        this.expirationYears = [];

        for (let i = 0; i < 10; i++) {
            const year = currentYear + i;
            const yearValue = year.toString().substr(2);
            this.expirationYears.push({ label: `${year}`, value: yearValue });
        }
        this.expirationMonths = [];

        for (let i = 1; i < 13; i++) {
            const month = i < 10 ? '0' + i : i;
            this.expirationMonths.push({ label: `${month}`, value: month });
        }
    }

    cancel() {
        this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Now, you will be redirected to Home Page.' });
        setTimeout(
            () => {

                var gridId = 3; // HMP users
                this.campaignService.setGridIdId(gridId);
                this.router.navigate(['mogo']),
                    1000
            }
        );
    }

    printOrder(id) {
        var printContents = document.getElementById(id).innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;

    }

    goBack() {
        this.onGoBack.emit(true);
    }

    // openAgreementDoc() {
    //     this.campaignController.GetCampaignFile("", Constants.GuidEmpty)
    //         .subscribe(
    //             result => {
    //                 if (!!result) {
    //                     let file = this.byteArrayToFileBlob(result as any);
    //                     var fileURL = URL.createObjectURL(file);
    //                     window.open(fileURL);
    //                 }

    //             })
    // }

    save() {
        this.onSave.emit(true);
    }

    pay() {
        let state = !!this.selectedState ? this.states.find(i => i.value == this.selectedState).value : this.states[0]
        let city = !!this.selectedCity ? this.cities.find(i => i.value == this.selectedCity).value : this.cities[0];
        const customer: IPaymentClientDto = {
            firstName: this.firstName,
            lastName: this.lastName,

            stateOrProvince: state,
            city: city,
            address: this.address,
            country: 'USA',
            zipcode: this.zipCode
        };

        if (this.defaultPaymentMethod == 1) {
            const creditCard: ICreditCardDto = {
                cvc: this.cvc,
                cardNumber: this.cardNumber,
                expirationDate: `${this.expirationMonth}${this.expirationYear}`
            };

            const payment: IPayCampaignByCardModel = {
                campaignId: this.baseCampaign.id,
                payment: {
                    client: customer,
                    creditCard: creditCard
                }
            };
            //   if (this.settingsService.IsLive) {
            this.pageLoader.showPageLoader();
            this.paymentController.PayCampaignByCard(payment).subscribe(
                result => {
                    this.pageLoader.hidePageLoader();
                    this.messageService.add({ severity: 'success', detail: 'Transaction successful' });
                    this.onPay.emit({ paymentData: creditCard.cardNumber, isCard: true, baseCampaign: this.baseCampaign });
                },
                error => {
                    this.pageLoader.hidePageLoader();
                    console.log(error);
                    let errors = '';
                    error.error.forEach((x, i) => {

                        errors += x.value + (i > 0 && i < error.error.length - 1 ? ', ' : '')
                    });

                    this.messageService.add({ severity: 'error', detail: errors });
                }
            );
        }
        else {
            const bankAccount: IBankAccountDto = {
                bankName: this.bankName,
                accountNumber: this.accountNumber,
                routingNumber: this.routingNumber
            };

            const payment: IPayCampaignByBankAccountModel = {
                campaignId: this.baseCampaign.id,
                payment: {
                    client: customer,
                    bankAccount: bankAccount
                }
            };
            this.pageLoader.showPageLoader();
            this.paymentController.PayCampaignByBankAccount(payment).subscribe(
                result => {
                    this.pageLoader.hidePageLoader();
                    this.messageService.add({ severity: 'success', detail: 'Transaction successful' });
                    this.onPay.emit({ paymentData: bankAccount.accountNumber, isCard: false, baseCampaign: this.baseCampaign });
                },
                error => {
                    this.pageLoader.hidePageLoader();
                    console.log(error);
                    let errors = '';
                    error.error.forEach((x, i) => {

                        errors += x.value + (i > 0 && i < error.error.length - 1 ? ', ' : '')
                    });

                    this.messageService.add({ severity: 'error', detail: errors });
                }
            );
            //  } else {
            // this.onPay.emit({ paymentData: bankAccount.accountNumber, isCard: false, baseCampaign: this.baseCampaign });
            //  }
        }
    }

    private byteArrayToFileBlob(base64: string) {
        let binaryString = window.atob(base64);
        const length = binaryString.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return new Blob([bytes], { type: 'application/pdf' });
    }

    validate() {
        let valid = false;

        if (!!this.address && !!this.firstName && !!this.lastName && !!this.selectedCity && this.selectedState && !!this.zipCode && ((this.defaultPaymentMethod == 1 && !!this.cvc && !!this.cardNumber && !!this.expirationMonth && !!this.expirationYear) || (this.defaultPaymentMethod == 2 && !!this.bankName && !!this.accountNumber && !!this.routingNumber))) {
            valid = true;
        }

        return valid;
    }

    validateCosts(){
        return !!this.campaignCostsTable && this.campaignCostsTable.costsTableValidation;
    }

    onInputChange(event: any) {
        const numericValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        event.target.value = numericValue;
        this.cardNumber = numericValue;
      }

    redirectSA() {
        let url: string = this.settingsService.createClientUrl('/#/mogo/service-agreement');
        window.open(url, "_blank");
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


}
