import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, AbstractType } from '@angular/core';
import { Constants } from '@Workspace/common';
import { IBaseCampaignDto, ILandingPageJson, ISeasonJson, IAssetDto, ICreativeDto, IDemographicsDto, IGeographicTargetingJson } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { eCampaignStatus, eChannelType } from '@Workspace/_generated/enums';
import { Router } from '@angular/router';
import { CampaignService } from '../../../../services/campaign.service';
import { PageLoaderService } from '@Workspace/services';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'su-campaign-review',
    templateUrl: 'su-campaign-review.component.html',
    styleUrls: ['./su-campaign-review.component.scss']
})
export class SuCampaignReviewComponent implements OnInit {
    constructor(
        public campaignController: CampaignController,
        public campaignService: CampaignService,
        private router: Router,
        private pageLoaderservice: PageLoaderService,
        private messageService: MessageService) { }

    @Output() onCreativeSelectionStep: EventEmitter<object> = new EventEmitter();
    @Input() channelGroup: any;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() dueDate: Date;
    @Input() campaign: IBaseCampaignDto;
    channel: string = '';
    constants = Constants;
    isSaved: boolean;

    ngOnInit() {

        if (this.channelGroup.Display) {
            this.channel = "Display Banner";
        }
        if (this.channelGroup.Social) {
            this.channel = "Social";
        }
        if (this.channelGroup.Other) {
            this.channel = "Other";
        }
        if (this.channelGroup.Display && this.channelGroup.Social && !this.channelGroup.Other) {
            this.channel = 'Display Banner, Social';
        }
        if (this.channelGroup.Display && this.channelGroup.Other && !this.channelGroup.Social) {
            this.channel = 'Display Banner, Other';
        }
        if (this.channelGroup.Social && this.channelGroup.Other && !this.channelGroup.Display) {
            this.channel = 'Social, Other';
        }
        if (this.channelGroup.Social && this.channelGroup.Other && this.channelGroup.Display) {
            this.channel = 'Display Banner, Social, Other';
        }
    }

    sendToClient(isExit: boolean) {
        this.isSaved = true;
        this.pageLoaderservice.showPageLoader();

        this.campaign.creatives = {} as ICreativeDto[];
        this.campaign.creatives = this.channelGroup.selectedCreatives;
        var crtest = [];
        this.campaign.creatives.forEach(x => {
            let creativeList = {} as ICreativeDto;
            creativeList.flightEndDate = x.flightEndDate;
            creativeList.flightStartDate = x.flightStartDate;
            creativeList.creativeTypeId = x.creativeTypeId;
            creativeList.channelId = x.channelId;
            creativeList.name = x.name;
            creativeList.isBuilt = x.isBuilt;
            creativeList.id = x.id;
            creativeList.displayName = x.displayName;
            creativeList.creativeAssets = x.creativeAssets;
            creativeList.detailJson = x.detailJson;
            if (this.channelGroup.Display)
                creativeList.bannerDimensions = x.bannerDimensions;
            crtest.push(creativeList);
        });

        this.campaign.creatives = crtest;
        this.campaign.advertiserId = '1';
        this.campaign.campaignGoalId = 1;
        this.campaign.campaignStatusId = this.campaign.campaignStatusId != eCampaignStatus.Campaign_Live && this.campaign.campaignStatusId != eCampaignStatus.Campaign_Paused ? eCampaignStatus.Assets_Pending : this.campaign.campaignStatusId;
        this.campaign.facebookPageName = '';
        this.campaign.geographicTargetingJson = {} as IGeographicTargetingJson;
        this.campaign.isCampaignSetAsPaid = false;
        this.campaign.landingPageJson = {} as ILandingPageJson;
        this.campaign.seasonJson = {} as ISeasonJson;
        this.campaign.demographics = {} as IDemographicsDto;
        this.campaign.isEdit = true;
        this.campaignController.SaveCampaignHS(this.campaign).subscribe(x => {
            if(x.id != undefined)
            {
                if (isExit)
                    var gridId = 2; // Other campaigns
                this.pageLoaderservice.hidePageLoader();
                this.campaignService.setGridIdId(gridId);
                this.router.navigate(['/mogo']);
            }
            else
            {
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong with sending a campaign.' });
                this.pageLoaderservice.hidePageLoader();
            }
        });
    }
    save(isExit: boolean) {
        this.isSaved = true;
        this.pageLoaderservice.showPageLoader();
        this.campaign.creatives = {} as ICreativeDto[];
        this.campaign.creatives = this.channelGroup.selectedCreatives;
        this.campaign.advertiserId = '1';
        this.campaign.campaignGoalId = 1;

        this.campaign.campaignStatusId = !!this.campaign.id && this.campaign.id != Constants.GuidEmpty ? this.campaign.campaignStatusId : eCampaignStatus.Campaign_Draft;
        this.campaign.facebookPageName = '';
        this.campaign.geographicTargetingJson = {} as IGeographicTargetingJson;
        this.campaign.isCampaignSetAsPaid = false;
        this.campaign.landingPageJson = {} as ILandingPageJson;
        this.campaign.seasonJson = {} as ISeasonJson;
        this.campaign.demographics = {} as IDemographicsDto;
        var crtest = [];
        this.campaign.creatives.forEach(x => {
            let creativeList = {} as ICreativeDto;
            creativeList.flightEndDate = x.flightEndDate;
            creativeList.flightStartDate = x.flightStartDate;
            creativeList.creativeTypeId = x.creativeTypeId;
            creativeList.channelId = x.channelId;
            creativeList.name = x.name;
            creativeList.id = x.id;
            creativeList.isBuilt = x.isBuilt;
            creativeList.displayName = x.displayName;
            creativeList.creativeAssets = x.creativeAssets;
            creativeList.detailJson = x.detailJson;
            if (this.channelGroup.Display)
                creativeList.bannerDimensions = x.bannerDimensions;
            crtest.push(creativeList);
        });
        this.campaign.creatives = crtest;
        this.campaignController.SaveCampaignHS(this.campaign).subscribe(x => {
            if(x.id != undefined)
                {
                    if (isExit)
                        var gridId = 2; // Other campaigns
                    this.pageLoaderservice.hidePageLoader();
                    this.campaignService.setGridIdId(gridId);
                    this.router.navigate(['/mogo']);
                }
                else
                {
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong with saving a campaign.' });
                    this.pageLoaderservice.hidePageLoader();
                }
             });
    }
    onPreviousStep() {
        this.onCreativeSelectionStep.emit(this.channelGroup);
    }
    onCancel() {
        var gridId = 2; // Other campaigns
        this.campaignService.setGridIdId(gridId);
        this.router.navigate(['/mogo']);
    }
}