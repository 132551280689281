<div [ngClass]="isApprove ? 'row col-12' : ''">

    <!-- for upload page -->
    <div [ngClass]="isApprove ? 'col-6' : ''">
        <div *ngIf="!isApprove">
            <h6 class="font-weight-normal text-left mb-2">{{headerTitle}}</h6>
        </div>
        <div class="upload-wrapper mb-3" [ngClass]="border && !reviewPage ? 'border-wrong' : (!border && url ? 'border-approved': 'border-ok')" id="first" style="height: 200px;" fileDrop (onFileDropped)="selectFile($event)">
            <img (click)="openPreview()" *ngIf="url  && isPicture" [src]="url" alt="" class="w-100 h-100 d-block" style="object-fit: contain;">
            <video (loadedmetadata)="onVideoLoaded($event)" (click)="openPreview()" *ngIf="url  && isVideo" alt="" [ngClass]="this.border && !reviewPage ? 'border border-2' : ''" class="w-100 h-100 d-block" controls>
                <!-- (loadedmetadata)="getDuration($event)"  -->
                <source [src]="url" id="video_here"> Your browser does not support HTML5 video. </video>
            <audio (click)="openPreview()" *ngIf="url && isAudio" [ngClass]="this.border && !reviewPage ? 'border border-2' : ''" class="w-100 h-100 d-block" controls [src]="url"> Your browser does not support the <code>audio</code> element. </audio>

            <div *ngIf="canDelete && !reviewPage && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" class="d-flex align-items-center justify-content-center deleteAsset delete-button">
                <i (click)="deleteAsset()" class="fa fa-trash-alt"></i>
            </div>

            <div *ngIf="checkIsUploadEnabled()" [ngClass]="border && !reviewPage?'invalid-message-box':''" class="text-center d-flex flex-column justify-content-center h-100">
                <h4 *ngIf="border && !reviewPage">{{validationMessage}}</h4>
                <!-- <h5 *ngIf="header">{{this.width}} x {{this.height}}</h5> -->
                <!-- <h5 *ngIf="width && width>0 && height && height>0">{{this.width}} x {{this.height}}</h5> -->
                <h5> {{!!width && width>0 && !!height && height>0 ? width + 'x' + height
                        : ((campaign.accountTypeId == 'FAN365' || campaign.accountTypeId == 'OAS') && creative.channelId == 2 ? '': dimension) }}</h5>
                <div *ngIf="isHero">Hero image</div>
                <div>Drag and drop
                    <span *ngIf="type">{{type}}</span>
                    <span *ngIf="!type">file</span>
                </div>
                <div>
                    or 
                    <a type="" class="text-secondary font-weight-bold" (onFileDropped)="selectFile($event)" *ngIf="!url" (click)="changeFileType();uploader.click()" id="upload_link">browse </a>
                </div>
                <div *ngIf="!!size">
                    <label class="sub-heading">Max {{this.size >= 1073741824 ? this.size/1073741824 + ' GB' : (this.size >= 1048576 ? this.size/1048576 + ' MB' : (this.size >= 1024 ? this.size/1024 + ' KB' : this.size + 'bytes'))}} , {{this.filetypes}}</label>
                </div>
            </div>

            <div *ngIf="url" class="text-center bg-grey w-100 size-wrapper">
                <div class="row w-100 mx-0 justify-content-between">
                    <div *ngIf="originalWidth > 0 && originalHeight > 0" class="col-4 px-0">
                        <div class="w-100 py-1 size" [ngClass]="creativeAsset.isValid == false && border && !reviewPage ? 'wrong-dimensions' : '' ">
                            {{this.originalWidth}} x {{this.originalHeight}}
                        </div>
                    </div>
                    <div *ngIf='(!creativeAsset.isValid && !reviewPage && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights))' class="col-3 px-0 reupload">
                        <a class="d-flex align-items-center justify-content-center text-uppercase delete-button w-100 h-100" type="button" (click)="deleteUpload()" id="changeupload_link">Remove</a>
                    </div>
                    <div *ngIf='(!creativeAsset.isValid && !reviewPage && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights))' [ngClass]="creativeAsset.isValid == false && border ? 'invalid-message-box':''" class="col-5 px-0 reupload">
                        <a class="d-flex align-items-center justify-content-center text-uppercase w-100 h-100" type="button" (click)="changeuploader.click()" id="changeupload_link">Browse For New</a>
                        <input id="Changeupload" type="file" #changeuploader (change)="selectFile($event)" [accept]="acceptTypes" hidden>
                    </div>
                    <div *ngIf='creativeAsset.isValid && !reviewPage && !!creativeAsset.file && !!creativeAsset.file.urlWithToken' class="col-6 px-0 reupload">
                        <label class="approved-font mt-1 mb-0">APPROVED</label>
                    </div>
                    <div *ngIf='creativeAsset.isValid==false && !currentUser.permissions.isClient && !reviewPage && !!creativeAsset.file && !!creativeAsset.file.urlWithToken' class="col-6 px-0 reupload">
                        <label class="reported-font mt-1 mb-0">REPORTED</label>
                    </div>
                </div>
            </div>
    </div>
        <input id="upload" type="file" #uploader (onFileDropped)="selectFile($event)" (change)="selectFile($event)" onclick="this.value=null;" [accept]="filetypes != 'all' ? acceptTypes : ''" hidden>

    </div>

    <div class="col-6" *ngIf="isApprove">
        <file-approve [reviewPage]="reviewPage" [statusId]="campaign.statusId" [headerTitle]="headerTitle" [isApprovedReported]="isApprovedReported" [creativeAsset]="creativeAsset" [isBuilt]="creative.isBuilt"></file-approve>
    </div>
</div>
<!-- footer for upload page -->
<div *ngIf="footer && !reviewPage">
    <div *ngIf="!!creative && creative.creativeTypeId != enumCreativeType.Stories" class="d-flex flex-wrap placeholder-wrapper mb-2" [ngClass]="{'disabled' : !functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)}" >
        <div class="p-2 upload-icon">
            <i class="d-flex align-items-center justify-content-center fa fa-fw fa-upload" style="cursor: pointer;" (click)="(!creativeAsset.isValid && !reviewPage && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)) ? uploaderZip.click() : $event.stopPropagation()"></i>
            <input id="uploadZip" type="file" #uploaderZip accept=".zip, .rar, .7zip" (change)="uploadZip($event)" hidden>
        </div>
        <div class="p-2" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" (click)="openZip(zip ? zip : null)">{{htmlToolTipError? 'Html zip file can not be larger than 200KB': zip ? creativeAsset.fileAdditional.description : 'HTML Zip'}}</div>
        <span tooltipPosition="top" [pTooltip]='constants.htmlZipInfo' tooltipStyleClass="tooltipStyle" class="fal fa-info-circle infoZip"></span>
    </div>

    <!-- <div *ngIf="isApprove" class="pl-3 pb-2" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; color: cornflowerblue;" (click)="openZip(this.zip)">{{this.zip ? creativeAsset.fileAdditional.description : ''}}</div> -->
    <input [disabled]='!!landingPage || url==null || !functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)' [ngClass]="!this.validationLandingUrl && !landingPage ? 'border-wrong' : ''" class="form-control file-input" (focusout)="validateLandingPage()" [(ngModel)]="!!landingPage && !!creativeAsset.file ? landingPage : creativeAsset.imageUrl" [title]="!!landingPage && !!creativeAsset.file ? landingPage:creativeAsset.imageUrl" placeholder='Landing page URL' style="overflow: hidden; text-overflow: ellipsis;" />
    <span tooltipPosition="top" [pTooltip]='constants.landingPageInfo' tooltipStyleClass="tooltipStyle" class="fal fa-info-circle info"></span>
    <!-- <div class="pl-3 pb-2" *ngIf="isApprove"><a style="color: cornflowerblue;" [href]="getLink()">{{creativeAsset.imageUrl}}</a></div> -->
</div>

<!-- footer for review page -->
<div class="ml-3" *ngIf="footer && reviewPage">
    <label class="row ml-0" style="color: cornflowerblue;">{{creativeAsset.fileAdditional ? creativeAsset.fileAdditional.description : null}}</label>
    <a class="row ml-0" (click)="redirect(!!landingPage && !!creativeAsset.file ? landingPage : creativeAsset.imageUrl)" style="color: cornflowerblue;">{{!!landingPage  ? landingPage : creativeAsset.imageUrl}}</a>
</div>

<div *ngIf="creativeAsset && creativeAsset.isValid == false && !isApprove && !!creativeAsset.validationMessage && !hideValidationMessage" class="p-1 mt-2 invalid-message-box">
    <label class="mb-0 truncate px-1">{{creativeAsset.validationMessage}}</label>
</div>