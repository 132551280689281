
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokenService } from 'libs/services/auth/auth.token.service';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {


    
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first

        if (component.canDeactivate()) {
            return true;
        }
        else {
            if (new Date().getTime() < AuthTokenService.accesTokenExpiration.getTime()) {
                return window.confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes or OK to lose these changes.');
            }
            else {
                return true;
            }
        }
            
        //return canDeactivateTest
        // ?
        // true :
        // // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // // when navigating away from your angular app, the browser will show a generic warning message
        // // see http://stackoverflow.com/a/42207299/7307355
        // window.confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes or OK to lose these changes.');
    }
}