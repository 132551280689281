<p-dialog [modal]="true" [(visible)]="isVisible" (onHide)="cancel()">
    <p-header>All Digital Deal Sheet Inventory</p-header>

    <div>
        <div class="text-muted" *ngIf="isGridEmpty" style="text-align: center; padding-top: 5em; padding-bottom: 5em;">
            <strong>There aren't any digital deal sheet inventory items for this campaign.</strong>
        </div>

        <div class="row" *ngIf="!isGridEmpty">
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-6 offset-6">
                        <div class="p-inputgroup w-100">
                            <input class="w-100" type="text" pInputText [(ngModel)]="searchParameter" placeholder="Search" (keyup.enter)="search()" style="border-right: 0;" />
                            <button pButton type="button" icon="pi pi-search" class="p-inputgroup-addon" (click)="search()"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <rc-grid [options]="options" [paginator]="true" [isCurentPageInputEnabled]="true" (onBeforeDataLoad)="setParameters($event)" [rowsPerPageOptions]="[10]"> 
                    <ng-template pTemplate="header" let-item>
                        <tr>
                            <th *ngFor="let column of options?.columns"
                                [ngStyle]="{'width':column.width}" class="p-3">
                                {{column.label}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        <tr>
                            <td>
                                <span [ngClass]="rowData.kore_ContractLineId == baseCampaign.pssContractLineId ? 'bold-row': ''">
                                    {{ rowData.eventType }}
                                </span>
                            </td>
                            <td>
                                <span [ngClass]="rowData.kore_ContractLineId == baseCampaign.pssContractLineId ? 'bold-row': ''">
                                    {{ rowData.inventorySKU }}
                                </span>
                            </td>
                            <td>
                                <span [ngClass]="rowData.kore_ContractLineId == baseCampaign.pssContractLineId ? 'bold-row': ''">
                                    {{ rowData.inventoryItem }}
                                </span>
                            </td>
                            <td>
                                <span [ngClass]="rowData.kore_ContractLineId == baseCampaign.pssContractLineId ? 'bold-row': ''">
                                    {{ rowData.inventoryDuration }}
                                </span>
                            </td>
                            <td>
                                <fa-icon *ngIf="rowData?.baseCampaigns?.length == 1" [icon]="faArrowUpRightFromSquare" (click)="handleHubCampaign(rowData)" class="faArrowUpRightFromSquare"></fa-icon>
                                <span *ngIf="rowData?.baseCampaigns?.length > 1" [pTooltip]="rowData.campaignsTooltip" [ngClass]="rowData.kore_ContractLineId == baseCampaign.pssContractLineId ? 'bold-row': ''">
                                    {{ rowData.baseCampaigns.length }}
                                </span>
                            </td>
                            <td>
                                <fa-icon *ngIf="!!rowData?.notes" [pTooltip]="rowData.notes" [icon]="faClipboard" class="faClipboard"></fa-icon>
                            </td>
                            <td>
                                <span [ngClass]="rowData.kore_ContractLineId == baseCampaign.pssContractLineId ? 'bold-row': ''">
                                    {{ rowData.kore_ContractLineId }}
                                </span>
                            </td>
                        </tr>

                    </ng-template>
                </rc-grid>
            </div>
        </div>
    </div> 

    <p-footer>
        <div class="mb-3 d-flex justify-content-end">
            <button (click)="cancel()" pButton class="px-3 flat-btn pull-left " label="Close"></button>
        </div>
    </p-footer>

</p-dialog>