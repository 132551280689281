<ng-container *ngIf="!dt.expandedRowTemplate && !dt.isEmpty()">
    <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue || dt.value.data) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue || dt.value.data)" [ngForTrackBy]="dt.rowTrackBy">
        <ng-container *ngTemplateOutlet="template; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns}"></ng-container>
    </ng-template>
</ng-container>
<ng-container *ngIf="dt.expandedRowTemplate && !dt.isEmpty()">
    <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue || dt.value.data) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue || dt.value.data)" [ngForTrackBy]="dt.rowTrackBy">
        <ng-container *ngTemplateOutlet="template; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns, expanded: dt.isRowExpanded(rowData)}"></ng-container>
        <ng-container *ngIf="dt.isRowExpanded(rowData)">
            <ng-container *ngTemplateOutlet="dt.expandedRowTemplate; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns}"></ng-container>
        </ng-container>
    </ng-template>
</ng-container>
<ng-container *ngIf="dt.isEmpty()">
    <ng-container *ngTemplateOutlet="dt.emptyMessageTemplate; context: {$implicit: columns}"></ng-container>
</ng-container>