import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResetPasswordModel, ICRMDto, ICreativeAssetDto, IUploadPayloadModelDto } from '@Workspace/_generated/interfaces';
import { eStandardCampaginChannel } from '@Workspace/_generated/enums';
import { HttpEvent, HttpRequest, HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CampaignController } from '@Workspace/_generated/services';
import { SettingsService } from '@Workspace/services';
import { map } from 'rxjs/internal/operators/map';
import { Constants } from '@Workspace/common';
@Injectable()
export class FileUploadService {


    constructor(
        private campaignController: CampaignController,
        private httpClient: HttpClient,
        protected settingsService: SettingsService
    ) { }
    public UploadCRMAsync(
        model: ICRMDto
    ): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('CampaignId', model.campaignId);
        formData.append('FileType', model.file.fileType.toLocaleString());
        formData.append('FileWrapper.File', model.fileWrapper.file);

        return this.httpClient.post(
            this.settingsService.createApiUrl('Campaign/UploadCRM'),
            formData,
            { withCredentials: true }
        );

    }


    public UploadCampaignAssetAsync(
        model: ICreativeAssetDto
    ): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        // formData.append('DeckId', model.creative.campaignId);

        formData.append('CreativeId', model.creativeId);
        // formData.append('', model.Id);
        // formData.append('FileWrapper.File', model.fileWrapper.file);

        const request = new HttpRequest(
            'POST',
            this.settingsService.createApiUrl('Media/UploadThumbnailAsync'),
            formData,
            { withCredentials: true }
        );
        return this.httpClient.request(request);
    }

    public DownloadInstructionPageContent(azurePath: string): Observable<any> {
        return this.httpClient.get<any>(azurePath);
    }

    public UploadInstructionPageImages(payload: IUploadPayloadModelDto): Observable<HttpEvent<any>> {
        const apiUrl = this.settingsService.createApiUrl('Instructions/UploadInstructionPageImages');
        return this.UploadPayload(payload, apiUrl, false);
    }

    public UpdateInstructionPage(payload: IUploadPayloadModelDto): Observable<HttpEvent<any>> {
        const apiUrl = this.settingsService.createApiUrl('Instructions/UpdateInstructionPage');
        return this.UploadPayload(payload, apiUrl, false);
    }

    public UploadReportFile(payload: IUploadPayloadModelDto): Observable<HttpEvent<any>> {
        const apiUrl = this.settingsService.createApiUrl('Campaign/UploadReport');
        return this.UploadPayload(payload, apiUrl, false);
    }

    public UploadCreativeAssets(payload: IUploadPayloadModelDto, isFromReview: boolean): Observable<HttpEvent<any>> {
        const apiUrl = this.settingsService.createApiUrl('Campaign/SaveCreativeAssets');
        return this.UploadPayload(payload, apiUrl, isFromReview);
    }
    public DownloadZipFile(campaignId: string): Observable<number[]> {
        const body = <any>{ 'campaignId': campaignId };

        return this.httpClient.get<number[]>(
            this.settingsService.createApiUrl('Campaign/GetAssetsZip'),
            {
                responseType: 'arraybuffer' as 'json',
                observe: 'response',
                withCredentials: true,
                params: new HttpParams({ fromObject: body })
            })
            .pipe(map(response => response.body));
    }
    public GetCMRFile(campaignId: string, specificFile: string): Observable<number[]> {
        const body = <any>{ 'campaignId': campaignId, 'specificFile': specificFile };

        return this.httpClient.get<number[]>(
            this.settingsService.createApiUrl('Campaign/GetCRMFiles'),
            {
                responseType: 'arraybuffer' as 'json',
                observe: 'response',
                withCredentials: true,
                params: new HttpParams({ fromObject: body })
            })
            .pipe(map(response => response.body));
    }
    public GetCampaignFile(campaignId: string, fileName: string): Observable<number[]> {
        const body = <any>{ 'campaignId': campaignId, 'fileName': fileName };

        return this.httpClient.get<number[]>(
            this.settingsService.createApiUrl('Campaign/GetCampaignFile'),
            {
                responseType: 'arraybuffer' as 'json',
                observe: 'response',
                withCredentials: true,
                params: new HttpParams({ fromObject: body })
            })
            .pipe(map(response => response.body));
    }
    private UploadPayload(payload: IUploadPayloadModelDto, apiUrl: string, isFromReview: boolean): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        payload.uploads.forEach((x, iter) => {
            const folderName = !!x.pathName ? x.pathName : '';
            const detailsJson = !!x.detailsJson ? x.detailsJson : '';
            const campaignId = !!x.campaignId ? x.campaignId : Constants.GuidEmpty;
            const fileTypeId = !!x.fileTypeId ? x.fileTypeId : 0;
            const fileExtension = !!x.fileExtension ? x.fileExtension : '';
            const isFromReviewPage = isFromReview ? '1' : '0';


            formData.append(`Uploads[${iter}].PathName`, folderName);
            formData.append(`Uploads[${iter}].FileName`, x.fileName);
            formData.append(`Uploads[${iter}].File`, x.file);
            formData.append(`Uploads[${iter}].DetailsJson`, detailsJson);
            formData.append(`Uploads[${iter}].CampaignId`, campaignId);
            formData.append(`Uploads[${iter}].FileTypeId`, fileTypeId.toString());
            formData.append(`Uploads[${iter}].FileExtension`, fileExtension);
            formData.append(`Uploads[${iter}].IsFromReview`, isFromReviewPage);

        });

        const request = new HttpRequest(
            'POST',
            apiUrl,
            formData,
            { withCredentials: true }
        );

        return this.httpClient.request(request);
    }


}