import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { LookUps, Constants } from '@Workspace/common';
import { IAccountDto, IUserAccountDto, IAccountReportingLinkDto } from '@Workspace/_generated/interfaces';
import { eUserRole, eEntityStatus } from '@Workspace/_generated/enums';
import { AuthService } from '@Workspace/auth';
import { TeamController, AccountController } from '@Workspace/_generated/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'account-reporting',
    templateUrl: 'account-reporting.component.html',
    styleUrls: ['./account-reporting.component.scss']
})
export class AccountReportingComponent implements OnInit, OnDestroy {
    constructor(
        public fb: UntypedFormBuilder,
        private authService: AuthService,
        private teamController: TeamController,
        private accountController: AccountController
    ) { }

    @Input() account: IAccountDto;

    formGroup: UntypedFormGroup;
    accountReportingLinks: IAccountReportingLinkDto[] = [];
    currentUser = this.authService.userSettings;
    isEdit: boolean;

    ngUnsubscribe: Subject<void> = new Subject();

    async ngOnInit() {
        this.formInit();
        this.initLists();
    }

    formInit(): void {
        this.formGroup = this.fb.group({
            urlLinkName: ["", Validators.required],
            url: ["", Validators.required]

        });
    }

    async initLists() {
        if (!this.account.accountReportingLinks)
            this.account.accountReportingLinks = [];
    }

    addReportLink() {
        let item = this.account.accountReportingLinks.find(x => x.isEdit);
        let reportLink = !!item ? item : {} as IAccountReportingLinkDto;
        reportLink.url = this.formGroup.value.url;
        reportLink.urlLinkName = this.formGroup.value.urlLinkName;
        reportLink.accountId = this.account.id;
        reportLink.id = !!item ? item.id : Constants.GuidEmpty;
        reportLink.isEdit = false;

        reportLink.entityStatus = eEntityStatus.Active;
        if (!item)
            this.account.accountReportingLinks.push(reportLink);

        this.formInit();
    }

    editReportLink(item: IAccountReportingLinkDto) {
        item.isEdit = true;
        this.patchFormValue(item);
    }

    removeReportLink(item: IAccountReportingLinkDto) {
        if (item.id == Constants.GuidEmpty) {
            let index = this.account.accountReportingLinks.findIndex(x => x.url == item.url && x.urlLinkName == item.urlLinkName);
            this.account.accountReportingLinks.splice(index, 1);
        } else {
            item.entityStatus = eEntityStatus.Deleted;
        }
    }

    patchFormValue(item: IAccountReportingLinkDto) {
        this.formGroup.patchValue({
            urlLinkName: item.urlLinkName,
            url: item.url
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}