import {
    Component,
    ElementRef,
    AfterViewChecked,
    AfterViewInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnInit
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { GalleriaModel } from './galleria.model';
import { eCampaignStatus, eFileType } from '@Workspace/_generated/enums';
import { DomHandler } from 'primeng/dom';
import { IFileDto, ICreativeAssetDto } from '@Workspace/_generated/interfaces';
import { Constants } from '@Workspace/common';
import { Galleria } from 'primeng/galleria';

@Component({
    selector: 'rc-galleria',
    templateUrl: './galleria.component.html',
    styleUrls: ['./galleria.component.scss']
})
export class RcGalleriaComponent implements OnDestroy, OnInit {

    @ViewChild(Galleria) gallery: Galleria;
    responsiveOptions: any[] = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];


    _medias: ICreativeAssetDto[];
    
    enumCampaignStatus = eCampaignStatus;

    @Input() isApproveReported: boolean;
    @Input() isApproveProcess: boolean;
    @Input() active: number;
    @Input() isVisible = false;
    @Input() activeIndex: number;
    @Input() isBuilt: boolean;
    @Input() statusId: eCampaignStatus;

    @Output() previewDialogDisplay: EventEmitter<any> = new EventEmitter<any>();


    @Input() medias: ICreativeAssetDto[];


    ngOnInit(): void {
        this.changeActiveIndex(this.active);

        // this.gallery._activeIndex = this.active;
    }


    close() {
        this.isVisible = false;
        this.isApproveReported = this.medias[this.activeIndex].isValid || this.medias[this.activeIndex].isValid == false
        this.previewDialogDisplay.emit({ visible: this.isVisible, item: this.medias[this.activeIndex], isApproveReported: this.isApproveReported });

    }


    changeActiveIndex(event) {
        this.activeIndex = event;
    }


    imageClick(index: number) {
        this.activeIndex = index;
        //this.displayCustom = true;
    }

    getMetiaType(media: ICreativeAssetDto) {
        let mediaType = '';

        if (!!media.fileType) {

            mediaType = media.fileType
        }
        else {

            mediaType = media.file.fileType;
        }

        return mediaType;
    }

    isImage(media: ICreativeAssetDto) {
        if (!!media) {
            return Constants.ImageFileTyps.some(x => x == this.getMetiaType(media));
        }
    }

    isAudio(media: ICreativeAssetDto) {
        if (media) {

            return Constants.AudioFileTyps.some(x => x == this.getMetiaType(media));
        }

    }

    isVideo(media: ICreativeAssetDto) {
        if (media) {

            return Constants.VideoFileTyps.some(x => x == this.getMetiaType(media));
        }

    }

    ngOnDestroy() {
        // this.stopSlideshow();
    }
}
