import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@Workspace/auth';
import { Constants } from '@Workspace/common';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { MessageService } from 'primeng/api';
import { CampaignService } from '../../../services/campaign.service';
import { SettingsService } from '@Workspace/services';

@Component({
    selector: 'campaign-success-card',
    templateUrl: './campaign-success-card.component.html',
    styleUrls: ['./campaign-success-card.component.scss']
})
export class CapmaignSuccessCardComponent implements OnDestroy {
    constructor(private router: Router,
        private authService: AuthService,
        public messageService: MessageService,
        private campaignService: CampaignService,
        private campaignController: CampaignController,
        private settingsService: SettingsService) { }


    @Input() campaignDto: IBaseCampaignDto;
    @Input() paymentData: any;

    @Output() onCerateNew: EventEmitter<any> = new EventEmitter();

    currentUser = this.authService.userSettings;
    constants = Constants;
    uploadNewCRMModalState: boolean;
    isPrint = false;

    goHome() {
        this.router.navigate(['mogo'])
    }
    print() {
        this.isPrint = true;
        setTimeout(
            () => {

                window.print(),
                    1000
            }
        );

    }

    openUploadNewCRM() {
        this.uploadNewCRMModalState = true;
    }

    handleCloseDialog() {
        this.uploadNewCRMModalState = false;
    }

    createNewCampaign() {
        this.onCerateNew.emit({ activeIndex: 0, campaign: {} as IBaseCampaignDto, isCampaignPaid: true });
    }

    cancel() {
        this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Now, you will be redirected to Home Page.' });
        setTimeout(
            () => {

                var gridId = 3; // HMP users
                this.campaignService.setGridIdId(gridId);
                this.router.navigate(['mogo']),
                    1000
            }
        );
    }


    // openAgreementDoc() {
    //     this.campaignController.GetCampaignFile("", Constants.GuidEmpty)
    //         .subscribe(
    //             result => {
    //                 if (!!result) {
    //                     let file = this.byteArrayToFileBlob(result as any);
    //                     var fileURL = URL.createObjectURL(file);
    //                     window.open(fileURL);
    //                 }

    //             })
    // }

    private byteArrayToFileBlob(base64: string) {
        let binaryString = window.atob(base64);
        const length = binaryString.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }


        return new Blob([bytes], { type: 'application/pdf' });
    }

    redirectSA() {
        let url: string = this.settingsService.createClientUrl('/#/mogo/service-agreement');
        window.open(url, "_blank");
    }


    ngOnDestroy(): void {

    }
}