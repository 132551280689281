<p-dialog [dismissableMask]="true" (onHide)="close()" [modal]="true" [(visible)]="isVisible">
    <!-- <button class="title-button" (click)="close()"> X </button> -->
    <div class="row m-0">
        <div [ngClass]="isApproveProcess ? 'col-10' : 'col-12 previewImage'" class="my-3">
            <div class="col-12 row mb-2">
                <div *ngIf="isImage(medias[activeIndex])" class="col-6 title">{{medias[activeIndex].width}}x{{medias[activeIndex].height}}</div>


            </div>
            <p-galleria #galleria (activeIndexChange)="changeActiveIndex($event)" [(value)]="medias" [responsiveOptions]="responsiveOptions" [circular]="true" [showItemNavigators]="true" [containerStyle]="{'max-width': '100%', 'min-height':'7rem'}" [numVisible]="5" [showThumbnails]="false" showCaption="true" [(activeIndex)]="activeIndex">
                <ng-template pTemplate="item" let-item>
                    <video *ngIf="isVideo(medias[activeIndex])" controls>
                        <source [src]="medias[activeIndex]?.file.urlWithToken" id="video_here"> Your browser does not support HTML5 video. </video>
                    <img *ngIf="isImage(medias[activeIndex])" [src]="medias[activeIndex]?.file.urlWithToken" style="display: block;height: medias[activeIndex].height" />
                </ng-template>

            </p-galleria>

            <div class="custom-galleria-footer row">
                <!-- <button type="button" pButton icon="pi pi-list"></button> -->
                <div *ngIf="medias" class="title-container mt-2 col-12">
                    <span>{{isImage(medias[activeIndex]) ? "IMAGE" : "VIDEO"}} {{activeIndex + 1}}/{{medias.length}}</span>
                    <!-- <span class="title">{{medias[activeIndex].title}}</span>
                            <span>{{images[activeIndex].alt}}</span> -->
                </div>
                <!-- <button type="button" pButton [icon]="fullScreenIcon()" (click)="toggleFullScreen()" class="fullscreen-button"></button> -->
            </div>

        </div>
        <div *ngIf="isApproveProcess" class="col-2 approve">
            <file-approve [creativeAsset]="medias[activeIndex]" [statusId]="statusId" [isBuilt]="isBuilt" [isFromPreview]="true" [isApprovedReported]="medias[activeIndex].isValid || medias[activeIndex].isValid == false"></file-approve>
        </div>
    </div>

</p-dialog>