import { Component, Output, EventEmitter, OnInit, HostListener, ViewChild, ElementRef } from "@angular/core";
import { IFileDto, ICRMDto, IFormFileWrapper, IEnumDataAttribute } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { AuthService } from '@Workspace/auth';
import { SelectItem, MessageService } from 'primeng/api';
import { eCRMType, eCRMTypeName, eFileType } from '@Workspace/_generated/enums';
import { LookUps, Constants } from '@Workspace/common';
import { Router } from '@angular/router';
import { FileUploadService } from '../../../services/file-upload.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { constants } from 'os';
import * as FileSaver from 'file-saver';
import { CampaignService } from '../../../services/campaign.service';
import { PageLoaderService } from '@Workspace/services';

@Component({
    selector: 'app-upload-crm-modal',
    templateUrl: './upload-crm.modal.html',
    styleUrls: ['./upload-crm.modal.scss']
})

export class UploadCrmFormComponent implements OnInit {
    constructor(
    public fileUploadService: FileUploadService,
    public pageLoaderService: PageLoaderService,
    public campaignController: CampaignController, 
    public authService: AuthService, 
    public router: Router, 
    public messageService: MessageService, 
    public campaignService: CampaignService) 
    { }
    public urls = [];
    public borders = [];
    public campaigns: SelectItem[] = [];
    public crmTypes: SelectItem[] = [];
    public isUploading: boolean = false;
    public isUploaded: boolean = false;
    public uploadProgress: number = 0;
    public fileName: string = "";
    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
    public crmDto = {} as ICRMDto;
    public file = {} as IFileDto
    public fileList: FileList
    ngUnsubscribe: Subject<void> = new Subject();

    @ViewChild('uploader1') crmUploader: ElementRef<HTMLInputElement>;

    ngOnInit() {
        window.addEventListener("dragover", e => {
            e && e.preventDefault();
        }, false);
        window.addEventListener("drop", e => {
            e && e.preventDefault();
        }, false);
        this.campaignController.GetCampaignsByUser(this.authService.userSettings.id).subscribe(x => {
            if(!!x.length && x.length > 0)
            {
                this.campaigns = x.map(y => {
                    let campaign = {} as SelectItem;
                    campaign.label = y.name;
                    campaign.value = y.id;
                    return campaign;
                });
                this.campaigns.unshift({ value: null, label: "-" });
            }
        });

        this.crmTypes = this.enumMapToArray(new Map<number, IEnumDataAttribute>([...eCRMTypeName])).map(x => {
            let crmType = {} as SelectItem;
            crmType.value = x.value;
            crmType.label = x.label;
            return crmType;
        });
        this.crmTypes.unshift({ value: 0, label: "-" });
    }
    onDownload() {
        this.pageLoaderService.showPageLoader();
        this.crmDto.campaignId = Constants.GuidEmpty;
        var x = this.fileUploadService.GetCMRFile(this.crmDto.campaignId, "crm").pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            let file = new Blob([x as any], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(file, "Mogo-CRM-Data-Template.xlsx");
            this.pageLoaderService.hidePageLoader();
        });
    }
    @HostListener('drop', ['$event']) onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        let transfer = e.dataTransfer;
        this.selectFile(transfer.files);
    }
    selectFile(fileList: FileList, number?: number, width?: number, height?: number) {
        this.fileList = fileList;
        this.crmDto.file = {} as IFileDto;
        this.fileName = fileList[0].name;
        this.file.fileName = this.fileName;
        this.file.sizeInBytes = fileList[0].size;
        this.file.fileType = (fileList[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? 'xlsx' : (fileList[0].type == 'application/vnd.ms-excel' || fileList[0].type == 'text/csv') ? 'csv' : '';
        this.isUploaded = false;
        this.isUploading = false;
        const wrapper = { file: fileList[0] } as IFormFileWrapper;
        this.crmDto.fileWrapper = wrapper;
        if ((this.file.fileType == 'xlsx') || (this.file.fileType == 'csv'))
            this.uploadFile();
        else
            this.messageService.add({ severity: 'info', summary: 'File type is not allowed' });
    }
    handleReplace(event) {
        this.isUploaded = false;
        this.isUploading = false;
        this.crmUploader.nativeElement.value = null;
    }
    handleSaveandExit() {
        this.pageLoaderService.showPageLoader();
        this.crmDto.file = this.file;
        this.fileUploadService.UploadCRMAsync(this.crmDto).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'CRM Is  Saved.' })
            this.crmDto.fileId = x;
            this.crmDto.fileWrapper = {} as IFormFileWrapper;
            this.campaignController.CreateCRM(this.crmDto).subscribe(y => {
                setTimeout(() => this.router.navigate(['/mogo']), 3000);
                this.closeDialog.emit(true);
                this.isUploaded = false;
                this.isUploading = false;
                this.pageLoaderService.hidePageLoader();
                this.campaignService.setRefresh(true);
            });
        });
    }

    handleSaveandNext() {
        this.crmDto.file = this.file;
        this.pageLoaderService.showPageLoader();
        if (!!this.crmDto.crmType) {
            this.isUploaded = false;
            this.isUploading = false;
            this.fileUploadService.UploadCRMAsync(this.crmDto).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                this.crmDto.fileId = x;
                this.crmDto.fileWrapper = {} as IFormFileWrapper;
                this.campaignController.CreateCRM(this.crmDto).subscribe(y => {
                    this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'CRM Is Saved.' });
                    this.pageLoaderService.hidePageLoader();
                    this.campaignService.setRefresh(true);

                });
            });
        }
        else {
            this.messageService.add({ severity: 'error', detail: 'Please populate all necessary fields' });
            this.pageLoaderService.hidePageLoader();
        }
    }
    uploadFile() {
        let counter: number = 0;
        let uploadProgressDuration: number = 500;
        let uploadDuration: number = 3000;

        setTimeout(() => {
            this.isUploaded = true;
        }, uploadDuration);

        let uploadInterval = setInterval(() => {
            this.isUploading = true;
            counter++;
            this.uploadProgress = ((counter * uploadProgressDuration) / uploadDuration) * 100;
            if (this.uploadProgress >= 100) {
                this.isUploading = false;
                clearInterval(uploadInterval);
            }
        }, uploadProgressDuration);
    }
    handleCloseDialog() {
        this.closeDialog.emit(false);
    }

    private enumMapToArray(map: Map<number, IEnumDataAttribute>): SelectItem[] {
        let result: SelectItem[] = [];

        map.forEach((enumDataAttr, key) => {
            if (key != 0) result.push({ label: enumDataAttr.name, value: key });
        });

        return result;
    }
}