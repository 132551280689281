import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ChangePasswordFormComponent } from '../change-password-form/change-password-form.component';

@Component({
    selector: 'change-password-dialog',
    templateUrl: 'change-password-dialog.component.html'
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
    constructor() { }

    @Input() userId: string;
    @Input() displayDialog: boolean = false;
    @Input() dialogText: string = "";
    @Output() changePasswordDialogDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('changePasswordForm') changePasswordForm: ChangePasswordFormComponent;

    ngUnsubscribe: Subject<void> = new Subject();

    async ngOnInit() {
    }

    close() {
        this.displayDialog = false;
        this.changePasswordDialogDisplay.emit(this.displayDialog);
    }

    submit() {
        this.changePasswordForm.save();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}