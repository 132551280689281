import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    Input
} from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import {
    ISelectItem,
    IBaseCampaignDto,
    ICampaignImpressionFlightDto,
    IUserPropertiesDto
} from '@Workspace/_generated/interfaces';
import { Subject } from 'rxjs';
import { CampaignController } from '@Workspace/_generated/services';
import { DecimalPipe } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants, Functions } from '@Workspace/common';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AuthService } from '@Workspace/auth';
import { eUserRole } from '@Workspace/_generated/enums';

@Component({
    selector: 'impression-flights-dialog',
    templateUrl: './impression-flights-dialog.html',
    styleUrls: ['./impression-flights-dialog.scss'],
    providers: [DecimalPipe]
})
export class ImpressionFlightsDialog implements OnInit, OnDestroy {
    pssLines: any[];
    addOns: SelectItem[];
    selectedAddOns: SelectItem[] = [];
    totalImpresions: string;
    addOnImpressions: string;
    isVisible: boolean = false;
    creatives: any[];
    camapignStartDate: Date;
    camapignEndDate: Date;
    impressions: string;
    formGroup: UntypedFormGroup;
    impressionsFormGroup: UntypedFormGroup;
    showImpressionExceed: boolean = false;
    impressionExceedAmount: number = 0;
    lastPlaceholder: any;
    isAlignCreativeFlights: boolean = false;
    functions = Functions;
    currentUser: IUserPropertiesDto = this.authService.userSettings;
    enumUserRole = eUserRole;
    constants = Constants;
    numberOfImpressionFlights: number = 0;
    atLeastOneBuild:boolean = false;

    private ngUnsubscribe = new Subject();

    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    @Input() baseCampaign: IBaseCampaignDto;

    tommorowDate: Date = new Date(new Date().getUTCFullYear(), new Date().getMonth(), new Date().getDate()+1);
    todayDate: Date = new Date();

    constructor(
        public campaignController: CampaignController,
        private decimalPipe: DecimalPipe,
        public fb: UntypedFormBuilder,
        public authService: AuthService,
        private messageService: MessageService
    ) {}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initForm(): void {
        this.impressionsFormGroup = this.fb.group({
            impressionsArray: this.fb.array([])
        });
    }

    ngOnInit() {
        this.initForm();
    }

    get impressionsFlights() {
        return this.impressionsFormGroup.controls[
            'impressionsArray'
        ] as UntypedFormArray;
    }

    openDialog() {
        this.baseCampaign.campaignImpressionFlights?.forEach((element,index) => {
            this.isAlignCreativeFlights = element.alignCreativeFlights;
            this.formGroup = this.fb.group({
                id: [element.id],
                campaignId: [element.baseCampaignId],
                // disabled: [true],
                // impressionMinStartDate: [index == 0 ? this.camapignStartDate : moment(this.baseCampaign.campaignImpressionFlights[index-1].impressionEndDate, 'YYYY-MM-DD').add(1, 'days').toDate()],
                // impressionMaxStartDate: [moment(element.impressionEndDate, 'YYYY-MM-DD').add(-1, 'days').toDate()],
                // impressionMinEndDate: [moment(element.impressionStartDate, 'YYYY-MM-DD').add(1, 'days').toDate()],
                // impressionMaxEndDate:
                //  index != this.baseCampaign.campaignImpressionFlights.length - 1 ? 
                //  [moment(this.baseCampaign.campaignImpressionFlights[index+1].impressionStartDate, 'YYYY-MM-DD').add(-1, 'days').toDate()]
                //  : [new Date(this.baseCampaign.endDate)],
                 impressionMinStartDate: [new Date(this.baseCampaign.startDate)],
                 impressionMaxStartDate:  [new Date(this.baseCampaign.endDate)],
                 impressionMinEndDate:  [new Date(this.baseCampaign.startDate)],
                 impressionMaxEndDate: [new Date(this.baseCampaign.endDate)],
                impressionStartDate: index == 0 ? [new Date(this.baseCampaign.startDate), Validators.required] 
                    : element.impressionStartDate ? [new Date(element.impressionStartDate), Validators.required] : ['', Validators.required],
                impressionEndDate: index == this.baseCampaign.campaignImpressionFlights.length - 1 ? [new Date(this.baseCampaign.endDate), Validators.required] 
                    : element.impressionEndDate ? [new Date(element.impressionEndDate), Validators.required] : ['', Validators.required],
                impressionOrderNumber: [
                    element.impressionOrderNumber
                ],
                impression: [
                    element.impression,
                    Validators.required
                ],
                isBuilt: [element.isBuilt]
            });

            if (element.impressionOrderNumber == 1 || Functions.isTodayOrBefore(new Date(element.impressionStartDate))
                    || this.isAlignCreativeFlights || element.isBuilt == true) 
                {
                    this.formGroup.get('impressionStartDate').disable();
                } 
            if (element.impressionOrderNumber == this.baseCampaign.campaignImpressionFlights.length || Functions.isTodayOrBefore(new Date(element.impressionEndDate))
                    || this.isAlignCreativeFlights || element.isBuilt == true) 
                {
                    this.formGroup.get('impressionEndDate').disable();
                }
        
            this.impressionsFlights.push(this.formGroup);

            this.validateDatepicker();
        });

        this.impressions = this.decimalPipe.transform(this.baseCampaign.impressions);
        this.creatives = this.baseCampaign.creatives;
        this.numberOfImpressionFlights = Math.max(...this.baseCampaign.campaignImpressionFlights?.map(x => x.impressionOrderNumber));

        this.camapignEndDate = !!this.baseCampaign.endDate? new Date(this.baseCampaign.endDate): null;
        this.camapignStartDate = !!this.baseCampaign.startDate? new Date(this.baseCampaign.startDate): null;

        this.isVisible = true;

        this.atLeastOneBuild = this.baseCampaign.campaignImpressionFlights.some(_ => _.isBuilt == true);
    }

    cancel() {
        this.isVisible = false;
        this.isAlignCreativeFlights = false;
        this.lastPlaceholder = this.decimalPipe.transform(
            this.baseCampaign.impressions
        );
        this.impressionsFlights.clear();
        this.impressionsFlights.markAsPristine();
        this.impressionExceedAmount=0;
    }

    alignCreativeFlights(event) {
        this.isAlignCreativeFlights = event.checked;
        this.impressionsFlights.clear();
        this.numberOfImpressionFlights = 0;
        
        if (event.checked) {
            this.creatives = this.creatives.sort(function(a, b) {
                return a.creativeGroup - b.creativeGroup;
            });

            this.creatives.forEach((element, index) => {
                this.formGroup = this.fb.group({
                    id: [Constants.GuidEmpty],
                    campaignId: [this.baseCampaign.id],
                    disabled: [true],
                    impressionStartDate: [
                        !!element.flightStartDate
                            ? new Date(element.flightStartDate)
                            : !!this.baseCampaign.startDate
                            ? new Date(this.baseCampaign.startDate)
                            : null
                    ],
                    impressionEndDate: [
                        !!element.flightEndDate
                            ? new Date(element.flightEndDate)
                            : !!this.baseCampaign.endDate
                            ? new Date(this.baseCampaign.endDate)
                            : null
                    ],
                    impressionMinStartDate: [moment(new Date(), 'YYYY-MM-DD').add(1, 'days').toDate()],
                    impressionMaxStartDate: [moment(new Date(), 'YYYY-MM-DD').add(-1, 'days').toDate()],
                    impressionMinEndDate: [moment(new Date(), 'YYYY-MM-DD').add(1, 'days').toDate()],
                    impressionMaxEndDate: [moment(new Date(), 'YYYY-MM-DD').add(-1, 'days').toDate()],
                    impressionOrderNumber: [index],
                    impression: [this.impressionsFlights.value.length == 0 && this.camapignStartDate < this.todayDate ? 0 : null, Validators.required],
                    isBuilt: false

                });
                
                this.formGroup.controls['impressionStartDate'].disable({onlySelf: true});
                this.formGroup.controls['impressionEndDate'].disable({onlySelf: true});
                this.impressionsFlights.push(this.formGroup);
            });
        }
    }

    checkSum(event, index) {
        this.impressionExceedAmount = this.impressionsFormGroup.value.impressionsArray
            .map(function(a) {
                return a.impression;
            })
            .reduce((a, b) => parseInt(a) + parseInt(b ? b : 0), 0);
        this.showImpressionExceed =
            this.impressionExceedAmount != this.baseCampaign.impressions;

        
            this.lastPlaceholder = this.baseCampaign.impressions - this.impressionExceedAmount > 0 ? this.baseCampaign.impressions - this.impressionExceedAmount : null;
            this.lastPlaceholder = this.decimalPipe.transform(
                this.lastPlaceholder
            );
        
    }
    
    addImpressionDisabled() : boolean {
        let addImpressionDisabled =  
            this.baseCampaign == undefined ||
            this.isAlignCreativeFlights 
            || (this.impressionsFlights.controls.length > 1 && Functions.isTodayOrBefore(new Date(this.impressionsFlights.controls[this.impressionsFlights.length  - 1]?.get('impressionEndDate').value)));
  
        return addImpressionDisabled;
    }

    addImpression() {
        this.formGroup = this.fb.group({
            id: [Constants.GuidEmpty],
            campaignId: [this.baseCampaign.id],
            disabled: [true],
            impressionStartDate: [this.impressionsFlights.value.length == 0 ? this.camapignStartDate : '', Validators.required],
            impressionEndDate: [this.camapignEndDate, Validators.required],
            impressionMinStartDate: [new Date(this.camapignStartDate)],
            impressionMaxStartDate: [new Date(this.camapignEndDate)],
            impressionMinEndDate: [new Date(this.camapignStartDate)],
            impressionMaxEndDate: [new Date(this.camapignEndDate)],
            impressionOrderNumber: [''],
            impression: [this.impressionsFlights.value.length == 0 && this.camapignStartDate < this.todayDate ? 0 : null, Validators.required],
            isBuilt: false
        });

        if(this.impressionsFlights.value.length != 0 ){
            this.impressionsFlights.controls.slice(-1)[0]['controls']['impressionEndDate'].reset();
            this.impressionsFlights.controls[this.impressionsFlights.controls.length - 1].get('impressionEndDate').enable();
        } 
        else {
            this.formGroup.get('impressionStartDate').disable();
        }

        this.formGroup.get('impressionEndDate').disable();

        this.impressionsFlights.push(this.formGroup);

        this.numberOfImpressionFlights = this.numberOfImpressionFlights + 1;
        this.validateDatepicker();
    }


    removeImpression(event){
        if (event == 0) {
            this.impressionsFlights.controls[1].get("impressionStartDate").setValue(new Date(this.baseCampaign.startDate))
            this.impressionsFlights.controls[1].get("impressionStartDate").disable();
        } else if (event == this.impressionsFlights.controls.length - 1) {
            this.impressionsFlights.controls[event - 1].get("impressionEndDate").setValue(new Date(this.baseCampaign.endDate))
        }
        
        this.impressionsFlights.removeAt(event);
        this.checkSum(null, event);
        this.numberOfImpressionFlights = this.numberOfImpressionFlights - 1;
        this.validateDatepicker();
    }

    getMinStartDate(checkedFlightIndex: number) {
        let index = checkedFlightIndex - 1;
        let minStartDate = new Date(this.baseCampaign.startDate);
        while (index >= 0) {
            if (this.impressionsFlights.controls[index].get('impressionEndDate').value) {
                minStartDate = moment(this.impressionsFlights.controls[index].get('impressionEndDate').value, 'YYYY-MM-DD').add(1, 'days').toDate();
                return minStartDate;
            }
            if (this.impressionsFlights.controls[index].get('impressionStartDate').value) {
                minStartDate = moment(this.impressionsFlights.controls[index].get('impressionStartDate').value, 'YYYY-MM-DD').add(2, 'days').toDate();
                return minStartDate;
            }
            index--;
        }
        return minStartDate;
    }

    getMaxStartDate(checkedFlightIndex: number) {
        let index = checkedFlightIndex + 1;
        let maxStartDate = new Date(this.baseCampaign.endDate);
        while (index < this.impressionsFlights.controls.length) {
            if (this.impressionsFlights.controls[index].get('impressionStartDate').value) {
                maxStartDate = moment(this.impressionsFlights.controls[index].get('impressionStartDate').value, 'YYYY-MM-DD').add(-2, 'days').toDate();
                return maxStartDate;
            }
            if (this.impressionsFlights.controls[index].get('impressionEndDate').value) {
                maxStartDate = moment(this.impressionsFlights.controls[index].get('impressionEndDate').value, 'YYYY-MM-DD').add(-3, 'days').toDate();
                return maxStartDate;
            }
            index++;
        }
        return maxStartDate;
    }

    getMinEndDate(checkedFlightIndex: number) {
        let index = checkedFlightIndex - 1;
        let minEndDate = moment(new Date(this.baseCampaign.startDate), 'YYYY-MM-DD').add(2, 'days').toDate();
        while (index >= 0) {
            if (this.impressionsFlights.controls[index].get('impressionEndDate').value) {
                minEndDate = moment(this.impressionsFlights.controls[index].get('impressionEndDate').value, 'YYYY-MM-DD').add(2, 'days').toDate();
                return minEndDate;
            }
            if (this.impressionsFlights.controls[index].get('impressionStartDate').value) {
                minEndDate = moment(this.impressionsFlights.controls[index].get('impressionStartDate').value, 'YYYY-MM-DD').add(3, 'days').toDate();
                return minEndDate;
            }
            index--;
        }
        return minEndDate;
    }

    getMaxEndDate(checkedFlightIndex: number) {
        let index = checkedFlightIndex + 1;
        let maxEndDate = new Date(this.baseCampaign.endDate);
        while (index < this.impressionsFlights.controls.length) {
            if (this.impressionsFlights.controls[index].get('impressionStartDate').value) {
                maxEndDate = moment(this.impressionsFlights.controls[index].get('impressionStartDate').value, 'YYYY-MM-DD').add(-1, 'days').toDate();
                return maxEndDate;
            }
            if (this.impressionsFlights.controls[index].get('impressionEndDate').value) {
                maxEndDate = moment(this.impressionsFlights.controls[index].get('impressionEndDate').value, 'YYYY-MM-DD').add(-2, 'days').toDate();
                return maxEndDate;
            }
            index++;
        }
        return maxEndDate;
    }

    validateDatepicker(){
        this.impressionsFlights.controls.forEach((element ,index) =>  {
   
            // start min date
            element.get('impressionMinStartDate')
            .setValue(this.getMinStartDate(index));

            // start max date    
            element.get('impressionMaxStartDate')
            .setValue(
                element.get('impressionEndDate').value ?
                moment(element.get('impressionEndDate').value, 'YYYY-MM-DD').add(-1, 'days').toDate()
                : this.getMaxStartDate(index));

            // end min date
            element.get('impressionMinEndDate')
            .setValue(
                element.get('impressionStartDate').value ? 
                moment(element.get('impressionStartDate').value, 'YYYY-MM-DD').add(1, 'days').toDate()
                : this.getMinEndDate(index));

            // end max date
            element.get('impressionMaxEndDate')
            .setValue(this.getMaxEndDate(index)); 
        });
    }

    onFlightStartDateChange(event, index){
        // if(this.impressionsFlights.value.length != 0 ){
        //     if(moment(this.impressionsFlights.controls[index]['controls']['impressionEndDate'].value).isBefore(event)){ 
        //         this.impressionsFlights.controls[index]['controls']['impressionEndDate'].reset();
        //     }
        //     this.impressionsFlights.controls[index]['controls']['impressionMinEndDate'].patchValue(new Date(event.getUTCFullYear(), event.getMonth(), event.getDate()+1));
        // }
        this.validateDatepicker();
    }

    onFlightEndDateChange(event, index){
        // if(this.impressionsFlights.value.length != 0 ){
        //     this.impressionsFlights.controls[index + 1]['controls']['impressionMinStartDate'].patchValue(new Date(event.getUTCFullYear(), event.getMonth(), event.getDate()+1));
        //     this.impressionsFlights.controls[index + 1]['controls']['impressionMinEndDate'].patchValue(new Date(event.getUTCFullYear(), event.getMonth(), event.getDate()+2));

        //     if(moment(this.impressionsFlights.controls[index + 1].value.impressionStartDate).isSameOrBefore(event)){
        //         this.impressionsFlights.controls[index + 1]['controls']['impressionStartDate'].reset();
        //     }
        // }
        this.validateDatepicker();
    }
    
    impressionFlightsDatesInvalid(){
        let invalid: boolean = false;

        this.impressionsFormGroup.getRawValue().impressionsArray.forEach((element, index) => {
            if (index != 0 && index != this.impressionsFormGroup.getRawValue().impressionsArray.length - 1) {
                
                if (element.impressionEndDate.getTime() <= element.impressionStartDate) {
                    invalid = true;
                    return invalid;
                }  
                if (this.impressionsFormGroup.getRawValue().impressionsArray[index + 1].impressionStartDate.getTime() <= element.impressionEndDate) {
                    invalid = true;
                    return invalid;
                }
                
            }
        });

        return invalid;
    } 

    validateImpressions(i:number, flights) : boolean {
        return (((i==this.numberOfImpressionFlights || flights.getRawValue().id == Constants.GuidEmpty) && Functions.isTodayOrBefore(new Date(flights.getRawValue().impressionEndDate)))
                    || (i<this.numberOfImpressionFlights && (flights.getRawValue().isBuilt || (flights.getRawValue().id != Constants.GuidEmpty && Functions.isTodayOrBefore(new Date(flights.getRawValue().impressionStartDate))))))
    }

    canRemove(flights, checkBoxAlign): boolean{
        return !checkBoxAlign.model && !Functions.isTodayOrBefore(new Date(flights.getRawValue().impressionStartDate)) && !flights.getRawValue().isBuilt;
    }

     save() {
         if (this.impressionFlightsDatesInvalid()) {            
             this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Not Saved. Impresion flights data is not valid.' });
         } else {
             let impressionSendData: ICampaignImpressionFlightDto[] = [];
     
             this.impressionsFormGroup.getRawValue().impressionsArray.forEach((element,index) => {
                 impressionSendData.push({
                     id: element.id,
                     baseCampaignId: element.campaignId,
                     impression: element.impression,
                     impressionEndDate: index == this.impressionsFormGroup.value.impressionsArray.length - 1 ? new Date(this.baseCampaign.endDate) : element.impressionEndDate,
                     impressionStartDate: index == 0 ? new Date(this.baseCampaign.startDate) : new Date(element.impressionStartDate),
                     impressionOrderNumber: index + 1,
                     alignCreativeFlights: this.isAlignCreativeFlights,
                     isBuilt: element.isBuilt
                 });
                 Functions.dateTimeZoneConversion(impressionSendData[index].impressionStartDate);
                 Functions.dateTimeZoneConversion(impressionSendData[index].impressionEndDate);
             });
     
             if(!_.isEqual(this.baseCampaign.campaignImpressionFlights,impressionSendData)){
                 this.onSave.emit(impressionSendData);
                 this.impressionsFlights.markAsPristine();
             }
             this.isVisible = false;
         }
     }
}
