<p-dialog [modal]="true" [(visible)]="isVisible">
    <p-header>{{header}}</p-header>


    <!-- <ng-template> -->
    <span>
        <div class="mb-2 row align-items-center">
            <div class="col-md-5 font-weight-bold">
                Payment completed
            </div>
            <div class="col-md-4">
                <p-inputSwitch [(ngModel)]="paymentStatus"></p-inputSwitch>
            </div>
        </div>
    </span>

    <!-- </ng-template> -->


    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button (click)="save()" pButton class="px-3 p-button-primary" [disabled]='!canSave' label="Save"></button>
        </div>
    </p-footer>
</p-dialog>