<div class="col-md-9 mx-auto p-3 bg-white rounded shadow-sm border-bottom py-4">
    <h3 class="first">Campaign Assets</h3>
    <h6 class="border-gray mb-0 text-muted ">Download, view or edit campaign assets</h6>
    <div class="pt-4 d-flex flex-wrap col-md-12">
        <div *ngIf="baseCampaign.campaignStatusId != enumCampaignStatus.Assets_Pending && baseCampaign.campaignStatusId != enumCampaignStatus.Campaign_Draft" class="col-md-6 py-2 d-flex flex-row">
            <label class=" my-auto col-md-6">Zip Folder Of Assets</label>
            <button (click)="downloadAssetsZip()" style="width:100%" type="button" class="btn btn-info">Download</button>
        </div>

        <div *ngIf="!currentUser.permissions.isClient && baseCampaign.campaignBuildUrl" class="col-md-6 py-2 d-flex flex-row">
            <label class="my-auto col-md-6">Campaign Manager</label>
            <button type="button" style="width:100%" (click)="redirect(baseCampaign.campaignBuildUrl)" class="btn btn-info">View Campaign</button>
        </div>

        <div *ngIf="currentUser.permissions.isClient && !!file" class="col-md-6 py-2 d-flex flex-row">
            <label class="my-auto col-md-6">Campaign Report Document</label>
            <button type="button" style="width:100%" (click)="redirectReport()" class="btn btn-info">Download</button>
        </div>
        <div *ngIf="currentUser.permissions.isClient && !!baseCampaign.reportLink" class="col-md-6 py-2 d-flex flex-row">
            <label class="my-auto col-md-6">Campaign Report Link</label>
            <button type="button" style="width:100%" (click)="redirectReport(baseCampaign.reportLink)" class="btn btn-info">View</button>
        </div>
        <div class="col-md-6 py-2 d-flex flex-row">
            <label class=" my-auto col-md-6">Campaign Assets</label>
            <button (click)="OnViewCampaign()" style="width:100%" type="button" class="btn btn-info">View/Edit</button>
        </div>

        <div *ngIf="!currentUser.permissions.isClient && baseCampaign.traffickingGridUrl" class="col-md-6 py-2 d-flex flex-row">
            <label class="my-auto col-md-6">{{baseCampaign.account.isLearfieldCampaign?'Campaign SDF':'Campaign Trafficking'}}</label>
            <button type="button" style="width:100%" (click)="redirect(baseCampaign.traffickingGridUrl)" class="btn btn-info">View Sheet</button>
        </div>
        <div *ngIf="!currentUser.permissions.isClient && baseCampaign.ttdBuildUrl" class="col-md-6 py-2 d-flex flex-row">
            <label class="my-auto col-md-6">TTD Campaign Build</label>
            <button type="button" style="width:100%" (click)="redirect(baseCampaign.ttdBuildUrl)" class="btn btn-info">View</button>
        </div>
        <div *ngIf="!currentUser.permissions.isClient && baseCampaign.ttdVideoBuildUrl" class="col-md-6 py-2 d-flex flex-row">
            <label class="my-auto col-md-6">TTD Video Campaign Build</label>
            <button type="button" style="width:100%" (click)="redirect(baseCampaign.ttdVideoBuildUrl)" class="btn btn-info">View</button>
        </div>
        <div *ngIf="!currentUser.permissions.isClient && baseCampaign.dV360CampaignURL" class="col-md-6 py-2 d-flex flex-row">
            <label class="my-auto col-md-6">DV360 Campaign Build</label>
            <button type="button" style="width:100%" (click)="redirect(baseCampaign.dV360CampaignURL)" class="btn btn-info">View</button>
        </div>
    </div>
</div>