import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MenuItem, SelectItem, TreeNode, MessageService } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eStandardCampaginChannel, eChannel, eChannelType, eFileType, eUserRole, eVideoType, eAccountType } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FileUpload } from '../../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IFacebookDetailsJson, IFacebookNewsFeedDetailJson, IInStreamVideoJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { Functions, LookUps, Constants } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'native-image',
    templateUrl: './native-image.component.html',
    styleUrls: ['./native-image.component.scss']
})
export class NativeImageComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() landingPage: string;
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    isPreviewOpen: boolean;
    currentIndex: number;
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isUploadOk: boolean = true;
    constants = Constants;
    isLandingUrlOk: boolean = true;
    titleWrongUrl: string = 'Please enter a valid Url.';
    functions = Functions;
    maxNumberOfFlights: number = 1;
    currentUser = this.authService.userSettings;
    enumAccountType = eAccountType;

    ngOnInit() {
        this.creative.creativeAssets.forEach(x => {
            if (!!x.file && !!x.file.urlWithToken)
                this.creativeAssetsForPreview.push(x);
        });

        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));
        //this.campaign.creatives.sort((x, y) => { return y.creativeGroup - x.creativeGroup })[0].creativeGroup;
        
        this.creative.creativeAssets.sort((x, y) => { return <any>new Date(x.createdOn) - <any>new Date(y.createdOn) });

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId && x.creativeGroup == this.creative.creativeGroup);
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }

    validationLandingPageMessage() {
        let textToMacth = this.landingPage ? this.landingPage : this.creative.detailJson.landingPageUrl;
        // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?%#[\]@!\$&'\(\)\*\+,;=.]+$/;
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        var found = textToMacth.match(regex);
        this.isLandingUrlOk = true;
        if (found == null) {
            this.isLandingUrlOk = false;
        }
    }
}
