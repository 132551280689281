import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { eCampaignStatus, eStandardCampaginChannel, eInventoryTypeOAS } from '@Workspace/_generated/enums';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IDetailJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Constants, Functions } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { CreativeSwapsDialog } from '../../../../../shared-core/components/creative-swaps-dialog/creative-swaps-dialog';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Component({
    selector: 'oas',
    templateUrl: './oas.component.html',
    styleUrls: ['./oas.component.scss'],
    providers: [DatePipe, DecimalPipe]
})
export class OASComponent implements OnInit, OnDestroy {
    constructor(
        private cardItemService: CartItemService,
        private authService: AuthService,
        private dateTime: DatePipe,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() landingPage: string;
    @Input() validationLandingUrlMessageText: string;
    @Input() premiumCreative: ICreativeDto;
    @Input() index: number;

    @ViewChild('creativeSwaps') creativeSwaps: CreativeSwapsDialog;

    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    @Output() onCreativetDelete: EventEmitter<any> = new EventEmitter();
    @Output() onDateChange: EventEmitter<any> = new EventEmitter();
    enumCampaignStatus = eCampaignStatus;
    isPreviewOpen: boolean;
    currentIndex: number;
    //creativeAssetsForPreview: ICreativeAssetDto[] = [];
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isUploadOk: boolean = true;
    isLandingUrlOk: boolean = true;
    constants = Constants;
    currentUser = this.authService.userSettings;
    creativeName: string;
    _creativeAssets: ICreativeAssetDto[] = [];
    displaySizesPageTakeover: string = "Accepted sizes: 300 x 250,\n 970 x 90,\n 320 x 50,\n 728 x 90,\n 300 x 600,\n 970 x 250,\n 320 x 480,\n 970 x 66";
    displaySizesPremiumPageTakeover: string = "Accepted sizes: 450 x 1100,\n 1600 x 1600,\n 900 x 380,\n 1440 x 380,\n 900 x 150,\n 2600 x 2600,\n 300 x 250,\n 970 x 90,\n 320 x 50,\n 728 x 90,\n 300 x 600,\n 970 x 250,\n 320 x 480,\n 970 x 66,\n 1920 x 350,\n 1920 x 120,\n 1024 x 512,\n 1024 x 150";
    displaySizesGeoDemo: string = "Accepted sizes: 300 x 250,\n 970 x 90,\n 320 x 50,\n 728 x 90,\n 300 x 600,\n 970 x 250,\n 320 x 480";
    videoSizes: string = "Accepted sizes: 640 x 480v,\n 640 x 483v";
    entitlementSizes: string = "Accepted size: 150 x 50";
    displaySizesTradeDeal: string = "Accepted sizes: 300 x 250,\n 320 x 50,\n 728 x 90,\n 970 x 90";
    displaySizesShareOfVoice: string = "Accepted sizes: 300 x 250,\n 970 x 90,\n 320 x 50,\n 728 x 90,\n 300 x 600,\n 970 x 250";
    defaultSizes: string = "";
    display: boolean = false;
    video:boolean = false;
    entitlement:boolean = false;
    geoDemoTarget : boolean = false;
    pageTakeover : boolean = false;
    premiumPageTakeover : boolean = false;
    rosDisplay : boolean = false;
    tradeDeal: boolean = false;
    shareOfVoice: boolean = false;
    numberOfAllowedSizes: number;
    defaultNumberOfAllowedSizes: number;
    creativeNumber:number;
    campaignDates = '';
    functions = Functions;

    ngOnInit() {

        // this.creative.creativeAssets.forEach(x => {
        //     if (!!x.file && !!x.file.urlWithToken)
        //         this.creativeAssetsForPreview.push(x);
        // })
        this.creativeNumber = this.campaign.creatives.indexOf(this.creative);

        if(this.creative.creativeTypeId == eStandardCampaginChannel.OAS_Display)
            this.display = true;
        else if(this.creative.creativeTypeId == eStandardCampaginChannel.OAS_Video)
            this.video = true;

        if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Entitlement)
            this.entitlement = true;   
        else if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Geo_Demo_Targeted)
            this.geoDemoTarget = true; 
        else if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover)
            this.pageTakeover = true; 
        else if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium)
            this.premiumPageTakeover = true;
        else if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Ros_Display_Campaign)
            this.rosDisplay = true;  
        else if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Trade_Deal)
            this.tradeDeal = true;
        else if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Share_Of_Voice)
            this.shareOfVoice = true;

        this.numberOfAllowedSizes = this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.numberOfAllowedSizes;
        this.defaultNumberOfAllowedSizes = this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.defaultNumberOfAllowedSizes;
        
        if (this.creative.creativeAssets.some(x => x.width == 1800 && x.height == 450)) {
            this.creative.creativeAssets.filter(x => x.width == 1800 && x.height == 450).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 500 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 500 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 500 && x.height == 100)) {
            this.creative.creativeAssets.filter(x => x.width == 500 && x.height == 100).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 450 && x.height == 1100)) {
            this.creative.creativeAssets.filter(x => x.width == 450 && x.height == 1100).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 1600 && x.height == 1600)) {
            this.creative.creativeAssets.filter(x => x.width == 1600 && x.height == 1600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 900 && x.height == 380)) {
            this.creative.creativeAssets.filter(x => x.width == 900 && x.height == 380).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 1440 && x.height == 380)) {
            this.creative.creativeAssets.filter(x => x.width == 1440 && x.height == 380).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 900 && x.height == 150)) {
            this.creative.creativeAssets.filter(x => x.width == 900 && x.height == 150).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 2600 && x.height == 2600)) {
            this.creative.creativeAssets.filter(x => x.width == 2600 && x.height == 2600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 120 && x.height == 35)) {
            this.creative.creativeAssets.filter(x => x.width == 120 && x.height == 35).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        // if (this.creative.creativeAssets.some(x => x.width == 1280 && x.height == 100)) {
        //     this.creative.creativeAssets.filter(x => x.width == 1280 && x.height == 100).forEach(el => {
        //         this._creativeAssets.push(el);
        //     });
        // }

        if (this.creative.creativeAssets.some(x => x.width == 160 && x.height == 600)) {
            this.creative.creativeAssets.filter(x => x.width == 160 && x.height == 600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 300 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 300 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 300 && x.height == 600)) {
            this.creative.creativeAssets.filter(x => x.width == 300 && x.height == 600).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 180)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 180).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 480)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 480).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 320 && x.height == 50)) {
            this.creative.creativeAssets.filter(x => x.width == 320 && x.height == 50).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 728 && x.height == 90)) {
            this.creative.creativeAssets.filter(x => x.width == 728 && x.height == 90).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 250)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 250).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 66)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 66).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 970 && x.height == 90)) {
            this.creative.creativeAssets.filter(x => x.width == 970 && x.height == 90).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 640 && x.height == 480)) {
            this.creative.creativeAssets.filter(x => x.width == 640 && x.height == 480).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 640 && x.height == 483)) {
            this.creative.creativeAssets.filter(x => x.width == 640 && x.height == 483).forEach(el => {
                this._creativeAssets.push(el);
            });
        }

        if (this.creative.creativeAssets.some(x => x.width == 150 && x.height == 50)) {
            this.creative.creativeAssets.filter(x => x.width == 150 && x.height == 50).forEach(el => {
                this._creativeAssets.push(el);
            });
        }
        
        if (this.creative.creativeAssets.some(x => !this._creativeAssets.includes(x)))
        {
            this.creative.creativeAssets.filter(x => !this._creativeAssets.includes(x)).forEach(el => {
                this._creativeAssets.push(el);
            });
        }
        
        this.creativeName = this.creative.creativeTypeId == eStandardCampaginChannel.OAS_Display ? "Standard Display Banner" : 
            (eStandardCampaginChannel.OAS_Video ? "Video" : '');

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                //this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId);
                this.isUploadOk = true;

                this.isLandingUrlOk = true;
                if (!!!data.creatives) {
                    return;
                }
                this.creative.creativeAssets.forEach(ca => {
                    if (!!this.landingPage && !!ca.file) {

                        ca.imageUrl = this.landingPage;
                        ca.isLandingPageExist = true;
                        this.isLandingUrlOk = true;
                    }
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                    if (ca.isLandingPageExist != null || ca.isLandingPageExist != undefined)
                        this.isLandingUrlOk = this.isLandingUrlOk && ca.isLandingPageExist;
                });
            });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }
    checkIsFileUploaded() {
        if (this.creative.creativeAssets.some(x => x.fileAdditional != null && x.file == null)) {

            return true;
        }
        else {
            return false;
        }
    }

    validationLandingPageMessage(event: any) {
        this.validationLandingUrlMessageText = event;
    }

    delete(){
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null))
        {
            this.creativeSwaps.openDialog(
                this.campaign, this.creative.creativeGroup, 'Remove');
        }
    }

    edit(){
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null))
        {
            if(this.creativeSwaps){
                this.creativeSwaps.openDialog(
                    this.campaign, this.creative.creativeGroup, 'Edit');
            }
        }
    }

    addCreativeAsset() {
        if (this.creative.creativeAssets.length < this.numberOfAllowedSizes 
            && this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null)) {

            let newAsset = {} as ICreativeAssetDto;
            newAsset.id = Constants.GuidEmpty;
            newAsset.width = 0;
            newAsset.height = 0;
            newAsset.isValid = null;
            newAsset.creativeId = this.creative.id;
            newAsset.fileType = null;
            newAsset.fileSize = 0;
            newAsset.imageUrl = null;
            newAsset.fileName = null;
            newAsset.url = null;
            newAsset.isChanged = null;
            newAsset.isUploadValid = null;
            newAsset.isDeleted = null;

            if (newAsset.detailJson == null) {
                newAsset.detailJson = {} as IDetailJson;

                newAsset.detailJson.headline = '';
                newAsset.detailJson.bodyText = [];
                newAsset.detailJson.bodyText.push('');
                newAsset.detailJson.linkDescription = '';
                newAsset.detailJson.landingPageUrl = '';
                newAsset.detailJson.facebookPageUrl = '';
                newAsset.detailJson.videoType = 0;
                // newAsset.detailJson.destinationUrl = '';
                newAsset.detailJson.campaignTitle = '';
                newAsset.detailJson.advertiser = '';
                newAsset.detailJson.companyName = '';
                newAsset.detailJson.shortTitle = '';
                newAsset.detailJson.longTitle = '';
                newAsset.detailJson.shortDescription = '';
                newAsset.detailJson.longDescription = '';
                newAsset.detailJson.callToAction = '';
                newAsset.detailJson.headline2 = '';
                newAsset.detailJson.description = '';
                newAsset.detailJson.path1 = '';
                newAsset.detailJson.path2 = '';
                newAsset.detailJson.displayUrl = '';
                newAsset.detailJson.adGroup = '';
                newAsset.detailJson.destinationUrl = '';
            }
            this.creative.creativeAssets.push(newAsset);

        }
    }

    removeAsset(asset: ICreativeAssetDto) {
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null)){
            let index = this.creative.creativeAssets.indexOf(asset);
            this.creative.creativeAssets.splice(index, 1);
        }
    }

    editCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged) {
            this.creative.flightStartDate = event.startDate;
        }
        if (event.isEndDateChanged) {
            this.creative.flightEndDate = event.endDate;
        }

        this.creative.creativeName = event.creativeName;

        this.campaignDates = `${this.formatDate(
            (this.campaign.startDate)
        )} - ${this.formatDate(this.campaign.endDate)}`;
    }

    removeCampaignDatesSaved(event: any) {
        if (event.isStartDateChanged || event.isEndDateChanged) {
            this.onCreativetDelete.emit({ baseCampaign: event.baseCampaign, creativeGroup: this.creative.creativeGroup });
        }
    }

    formatDate(dateProp: any) {
        var date = new Date(dateProp);
        return `${this.dateTime.transform(dateProp, this.constants.DEFAULT_FORMAT_DATE_US)}`;
    }

}
