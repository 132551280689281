<p-dialog [modal]="true" [contentStyle]="{'overflow': 'visible'}" [(visible)]="isVisible">
    <p-header>Campaign Dates</p-header>

    <!-- <ng-template> -->

    <div class="mb-2 mt-2 row align-items-center">
        <div class="col-md-12">
            <p-calendar (onSelect)="onStartDateChange($event)" appendTo="body" [minDate]="minStartDate" [maxDate]="maxStartDate" [(ngModel)]="startDate"></p-calendar> <span class="ml-2 mr-4">-</span>
            <p-calendar (onSelect)="onEndDateChange($event)" appendTo="body" [disabled]="startDate == null || disableEndDate" [minDate]="minEndDate" [maxDate]="maxEndDate" [(ngModel)]="endDate"></p-calendar>
        </div>
    </div>
    <div class="col-md-12 mt-2 font-weight-bold">
        All impressions will deliver in full within the date range selected.
    </div>
    <div *ngIf="!!validationMessage" class=" col-md-10 mb-2 mt-2 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">{{validationMessage}}</span>
    </div> 
    <!-- </ng-template> -->

    <p-footer>
        <div class="mb-3">
            <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
            <button [disabled]="!startDate || !endDate || !!validationMessage" (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>