import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MenuItem, SelectItem, TreeNode, MessageService } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eStandardCampaginChannel, eContentType, eSweepstakesCategory, eCreativeTheme, eSweepstakesCategoryName } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { EnumStringPipe } from '@Workspace/pipes';
import { FileUpload } from '../../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IFacebookDetailsJson, IFacebookNewsFeedDetailJson, IInStreamVideoJson, IDetailJson, ISelectItem, IDetailSocialCampaignJson, IEnumDataAttribute } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { FileUploadService } from '../../../../services/file-upload.service';
import { Functions, LookUps, Constants } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../../../mogo/services/campaign.service';
import { constants } from 'os';

@Component({
    selector: 'social-content-creation',
    templateUrl: './social-content-creation.component.html',
    styleUrls: ['./social-content-creation.component.scss']
})
export class SocialContentCreation implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
        private campaignService: CampaignService
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() campaign: IBaseCampaignDto;

    @Output() Basecampaign: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    enumCampaignStatus = eCampaignStatus;
    isPreviewOpen: boolean;
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    constants = Constants;
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    isUploadOk: boolean = true;
    creativeThemeDD: SelectItem[] = [];
    creativeThemeLabel: string;
    sweepstakesCategoryDD: SelectItem[] = [];
    sweepstakesCategoryLabel: string;
    contentTypeDD: SelectItem[] = [];
    contentTypeLabel: string;
    
    currentUser = this.authService.userSettings;
    creativeTypes:any = eStandardCampaginChannel;
    
    creativeName: string;

    ngOnInit() {

        this.creativeName = new EnumStringPipe().transform(this.creative.creativeTypeId, eStandardCampaginChannel).replace('Social ', '');
        
        if (this.creative.detailSocialCampaignJson == null) {
            this.creative.detailSocialCampaignJson = {} as IDetailSocialCampaignJson;
            this.creative.detailSocialCampaignJson.campaignTitle = '';
            this.creative.detailSocialCampaignJson.conceptObjective = '';
            this.creative.detailSocialCampaignJson.detailedPrizeDescription = '';
            this.creative.detailSocialCampaignJson.totalEstimatePrizeValue = '';
            this.creative.detailSocialCampaignJson.notesCreativeGraphics = '';
            this.creative.detailSocialCampaignJson.creativeTheme = 0;
        }

        if(!!!this.creative.detailSocialCampaignJson.campaignTitle)
            this.creative.detailSocialCampaignJson.campaignTitle = this.campaign.name;
        
        this.creative.creativeAssets.sort((x, y) => { return <any>new Date(x.createdOn) - <any>new Date(y.createdOn) });

        this.creative.creativeAssets.forEach(x => {
            if (!!x.file && !!x.file.urlWithToken)
                this.creativeAssetsForPreview.push(x);
        });

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                //this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId);
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            });

        this.creativeThemeLabel = Functions.getEnumName(eCreativeTheme, this.creative.detailSocialCampaignJson.creativeTheme);
        this.creativeThemeDD = LookUps.getCreativeThemeEnum();
        this.creativeThemeDD.unshift({ label: "-", value: 0 });

        this.sweepstakesCategoryLabel = Functions.getEnumName(eSweepstakesCategory, this.creative.detailSocialCampaignJson.additionalEngagementElement);
        //this.sweepstakesCategoryDD = LookUps.getSweepstakesCategoryEnum();
        

        var sweepstakesCategoryDDFromMap = new Map<number, IEnumDataAttribute>([...eSweepstakesCategoryName]);
        this.sweepstakesCategoryDD = this.enumMapToArray(sweepstakesCategoryDDFromMap)
        this.sweepstakesCategoryDD.unshift({ label: "-", value: 0 });

        this.contentTypeLabel = Functions.getEnumName(eContentType, this.creative.detailSocialCampaignJson.contentType);
        this.contentTypeDD = LookUps.getContentTypeEnum();
        this.contentTypeDD.unshift({ label: "-", value: 0 });
        
    }

    private enumMapToArray(map: Map<number, IEnumDataAttribute>): any[] {
        let result: any[] = [];
        map.forEach(((enumDataAttr, key) => {
            if (key!=0)
               result.push({ label: enumDataAttr.name, value: key, quantity: 0 })
        }
        ));


        return result;

    }

    setCreativeTheme(event) {
        this.creativeThemeLabel = Functions.getEnumName(eCreativeTheme, event.value);
    }
    setSweepstakesCategory(event) {
        this.sweepstakesCategoryLabel = Functions.getEnumName(eSweepstakesCategory, event.value);
    }
    setContentType(event) {
        this.contentTypeLabel = Functions.getEnumName(eContentType, event.value);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    openPreview(event) {
        this.openPreviewEvent.emit(event);
        
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }

    removeAsset(asset: ICreativeAssetDto) {
        let index = this.creative.creativeAssets.indexOf(asset);
        this.creative.creativeAssets.splice(index, 1);
    }

}
