import { ElementRef, Directive, AfterViewInit, Renderer2, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[truncateText]'
})
export class TruncateTextDirective implements AfterViewInit {


    @Output() onEllipsisAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        setTimeout(() => this.addEllipsis(), 50);
    }

    @HostListener("window:resize")
    onWindowResize(){
        setTimeout(() => this.addEllipsis(), 50);
    }

    private addEllipsis() {
        let textElement = this.elementRef.nativeElement.querySelector('span');

        if (!!textElement) {
            this.renderer.addClass(textElement, "truncate");
            if (
                this.elementRef.nativeElement.offsetWidth <
                    textElement.scrollWidth ||
                this.elementRef.nativeElement.offsetWidth <
                    textElement.offsetWidth
            ) {
                this.renderer.addClass(textElement, "d-block");
                this.renderer.removeClass(textElement, "d-inherit");
                this.onEllipsisAdded.emit(true);
            }

            else{
                this.renderer.addClass(textElement, "d-inherit");
                this.renderer.removeClass(textElement, "d-block");
                this.onEllipsisAdded.emit(false);
            }
        }
    }
}
