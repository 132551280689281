import { Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

import { Subscription } from 'rxjs';
import { PageLoaderService } from '@Workspace/services';

@Directive({
    selector: '[fullPageLoader]'
})
export class FullPageLoaderDirective implements OnDestroy {
    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private pageLoaderService: PageLoaderService
    ) {
        this.sub = this.pageLoaderService.isPageLoading.subscribe(
            (isLoading: boolean) => {
                if (isLoading) {
                    this.renderer.addClass(
                        this.el.nativeElement,
                        'full-page-loader'
                    );
                } else {
                    this.renderer.removeClass(
                        this.el.nativeElement,
                        'full-page-loader'
                    );
                }
            }
        );
    }

    private sub: Subscription;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
