<div class="main-content">
    <div class="row mt-3 mx-3">
        <div class="left-pane pl-0 col-lg-3 col-12 flex-wrap pr-0">
            <div class="card card-body">
                <div *ngFor="let page of instructionPages; let i = index" (click)="openCard(page.orderNumber)" class="selectable p-2 d-flex justify-content-between" [ngClass]="{'selected': currentCard === i}">

                    <div>{{ page.title }}</div>
                    <div *ngIf="currentUser.permissions.isAdmin" class="edit-buttons-holder">
                        <i (click)="onPageEditClick(page)" class="fas fa-pencil-alt edit-icon"></i>
                        <i (click)="onPageRemoveClick(page)" class="fas fa-trash-alt delete-icon"></i>
                    </div>
                </div>
            </div>
            <div *ngIf="currentUser.permissions.isAdmin" class="add-instruction">
                <button (click)="onPageAddClick()" pButton class="p-button-primary text-uppercase" label="Add new"></button>
            </div>
        </div>

        <div class="right-pane col-lg-9 col-12 d-flex flex-wrap mt-lg-0 mt-3 px-lg-3 px-0">
            <div class="card card-body content-wrapper">
                <h4 class="my-4 font-weight-bold">{{ currentTitle }}</h4>
                <div #instructionContent></div>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>
<edit-instructions-dialog #editDialog (onSaved)="onPageEdited($event)"></edit-instructions-dialog>