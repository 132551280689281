<div [ngStyle]="!isNoteValid && _header == 'Campaign Dates' ? {'background': '#fde7ec' } : ''" class=" campaign-card-container d-flex flex-column p-3 w-100">
    <div class="campaign-card-header d-flex justify-content-between">
        <div class=" break-word">
            {{ _header  | uppercase }}
            <span *ngIf='_header == "Demographics" || _header == "Geographic"' tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='_header == "Demographics" ? constants.demographicsInfo : constants.geographicsInfo' class="fal fa-info-circle info"></span>
            <span *ngIf='_header == "Additional Settings"' tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.additionalSettingsInfo' class="fal fa-info-circle info"></span>
            <span *ngIf='_header == "Landing Page"' tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.singleLandingPage' class="fal fa-info-circle info"></span>
        </div>

        <div *ngIf="editButton" class="campaign-edit-button" (click)="editClicked(_header)"><a>{{_header == "Inventory items" ? 'SEE ALL' : 'EDIT'}}</a></div>
    </div>
    <!-- Header is the same only the body changes depending on the card type -->
    <div *ngIf="cardType=='generic' || cardType=='inventoryAddOn'">
        <div *ngIf="isContentArray">
            <div *ngFor="let item of _content" class="campaign-card-content">{{ item }}{{_header == "Impressions" && isShareOfVoice && !!item && !item.includes('N/A') ? '%' : ''}}</div>
        </div>
        <div *ngIf="_content && !isContentArray" class="campaign-card-content ">{{ _content }}</div>
        <div *ngIf="!_content || !_content.length || (!_content[0] && !_content[1])"> - </div>
    </div>
    <div *ngIf="cardType=='social'">
        <div *ngIf="isContentArray && _content.length>0 && (!!_content[0] || !!_content[1])">
            <div class="campaign-card-content" *ngIf="!!_content[0]"><span style="font-weight: bold;">Name: </span><span>{{ _content[0] }}</span></div>
            <div class="campaign-card-content" *ngIf="!!_content[1]"><span style="font-weight: bold;">ID: </span><span>{{ _content[1] }}</span></div>
            <div class="campaign-card-content" *ngIf="!!_content[2]"><span style="font-weight: bold;">URL: </span><span>{{ _content[2] }}</span></div>
        </div>
        <div *ngIf="_content && !isContentArray" class="campaign-card-content  ">{{ _content }}</div>
        <div *ngIf="!_content || !_content.length || (!_content[0] && !_content[1])"> - </div>
    </div>
    <div *ngIf="cardType =='contact'">
        <div *ngIf="!!_content">
            <div class="campaign-card-content">
                <span>{{ _content[0] }}</span>
                <span *ngIf="isIconVisible" class="pi pi-envelope" style="margin-left: 5px; font-size: unset;" [title]="_content[1]"></span>
            </div>
            <div #contactDiv [title]="_content[1]" class="campaign-card-content">
                <span *ngIf="!isIconVisible">{{_content[1]}}</span>
                <br>
                <span #contactSpan style="visibility: hidden;">{{ _content[1] }}</span>
            </div>
            <div *ngIf="!!contactInfo" class="invalid-message-box d-flex align-items-center" style="min-height: 3em;">
                <span class="px-2 py-1">{{contactInfo}}</span>
            </div>
        </div>
        <div *ngIf="!_content" class="campaign-card-content"> - </div>
    </div>
    <div *ngIf="cardType=='advertiser'">
        <div *ngIf="isContentArray && _content.length==2 && (!!_content[0] || !!_content[1])">
            <div class="campaign-card-content" *ngIf="!!_content[0]"><span style="font-weight: bold;">ID: </span><span>{{ _content[0] }}</span></div>
            <div class="campaign-card-content" *ngIf="!!_content[1]"><span style="font-weight: bold;">Name: </span><span>{{ _content[1] }}</span></div>
        </div>
        <div *ngIf="isContentArray && _content.length==5 && _content[4] == enumAccountType.FAN365 && (!!_content[0] || !!_content[1] || !!_content[2])">
            <div class="campaign-card-content" *ngIf="!!_content[0]"><span style="font-weight: bold;">GCM Advertiser ID: </span><span>{{ _content[0] }}</span></div>
            <div class="campaign-card-content" *ngIf="!!_content[1]"><span style="font-weight: bold;">TTD Advertiser ID: </span><span style="overflow: hidden; text-overflow: ellipsis;" title={{_content[1]}}>{{ _content[1] }}</span></div>
            <div class="campaign-card-content" *ngIf="!!_content[2]"><span style="font-weight: bold;">DV360 Advertiser ID: </span><span style="overflow: hidden; text-overflow: ellipsis;" title={{_content[2]}}>{{ _content[2] }}</span></div>
        </div>
        <div *ngIf="isContentArray && _content.length == 5 && _content[4] == enumAccountType.OAS && (!!_content[3] || !!_content[0])">
            <div class="campaign-card-content" *ngIf="!!_content[0]"><span style="font-weight: bold;">GCM Advertiser ID: </span><span>{{ _content[0] }}</span></div>
            <div class="campaign-card-content" *ngIf="!!_content[3]"><span style="font-weight: bold;">GAM Advertiser ID: </span><span>{{ _content[3] }}</span></div>
        </div>
        <div *ngIf="_content && !isContentArray" class="campaign-card-content">{{ _content }}</div>
        <div *ngIf="!_content || !_content.length || (_content[4] == enumAccountType.FAN365 && !_content[0] && !_content[1]) || (_content[4] == enumAccountType.OAS && !_content[0] && !_content[3])"  class="invalid-message-box d-flex align-items-center" style="min-height: 3em; margin-top: 10px;">
            <span class="px-2 py-1">Missing Advertiser ID</span>
        </div>

    </div>

    <div *ngIf="cardType=='keyValuePair'">
        <div class="campaign-card-content"><span style="font-weight: bold;">Page Template: </span><span>{{ _content[1] && _content[1] !='-' ? _content[1] : '-' }}</span></div>
        <div class="campaign-card-content"><span style="font-weight: bold;">Section: </span><span>{{ _content[0] && _content[0] !='-' ? _content[0] : '-' }}</span></div>
    </div>

    <div *ngIf="cardType=='landingPage'">
        <div *ngIf=" isContentArray">
            <div *ngFor="let item of _content" class="campaign-card-content  ">{{ item }}</div>
        </div>
        <div *ngIf="_content && !isContentArray" class="campaign-card-content  ">{{ _content }}</div>
        <div *ngIf="!_content">
            <div class="campaign-card-content">-</div>
        </div>
    </div>

    <div *ngIf="cardType=='additionalSettings'">
        <!-- *ngIf="additionalSettingChanged" -->
        <div *ngIf="isContentArray">
            <div class="campaign-card-content" *ngIf="_content[0]!='uneditable'">
                <span class="dot" [style.color]="_content[0]=='true' ? '#218250' : '#EA1744'">{{_content[0]=='true' ? 'YES' : 'NO'}}</span>Site visit tracking
                <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.siteVisitTrackingInfo' class="fal fa-info-circle info"></span>
            </div>
            <div class="campaign-card-content" *ngIf="_content[1]!='uneditable'"><span class="dot" [style.color]="_content[1]=='true' ? '#218250' : '#EA1744'">{{_content[1]=='true' ? 'YES' : 'NO'}}</span>Conversion tracking <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.conversionTrackingInfo' class="fal fa-info-circle info"></span></div>
            <div class="campaign-card-content" *ngIf="_content[2]!='uneditable'"><span class="dot" [style.color]="_content[2]=='true' ? '#218250' : '#EA1744'">{{_content[2]=='true' ? 'YES' : 'NO'}}</span>Additional Targeting Segments <span tooltipPosition="top" tooltipStyleClass="tooltipStyle" [pTooltip]='constants.additionalTargerSegmentsInfo' class="fal fa-info-circle info"></span></div>
        </div>
        <div *ngIf="_content && !isContentArray">
            <div class="campaign-card-content">
                <span class="dot" [style.color]="_content=='true' ? '#218250' : '#EA1744'">{{_content=='true' ? 'YES' : 'NO'}}</span>Activated outside of HUB
                <!-- <span tooltipPosition="top" [pTooltip]='constants.siteVisitTrackingInfo' class="fal fa-info-circle info"></span> -->
            </div>

        </div>
        <!-- <div *ngIf="!additionalSettingChanged">
            <div class="campaign-card-content">Default</div>
        </div> -->
    </div>

    <div *ngIf="cardType=='paymentStatus'">
        <div *ngIf="_content">
            <div class="campaign-card-content" *ngIf="_content">
                <span class="dot" [style.color]="_content == '5' ? '#218250' : '#EA1744'">{{_content == '5' ? 'YES' : 'NO'}}</span>Payment completed
            </div>
        </div>
    </div>

    <div *ngIf="cardType=='unlockFeatures'">
        <div *ngIf="isContentArray">
            <div class="campaign-card-content" *ngIf="baseCampaign.account?.isLearfieldCampaign">
                <span class="dot" [style.color]="_content[0]=='true' ? '#218250' : '#EA1744'">{{_content[0]=='true' ? 'YES' : 'NO'}}</span>Site visit tracking can edit
            </div>
            <div class="campaign-card-content" *ngIf="baseCampaign.account?.isLearfieldCampaign">
                <span class="dot" [style.color]="_content[1]=='true' ? '#218250' : '#EA1744'">{{_content[1]=='true' ? 'YES' : 'NO'}}</span>Conversion tracking can edit
            </div>
            <div class="campaign-card-content" *ngIf="baseCampaign.account?.isLearfieldCampaign">
                <span class="dot" [style.color]="_content[2]=='true' ? '#218250' : '#EA1744'">{{_content[2]=='true' ? 'YES' : 'NO'}}</span>Date range can edit
            </div>
            <div class="campaign-card-content" *ngIf="baseCampaign.account?.isLearfieldCampaign">
                <span class="dot" [style.color]="_content[3]=='true' ? '#218250' : '#EA1744'">{{_content[3]=='true' ? 'YES' : 'NO'}}</span>Interest Targeting can edit
            </div>
            <div class="campaign-card-content" *ngIf="baseCampaign.account?.isLearfieldCampaign">
                <span class="dot" [style.color]="_content[4]=='true' ? '#218250' : '#EA1744'">{{_content[4]=='true' ? 'YES' : 'NO'}}</span>Demographic targeting can edit
            </div>
            <div class="campaign-card-content">
                <span class="dot" [style.color]="_content[5]=='true' ? '#218250' : '#EA1744'">{{_content[5]=='true' ? 'YES' : 'NO'}}</span>Geographic targeting can edit
            </div>
            <div class="campaign-card-content" *ngIf="baseCampaign.account?.isLearfieldCampaign">
                <span class="dot" [style.color]="_content[6]=='true' ? '#218250' : '#EA1744'">{{_content[6]=='true' ? 'YES' : 'NO'}}</span>Zip codes targeting can edit
            </div>
        </div>
    </div>

    <div *ngIf="cardType=='demographics'">
        <div *ngIf="demographicsChanged">
            <div class="campaign-card-content" *ngIf="_content[1]!=''"><span style="font-weight: bold;">Age: </span><span>{{ _content[1] }}</span></div>
            <div class="campaign-card-content" *ngIf="_content[0]!=''"><span style="font-weight: bold;">Gender: </span><span>{{ _content[0] }}</span></div>
            <div class="campaign-card-content" *ngIf="_content[2]!=''"><span style="font-weight: bold;">Household Income: </span><span>{{ _content[2] }}</span></div>
        </div>
        <div *ngIf="!demographicsChanged || (_content[0]=='' && _content[1]=='' && _content[2]=='')">
            <div class="campaign-card-content">Default</div>
        </div>
    </div>

    <div *ngIf="cardType=='geographics'">
        <div *ngIf="geographicsChanged">
            <div class="campaign-card-content" *ngIf="_content[2]!=''"><span style="font-weight: bold;">State: </span><span>{{ _content[2] }}</span></div>
            <div class="campaign-card-content" *ngIf="_content[1]!=''"><span style="font-weight: bold;">DMA: </span><span>{{ _content[1] }}</span></div>
            <div class="campaign-card-content" *ngIf="_content[0]!=''"><span style="font-weight: bold;">City: </span><span>{{ _content[0] }}</span></div>
            <div class="campaign-card-content" *ngIf="_content[3]!=''"><span style="font-weight: bold;">ZIP: </span><span>{{ _content[3] }}</span></div>
        </div>
        <div *ngIf="!geographicsChanged || (_content[0]=='' && _content[1]=='' && _content[2]=='' && _content[3]=='')">
            <div class="campaign-card-content">Default</div>
            <!-- <div class="campaign-card-content"><span>{{baseCampaign.isSocialCampaign ? '-' : 'Default'}}y</span></div> -->
        </div>
    </div>

    <div *ngIf="_header == 'Campaign Dates'" class="mt-2">
        All impressions will deliver in full within the date range selected.
    </div>

</div>