import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { CampaignController } from '@Workspace/_generated/services';
import { IColumn } from '@Workspace/interfaces';
import { eCampaignGoal, eChannelType, eStandardCampaginChannel, eHMPCampaignPackages } from '@Workspace/_generated/enums';
import { IPackageDto, IBaseCampaignDto, ICreativeDto, ICreativeAssetDto } from '@Workspace/_generated/interfaces';
import { EnumStringPipe } from '@Workspace/pipes';
import { PageLoaderService } from '@Workspace/services';
import { Router } from '@angular/router';
import { Constants, Functions } from '@Workspace/common';
import { CartItemService } from '../../../../shared-core/components/card-item/card-item.service';
import { CampaignService } from '../../../services/campaign.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'campaign-package-preview',
    templateUrl: './campaign-package-preview.component.html',
    styleUrls: ['./campaign-package-preview.component.scss']
})
export class CampaignPackagePreviewComponent implements OnInit, OnDestroy {
    constructor(
        public campaingController: CampaignController,
        public pageLoader: PageLoaderService,
        public router: Router,
        private cardItemService: CartItemService,
        public campaignService: CampaignService,
        private messageService: MessageService,
    ) { }
    showPackage: boolean = true;
    packageItem: any;
    amount: number;
    packages: any[] = [];
    packagesAll: IPackageDto[] = [];
    @Input() campaignGoalType: number;
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    @Output() onSelectedPackageItem: EventEmitter<any> = new EventEmitter();
    @Output() onSelectedPackageItemAddOn: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    abandonedCartDisplayCreatives: false
    annuaMediaPlanning: false
    crm: true
    dedicatedAccountTeam: false
    description: "Basic package for a single channel"
    discountId: 1
    geofencing: false
    isActive: false
    isRecomended: false
    name: "Grow"
    native: false
    postConversationDisplayCreatives: false
    premiumCreativeAssets: false
    premiumFacebookInstagram: false
    price: 1099
    reportingDashboard: true
    search: false
    standardCreative: true
    unit: 0
    video: false
    addOn: any;
    @Input() difference_In_Days: any;
    campaignDto: IBaseCampaignDto = {} as IBaseCampaignDto;
    @Input() campaignDetails: IBaseCampaignDto;
    selectedPackage: any;
    recommendedPackage: any;
    premiumBanners: any[] = ['970x250', '970x90', '320x100'];
    standardBanners: string[] = ['160x600', '300x250', '728x90', '320x50', '300x600'];
    header = [
        { name: "Channel", value: "channelName", grow: false, expand: false, amplify: false, amplifyFlex: false, thrive: false, title: "Display and/or Facebook advertising" },
        { name: "Standard Creatives", value: "standardCreative", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Display banners include: 160x600, 300x250, 728x90, 320x50, 300x600. Facebook components include: Static images plus ad copy "},
        { name: "Reporting Dashboard", value: "reportingDashboard", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Comprehensive location for all of your campaign reporting metrics." },
        { name: "CRM", value: "crm", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "List of your current prospective customers" },
        { name: "Abandoned Cart Display Creatives", value: "abandonedCartDisplayCreatives", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Lower funnel banner set with a more urgent call to action" },
        { name: "Post Conversation Display Creatives", value: "postConversationDisplayCreatives", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Bottom funnel banner set that drives post conversion activity" },
        { name: "Premium Creative Assets", value: "premiumCreativeAssets", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Display banners include: 320x100, 970x90, 970x250" },
        { name: "Geotargeting", value: "geofencing", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Localized place or radii-based geographic targeting of mobile devices" },
        { name: "Creative Flighting", value: "creativeFlighting", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "" },
        { name: "Dedicated Account Team", value: "dedicatedAccountTeam", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Get a team of dedicated digital marketing experts" },
        { name: "Annual Media Planning", value: "annuaMediaPlanning", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Plan out your media for the entire year with a dedicated team" },
        { name: "Premium Facebook Instagram", value: "premiumFacebookInstagram", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Facebook components include: Facebook video" },
        { name: "Native", value: "native", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Native is a display asset that includes a combination of imagery and copy" },
        { name: "Video", value: "video", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Display video can include: Connected TV, Pre-roll/Mid-roll in-stream video, YouTube" },
        { name: "Search", value: "search", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Leverage search engine marketing to drive results" },
        { name: "Custom Analysis", value: "customAnalysis", grow: false, expand: false, amplifyFlex: false, amplify: false, thrive: false, title: "Get custom insights and reporting" },]

    ngOnInit() {
        this.pageLoader.showPageLoader();
        if (!!this.campaignGoalType) {
            this.campaingController.GetPackagesDetails().subscribe(x => {
                this.packagesAll = x;
                if (!!this.campaignDetails.packageId)
                    this.packagesAll.find(x => x.id == this.campaignDetails.packageId).isActive = true;
                this.filterPackages(this.campaignGoalType);
                //this.PackagePreview()
                this.pageLoader.hidePageLoader();
            });
        }
    }
    PackagePreview() {
        var grow = this.packages.find(x => x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Grow, eHMPCampaignPackages) || x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Display, eHMPCampaignPackages));
        var expand = this.packages.find(x => x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Expand, eHMPCampaignPackages) || x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Social, eHMPCampaignPackages));
        var amplify = this.packages.find(x => x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Multi_channel, eHMPCampaignPackages) || x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify, eHMPCampaignPackages));
        var thrive = this.packages.find(x => x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Thrive, eHMPCampaignPackages));
        var amplifyFlex = this.packages.find(x => x.name == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify_Flex, eHMPCampaignPackages));

        for (let j = 0; j < this.header.length; j++) {
            var value1 = !!grow ? grow[this.header[j].value] : null;
            var value2 = !!expand ? expand[this.header[j].value] : null;
            var value3 = !!amplify ? amplify[this.header[j].value] : null;
            var value4 = !!thrive ? thrive[this.header[j].value] : null;
            this.header[j].grow = value1;
            this.header[j].expand = value2;
            this.header[j].amplify = value3;
            this.header[j].thrive = value4;
            if(!!amplifyFlex){
                var value5 = amplifyFlex[this.header[j].value];
                this.header[j].amplifyFlex = value5;
            }
        }
    }
    filterPackages(campaignGoal) {
        if (!!this.packagesAll)
            this.packages = this.packagesAll.filter(_ => campaignGoal == eCampaignGoal.Quick_Promotion ? _.campaignGoalId == eCampaignGoal.Quick_Promotion : _.campaignGoalId != eCampaignGoal.Quick_Promotion);

        this.recommendedPackage = this.packages.find(x => x.isRecomended == true);
        this.PackagePreview();
    }
    onShowPackage(event: any) {
        if(Functions.areHmpCampaignDatesInvalid(new Date(this.campaignDetails.startDate), new Date(this.campaignDetails.endDate), this.campaignGoalType)){
            this.messageService.add({ severity: 'error', summary: 'Invalid dates!', detail: 'The campaign dates are invalid.' });
            return;
        }
        this.campaignDto.creatives = {} as ICreativeDto[];
        //this.campaignDetails.creatives = {} as ICreativeDto[];
        this.campaignDetails.creatives = [];
        let array = event.split(' ');

        if (array[1] == new EnumStringPipe().transform(eHMPCampaignPackages.Display, eHMPCampaignPackages)) {
            this.campaignDto.creatives = [];
            this.packages[0].premiumDisplayBanners = true;
            this.packages[0].facebookDynamicNewsFeedVideo = false;
            let creativesDto = {} as ICreativeDto;
            creativesDto.creativeAssets = [];
            creativesDto.channelId = eChannelType.Display;
            creativesDto.quantity = 1;
            creativesDto.isBuilt = false;
            creativesDto.creativeGroup = 1;
            creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Standard_Display_Banner, eStandardCampaginChannel)
            creativesDto.creativeTypeId = eStandardCampaginChannel.Standard_Display_Banner;
            for (var element = 0; element < this.standardBanners.length; element++) {
                let height = this.standardBanners[element].split('x')[1]
                let width = this.standardBanners[element].split('x')[0]
                let creativeAssetDto = {} as ICreativeAssetDto;
                creativeAssetDto.id = Constants.GuidEmpty;
                creativeAssetDto.width = Number(width)
                creativeAssetDto.height = Number(height)
                creativesDto.creativeAssets.push(creativeAssetDto);
            }
            this.campaignDto.creatives.push(creativesDto);
        }

        else if (array[1] == new EnumStringPipe().transform(eHMPCampaignPackages.Social, eHMPCampaignPackages)) {
            this.campaignDto.creatives = [];
            this.packages[0].premiumDisplayBanners = false;
            this.packages[0].facebookDynamicNewsFeedVideo = true;
            let creativesDto = {} as ICreativeDto;
            creativesDto.creativeAssets = [];
            creativesDto.channelId = eChannelType.Social;
            creativesDto.quantity = 1;
            creativesDto.isBuilt = false;
            creativesDto.creativeGroup = 1;
            creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed, eStandardCampaginChannel)
            creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Static_News_Feed;
            for (var element = 0; element < 2; element++) {
                let creativeAssetDto = {} as ICreativeAssetDto;
                creativeAssetDto.id = Constants.GuidEmpty;
                creativeAssetDto.width = 0;
                creativeAssetDto.height = 0;
                creativesDto.creativeAssets.push(creativeAssetDto);
            }
            
            this.campaignDto.creatives.push(creativesDto);

            //this.onSelectedPackageItemAddOn.emit(this.packages[0]);
        }

        if (array[0] == new EnumStringPipe().transform(eHMPCampaignPackages.Grow, eHMPCampaignPackages)) {
            this.addOn = array[1];
            this.showPackage = false;
            this.packageItem = this.packages[0];
            this.amount = this.packages[0].price;
            this.onSelectedPackageItemAddOn.emit(this.packages[0]);
        }

        else if (array[0] == new EnumStringPipe().transform(eHMPCampaignPackages.Expand, eHMPCampaignPackages)) {
            this.campaignDto.creatives = [];
            this.showPackage = false;
            this.packageItem = this.packages[1];
            this.amount = this.packages[1].price;
            this.packages[1].facebookDynamicNewsFeedVideo = true;
            this.packages[1].premiumDisplayBanners = true;
            this.packages[1].abandonedCartDisplayCreatives = true;
            this.packages[1].cartDisplayBannerCreative = true;
            this.packages[1].ppuDisplayBannerCreative = true
            
            for (let index = 1; index <= 2; index++) {
                let creativesDto = {} as ICreativeDto;
                creativesDto.creativeAssets = [];
                creativesDto.channelId = index;
                creativesDto.quantity = 1;
                creativesDto.isBuilt = false;
                creativesDto.creativeGroup = 1;
                if (index == 1)
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed, eStandardCampaginChannel)
                else
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Standard_Display_Banner, eStandardCampaginChannel)

                creativesDto.creativeTypeId = index == 1 ? eStandardCampaginChannel.Facebook_Static_News_Feed : eStandardCampaginChannel.Standard_Display_Banner;

                if (creativesDto.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner) {
                    for (var element = 0; element < this.standardBanners.length; element++) {
                        let height = this.standardBanners[element].split('x')[1]
                        let width = this.standardBanners[element].split('x')[0]
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = Number(width)
                        creativeAssetDto.height = Number(height)
                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                } else if (creativesDto.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) {
                    for (var element = 0; element < 2; element++) {
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = 0;
                        creativeAssetDto.height = 0;
                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                }
                this.campaignDto.creatives.push(creativesDto);
            }
            this.onSelectedPackageItemAddOn.emit(this.packages[1]);
        }

        else if (array[0] == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify, eHMPCampaignPackages)) {
            this.campaignDto.creatives = [];
            this.showPackage = false;
            this.packageItem = this.packages[2];
            this.amount = this.packages[2].price;
            this.packages[2].facebookDynamicNewsFeedVideo = true;
            this.packages[2].premiumDisplayBanners = true;
            for (let index = 1; index <= 8; index++) {
                if ((index !== eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel && index !== eStandardCampaginChannel.Facebook_Dynamic_News_Feed 
                        && index !== eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video && index !== eStandardCampaginChannel.Premium_Display_Banner)) 
                    {
                    let creativesDto = {} as ICreativeDto;
                    creativesDto.creativeAssets = [];
                    creativesDto.bannerDimensions = [""];
                    creativesDto.name = new EnumStringPipe().transform(index, eStandardCampaginChannel)
                    creativesDto.channelId = index > 4 ? eChannelType.Display : eChannelType.Social;
                    creativesDto.quantity = 1;
                    creativesDto.isBuilt = false;
                    creativesDto.creativeGroup = 1;
                    creativesDto.creativeTypeId = index;

                    if (this.campaignService.isDisplayBanner(creativesDto.creativeTypeId)) {
                        for (var element = 0; element < this.standardBanners.length; element++) {
                            let height = this.standardBanners[element].split('x')[1]
                            let width = this.standardBanners[element].split('x')[0]
                            let creativeAssetDto = {} as ICreativeAssetDto;
                            creativeAssetDto.id = Constants.GuidEmpty;
                            creativeAssetDto.width = Number(width)
                            creativeAssetDto.height = Number(height)
                            creativesDto.creativeAssets.push(creativeAssetDto);
                        }
                    } 
                    else if (creativesDto.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) {
                        for (var element = 0; element < 2; element++) {
                            let creativeAssetDto = {} as ICreativeAssetDto;
                            creativeAssetDto.id = Constants.GuidEmpty;
                            creativeAssetDto.width = 0;
                            creativeAssetDto.height = 0;
                            creativesDto.creativeAssets.push(creativeAssetDto);
                        }
                    }
                    this.campaignDetails.creatives.push(creativesDto);
                }
            }
            this.onSelectedPackageItemAddOn.emit(this.packages[2]);
        }
        
        else if (array[0] == new EnumStringPipe().transform(eHMPCampaignPackages.Thrive, eHMPCampaignPackages)) {
            var guid = null;
            if (this.campaignDetails.id == null)
                guid = Constants.GuidEmpty;
            else
                guid = this.campaignDetails.id;
            this.cardItemService.updatedDataSelection(this.campaignDto);
            this.router.navigate(["campaign/" + guid + "/thrive"]);
            this.showPackage = false;
            this.packageItem = this.packages[3];
            this.amount = 0;
        }

        else if (array[0] == new EnumStringPipe().transform(eHMPCampaignPackages.Display, eHMPCampaignPackages)) {
            this.campaignDto.creatives = [];
            this.showPackage = false;
            this.packageItem = this.packages[0];
            this.amount = this.packages[0].price;
            this.packages[0].premiumDisplayBanners = true;
            this.packages[0].facebookDynamicNewsFeedVideo = false;
            let creativesDto = {} as ICreativeDto;
            creativesDto.creativeAssets = [];
            creativesDto.channelId = eChannelType.Display;
            creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Standard_Display_Banner, eStandardCampaginChannel);
            creativesDto.quantity = 1;
            creativesDto.isBuilt = false;
            creativesDto.creativeGroup = 1;
            creativesDto.creativeTypeId = eStandardCampaginChannel.Standard_Display_Banner;
            for (var element = 0; element < this.standardBanners.length; element++) {
                let height = this.standardBanners[element].split('x')[1];
                let width = this.standardBanners[element].split('x')[0];
                let creativeAssetDto = {} as ICreativeAssetDto;
                creativeAssetDto.id = Constants.GuidEmpty;
                creativeAssetDto.width = Number(width);
                creativeAssetDto.height = Number(height);
                creativesDto.creativeAssets.push(creativeAssetDto);
            }

            this.campaignDto.creatives.push(creativesDto);
            this.onSelectedPackageItemAddOn.emit(this.packages[0]);
        }

        else if (array[0] == new EnumStringPipe().transform(eHMPCampaignPackages.Social, eHMPCampaignPackages)) {
            this.campaignDto.creatives = [];
            this.showPackage = false;
            this.packageItem = this.packages[1];
            this.amount = this.packages[1].price;
            this.packages[1].facebookDynamicNewsFeedVideo = true;
            this.packages[1].premiumDisplayBanners = false;
            let creativesDto = {} as ICreativeDto;
            creativesDto.creativeAssets = [];
            creativesDto.channelId = eChannelType.Social;
            creativesDto.quantity = 1;
            creativesDto.isBuilt = false;
            creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed, eStandardCampaginChannel)
            creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Static_News_Feed;
            for (var element = 0; element < 2; element++) {
                let creativeAssetDto = {} as ICreativeAssetDto;
                creativeAssetDto.id = Constants.GuidEmpty;
                creativeAssetDto.width = 0;
                creativeAssetDto.height = 0;
                creativesDto.creativeGroup = 1;
                creativesDto.creativeAssets.push(creativeAssetDto);
            }
            this.campaignDto.creatives.push(creativesDto);
            this.onSelectedPackageItemAddOn.emit(this.packages[1]);
        }

        else if (array[0].replace('_', ' ') == new EnumStringPipe().transform(eHMPCampaignPackages.Amplify_Flex, eHMPCampaignPackages)) {

            var diffInWeeks = moment.duration(moment(this.campaignDetails.endDate).diff(moment(this.campaignDetails.startDate))).asWeeks();

            if(diffInWeeks < 4){
                this.messageService.add({ severity: 'error', summary: 'Dates error!', detail: 'Period between dates should not be less than 4 weeks!' });
            }

            else{
                this.campaignDto.creatives = [];
                this.showPackage = false;
                this.packageItem = this.packages[3];
                this.amount = this.packages[3].price;
                this.packages[3].facebookDynamicNewsFeedVideo = true;
                this.packages[3].premiumDisplayBanners = true;
                for (let index = 1; index <= 8; index++) {
                    if ((index !== eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel && index !== eStandardCampaginChannel.Facebook_Dynamic_News_Feed 
                            && index !== eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video && index !== eStandardCampaginChannel.Premium_Display_Banner)) 
                        {
                        let creativesDto = {} as ICreativeDto;
                        creativesDto.creativeAssets = [];
                        creativesDto.bannerDimensions = [""];
                        creativesDto.name = new EnumStringPipe().transform(index, eStandardCampaginChannel)
                        creativesDto.channelId = index > 4 ? eChannelType.Display : eChannelType.Social;
                        creativesDto.quantity = 1;
                        creativesDto.isBuilt = false;
                        creativesDto.creativeTypeId = index;
                        creativesDto.creativeGroup = 1;
    
                        if (this.campaignService.isDisplayBanner(creativesDto.creativeTypeId)) {
                            for (var element = 0; element < this.standardBanners.length; element++) {
                                let height = this.standardBanners[element].split('x')[1]
                                let width = this.standardBanners[element].split('x')[0]
                                let creativeAssetDto = {} as ICreativeAssetDto;
                                creativeAssetDto.id = Constants.GuidEmpty;
                                creativeAssetDto.width = Number(width)
                                creativeAssetDto.height = Number(height)
                                creativesDto.creativeAssets.push(creativeAssetDto);
                            }
                        } 
                        else if (creativesDto.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) {
                            for (var element = 0; element < 2; element++) {
                                let creativeAssetDto = {} as ICreativeAssetDto;
                                creativeAssetDto.id = Constants.GuidEmpty;
                                creativeAssetDto.width = 0;
                                creativeAssetDto.height = 0;
                                creativesDto.creativeAssets.push(creativeAssetDto);
                            }
                        }
                        this.campaignDetails.creatives.push(creativesDto);
                    }
                }
                this.onSelectedPackageItemAddOn.emit(this.packages[3]);
            }
        }

        else if (array[0] == 'Multi') {
            this.campaignDto.creatives = [];
            this.showPackage = false;
            this.packageItem = this.packages[2];
            this.amount = this.packages[2].price;
            this.packages[2].facebookDynamicNewsFeedVideo = true;
            this.packages[2].premiumDisplayBanners = true;
            for (let index = 1; index <= 2; index++) {
                let creativesDto = {} as ICreativeDto;
                creativesDto.creativeAssets = [];
                creativesDto.channelId = index;
                creativesDto.quantity = 1;
                creativesDto.creativeGroup = 1;
                creativesDto.isBuilt = false;

                if (index == 1)
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed, eStandardCampaginChannel)
                else
                    creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Standard_Display_Banner, eStandardCampaginChannel)

                creativesDto.creativeTypeId = index == 1 ? eStandardCampaginChannel.Facebook_Static_News_Feed : eStandardCampaginChannel.Standard_Display_Banner;

                if (creativesDto.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner) {
                    if (creativesDto.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner) {
                        for (var element = 0; element < this.standardBanners.length; element++) {
                            let height = this.standardBanners[element].split('x')[1];
                            let width = this.standardBanners[element].split('x')[0];
                            let creativeAssetDto = {} as ICreativeAssetDto;
                            creativeAssetDto.id = Constants.GuidEmpty;
                            creativeAssetDto.width = Number(width);
                            creativeAssetDto.height = Number(height);
                            creativesDto.creativeAssets.push(creativeAssetDto);
                        }
                    }
                }
                else {
                    for (var element = 0; element < 2; element++) {
                        let creativeAssetDto = {} as ICreativeAssetDto;
                        creativeAssetDto.id = Constants.GuidEmpty;
                        creativeAssetDto.width = 0;
                        creativeAssetDto.height = 0;
                        creativesDto.creativeAssets.push(creativeAssetDto);
                    }
                }
                this.campaignDto.creatives.push(creativesDto);
            }
            this.onSelectedPackageItemAddOn.emit(this.packages[2]);
        }

        else if (array[0] == 'Show') {
            this.showPackage = true;
        }

        this.onClick.emit(this.showPackage);
        this.campaignDto.package = this.packageItem;
        this.campaignDetails.package = this.packageItem;

        if (this.campaignDetails.creatives.length == 0) {
            this.onSelectedPackageItem.emit(this.campaignDto);
        }
        else {
            this.onSelectedPackageItem.emit(this.campaignDetails);
        }
    }

    ngOnDestroy() {
    }

}