import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'campaign-package-preview-page',
    templateUrl: 'campaign-package-preview-page.html'
})
export class CampaignPackagePreviewPageComponent implements OnInit {
    constructor(
    ) { }
    @Input()showPackage: any;

    ngOnInit() {
    }
}