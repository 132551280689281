import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { IChangePasswordModel } from '@Workspace/_generated/interfaces';
import { AccountController } from '@Workspace/_generated/services';
import { PageLoaderService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'change-password-form',
    templateUrl: 'change-password-form.component.html'
})
export class ChangePasswordFormComponent implements OnInit {
    constructor(
        private fb: UntypedFormBuilder,
        private accountController: AccountController,
        private messageService: MessageService,
        private pageLoaderService: PageLoaderService,
        private authService: AuthService
    ) { }

    @Input() userId: string;
    @Input() showSaveButton: boolean = true; // Set false if nested inside another form or dialog. Removes save button. Call save() method from @ViewChild of this component.
    @Input() isForForgotPassword: boolean = false; // Set true for reseting forgot password. Removes current password from the form.
    @Input() styleClass: string; // Add custom classes.
    @Output() onSaved: EventEmitter<object> = new EventEmitter<object>();

    formGroup: UntypedFormGroup;
    passwordPattern: string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
    currentUser = this.authService.userSettings;
    oldPasswordFieldTextType: boolean;
    newPasswordFieldTextType: boolean;
    confirmPasswordFieldTextType: boolean;
    showOldPassword: boolean;

    ngOnInit() {
        this.showOldPassword = (!this.isForForgotPassword && !this.currentUser?.permissions.isAdmin) || (this.currentUser?.id == this.userId);
        this.formInit();
    }

    private formInit() {
        this.formGroup = this.fb.group({
            oldPassword: this.showOldPassword? ["", Validators.required] : [""],
            newPassword: ["", [Validators.required, Validators.pattern(this.passwordPattern)]],
            confirmPassword: ["", Validators.required],
        });
    }

    isValid() {
        return !this.formGroup.invalid && (this.formGroup.controls['newPassword'].value == this.formGroup.controls['confirmPassword'].value);
    }

    save() {
        let passwordModel = {} as IChangePasswordModel;
        passwordModel.oldPassword = this.showOldPassword? this.formGroup.value.oldPassword : this.formGroup.value.newPassword;
        passwordModel.newPassword = this.formGroup.value.newPassword;
        passwordModel.confirmPassword = this.formGroup.value.confirmPassword;
        passwordModel.userId = this.userId;
        passwordModel.validateOldPassword = this.showOldPassword;
        this.pageLoaderService.showPageLoader();

        this.accountController.ChangePassword(passwordModel).subscribe(
            res => {
                this.pageLoaderService.hidePageLoader();
                this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Password Is Updated.' })
                this.onSaved.emit();
            },
            error => {
                this.pageLoaderService.hidePageLoader();    
                for (const property in error.error.Errors) {
                    error.error.Errors[property].forEach(e => {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: e });
                    });
                }
            }
        );
    }
}