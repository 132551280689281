import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Constants } from '@Workspace/common';
import { CampaignController } from '@Workspace/_generated/services';
import { take } from 'rxjs/operators';
import { IGridOptions } from '@Workspace/interfaces';
import { PageLoaderService } from '@Workspace/services';
import { GridComponent } from '../../../shared-core/components/grid/rc-grid.component';
import { IBaseCampaignDto, ICampaignTransferDto, IUserPropertiesDto } from '@Workspace/_generated/interfaces';
import { eAccountType, eChannelType } from '@Workspace/_generated/enums';
import { Router } from '@angular/router';
import { AuthService } from '@Workspace/auth';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'campaign-transfer-data-dialog',
    templateUrl: './campaign-transfer-data-dialog.html',
    styleUrls: ['./campaign-transfer-data-dialog.scss'],
})
export class CampaignTransferDataDialog implements OnInit, OnDestroy {

    constructor( 
        public campaignController: CampaignController,
        public pageLoader: PageLoaderService,
        private authService: AuthService,
        private messageService: MessageService,
        private router: Router) {
    }

    constants = Constants;
    ngUnsubscribe = new Subject();
    isVisible: boolean = false;
    options: IGridOptions;
    currentUser: IUserPropertiesDto = this.authService.userSettings;
    selectedCampaign: any;
    isGridEmpty: boolean = true;
    step: number = 1; 
    skipParam: number = 0;
    takeParam: number = 10;
    searchParameter: string = '';
    enumAccountType = eAccountType;

    transferCampaign: IBaseCampaignDto;

    @ViewChild(GridComponent) GridComponent: GridComponent;
    @Output() updateGrid: EventEmitter<any> = new EventEmitter();

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    openDialog(rowData: IBaseCampaignDto) {
        this.isVisible = true;

        this.options = {} as IGridOptions;
        this.options.isOData = false;
        this.options.columns = [
            {
                label: '',
                value:'radioButton',
                width: '2%'
            },
            {
                label: 'Campaign Name',
                value: 'name',
                width: '35%'
            },
            {
                label: 'Deal Number',
                value: 'dealId',
                width: '10%'
            },
            {
                label: 'Impressions',
                value: 'impressions',
                width: '10%'
            },
            {
                label: 'Channel',
                value: 'channel',
                width: '10%'
            }
        ];

        this.transferCampaign = rowData;

        this.getData(this.transferCampaign);
        // this.campaignController.GetTransferDataCampaigns(rowData.dealId, rowData.id, rowData.isLearfieldCampaign != null ? rowData.isLearfieldCampaign : false, rowData.isOASCampaign != null ? rowData.isOASCampaign : false, this.skipParam, this.takeParam)
        // .pipe(take(1)).subscribe( 
        //     result => {
        //         this.options.data = result;
        //         this.pageLoader.hidePageLoader();
        //         this.isGridEmpty = result.data.length != 0 ? false : true;
        //         setTimeout(() => {
        //             this.GridComponent.totalCount = result.totalCount;
        //         }, 1000);
        //     }, 
        //     error => {}
        // )
    }

    getData(rowData: IBaseCampaignDto, refresh = false){
        this.pageLoader.showPageLoader();
        this.campaignController.GetTransferDataCampaigns(rowData.accountId, rowData.id, rowData.campaignType == eAccountType.FAN365, rowData.campaignType == eAccountType.OAS, rowData.seasonMapping.seasonId, this.searchParameter, this.skipParam, this.takeParam)
        .pipe(take(1)).subscribe( 
            result => {
                this.options.data = result;
                if (refresh) {
                    this.GridComponent.value = this.options.data;
                } else {
                    this.isGridEmpty = result.data.length != 0 ? false : true;
                }
                this.pageLoader.hidePageLoader();
                if (!this.isGridEmpty) {
                    setTimeout(() => {
                        this.GridComponent.totalCount = result.totalCount;
                    }, 0);
                }
            }, 
            error => {
                this.pageLoader.hidePageLoader();}
        )
    }
    
    setParameters(event){
        this.skipParam = event.skip;
        this.takeParam = event.take;

        this.getData(this.transferCampaign, true);
    }

    search(){
        this.GridComponent.resetSkipTake();
        this.skipParam = 0;
        this.takeParam = 10;
        this.getData(this.transferCampaign, true);
    }

    cancel(){
        this.isGridEmpty = true;
        this.isVisible = false;
    }

    getCampaignChannels(campaign: IBaseCampaignDto) {
        let channels;
        let creativesSocial = campaign.creatives.some(x => x.channelId == eChannelType.Social);
        let creativesDisplay = campaign.creatives.some(x => x.channelId != eChannelType.Social);
        if (creativesSocial && creativesDisplay)
            channels = 'Display; Facebook'
        else if (creativesDisplay)
            channels = 'Display'
        else
            channels = 'Facebook'

        return channels;
    }

    updateCampaignService(rowData: IBaseCampaignDto) {

        if (rowData.account.isLearfieldCampaign || rowData.account.isOASCampaign || rowData.account.isSocialCampaign || rowData.account.isContentCampaign)
            this.router.navigate(['/campaign/' + rowData.id + '/campaign-upload-fan365']);
        else if (rowData.account.isCampaignCreation && this.currentUser.permissions.isClient)
            this.router.navigate(['/campaign/' + rowData.id]);
        else
            this.router.navigate(['/campaign/' + rowData.id + '/campaign-upload']);
    }

    checkValue(rowData){
        if (rowData.id == this.selectedCampaign?.id){
            return true;
        }
        else{
            return false;
        }
    }

    save(){
        this.step = 2;
    }

    transferData(event){
        if(event){
            this.pageLoader.showPageLoader();

            this.campaignController.GetPSSBraodcastContractLineByCampaignIdQuery(this.transferCampaign.id).subscribe(x => {
              if(x.data[0].contractSeasonStatus == 'Opted-out' || x.data[0].contractLineStateCode == true || x.data[0].contractStateCode == true){
                this.pageLoader.hidePageLoader();
                this.step = 3;
              }
              else{
                let transferData: ICampaignTransferDto = {
                    transferedCampaignId: this.transferCampaign.id,
                    targetCampaignId: this.selectedCampaign.id,
                    isDeleted: false
                } as ICampaignTransferDto;  

                this.callbackTransferData(transferData);
              }
            });
        }
        else{
            this.step = 1;
        }
    }

    deleteData(event){
        if (event) {
            this.pageLoader.showPageLoader();

            let transferData: ICampaignTransferDto = {
                transferedCampaignId: this.transferCampaign.id,
                targetCampaignId: this.selectedCampaign.id,
                isDeleted: true
            } as ICampaignTransferDto;  

            this.callbackTransferData(transferData);
        } 
        else {
            this.step = 2;
        }
    }

    callbackTransferData(transferData: ICampaignTransferDto){
        this.campaignController.TransferCampaigns(transferData).pipe(take(1)).subscribe(
            result => {
                this.updateGrid.emit();
                this.isVisible = false;
                this.messageService.add({ severity: 'success', summary: 'Success Message', detail: transferData.isDeleted ? 'Campaign successfully transfered and deleted.' : 'Campaign successfully transfered.' });
                this.pageLoader.hidePageLoader();
                this.step = 1;
                this.selectedCampaign = null;
            }, 
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong.' });
                this.pageLoader.hidePageLoader();
                this.step = 1;
                    this.selectedCampaign = null;
            })
    }

}
