import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { IUserPropertiesDto, IAccountDto, ISeasonMappingDto } from '@Workspace/_generated/interfaces';
import { MenuItem, SelectItem } from 'primeng/api';
import { AuthService } from '@Workspace/auth';
import { IGridOptions } from '@Workspace/interfaces';
import { LookUps } from '@Workspace/common';
import { PageLoaderService } from '@Workspace/services';
import { CampaignController, AccountController } from '@Workspace/_generated/services';
import { eCampaignStatus, eUserRole } from '@Workspace/_generated/enums';
import { CampaignListComponent } from '../../components/campaign-list/campaign-list.component';
import { CampaignService } from '../../services/campaign.service';
import { CRMListComponent } from '../../components/crm/crm-list/crm-list.component';
import { takeUntil } from 'rxjs/operators';
import { CampaignBulkEditSettingsDialog } from '../../components/campaign-bulk-edit-settings-dialog/campaign-bulk-edit-settings-dialog';
import * as moment from 'moment';

@Component({
    selector: 'campaign-preview-page-component',
    templateUrl: './campaign-preview.page.html',
    styleUrls: ['./campaign-preview.page.scss']
})
export class CampaignPreviewPage implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private campaignService: CampaignService,
        private pageLoader: PageLoaderService,
        public accountController: AccountController,
        public campaignController: CampaignController,
        private cd: ChangeDetectorRef
    ) {}
    
    @ViewChild(CampaignListComponent) campaignListComponent: CampaignListComponent;
    @ViewChild(CRMListComponent) crmListComponent: CRMListComponent;

    ngUnsubscribe = new Subject();
    currentUser: IUserPropertiesDto;
    searchParameter: string = '';
    searchParameterSponsor: string = '';
    searchParameterCRM: string;
    filterGrid: any;
    fiscalYearFilterChanged: string[] = [];

    isGrouping: boolean;
    enumUserRole = eUserRole;

    messageVisable: boolean = false;

    currentAccount: IAccountDto[];
    options: IGridOptions;
    optionsGrouping: IGridOptions[] = [];
    showGrid: boolean = true;
    gridId: number;
    grids: SelectItem[] = [];
    campaignStatuses: SelectItem[] = [];
    isBulkEditVisible: boolean = false;

    selectedSponsor: string = '';

    @ViewChild('bulkEditDialog') campaignBulkEditSettingsDialog: CampaignBulkEditSettingsDialog;
    @ViewChild('campaignListComponent') campaignListComponentViewChild: CampaignListComponent;
    @ViewChildren('campaignListByStatuses') campaignListByStatuses: QueryList<CampaignListComponent>;

    // oData filters
    public gridOptions = {} as IGridOptions;
    public clientNameFilter = '';
    public clientNameSelectOptions: SelectItem[];
    public regionFilter = '';
    public regionSelectOptions: SelectItem[];
    public campaignStatusSelectOptions: SelectItem[];
    public fiscalYearFilter: string[] = [];
    public campaignStatusFilter: string[] = [];
    public defaultValueFiscalYearFilter: string[] = [];
    fiscalYearSelectOptions: SelectItem[] = [];
    public gridFilters = '';
    public breadcrumbItems: MenuItem[];

    ngOnInit() {
        var todayDate = new Date;
        todayDate.setHours(0, 0, 0, 0);
        if (todayDate >= new Date('2021-11-29 0:0:0:0') && todayDate <= new Date('2022-01-03 0:0:0:0'))
            this.messageVisable = true;

        this.campaignStatuses = [{
            value: 1,
            label: "Action Required"
        },
        {
            value: 2,
            label: "In Progress"
        },
        {
            value: 3,
            label: "Completed"
        }]

        this.currentUser = this.authService.userSettings;
        if (!!this.currentUser.accounts && this.currentUser.userRole == 1){
            this.clientNameFilter = this.currentUser.accounts[0].id;
            this.currentAccount = this.currentUser.accounts;
        }

        if (!!this.currentAccount && this.currentAccount[0].isCampaignCreation)
            this.gridId = 3;
        else if (
            !!this.currentAccount &&
            this.currentAccount[0].isHubStandardCampaign
        )
            this.gridId = 2;
        else if (
            !!this.currentAccount &&
            this.currentAccount[0].isLearfieldCampaign
        )
            this.gridId = 6;
        else if (!!this.currentAccount && this.currentAccount[0].isOASCampaign)
            this.gridId = 6;
        else if (
            !!this.currentAccount &&
            this.currentAccount[0].isSocialCampaign
        )
            this.gridId = 6;
        else if (this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin) this.gridId = 6;
        else if (this.currentUser.userRole == eUserRole.LIMGCSocialAdOps) this.gridId = 6;
        else {
            this.campaignService.getGridIdId().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (!!x)
                    this.gridId = x;
                else
                    this.gridId = (!this.currentUser.accountId && (this.currentUser.permissions.isEmployee || this.currentUser.permissions.isAdmin))
                        || (!!this.currentAccount && this.currentAccount[0].isLearfieldCampaign) ? 1 :
                        ((!this.currentUser.accountId && (this.currentUser.permissions.isEmployee || this.currentUser.permissions.isAdmin))
                            || (!!this.currentAccount && this.currentAccount[0].isOASCampaign) ? 4 :
                            ((!this.currentUser.accountId && (this.currentUser.permissions.isEmployee || this.currentUser.permissions.isAdmin))
                                || (!!this.currentAccount && this.currentAccount[0].isSocialCampaign) ? 5 : (this.currentUser.permissions.isHSClient ? 2 : 3)));
                ;
            });
        }

        this.campaignService.setGridIdId(this.gridId);

        if (
            !this.currentUser.accountId &&
            (this.currentUser.permissions.isEmployee ||
                this.currentUser.permissions.isAdmin)
        )
            this.grids = [
                {
                    value: 1,
                    label: 'FAN365 Campaigns'
                },
                {
                    value: 2,
                    label: 'HS Campaigns'
                },
                {
                    value: 3,
                    label: 'HMP Campaigns'
                },
                {
                    value: 4,
                    label: 'OAS Campaigns'
                },
                {
                    value: 5,
                    label: 'Social Campaigns'
                }
                ,
                {
                    value: 7,
                    label: 'Content Campaigns'
                }
            ];

        if (this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin)
            this.grids = [
                {
                    value: 6,
                    label: 'All Campaigns'
                },
                {
                    value: 1,
                    label: 'FAN365 Campaigns'
                },
                {
                    value: 4,
                    label: 'OAS Campaigns'
                },
                {
                    value: 5,
                    label: 'Social Campaigns'
                },
                {
                    value: 7,
                    label: 'Content Campaigns'
                }
            ];

        if (this.currentUser.userRole == eUserRole.LIMGCSocialAdOps)
            this.grids = [
                {
                    value: 6,
                    label: 'All Campaigns'
                },
                {
                    value: 5,
                    label: 'Social Campaigns'
                },
                {
                    value: 7,
                    label: 'Content Campaigns'
                }
            ];

        if (this.currentUser.accountId && (this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isSocialClient || this.currentUser.permissions.isOASClient))
            this.grids = [
                {
                    value: 6,
                    label: 'All Campaigns'
                },
                {
                    value: 1,
                    label: 'FAN365 Campaigns'
                },
                {
                    value: 4,
                    label: 'OAS Campaigns'
                },
                {
                    value: 5,
                    label: 'Social Campaigns'
                },
                {
                    value: 7,
                    label: 'Content Campaigns'
                }
            ];

        if (!this.currentUser.permissions.isClient || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || (this.currentUser.permissions.isClient && this.currentUser.accounts)) {
            this.setClientNameSelectOption(this.gridId);
        }
        this.campaignStatusSelectOptions = LookUps.getCampaignStatusesEnum();

        let sessionVal = JSON.parse(localStorage.getItem('gridFilter'));
        if((!!sessionVal && (sessionVal['gridId'] == 7 || sessionVal['gridId'] == 6))
        || (sessionVal == null && this.grids.some(x => x.value == 6))){
            this.campaignStatusSelectOptions.forEach(x =>{
                if(sessionVal != null){
                    if(x.value == eCampaignStatus.Assets_Pending)
                        x.label = sessionVal['gridId'] == 7 ? "Form Pending" : "Assets Pending / Form Pending";
                    else if(x.value == eCampaignStatus.Assets_Uploaded)
                        x.label = sessionVal['gridId'] == 7 ? "Form Submitted" : "Assets Uploaded / Form Submitted";
                }
                else{
                    if(x.value == eCampaignStatus.Assets_Pending)
                        x.label = "Assets Pending / Form Pending";
                    else if(x.value == eCampaignStatus.Assets_Uploaded)
                        x.label = "Assets Uploaded / Form Submitted";
                }
            });
        }

        this.regionSelectOptions = LookUps.getRegionsLu();
        this.regionSelectOptions.unshift({ label: "-", value: 0 });

        this.campaignController.GetAllSeasonMapping().subscribe(data => {
            data.filter(x => x.category.includes("Summer")).forEach(x => {
                let compareDate = moment(todayDate);
                let startDate   = moment(x.startDate);
                let endDate     = moment(data.find(y => y.category.includes("Spring") && y.seasonYear == x.seasonYear).endDate);

                //Session check if fiscal year exist in session
                if(localStorage.getItem('gridFilter') == null){
                    if(compareDate.isBetween(startDate, endDate, null, '[]') && !this.fiscalYearFilter.some(y=> y == x.seasonYear)){
                        this.fiscalYearFilter.push(x.seasonYear);
                        this.defaultValueFiscalYearFilter.push(x.seasonYear);
                    }
                    this.fiscalYearFilterChanged = this.fiscalYearFilter;
                }
                
                if (this.defaultValueFiscalYearFilter.length == 0 && compareDate.isBetween(startDate, endDate, null, '[]')) {
                    this.defaultValueFiscalYearFilter.push(x.seasonYear);
                }
                
                let seasonYearOption = {} as SelectItem;
                seasonYearOption.label = x.seasonYear;
                seasonYearOption.value = x.seasonYear;
                this.fiscalYearSelectOptions.push(seasonYearOption);
            });
            let uniqueData = [
                ...new Map(this.fiscalYearSelectOptions.map(obj => [`${obj.label}:${obj.value}`,obj]))
                    .values()
            ];
            this.fiscalYearSelectOptions = uniqueData;
        });
                
        if(localStorage.getItem('gridFilter') != null && localStorage.getItem('gridFilter') != "" && localStorage.getItem('gridFilter') != "null"){
            var sessionValue = JSON.parse(localStorage.getItem('gridFilter'));
            if(sessionValue['gridId'])
            {
                this.gridId = sessionValue['gridId'];
                this.campaignService.setGridIdId(this.gridId);
                if (!this.currentUser.permissions.isClient || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin || (this.currentUser.permissions.isClient && this.currentUser.accounts)) {
                    this.setClientNameSelectOption(this.gridId);
                }
            }
            if(sessionValue['clientName'])
            {
                this.clientNameFilter = sessionValue['clientName'];
            }
            if(sessionValue['region'])
            {
                this.regionFilter = sessionValue['region'];
            }
            if(sessionValue['campaignStatus'])
            {
                sessionVal['campaignStatus'].forEach(x =>{
                    this.campaignStatusFilter.push(x);
                });
            }
            else{
                this.campaignStatusSelectOptions.forEach(x =>{
                    this.campaignStatusFilter.push(x.value);
                });
            }
            if(sessionValue['fiscalYear'])
            {
                this.fiscalYearFilter = sessionValue['fiscalYear'];
                this.fiscalYearFilterChanged = this.fiscalYearFilter;
            }
            if(sessionValue['searchParameter'])
            {
                this.searchParameter = sessionValue['searchParameter'];
            }
            if(sessionValue['searchParameterSponsor'])
            {
                this.searchParameterSponsor = sessionValue['searchParameterSponsor'];
            }
            
        setTimeout(() => {
            this.applyFilters();
            }, 500);  
        }
        else{
            this.campaignStatusSelectOptions.forEach(x =>{
                this.campaignStatusFilter.push(x.value);
            });
        }
        this.setBreadCrumb();
    }

    setBreadCrumb(){
        this.breadcrumbItems = [
            {
                label:
                    this.clientNameFilter == ''
                        ? this.currentUser.accounts[0].name
                        : this.currentUser.accounts.filter(x => x.id == this.clientNameFilter)[0].name,
                icon: 'pi pi-home',
                command: () => {
                    this.campaignListComponent.resetGroupedCampaigns();
                }
            }
        ];
    }
    setClientNameSelectOption(gridId: number) {
        if (gridId == 1) {
            this.clientNameSelectOptions = this.currentUser.accounts
                .filter(
                    x =>
                        x.isLearfieldCampaign == true ||
                        x.isOASCampaign == true ||
                        x.isSocialCampaign == true ||
                        x.isContentCampaign == true
                )
                .map(clients => {
                    let clientOption = {} as SelectItem;
                    clientOption.label = clients.name;
                    clientOption.value = clients.id;
                    return clientOption;
                });
        } else if (gridId == 2) {
            this.clientNameSelectOptions = this.currentUser.accounts
                .filter(x => x.isHubStandardCampaign == true)
                .map(clients => {
                    let clientOption = {} as SelectItem;
                    clientOption.label = clients.name;
                    clientOption.value = clients.id;
                    return clientOption;
                });
        } else if (gridId == 3) {
            this.clientNameSelectOptions = this.currentUser.accounts
                .filter(x => x.isCampaignCreation == true)
                .map(clients => {
                    let clientOption = {} as SelectItem;
                    clientOption.label = clients.name;
                    clientOption.value = clients.id;
                    return clientOption;
                });
        } else if (gridId == 4) {
            this.clientNameSelectOptions = this.currentUser.accounts
                .filter(
                    x =>
                        x.isLearfieldCampaign == true ||
                        x.isOASCampaign == true ||
                        x.isSocialCampaign == true ||
                        x.isContentCampaign == true
                )
                .map(clients => {
                    let clientOption = {} as SelectItem;
                    clientOption.label = clients.name;
                    clientOption.value = clients.id;
                    return clientOption;
                });
        } else if (gridId == 5) {
            this.clientNameSelectOptions = this.currentUser.accounts
                .filter(
                    x =>
                        x.isLearfieldCampaign == true ||
                        x.isOASCampaign == true ||
                        x.isSocialCampaign == true ||
                        x.isContentCampaign == true
                )
                .map(clients => {
                    let clientOption = {} as SelectItem;
                    clientOption.label = clients.name;
                    clientOption.value = clients.id;
                    return clientOption;
                });
        } else if (gridId == 6) {
            this.clientNameSelectOptions = this.currentUser.accounts
                .filter(
                    x =>
                        x.isLearfieldCampaign == true ||
                        x.isOASCampaign == true ||
                        x.isSocialCampaign == true ||
                        x.isContentCampaign == true
                )
                .map(clients => {
                    let clientOption = {} as SelectItem;
                    clientOption.label = clients.name;
                    clientOption.value = clients.id;
                    return clientOption;
                });
        } else if (gridId == 7) {
            this.clientNameSelectOptions = this.currentUser.accounts
                .filter(
                    x =>
                        x.isLearfieldCampaign == true ||
                        x.isOASCampaign == true ||
                        x.isSocialCampaign == true ||
                        x.isContentCampaign == true
                )
                .map(clients => {
                    let clientOption = {} as SelectItem;
                    clientOption.label = clients.name;
                    clientOption.value = clients.id;
                    return clientOption;
                });
        }
        
        if(!(this.currentUser.permissions.isClient && this.currentUser.userRole != this.enumUserRole.LIMGC_AdOps_Admin && this.currentUser.userRole != this.enumUserRole.LIMGCSocialAdOps))
            this.clientNameSelectOptions.unshift({ label: "-", value: 0 });
    }

    public navigateToGrid() {
        this.isGrouping = false;
        this.cd.detectChanges();
        this.applyFilters();
    }

    public navigateToGroupGrid() {
        this.isGrouping = true;
        this.cd.detectChanges();
        localStorage.setItem("gridFilter","");
    }
    onClientChange(){
        this.setBreadCrumb();
        this.applyFilters();
        if (this.isGrouping) {
            this.campaignListByStatuses.forEach(child => {
                child.composeGridColumns();
            });
        } else {
            if(!!this.selectedSponsor){
                this.campaignListComponent.composeGridColumns();
            } else{
                this.campaignListComponent.composeGroupedGrid(this.clientNameFilter);
            }
        }
    }

    public composeFilter() {
        this.campaignListComponent.searchParameter = this.searchParameter;

        if (!!this.searchParameter && this.searchParameter != ''){
            this.filterGrid = {...this.filterGrid, searchParameter : this.searchParameter};
        }

        if(Object.keys(this.filterGrid).length > 0){
            localStorage.setItem('gridFilter', JSON.stringify(this.filterGrid));
        }

        this.campaignListComponent.composeFilter(
            this.searchParameter,
            this.gridFilters,
            0,10,
            this.clientNameFilter
        );      
    }

    public composeSearchParameter() {
        
        if (!!this.searchParameterSponsor && this.searchParameterSponsor != ''){
            this.filterGrid = {...this.filterGrid, searchParameterSponsor : this.searchParameterSponsor};
        }

        else{
            this.filterGrid = {...this.filterGrid, searchParameterSponsor : ''};
        }


        if(Object.keys(this.filterGrid).length > 0){
            localStorage.setItem('gridFilter', JSON.stringify(this.filterGrid));
        }

        this.campaignListComponent.searchGroupedCampaigns(this.searchParameterSponsor);
    }

    public composeFilterCRM() {
        this.crmListComponent.composeGridColumns(this.searchParameterCRM);
    }

    onChangeGrid(event) {
        this.resetSelectedCampaigns();
        this.gridId = event.value;
        this.setClientNameSelectOption(this.gridId);
        this.campaignService.setGridIdId(this.gridId);

        if(event.value == 7 || event.value == 6){
            this.campaignStatusSelectOptions.filter(x => x.value == eCampaignStatus.Assets_Pending || x.value == eCampaignStatus.Assets_Uploaded).forEach(x =>{
                if(x.value == eCampaignStatus.Assets_Pending)
                    x.label = event.value == 7 ? "Form Pending" : "Assets Pending / Form Pending";
                else if(x.value == eCampaignStatus.Assets_Uploaded)
                    x.label = event.value == 7 ? "Form Submitted" : "Assets Uploaded / Form Submitted";
            });
        }
        else if(localStorage.getItem('gridFilter') != null && (JSON.parse(localStorage.getItem('gridFilter'))['gridId'] == 7 || JSON.parse(localStorage.getItem('gridFilter'))['gridId'] == 6)){
            this.campaignStatusSelectOptions.filter(x => x.value == eCampaignStatus.Assets_Pending || x.value == eCampaignStatus.Assets_Uploaded).forEach(x =>{
                if(x.value == eCampaignStatus.Assets_Pending)
                    x.label = "Assets Pending";
                else if(x.value == eCampaignStatus.Assets_Uploaded)
                    x.label = "Assets Submitted";
            });
        }
        this.cd.detectChanges();
        
        if(localStorage.getItem('gridFilter') != null && localStorage.getItem('gridFilter') != "" && localStorage.getItem('gridFilter') != "null"){
            var oldGridValue = JSON.parse(localStorage.getItem('gridFilter'))['gridId'];

            if(((oldGridValue == 1 || oldGridValue == 4 || oldGridValue == 5) && (this.gridId == 2 || this.gridId == 3 || this.gridId == 7)) 
                || (oldGridValue == 2 &&  this.gridId != 2) 
                || (oldGridValue == 3 && this.gridId != 3)
                || (oldGridValue == 7 && this.gridId != 7)
              ){
                localStorage.removeItem('gridFilter');

                this.clientNameFilter = "";
                this.regionFilter = "";
                this.campaignStatusFilter = [];
                this.fiscalYearFilter = this.defaultValueFiscalYearFilter;
                this.fiscalYearFilterChanged = this.fiscalYearFilter;
                this.searchParameter ="";
            }
        }
        this.applyFilters();
        
        this.campaignListComponent.composeGridColumns(this.gridId, true);
        this.cd.detectChanges();
    }

    onChangeGroupedGrid(event) {
        this.gridId = event.value;
        this.setClientNameSelectOption(this.gridId);
        this.applyFilters();
        if (this.isGrouping) {
            this.campaignListByStatuses.forEach(child => {
                child.composeGridColumns(this.gridId);
            });
        } else {
            this.campaignListComponent.composeGridColumns(this.gridId);
        }
    }

    onChangeFiscalYear(){
        if(this.fiscalYearFilterChanged != this.fiscalYearFilter){
            if(this.fiscalYearFilter.length == 0)
            {
                this.fiscalYearFilter = [];
                this.fiscalYearFilter = this.defaultValueFiscalYearFilter;
                this.campaignListComponent.fiscalYearFilter = this.defaultValueFiscalYearFilter;
            }
            this.fiscalYearFilterChanged = this.fiscalYearFilter;
            this.applyFilters();
            if (this.isGrouping) {
                this.campaignListByStatuses.forEach(child => {
                    child.composeGridColumns();
                });
            } else {
                if(!!this.selectedSponsor){
                    this.campaignListComponent.composeGridColumns();
                } else{
                    this.campaignListComponent.composeGroupedGrid();
                }
            }
        }
    }

    onChangeGroupedStatus() {
        this.applyFilters();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public applyFilters() {
        this.filterGrid = {};
        
        this.gridFilters = '';
        if (!!this.clientNameFilter && this.clientNameFilter != ''){
            this.filterGrid = {...this.filterGrid, clientName : this.clientNameFilter};
            this.gridFilters +=
                (this.gridFilters != null && this.gridFilters != ''
                    ? ' and '
                    : ' ') + ` Account/Id eq ${this.clientNameFilter}`;}
        else
            this.clientNameFilter = '';
            
        if (!!this.regionFilter && this.regionFilter != '' && parseInt(this.regionFilter) != 0 && this.regionFilter != null){
            this.filterGrid = {...this.filterGrid, region : this.regionFilter};
            this.gridFilters +=
                (this.gridFilters != null && this.gridFilters != ''
                    ? ' and '
                    : ' ') +
                ` RegionId eq Learfield.Mogo_Fan365.Common.Enums.eRegion'${this.regionFilter}'`;}
        else
            this.regionFilter = '';

        if (!!this.campaignStatusFilter && this.campaignStatusFilter.length != 0 && this.campaignStatusFilter.length != this.campaignStatusSelectOptions.length) {
            this.filterGrid = {...this.filterGrid, campaignStatus : this.campaignStatusFilter};
            let ids = '';
            this.campaignStatusFilter.forEach(statusId =>{
                ids += statusId + ',';
            });
            if(ids != ''){
                ids = ids.slice(0,-1);
                let campaignStatusCondition = `StatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${ids}'`;
                this.gridFilters += (this.gridFilters != null && this.gridFilters != '' ? ' and ' : ' ') + campaignStatusCondition;
            }

            // this.gridFilters +=
            //     (this.gridFilters != null && this.gridFilters != ''
            //         ? ' and '
            //         : ' ') +
            //     ` (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${this.campaignStatusFilter}'
            // or (CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'14' and CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'8' and
            // CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'7' and CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'18' and
            //     StatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${this.campaignStatusFilter}'))`;
            // if(this.campaignStatusFilter==eCampaignStatus.Campaign_Paused.toString() || this.campaignStatusFilter==eCampaignStatus.Campaign_Live.toString())
            //     this.gridFilters += ` and CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${this.campaignStatusFilter}'`;
            // else
            // this.gridFilters += ` and (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${this.campaignStatusFilter}'
            //                         or StatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${this.campaignStatusFilter}')`;
        }

        if(this.fiscalYearFilter.length == 0){
            this.fiscalYearFilter = [];
            this.fiscalYearFilter = this.defaultValueFiscalYearFilter;
        }
        var fiscalYearArray: string[] = [];
        for (var item of this.fiscalYearFilter) {
            fiscalYearArray.push("'" + item + "'");
        }
        var fiscalYearString = fiscalYearArray.join(",");
        if(!!fiscalYearString && this.gridId != 2 && this.gridId != 3){
            this.gridFilters += (this.gridFilters != null && this.gridFilters != '' ? ' and ' : ' ') + ` SeasonMapping/SeasonYear in (${fiscalYearString})`;
        }

        if(!!fiscalYearString && fiscalYearString != '')
        {
            this.filterGrid = {...this.filterGrid, fiscalYear : this.fiscalYearFilter};
        }

        if (!!this.searchParameter && this.searchParameter != ''){
            this.filterGrid = {...this.filterGrid, searchParameter : this.searchParameter};
        }

        if(!!this.gridId){
            this.filterGrid = {...this.filterGrid, gridId : this.gridId};
        }

        if(!!this.searchParameterSponsor){
            this.filterGrid = {...this.filterGrid, searchParameterSponsor : this.searchParameterSponsor};
        }

        if(Object.keys(this.filterGrid).length > 0){
            localStorage.setItem('gridFilter', JSON.stringify(this.filterGrid));
        }
        else{
            localStorage.removeItem('gridFilter');
        }

        this.pageLoader.showPageLoader();
        this.gridOptions = {} as IGridOptions;
        this.composeFilter();
        this.pageLoader.hidePageLoader();
    }

    public clearFilters() {
        this.searchParameter = '';
        this.campaignStatusFilter = [];
        this.fiscalYearFilter = this.defaultValueFiscalYearFilter;
        this.fiscalYearFilterChanged = this.fiscalYearFilter;
        this.clientNameFilter = '';
        this.regionFilter = '';
        this.gridFilters = '';
        localStorage.removeItem('gridFilter');

        this.pageLoader.showPageLoader();
        this.composeFilter();
        this.pageLoader.hidePageLoader();
    }

    showBulkEdit(event) {
        this.isBulkEditVisible =
            event.isAllRowsChecked || event.selectedCampaigns.length > 0;
    }

    openBulkEditDialog() {
        this.campaignBulkEditSettingsDialog.openDialog(
            true,
            this.campaignListComponentViewChild.selectedCampaigns
        );
    }

    resetSelectedCampaigns() {
        if (this.campaignListComponentViewChild) {
            this.campaignListComponentViewChild.selectedCampaigns = [];
            this.campaignListComponentViewChild.gridList = [];
        }
    }

    updateBreadCrumb(event) {
        setTimeout(() => {
            this.selectedSponsor = event;
        }, 0);
        
            if (event) {
                //this.searchParameter = '';
                this.breadcrumbItems.push({
                    label: event
                });
            } else {
                if (this.breadcrumbItems.length > 1) {
                    this.isGrouping = false;
                    //this.searchParameter = '';
                    this.breadcrumbItems.pop();
            }
        }
        this.breadcrumbItems = [...this.breadcrumbItems];
    }

    onSelectionChange(){
        this.cd.detectChanges();
    }

}
